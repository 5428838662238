import React, { Component } from "react";
import styles from "../css/CheckAuthentication.module.scss";
import LogoIndustry from "../assets/icon/LogoIndustry.png";
import EcoLogo from "../assets/icon/eco.png";
import CheckStatusFalse from "../assets/icon/cancel.png";
import RegisterIcon from "../assets/icon/plus.png";
import CheckStatusTrue from "../assets/icon/check.png";
import SyncSccess from "../assets/icon/sync_success.png";
import UnSync from "../assets/icon/sync.png";
import { Button, Container, Icon, Transition } from "semantic-ui-react";
import { GET_ACCESS_TOKEN_AUTO_LOGIN } from "./router";
import Axios from "axios";

const mockupOption = [
	{
		companyName: "รายชื่อบริษัทที่หนึ่ง",
		connected: false,
		nameCustomer: "รายชื่อผู้ดูแลหนึ่ง",
	},
	{
		companyName: "รายชื่อบริษัทที่สอง",
		connected: true,
		nameCustomer: "รายชื่อผู้ดูแลสอง",
	},
];

export default class CheckAuthentication extends Component {
	constructor(props) {
		super(props);
		this.state = {
			statusRegister: false,
		};
	}
	textCheckStatus = (status) => {
		switch (status) {
			case "":
				return <h3 style={{ fontSize: "18px" }}>กำลังตรวจสอบการเข้าสู่ระบบ ...</h3>;
			case "pass":
				return <h3 style={{ fontSize: "18px" }}>เข้าสู่ระบบสำเร็จ</h3>;
			case "error":
				return <h3 style={{ fontSize: "18px" }}>เกิดข้อผิดพลาดในการเข้าสู่ระบบ</h3>;
		}
	};

	iconCheckStatus = (status) => {
		switch (status) {
			case "":
				return (
					<Icon
						name="angle double right"
						className={styles.iconArrowLoading}
						style={{ margin: "0 25px" }}
						size="big"
					/>
				);
			case "pass":
				return <img className={styles.imageStatusIcon} src={CheckStatusTrue} />;
			case "error":
				return <img className={styles.imageStatusIcon} src={CheckStatusFalse} />;
		}
	};

	handleRedirectIndusktryLogin = async (data) => {
		if (this.props.token) {
			if (data.connected) {
				let response = await Axios({
					url: `${GET_ACCESS_TOKEN_AUTO_LOGIN}${data.company_id}`,
					method: "GET",
					headers: {
						Authorization: `Bearer ${this.props.token}`,
					},
				});
				if (response.data.code === 200) {
					localStorage.setItem("name", response.data.data.name);
					localStorage.setItem("company_id", response.data.data.companies_id);
					localStorage.setItem("token", response.data.data.access_token);
					localStorage.setItem("refreshToken", response.data.data.refresh_token);
					localStorage.setItem("role", "0");
					localStorage.setItem("should_show_popup", true);
				}
				window.location.href = "/company/main";
			} else {
				localStorage.clear();
				this.props.history.push("/i-industry-auth-login", {
					token: this.props.token,
					company_id: data.company_id,
				});
			}
		}
	};

	handleRedirectIndusktryRegister = async (juristic_id) => {
		this.props.history.push(`/register-industry?access_token=${this.props.token}&juristic_id=${juristic_id}`);
	};

	render() {
		const { loading, status, loadingCompany, listCompany } = this.props;
		const { statusRegister } = this.state;
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backdropFilter: "blur(8px)",
					background: "radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%)",
				}}
			>
				<Container>
					<div
						style={{
							// maxWidth: 800,
							width: "100%",
							margin: "auto",
							display: "flex",
							minHeight: "100vh",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div className={styles.container}>
							<div className={styles.boxContainer}>
								<h1 style={{ color: "#21ba45" }}>ตรวจสอบสถานะการเข้าถึง</h1>
								{loading && this.textCheckStatus(status)}
								<div className={styles.imageContainer}>
									<div className={styles.boxLogoImage}>
										<img src={LogoIndustry} />
									</div>
									{loading && this.iconCheckStatus(status)}
									<div style={{ width: "140px" }} className={styles.boxLogoImage}>
										<img src={EcoLogo} />
									</div>
								</div>
								{status == "error" && (
									<p style={{ marginTop: "20px" }}>ระบบขัดข้อง กรุณาติดต่อเจ้าหน้าที่</p>
								)}
							</div>

							{statusRegister ? (
								<Transition visible={status == "pass"} animation="scale" duration={500}>
									<div className={styles.containerListCompany}>
										<h3>เลือกกิจการที่ต้องการลงทะเบียน</h3>
										{loadingCompany ? (
											<div className={styles["lds-ellipsis"]}>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										) : listCompany?.industry_juristic_list?.length !== 0 ? (
											listCompany?.industry_juristic_list.map((item, i) => {
												return (
													<div className={styles.boxCompanyButton}>
														<Button
															onClick={() =>
																this.handleRedirectIndusktryRegister(item.juristic_id)
															}
															color="gray"
															className={styles.buttonCompanyLink}
														>
															<div>
																<h3 style={{ fontSize: "16px" }}>
																	{item.juristic_name_th}
																</h3>
															</div>
															<div>
																<img
																	className={styles.iconStatusButton}
																	src={RegisterIcon}
																/>
															</div>
														</Button>
													</div>
												);
											})
										) : (
											<div
												style={{
													width: "100%",
													textAlign: "center",
													padding: 20,
													color: "gray",
												}}
											>
												<p>ไม่พบกิจการที่ต้องการลงทะเบียน</p>
											</div>
										)}
										<div className={styles.boxCompanyButton}>
											<Button
												onClick={() =>
													this.setState({
														statusRegister: !statusRegister,
													})
												}
												color="green"
												basic
												className={styles.buttonCompanyLink}
											>
												<h3 style={{ fontSize: "16px", textAlign: "center" }}>
													กลับสู่การเลือก
												</h3>
											</Button>
										</div>
									</div>
								</Transition>
							) : (
								<Transition visible={status == "pass"} animation="scale" duration={500}>
									<div className={styles.containerListCompany}>
										<h3>เลือกบัญชี Eco Sticker</h3>
										{loadingCompany ? (
											<div className={styles["lds-ellipsis"]}>
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										) : listCompany?.company_exist?.length !== 0 ? (
											listCompany?.company_exist?.map((item, i) => {
												return (
													<div key={i} className={styles.boxCompanyButton}>
														<Button
															onClick={() => this.handleRedirectIndusktryLogin(item)}
															color="green"
															className={styles.buttonCompanyLink}
														>
															<div>
																<h3 style={{ fontSize: "16px" }}>
																	{item.company_name_th}
																</h3>
															</div>
															<div>
																{item.connected ? (
																	<img
																		className={styles.iconStatusButton}
																		src={SyncSccess}
																	/>
																) : (
																	<img
																		className={styles.iconStatusButton}
																		src={UnSync}
																	/>
																)}
															</div>
														</Button>
													</div>
												);
											})
										) : (
											<div
												style={{
													width: "100%",
													textAlign: "center",
													padding: 20,
													color: "gray",
												}}
											>
												<p>ไม่พบบัญชี Eco Sticker</p>
											</div>
										)}
										<div className={styles.boxCompanyButton}>
											<Button
												onClick={() =>
													this.setState({
														statusRegister: !statusRegister,
													})
												}
												color="green"
												basic
												className={styles.buttonCompanyLink}
											>
												<h3 style={{ fontSize: "16px", textAlign: "center" }}>
													ลงทะเบียนผู้ประกอบการ
												</h3>
											</Button>
										</div>
									</div>
								</Transition>
							)}
						</div>
					</div>
				</Container>
			</div>
		);
	}
}
