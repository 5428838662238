import React, { Component,Fragment } from "react";
import {
  Container,
  Form,
  Segment,
  Grid,
  Table,
  Icon,
  Button,
} from "semantic-ui-react";
import { HOST_PATH_FILE } from "../../router";
class delegateTable extends Component {
  constructor() {
    super();
    this.state = {
      delegatePathFile: "",
      index: 0,
    };
  }

  componentDidMount() {
    //set defualt path file

    let index = this.props.dataPersons
      .map((data) => data.type_personTemp)
      .indexOf("ผู้รับมอบอำนาจ");
    if (index > -1)
      this.setState({
        index: index,
        delegatePathFile: this.props.dataPersons[index]
          .person_certificateIsEdited
          ? `${HOST_PATH_FILE}${this.props.dataPersons[index].person_certificateTemp}`
          : `${HOST_PATH_FILE}${this.props.dataPersons[index].person_certificateOriginal}`,
      });
  }

  async delegateShowPDF(_, { name }) {
    if (name === "file_idcard") {
      this.setState({
        delegatePathFile: this.props.dataPersons[this.state.index]
          .person_certificateIsEdited
          ? `${HOST_PATH_FILE}${
              this.props.dataPersons[this.state.index].person_certificateTemp
            }`
          : `${HOST_PATH_FILE}${
              this.props.dataPersons[this.state.index]
                .person_certificateOriginal
            }`,
      });
    } else if (name === "file_passport") {
      this.setState({
        delegatePathFile: this.props.dataPersons[this.state.index]
          .person_certificateIsEdited
          ? `${HOST_PATH_FILE}${
              this.props.dataPersons[this.state.index].person_certificateTemp
            }`
          : `${HOST_PATH_FILE}${
              this.props.dataPersons[this.state.index]
                .person_certificateOriginal
            }`,
      });
    } else if (name === "file_poa") {
      this.setState({
        delegatePathFile: this.props.dataPersons[this.state.index]
          .power_of_attorneyIsEdited
          ? `${HOST_PATH_FILE}${
              this.props.dataPersons[this.state.index].power_of_attorneyTemp
            }`
          : `${HOST_PATH_FILE}${
              this.props.dataPersons[this.state.index].power_of_attorneyOriginal
            }`,
      });
    }
  }

  getCompared = (origin, alter) => {
    return (
      <Fragment>
        <span style={{ textDecoration: "line-through", color: "gray" }}>
          {origin}
        </span>{" "}
        <span style={{color:'green'}}>{alter}</span>
      </Fragment>
    );
  };

  render() {
    return (
      <Form.Field>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="3">
                      <Icon name="user outline" />
                      ผู้รับมอบอำนาจ
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {this.props.dataPersons.map((person, index) => {
                  if (person.type_personTemp === "ผู้รับมอบอำนาจ") {
                    return (
                      <React.Fragment>
                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>ชื่อ</Table.Cell>
                            <Table.Cell>
                              {/* {person.name_th} */}
                              {person.name_thIsEdited
                                ? this.getCompared(
                                    person.name_thOriginal,
                                    person.name_thTemp
                                  )
                                : person.name_thOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        <Table.Row>
                          <Table.Cell collapsing>Name</Table.Cell>
                          <Table.Cell>
                            {person.name_en}
                            {person.name_enIsEdited
                              ? this.getCompared(
                                  person.name_enOriginal,
                                  person.name_enTemp
                                )
                              : person.name_enOriginal}
                          </Table.Cell>
                        </Table.Row>

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>เลขบัตรประชาชน</Table.Cell>
                            <Table.Cell>
                              {person.tax_idIsEdited
                                ? this.getCompared(
                                    person.tax_idOriginal,
                                    person.tax_idTemp
                                  )
                                : person.tax_idOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ต่างชาติ" && (
                          <Table.Row>
                            <Table.Cell collapsing>
                              เลขที่หนังสือดินทาง
                            </Table.Cell>
                            <Table.Cell>
                              {person.passportIsEdited
                                ? this.getCompared(
                                    person.passportOriginal,
                                    person.passportTemp
                                  )
                                : person.passportOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>เลขที่</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {person.addressIsEdited
                                ? this.getCompared(
                                    person.addressOriginal,
                                    person.addressTemp
                                  )
                                : person.addressOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>ซอย</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {person.soiIsEdited
                                ? this.getCompared(
                                    person.soiOriginal,
                                    person.soiTemp
                                  )
                                : person.soiOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>ถนน</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {person.roadIsEdited
                                ? this.getCompared(
                                    person.roadOriginal,
                                    person.roadTemp
                                  )
                                : person.roadOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>จังหวัด</Table.Cell>
                            <Table.Cell>
                              {person.provinceIsEdited
                                ? this.getCompared(
                                    person.provinceOriginal,
                                    person.provinceTemp
                                  )
                                : person.provinceOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>อำเภอ/เขต</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {person.districtIsEdited
                                ? this.getCompared(
                                  person.districtOriginal,
                                  person.districtTemp
                                  )
                                : person.districtOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>ตำบล/แขวง</Table.Cell>
                            <Table.Cell>
                              {person.subdistrictIsEdited
                                ? this.getCompared(
                                    person.subdistrictOriginal,
                                    person.subdistrictTemp
                                  )
                                : person.subdistrictOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ไทย" && (
                          <Table.Row>
                            <Table.Cell collapsing>รหัสไปรษณีย์</Table.Cell>
                            <Table.Cell>
                              {person.zipcodeIsEdited
                                ? this.getCompared(
                                    person.zipcodeOriginal,
                                    person.zipcodeTemp
                                  )
                                : person.zipcodeOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        {person.nationalityTemp === "ต่างชาติ" && (
                          <Table.Row>
                            <Table.Cell>ที่อยู่</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {person.foreigner_addressIsEdited
                                ? this.getCompared(
                                    person.foreigner_addressOriginal,
                                    person.foreigner_addressTemp
                                  )
                                : person.foreigner_addressOriginal}
                            </Table.Cell>
                          </Table.Row>
                        )}

                        <Table.Row>
                          <Table.Cell collapsing>เบอร์โทรศัพท์</Table.Cell>
                          <Table.Cell>
                            {person.phoneIsEdited
                              ? this.getCompared(
                                  person.phoneOriginal,
                                  person.phoneTemp
                                )
                              : person.phoneOriginal}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell collapsing>อีเมล์</Table.Cell>
                          <Table.Cell>
                            {" "}
                            {person.emailIsEdited
                              ? this.getCompared(
                                  person.emailOriginal,
                                  person.emailTemp
                                )
                              : person.emailOriginal}
                          </Table.Cell>
                        </Table.Row>

                        {person.nationalityTemp === "ไทย" ? (
                          <Table.Row>
                            <Table.Cell collapsing>
                              <Icon name="file outline" />
                              สำเนาบัตรประชาชน
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                size="small"
                                // floated="left"
                                basic
                                color="orange"
                                name="file_idcard"
                                id="file_idcard"
                                onClick={this.delegateShowPDF.bind(this)}
                              >
                                <Icon name="search" />
                                ดูเอกสาร
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row>
                            <Table.Cell collapsing>
                              <Icon name="file outline" />
                              สำเนาหนังสือเดินทาง
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                size="small"
                                // floated="left"
                                basic
                                color="orange"
                                name="file_passport"
                                id="file_passport"
                                onClick={this.delegateShowPDF.bind(this)}
                              >
                                <Icon name="search" />
                                ดูเอกสาร
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        )}

                        <Table.Row>
                          <Table.Cell collapsing>
                            <Icon name="file outline" />
                            สำเนาหนังสือมอบอำนาจ
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              size="small"
                              // floated="left"
                              basic
                              color="orange"
                              name="file_poa"
                              id="file_poa"
                              onClick={this.delegateShowPDF.bind(this)}
                            >
                              <Icon name="search" />
                              ดูเอกสาร
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    );
                  }
                })}
              </Table>
            </Grid.Column>

            <Grid.Column  mobile={16} tablet={8} computer={8}>
              <iframe
              title='iframe_delegate'
                // src={`${HOST_PATH_FILE}${this.props.dataPersons[0].person_certificate}`}
                src={this.state.delegatePathFile}
                style={{
                  height: window.innerHeight,
                  width: "100%",
                  borderWidth: 0,
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
    );
  }
}

export default delegateTable;
