import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import HomePageBeta from "./components/HomePage_beta";
import homeCompany from "./components/main";
import entrepreneurRegister from "./components/registerControl/entrepreneurRegister";
import IndustryCompanyRegister from "./components/registerControl/entrepreneurRegister";
import productRegister from "./components/registerControl/productRegister";
import addAuthorized from "./components/registerControl/addAuthorized";
import productImport from "./components/registerControl/productImport";
import addDelegate from "./components/registerControl/addDelegate";
import registerConfirm from "./components/registerControl/registerConfirm";
import RegisterSuccess from "./components/registerControl/registerSuccess";
import RegisterEditPending from "./components/registerControl/registerEditPending";
import homeOIE from "./components/OIEControl/home";
import Dashboard from "./components/OIEControl/dashboard";
import LoginCompany from "./components/company/login";
import approvalList from "./components/approval/list/tisi_oie/home";
// import approvalListV2 from "./components/approval/list/tisi_oie/Homev2";
import approvalListV2 from "./components/approval/list/tisi_oie/HomeV2Func";
import approvalListOld from "./components/approval/list/tisi_oie/oldStickerHome";
import ApprovalLogin from "./components/approval/login/ApprovalLogin";
import ProductDetail from "./components/approval/detail/ProductDetail";
import TechnicalDetail from "./components/approval/technical/TechnicalDetail";
import requestIndex from "./components/requestControl/requestIndex";
import RequestIndexNew from "./components/requestControl/RequestIndexNew";
import requestLicense from "./components/requestControl/requestLicense";
import requestDetail from "./components/requestControl/requestDetail";
import requestTest from "./components/requestControl/requestTest";
import requestPreview from "./components/requestControl/requestPreview";
import StickerDetail from "./components/requestControl/StickerDetail";
import StickerDownload from "./components/requestControl/StickerDownload";
import detailCompany from "./components/OIEControl/detailCompany";
import paymentList from "./components/approval/paymentStatus/paymentList.js";
import requestPreviewApprove from "./components/requestControl/requestPreviewApprove";
import pdfPreview from "./components/requestControl/pdfPreview";
import LoginOIE from "./components/OIEControl/login";
import StickerProduct from "./components/approval/detail/StickerProduct";
import requestPreviewApproveGeneral from "./components/requestControl/requestPreviewGeneral";
import requestPayment from "./components/requestControl/requestPayment";

import EditEntrepreneur from "./components/editCustomerDataController/EditEntrepreneur";
import EditFactoryManufacture from "./components/editCustomerDataController/EditFactoryManufacture";
import EditFactoryImport from "./components/editCustomerDataController/EditFactoryImport";
import EditAuthorizedPerson from "./components/editCustomerDataController/EditAuthorizedPerson";
import EditDelegatePerson from "./components/editCustomerDataController/EditDelegatePerson";
import EditionConfirm from "./components/editCustomerDataController/EditionConfirm";
import EditSuccess from "./components/editCustomerDataController/EditSuccess";
import EditPending from "./components/editCustomerDataController/EditPending";
import SubmitInvoice from "./components/approval/submitInvoice/SubmitInvoice";

import UnderConstruction from "./container/UnderConstruction";
import ResetPassword from "./components/recoverPasswordControl/ResetPassword";
import RecoveryPassword from "./components/recoverPasswordControl/RecoverPassword";
import EditFailed from "./components/editCustomerDataController/EditFailed";

import AuthenticationSevice from "./pages/AuthenticationSevice";

import SearchStickerDetail from "./components/SearchPage/StickerDetail";
import HomeV2 from "./components/OIEControl/HomeV2";
import Layout from "./components/OIEControl/Layout";

class App extends Component {
    render() {
        return (
            <Router>
                {/* <Route
					path={'/'}
					render={() => {
						if (localStorage.getItem('role')) {
							if (localStorage.getItem('role') !== '0') {
								return <Redirect to={"/oie/entrepreneur"} />
							} else {
								return <Redirect to={"/company/main/"} />
							}
						} else {
							return <HomePageBeta />
						}
					}}
				/> */}

                {/* REQUEST ROUTE  */}
                {/* <Route
          path={["/company", "/"]}
          render={() => (
            <Sidebar.Pushable as={Segment}>
              <Sidebar
                as={Menu}
                animation="overlay"
                icon="labeled"
                inverted
                onHide={() => {}}
                vertical
                visible={true}
                width="thin"
              >
                <Menu.Item as="a">
                  <Icon name="home" />
                  Homez
                </Menu.Item>
                <Menu.Item as="a">
                  <Icon name="gamepad" />
                  Games
                </Menu.Item>
                <Menu.Item as="a">
                  <Icon name="camera" />
                  Channels
                </Menu.Item>
              </Sidebar>
              <Sidebar.Pusher style={{height:`100ch`}}>
              <Switch>

              </Switch>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
     
          )}
        /> */}

                <Route
                    path="/company/main/"
                    component={RequestIndexNew}
                />

                <Route
                    exact
                    path="/company/request-license/:company_id/:request_id"
                    component={requestLicense}
                />
                <Route
                    exact
                    path="/company/request-license"
                    component={requestLicense}
                />
                <Route
                    path="/company/request-detail"
                    component={requestDetail}
                />
                <Route
                    path="/company/request-test"
                    component={requestTest}
                />
                <Route
                    path="/company/request-preview"
                    component={requestPreview}
                />
                <Route
                    exact
                    path="/company/preview-request"
                    component={requestPreviewApprove}
                />
                <Route
                    path="/company/request-pdf"
                    component={pdfPreview}
                />
                <Route
                    exact
                    path="/company/sticker-preview/:sizeId"
                    component={StickerDetail}
                />
                <Route
                    exact
                    path="/company/preview-request/:request_id"
                    component={requestPreviewApproveGeneral}
                />
                <Route
                    path="/company/request-payment"
                    component={requestPayment}
                />
                <Route
                    exact
                    path="/company/sticker-public-preview/:sizeId"
                    component={StickerDetail}
                />
                <Route
                    exact
                    path="/company/sticker-download/:sizeId"
                    component={StickerDownload}
                />

                {/* //TODO: REGISTER ROUTE */}
                <Route
                    exact
                    path="/oie"
                    component={LoginOIE}
                />
                <Route
                    exact
                    path="/company"
                    component={homeCompany}
                />
                <Route
                    path="/company/super-admin-login"
                    component={LoginCompany}
                />
                <Route
                    path="/i-industry-auth-login"
                    render={(props) => (
                        <LoginCompany
                            {...props}
                            isIndustry
                        />
                    )}
                />
                <Route
                    path="/entrepreneurRegister"
                    component={entrepreneurRegister}
                />
                <Route
                    path="/register-industry"
                    render={(props) => (
                        <IndustryCompanyRegister
                            {...props}
                            isIndustry
                        />
                    )}
                />
                <Route
                    path="/productRegister"
                    component={productRegister}
                />
                <Route
                    path="/addAuthorized"
                    component={addAuthorized}
                />
                <Route
                    path="/productImport"
                    component={productImport}
                />
                <Route
                    path="/addDelegate"
                    component={addDelegate}
                />
                <Route
                    path="/registerConfirm"
                    component={registerConfirm}
                />
                <Route
                    path="/registerSuccess"
                    component={RegisterSuccess}
                />
                <Route
                    path="/registerEditPending"
                    component={RegisterEditPending}
                />

                {/* //TODO: EDIT DATA ENTREPRENEUR ROUTE */}
                <Route
                    path="/company/editEntrepreneur/entrepreneur"
                    component={EditEntrepreneur}
                />
                <Route
                    path="/company/editEntrepreneur/factoryManufacture"
                    component={EditFactoryManufacture}
                />
                <Route
                    path="/company/editEntrepreneur/factoryImport"
                    component={EditFactoryImport}
                />
                <Route
                    path="/company/editEntrepreneur/authorizedPerson"
                    component={EditAuthorizedPerson}
                />
                <Route
                    path="/company/editEntrepreneur/delegatePerson"
                    component={EditDelegatePerson}
                />
                <Route
                    path="/company/editEntrepreneur/editionConfirm"
                    component={EditionConfirm}
                />
                <Route
                    path="/company/editEntrepreneur/editSuccess"
                    component={EditSuccess}
                />
                <Route
                    path="/company/editEntrepreneur/editPending"
                    component={EditPending}
                />
                <Route
                    path="/company/editEntrepreneur/editFailed"
                    component={EditFailed}
                />
                <Route
                    exact
                    path="/company/forgetPassword"
                    component={RecoveryPassword}
                />
                <Route
                    exact
                    path="/company/resetPassword/:id"
                    component={ResetPassword}
                />

                <Route
                    path="/oie/detailCompany"
                    component={detailCompany}
                />
                {/* <Route
                    path="/oie/entrepreneur"
                    component={homeOIE}
                /> */}
                <Route
                    path="/oie/entrepreneur"
                    component={() => (
                        <Layout>
                            <HomeV2 />
                        </Layout>
                    )}
                />
                <Route
                    path="/oie/dashboard"
                    component={Dashboard}
                />
                <Route
                    path="./header"
                    component={Headers}
                />

                {/* // TODO: APPROVE */}
                <Route
                    path="/approval/tisi_oie"
                    component={approvalListV2}
                />
                <Route
                    path="/approval/old_tisi_oie"
                    component={approvalListOld}
                />
                <Route
                    path="/approval/payment"
                    component={paymentList}
                />
                <Route
                    path="/approval/login"
                    component={ApprovalLogin}
                />
                <Route
                    exact
                    path="/approval/product_detail"
                    component={ProductDetail}
                />
                <Route
                    path="/technical/product_detail"
                    component={TechnicalDetail}
                />
                <Route
                    path="/approval/product_detail/preview_product"
                    component={requestPreview}
                />
                <Route
                    path="/approval/product_detail/sticker_product"
                    component={StickerProduct}
                />
                <Route
                    path="/approval/submit_invoice"
                    component={SubmitInvoice}
                />
                {/* </Sidebar.Pusher> */}

                <Route
                    exact
                    path="/underconstruct"
                    component={UnderConstruction}
                />
                <Route
                    exact
                    path="/"
                    component={HomePageBeta}
                />
                <Route
                    exact
                    path="/industry-auth"
                    component={AuthenticationSevice}
                />
                <Route
                    exact
                    path="/homepage"
                    component={HomePage}
                />
                {/* <Route exact path="/search" component={SearchPage} /> */}
                <Route
                    exact
                    path="/sticker-detail/:sizeId"
                    component={SearchStickerDetail}
                />
            </Router>
        );
    }
}
export default App;
