import React, { useEffect, useState } from "react";
import { Button, Header, Modal, Input, Dimmer, Loader } from "semantic-ui-react";
import style from "./style.module.scss";
import InputFile from "./InputFile";
import { HOST_PATH_FILE, getCompanyByProductID, uploadFileTisi2718_2719, uploadFileTisi2721 } from "../router";
import Swal from "sweetalert2";
import ModalViewFile from "../ModalViewFile";

const initialFile = {
    open: false,
    title: "",
    url: "",
};

const ModalEditData = ({ onClose, onOpen, open, keyId, productId, viewMode = false }) => {
    const [is_open_view_file, setIsOpenViewFile] = useState(initialFile);
    const [type_modal, setTypeModal] = useState("tisi_2718");
    const [form_value, setFormValue] = useState({
        license: null,
        list: null,
    });
    const [data, setData] = useState();
    const [loading_modal, setLoadingModal] = useState(false);
    const [loading_upload, setLoadingUpload] = useState(false);
    const [file_name, setFileName] = useState("");
    const [file_data, setFileData] = useState();
    const [data_fill, setDataFill] = useState(false);
    const [is_submitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState({
        license: false,
        list: false,
        file: false,
    });

    useEffect(() => {
        if (productId && open) {
            fnGetProductDetail();
        }
    }, [open]);

    useEffect(() => {
        const typeModalMapping = {
            tisi_2718: {
                licenseKey: "license_request",
                listKey: "list_request",
                filePathKey: "file_path_product_request",
                fileNameKey: "file_filename_product_request",
            },
            tisi_2721: {
                licenseKey: "license_request_tisi_2721",
                listKey: "list_request_tisi_2721",
                filePathKey: "file_path_tisi_2721",
                fileNameKey: "file_name_tisi_2721",
            },
        };

        const modalType = typeModalMapping[type_modal];
        if (data) {
            setFormValue({
                license: data[modalType.licenseKey],
                list: data[modalType.listKey],
            });

            if (data[modalType.filePathKey]) {
                setDataFill(true);
                setFileName(data[modalType.fileNameKey] || "ไม่พบชื่อไฟล์");
            } else {
                setDataFill(false);
                setFileName("");
            }
        }
    }, [type_modal, data]);

    const fnGetProductDetail = async () => {
        try {
            setLoadingModal(true);
            const response = await getCompanyByProductID(productId);
            const value = response.data.data;

            setData(value);
        } catch (error) {
            Swal.fire({
                type: "error",
                icon: "error",
                title: "ไม่สามารถดึงข้อมูลได้",
                confirmButtonText: "ปิด",
            });
        } finally {
            setLoadingModal(false);
        }
    };

    const fnHandleClose = () => {
        // setDataFill(true);
        setTypeModal("tisi_2718");
        fnResetFieldValue();
        if (onClose) {
            onClose();
        }
    };

    const fnUploadFile = async () => {
        const formData = new FormData();
        formData.append(
            type_modal === "tisi_2718" ? "file_tisi2718_2719_for_product_request" : "file_tisi2721_for_product_request",
            file_data
        );
        formData.append(type_modal === "tisi_2718" ? "list_request" : "list_request_tisi_2721", form_value.list);
        formData.append(type_modal === "tisi_2718" ? "license_request" : "license_request_tisi_2721", form_value.license);

        setLoadingUpload(true);
        try {
            let response;

            if (type_modal === "tisi_2718") {
                response = await uploadFileTisi2718_2719({
                    formData,
                    productId,
                });
            } else {
                response = await uploadFileTisi2721({
                    formData,
                    productId,
                });
            }

            setLoadingUpload(false);
            fnHandleClose()
            Swal.fire({
                type: response ? "success" : "error",
                icon: response ? "success" : "error",
                title: response ? "Upload สำเร็จ" : "Upload ไม่สำเร็จ",
                confirmButtonText: "ปิด",
            })
        } catch (error) {
            setLoadingUpload(false);
            fnHandleClose()
            Swal.fire({
                type: "error",
                icon: "error",
                title: "Upload ไม่สำเร็จ",
                confirmButtonText: "ปิด",
            })
        }
    };

    const fnResetFieldValue = () => {
        setFileName("");
        setFormValue({
            license: "",
            list: "",
        });
        setError({
            file: false,
            license: false,
            list: false,
        });
    };

    const fnHandleOpenViewFile = (file) => {
        setIsOpenViewFile({
            open: true,
            ...file,
        });
    };

    const fnHandleCloseViewFile = () => {
        setIsOpenViewFile(initialFile);
    };

    return (
        <Modal
            onClose={fnHandleClose}
            onOpen={onOpen}
            open={open}
            style={{
                padding: 20,
                zIndex:99
            }}
        >
            {loading_modal ? (
                <Dimmer
                    active
                    inverted
                >
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Header
                        as="h3"
                        style={{ margin: 0, borderBottom: "0px", textAlign: "center" }}
                    >
                        ประเภทใบอนุญาติ มอก.
                    </Header>

                    <div className={style.modalSelect}>
                        <div
                            style={{
                                marginRight: 2.5,
                                backgroundColor: type_modal === "tisi_2718" ? "#ffd646" : "#fff",
                            }}
                            onClick={() => {
                                setTypeModal("tisi_2718");
                                // setFormValue({
                                //     license: null,
                                //     list: null
                                // });
                            }}
                        >
                            <div>มอก.</div>
                            <div>2718-2560/2719-2560</div>
                        </div>
                        <div
                            style={{
                                marginLeft: 2.5,
                                backgroundColor: type_modal === "tisi_2721" ? "#ffd646" : "#fff",
                            }}
                            onClick={() => {
                                setTypeModal("tisi_2721");
                                // setFormValue({
                                //     license: null,
                                //     list: null
                                // });
                            }}
                        >
                            <div>มอก.</div>
                            <div>2721-2560</div>
                        </div>
                    </div>

                    <div>
                        <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                            ชื่อผลิตภัณฑ์ : <span>{data?.product_name}</span>
                        </div>

                        <div style={{ display: "flex", marginTop: 10 }}>
                            <Input
                                className={style.disableInput}
                                placeholder={`ใบอณุญาติที่: n xxxx-xx/2718`}
                                style={{ flex: 1 / 2, marginRight: 10 }}
                                value={form_value.license}
                                error={is_submitted && error.license && !data_fill}
                                onChange={({ target }) => {
                                    setFormValue((prev) => {
                                        return {
                                            ...prev,
                                            license: target.value,
                                        };
                                    });
                                }}
                                disabled={viewMode || data_fill}
                            />
                            <Input
                                className={style.disableInput}
                                placeholder={`หน้าที่: 1 , 2 , 5`}
                                style={{ flex: 1 / 2, marginLeft: 10 }}
                                value={form_value.list}
                                onChange={({ target }) => {
                                    setFormValue((prev) => {
                                        return {
                                            ...prev,
                                            list: target.value,
                                        };
                                    });
                                }}
                                disabled={viewMode || data_fill}
                                error={is_submitted && error.list && !data_fill}
                            />
                        </div>

                        <div style={{ display: "flex", marginTop: 5, flexDirection: "row-reverse" }}>
                            <div style={{ flex: 1 / 2, color: "#db2828", marginLeft: 25, fontSize: 12 }}>
                                หมายเหตุ: กรอกหมายเลขที่ปรากฏรายการยางรถยนต์ข้างต้น หากมีมากกว่า 1 หน้าให้ใช้เครื่องหมาย ,
                                คั่นระหว่างตัวเลขเช่น 1,2,4
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                marginTop: 35,
                            }}
                        >
                            <InputFile
                                style={{
                                    border: is_submitted && error.file ? "1px solid rgb(224,180,180)" : "1px solid rgba(34, 36, 38, 0.15)",
                                    borderRadius: "0.285714rem",
                                    transition: "box-shadow 0.1s ease 0s, border-color 0.1s ease 0s",
                                    boxShadow: "none",
                                    textAlign: "left",
                                    lineHeight: "1.21429em",
                                    padding: "0.678571em 1em",
                                    width: "calc(50% - 10px)",
                                    background: viewMode
                                        ? "#eee"
                                        : is_submitted && error.file
                                        ? "rgb(255,246,246)"
                                        : !data_fill
                                        ? "#fff"
                                        : "#eee",
                                    color: viewMode ? "#000" : is_submitted && error.file ? "rgb(159,58,56)" : "#000",
                                    cursor: viewMode ? "default" : !data_fill ? "pointer" : "default",
                                }}
                                filename={file_name}
                                id={type_modal === "tisi_2718" ? `tisi_2718_${keyId}` : `tisi_2721_${keyId}`}
                                keyId={keyId}
                                onFileChange={(e) => setFileData(e[keyId].data)}
                                disable={viewMode || data_fill}
                            />
                            {data_fill && (
                                <div style={{ flex: 1, marginLeft: 20 }}>
                                    <Button
                                        basic
                                        color="green"
                                        onClick={() => {
                                            if (type_modal === "tisi_2718") {
                                                fnHandleOpenViewFile({
                                                    title: "ใบอนุญาต มอก. 2718-2560/2719-2560",
                                                    url: HOST_PATH_FILE + data?.file_path_product_request,
                                                });
                                            } else {
                                                fnHandleOpenViewFile({
                                                    title: "ใบอนุญาต มอก.2721-2560",
                                                    url: HOST_PATH_FILE + data?.file_path_tisi_2721,
                                                });
                                            }
                                        }}
                                    >
                                        ดูไฟล์
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div style={{ display: "flex", paddingTop: 40, justifyContent: "center" }}>
                {!viewMode && (
                    <Button
                        positive={!data_fill}
                        color={data_fill && "yellow"}
                        style={{ width: "fit-content" }}
                        loading={loading_upload}
                        disabled={loading_upload}
                        onClick={() => {
                            // if have data click will be upload new file
                            // else if don't have data submit button will be save file
                            if (data_fill) {
                                //switch mode to upload new file set field to empty
                                setDataFill(false);
                                fnResetFieldValue();
                            } else {
                                setIsSubmitted(true);
                                if (form_value.license && form_value.list && file_data) {
                                    fnUploadFile();
                                } else {
                                    setError({
                                        file: !file_data,
                                        license: !form_value.license,
                                        list: !form_value.list,
                                    });
                                }
                            }
                        }}
                    >
                        {data_fill ? "อัพโหลดไฟล์ใหม่" : "บันทึก"}
                    </Button>
                )}

                <Button
                    onClick={fnHandleClose}
                    style={{ width: 100 }}
                >
                    ปิด
                </Button>
            </div>
            <ModalViewFile
                open={is_open_view_file.open}
                data={is_open_view_file}
                onClose={fnHandleCloseViewFile}
            />
        </Modal>
    );
};

export default ModalEditData;
