import React, { Component } from "react";
import {
  Image,
  Header,
  Form,
  Input,
  Message,
  Button,
  Icon,
} from "semantic-ui-react";
import axios from "axios";
import { LOGIN_USER, HEADER } from "../router";
import { to } from "../handle";
import { withRouter } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import sticker_landing from "../../assets/img/sticker_landing.png";
import ecoLogo from "../../assets/img/logo-eco.png";
import Fade from "react-reveal/Fade";
import News from "./News";
import { alignAuto } from "react-charts/dist/react-charts.development";

export class CompanyLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: {
        username: "",
        password: "",
      },
      isLoggingIn: false,
      reset_password: false,
      register: false,
    };
  }

  handleSummit = async () => {
    let loginData = {
      username: this.state.username,
      password: this.state.password,
    };
    let result,
      error = [];
    localStorage.clear();
    this.setState({ isLoggingIn: true });

    ({ result, error } = await to(axios.post(LOGIN_USER, loginData, HEADER)));
    this.setState({ isLoggingIn: false });
    if (error) {
      this.setState({ loader_login: false });
      if (!error.response) {
        // network error
        this.setState({
          networkFail: true,
          loginFail: false,
          textError: "เกิดข้อผิดพลาดจากระบบเครือข่าย",
        });
      } else {
        // response data
        const response = error.response.data;
        if (response.message === "No Username in System") {
          this.setState({
            networkFail: false,
            loginFail: true,
            textError: "ไม่พบรหัสผู้ใช้งานนี้ในระบบ",
            textErrorSub: "กรุณาตรวจสอบใหม่อีกครั้ง",
          });
        } else if (response.message === "wrong Username") {
          this.setState({
            networkFail: false,
            loginFail: true,
            textError: "ชื่อผู้ใช้งานไม่ถูกต้อง",
            textErrorSub: "กรุณาตรวจสอบใหม่อีกครั้ง",
          });
        } else if (response.message === "wrong Password") {
          this.setState({
            networkFail: false,
            loginFail: true,
            textError: "รหัสผ่านไม่ถูกต้อง",
            textErrorSub: "กรุณาตรวจสอบใหม่อีกครั้ง",
          });
        } else if (response.message === "suspended") {
          this.setState({
            networkFail: false,
            loginFail: true,
            textError: "ผู้ใช้ถูกระงับการใช้งาน",
            textErrorSub: "กรุณาติดต่อเจ้าหน้าที่\nโทร. 02-4306805 ต่อ 680513",
          });
        }
      }
    }
    if (result) {
      if (result.data.status === "Success") {
        try {
          //  localStorage.setItem("jwt","some value");
          localStorage.setItem("name", result.data.value.name);
          localStorage.setItem("company_id", result.data.value.companies_id);
          localStorage.setItem("token", result.data.value.access_token);
          localStorage.setItem("refreshToken", result.data.value.refresh_token);
          localStorage.setItem("role", "0");
          localStorage.setItem('should_show_popup', true)
          this.setState({ networkFail: false, loginFail: false, login: true });
        } catch (error) {
          console.log(error);
        }

        //  this.props.history.push("/company/main")
      }
    }
  };

  handleOnchange = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    if (this.state.login) {
      // return <Redirect to='/company/main' />;
      window.location.href = "/company/main";
    }
    if (this.state.reset_password) {
      // return <Redirect to='/company/main' />;
      window.location.href = "/company/forgetPassword";
    }
    if (this.state.register) {
      // return <Redirect to='/company/main' />;
      window.location.href = "/entrepreneurRegister";
    }
    return (
      <React.Fragment>
        <section id="eco_stickers" className="login-area ptb-100">
          <div className="container">
            <Fade top>
              <VisibilitySensor
                onChange={(isVisible) =>
                  isVisible && this.props.setActiveMenu("eco_stickers")
                }
              >
                <h3 className="section-header">ECO Sticker</h3>
              </VisibilitySensor>
            </Fade>
            <Fade bottom>
              <div
                className={this.props.displayEcoSticker.containerCompanyLogin}
              >
                {/* <div className="section-title">
                            <h2>{`sectionTitle`}</h2>
                            <p>{`sectionContent`}</p>
                        </div> */}


                <div className={this.props.displayEcoSticker.stickerLanding}>
                  <Image src={sticker_landing} size={"medium"} />

                  <div style={{ marginTop: "20px" }}>
                    ตัวอย่าง ECO Sticker ยางรถยนต์
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          {/* </VisibilitySensor> */}
        </section>
      </React.Fragment>
    );
  }
}

CompanyLogin.defaultProps = {
  displayEcoSticker: {
    containerCompanyLogin: "container-company-login",
    loginForm: "eco-sticker-login",
    loginFormInput: "input-login",
    stickerLanding: "sticker-landing",
    
  },
};

export default withRouter(CompanyLogin);
