import React, { Component } from "react";
import PropTypes from "prop-types";
import TIS2718 from "../../assets/TIS2718_2558.pdf";
import TIS2719 from "../../assets/TIS2719_2558.pdf";

import Submission_Guide from "../../assets/Registration_submission_guide_eco_tyre.pdf";

import news_scaletime from "../../assets/img/news_img_landingpage/news_22-04-2021_scaletime.png";
import Regulation_EC from "../../assets/Regulation_EC.pdf";
import Regulation from "../../assets/Regulation.pdf";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "react-reveal/Fade";
import styles from "./css/News.module.scss";
import Thailand from "@svg-maps/thailand";
import ThMap from "../../assets/img/thai_map.png";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import { Header, Icon, Grid, Image, Card, Label } from "semantic-ui-react";
import TableManufacturers from "./TableManufacturers";
import ProduceChart from "./ProduceChart";
import RevealCard from "./RevealCard";
import NewsContent from "./NewsContent";
import Pagination from "react-bootstrap/Pagination";
import PopupNews from "./PopupNews";
import BackdropNews from "../../assets/img/news.png";
import { Carousel } from "react-bootstrap";
import INFOECOTYRE from "../../assets/img/Info_ECO_Sticker.png";
import IMAGESHOWPREVIEWPDF from "../../assets/img/image_show_news.png";
import RegulationModal from "./RegulationModal";

const containerCarouselItem = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: 426,
};
const news = [
	{
		title: "หัวข้อข่าว",
		date: "18-08-2022",
		details:
			"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti placeat corporis, fugiat nostrum natus amet fuga quod sed recusandae. Nostrum optio at assumenda ut distinctio, quam sunt dolore doloremque est.",
		image: "https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
	},
	{
		title: "หัวข้อข่าว",
		date: "18-08-2022",
		details:
			"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti placeat corporis, fugiat nostrum natus amet fuga quod sed recusandae. Nostrum optio at assumenda ut distinctio, quam sunt dolore doloremque est.",
		image: "https://images.unsplash.com/photo-1514053514818-a89e7ce304e9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
	},
	// {
	// 	title: "หัวข้อข่าว",
	// 	date: "18-08-2022",
	// 	details:
	// 		"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti placeat corporis, fugiat nostrum natus amet fuga quod sed recusandae. Nostrum optio at assumenda ut distinctio, quam sunt dolore doloremque est.",
	// 	image: "https://images.unsplash.com/photo-1519641471654-76ce0107ad1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80",
	// },
	// {
	// 	title: "หัวข้อข่าว",
	// 	date: "18-08-2022",
	// 	details:
	// 		"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti placeat corporis, fugiat nostrum natus amet fuga quod sed recusandae. Nostrum optio at assumenda ut distinctio, quam sunt dolore doloremque est.",
	// 	image: "https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
	// },
	// {
	// 	title: "หัวข้อข่าว",
	// 	date: "18-08-2021",
	// 	details:
	// 		"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti placeat corporis, fugiat nostrum natus amet fuga quod sed recusandae. Nostrum optio at assumenda ut distinctio, quam sunt dolore doloremque est.",
	// 	image: "https://images.unsplash.com/photo-1514053514818-a89e7ce304e9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
	// },
	// {
	// 	title: "หัวข้อข่าว",
	// 	date: "18-08-2023",
	// 	details:
	// 		"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti placeat corporis, fugiat nostrum natus amet fuga quod sed recusandae. Nostrum optio at assumenda ut distinctio, quam sunt dolore doloremque est.",
	// 	image: "https://images.unsplash.com/photo-1519641471654-76ce0107ad1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80",
	// },
];

export class News extends Component {
	constructor(props) {
		super(props);
		this.popupRef = React.createRef();
		this.state = {
			isOpenModal: false,
			src: null,
			styleObj: {},
			visibilityPopup: false,
			news: null,
			index: 0,
		};
	}
	componentDidMount() {}
	handleClick = (e, src) => {
		e.preventDefault();
		if (src) {
			this.setState({
				src: src,
				isOpenModal: true,
			});
		}
	};

	// function open pop up and scroll smooth
	scroll = (data, ref) => {
		const positionRef = document.getElementById("news");
		positionRef.scrollIntoView({
			behavior: "smooth",
		});
		this.setState({
			visibilityPopup: true,
			news: data,
		});
	};

	handleSelect = (selectedIndex, e) => {
		this.setState({
			index: selectedIndex,
		});
	};

	popupCloseHandler = () => {
		this.setState({ visibilityPopup: false });
	};

	getLocationClassName = (e) => {
		const coord = e.target.getPointAtLength(e.target.getTotalLength());
		const xCal = 400 + coord.x + "px";
		const yCal = 300 + coord.y + "px";
		console.log("x", coord.x);
		this.setState({
			styleObj: { ...this.state.styleObj, left: xCal, top: yCal },
		});
	};

	//function modal
	closeModal = () => {
		this.setState({ isOpenModal: false });
	};

	render() {
		return (
			<React.Fragment>
				<RegulationModal isOpen={this.state.isOpenModal} closeModal={this.closeModal} src={this.state.src} />
				<Fade bottom cascade>
					<div id="popup" style={{ display: this.state.visibilityPopup ? "flex" : "none" }}>
						<PopupNews
							activePopup={this.state.visibilityPopup}
							onClose={this.popupCloseHandler}
							data={this.state.news}
						/>
					</div>
					<div id="news" className="news">
						<div className={styles.container}>
							<div className={styles.containerBoxNews}>
								{/* hide content news */}
								{/* <div className={styles.containerSlideNews}>
                    <Fade top cascade>
                      <div
                        className="section-title"
                        style={{ marginBottom: 20 }}
                      >
                        <h2>ข่าวสาร</h2>
                      </div>
                    </Fade>
                    <img
                      src={BackdropNews}
                      className={styles.boxImageBackgroud}
                    />
                    <div className={styles.myCarousel}>
                      <Carousel fade={true}>
                        <Carousel.Item
                          style={containerCarouselItem}
                          interval={5000}
                        >
                          <img
                            className="d-block w-100"
                            src="https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                            alt="Image One"
                          />

                          <Carousel.Caption>
                            <div className={styles.boxSlideContent}>
                              <h3>หัวข้อข่าว</h3>
                              <small>08-08-2022</small>
                              <p>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Corrupti placeat corporis,
                                fugiat nostrum natus amet fuga quod sed
                                recusandae. Nostrum optio at assumenda ut
                                distinctio, quam sunt dolore doloremque est.
                              </p>
                            </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item
                          style={containerCarouselItem}
                          interval={5000}
                        >
                          <img
                            className="d-block w-100"
                            src="https://images.unsplash.com/photo-1474128710184-2a547ec84777?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2094&q=80"
                            alt="Image Two"
                          />
                          <Carousel.Caption>
                            <div className={styles.boxSlideContent}>
                              <h3>หัวข้อข่าว</h3>
                              <small>18-08-2022</small>
                              <p>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Corrupti placeat corporis,
                                fugiat nostrum natus amet fuga quod sed
                                recusandae. Nostrum optio at assumenda ut
                                distinctio, quam sunt dolore doloremque est.
                              </p>
                            </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div> */}

								<div className="container">
									<div className={styles.contentNews}>
										<div className={styles.boxGirdImage}>
											<img src={INFOECOTYRE} className={styles.boxImageShowContentNews} />
										</div>
										<div
											style={{
												width: "43%",
												height: '100%',
												padding: 5,
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												
											}}
										>
											<div className={styles.boxPreviewPdfCard}>
												<div className={styles.boxImagePreviewPdfCard}>
													<img className={styles.imagePreviewPdf} src={IMAGESHOWPREVIEWPDF} />
												</div>
												<div className={styles.boxContentPreviewPdfCard}>
													<h3>
														<Grid columns={2}>
															<Grid.Column width={12}>
																<a
																	href=""
																	onClick={(e) =>
																		this.handleClick(e, Submission_Guide)
																	}
																>
																	วิธีการลงทะเบียนและยื่นข้อมูล ขออนุมัติ ECO Sticker
																</a>
															</Grid.Column>
															<Grid.Column
																width={4}
																style={{ textAlign: "right" }}
															></Grid.Column>
														</Grid>
													</h3>
													<p className="sub-post-content">
														คู่มือวิธีการลงทะเบียนและยื่นข้อมูล เพื่อขออนุมัติ ECO Sticker
														สาหรับยางรถยนต์ ผ่านเว็บไซต์ tyre.go.th
													</p>
												</div>
											</div>
											{/* <div className={styles.containerPreviewPdf}>
												<div
													className={styles.boxContentShowPdf}
													onClick={(e) => this.handleClick(e, Submission_Guide)}
												>
													<div className={styles.boximageContentPdf}>
														<img
															className={styles.imagePreviewPdf}
															src={IMAGESHOWPREVIEWPDF}
														/>
													</div>
													<div className={styles.boxContentPdf}>
														<div style={{ marginBottom: "12px" }}>
															<Label as="a" tag>
																คู่มือ
															</Label>
														</div>
														<span>วิธีการลงทะเบียนและยื่นข้อมูล ขออนุมัติ ECO Sticker</span>
														<p>
															คู่มือวิธีการลงทะเบียนและยื่นข้อมูล เพื่อขออนุมัติ ECO
															Sticker สาหรับยางรถยนต์ ผ่านเว็บไซต์ tyre.go.th
														</p>
													</div>
												</div>
											</div> */}

											{/* hide */}
											{/* {news.map((data, i) => {
												return (
													<div key={i} className={styles.containerPreviewPdf}>
														<div
															className={styles.boxContentShowPdf}
															onClick={() => this.scroll(data)}
														>
															<div className={styles.boximageContentPdf}>
																<img
																	className={styles.imagePreviewPdf}
																	src={data.image}
																/>
															</div>
															<div className={styles.boxContentPdf}>
																<div style={{ marginBottom: "12px" }}>
																	<Label as="a" tag>
																		ข่าวสาร
																	</Label>
																</div>
																<span>{data.title}</span>
																<p>{data.details}</p>
															</div>
														</div>
													</div>
												);
											})} */}

											{/* <div style={{ display: "flex", flexDirection: "row",flexWrap:'wrap',width:'100%' }}>
													{news.map((data, i) => {
														return (
															<div key={i} style={{ padding: 10 }}>
																<div
																	onClick={() => this.scroll(data)}
																	className={styles.card}
																>
																	<div className={styles.cardActionArea}>
																		<div className={styles.cardMedia}>
																			<img src={data.image} />
																		</div>
																		<div className={styles.cardContent}>
																			<h3>{data.title}</h3>
																			<h4>{data.date}</h4>
																			<p>{data.details}</p>
																		</div>
																	</div>
																</div>
															</div>
														);
													})}
												</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</React.Fragment>
		);
	}
}

export default News;
