import React, { useEffect, useState } from "react";
import axios from "axios";

import { GET_STICKER_DATA_BY_SIZE_ID } from "../router";

// pdf class
import { Font } from "@react-pdf/renderer";
import background_c1 from "../../assets/img/base_stricker-C1.png";
import background_c2 from "../../assets/img/base_stricker-C2.png";
import background_c3 from "../../assets/img/base_stricker-C3.png";
import background_c3_special from "../../assets/img/base_sticker-C3-special.png";
import vector1 from "../../assets/img/vector1.png";
import vector2 from "../../assets/img/vector2.png";
import volume1 from "../../assets/img/volume_1.png";
import volume2 from "../../assets/img/volume_2.png";
import volume3 from "../../assets/img/volume_3.png";
import font from "../../assets/font/RSU_Regular.ttf";
import "../../css/Sticker.css";

// import { customCompany } from "./customCompany";
// import { useQRCode } from 'react-qrcode'
Font.register({ family: "rsu", src: font });
var QRCode = require("qrcode.react");
const StickerDrawer = ({ sizeId }) => {
    const [data, setData] = useState({
        brand_name: "",
        gen_name: "",
        size_text: "",
        rolling_grade: "A",
        rolling_value: 0,
        wet_grip_grade: "A",
        wet_grip_value: 0,
        noise_grade: 2,
        noise_value: 0,
        type_product_name: "",
        size_symbol: "",
        standard1: "",
        standard2: "",
        company_title_name: "",
        qr_value: "google.co.th",
        qr_path: "#",
        isLoading: true,
    });

    useEffect(() => {
        const getData = async (sizeId) => {
            var data = await getStickerData(sizeId);
            data.sticker.sizeId = data.sizeRequest.id;
            data.sticker.backgroundImg = getBackgroundSticker(data.sticker.type_product_name, data.sticker.type_struct_id);
            data.sticker.volumeImg = getVolume(data.sticker.noise_grade);
            setData(data.sticker);
        };

        getData(sizeId);
    }, [sizeId]);

    const getStickerData = async (sizeId) => {
        try {
            let { data, status } = await axios.get(GET_STICKER_DATA_BY_SIZE_ID + sizeId);

            if (status === 200) {
                return data;
            } else {
                return;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getBackgroundSticker = (productName, typeStructID) => {
        switch (productName) {
            case "C1":
                return background_c1;
            case "C2":
                return background_c2;
            case "C3":
                return typeStructID === 2 ? background_c3_special : background_c3;
            default:
                return null;
        }
    };

    const getVolume = (level) => {
        switch (level) {
            case 1:
                return volume1;
            case 2:
                return volume2;
            case 3:
                return volume3;
            default:
                return null;
        }
    };

    // const qrUrl = useQRCode('https://www.1stg.me')
    return (
        <div className="Div-Sticker">
            <div className="ShowSticker-Block_BEV">
                <div
                    id="sticker"
                    className="ShowSticker-Data_BEV"
                >
                    <img
                        src={data.backgroundImg}
                        className="ShowSticker"
                        alt="Sticker"
                    />
                    <div className="header_wrapper">
                        <div className="header_company">
                            <p> ยาง</p>
                        </div>
                        <div className="header_brand">
                            <p>{data.brand_name}</p>
                        </div>
                        <div className="header_gen">
                            <p>{data.gen_name}</p>
                        </div>
                        <div className="header_size">
                            <p>
                                {" "}
                                ขนาด {data.size_text} {data.load_capacity}
                                {data.speed_symbol} {data.special_feature}
                            </p>
                        </div>
                    </div>

                    {!(data.type_product_name === "C3" && data.type_struct_id === 2) && (
                        <div className={`vector_1_wrapper_${data.rolling_grade}`}>
                            <img
                                //  src={() => this.getBackgroundSticker(this.state.type_product_name, this.state.type_struct_id)}
                                src={vector1}
                                className="vector_1"
                                alt="Sticker"
                            ></img>

                            <div className="vector_1_txt">
                                <p>{data.rolling_value}</p>
                            </div>
                        </div>
                    )}

                    <div className={`vector_2_wrapper_${data.wet_grip_grade}`}>
                        <img
                            //  src={() => this.getBackgroundSticker(this.state.type_product_name, this.state.type_struct_id)}
                            src={vector2}
                            className="vector_2"
                            alt="Sticker"
                        />

                        <div className="vector_2_txt">
                            <p>{data.wet_grip_value}</p>
                        </div>
                    </div>

                    {!(data.type_product_name === "C3" && data.type_struct_id === 2) && (
                        <React.Fragment>
                            {data.noise_grade && (
                                <img
                                    src={data.volumeImg}
                                    className="volume"
                                    alt="Sticker"
                                ></img>
                            )}

                            <div className="volume_txt">
                                <p>{data.noise_value}</p>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="tyre_class_wrapper">
                        <div className="tyre_class_left">
                            <p>Tyre Class {data.type_product_name}</p>
                        </div>
                        <div className="tyre_class_right">
                            <p>{data.size_symbol}</p>
                        </div>
                    </div>

                    <div className="standard_top">
                        <p>{data.standard1}</p>
                    </div>

                    <div className="standard_bottom">
                        <p>{data.standard2}</p>
                    </div>

                    {data.company_title_name === "ผลิตโดย" && (
                        <React.Fragment>
                            <div className="produce_by_wrapper">
                                <div className="produce_by_left">
                                    <p>ผลิตโดย</p>
                                </div>

                                <div className="produce_by_right">
                                    <p>
                                        {" "}
                                        {data.company_prefix_th} {data.company_name_th} {data.company_suffix_th}
                                    </p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {data.company_title_name === "นำเข้าโดย" && (
                        <React.Fragment>
                            <div className="produce_by_wrapper">
                                <div className="produce_by_left">
                                    <p>นำเข้าโดย</p>
                                </div>

                                <div className="produce_by_right">
                                    <p>
                                        {" "}
                                        {data.company_prefix_th} {data.company_name_th} {data.company_suffix_th}
                                    </p>
                                </div>
                            </div>

                            <div className="import_by_wrapper">
                                <div className="import_by_left">
                                    <p>ผลิตโดย</p>
                                </div>

                                <div className="import_by_right">
                                    <p> {data.factory_name_en === "" ? data.factory_name_th : data.factory_name_en} </p>
                                </div>
                            </div>

                            <div className="country_wrapper">
                                <div className="country_left">
                                    <p>ประเทศ</p>
                                </div>

                                <div className="country_right">
                                    <p> {data.country}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="QR_wrapper">
                        <div className="QR_txt">
                            <p>ID {data.qr_running}</p>
                            {/* <p>ยาง</p> */}
                        </div>

                        <QRCode
                            renderAs="svg"
                            className="QR"
                            size={80}
                            value={`https://tyre.go.th/company/sticker-preview/${data.sizeId}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StickerDrawer;
