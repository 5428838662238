import React, { Component } from "react";
import "./animation.css";
import {
  Label,
  Segment,
  Header,
  Icon,
  Container,
  Table,
  Divider,
  Menu,
  Button,
  Dropdown,
  Pagination,
  Dimmer,
  Loader,
  Modal,
  Grid,
  Image,
} from "semantic-ui-react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Apiurl,
  Approval_edit,
  Approval_approve,
  Approval_unapprove,
  approve,
  unapprove,
  edit,
  UPLOADFILELICENSE,
  CHECKINSERTLICENSE,
  RENDERLICENSE,
  LOADRENDER,
} from "../../../router";
import ApprovalHeader from "../../ApprovalHeader";
import Iframe from "react-iframe";

const allSizeRow = [
  { key: 1, text: 5, value: 5 },
  { key: 2, text: 10, value: 10 },
  { key: 3, text: 20, value: 20 },
];

const typeCom = ["", "ผลิต", "นำเข้า"];

export default class OldStickerHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      login: true,
      activeItem: "approve",
      data: null,
      rowQty: 10,
      currentPage: 1,
      pageQty: 1,
      loading: true,
      hasError: false,
      activeAPI: approve,
      open: false,
      openAssignModal: false,
      openShowLicense: false,
      dylnamic: [{ id: 0 }],
      dataSign1: [],
      dataSign2: [],
      dataSign3: [],
      AssignRequest: null,
      lastpages: 0,
      file: "",
      Tcheck: false,
      urlmsg: "",
      selector: [],
      license1: [
        {
          person_id: 0,
          start_page: 1,
          end_page: 1,
          request_id: "",
          check: false,
        },
      ],
      license2: [
        {
          index: 1,
          person_id: 0,
          start_page: 0,
          end_page: 0,
          request_id: "",
          check: false,
          checkend: false,
          person: false,
        },
      ],
      license3: [
        {
          person_id: 0,
          start_page: 0,
          end_page: 0,
          request_id: "",
          check: false,
        },
      ],
    };
  }

  componentWillMount = () => {
    if (
      localStorage.getItem("role") !== "0" &&
      localStorage.getItem("role") !== null
    ) {
      this.setState({ login: false });
    } else {
      this.props.history.push("/oie");
    }
  };

  componentDidMount = () => {
    axios
      .get(Approval_approve + "?page=1&row=" + this.state.rowQty)
      .then((res) => {
        this.setState({ data: res.data.resultRequest }, () => {
          let pageQty = res.data["Total Page"];
          this.setState({ pageQty: pageQty });
          setTimeout(() => this.setState({ loading: false }), 500);
        });
      })
      .catch((err) => {
        this.setState({ hasError: true, loading: false });
        console.log(this.state.hasError);
      });
  };

  fileInputRef = React.createRef();

  onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file, this.state.fileId);
    // .then(response => {
    //   console.log(response.data);
    // });
  };

  fileChange = (e, id) => {
    this.setState({ file: e.target.files[0], fileId: id }, () => {});
  };

  // Import datasources/schemas Tab 1
  fileUpload = (file, id) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", id);
    return axios.post(UPLOADFILELICENSE, formData);
  };

  checkupload = (file_path_upload_license) => {
    if (
      file_path_upload_license === "" ||
      typeof file_path_upload_license === "undefined"
    ) {
      return <div></div>;
    } else {
      return (
        <div>
          <div>(อัปโหลดแล้ว)</div>
        </div>
      );
    }
  };

  checkInsertLicense = async (request_id) => {
    await this.setState({ request_id: request_id });
    axios
      .post(CHECKINSERTLICENSE, { requests_id: request_id })
      .then(async (res) => {
        if (res.data.msg === "insert first") {
          this.loaddatatorender(request_id);
          this.setState({ openAssignModal: true });
        } else {
          await this.setState({ openShowLicense: true, urlmsg: res.data.msg });
        }
      });
  };

  formatDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (day < 10) {
      day = "0" + day;
    }

    switch (month) {
      case 1:
        month = "Jan";
        break;
      case 2:
        month = "Feb";
        break;
      case 3:
        month = "Mar";
        break;
      case 4:
        month = "Apr";
        break;
      case 5:
        month = "May";
        break;
      case 6:
        month = "Jun";
        break;
      case 7:
        month = "Jul";
        break;
      case 8:
        month = "Aug";
        break;
      case 9:
        month = "Sep";
        break;
      case 10:
        month = "Oct";
        break;
      case 11:
        month = "Nov";
        break;
      case 12:
        month = "Dec";
        break;
      default:
        break;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var formattedDate =
      " วันที่ " +
      day +
      "-" +
      month +
      "-" +
      (year + 543) +
      "\n เวลา " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;

    return formattedDate;
  };

  addInputAssignPersonRow = () => {
    if (this.state.lastpages !== this.state.dylnamic.length + 2) {
      this.setState({
        license2: [
          ...this.state.license2,
          {
            index: this.state.license2.length,
            person_id: 0,
            start_page: 0,
            end_page: 0,
            check: false,
            checkend: false,
            person: false,
          },
        ],
        dylnamic: [...this.state.dylnamic, { id: 0 }],
      });
    }
  };

  checkInsertLicense = async (request_id) => {
    this.setState({ request_id: request_id });
    axios
      .post(CHECKINSERTLICENSE, { requests_id: request_id })
      .then(async (res) => {
        if (res.data.msg === "insert first") {
          this.loaddatatorender(request_id);
          this.setState({ openAssignModal: true });
        } else {
          this.setState({ openShowLicense: true, urlmsg: res.data.msg });
        }
      });
  };

  Checkvalidate = async () => {
    var validate = true;
    if (this.state.license1[0].person_id == 0) {
      var tmp = this.state.license1;
      tmp[0].check = true;
      this.setState({ license1: tmp });
      validate = false;
    }

    if (this.state.license3[0].person_id == 0) {
      var tmp = this.state.license3;
      tmp[0].check = true;
      this.setState({ license3: tmp });
      validate = false;
    }
    for (let index = 0; index < this.state.license2.length; index++) {
      if (this.state.license2[index].person_id == 0) {
        let cases = this.state.license2;
        cases[index].person = true;
        this.setState({ license2: cases });
        validate = false;
      }
      if (this.state.license2[index].start_page == 0) {
        let cases = this.state.license2;
        cases[index].check = true;
        this.setState({ license2: cases });
        validate = false;
      }
      if (this.state.license2[index].end_page == 0) {
        let cases = this.state.license2;
        cases[index].checkend = true;
        this.setState({ license2: cases });
        validate = false;
      }

      let dataliecnsepage = this.state.license2;
      // console.log(dataliecnsepage[index].start_page);
      // console.log(dataliecnsepage[index].end_page);
      if (dataliecnsepage[index].start_page > dataliecnsepage[index].end_page) {
        // console.log("Hello");
        dataliecnsepage[index].checkend = true;
        this.setState({ license2: dataliecnsepage });
        validate = false;
      }
      let data = this.state.license2;
      if (
        data[index].start_page === data[index].end_page &&
        data[index].start_page !== 0 &&
        data[index].end_page !== 0
      ) {
        dataliecnsepage[index].check = false;
        dataliecnsepage[index].checkend = false;
        this.setState({ license2: dataliecnsepage });
      }

      // console.log(this.state.license2);
      //น้อยกว่าตัวก่อนหน้าไหม
      if (index !== 0) {
        if (
          data[index].start_page <= data[index - 1].start_page ||
          data[index].start_page <= data[index - 1].end_page
        ) {
          dataliecnsepage[index].check = true;
          this.setState({ license2: dataliecnsepage });
          validate = false;
          if (
            data[index].end_page <= data[index - 1].end_page ||
            data[index - 1].end_page <= data[index - 1].start_page
          ) {
            dataliecnsepage[index].checkend = true;
            this.setState({ license2: dataliecnsepage });
          }
        }
      }
    }
    // console.log(this.state);
    return validate;
  };
  setValuePicker = async (e, value, index, type) => {
    switch (type) {
      case "1":
        let tmps = this.state.license2;
        tmps[index].start_page = value.value;
        tmps[index].check = false;
        this.setState({ license2: tmps });
        break;
      case "2":
        let i = this.state.license2;
        i[index].person_id = value.value;
        i[index].person = false;
        this.setState({ license2: i });
        break;
      case "3":
        let datalicense3 = this.state.license3;
        datalicense3[index].person_id = value.value;
        datalicense3[index].check = false;
        this.setState({ license3: datalicense3 });
        break;
      case "4":
        let dataendpage = this.state.license2;
        dataendpage[index].end_page = value.value;
        dataendpage[index].checkend = false;
        this.setState({ license2: dataendpage });
        break;
      case "5":
        let tmp = this.state.license1;
        tmp[0].person_id = value.value;
        tmp[0].request_id = this.state.request_id;
        tmp[0].start_page = 1;
        tmp[0].end_page = 1;
        tmp[0].check = false;
        this.setState({
          license1: tmp,
        });
        break;
      default:
        break;
    }
  };
  defer(callback) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve(callback());
      }, 1000);
    });
  }
  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  Submit = async () => {
    var result = await this.Checkvalidate();

    if (result === false) {
      return;
    } else {
      const data = {
        no1: {
          person_id: this.state.license1[0].person_id,
          request_id: this.state.license1[0].request_id,
          start_page: this.state.license1[0].start_page,
          end_page: this.state.license1[0].end_page,
        },
        no2: this.state.license2,
        no3: {
          person_id: this.state.license3[0].person_id,
          request_id: this.state.license3[0].request_id,
          start_page: 1,
          end_page: 1,
        },
        request_id: this.state.request_id,
      };

      // let result = await axios.post(`http://192.168.250.190:1323` + `/api/v1/approve/render_license`, data)
      // await this.setState({openShowLicense:true,urlmsg:result.data.data.msg,openAssignModal:false})
      await axios
        .post(RENDERLICENSE, data)
        .then(async (res) => {
          await this.setState({ openAssignModal: false });
          this.checkInsertLicense(this.state.request_id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  showLicense = (data) => {
    const { open, closeOnEscape, closeOnDimmerClick } = this.state;

    return (
      <span>
        <Button primary onClick={this.closeConfigShow(false, true)}>
          <Icon name="eye" /> ใบอนุญาต
        </Button>
        <Modal
          open={open}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          onClose={() => this.close}
          closeIcon
        >
          <Header icon="file text" content="Preview" />
          <Modal.Content>
            <Iframe
              url={data.file_path_license}
              width="100%"
              height="800px"
              id="iframe"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="close"
              icon="close"
              onClick={this.close}
              negative
            />
          </Modal.Actions>
        </Modal>
      </span>
    );
  };

  loaddatatorender = async (id) => {
    var arr1 = new Array();
    var arr2 = new Array();
    var arr3 = new Array();
    await axios
      .get(LOADRENDER + id)
      .then(async (res) => {
        this.state.license3[0].start_page = parseInt(
          res.data.PageMaxAndMinFromTyreRequest.MaxPage + 2
        );
        this.state.license3[0].end_page = parseInt(
          res.data.PageMaxAndMinFromTyreRequest.MaxPage + 2
        );
        this.state.license3[0].request_id = id;
        this.state.license1[0].request_id = id;
        this.setState({
          textlimt:
            "(สามารถเลือกได้ตั้งแต่ " +
            res.data.PageMaxAndMinFromTyreRequest.MinPage +
            "ถึง" +
            res.data.PageMaxAndMinFromTyreRequest.MaxPage +
            ")",
          lastpages: res.data.PageMaxAndMinFromTyreRequest.MaxPage + 2,
          request_id: id,
        });

        await res.data.PersonSign.map(async (item) => {
          switch (item.position_sign) {
            case 1:
              item.personel.map((data) => {
                arr1.push({
                  key: data.id,
                  text:
                    data.name_title +
                    data.firstname_th +
                    " " +
                    data.lastname_th,
                  value: data.id,
                });
              });
              break;
            case 2:
              item.personel.map((data) => {
                arr2.push({
                  key: data.id,
                  text:
                    data.name_title +
                    data.firstname_th +
                    " " +
                    data.lastname_th,
                  value: data.id,
                });
              });
              break;
            case 3:
              item.personel.map((data) => {
                arr3.push({
                  key: data.id,
                  text:
                    data.name_title +
                    data.firstname_th +
                    " " +
                    data.lastname_th,
                  value: data.id,
                });
              });
              break;
          }
        });
        var loop =
          res.data.PageMaxAndMinFromTyreRequest.MaxPage -
          res.data.PageMaxAndMinFromTyreRequest.MinPage;
        var Arr = new Array();
        var Item = res.data.PageMaxAndMinFromTyreRequest.MaxPage;
        if (loop == 0) {
          Arr.push({
            key: Item,
            text: Item.toString(),
            value: res.data.PageMaxAndMinFromTyreRequest.MaxPage,
          });
        } else {
          for (let i = 0; i <= loop; i++) {
            let data = res.data.PageMaxAndMinFromTyreRequest.MinPage + i;
            Arr.push({
              text: data.toString(),
              value: data,
              key: Item,
            });
          }
        }
        await this.setState({
          dataSign1: arr1,
          dataSign2: arr2,
          dataSign3: arr3,
          selector: Arr,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  assignPersonToDocument = () => {
    return (
      <span>
        <Modal
          open={this.state.openShowLicense}
          onClose={() => this.setState({ openShowLicense: false })}
          closeIcon={true}
        >
          <Header icon="file text" content="Preview" />
          <Modal.Content>
            <Iframe
              url={Apiurl + this.state.urlmsg}
              width="100%"
              height="800px"
              id="iframe"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="close"
              icon="close"
              onClick={() => this.setState({ openShowLicense: false })}
              negative
            />
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.openAssignModal}
          onClose={() => this.setState({ openAssignModal: false })}
          closeIcon={true}
        >
          <Header icon="file text" content="เลือกคนเซ็นเอกสาร" />
          <Modal.Content>
            <Grid divided="vertically">
              <Grid.Row columns={16}>
                <div class="ui card" style={{ width: "100%" }}>
                  <div class="content">
                    <Grid.Column width={16}>
                      <div class="header">ลำดับที่ 1</div>

                      <div class="ui input">
                        <input
                          type="text"
                          value={"1"}
                          disabled={true}
                          placeholder="หน้าเริ่มต้น"
                        />
                      </div>
                      {" - "}
                      <div class="ui input">
                        <input
                          type="text"
                          value={"1"}
                          disabled={true}
                          placeholder="หน้าสิ้นสุด"
                        />
                      </div>
                      <Dropdown
                        placeholder="เลือกบุคคลเซ็นเอกสาร"
                        selection
                        options={this.state.dataSign1}
                        onChange={(e, value) =>
                          this.setValuePicker(e, value, 0, "5")
                        }
                        style={{
                          margin: "0px 10px 0px 10px",
                          borderColor:
                            this.state.license1[0].check === true ? "red" : "",
                        }}
                      />
                    </Grid.Column>{" "}
                  </div>
                </div>
              </Grid.Row>
              <Grid.Row columns={16}>
                <div class="ui card" style={{ width: "100%" }}>
                  <div class="content">
                    <Grid.Column width={16}>
                      <div>
                        <div class="header">
                          ลำดับที่ 2{" "}
                          <Label style={{ color: "red" }}>
                            {this.state.textlimt}
                          </Label>
                        </div>
                        {this.state.dylnamic.map((item, index) => {
                          return (
                            <div key={index} style={{ marginBottom: "8px" }}>
                              <div class="ui input">
                                {/* <input type="text" id={index.toString()} placeholder="หน้าเริ่มต้น" /> */}
                                <Dropdown
                                  style={{
                                    marginTop: "10px",
                                    borderColor:
                                      this.state.license2[index].check == true
                                        ? "red"
                                        : "",
                                  }}
                                  placeholder="เลือกหน้าเริ่มต้น"
                                  selection
                                  onChange={(e, value) =>
                                    this.setValuePicker(e, value, index, "1")
                                  }
                                  id={
                                    index.toString() +
                                    index.toString() +
                                    index.toString()
                                  }
                                  options={this.state.selector}
                                />
                              </div>
                              {" - "}
                              <div class="ui input">
                                {/* <input type="text" id={index.toString()+index.toString()} placeholder="หน้าสิ้นสุด" /> */}
                                <Dropdown
                                  style={{
                                    marginTop: "10px",
                                    borderColor:
                                      this.state.license2[index].checkend ==
                                      true
                                        ? "red"
                                        : "",
                                  }}
                                  placeholder="เลือกหน้าสุดท้าย"
                                  selection
                                  onChange={(e, value) =>
                                    this.setValuePicker(e, value, index, "4")
                                  }
                                  id={
                                    index.toString() +
                                    index.toString() +
                                    index.toString()
                                  }
                                  options={this.state.selector}
                                />
                              </div>
                              <Dropdown
                                style={{ marginTop: "10px" }}
                                placeholder="เลือกบุคคลเซ็นเอกสาร"
                                selection
                                onChange={(e, value) =>
                                  this.setValuePicker(e, value, index, "2")
                                }
                                id={
                                  index.toString() +
                                  index.toString() +
                                  index.toString()
                                }
                                options={this.state.dataSign2}
                                style={{
                                  margin: "0px 10px 0px 10px",
                                  borderColor:
                                    this.state.license2[index].person == true
                                      ? "red"
                                      : "",
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <Button
                        inverted
                        color="green"
                        class="align center icon"
                        disabled={
                          this.state.lastpages ===
                          this.state.dylnamic.length + 2
                            ? true
                            : false
                        }
                        onClick={() => this.addInputAssignPersonRow()}
                      >
                        +
                      </Button>
                      <br></br>
                    </Grid.Column>
                  </div>
                </div>
              </Grid.Row>
              <Grid.Row columns={16}>
                <div class="ui card" style={{ width: "100%" }}>
                  <div class="content">
                    <Grid.Column width={16}>
                      <div class="header">ลำดับที่ 3</div>
                      <div class="ui input">
                        <input
                          type="text"
                          disabled={true}
                          value={"1"}
                          placeholder="หน้าเริ่มต้น"
                        />
                      </div>
                      {" - "}
                      <div class="ui input">
                        <input
                          type="text"
                          disabled={true}
                          value={"1"}
                          placeholder="หน้าสิ้นสุด"
                        />
                      </div>
                      <Dropdown
                        placeholder="เลือกบุคคลเซ็นเอกสาร"
                        selection
                        onChange={(e, value) =>
                          this.setValuePicker(e, value, 0, "3")
                        }
                        options={this.state.dataSign3}
                        style={{
                          margin: "0px 10px 0px 10px",
                          borderColor:
                            this.state.license3[0].check == true ? "red" : "",
                        }}
                      />
                    </Grid.Column>{" "}
                  </div>
                  <Button color="green" onClick={() => this.Submit()}>
                    Save
                  </Button>
                </div>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      </span>
    );
  };

  showNoData() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "200px",
          paddingBottom: "200px",
        }}
      >
        <i class="big frown outline" style={{ height: "50px" }} />
        <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
      </div>
    );
  }

  changeRowSize = async (e, { name, value }) => {
    await this.setState({
      loading: true,
    });
    await this.setState({ [name]: value }, () => {
      axios
        .get(
          Apiurl +
            this.state.activeAPI +
            this.state.currentPage +
            "&row=" +
            value
        )
        .then((res) => {
          this.setState({ data: res.data.resultRequest }, () => {
            this.setState({ pageQty: res.data["Total Page"] });
            setTimeout(() => this.setState({ loading: false }), 500);
          });
        });
    });
    await this.setState({
      currentPage: 1,
    });
  };

  changePage = async (name, value) => {
    await this.setState({
      loading: true,
    });
    await this.setState({ [name]: value }, () => {
      axios
        .get(
          Apiurl + this.state.activeAPI + value + "&row=" + this.state.rowQty
        )
        .then((res) => {
          this.setState({ data: res.data.resultRequest }, () => {
            let pageQty2 = res.data["Total Page"];
            this.setState({ pageQty: pageQty2 });
            setTimeout(() => this.setState({ loading: false }), 500);
          });
        });
    });
  };

  handleMenuClick = async (e, { id, value }) => {
    await this.setState({
      loading: true,
      data: null,
      activeItem: id,
      rowQty: 10,
      currentPage: 1,
    });
    if (this.state.activeItem === "approve") {
      this.setState({ activeAPI: approve });
      axios
        .get(Approval_approve + "?page=1&row=" + this.state.rowQty)
        .then((res) => {
          this.setState(
            { data: res.data.resultRequest, pageQty: res.data["Total Page"] },
            () => {
              setTimeout(() => this.setState({ loading: false }), 500);
            }
          );
        })
        .catch((err) => {
          this.setState({ hasError: true, loading: false });
          console.log(this.state.hasError);
        });
    } else if (this.state.activeItem === "unapprove") {
      this.setState({ activeAPI: unapprove });
      axios
        .get(Approval_unapprove + "?page=1&row=" + this.state.rowQty)
        .then((res) => {
          this.setState(
            { data: res.data.resultRequest, pageQty: res.data["Total Page"] },
            () => {
              setTimeout(() => this.setState({ loading: false }), 500);
            }
          );
        })
        .catch((err) => {
          this.setState({ hasError: true, loading: false });
          console.log(this.state.hasError);
        });
    } else if (this.state.activeItem === "edit") {
      this.setState({ activeAPI: edit });
      axios
        .get(Approval_edit + "?page=1&row=" + this.state.rowQty)
        .then((res) => {
          this.setState(
            { data: res.data.resultRequest, pageQty: res.data["Total Page"] },
            () => {
              setTimeout(() => this.setState({ loading: false }), 500);
            }
          );
        })
        .catch((err) => {
          this.setState({ hasError: true, loading: false });
          console.log(this.state.hasError);
        });
    }
  };

  showWaiting = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Table.Header
            className="fade-in-bottom ui celled table"
            style={{ textAlign: "center" }}
          >
            <Table.Row>
              <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
              <Table.HeaderCell>Reference Code</Table.HeaderCell>
              <Table.HeaderCell>ชื่อผู้ประกอบการ</Table.HeaderCell>
              <Table.HeaderCell>ประเภท</Table.HeaderCell>
              <Table.HeaderCell>วันที่ทำรายการ</Table.HeaderCell>
              {/* <Table.HeaderCell>สมอ/ศสอ</Table.HeaderCell>
              <Table.HeaderCell>Technical</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                // console.log(data);
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                          {this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <Header as="h4" color="violet">
                              {data.ref_code}
                            </Header>
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{data.companies_name}</Table.Cell>
                        {/* <Table.Cell>{data.factories_name}</Table.Cell> */}
                        <Table.Cell>
                          {typeCom[data.type_factories_id]}
                        </Table.Cell>
                        <Table.Cell>
                          {" "}
                          {this.formatDate(data.created_at)}
                        </Table.Cell>
                        <Table.Cell>
                          {data.approve_oie_tisi === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {data.approve_technical === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };


  showWaitingMobile = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
        
          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                // console.log(data);
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ลำดับ: </span>
                          {this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>Reference code: </span>
                          
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                               <span style={{color:'#5c3dc0'}}>
                              {data.ref_code}
                            </span>
                          </Link>
                        </Table.Cell>
                        <Table.Cell><span style={{fontWeight:'bolder'}}>ผู้ประกอบการ: </span>{data.companies_name}</Table.Cell>
                        {/* <Table.Cell>{data.factories_name}</Table.Cell> */}
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ประเภท: </span>
                          {typeCom[data.type_factories_id]}
                        </Table.Cell>
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>วันที่: </span>
                          {" "}
                          {this.formatDate(data.created_at)}
                        </Table.Cell>
                        <Table.Cell>
                          {data.approve_oie_tisi === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {data.approve_technical === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  showApproveStatus = (data) => {
    if (data === "1") {
      return (
        <span>
          <Label color="yellow" style={{ width: "100%" }}>
            <Icon name="clock outline"></Icon> รอการอนุมัติ
          </Label>
        </span>
      );
    } else if (data === "2") {
      return (
        <span>
          <Label color="green" style={{ width: "100%" }}>
            <Icon name="check"></Icon> อนุมัติแล้ว
          </Label>
        </span>
      );
    }
  };

  showApproved = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Table.Header
            className="fade-in-bottom ui celled table"
            style={{ textAlign: "center" }}
          >
            <Table.Row>
              <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
              <Table.HeaderCell>Reference Code</Table.HeaderCell>
              <Table.HeaderCell>ชื่อผู้ประกอบการ</Table.HeaderCell>
              <Table.HeaderCell>ประเภท</Table.HeaderCell>
              {/* <Table.HeaderCell>รุ่น</Table.HeaderCell> */}
              <Table.HeaderCell>วันที่ทำรายการ</Table.HeaderCell>
              {/* <Table.HeaderCell>ดาวน์โหลดเอกสารใบอนุญาต</Table.HeaderCell> */}
              {/* <Table.HeaderCell>อัปโหลดเอกสารใบอนุญาต</Table.HeaderCell> */}
              {/* <Table.HeaderCell>สมอ/ศสอ</Table.HeaderCell>
              <Table.HeaderCell>Technical</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                          {this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <Header as="h4" color="violet">
                              {data.ref_code}
                            </Header>
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{data.companies_name}</Table.Cell>
                        {/* <Table.Cell>{data.factories_name}</Table.Cell> */}
                        <Table.Cell>
                          {typeCom[data.type_factories_id]}
                        </Table.Cell>
                        {/* <Table.Cell textAlign={'left'}>
                          <Accordion
                          >
                            <Accordion.Title
                              active={this.state.activeIndex === index}
                              index={index}
                              onClick={this.handleAccordionClick}
                            >
                              <Icon name='dropdown' />
                              รายการรุ่น
        </Accordion.Title>
                            <Accordion.Content active={this.state.activeIndex === index}>
                              {data.gen_requests.map((gen) => (
                                <li>{gen.gen_name}</li>
                              ))}

                            </Accordion.Content>


                          </Accordion>
                        </Table.Cell> */}
                        <Table.Cell>
                          {this.formatDate(data.created_at)}
                        </Table.Cell>

                        {/* ดาวน์โหลดเอกสารใบอนุญาต */}

                        {/* <Table.Cell>
                          <Button
                            color="blue"
                            onClick={() => this.checkInsertLicense(data.id)}
                          >
                            ดาวน์โหลด
                          </Button>
                          <Modal
                            open={this.state.openAssignModal}
                            close={() =>
                              this.setState({ openAssignModal: true })
                            }
                            closeIcon={true}
                          >
                            <Header
                              icon="file text"
                              content="เลือกคนเซ็นเอกสาร"
                            />
                            <Modal.Content>
                              <Grid divided="vertically">
                                <Grid.Row columns={16}>
                                  <div
                                    class="ui card"
                                    style={{ width: "100%" }}
                                  >
                                    <div class="content">
                                      <Grid.Column width={16}>
                                        <div class="header">ลำดับที่ 1</div>

                                        <div class="ui input">
                                          <input
                                            type="text"
                                            value={"1"}
                                            disabled={true}
                                            placeholder="หน้าเริ่มต้น"
                                          />
                                        </div>
                                        {" - "}
                                        <div class="ui input">
                                          <input
                                            type="text"
                                            value={"1"}
                                            disabled={true}
                                            placeholder="หน้าสิ้นสุด"
                                          />
                                        </div>
                                        <Dropdown
                                          placeholder="เลือกบุคคลเซ็นเอกสาร"
                                          selection
                                          options={this.state.dataSign1}
                                          onChange={(e, value) =>
                                            this.setValuePicker(
                                              e,
                                              value,
                                              0,
                                              "5"
                                            )
                                          }
                                          style={{
                                            margin: "0px 10px 0px 10px",
                                            borderColor:
                                              this.state.license1[0].check ===
                                              true
                                                ? "red"
                                                : ""
                                          }}
                                        />
                                      </Grid.Column>{" "}
                                    </div>
                                  </div>
                                </Grid.Row>
                                <Grid.Row columns={16}>
                                  <div
                                    class="ui card"
                                    style={{ width: "100%" }}
                                  >
                                    <div class="content">
                                      <Grid.Column width={16}>
                                        <div>
                                          <div class="header">ลำดับที่ 2</div>
                                          {this.state.dylnamic.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  style={{
                                                    marginBottom: "8px"
                                                  }}
                                                >
                                                  <div class="ui input">
                                                  //  <input type="text" id={index.toString()} placeholder="หน้าเริ่มต้น" /> 
                                                    <Dropdown
                                                      style={{
                                                        marginTop: "10px",
                                                        borderColor:
                                                          this.state.license2[
                                                            index
                                                          ].check == true
                                                            ? "red"
                                                            : ""
                                                      }}
                                                      placeholder="เลือกหน้าเริ่มต้น"
                                                      selection
                                                      onChange={(e, value) =>
                                                        this.setValuePicker(
                                                          e,
                                                          value,
                                                          index,
                                                          "1"
                                                        )
                                                      }
                                                      id={
                                                        index.toString() +
                                                        index.toString() +
                                                        index.toString()
                                                      }
                                                      options={
                                                        this.state.selector
                                                      }
                                                    />
                                                  </div>
                                                  {" - "}
                                                  <div class="ui input">
                                                  //  <input type="text" id={index.toString()+index.toString()} placeholder="หน้าสิ้นสุด" /> 
                                                    <Dropdown
                                                      style={{
                                                        marginTop: "10px",
                                                        borderColor:
                                                          this.state.license2[
                                                            index
                                                          ].checkend == true
                                                            ? "red"
                                                            : ""
                                                      }}
                                                      placeholder="เลือกหน้าสุดท้าย"
                                                      selection
                                                      onChange={(e, value) =>
                                                        this.setValuePicker(
                                                          e,
                                                          value,
                                                          index,
                                                          "4"
                                                        )
                                                      }
                                                      id={
                                                        index.toString() +
                                                        index.toString() +
                                                        index.toString()
                                                      }
                                                      options={
                                                        this.state.selector
                                                      }
                                                    />
                                                  </div>
                                                  <Dropdown
                                                    style={{
                                                      marginTop: "10px"
                                                    }}
                                                    placeholder="เลือกบุคคลเซ็นเอกสาร"
                                                    selection
                                                    onChange={(e, value) =>
                                                      this.setValuePicker(
                                                        e,
                                                        value,
                                                        index,
                                                        "2"
                                                      )
                                                    }
                                                    id={
                                                      index.toString() +
                                                      index.toString() +
                                                      index.toString()
                                                    }
                                                    options={
                                                      this.state.dataSign2
                                                    }
                                                    style={{
                                                      margin:
                                                        "0px 10px 0px 10px",
                                                      borderColor:
                                                        this.state.license2[
                                                          index
                                                        ].person == true
                                                          ? "red"
                                                          : ""
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                        <Button
                                          inverted
                                          color="green"
                                          class="align center icon"
                                          disabled={
                                            this.state.lastpages ===
                                            this.state.dylnamic.length + 2
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            this.addInputAssignPersonRow()
                                          }
                                        >
                                          +
                                        </Button>
                                        <br></br>
                                      </Grid.Column>
                                    </div>
                                  </div>
                                </Grid.Row>
                                <Grid.Row columns={16}>
                                  <div
                                    class="ui card"
                                    style={{ width: "100%" }}
                                  >
                                    <div class="content">
                                      <Grid.Column width={16}>
                                        <div class="header">ลำดับที่ 3</div>
                                        <div class="ui input">
                                          <input
                                            type="text"
                                            disabled={true}
                                            value={"1"}
                                            placeholder="หน้าเริ่มต้น"
                                          />
                                        </div>
                                        {" - "}
                                        <div class="ui input">
                                          <input
                                            type="text"
                                            disabled={true}
                                            value={"1"}
                                            placeholder="หน้าสิ้นสุด"
                                          />
                                        </div>
                                        <Dropdown
                                          placeholder="เลือกบุคคลเซ็นเอกสาร"
                                          selection
                                          onChange={(e, value) =>
                                            this.setValuePicker(
                                              e,
                                              value,
                                              0,
                                              "3"
                                            )
                                          }
                                          options={this.state.dataSign3}
                                          style={{
                                            margin: "0px 10px 0px 10px",
                                            borderColor:
                                              this.state.license3[0].check ==
                                              true
                                                ? "red"
                                                : ""
                                          }}
                                        />
                                      </Grid.Column>{" "}
                                    </div>
                                    <Button
                                      color="green"
                                      onClick={() => this.Submit()}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Grid.Row>
                              </Grid>
                            </Modal.Content>
                          </Modal>
                        </Table.Cell>   */}

                        {/* End of ดาวน์โหลดเอกสารใบอนุญาต */}

                        {/* อัปโหลดอกสารใบอนุญาต */}

                        {/* <Table.Cell>
                          <Form onSubmit={this.onFormSubmit}>
                            <Form.Field>
                              <Input
                                ref={this.fileInputRef}
                                type="file"
                                hidden
                                onChange={e => this.fileChange(e, data.id)}
                                style={{ width: 250 }}
                              />
                            </Form.Field>
                            <Button
                              icon="upload"
                              labelPosition="left"
                              type="submit"
                              content="อัปโหลด"
                              primary
                              onClick={() => window.location.reload()}
                            />
                          </Form>
                          {this.checkupload(data.file_path_upload_license)}
                        </Table.Cell> */}

                        {/* End of อัปโหลดเอกสารใบอนุญาต */}
                        {/* <Table.Cell>
                          {data.approve_oie_tisi === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {data.approve_technical === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell> */}
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  showApprovedMobile = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
      
          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ลำดับ: </span>{this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>Reference code: </span>
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                          <span style={{color:'#5c3dc0'}}>
                              {data.ref_code}
                            </span>
                          </Link>
                        </Table.Cell>
                        <Table.Cell><span style={{fontWeight:'bolder'}}>ผู้ประกอบการ: </span>{data.companies_name}</Table.Cell>
                        {/* <Table.Cell>{data.factories_name}</Table.Cell> */}
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ประเภท: </span>{typeCom[data.type_factories_id]}
                        </Table.Cell>
                        {/* <Table.Cell textAlign={'left'}>
                          <Accordion
                          >
                            <Accordion.Title
                              active={this.state.activeIndex === index}
                              index={index}
                              onClick={this.handleAccordionClick}
                            >
                              <Icon name='dropdown' />
                              รายการรุ่น
        </Accordion.Title>
                            <Accordion.Content active={this.state.activeIndex === index}>
                              {data.gen_requests.map((gen) => (
                                <li>{gen.gen_name}</li>
                              ))}

                            </Accordion.Content>


                          </Accordion>
                        </Table.Cell> */}
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>วันที่ทำรายการ: </span>{this.formatDate(data.created_at)}
                        </Table.Cell>

                        {/* ดาวน์โหลดเอกสารใบอนุญาต */}

                        {/* <Table.Cell>
                          <Button
                            color="blue"
                            onClick={() => this.checkInsertLicense(data.id)}
                          >
                            ดาวน์โหลด
                          </Button>
                          <Modal
                            open={this.state.openAssignModal}
                            close={() =>
                              this.setState({ openAssignModal: true })
                            }
                            closeIcon={true}
                          >
                            <Header
                              icon="file text"
                              content="เลือกคนเซ็นเอกสาร"
                            />
                            <Modal.Content>
                              <Grid divided="vertically">
                                <Grid.Row columns={16}>
                                  <div
                                    class="ui card"
                                    style={{ width: "100%" }}
                                  >
                                    <div class="content">
                                      <Grid.Column width={16}>
                                        <div class="header">ลำดับที่ 1</div>

                                        <div class="ui input">
                                          <input
                                            type="text"
                                            value={"1"}
                                            disabled={true}
                                            placeholder="หน้าเริ่มต้น"
                                          />
                                        </div>
                                        {" - "}
                                        <div class="ui input">
                                          <input
                                            type="text"
                                            value={"1"}
                                            disabled={true}
                                            placeholder="หน้าสิ้นสุด"
                                          />
                                        </div>
                                        <Dropdown
                                          placeholder="เลือกบุคคลเซ็นเอกสาร"
                                          selection
                                          options={this.state.dataSign1}
                                          onChange={(e, value) =>
                                            this.setValuePicker(
                                              e,
                                              value,
                                              0,
                                              "5"
                                            )
                                          }
                                          style={{
                                            margin: "0px 10px 0px 10px",
                                            borderColor:
                                              this.state.license1[0].check ===
                                              true
                                                ? "red"
                                                : ""
                                          }}
                                        />
                                      </Grid.Column>{" "}
                                    </div>
                                  </div>
                                </Grid.Row>
                                <Grid.Row columns={16}>
                                  <div
                                    class="ui card"
                                    style={{ width: "100%" }}
                                  >
                                    <div class="content">
                                      <Grid.Column width={16}>
                                        <div>
                                          <div class="header">ลำดับที่ 2</div>
                                          {this.state.dylnamic.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  style={{
                                                    marginBottom: "8px"
                                                  }}
                                                >
                                                  <div class="ui input">
                                                  //  <input type="text" id={index.toString()} placeholder="หน้าเริ่มต้น" /> 
                                                    <Dropdown
                                                      style={{
                                                        marginTop: "10px",
                                                        borderColor:
                                                          this.state.license2[
                                                            index
                                                          ].check == true
                                                            ? "red"
                                                            : ""
                                                      }}
                                                      placeholder="เลือกหน้าเริ่มต้น"
                                                      selection
                                                      onChange={(e, value) =>
                                                        this.setValuePicker(
                                                          e,
                                                          value,
                                                          index,
                                                          "1"
                                                        )
                                                      }
                                                      id={
                                                        index.toString() +
                                                        index.toString() +
                                                        index.toString()
                                                      }
                                                      options={
                                                        this.state.selector
                                                      }
                                                    />
                                                  </div>
                                                  {" - "}
                                                  <div class="ui input">
                                                  //  <input type="text" id={index.toString()+index.toString()} placeholder="หน้าสิ้นสุด" /> 
                                                    <Dropdown
                                                      style={{
                                                        marginTop: "10px",
                                                        borderColor:
                                                          this.state.license2[
                                                            index
                                                          ].checkend == true
                                                            ? "red"
                                                            : ""
                                                      }}
                                                      placeholder="เลือกหน้าสุดท้าย"
                                                      selection
                                                      onChange={(e, value) =>
                                                        this.setValuePicker(
                                                          e,
                                                          value,
                                                          index,
                                                          "4"
                                                        )
                                                      }
                                                      id={
                                                        index.toString() +
                                                        index.toString() +
                                                        index.toString()
                                                      }
                                                      options={
                                                        this.state.selector
                                                      }
                                                    />
                                                  </div>
                                                  <Dropdown
                                                    style={{
                                                      marginTop: "10px"
                                                    }}
                                                    placeholder="เลือกบุคคลเซ็นเอกสาร"
                                                    selection
                                                    onChange={(e, value) =>
                                                      this.setValuePicker(
                                                        e,
                                                        value,
                                                        index,
                                                        "2"
                                                      )
                                                    }
                                                    id={
                                                      index.toString() +
                                                      index.toString() +
                                                      index.toString()
                                                    }
                                                    options={
                                                      this.state.dataSign2
                                                    }
                                                    style={{
                                                      margin:
                                                        "0px 10px 0px 10px",
                                                      borderColor:
                                                        this.state.license2[
                                                          index
                                                        ].person == true
                                                          ? "red"
                                                          : ""
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                        <Button
                                          inverted
                                          color="green"
                                          class="align center icon"
                                          disabled={
                                            this.state.lastpages ===
                                            this.state.dylnamic.length + 2
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            this.addInputAssignPersonRow()
                                          }
                                        >
                                          +
                                        </Button>
                                        <br></br>
                                      </Grid.Column>
                                    </div>
                                  </div>
                                </Grid.Row>
                                <Grid.Row columns={16}>
                                  <div
                                    class="ui card"
                                    style={{ width: "100%" }}
                                  >
                                    <div class="content">
                                      <Grid.Column width={16}>
                                        <div class="header">ลำดับที่ 3</div>
                                        <div class="ui input">
                                          <input
                                            type="text"
                                            disabled={true}
                                            value={"1"}
                                            placeholder="หน้าเริ่มต้น"
                                          />
                                        </div>
                                        {" - "}
                                        <div class="ui input">
                                          <input
                                            type="text"
                                            disabled={true}
                                            value={"1"}
                                            placeholder="หน้าสิ้นสุด"
                                          />
                                        </div>
                                        <Dropdown
                                          placeholder="เลือกบุคคลเซ็นเอกสาร"
                                          selection
                                          onChange={(e, value) =>
                                            this.setValuePicker(
                                              e,
                                              value,
                                              0,
                                              "3"
                                            )
                                          }
                                          options={this.state.dataSign3}
                                          style={{
                                            margin: "0px 10px 0px 10px",
                                            borderColor:
                                              this.state.license3[0].check ==
                                              true
                                                ? "red"
                                                : ""
                                          }}
                                        />
                                      </Grid.Column>{" "}
                                    </div>
                                    <Button
                                      color="green"
                                      onClick={() => this.Submit()}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Grid.Row>
                              </Grid>
                            </Modal.Content>
                          </Modal>
                        </Table.Cell>   */}

                        {/* End of ดาวน์โหลดเอกสารใบอนุญาต */}

                        {/* อัปโหลดอกสารใบอนุญาต */}

                        {/* <Table.Cell>
                          <Form onSubmit={this.onFormSubmit}>
                            <Form.Field>
                              <Input
                                ref={this.fileInputRef}
                                type="file"
                                hidden
                                onChange={e => this.fileChange(e, data.id)}
                                style={{ width: 250 }}
                              />
                            </Form.Field>
                            <Button
                              icon="upload"
                              labelPosition="left"
                              type="submit"
                              content="อัปโหลด"
                              primary
                              onClick={() => window.location.reload()}
                            />
                          </Form>
                          {this.checkupload(data.file_path_upload_license)}
                        </Table.Cell> */}

                        {/* End of อัปโหลดเอกสารใบอนุญาต */}
                        {/* <Table.Cell>
                          {data.approve_oie_tisi === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {data.approve_technical === "2" ? (
                            <Label color="green" style={{ width: "100%" }}>
                              อนุมัติ
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>
                              รอการอนุมัติ
                            </Label>
                          )}
                        </Table.Cell> */}
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  showUnapproved = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Table.Header
            className="fade-in-bottom ui celled table"
            style={{ textAlign: "center" }}
          >
            <Table.Row>
              <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
              <Table.HeaderCell>Reference Code</Table.HeaderCell>
              <Table.HeaderCell>ชื่อผู้ประกอบการ</Table.HeaderCell>
              <Table.HeaderCell>ประเภท</Table.HeaderCell>
              <Table.HeaderCell>วันที่ระงับ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                // console.log(data);
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                          {this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <Header as="h4" color="violet">
                              {data.ref_code}
                            </Header>
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{data.companies_name}</Table.Cell>

                        <Table.Cell>
                          {typeCom[data.type_factories_id]}
                        </Table.Cell>

                        <Table.Cell>
                          {" "}
                          {this.formatDate(data.created_at)}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  showUnapprovedMobile = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
       
          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                // console.log(data);
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ลำดับ: </span>{this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>Reference code: </span>
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <span style={{color:'#5c3dc0'}}>
                              {data.ref_code}
                            </span>
                          </Link>
                        </Table.Cell>
                        <Table.Cell><span style={{fontWeight:'bolder'}}>ผู้ประกอบการ: </span>{data.companies_name}</Table.Cell>

                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ประเภท: </span>{typeCom[data.type_factories_id]}
                        </Table.Cell>

                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>วันที่ระงับ: </span>
                          {this.formatDate(data.created_at)}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  showEdit = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
          <Table.Header
            className="fade-in-bottom ui celled table"
            style={{ textAlign: "center" }}
          >
            <Table.Row>
              <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
              <Table.HeaderCell>Reference Code</Table.HeaderCell>
              <Table.HeaderCell>ชื่อผู้ประกอบการ</Table.HeaderCell>
              <Table.HeaderCell>ประเภท</Table.HeaderCell>
              <Table.HeaderCell>วันที่ส่งแก้ไข</Table.HeaderCell>

              {/* <Table.HeaderCell>Technical</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                // console.log("edit", data);
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                          {this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <Header
                              as="h4"
                              color="violet"
                              style={{ textDecorationLine: "underline" }}
                            >
                              {data.ref_code}
                            </Header>
                          </Link>
                        </Table.Cell>
                        <Table.Cell collapsing>
                          {data.companies_name}
                        </Table.Cell>
                        <Table.Cell>
                          {typeCom[data.type_factories_id]}
                        </Table.Cell>
                        <Table.Cell>
                          {" "}
                          {this.formatDate(data.created_at)}
                        </Table.Cell>

                        {/* <Table.Cell>
                          {data.approve_technical === "4" ? (
                            <Label color="yellow" style={{ width: "100%" }}>
                              รอการแก้ไข
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>รอ</Label>
                          )}
                        </Table.Cell> */}
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  showEditMobile = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else
      return (
        <React.Fragment>
        
          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                // console.log("edit", data);
                if (
                  index >
                    this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ลำดับ: </span>{this.state.rowQty * (this.state.currentPage - 1) +
                            index +
                            1}
                        </Table.Cell>
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>Reference code: </span>
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <span style={{color:'#5c3dc0'}}>
                              {data.ref_code}
                            </span>
                          </Link>
                        </Table.Cell>
                        <Table.Cell >
                        <span style={{fontWeight:'bolder'}}>ผู้ประกอบการ: </span>{data.companies_name}
                        </Table.Cell>
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>ประเภท: </span>{typeCom[data.type_factories_id]}
                        </Table.Cell>
                        <Table.Cell>
                        <span style={{fontWeight:'bolder'}}>วันที่ส่งแก้ไข: </span>
                          {this.formatDate(data.created_at)}
                        </Table.Cell>

                        {/* <Table.Cell>
                          {data.approve_technical === "4" ? (
                            <Label color="yellow" style={{ width: "100%" }}>
                              รอการแก้ไข
                            </Label>
                          ) : (
                            <Label style={{ width: "100%" }}>รอ</Label>
                          )}
                        </Table.Cell> */}
                      </Table.Row>
                    </React.Fragment>
                  );
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  checkShowTable(isMobile = false) {
    if (this.state.activeItem === "waiting") {
      return isMobile ? this.showWaitingMobile(): this.showWaiting();
    } else if (this.state.activeItem === "approve") {
      return isMobile ? this.showApprovedMobile():this.showApproved();
    } else if (this.state.activeItem === "unapprove") {
      return isMobile ? this.showUnapprovedMobile():this.showUnapproved();
    } else if (this.state.activeItem === "edit") {
      return isMobile ? this.showEditMobile() :this.showEdit();
    } else {
      return [];
    }
  }
  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;

    const newIndex = this.state.activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    return (
      <div>
        <ApprovalHeader />
        <div style={{ margin: "15px 10px" }}>
          <Container fluid>
            <Header as="h2" textAlign="left">
              {/* <Icon name="list layout" color="violet" /> */}

              <Image
                src={"/logo-eco.png"}
                style={{
                  // display: "flex",
                  justifyContent: "center",
                  // width: "32px",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                  marginRight: "10px",
                }}
              />

              <span>ฐานข้อมูลเดิม</span>
            </Header>
            <Divider />
            <Grid>
              <Grid.Row only="tablet computer">
                <Grid.Column>
                  <Menu
                    attached="top"
                    icon="labeled"
                    tabular
                    color="violet"
                    size="small"
                  >
                    <Menu.Item
                      id="approve"
                      name="ฐานข้อมูล Eco Sticker"
                      icon="database"
                      active={this.state.activeItem === "approve"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Item
                      id="unapprove"
                      name="สติ๊กเกอร์ที่ถูกระงับ"
                      icon="times"
                      active={this.state.activeItem === "unapprove"}
                      onClick={this.handleMenuClick}
                    />

                    <Menu.Item
                      id="edit"
                      name="แก้ไข"
                      icon="edit"
                      active={this.state.activeItem === "edit"}
                      onClick={this.handleMenuClick}
                    />
                  </Menu>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row only="mobile">
                <Grid.Column>
                  <Menu
                    fluid
                    icon="labeled"
                    color="violet"
                    size="small"
                    vertical
                  >
                    <Menu.Item
                      id="approve"
                      name="ฐานข้อมูล Eco Sticker"
                      icon="database"
                      active={this.state.activeItem === "approve"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Item
                      id="unapprove"
                      name="สติ๊กเกอร์ที่ถูกระงับ"
                      icon="times"
                      active={this.state.activeItem === "unapprove"}
                      onClick={this.handleMenuClick}
                    />

                    <Menu.Item
                      id="edit"
                      name="แก้ไข"
                      icon="edit"
                      active={this.state.activeItem === "edit"}
                      onClick={this.handleMenuClick}
                    />
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Segment attached="bottom"  textAlign="center">
              <Dimmer active={this.state.loading}>
                <Loader size="medium">Loading</Loader>
              </Dimmer>
              <Grid>
                <Grid.Row only='tablet computer'>
                  <Grid.Column>
                    <Table  celled textAlign="center">
                      {this.checkShowTable()}

                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell colSpan="18" textAlign="right">
                            Row
                            <Dropdown
                              placeholder="Row"
                              selection
                              compact
                              name="rowQty"
                              value={this.state.rowQty}
                              options={allSizeRow}
                              onChange={this.changeRowSize}
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                width: 60,
                              }}
                            />
                            Page
                            <Pagination
                              boundaryRange={1}
                              activePage={this.state.currentPage}
                              siblingRange={1}
                              name="currentPage"
                              totalPages={this.state.pageQty}
                              onPageChange={(e, { name, activePage }) =>
                                this.changePage(name, activePage)
                              }
                              style={{ marginLeft: 10, marginRight: 10 }}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row only='mobile'>
                  <Grid.Column>
                    <Table style={{display:'block',}} padded celled textAlign="left">
                      {this.checkShowTable(true)}

                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell colSpan="18" textAlign="right">
                            Row
                            <Dropdown
                              placeholder="Row"
                              selection
                              compact
                              name="rowQty"
                              value={this.state.rowQty}
                              options={allSizeRow}
                              onChange={this.changeRowSize}
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                width: 60,
                              }}
                            />
                            Page
                            <Pagination
                              boundaryRange={0}
                              activePage={this.state.currentPage}
                              siblingRange={0}
                              name="currentPage"
                              totalPages={this.state.pageQty}
                              onPageChange={(e, { name, activePage }) =>
                                this.changePage(name, activePage)
                              }
                              style={{ marginLeft: 10, marginRight: 10 }}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
          {this.assignPersonToDocument()}
        </div>
      </div>
    );
  }
}
