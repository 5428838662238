import React, { Component } from "react";
import axios from "axios";
import {
  Grid,
  Table,
  Icon,
  Segment,
  Header,
  Container,
  Button,
} from "semantic-ui-react";
import { GET_STICKER_DATA_BY_SIZE_ID, HOST_PATH_FILE } from "../router";

// pdf class
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import background_c1 from "../../assets/img/base_stricker-C1.png";
import background_c2 from "../../assets/img/base_stricker-C2.png";
import background_c3 from "../../assets/img/base_stricker-C3.png";
import background_c3_special from "../../assets/img/base_sticker-C3-special.png";
import vector1 from "../../assets/img/vector1.png";
import vector2 from "../../assets/img/vector2.png";
import volume1 from "../../assets/img/volume_1.png";
import volume2 from "../../assets/img/volume_2.png";
import volume3 from "../../assets/img/volume_3.png";
import water_mark from "../../assets/img/watermark.png";
import font from "../../assets/font/RSU_Regular.ttf";
import { customCompany } from "./customCompany";
import StickerDrawer from "./StickerDrawer";
Font.register({ family: "rsu", src: font });

var QRCode = require("qrcode.react");

export default class StickerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublicPreview: true,
      loader_request: true,
      openStickerPreview: true,
      selected_file: "",
      height_div: "",
      width_div: "",
      columnWidth: "10",
      data: {},
      pdf: <div />,
    };

    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    await this.setWidthColoumn();
    var size_id = this.props.match.params.sizeId;

    // ดึงข้อมูลเช็คว่าเป็น URL: sticker-public-preview หรือ sticker-preview
    if (window.location.href.includes("sticker-preview")) {
      await this.setState({ isPublicPreview: false });
    }

    await this.getData(size_id);
  };

  getData = async (sizeId) => {
    await this.setState({
      loader_request: true,
    });

    var data = await this.getStickerData(sizeId);
    data.sticker.sizeId = data.sizeRequest.id;
    await this.setState(data);
    // console.log("state", this.state);

    // console.log("this.state.sticker", this.state.sticker);
    // await this.setState({
    //   pdf: (
    //     <div style={{ width: "500" }}>
    //       {" "}
    //       <PDF data={this.state.sticker} width_div={this.state.width_div} />
    //       {/* <iframe
    //         style={{
    //           width: this.state.width_div,
    //           height: window.innerHeight
    //         }}
    //         src={
    //           HOST_PATH_FILE +
    //           "/static/CompanyData/3f500ad3-64af-4489-91e9-9685177dd1fd/tyre-requests/test-results/b32455b32453b39c64ab8f02d1875224d97cc429"
    //         }
    //       /> */}
    //     </div>
    //   )
    // });

    if (this.state.isPublicPreview) {
      await this.setState({
        pdf: (
          <div style={{ width: "500" }}>
            {" "}
            <PDF data={this.state.sticker} width_div={this.state.width_div} />
            {/* <iframe
              style={{
                width: this.state.width_div,
                height: window.innerHeight
              }}
              src={
                HOST_PATH_FILE + data.tyreRequest.file_path_example_license
              }
            /> */}
          </div>
        ),
      });
    } else {
      await this.setState({
        pdf: (
          <div style={{ width: "500" }}>
            {" "}
            <PDF data={this.state.sticker} width_div={this.state.width_div} />
            {/* <iframe
            title='iframe_1'
              style={{
                width: this.state.width_div,
                height: window.innerHeight
              }}
              src={
                HOST_PATH_FILE + data.tyreRequest.file_path_example_license
              }
            /> */}
          </div>
        ),
      });
    }

    await this.setState({
      loader_request: false,
    });
  };

  getStickerData = async (sizeId) => {
    try {
      let { data, status } = await axios.get(
        GET_STICKER_DATA_BY_SIZE_ID + sizeId
      );
      // console.log("response", data);

      //   localStorage.setItem("sticker", JSON.stringify(data));
      //   let status = 200;
      //   let data = JSON.parse(localStorage.getItem("sticker"));
      // await this.setState(res.data.sticker);
      if (status === 200) {
        return data;
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  downloadFile = (path) => {
    const link = document.createElement("a");

    link.href = HOST_PATH_FILE + path;

    link.setAttribute("download", "request_file" + ".pdf"); //any other extension
    // link.target = "_blank";
    document.body.appendChild(link);

    link.click();
  };
  setWidthColoumn = async () => {
    var isMobile = this.detectMobile();
    var mul;
    var minus = 0;
    if (isMobile) {
      mul = 1;
    } else {
      mul = 2;
      minus = this.state.columnWidth * 2;
    }
    await this.setState({
      width_div: this.tableRef.current.offsetWidth / mul - minus,
    });

    return;
  };

  detectMobile() {
    if (window.innerWidth <= 800) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    var tyreRequest = this.state.tyreRequest;
    var productRequests = this.state.productRequests;
    var brandRequest = this.state.brandRequest;
    var genRequest = this.state.genRequest;
    var sizeRequest = this.state.sizeRequest;
    var testResult = this.state.testResult;
    // console.log("render", this.state);

    // console.log("ok", this.state.pdf);
    return (
      <div>
        <div style={{ margin: 5 }}>
          <Segment color="violet" style={{ padding: "10" }}>
            {/* <Dimmer active={this.state.loader_request}>
              <Loader size="small">Loading</Loader>
            </Dimmer> */}
            {/* Segment all */}
            {/* Segment step */}
            {/* <Header as='h1' textAlign='left'> */}
            {/* <Icon name='users' color='violet' /> */}
            {/* <Image src={headerLogo} size='massive' /> 	ระบบ ECO Sticker Tyre  */}
            {/* </Header> */}
            <Container fluid>
              <br />
              <div ref={this.tableRef}>
                <Grid centered>
                  <Grid.Row>
                    {!this.state.isPublicPreview ? (
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Table celled striped>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan="2" width={1}>
                                <Icon name="user" />
                                ข้อมูลการร้องขอ
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>ประเภทผู้ยื่นขอ</Table.Cell>
                              <Table.Cell>
                                {tyreRequest !== undefined
                                  ? tyreRequest.type_factories.name
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>โรงงาน</Table.Cell>
                              <Table.Cell>
                                {tyreRequest !== undefined
                                  ? tyreRequest.factories.name_th
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>

                        <Table
                          celled
                          striped
                          width="100%"
                          // style={{ margin: "20" }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan="2" width={1}>
                                <Icon name="user" />
                                ข้อมูลผลิตภัณฑ์ <br />
                                {productRequests !== undefined
                                  ? productRequests.products.product_struct
                                  : "-"}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {productRequests !== undefined &&
                              productRequests.license_request && (
                                <Table.Row>
                                  <Table.Cell>
                                    เลขที่ใบอนุญาต มอก.{" "}
                                    {productRequests === undefined
                                      ? ""
                                      : productRequests.products
                                          .type_product_id === 1
                                      ? "2718"
                                      : "2719"}
                                    -2560
                                  </Table.Cell>
                                  <Table.Cell>
                                    {productRequests !== undefined
                                      ? productRequests.license_request
                                      : "-"}
                                  </Table.Cell>
                                </Table.Row>
                              )}

                            {productRequests !== undefined &&
                              productRequests.list_request && (
                                <Table.Row>
                                  <Table.Cell>
                                    หน้าที่ (มอก.{" "}
                                    {productRequests.products
                                      .type_product_id === 1
                                      ? "2718"
                                      : "2719"}
                                    -2560)
                                  </Table.Cell>
                                  <Table.Cell>
                                    {productRequests.list_request}
                                  </Table.Cell>
                                </Table.Row>
                              )}

                            {productRequests !== undefined &&
                              productRequests.file_path_product_request && (
                                <Table.Row>
                                  <Table.Cell colSpan="2">
                                    <button
                                      onClick={() =>
                                        this.downloadFile(
                                          productRequests.file_path_product_request
                                        )
                                      }
                                      class="large ui orange fluid button"
                                    >
                                      {`ดาวน์โหลดใบอนุญาต มอก. ${
                                        productRequests === undefined
                                          ? ""
                                          : productRequests.products
                                              .type_product_id === 1
                                          ? "2718"
                                          : "2719"
                                      }-2560`}
                                    </button>
                                  </Table.Cell>
                                </Table.Row>
                              )}

                            {productRequests !== undefined &&
                              productRequests.license_request_tisi_2721 && (
                                <Table.Row>
                                  <Table.Cell>
                                    เลขที่ใบอนุญาต มอก. 2721-2560
                                  </Table.Cell>
                                  <Table.Cell>
                                    {productRequests !== undefined
                                      ? productRequests.license_request_tisi_2721
                                      : "-"}
                                  </Table.Cell>
                                </Table.Row>
                              )}

                            {productRequests !== undefined &&
                              productRequests.list_request_tisi_2721 && (
                                <Table.Row>
                                  <Table.Cell>
                                    หน้าที่ (มอก. 2721-2560)
                                  </Table.Cell>
                                  <Table.Cell>
                                    {productRequests.list_request_tisi_2721}
                                  </Table.Cell>
                                </Table.Row>
                              )}

                            {productRequests !== undefined &&
                              productRequests.file_path_tisi_2721 && (
                                <Table.Row>
                                  <Table.Cell colSpan="2">
                                    <button
                                      onClick={() =>
                                        this.downloadFile(
                                          productRequests.file_path_tisi_2721
                                        )
                                      }
                                      class="large ui orange fluid button"
                                    >
                                      ดาวน์โหลดใบอนุญาต มอก. 2721-2560
                                    </button>
                                  </Table.Cell>
                                </Table.Row>
                              )}

                            <Table.Row>
                              <Table.Cell colSpan="2" warning>
                                {" "}
                                <Header as="h5">
                                  ชื่อทางการค้า{" "}
                                  {brandRequest !== undefined
                                    ? brandRequest.brands.name
                                    : "-"}
                                </Header>
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell colSpan="2">
                                <Header as="h5">
                                  รุ่น{" "}
                                  {genRequest !== undefined
                                    ? genRequest.gen_name
                                    : "-"}
                                </Header>
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell colSpan="2">
                                <Header as="h5">
                                  ขนาด{" "}
                                  {sizeRequest !== undefined
                                    ? sizeRequest.size_text
                                    : "-"}
                                </Header>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ดัชนีความสามารถการรับโหลด</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.load_capacity
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>สัญลักษณ์ความเร็ว</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.speed_symbol
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>คุณลักษณะพิเศษ</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.special_feature
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>โครงสร้าง</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.size_struct
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ประเภทใช้งาน</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.type_use
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ความกว้างภาคตัดระบุ</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.cutting_width
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>เครื่องหมาย</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.symbol
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>
                                ค่าสัมประสิทธิ์ของความต้านทานการหมุน
                              </Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.rs_test_value +
                                    " " +
                                    testResult.rs_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>
                                ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก
                              </Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.rg_test_value +
                                    " " +
                                    testResult.rg_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>
                                ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน
                              </Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.tr_test_value +
                                    " " +
                                    testResult.tr_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>ค่าดัชนีการเกาะถนนหิมะ</Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.sr_test_value +
                                    " " +
                                    testResult.sr_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    ) : (
                      // INLINE Condition: isPublicPreview is true คือ เชื่อมต่อกับระบบ Car URL: /company/sticker-public-preview/:sizeId
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Table celled striped>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan="2" width={1}>
                                <Icon name="user" />
                                ข้อมูลการร้องขอ
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>ประเภทผู้ยื่นขอ</Table.Cell>
                              <Table.Cell>
                                {tyreRequest !== undefined
                                  ? tyreRequest.type_factories.name
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>โรงงาน</Table.Cell>
                              <Table.Cell>
                                {tyreRequest !== undefined
                                  ? tyreRequest.factories.name_th
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>

                        <Table
                          celled
                          striped
                          width="100%"
                          // style={{ margin: "20" }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan="2" width={1}>
                                <Icon name="user" />
                                ข้อมูลผลิตภัณฑ์ <br />
                                {productRequests !== undefined
                                  ? productRequests.products.product_struct
                                  : "-"}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                เลขที่ใบอนุญาต มอก.
                                {productRequests === undefined
                                  ? ""
                                  : productRequests.products.type_product_id ===
                                    1
                                  ? "2718"
                                  : "2719"}
                                -2560
                              </Table.Cell>
                              <Table.Cell>
                                {productRequests !== undefined
                                  ? productRequests.license_request
                                  : "-"}

                                <Button
                                  icon
                                  floated="right"
                                  labelPosition="left"
                                  color="orange"
                                  size="small"
                                  onClick={() => {
                                    this.setState({
                                      pdf: (
                                        <div style={{ width: "120%" }}>
                                          {" "}
                                          <iframe
                                            title="iframe_2"
                                            style={{
                                              width: "100%",
                                              height: window.innerHeight,
                                            }}
                                            src={
                                              HOST_PATH_FILE +
                                              productRequests.file_path_product_request
                                            }
                                          />
                                        </div>
                                      ),
                                      openStickerPreview: false,
                                    });
                                  }}
                                >
                                  <Icon name="file pdf" />
                                  ใบอนุญาต มอก.{" "}
                                  {productRequests === undefined
                                    ? ""
                                    : productRequests.products
                                        .type_product_id === 1
                                    ? "2718"
                                    : "2719"}
                                  -2560
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                            {productRequests !== undefined &&
                              productRequests.list_request && (
                                <Table.Row>
                                  <Table.Cell>
                                    หน้าที่ (มอก.{" "}
                                    {productRequests.products
                                      .type_product_id === 1
                                      ? "2718"
                                      : "2719"}
                                    -2560)
                                  </Table.Cell>
                                  <Table.Cell>
                                    {productRequests.list_request}
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            <Table.Row>
                              <Table.Cell>
                                เลขที่ใบอนุญาต มอก. 2721-2560
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {productRequests !== undefined &&
                                  (productRequests.license_request_tisi_2721
                                    ? productRequests.license_request_tisi_2721
                                    : "-")}

                                <Button
                                  icon
                                  floated="right"
                                  labelPosition="left"
                                  color="orange"
                                  size="small"
                                  onClick={() => {
                                    this.setState({
                                      pdf: (
                                        <div style={{ width: "120%" }}>
                                          {" "}
                                          <iframe
                                            title="iframe_2"
                                            style={{
                                              width: "100%",
                                              height: window.innerHeight,
                                            }}
                                            src={
                                              HOST_PATH_FILE +
                                              productRequests.file_path_product_request
                                            }
                                          />
                                        </div>
                                      ),
                                      openStickerPreview: false,
                                    });
                                  }}
                                >
                                  <Icon name="file pdf" />
                                  ใบอนุญาต มอก. 2721-2560
                                </Button>
                              </Table.Cell>
                            </Table.Row>

                            {productRequests !== undefined &&
                              productRequests.list_request_tisi_2721 && (
                                <Table.Row>
                                  <Table.Cell>
                                    หน้าที่ (มอก. 2721-2560)
                                  </Table.Cell>
                                  <Table.Cell>
                                    {productRequests.list_request_tisi_2721}
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            <Table.Row>
                              <Table.Cell>แสดงสติ๊กเกอร์</Table.Cell>
                              <Table.Cell
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  icon
                                  labelPosition="left"
                                  color="olive"
                                  size="small"
                                  onClick={() => {
                                    this.setState({
                                      pdf: (
                                        <div style={{ width: "500" }}>
                                          {" "}
                                          <PDF
                                            data={this.state.sticker}
                                            width_div={this.state.width_div}
                                          />
                                        </div>
                                      ),
                                      openStickerPreview: true,
                                    });
                                  }}
                                >
                                  <Icon name="file pdf" />
                                  สติ๊กเกอร์
                                </Button>
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell colSpan="2" warning>
                                {" "}
                                <Header as="h5">
                                  ชื่อทางการค้า{" "}
                                  {brandRequest !== undefined
                                    ? brandRequest.brands.name
                                    : "-"}
                                </Header>
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell colSpan="2">
                                <Header as="h5">
                                  รุ่น{" "}
                                  {genRequest !== undefined
                                    ? genRequest.gen_name
                                    : "-"}
                                </Header>
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell colSpan="2">
                                <Header as="h5">
                                  ขนาด{" "}
                                  {sizeRequest !== undefined
                                    ? sizeRequest.size_text
                                    : "-"}
                                </Header>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ดัชนีความสามารถการรับโหลด</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.load_capacity
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>สัญลักษณ์ความเร็ว</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.speed_symbol
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>คุณลักษณะพิเศษ</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.special_feature
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>โครงสร้าง</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.size_struct
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ประเภทใช้งาน</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.type_use
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ความกว้างภาคตัดระบุ</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.cutting_width
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>เครื่องหมาย</Table.Cell>
                              <Table.Cell>
                                {sizeRequest !== undefined
                                  ? sizeRequest.symbol
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>
                                ค่าสัมประสิทธิ์ของความต้านทานการหมุน
                              </Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.rs_test_value +
                                    " " +
                                    testResult.rs_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>
                                ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก
                              </Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.rg_test_value +
                                    " " +
                                    testResult.rg_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>
                                ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน
                              </Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.tr_test_value +
                                    " " +
                                    testResult.tr_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>ค่าดัชนีการเกาะถนนหิมะ</Table.Cell>
                              <Table.Cell>
                                {testResult !== undefined
                                  ? testResult.sr_test_value +
                                    " " +
                                    testResult.sr_test_unit
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    )}

                    <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={5}
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      {this.state.openStickerPreview ? (
                        <StickerDrawer
                          sizeId={this.props.match.params.sizeId}
                        />
                      ) : (
                        this.state.pdf
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {/* </Ref> */}
              </div>
            </Container>
          </Segment>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'column',
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35
    // backgroundColor: '#E4E4E4'
  },
  header_1: {
    fontFamily: "rsu",
    color: `gray`,
    fontSize: 30,
  },
  watermark: {
    left: "100",
    top: "150",
    height: "150.3mm",
    // hegiht:'100%',
    position: `absolute`,
    zIndex: 30,
  },
  image: {
    // top: `100px`, /* your required value */
    // left: `300`,
    width: "311.81",
    height: "623.62",
    position: `absolute`,
    // height:'100%',
    // width:'100%',
    // margin:'0',
    // padding:'0',
    // zIndex: 15,
    // marginVertical: 15,
    // marginHorizontal: 100,
  },
  textWhite: {
    color: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  wrapper: {
    width: "311.81",
    height: "623",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    padding: "0",
    backgroundColor: "yellow",
  },
  wrapper_blank: {
    width: "110mm",
    height: "220mm",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    // backgroundColor: 'yellow'
    TextAlign: "center",
  },
  logo: {
    overflow: "visible",
    // alignSelf: 'flex-end',
    // backgroundColor: `green`,
    fontFamily: "rsu",
    marginTop: "4mm",
    // paddingTop: '2px',
    color: "blue",
    height: "18mm",
    textAlign: "center",
  },
  logo_txt: {
    lineHeight: "20%",
    overflow: "visible",
    position: "relative",
    top: "20px",
    // backgroundColor: 'blue',
    padding: "0",
    fontSize: 34,
    //34
    color: `red`,
  },
  title: {
    // position: 'relative',
    // top: '20px',
    // lineHeight: "80%",
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: "rsu",
    // marginTop: '2mm',
    height: "31mm",
    textAlign: "center",
    // backgroundColor: `blue`
  },
  sec: {
    fontFamily: "rsu",
    width: "100%",
    height: "167mm",
    border: "2px; solid; palevioletred;",
    // backgroundColor: 'rgba(255,0,0,0) ',
    color: "red",
    textAlign: "center",
    verticalAlign: "middle",
    marginLeft: "auto",
    marginRight: "auto",
    // paddingTop:'50',
    // marginTop: '50',
    // marginBottom: '55mm'
  },
  imageSecTop: {
    display: "flex",
    flexWrap: "wrap",
    width: "103mm", // width of sec - marLeft - marRight = 75 - 5 - 5 = 65
    height: "73mm", // 70% of 110mm is 77m // height - marginTop = height --> 77 - 5 = 72mm
    // backgroundColor: 'orange',
    marginLeft: "4mm",
    marginRight: "4mm",
    marginTop: "2mm",
  },
  imageGasTank: {
    //  backgroundColor: 'green',
    width: "60%",
    height: "100%",
  },
  logoGas: {
    // backgroundColor: 'purple',
    width: "100%",
    height: "20mm", // estimation 29% of height imageSecTop(72) = 20.88
    color: "white",
  },
  wrapSecAndGradArrow: {
    width: "100%",
    height: "52mm", // height imageSecTop - height logoGas = 72 - 20 = 52
    display: "flex",
    flexWrap: "wrap",
  },
  secGrade: {
    paddingTop: "2",
    // backgroundColor: 'red',
    width: "50%",
    height: "100%",
  },
  secArrow: {
    marginTop: "5mm",
    width: "50%",
    height: "46mm",
    // backgroundColor: 'purple',
  },
  spaceLogoGrade: {
    width: "100%",
    height: "7mm",
    // backgroundColor: 'silver'
  },
  wrapGrade: {
    width: "100%",
    height: "5mm",
    marginBottom: "1mm",
    // backgroundColor: 'cream'
  },
  gasGradeA: {
    width: "20%",
    height: "100%",
    backgroundColor: "#2E8B57",
    // border-left: 20px solid #58ff00;
    // border-top: 20px solid #00800000;
    // border-bottom: 20px solid #00800000;
  },
  gasGradeB: {
    width: "50%",
    height: "100%",
    backgroundColor: "#32CD32",
  },
  gasGradeC: {
    width: "60%",
    height: "100%",
    backgroundColor: "#ADFF2F",
  },
  gasGradeD: {
    width: "70%",
    height: "100%",
    backgroundColor: "#FFFF00",
  },
  gasGradeE: {
    width: "80%",
    height: "100%",
    backgroundColor: "#FFD700",
  },
  gasGradeF: {
    width: "90%",
    height: "100%",
    backgroundColor: "##FF6347",
  },
  gasGradeG: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FF0000",
  },
  logoSlippery: {
    // backgroundColor: 'brown',
    width: "100%",
    height: "20mm", // estimation 25% of height imageSecTop = 18
    color: "white",
  },
  imageSlippery: {
    //  backgroundColor: 'pink',
    width: "39%",
    height: "100%",
  },
  imageSecBottom: {
    display: "flex",
    flexWrap: "wrap",
    height: "24mm",
    width: "71mm", // 30% of 110mm is 33m // height - marginTop - height Of <div> image Flag = height --> 33 - 2 - 9 = 22mm
    // backgroundColor: 'black',
    marginLeft: "36mm",
    marginRight: "3mm",
    // marginTop: '2mm'
  },
  volumeSec: {
    width: "50%",
    height: "100%",
    // backgroundColor: 'green',
  },
  volumeValueSec: {
    width: "50%",
    height: "100%",
    // backgroundColor: 'pink',
  },
  tyreClassWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "7mm",
    // backgroundColor: 'green',
    // opacity: '0.5'
  },
  tyreClass: {
    color: "black",
    fontSize: "12",
    textAlign: "left",
    width: "78%", // Equal
    height: "7mm",
    // backgroundColor: 'red',
    marginLeft: "3mm",
    marginBottom: "2mm",
  },

  tyreModel: {
    color: "black",
    fontSize: "12",
    textAlign: "right",
    width: "16%", // Equal
    height: "7mm",
    // backgroundColor: 'blue',
  },
  standardWrapper: {
    display: "flex",
    flexWrap: "wrap",

    width: "100%",
    height: "60mm",
    // backgroundColor: 'red',
  },
  qrWrapper: {
    flexWrap: "wrap",
    marginLeft: "4",
    width: "31%",
    height: "100%",
    // backgroundColor: 'gray'
  },
  qrTopSection: {
    width: "95%",
    height: "56%",
    // backgroundColor: 'green',

    verticalAlign: "text-bottom",
  },
  qrTextSection: {
    position: "relative",
    top: "2",
    fontSize: "9",
    color: "black",

    width: "50%",
    // backgroundColor: 'black',
    marginTop: "1",
    marginLeft: "18",
  },
  qrBottomSection: {
    width: "52%",
    height: "30%",
    // backgroundColor: "yellow",
    // marginTop: '12',
    marginLeft: "18",
    // verticalAlign: 'text-bottom'
  },

  standardDetailWrapper: {
    position: "relative",
    top: "-5px",
    padding: "15",
    fontSize: "16",
    color: "black",
    textAlign: "left",
    width: "67%",
    height: "100%",
    // backgroundColor: 'yellow',
    marginBottom: "4",
  },
  importBy: {
    display: "flex",
    flexWrap: "wrap",
    // height: "30%",
    height: "auto",
    width: "95%",
    marginTop: "1",
    // backgroundColor: 'black'
  },
  importTitle: {
    height: "100%",
    width: "30%",
    // backgroundColor: 'red',
  },
  importValue: {
    marginLeft: "5",
    height: "100%",
    width: "65%",
    // backgroundColor: 'red',
  },
  produceBy: {
    // display: 'flex',
    // flexWrap: 'wrap',
    height: "30%",
    width: "95%",
    marginTop: "10",
    // backgroundColor: 'black'
  },
  importbyWrapper: {
    display: "flex",
    flexWrap: "wrap",
    // height: "48%",
    height: "auto",
    maxHeight: "34%",
    width: "100%",
    // backgroundColor: 'blue'
  },
  productbyWrapper: {
    display: "flex",
    flexWrap: "wrap",
    height: "32%",
    // height:"auto",
    width: "100%",
    // backgroundColor: 'blue'
  },
  produceTitle: {
    height: "100%",
    width: "30%",
    // backgroundColor: 'red',
  },
  produceValue: {
    // marginTop: "3",
    marginLeft: "5",
    height: "100%",
    width: "65%",
    // backgroundColor: 'red',
  },
  vector1: {
    position: "relative",
    top: "20px",
  },
});

// const blank_page = (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.wrapper_blank}>
//         <Text>Generate..</Text>
//       </View>
//     </Page>
//   </Document>
// );

class PDF extends Component {
  constructor(props) {
    super(props);
    this.pdfLinkRef = React.createRef();
    this.state = {
      brand_name: "",
      gen_name: "",
      size_text: "",
      rolling_grade: "A",
      rolling_value: 0,
      wet_grip_grade: "A",
      wet_grip_value: 0,
      noise_grade: 2,
      noise_value: 0,
      type_product_name: "",
      size_symbol: "",
      standard1: "",
      standard2: "",
      company_title_name: "",
      qr_value: "google.co.th",
      qr_path: "#",
    };
  }

  componentWillMount = async () => {
    // console.log("will Mount");
    // console.log("props", this.props.data);
    // console.log("before", this.state);
    await this.setState(this.props.data);
    // await this.setState({sizeId : this.props.sizeId});
    // await this.setState({qr_value : 'localhost:3000/company/sticker-preview/'+this.props.id})
    // console.log("after", this.state);
    // console.log('sizeIDProps',this.props.id)
  };
  componentDidMount = async () => {
    const canvas = document.getElementById("qr1");
    const qrURL = canvas.toDataURL();
    await this.setQrPath(qrURL);
    // this.setState({ qr_path: qrURL })
    // let res = await axios.post(`http://192.168.250.178:1323/api/v1/requesting/componentDidMount`, {
    //   url: qrURL
    // });

    // await this.setState({ qr_path: res.data.value })
    // await this.setState({ qr_path: qrURL })

    // console.log("canvas", qrURL);
    // console.log(" PDF state", this.state);
  };
  componentWillReceiveProps = async (nextProps) => {
    await this.setState(nextProps.data);

    const canvas = document.getElementById("qr1");
    const qrURL = canvas.toDataURL();
    await this.setQrPath(qrURL);
  };

  setQrPath = (url) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // console.log('wake')
        this.setState({ qr_path: url });
        resolve("success");
      }, 1000);
    });
  };

  calVolume = (vol) => {
    var res;
    switch (vol) {
      case 1:
        res = volume1;
        break;
      case 2:
        res = volume2;
        break;
      case 3:
        res = volume3;
        break;
      default:
        res = "//:0";
    }
    return res;
  };

  calPosition = (grade) => {
    var pos = "";
    switch (grade) {
      case "A":
        pos = -4;
        break;
      case "B":
        pos = 13;
        break;
      case "C":
        pos = 30;
        break;
      case "D":
        pos = 47;
        break;
      case "E":
        pos = 64;
        break;
      case "F":
        pos = 81;
        break;
      case "G":
        pos = 98;
        break;
      default:
        pos = 0;
    }
    return pos;
  };

  calPositionB = (grade) => {
    var pos = "";
    switch (grade) {
      case "A":
        pos = 3;
        break;
      case "B":
        pos = 21;
        break;
      case "C":
        pos = 38;
        break;
      case "D":
        pos = 55;
        break;
      case "E":
        pos = 73;
        break;
      case "F":
        pos = 90;
        break;
      case "G":
        pos = 106;
        break;
      default:
        pos = 0;
    }
    return pos;
  };

  isEngChar = (word) => {
    var english = /^[A-Za-z0-9&,.() ]*$/;
    for (let index = 0; index < word.length; index++) {
      // console.log(word[index])
      if (!english.test(word[index])) {
        return false;
      }
    }
    return true;
  };

  calLenghtWord = () => {
    let brandFontSize;

    let importCompany =
      this.state.company_prefix_th +
      " " +
      this.state.company_name_th +
      " " +
      this.state.company_suffix_th;

    let { part_one, part_two, importFontSize, productFontSize } = customCompany(
      importCompany,
      this.state.company_name_th,
      this.state.companyId,
      this.state.factory_name_en,
      this.state.factory_name_th
    );

    if (this.state.brand_name.length >= 45) {
      // 20 คือตัวอักษรยาวเกิน 45
      brandFontSize = 30;
    } else if (this.state.brand_name.length.length > 17) {
      // 24 คือยาวเกิน 13
      brandFontSize = 24;
    } else {
      // 30 คือยาวใน 1 บรรทัด
      brandFontSize = 30;
    }

    return {
      part_one,
      part_two,
      importFontSize,
      productFontSize,
      brandFontSize,
    };
  };

  render() {
    const styles_vector = StyleSheet.create({
      vector1: {
        // marginLeft:'5px',// height:'30%',
        position: "relative",
        top: this.calPosition(this.state.rolling_grade),
        left: "1.995px",
      },
      vector2: {
        position: "relative",
        top: this.calPositionB(this.state.wet_grip_grade),
      },
      vector1_txt: {
        fontSize: 22,
        color: "white",
        fontFamily: "rsu",
        position: "relative",
        top: this.calPosition(this.state.rolling_grade) - 34,
        left: 20,
      },
      vector2_txt: {
        fontSize: 18,
        color: "white",
        fontFamily: "rsu",
        position: "relative",
        top: this.calPositionB(this.state.wet_grip_grade) - 25,
        left: 12,
      },
      volume: {
        position: "relative",
        top: 22,
        left: 17,
        width: "20%",
      },
    });

    let {
      part_one,
      part_two,

      importFontSize,
      productFontSize,
      brandFontSize,
    } = this.calLenghtWord();
    // console.log(this.state.brand_name);
    const doc = (
      <Document>
        <Page size={[311.81, 623.62]} style={styles.page}>
          <View style={styles.wrapper}>
            <Image
              style={styles.image}
              src={
                this.state.type_product_name === "C1"
                  ? background_c1
                  : this.state.type_product_name === "C2"
                  ? background_c2
                  : this.state.type_product_name === "C3" &&
                    this.state.type_struct_id !== 2
                  ? background_c3
                  : background_c3_special
              }
            />
            <View style={styles.logo}>
              <Text style={styles.logo_txt}>ยาง</Text>
            </View>
            <View style={styles.title}>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    fontFamily: "rsu",
                    color: `gray`,
                    fontSize: brandFontSize,
                  }}
                >
                  {this.state.brand_name}
                </Text>
              </View>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    position: "relative",
                    // top: "0px",
                    // top: "-15px"
                    fontSize: 18,
                  }}
                >
                  {this.state.gen_name}
                </Text>
              </View>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    position: "relative",
                    // top: "-8px"
                    // top :"-25px"
                  }}
                >
                  ขนาด {this.state.size_text} {this.state.load_capacity}
                  {this.state.speed_symbol} {this.state.special_feature}
                </Text>
              </View>
            </View>
            <View style={styles.sec}>
              <View style={styles.imageSecTop}>
                <View style={styles.imageGasTank}>
                  <View style={styles.logoGas}>
                    {/* <Text>Logo Gas</Text> */}
                  </View>
                  <View style={styles.wrapSecAndGradArrow}>
                    <View style={styles.secGrade}>
                      <View style={styles.spaceLogoGrade} />
                      {/* <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeA}>
                                                </View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeB}>
                                                </View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeC}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeD}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeE}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeF}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeG}></View>
                                            </View> */}
                      <View style={styles.spaceLogoGrade} />
                    </View>
                    <View style={styles.secArrow}>
                      {!(
                        this.state.type_product_name === "C3" &&
                        this.state.type_struct_id === 2
                      ) && (
                        <React.Fragment>
                          <Image style={styles_vector.vector1} src={vector1} />
                          <Text style={styles_vector.vector1_txt}>
                            {this.state.rolling_value}
                          </Text>
                        </React.Fragment>
                      )}
                    </View>
                  </View>
                </View>
                <View style={styles.imageSlippery}>
                  <View style={styles.logoSlippery}>
                    {/* <Text>Logo Slippery</Text> */}
                  </View>
                  {/* <View style={styles.spaceLogoGrade}></View> */}

                  <View style={styles.wrapSecAndGradArrow}>
                    <View style={styles.secGrade} />
                    <View style={styles.secArrow}>
                      <Image style={styles_vector.vector2} src={vector2} />
                      <Text style={styles_vector.vector2_txt}>
                        {this.state.wet_grip_value}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.imageSecBottom}>
                {!(
                  this.state.type_product_name === "C3" &&
                  this.state.type_struct_id === 2
                ) && (
                  <React.Fragment>
                    <View style={styles.volumeSec}>
                      <Image
                        style={styles_vector.volume}
                        src={this.calVolume(this.state.noise_grade)}
                      />
                    </View>
                    <View style={styles.volumeValueSec}>
                      <Text
                        style={{
                          color: "white",
                          position: "relative",
                          top: 24,
                          left: 16,
                        }}
                      >
                        {this.state.noise_value}
                      </Text>
                    </View>
                  </React.Fragment>
                )}
              </View>

              <View style={styles.tyreClassWrapper}>
                <View style={styles.tyreClass}>
                  <Text>Tyre Class {this.state.type_product_name}</Text>
                </View>
                <View style={styles.tyreModel}>
                  <Text>{this.state.size_symbol}</Text>
                </View>
              </View>
              <View style={styles.standardWrapper}>
                <View style={styles.qrWrapper}>
                  <View style={styles.qrTopSection} />
                  <View style={styles.qrTextSection}>
                    <Text>ID {this.state.qr_running} </Text>
                  </View>
                  <View style={styles.qrBottomSection}>
                    <Image
                      // style={styles.watermark}
                      src={this.state.qr_path}
                    />
                    {/* <QRCode renderAs='svg' value="http://facebook.github.io/react/" /> */}
                  </View>
                </View>
                <View style={styles.standardDetailWrapper}>
                  <Text>{this.state.standard1}</Text>
                  <Text>{this.state.standard2}</Text>
                  <View style={styles.importBy}>
                    {this.state.company_title_name === "ผลิตโดย" ? (
                      <View>
                        <View style={styles.importbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: importFontSize }}>
                              <Text>{this.state.company_prefix_th}</Text>
                              <Text> {part_one}</Text>
                              <Text>{part_two} </Text>
                              <Text>{this.state.company_suffix_th}</Text>
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>
                              {/* ผลิตโดย */}
                            </Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {/* {this.state.factory_name_en} */}
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            {/* <Text style={{ fontSize: 13 }}>ประเทศ</Text> */}
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {/* {(this.state.country).toUpperCase()} */}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <View>
                        <View style={styles.importbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>นำเข้าโดย</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: importFontSize }}>
                              <Text>{this.state.company_prefix_th}</Text>
                              <Text> {part_one}</Text>
                              <Text>{part_two} </Text>
                              <Text>{this.state.company_suffix_th}</Text>
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: productFontSize }}>
                              {this.state.factory_name_en === ""
                                ? this.state.factory_name_th
                                : this.state.factory_name_en}
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>ประเทศ</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {this.state.country.toUpperCase()}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                  {/* <View style={styles.produceBy}>
                                        <View style={styles.productbyWrapper}>
                                            <View style={styles.produceTitle}>
                                                <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                                            </View>
                                            <View style={styles.produceValue}>
                                                <Text style={{ fontSize: 13 }}>company</Text>
                                            </View>
                                        </View>

                                        <View style={styles.productbyWrapper}>
                                            <View style={styles.produceTitle}>
                                                <Text style={{ fontSize: 13 }}>ประเทศ</Text>
                                            </View>
                                            <View style={styles.produceValue}>
                                                <Text style={{ fontSize: 13 }}>company</Text>
                                            </View>
                                        </View>


                                    </View> */}
                </View>
              </View>
            </View>
          </View>
          {!(this.state.request_status === 2) ? (
            <Image style={styles.watermark} src={water_mark} />
          ) : null}
          {/* <Image style={styles.watermark} src={water_mark} /> */}
        </Page>
      </Document>
    );

    // // console.log('pdf blob', pdf(doc).toBlob())
    return (
      <div>
        {
          /Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) && (
            <PDFDownloadLink document={doc} fileName="somename.pdf">
              {({ blob, url, loading, error }) =>
                loading ? (
                  <button disabled class="huge ui orange fluid loading button">
                    Loading...
                  </button>
                ) : (
                  <button class="huge ui orange fluid button">
                    แสดง Sticker
                  </button>
                )
              }
            </PDFDownloadLink>
          )

          // : (
          //   <PDFViewer style={{ width: "100%", height: "100vh" }}>
          //     {doc}
          //   </PDFViewer>
          // )
        }

        <QRCode
          id="qr1"
          value={
            "https://tyre.go.th/company/sticker-preview/" + this.state.sizeId
          }
          style={{ display: "none" }}
        />
      </div>
    );
  }
}
