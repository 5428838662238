import React, { useState } from 'react';
import { Segment, Divider, Header, Icon, Container, Form, Button, Input, Message, Transition } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Headers from '../header/headerUser';
import { GET_PERSON_BY_USERNAME, RECOVER_PASSWORD_BY_PERSON_ID } from '../router'



const RecoveryPassword = () => {
	const [taxID, setTaxID] = useState('')
	const [taxIDStamp, setTaxIDStamp] = useState('')
	const [personID, setPersonID] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [emailOptions, setEmailOptions] = useState([])


	const checkTaxID = async () => {
		setIsLoading(true)
		try {
			const { data: { value } } = await axios.get(`${GET_PERSON_BY_USERNAME}/${taxID}`)
			const emails = value.map(val => {
				var regex = /[\w.!#$%&'*+=?^`{}|~-]{4}(?=@)/
				const email = val.email.replace(regex, "****")
				return {
					key: val.id,
					text: email,
					value: val.id
				}
			})
			setEmailOptions(emails)
			setTaxIDStamp(taxID)

		} catch (e) {
			setIsError(true)
		}
		setIsLoading(false)



	}

	const submitRecover = async () => {
		setIsLoading(true)

		try {
			await axios.put(`${RECOVER_PASSWORD_BY_PERSON_ID}/${personID}`)

			Swal.fire({
				allowOutsideClick: false,
				type: 'success',
				title: 'ทำรายการสำเร็จ',
				text: 'Link สำหรับ reset รหัสผ่านจะถูกส่งไปยังอีเมล์ที่ระบุ',
				showConfirmButton: false,
				timer: 2400
			}).then(() => {
				window.location.href = '/'
			})
		} catch (e) {
			Swal.fire({
				type: 'error',
				title: 'เกิดข้อผิดพลาด!',
				text: 'ไม่สามารถทำรายการได้',
				showConfirmButton: false,
				timer: 1300
			})
		}
		setIsLoading(false)

	}

	return (
		<div>
			<Headers />
			<Container style={{ padding: '50px 0px' }}>
				<Segment loading={isLoading} raised padded='very' color='violet' style={{ padding: '60px' }}>
					<Header>
						<h1>
							<Icon color='violet' name='key' />กู้คืนรหัสผ่าน
							</h1>
					</Header>
					<Divider />
					<Transition visible={isError} animation='scale' duration={500}>
						<Message negative>
							<Message.Header>ไม่พบข้อมูล Tax ID </Message.Header>
							<p>กรุณาตรวจสอบใหม่อีกครั้ง</p>
						</Message>
					</Transition>

					<Form
						size={'big'}
					// onSubmit={() => { }}
					>
						<Form.Field>
							<label>Tax ID</label>
							<Input
								placeholder='กรุณาระบุ Tax ID'
								onChange={(_, { value }) => {
									if (taxIDStamp) {
										setTaxIDStamp('')
										setPersonID('')
									}
									setIsError(false)
									setTaxID(value)
								}}
								action={{
									type: 'button',
									color: 'violet',
									onClick: () => {
										checkTaxID()
									},
									disabled: isLoading || taxIDStamp,
									content: 'ตรวจสอบ',
									// disabled: (getValues(name) === '')
								}}
							/>
						</Form.Field>
						<Transition visible={taxIDStamp} animation='scale' duration={500}>
							<div>
								<Form.Field>
									<label>รายการ E-mail</label>
									<Dropdown
										placeholder='กรุณาเลือก E-mail'
										fluid
										selection
										options={emailOptions}
										onChange={(_, { value }) => setPersonID(value)}
										value={personID}
									/>

								</Form.Field>
								<Button
									disabled={!personID}
									size='large'
									floated='right'
									content='ยืนยันการกู้คืนรหัสผ่าน'
									onClick={submitRecover}
									positive />
							</div>
						</Transition>




					</Form>
				</Segment>
			</Container >
		</div >
	);
}


export default RecoveryPassword
