import React, { useState, useEffect } from 'react';
import { Segment, Divider, Header, Icon, Container, Form, Button, Label, Input, Message, Transition } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Headers from '../header/headerUser';
import { CHECK_IS_PASSWORD_CAN_RESET, RESET_PASSWORD } from '../router'
import { useParams } from "react-router-dom";


const ResetPassword = () => {
	const [canReset, setCanReset] = useState(undefined)
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const { id } = useParams()

	useEffect(() => {
		const checkID = async () => {
			try {
				setIsLoading(true)
				const { data: { is_password_changed } } = await axios.get(`${CHECK_IS_PASSWORD_CAN_RESET}/${id}`)
				if (!is_password_changed) {
					throw 401
				}
				setCanReset(true)

			} catch (e) {
				// console.log(e)
				// Swal.fire({
				// 	type: 'error',
				// 	title: 'เกิดข้อผิดพลาด!',
				// 	text: 'ไม่สามารถเปลี่ยนรหัสผ่านได้',
				// 	showConfirmButton: false,
				// 	timer: 1300
				// }).then(() => window.location.href = '/')
				setCanReset(false)

			}
			setIsLoading(false)
		}

		checkID()

	}, [])
	const submitRecover = async () => {
		
		if (password !== confirmPassword) {
			setIsError(true)
			return
		}
		setIsLoading(true)
		try {
			await axios.put(`${RESET_PASSWORD}/${id}`,
				{
					password: password
				})

			Swal.fire({
				allowOutsideClick: false,
				type: 'success',
				title: 'ทำการเปลี่ยนรหัสผ่านสำเร็จ',
				showConfirmButton: false,
				timer: 2400
			}).then(() => {
				window.location.href = '/'
			})
		} catch (e) {
			Swal.fire({
				type: 'error',
				title: 'เกิดข้อผิดพลาด!',
				text: 'ไม่สามารถทำรายการได้',
				showConfirmButton: false,
				timer: 1300
			})
		}
		setIsLoading(false)

	}

	return (
		<div>
			<Headers />
			<Container style={{ padding: '50px 0px' }}>
				{canReset ?
					<Segment loading={isLoading} raised padded='very' color='violet' style={{ padding: '60px' }}>
						<Header>
							<h1>
								<Icon color='violet' name='key' />กู้คืนรหัสผ่าน
							</h1>
						</Header>
						<Divider />
						<Transition visible={isError} animation='scale' duration={500}>
							<Message negative>
								<Message.Header>รหัสผ่านไม่ตรงกัน </Message.Header>
								<p>กรุณาตรวจสอบใหม่อีกครั้ง</p>
							</Message>
						</Transition>

						<Form
							size={'big'}
						// onSubmit={() => { }}
						>
							<Form.Field error={isError}>
								<label>รหัสผ่านใหม่</label>
								<Input
									type='password'
									placeholder='กรอกรหัสผ่านใหม่'
									onChange={(_, { value }) => {
										setPassword(value)
										setIsError(false)
									}}
								/>
							</Form.Field>

							<Form.Field error={isError}>
								<label>ยืนยันรหัสผ่านใหม่</label>
								<Input
									type='password'
									placeholder='ยืนยันรหัสผ่านใหม่ โดยกรอกให้ตรงกับรหัสผ่านด้านบน'
									onChange={(_, { value }) => {
										setConfirmPassword(value)
										setIsError(false)
									}}
								/>
							</Form.Field>

							<Button
								disabled={!password || !confirmPassword}
								loading={isLoading}
								size='large'
								floated='right'
								content='เปลี่ยนรหัสผ่าน'
								onClick={submitRecover}
								positive
							/>

						</Form>
					</Segment>
					: canReset === false
					&&
					<Message size='huge' negative>
						<Message.Header>ระบบไม่สามารถทำรายการได้</Message.Header>
						<p>กรุณาตรวจสอบข้อมูลอีกครั้ง</p>
					</Message>

				}
			</Container >
		</div >
	);
}


export default ResetPassword
