import React, { useState, useEffect } from "react";
import styles from "./css/PopupNews.module.scss";
import NewsContent from "./NewsContent";

const PopupNews = ({ data, activePopup = false, onClose }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(activePopup);
  }, [activePopup]);


  return (
    <div
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
      }}
      className={styles.overlay}
    >
      <NewsContent data={data} onClose={onClose} />
    </div>
  );
};

export default PopupNews;
