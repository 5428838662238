import React, { Component } from "react";
import "../list/tisi_oie/animation.css";
import Swal from "sweetalert2";
import {
  Label,
  Segment,
  Header,
  Icon,
  Container,
  Table,
  Divider,
  Menu,
  Button,
  Dropdown,
  Pagination,
  Modal,
  Grid,
  Dimmer,
  Loader,

} from "semantic-ui-react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Apiurl,
  finance_incomplete,
  finance_complete,
  finance_approve,
  finance_disapprove,
  LOADRENDER,
} from "../../router";
import ApprovalHeader from "../ApprovalHeader.jsx";
import Iframe from "react-iframe";

const allSizeRow = [
  { key: 1, text: 5, value: 5 },
  { key: 2, text: 10, value: 10 },
  { key: 3, text: 20, value: 20 },
];

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      activeItem: "unpaid",
      data: null,
      rowQty: 5,
      currentPage: 1,
      pageQty: 1,
      loading: true,
      hasError: false,
      activeAPI: finance_incomplete,
      open: false,
      openAssignModal: false,
      openShowLicense: false,
      dylnamic: [{ id: 0 }],
      dataSign1: [],
      dataSign2: [],
      dataSign3: [],
      AssignRequest: null,
      lastpages: 0,
      file: "",
      Tcheck: false,
      urlmsg: "",
      selector: [],
      activePage: 1,
      license1: [
        {
          person_id: 0,
          start_page: 1,
          end_page: 1,
          request_id: "",
          check: false,
        },
      ],
      license2: [
        {
          index: 1,
          person_id: 0,
          start_page: 0,
          end_page: 0,
          request_id: "",
          check: false,
          checkend: false,
          person: false,
        },
      ],
      license3: [
        {
          person_id: 0,
          start_page: 0,
          end_page: 0,
          request_id: "",
          check: false,
        },
      ],
    };
  }

  componentWillMount = async () => {
    if (localStorage.getItem("name") !== null) {
      this.setState({ login: false });
    } else if (localStorage.getItem("role") !== "0") {
      this.props.history.push("/oie");
    }
  };

  loaddatatorender = async id => {
    var arr1 = [];
    var arr2 = [];
    // var arr3 = new Array();
    var arr3 = [];
    await axios
      .get(LOADRENDER + id)
      .then(async res => {
   
        this.state.license3[0].start_page = parseInt(
          res.data.PageMaxAndMinFromTyreRequest.MaxPage + 2
        );
        this.state.license3[0].end_page = parseInt(
          res.data.PageMaxAndMinFromTyreRequest.MaxPage + 2
        );
        this.state.license3[0].request_id = id;
        this.state.license1[0].request_id = id;
        this.setState({
          textlimt:
            "(สามารถเลือกได้ตั้งแต่ " +
            res.data.PageMaxAndMinFromTyreRequest.MinPage +
            "ถึง" +
            res.data.PageMaxAndMinFromTyreRequest.MaxPage +
            ")",
          lastpages: res.data.PageMaxAndMinFromTyreRequest.MaxPage + 2,
          request_id: id,
        });
        
        await res.data.PersonSign.map(async item => {
          switch (item.position_sign) {
            case 1:
              item.personel.map(data => {
                return arr1.push({
                  key: data.id,
                  text:
                    data.name_title +
                    data.firstname_th +
                    " " +
                    data.lastname_th,
                  value: data.id,
                });
              });
              break;
            case 2:
              item.personel.map(data => {
                return arr2.push({
                  key: data.id,
                  text:
                    data.name_title +
                    data.firstname_th +
                    " " +
                    data.lastname_th,
                  value: data.id,
                });
              });
              break;
            case 3:
              item.personel.map(data => {
                return arr3.push({
                  key: data.id,
                  text:
                    data.name_title +
                    data.firstname_th +
                    " " +
                    data.lastname_th,
                  value: data.id,
                });
              });
              break;
            default:
              break
          }
        });
        var loop =
          res.data.PageMaxAndMinFromTyreRequest.MaxPage -
          res.data.PageMaxAndMinFromTyreRequest.MinPage;
        var Arr = [];
        var Item = res.data.PageMaxAndMinFromTyreRequest.MaxPage;
        if (loop === 0) {
          Arr.push({
            key: Item,
            text: Item.toString(),
            value: res.data.PageMaxAndMinFromTyreRequest.MaxPage,
          });
        } else {
          for (let i = 0; i <= loop; i++) {
            let data = res.data.PageMaxAndMinFromTyreRequest.MinPage + i;
            Arr.push({
              text: data.toString(),
              value: data,
              key: Item,
            });
          }
        }
        await this.setState({
          dataSign1: arr1,
          dataSign2: arr2,
          dataSign3: arr3,
          selector: Arr,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  componentDidMount = async () => {
    await this.setState({ data: [] });
    axios
      .get(finance_incomplete + "?page=1&row=5")
      .then(res => {

        this.setState(
          { data: res.data.resultRequest, pageQty: res.data["Total Page"] },
          () => {
            
            setTimeout(() => this.setState({ loading: false }), 800);
          }
        );
      })
      .catch(err => {
        this.setState({ hasError: true, loading: false });
      });
  };

  fileInputRef = React.createRef();

  onFormSubmit = e => {
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file);
    // .then(response => {
    //   console.log(response.data);
    // });
  };

  fileChange = e => {
    this.setState({ file: e.target.files[0] }, () => {
    });
  };

  // Import datasources/schemas Tab 1
  fileUpload = file => {
    // const url = "/some/path/to/post";
    const formData = new FormData();
    formData.append("file", file);
    // const config = {
    //   headers: {
    //     "Content-type": "multipart/form-data",
    //   },
    // };
    // console.log("uploaded", file);
  };

  showUploadButtonLicense = data => {
    return (
      <Button color="green" style={{ width: "100%" }}>
        <Icon name="upload" /> อัปโหลดใบอนุญาตจริง
      </Button>
    );
  };

  showNoData() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "200px",
          paddingBottom: "200px",
        }}
      >
        <i class="big frown outline" style={{ height: "50px" }} />
        <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
      </div>
    );
  }

  changeRowSize = (e, { name, value }) =>
    this.setState({ [name]: value }, () => {
      axios
        .get(
          Apiurl +
          this.state.activeAPI +
          "?page=" +
          this.state.currentPage +
          "&row=" +
          value
        )
        .then(res => {
          this.setState({ data: res.data.resultRequest }, () => {
            this.setState({ pageQty: res.data["Total Page"] });
            setTimeout(() => this.setState({ loading: false }), 1000);
          });
        });
    });

  changePage = (name, value) =>
    this.setState({ [name]: value }, () => {
      this.setState({ loading: true });
      axios
        .get(
          this.state.activeAPI + "?page=" + value + "&row=" + this.state.rowQty
        )
        .then(res => {
          this.setState({ activePage: value });
          this.setState({ data: res.data.resultRequest }, () => {
            this.setState({ pageQty: res.data["Total Page"] });
            setTimeout(() => this.setState({ loading: false }), 1000);
          });
        });
    });

  handleMenuClick = async (e, { id, value }) => {
    await this.setState({
      loading: true,
      data: null,
      activeItem: id,
      rowQty: 5,
    });
    if (this.state.activeItem === "unpaid") {
      axios
        .get(Apiurl + finance_incomplete + "?page=1&row=" + this.state.rowQty)
        .then(res => {
          this.setState(
            { data: res.data.resultRequest, pageQty: res.data["Total Page"] },
            () => {
              this.setState({ activeAPI: finance_incomplete });
              setTimeout(() => this.setState({ loading: false }), 1000);
             
            }
          );
        })
        .catch(err => {
          this.setState({ hasError: true, loading: false });
          console.log(this.state.hasError);
        });
    } else if (this.state.activeItem === "paid") {
      axios
        .get(finance_complete + "?page=1&row=" + this.state.rowQty)
        .then(res => {
          this.setState(
            { data: res.data.resultRequest, pageQty: res.data["Total Page"] },
            () => {
              this.setState({ activeAPI: finance_complete });
              setTimeout(() => this.setState({ loading: false }), 1000);
              
            }
          );
        })
        .catch(err => {
          this.setState({ hasError: true, loading: false });
          console.log(this.state.hasError);
        });
    }
   
  };

  showPaymentStatus = status => {
    if (status === 5) {
      return (
        <span>
          <Label color="yellow" style={{ width: "100%" }}>
            รอการชำระเงิน
          </Label>
        </span>
      );
    } else if (status === 6) {
      return (
        <span>
          <Label color="green" style={{ width: "100%" }}>
            ชำระเงินแล้ว
          </Label>
        </span>
      );
    }
  };

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true });
  };

  close = () => this.setState({ open: false });

  closeAssignModal = () => this.setState({ openAssignModal: false });

  showBillUpload = data => {
    const { open, closeOnEscape, closeOnDimmerClick } = this.state;
    if (data.payment_status === 5) {
      return (
        <span>
          <Label color="yellow" style={{ width: "100%" }}>
            รออัปโหลด
          </Label>
        </span>
      );
    } else if (data.payment_status === 6 || data.payment_status === 7) {
      return (
        <span>
          <Button color="teal" onClick={this.closeConfigShow(false, true)}>
            <Icon name="file" /> ใบเสร็จ
          </Button>
          <Modal
            open={open}
            closeOnEscape={closeOnEscape}
            closeOnDimmerClick={closeOnDimmerClick}
            onClose={this.close}
            closeIcon
          >
            <Header icon="file text" content="Preview" />
            <Modal.Content>
              <Iframe
                url={data.file_path_bill}
                width="100%"
                height="800px"
                id="iframe"
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="close"
                icon="close"
                onClick={this.close}
                negative
              />
            </Modal.Actions>
          </Modal>
        </span>
      );
    }
  };

  showLicense = data => {
    const { open, closeOnEscape, closeOnDimmerClick } = this.state;
   
    return (
      <span>
        <Button primary onClick={this.closeConfigShow(false, true)}>
          <Icon name="eye" /> ใบอนุญาต
        </Button>
        <Modal
          open={open}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          onClose={() => this.close}
          closeIcon
        >
          <Header icon="file text" content="Preview" />
          <Modal.Content>
            <Iframe
              url={data.file_path_license}
              width="100%"
              height="800px"
              id="iframe"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="close"
              icon="close"
              onClick={this.close}
              negative
            />
          </Modal.Actions>
        </Modal>
      </span>
    );
  };

  showApprovedPayment = data => {
    if (data.payment_status === 5) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Button color="green" onClick={() => this.approve(data.id)}>
              <Icon name="check" /> อนุมัติ
            </Button>
            <Button color="red" onClick={() => this.disapprove(data.id)}>
              <Icon name="close" /> ไม่อนุมัติ
            </Button>
          </Grid.Column>
        </Grid.Row>
      );
    } else if (data.payment_status === 6) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Button color="" disabled>
              <Icon name="check" /> อนุมัติ
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button color="" disabled>
              <Icon name="close" /> ไม่อนุมัติ
            </Button>
          </Grid.Column>
        </Grid.Row>
      );
    }
  };
  disapprove = data => {
    Swal.fire({
      title: "ยืนยันที่จะทำขั้นตอนนี้?",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    })
      .then(res => {
        if (res.value) {
          axios
            .put(Apiurl + finance_disapprove, {
              request_id: data.id,
              payment_note: res.value,
            })
            .then(() => {
              Swal.fire({
                position: "center",
                type: "success",
                title: "ไม่อนุมัติสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(err => {
              console.log(err);
            })
            .then(() => new Promise(resolve => setTimeout(resolve, 1600)))
            .then(res => {
              this.props.history.push("/approval/payment");
            })
            .catch(err => {
              console.log(err);
            });
        } else if (res.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      })
      .catch(err => {
        console.log("failed" + err);
      });
  };

  approve = id => {
    Swal.fire({
      title: "ยืนยันที่จะทำขั้นตอนนี้?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
    })
      .then(res => {
        if (res.value) {
          axios
            .put(Apiurl + finance_approve, {
              request_id: id,
            })
            .then(res => {
              Swal.fire({
                position: "center",
                type: "success",
                title: "อนุมัติสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(err => {
              console.log(err);
            })
            .then(() => new Promise(resolve => setTimeout(resolve, 1600)))
            .then(res => {
              this.props.history.push("/approval/payment");
            })
            .catch(err => {
              console.log("approve failed" + err);
            });
        } else if (res.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  orderItem = index => {
    let ordered =
      parseInt(this.state.rowQty) * (parseInt(this.state.activePage) - 1) +
      (index + 1);
    return ordered;
  };

  showUnpaid = () => {
    // let start_pagenumber = this.state.currentPage * this.state.rowQty - this.state.rowQty;
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <Dimmer active>
          <Loader size="medium">Loading</Loader>
        </Dimmer>
      );
    } else
      return (
        <React.Fragment>
          <Table.Header className="fade-in-bottom ui celled table">
            <Table.Row textAlign="center">
              <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
              <Table.HeaderCell>Reference Code</Table.HeaderCell>
              <Table.HeaderCell>บริษัท</Table.HeaderCell>
              <Table.HeaderCell>โรงงาน</Table.HeaderCell>
              <Table.HeaderCell collapsing>ประเภท</Table.HeaderCell>
              <Table.HeaderCell>ใบเสร็จ</Table.HeaderCell>
              <Table.HeaderCell>ดำเนินการ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                if (
                  index >
                  this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>{this.orderItem(index)}</Table.Cell>
                        <Table.Cell >
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <Header as="h4" color="violet">
                              {data.ref_code}
                            </Header>
                          </Link>
                        </Table.Cell>
                        <Table.Cell collapsing>
                          {data.companies_name}
                        </Table.Cell>
                        <Table.Cell>{data.factories_name}</Table.Cell>
                        <Table.Cell>{data.type_factories_id}</Table.Cell>

                        <Table.Cell>{this.showBillUpload(data)}</Table.Cell>
                        <Table.Cell>
                          {this.showApprovedPayment(data)}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                } else {
                  return null
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  showPaid = () => {
    if (this.state.hasError === true && this.state.data === null) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <Icon name="frown outline icon" size="massive" />

          <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <Dimmer active>
          <Loader size="medium">Loading</Loader>
        </Dimmer>
      );
    } else
      return (
        <React.Fragment>
          <Table.Header className="fade-in-bottom ui celled table">
            <Table.Row textAlign="center">
              <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
              <Table.HeaderCell>Reference Code</Table.HeaderCell>
              <Table.HeaderCell>บริษัท</Table.HeaderCell>
              <Table.HeaderCell>โรงงาน</Table.HeaderCell>
              <Table.HeaderCell collapsing>ประเภท</Table.HeaderCell>
              <Table.HeaderCell>สมอ/ศสอ</Table.HeaderCell>
              <Table.HeaderCell>Technical</Table.HeaderCell>
              <Table.HeaderCell>ใบเสร็จ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.data &&
              this.state.data.map((data, index) => {
                if (
                  index >
                  this.state.rowQty * (this.state.currentPage - 1) - 1 ||
                  index < this.state.rowQty * this.state.currentPage
                ) {
                  return (
                    <React.Fragment key={index}>
                      <Table.Row className="fade-in-bottom">
                        <Table.Cell>{this.orderItem(index)}</Table.Cell>
                        <Table.Cell >
                          <Link
                            to={{
                              pathname: "/approval/product_detail",
                              state: {
                                id: data.id,
                                company: data.companies_name,
                                factory: data.factories_name,
                                entrepreneur: data.entrepreneur,
                                ref_code: data.ref_code,
                                approve_oie_tisi: data.approve_oie_tisi,
                                approve_oie_tisi_at: data.approve_oie_tisi_at,
                                approve_oie_tisi_by: data.approve_oie_tisi_by,
                                cancel_oie_tisi_note: data.cancel_oie_tisi_note,
                                approve_technical: data.approve_technical,
                                approve_technical_at: data.approve_technical_at,
                                approve_technical_by: data.approve_technical_by,
                                created_at: data.created_at,
                              },
                            }}
                          >
                            <Header as="h4" color="violet">
                              {data.ref_code}
                            </Header>
                          </Link>
                        </Table.Cell>
                        <Table.Cell collapsing>
                          {data.companies_name}
                        </Table.Cell>
                        <Table.Cell>{data.factories_name}</Table.Cell>
                        <Table.Cell>{data.type_factories_id}</Table.Cell>
                        <Table.Cell>{data.approved_oie_tisi_at}</Table.Cell>
                        <Table.Cell collapsing>
                          {data.approved_technical_at}
                        </Table.Cell>
                        <Table.Cell>{this.showBillUpload(data)}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                } else {
                  return null
                }
              })}
          </Table.Body>
        </React.Fragment>
      );
  };

  checkShowTable() {
    if (this.state.activeItem === "unpaid") {
      return this.showUnpaid();
    } else if (this.state.activeItem === "paid") {
      return this.showPaid();
    } else {
      return [];
    }
  }

  render() {
    return (
      <div>
        <ApprovalHeader />
        <div style={{ margin: 30 }}>
          <Container fluid>
            <Header as="h2" textAlign="left">
              <Icon name="money bill alternate outline" color="violet" />
              รายการการชำระเงิน
            </Header>
            <Divider />

            <Menu
              attached="top"
              icon="labeled"
              tabular
              color="violet"
              size="small"
            >
              <Menu.Item
                id="unpaid"
                name="ยังไม่ชำระเงิน"
                icon="wait"
                active={this.state.activeItem === "unpaid"}
                onClick={this.handleMenuClick}
              />
              <Menu.Item
                id="paid"
                name="ชำระเงินแล้ว"
                icon="check"
                active={this.state.activeItem === "paid"}
                onClick={this.handleMenuClick}
              />
              <Menu.Menu position="right" style={{ marginBottom: 10 }}>
                <Button color="violet">Export Data</Button>
              </Menu.Menu>
            </Menu>
            {/* <Segment attached="bottom" padded="very" textAlign="center">
              <Table
                padded
                celled
                textAlign="center"
                className="fade-in-bottom"
              > */}
            <Segment attached="bottom" padded="very" textAlign="center">
              <Table padded celled textAlign="center">
                {this.checkShowTable()}

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="9" textAlign="right">
                      Row
                      <Dropdown
                        placeholder="Row"
                        selection
                        compact
                        name="rowQty"
                        value={this.state.rowQty}
                        options={allSizeRow}
                        onChange={this.changeRowSize}
                        style={{ marginLeft: 10, marginRight: 10, width: 60 }}
                      />
                      Page
                      <Pagination
                        boundaryRange={1}
                        activePage={this.state.currentPage}
                        siblingRange={1}
                        name="currentPage"
                        totalPages={this.state.pageQty}
                        onPageChange={(e, { name, activePage }) =>
                          this.changePage(name, activePage)
                        }
                        style={{ marginLeft: 10, marginRight: 10 }}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
          </Container>
        </div>
      </div>
    );
  }
}
