import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./animation.css";
import { Header, Icon, Container, Divider, Button, Grid, Image, Input, Menu, Popup } from "semantic-ui-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HOST_PATH_FILE, deletedOieData, exportApprovedDataTyre, exportSummarySticker, getApprovedDataTyre } from "../../../router";
import ApprovalHeader from "../../ApprovalHeader";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
import OiePagination from "../../../OiePagination";
import MainTable from "../../../MainTable";
import { debounce } from "debounce";
import style from "./styleHome.module.css";
import "moment/locale/th";
import ModalEditData from "../../../company/ModalEditData";
import styles from "./HomeV2Func.module.scss";

moment.locale("th");

const fnMakeId = () => {
	var text = `tires_data_${moment().format("DD_MM_YYYY")}_`;
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for (var i = 0; i < 7; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

	return text;
};

export const COLORS = [
	"#ff5400",
	"#03045e",
	"#2d6a4f",
	"#9e0059",
	"#073b4c",
	"#e13700",
	"#006d75",
	"#320a28",
	"#10239e",
	"#ad6800",
	"#d00000",
];

export const PopupTrigger = () => {
	return (
		<Button
			size="tiny"
			fluid
		>
			ดูเพิ่มเติม
		</Button>
	);
};

const Home = () => {
	const [is_exporting, setIsExporting] = useState(false);
	const { search } = useLocation();

	const [show_delete_request_btn, setShowDeleteRequestBTN] = useState(false);
	const [open_popup, setOpenPopup] = useState(false);
	const [info, setInfo] = useState({});
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);

	const [page, setPage] = useState(searchParams.get("page") || 1);
	const [rows_per_page, setRowsPerPage] = useState(searchParams.get("rows_per_page") || 10);
	const [search_txt, setSearchTxt] = useState(searchParams.get("search") || "");
	const [search_check, setSearchCheck] = useState(searchParams.get("search") || "");

	const [data, setData] = useState(null);
	const [product_id_request, setProductIdRequest] = useState();
	const [open_modal, setOpenModal] = useState(false);
	const [key, setKey] = useState();
	const history = useHistory();
	const [btnExportLoading, setBtnExportLoading] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("role") !== "0" && localStorage.getItem("role") !== null) {
		} else {
			history.push("/oie");
		}
	}, []);

	useEffect(() => {
		fnGetApprovedData();
	}, [page, rows_per_page, search_txt]);

	const fnGetApprovedData = async () => {
		searchParams.set("page", page);
		searchParams.set("rows_per_page", rows_per_page);
		searchParams.set("search", search_txt);
		history.replace({ search: searchParams.toString() });
		setData([]);
		try {
			const { data } = await getApprovedDataTyre({
				row: rows_per_page,
				page: page,
				search: search_txt,
				tab: "approved",
			});
			setInfo(data.data?.info);
			setData(data.data?.tyre_requests_list);
		} catch (error) {}
	};

	const fnDeletedOieData = async (id) => {
		try {
			const result = await deletedOieData(id);
			Swal.fire({
				icon: "success",
				title: "ดำเนินการเสร็จสิ้น",
				showConfirmButton: false,
				timer: 1000,
			});
			fnGetApprovedData();
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "ผิดพลาด",
				showConfirmButton: false,
				timer: 1000,
				titleText: error.message,
			});
		}
	};

	const fnSearchDebounce = useCallback(
		debounce((value) => {
			setSearchTxt(value);
			setPage(1);
		}, 1000),
		[]
	);

	const fnHandleChangePage = (newPage) => {
		setPage(newPage);
	};

	const fnHandleChangeRowsPerPage = (value) => {
		setPage(1);
		setRowsPerPage(value);
	};

	const fnProductColor = (requests) => {
		return requests?.map((product, index) => {
			return {
				product_id: product.product_requests_id,
				color: COLORS[index],
			};
		});
	};

	const fnShowListData = (request, keyName, totals = 3) => {
		return request.slice(0, totals).map((data, index) => (
			<div
				key={index}
				style={{ color: COLORS[index], padding: 5, textAlign: "left", cursor: "pointer" }}
				onClick={() => {
					setProductIdRequest(data.product_requests_id);
					setKey(index);
					setOpenModal(true);
				}}
				className={styles.productRow}
			>
				<span>{data[keyName]}</span>
				<Button
					size="tiny"
					className={styles.button}
					color="green"
				>
					เอกสาร มอก.
				</Button>
			</div>
		));
	};

	const ContentPopup = ({ moreData, title = "" }) => {
		const content_result = moreData.map((data) => {
			return data;
		});
		content_result.unshift(<div>{title}</div>);

		return <div>{content_result}</div>;
	};

	const fnGroupProductList = ({ request, keyName, totals = 3, productColor, type = "main" }) => {
		return request.map((data, index) => {
			if (type === "main") {
				if (index >= totals) return null;
			} else {
				if (index < totals) return null;
			}

			const data_with_color = productColor.map((item) => {
				if (item.product_id === data.product_requests_id) {
					return (
						<div style={{ display: "flex", textAlign: "left", alignItems: "center", padding: 5 }}>
							<div style={{ color: item.color }}>{data[keyName]}</div>
						</div>
					);
				}
			});
			return data_with_color;
		});
	};

	const handleExportExcelSummarySticker = async () => {
        setBtnExportLoading(true);
		try {
			let response = await exportSummarySticker();
			const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = downloadUrl;
			link.setAttribute("download", "สรุปจำนวนสติ๊กเกอร์ตามบริษัท.xlsx");
			document.body.appendChild(link);
			link.click();
            setBtnExportLoading(false);
		} catch (error) {
            setBtnExportLoading(false);
            console.log(error);
        }
	};

	const fnHandleExportData = async () => {
		try {
			setIsExporting(true);
			const { data } = await exportApprovedDataTyre();
			const downloadUrl = `${HOST_PATH_FILE}${data.path}`;
			const fakeLink = document.createElement("a");
			fakeLink.style.display = "none";
			fakeLink.download = `${fnMakeId()}`;
			fakeLink.href = downloadUrl;

			document.body.appendChild(fakeLink);
			fakeLink.click();
			document.body.removeChild(fakeLink);
		} catch (error) {
			console.log(error);
		}

		setIsExporting(false);
	};

	const columns = [
		{
			label: "ลำดับ",
			styleHead: {
				width: 50,
				minWidth: 50,
				whiteSpace: "nowarp",
			},
			style: {
				textAlign: "center",
			},
			format: ({ index }) => rows_per_page * (page - 1) + (index + 1),
		},
		{
			label: "Reference Code",
			styleHead: {
				width: 80,
				minWidth: 80,
				whiteSpace: "nowrap",
			},
			style: {
				textAlign: "center",
				whiteSpace: "nowrap",
			},
			format: ({ data }) => {
				return (
					<Link
						to={{
							pathname: "/approval/product_detail",
							state: {
								id: data.tyre_requests_id,
								company: data.companies_name,
								factory: data.factories_name,
								entrepreneur: data.entrepreneur,
								ref_code: data.ref_code,
								approve_oie_tisi: data.approve_oie_tisi,
								approve_oie_tisi_at: data.approved_oie_tisi_at,
								// approve_oie_tisi_by: data.approved_oie_tisi_by,
								cancel_oie_tisi_note: data.cancel_oie_tisi_note,
								approve_technical: data.approve_technical,
								approve_technical_at: data.approved_technical_at,
								// approve_technical_by: data.approve_technical_by,
								created_at: data.created_at,
							},
						}}
					>
						{data.ref_code}
					</Link>
				);
			},
		},
		{
			label: "บริษัท",
			id: "companies_name",
			styleHead: {
				width: 180,
				minWidth: 180,
				whiteSpace: "nowrap",
			},
			style: {
				textAlign: "left",
			},
		},
		{
			label: "โรงงาน",
			styleHead: {
				width: 300,
				minWidth: 300,
				whiteSpace: "nowrap",
			},
			alignHead: "center",
			format: ({ data }) => {
				return (
					<div style={{ display: "flex", flexDirection: "column" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 8, textAlign: "left" }}>{data.factories_name}</div>
							<div className={style.typeFactoryCard}>{data.type_factories}</div>
						</div>
						{data.factories_address && (
							<div className={style.cardAddress}>
								<div style={{ fontSize: "0.8rem" }}>ที่อยู่ :</div>
								<div style={{ fontSize: "0.8rem", textAlign: "start" }}>{data.factories_address}</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			label: "ผลิตภัณท์",
			styleHead: {
				width: 300,
				minWidth: 300,
				whiteSpace: "nowrap",
			},
			style: {
				padding: 0,
			},
			format: ({ data }) => {
				const keys = "product_name";
				const request = data.product_requests;
				const product_name = fnShowListData(data.product_requests, keys);

				const see_more = request.slice(3).map((data, index) => (
					<div
						key={index + 3}
						style={{ color: COLORS[index + 3], padding: 5, cursor: "pointer" }}
						onClick={() => {
							setProductIdRequest(data.product_requests_id);
							setKey(index + 3);
							setOpenModal(true);
						}}
						className={styles.productRow}
					>
						<span>{data.product_name}</span>
						<Button
							size="tiny"
							className={styles.button}
							color="green"
						>
							เอกสาร มอก.
						</Button>
					</div>
				));

				const content_result = [<div key="header">รายการผลิตภัณท์</div>, ...see_more];

				if (data.product_requests.length > 3) {
					product_name.push(
						<Popup
							className={style.customPopup}
							style={{
								zIndex: 98,
							}}
							on="click"
							position="bottom left"
							trigger={
								<div style={{ position: "relative" }}>
									<PopupTrigger />
								</div>
							}
						>
							<div style={{ minWidth: "250px" }}>
								<div>{content_result}</div>
							</div>
						</Popup>
					);
				}

				return (
					<div
						style={{
							width: "100%",
						}}
					>
						<div>{product_name}</div>
					</div>
				);
			},
		},
		{
			label: "ยี่ห้อ",
			styleHead: {
				width: 200,
				minWidth: 200,
				whiteSpace: "nowrap",
			},
			format: ({ data }) => {
				const keys = "brand_name";
				const request = data.brand_requests;
				const product_color = fnProductColor(data.product_requests);

				const brand_name = fnGroupProductList({ request: request, keyName: keys, productColor: product_color });
				const seemore_brand_name = fnGroupProductList({
					request: request,
					keyName: keys,
					productColor: product_color,
					type: "info",
				});

				if (data.product_requests.length > 3) {
					brand_name.push(
						<Popup
							className={style.customPopup}
							on="click"
							position="bottom left"
							trigger={
								<div style={{ position: "relative" }}>
									<PopupTrigger />
								</div>
							}
						>
							<div style={{ minWidth: "250px" }}>
								<ContentPopup
									moreData={seemore_brand_name}
									title="รายการยี่ห้อ"
								/>
							</div>
						</Popup>
					);
				}

				return brand_name;
			},
		},
		{
			label: "รุ่นยาง",
			styleHead: {
				width: 200,
				minWidth: 200,
				whiteSpace: "nowrap",
			},
			alignHead: "center",
			format: ({ data }) => {
				const keys = "gen_name";
				const request = data.gen_requests;
				const product_color = fnProductColor(data.product_requests);

				const gen_requests = fnGroupProductList({ request: request, keyName: keys, productColor: product_color });
				const seemore_gen_requests = fnGroupProductList({
					request: request,
					keyName: keys,
					productColor: product_color,
					type: "info",
				});

				if (data.product_requests.length > 3) {
					gen_requests.push(
						<Popup
							className={style.customPopup}
							on="click"
							position="bottom left"
							trigger={
								<div style={{ position: "relative" }}>
									<PopupTrigger />
								</div>
							}
						>
							<div style={{ minWidth: "250px" }}>
								<ContentPopup
									moreData={seemore_gen_requests}
									title="รายการยี่ห้อ"
								/>
							</div>
						</Popup>
					);
				}

				return gen_requests;
			},
		},
		{
			label: "ขนาดยาง",
			styleHead: {
				width: 250,
				minWidth: 250,
				whiteSpace: "nowrap",
			},
			style: {
				minWidth: "fit-content",
				padding: 10,
			},
			format: ({ data }) => {
				const product_color = fnProductColor(data.product_requests);

				const size_requests_count = {};

				for (const size of data.size_requests) {
					const product_requests_id = size.product_requests_id;
					if (size_requests_count.hasOwnProperty(product_requests_id)) {
						size_requests_count[product_requests_id]++;
					} else {
						size_requests_count[product_requests_id] = 1;
					}
				}

				const grouped_data = {};
				for (const item of data.size_requests) {
					const product_requests_id = item.product_requests_id;

					if (!grouped_data[product_requests_id]) {
						grouped_data[product_requests_id] = [];
					}

					grouped_data[product_requests_id].push(item);
				}

				const value_group_data = Object.values(grouped_data);

				const result = {};
				for (const size of data.size_requests) {
					if (!(size.product_requests_id in result)) {
						result[size.product_requests_id] = {
							product_requests_id: size.product_requests_id,
							size_text: size.size_text,
						};
					}
				}

				const first_of_id = Object.values(result);
				const size_requests = first_of_id.slice(0, 3).map((size) => {
					const relevantColors = product_color.filter((item) => item.product_id === size.product_requests_id);

					const product_name = data.product_requests
						.filter((element) => element.product_requests_id === size.product_requests_id)
						.map((element) => <div key={element.product_requests_id}>{element.product_name}</div>);

					const size_with_color = relevantColors.map((item_with_color, key) => (
						<div
							key={key}
							className={style.containerSize}
						>
							<div style={{ color: item_with_color.color, paddingRight: 10 }}>{size.size_text}</div>

							{size_requests_count[item_with_color.product_id] >= 2 && (
								<div>
									<Popup
										className={style.popUpSize}
										on="click"
										position="right center"
										offset={"40%p,0"}
										trigger={
											<div style={{ position: "relative" }}>
												<Button size="mini">...</Button>
											</div>
										}
									>
										<div style={{ minWidth: "200px" }}>ผลิตภัณฑ์</div>
										<div
											className={style.headerSizeProductName}
											style={{
												backgroundColor: item_with_color.color,
											}}
										>
											{product_name}
										</div>
										<div style={{ paddingTop: 3 }}>รายการขนาดยางเพิ่มเติม</div>
										<div style={{ color: item_with_color.color, display: "flex", flexDirection: "column", padding: 5 }}>
											{value_group_data.map((element) => {
												const response = element
													.filter(
														(value) =>
															value.product_requests_id === size.product_requests_id && value !== element[0]
													)
													.map((value, index) => (
														<div
															style={{ width: "100%" }}
															key={index}
														>
															{value.size_text}
														</div>
													));
												return response;
											})}
										</div>
									</Popup>
								</div>
							)}
						</div>
					));
					return size_with_color;
				});

				const seemore_size_requests = first_of_id.slice(3).map((size, index) => {
					const relevantColors = product_color.filter((item) => item.product_id === size.product_requests_id);
					const product_name = data.product_requests
						.filter((element) => element.product_requests_id === size.product_requests_id)
						.map((element) => <div key={element.product_requests_id}>{element.product_name}</div>);

					const size_with_color = relevantColors.map((item_with_color, key) => (
						<div
							key={key}
							className={style.containerSize}
						>
							<div style={{ color: item_with_color.color, paddingRight: 10 }}>{size.size_text}</div>

							{size_requests_count[item_with_color.product_id] >= 3 && (
								<div>
									<Popup
										className={style.popUpSize}
										on="click"
										position="right center"
										offset={"40%p,0"}
										onOpen={() => setOpenPopup(true)}
										onClose={() => setOpenPopup(false)}
										trigger={
											<div style={{ position: "relative" }}>
												<Button size="mini">...</Button>
											</div>
										}
									>
										<div style={{ minWidth: "200px" }}>ผลิตภัณฑ์</div>
										<div
											className={style.headerSizeProductName}
											style={{
												backgroundColor: item_with_color.color,
											}}
										>
											{product_name}
										</div>
										<div style={{ paddingTop: 3 }}>รายการขนาดยางเพิ่มเติม</div>
										{value_group_data.map((element, index) => (
											<div key={index}>{element.size_text}</div>
										))}
									</Popup>
								</div>
							)}
						</div>
					));

					return size_with_color;
				});

				const ContentPopup = () => {
					const content_result = seemore_size_requests.map((item) => {
						return item;
					});
					return <div>{content_result}</div>;
				};

				if (data.product_requests.length > 3) {
					size_requests.push(
						<Popup
							eventsEnabled={true}
							className={style.customPopup}
							on="click"
							open={open_popup}
							position="bottom left"
							onClose={() => setOpenPopup(false)}
							onOpen={() => setOpenPopup(true)}
							trigger={
								<div style={{ position: "relative" }}>
									<PopupTrigger />
								</div>
							}
						>
							<div style={{ minWidth: "200px" }}>รายการขนาดยาง</div>
							<div>
								<ContentPopup />
							</div>
						</Popup>
					);
				}

				return <div style={{ paddingRight: 5, paddingLeft: 5 }}>{size_requests}</div>;
			},
		},
		{
			id: "approve_oie_tisi_text",
			label: "สถานะ",
			styleHead: {
				width: 100,
				minWidth: 100,
				whiteSpace: "nowrap",
			},
			style: {
				textAlign: "center",
			},
		},
		{
			label: "วันที่ยื่น",
			styleHead: {
				width: 150,
				minWidth: 150,
				whiteSpace: "nowrap",
			},
			style: {
				textAlign: "center",
			},
			format: ({ data }) => {
				const date_format = moment(data.created_at).add(543, "years").format("DD/MM/YYYY");
				return <div>{date_format}</div>;
			},
		},
	];

	return (
		<div>
			<ApprovalHeader />
			<div style={{ margin: "15px 10px" }}>
				<Container fluid>
					<Grid
						stackable
						columns={"equal"}
					>
						<Grid.Row>
							<Grid.Column width={11}>
								<Header
									as="h2"
									textAlign="left"
								>
									<Image
										src={"/logo-eco.png"}
										style={{
											justifyContent: "center",
											borderBottomLeftRadius: 10,
											borderBottomRightRadius: 10,
											borderTopRightRadius: 10,
											borderTopLeftRadius: 10,
										}}
									/>{" "}
									ฐานข้อมูล ECO Sticker
								</Header>
							</Grid.Column>
							<Grid.Column width={2}>
								<Button
									fluid
									disabled={is_exporting}
									loading={is_exporting}
									color="violet"
									floated="right"
									onClick={fnHandleExportData}
								>
									<Icon name="download" />
									Export data
								</Button>
							</Grid.Column>
							<Grid.Column width={3}>
								<Button
									fluid
									disabled={btnExportLoading}
									loading={btnExportLoading}
									color="violet"
									floated="right"
									onClick={handleExportExcelSummarySticker}
								>
									<Icon name="download" />
									สรุปจำนวนสติ๊กเกอร์ตามบริษัท
								</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<Divider />
					<Menu
						fluid
						attached="top"
						icon="labeled"
						tabular
						color="violet"
						size="small"
					>
						<Menu.Item
							name="คำขอ Eco Sticker จากระบบ Auto Approve"
							icon="check"
							// active={tab === "approved"}
							active={true}
							// onClick={fnHandleMenuClick}
						/>
						{/* <Menu.Item
                                name="รอการอนุมัติ"
                                icon="wait"
                                active={tab === "pending"}
                                onClick={fnHandleMenuClick}
                            />
                            <Menu.Item
                                name="ไม่ผ่านการอนุมัติ"
                                icon="times"
                                active={tab === "disapproved"}
                                onClick={fnHandleMenuClick}
                            /> */}
						<Menu.Menu position="right">
							<Menu.Item>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "10px",
									}}
								>
									<Input
										style={{ marginRight: "20px" }}
										floated="right"
										icon="search"
										placeholder="Search..."
										value={search_check}
										onChange={(e) => {
											const value = e.target.value;

											const regex = /^[^-\s]/;
											if (!regex.test(value) && value.length > 0) {
												setSearchCheck("");
											} else {
												setSearchCheck(e.target.value);
												fnSearchDebounce(e.target.value);
											}
										}}
									/>

									{/* delete button */}
									<Button
										animated="vertical"
										basic
										color="red"
										onClick={() => {
											setShowDeleteRequestBTN(!show_delete_request_btn);
										}}
									>
										<Button.Content hidden>ลบคำขอ</Button.Content>
										<Button.Content visible>
											<Icon name="trash alternate" />
										</Button.Content>
									</Button>
								</div>
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					{/* Menu */}
					<MainTable
						data={data || []}
						columns={
							show_delete_request_btn
								? [
										...columns,
										{
											label: "ลบคำขอ",
											styleHead: {
												width: 80,
												minWidth: 80,
												padding: 0,
											},
											style: {
												textAlign: "center",
												padding: 0,
											},
											format: ({ data }) => {
												return (
													<div>
														<Button
															color="red"
															icon="trash alternate"
															onClick={() => {
																Swal.fire({
																	title: "ลบคำขอสติ๊กเกอร์",
																	icon: "error",
																	showCancelButton: true,
																	confirmButtonColor: "#3085d6",
																	cancelButtonColor: "#d33",
																	confirmButtonText: "ยืนยัน",
																	cancelButtonText: "ยกเลิก",
																}).then((result) => {
																	if (result.isConfirmed) {
																		fnDeletedOieData(data.tyre_requests_id);
																	}
																});
															}}
														/>
													</div>
												);
											},
										},
								  ]
								: columns
						}
					/>
					<div style={{ alignSelf: "flex-end", flex: 1, padding: "10px 0px" }}>
						<OiePagination
							handleChangePage={fnHandleChangePage}
							totalPages={info?.total_pages}
							page={parseInt(page)}
							handleChangeRowsPerPage={fnHandleChangeRowsPerPage}
							rowsPerPage={parseInt(rows_per_page)}
							totalRows={info?.total_rows}
						/>
					</div>
				</Container>
			</div>
			<ModalEditData
				onOpen={() => setOpenModal(true)}
				open={open_modal}
				onClose={() => {
					setOpenModal(false);
					setProductIdRequest();
					setKey();
				}}
				productId={product_id_request}
				keyId={key}
				viewMode
			/>
		</div>
	);
};

export default Home;
