import React, { Component } from "react";
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from "prop-types";
import { HEADER, FASTCONTACTMAIL } from "../router";
import VisibilitySensor from "react-visibility-sensor";
import { Modal } from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import { GET_VISITORS } from "../router";

export class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      from_email: "",
      message: "",
      successMsg: "",
      isOpen: false,
    };
  }

  getVisitorCount = async () => {
    const res = await axios.put(GET_VISITORS);
  };
  handleForm = (e) => {
    let that = this;
    axios
      .post(FASTCONTACTMAIL, this.state, HEADER)
      .then(function (response) {
        document.getElementById("contactForm").reset();
        that.setState({
          successMsg: "We received your submission",
        });
        document.getElementById("contactForm").reset();
      })
      .catch(function (error) {});
  };

  handleFields = (e) => this.setState({ [e.target.name]: e.target.value });

  handleIconClick = (name) => {
    switch (name) {
      case "Address":
        // this.setState({ isOpen: true })
        var win = window.open(
          "https://goo.gl/maps/siy86Tt97YdbdnRZ6",
          "_blank"
        );
        if (win) {
          win.focus();
        }

        break;
      case "e-mail":
        // code block
        break;
      case "Phone":
        window.open("tel:02-4306805 ต่อ 680513", "_self");
        break;
      default:
      // code block
    }
  };

  render() {
    //Start ContactUs Loop
    const contactusdata = this.props.contactussData.map((contactus, index) => (
      <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
        <div className="contact-box">
          <a
            href="/#"
            // href='javascript:void(0);'
            className="icon"
            onClick={() => this.handleIconClick(contactus.boxtitle)}
          >
            <i className={contactus.boxicon} />
          </a>
          <h4>{contactus.boxtitle}</h4>
          <p>{contactus.boxitem1}</p>
          <p>{contactus.boxitem2}</p>
        </div>
      </div>
    ));
    //End ContactUs Loop

    const visitor = this.props.visitorAmount;
    return (
      <React.Fragment>
        <Modal
          onClose={() => this.setState({ isOpen: false })}
          // onOpen={() => setOpen(true)}
          open={this.state.isOpen}
        >
          <Modal.Header>Select a Photo</Modal.Header>
          <Modal.Content>
            <Modal.Description></Modal.Description>
          </Modal.Content>
        </Modal>

        <section className="contact-area ptb-100">
          <VisibilitySensor
            onChange={(isVisible) =>
              isVisible && this.props.setActiveMenu("contact")
            }
          >
            <div id="contact" className="container">
              <div className="section-title" style={{ textAlign: "left" }}>
                <Fade top>
                  <div>
                    <h3 className="section-header">Contact us</h3>
                    {/* <h3 style={{ display: 'inline-block', float: 'right' }}>
                                            {this.props.sectionTitleSpan}
                                        </h3> */}
                  </div>
                </Fade>
              </div>
              <div className="row">
                <Fade top>{contactusdata}</Fade>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="get-in-touch">
                    <h3>{this.props.title}</h3>
                    <p>{this.props.content}</p>
                    {/* <ul>
                                    <li><a href={this.props.fblink} className="icofont-facebook"> </a></li>
                                    <li><a href={this.props.twitterlink} className="icofont-twitter"> </a></li>
                                    <li><a href={this.props.instagramlink} className="icofont-instagram"> </a></li>
                                    <li><a href={this.props.linkedinlink} className="icofont-linkedin"> </a></li>
                                </ul> */}
                    {/* <h4 className="visitor-count">
                      จำนวนผู้เข้าชมเว็บไซต์ :{" "}
                      <span className="num">
                        {visitor && (visitor.visitor_amount).toLocaleString()}{" "}
                      </span>{" "}
                    </h4> */}
                  </div>
                </div>

                <div className="col-lg-8 col-md-12 col-sm-12">
                  <Fade bottom>
                    <ValidationForm
                      id="contactForm"
                      onSubmit={(e, formData, inputs) => {
                        e.preventDefault();
                        this.handleForm(formData);
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="form-group">
                            <TextInput
                              name="title"
                              id="title"
                              required
                              successMessage=""
                              errorMessage="Please enter your title"
                              className="form-control"
                              placeholder="Title"
                              autoComplete="off"
                              onChange={this.handleFields}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="form-group">
                            <TextInput
                              name="from_email"
                              id="from_email"
                              type="email"
                              required
                              successMessage=""
                              errorMessage="Please enter your email address"
                              className="form-control"
                              placeholder="e-mail"
                              autoComplete="off"
                              onChange={this.handleFields}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <TextInput
                              name="message"
                              id="description"
                              multiline
                              placeholder="Your message"
                              className="form-control"
                              required
                              successMessage=""
                              errorMessage="Please write your message"
                              rows="5"
                              autoComplete="off"
                              onChange={this.handleFields}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <button type="submit" className="btn btn-primary">
                            Send Message
                          </button>
                          {/* <div id="msgSubmit" className="h3 text-center hidden"></div> */}
                          {/* <div className="clearfix"></div> */}
                        </div>
                      </div>
                    </ValidationForm>
                  </Fade>
                  {this.state.successMsg !== "" ? (
                    <h3 className="contactMsg">{this.state.successMsg}</h3>
                  ) : null}
                </div>
              </div>
            </div>
          </VisibilitySensor>
        </section>
      </React.Fragment>
    );
  }
}
ContactUs.PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  fblink: PropTypes.string,
  twitterlink: PropTypes.string,
  instagramlink: PropTypes.string,
  linkedinlink: PropTypes.string,
  contactussData: PropTypes.array,
};
ContactUs.defaultProps = {
  sectionTitle: "Contact",
  sectionTitleSpan: "us",
  sectionContent: "For support or any questions:",
  title: "Get in Touch",
  content:
    "Please fill out the quick form and we will be in touch with lightning speed.",
  fblink: "#",
  twitterlink: "#",
  instagramlink: "#",
  linkedinlink: "#",
  contactussData: [
    {
      boxicon: "icofont-location-pin",
      boxtitle: "Address",
      boxitem1link: "#",
      boxitem1: "75/6 Rama VI Road,",
      boxitem2link: "#",
      boxitem2: "Ratchathewi, Bangkok 10400",
    },
    {
      boxicon: "icofont-mail",
      boxtitle: "e-mail",
      boxitem1link: "#",
      boxitem1: "tyre@oie.go.th",
      boxitem2link: "#",
      boxitem2: "",
    },
    {
      boxicon: "icofont-phone",
      boxtitle: "Phone",
      boxitem1link: "#",
      boxitem1: "0 2430 6805 ต่อ 680513",
      boxitem2link: "#",
      boxitem2: "",
    },
  ],
};
export default ContactUs;
