import React, { Component } from 'react';
import {
    Modal,
    Grid

} from 'semantic-ui-react';


export class RegulationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    basic
                    open={this.props.isOpen}
                    onClose={this.props.closeModal}
                    size='large'
                    centered={false}
                    closeIcon
                >

                    <Modal.Content >
                        <Grid columns='equal'>

                            <Grid.Column width={16}>
                                <iframe
                                    title='fileViewer'
                                    frameBorder="0"
                                    style={{
                                        width: "100%",
                                        height: "80vh"
                                    }}
                                    src={`${this.props.src}#zoom=80`}
                                // src={URL.createObjectURL(data[0])}
                                />
                            </Grid.Column>

                        </Grid>








                    </Modal.Content>
                </Modal>




            </React.Fragment >
        );
    }
}


export default RegulationModal

