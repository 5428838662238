import React, { Component } from 'react';
import { connect } from 'react-redux'; // connect redux
import { Table, Button, Grid, Icon, Header } from 'semantic-ui-react';

const companies_type = [
  { key: 1, text: 'บุคคลธรรมดา', prefix_th: '', suffix_th: '', prefix_en: '', suffix_en: '', value: 1 },
  { key: 2, text: 'ห้างหุ้นส่วนสามัญนิติบุคคล', prefix_th: 'ห้างหุ้นส่วนสามัญนิติบุคคล', suffix_th: '', prefix_en: '', suffix_en: 'ROP', value: 2 },
  { key: 3, text: 'ห้างหุ้นส่วนจำกัด', prefix_th: 'ห้างหุ้นส่วนจำกัด', suffix_th: '', prefix_en: '', suffix_en: 'Part., Ltd.', value: 3 },
  { key: 4, text: 'บริษัทจำกัด', prefix_th: 'บริษัท', suffix_th: 'จำกัด', prefix_en: '', suffix_en: 'Co., Ltd.', value: 4 },
  { key: 5, text: 'บริษัทมหาชนจำกัด', prefix_th: 'บริษัท', suffix_th: 'จำกัด (มหาชน)', prefix_en: '', suffix_en: 'Pub Co., Ltd.', value: 5 }
]

class entrepreneurPreview extends Component {
  constructor() {
    super();
    this.state = {
      entrepreneurPathFile: "",
    }
  }

  componentDidMount() {
    if (this.props.stateRedux.entrepreneurFields.file_corporation_certificate !== undefined) {
      this.setState({ entrepreneurPathFile: this.props.stateRedux.entrepreneurFields.file_corporation_certificate.path })
    }
  }

  async entrepreneurShowPDF(e) {
    let name = e.target.name;
    let path = this.props.stateRedux[name].path
    this.setState({ entrepreneurPathFile: path })
  }

  render() {
    const props = this.props;

    // Condition Show company_name and company_type
    let show_company_type = "";
    let show_company_name_th = "";
    let show_company_name_en = "";

    let company_name_th = "";
    let company_name_en = "";

    if (props.stateRedux.entrepreneurFields.personal_nameTH !== "") {
      company_name_th = props.stateRedux.entrepreneurFields.personal_nameTH;
      company_name_en = props.stateRedux.entrepreneurFields.personal_nameEN;
    }
    if (props.stateRedux.entrepreneurFields.name_juristicTH !== "") {
      company_name_th = props.stateRedux.entrepreneurFields.name_juristicTH;
      company_name_en = props.stateRedux.entrepreneurFields.name_juristicEN;
    }
    if (props.stateRedux.entrepreneurFields.name_limited_partnershipTH !== "") {
      company_name_th = props.stateRedux.entrepreneurFields.name_limited_partnershipTH;
      company_name_en = props.stateRedux.entrepreneurFields.name_limited_partnershipEN;
    }
    if (props.stateRedux.entrepreneurFields.name_company_limitedTH !== "") {
      company_name_th = props.stateRedux.entrepreneurFields.name_company_limitedTH;
      company_name_en = props.stateRedux.entrepreneurFields.name_company_limitedEN;
    }
    if (props.stateRedux.entrepreneurFields.name_public_companyTH !== "") {
      company_name_th = props.stateRedux.entrepreneurFields.name_public_companyTH;
      company_name_en = props.stateRedux.entrepreneurFields.name_public_companyEN;
    }

    companies_type.map((company_type) => {
      if (props.stateRedux.entrepreneurFields.personality_value === company_type.value) {
        show_company_type = company_type.text;
        show_company_name_th = company_type.prefix_th + " " + company_name_th + " " + company_type.suffix_th;
        show_company_name_en = company_type.prefix_en + " " + company_name_en + " " + company_type.suffix_en
      }
      return null
    })
    // Condition Show company_name and company_type
    // console.log(props.stateRedux.entrepreneurFields)
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8}>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='3'>
                      <Header as='h4'>
                        <Header.Content>
                          <Icon name="user" />
                          ข้อมูลผู้ประกอบการ
                        </Header.Content>
                      </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h5'>
                      <Header.Content>
                        สภาพบุคคล
                        </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {show_company_type}
                  </Table.Cell>
                </Table.Row>

                {/* ชื่อผู้ประกอบการ */}
                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h5'>
                      <Header.Content>
                        ชื่อผู้ประกอบการภาษาไทย
                        </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {show_company_name_th}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h5'>
                      <Header.Content>
                        ชื่อผู้ประกอบการภาษาอังกฤษ
                        </Header.Content>
                    </Header>

                  </Table.Cell>
                  <Table.Cell>
                    {show_company_name_en}
                  </Table.Cell>
                </Table.Row>
                {/* ชื่อผู้ประกอบการ */}

                <Table.Row>
                  <Table.Cell>
                    <Header as='h5'>
                      <Header.Content>
                        ประเภทผู้ประกอบการ
                        </Header.Content>
                    </Header>

                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.companyType_text}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h5'>
                      <Header.Content>
                        เลขที่ประจำตัวผู้เสียภาษีอากร
                        </Header.Content>
                    </Header>

                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.tax_id}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h5'>
                      <Header.Content>
                        เครื่องหมายการค้า
                        </Header.Content>
                    </Header>

                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.tradeMark_id !== "" ? "มี" : "ไม่มี"}
                  </Table.Cell>
                </Table.Row>

                {props.stateRedux.entrepreneurFields.tradeMark_id !== "" && (
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Header as='h5'>
                        <Header.Content>
                          ทะเบียนเลขที่
                        </Header.Content>
                      </Header>

                    </Table.Cell>
                    <Table.Cell>
                      {props.stateRedux.entrepreneurFields.tradeMark_id}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell colSpan='3'>
                    {/* <Icon name="point" /> */}
                    <Header as='h5'>
                      <Header.Content>
                        ที่ตั้งสำนักงานใหญ่
                        </Header.Content>
                    </Header>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell collapsing>
                    เลขที่
                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.address_id}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    ซอย
                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.alley}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    ถนน
                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.road}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell collapsing>
                    ตำบล/แขวง
                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.sub_district}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    อำเภอ/เขต
                        </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.district}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    จังหวัด
                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.province}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    รหัสไปรษณีย์
                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.zip_code}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h5'>
                      <Header.Content>
                        เบอร์โทรศัพท์
                        </Header.Content>
                    </Header>

                  </Table.Cell>
                  <Table.Cell>
                    {props.stateRedux.entrepreneurFields.phone_number}
                  </Table.Cell>
                </Table.Row>

                {/* {props.stateRedux.entrepreneurFields.fax_number !== "" && (
                  <React.Fragment>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Header as='h5'>
                          <Header.Content>
                            เบอร์แฟกซ์
                        </Header.Content>
                        </Header>

                      </Table.Cell>
                      <Table.Cell>
                        {props.stateRedux.entrepreneurFields.fax_number}
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                )} */}

                {props.stateRedux.entrepreneurFields.website !== "" && (
                  <React.Fragment>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Header as='h5'>
                          <Header.Content>
                            เว็บไซต์
                        </Header.Content>
                        </Header>

                      </Table.Cell>
                      <Table.Cell>
                        {props.stateRedux.entrepreneurFields.website}
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                )}

                <Table.Row>
                  <Table.Cell colSpan='3'>
                    {/* <Icon name="point" /> */}
                    <Header as='h5'>
                      <Header.Content>
                        เอกสารแนบ
                        </Header.Content>
                    </Header>

                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell collapsing>
                    <Icon name='file outline' />
                    หนังสือรับรองนิติบุคคล
                  </Table.Cell>
                  <Table.Cell>
                    {/* {props.stateRedux.file_corporation_certificate.name} */}
                    <Button
                      size='small'
                      floated='left'
                      basic
                      color='orange'
                      name="file_corporation_certificate"
                      id="file_corporation_certificate"
                      onClick={this.entrepreneurShowPDF.bind(this)}
                    // onClick={this.handleshowPDF.bind(this)}
                    >
                      <Icon name='search' />
                      ดูเอกสาร
                          </Button>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Icon name='file outline' />
                    ใบทะเบียนภาษีมูลค่าเพิ่ม
                  </Table.Cell>
                  <Table.Cell>
                    {/* {props.stateRedux.file_vat_certificate.name} */}
                    <Button
                      size='small'
                      floated='left'
                      color='orange'
                      basic
                      name="file_vat_certificate"
                      id="file_vat_certificate"
                      onClick={this.entrepreneurShowPDF.bind(this)}
                    // onClick={this.handleshowPDF.bind(this)}
                    >
                      <Icon name='search' />
                      ดูเอกสาร
                    </Button>
                  </Table.Cell>
                </Table.Row>

                {props.stateRedux.file_trademark.name !== "" && (
                  <Table.Row>
                    <Table.Cell width="3">
                      <Icon name='file outline' />
                      หนังสือสำคัญแสดงการจดทะเบียนเครื่องหมายการค้า เครื่องหมายบริการ
                    </Table.Cell>
                    <Table.Cell>
                      {/* {props.stateRedux.file_trademark.name} */}
                      <Button
                        size='small'
                        floated='left'
                        color='orange'
                        basic
                        name="file_trademark"
                        id="file_trademark"
                        onClick={this.entrepreneurShowPDF.bind(this)}
                      // onClick={this.handleshowPDF.bind(this)}
                      >
                        <Icon name='search' />
                        ดูเอกสาร
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table>
            </Grid.Column>

            <Grid.Column computer={8}>
              <iframe
                title="entrepreneur_file"
                src={this.state.entrepreneurPathFile}
                style={{
                  height: window.innerHeight,
                  width: '100%',
                  borderWidth: 0
                }}
                type='application/pdf'
              />
              {/* {this.showIframe()} */}
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

// export default entrepreneurPreview;
const mapStateToProps = function (state) {
  return {
    message: 'This is message from mapStateToProps',
    stateRedux: state.reduxxx
  }
}

export default connect(mapStateToProps)(entrepreneurPreview);