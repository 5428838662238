import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Message, Container, Segment, Button } from "semantic-ui-react";
import { reduxSource } from "../actions/actions";
import { connect } from "react-redux";
import Headers from "../header/requestHeader";

class EditFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entrepreneurRegister: false,
      productRegister: false,
      productImport: false,
      addDelegate: false,

      // Menu Status
      status_entrepreneur: false,
      status_productRegister: false,
      status_productImport: false,
      status_authorized: false,
      status_delegate: false,

      ///// JuristicRegister.js ######################################
      entrepreneurFields: {
        name_prefixTH_value: "1",
        name_prefixTH_index: 0,
        name_prefixTH_text: "นาย",
        name_prefixEN_value: "1",
        name_prefixEN_index: 0,
        name_prefixEN_text: "Mr.",
        personality_value: 1,
        personality_index: 0,
        personality_text: "บุคคลธรรมดา",
        personal_nameTH: "",
        personal_nameEN: "",
        name_juristicTH: "",
        name_juristicEN: "",
        name_limited_partnershipTH: "",
        name_limited_partnershipEN: "",
        name_company_limitedTH: "",
        name_company_limitedEN: "",
        name_public_companyTH: "",
        name_public_companyEN: "",
        entrepreneur_type: "",
        companyType_text: "",
        status_manufacture: 0,
        stauts_import: 0,
        tax_id: "",
        tax_id_show: "",
        tradeMark_id: "",
        address_id: "",
        alley: "",
        road: "",
        province: "",
        district: "",
        sub_district: "",
        zip_code: "",
        phone_number: "",
        fax_number: "",
        website: "",
        file_corporation_name: "",
        file_vat_name: "",
        file_trademark_name: "",
        juristicTypeTH_value: "1",
        juristicTypeTH_index: 0,
        juristicTypeTH_text: "ห้างหุ้นส่วนสามัญนิติบุคคล",
        juristicTypeEN_value: "1",
        juristicTypeEN_index: 0,
        juristicTypeEN_text: "Juristic Ordinary Partnership",
      },
      file_corporation_certificate: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ
      file_vat_certificate: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ
      file_trademark: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ

      ///// productRegister.js ######################################
      factory_pos: 0,
      product_type_options: [],
      formProductError: false,
      factory_form_obj: [
        {
          factory_name_th: "",
          factory_name_en: "",
          factory_license_number: "",
          factory_certificate: "",
          factory_from: "",
          factory_under: "",
          factory_location: "",
          factory_number: "",
          factory_alley: "",
          factory_road: "",
          factory_province: "",
          factory_district: "",
          factory_zone: "",
          factory_zip_code: "",
          factory_tel: "",
          factory_license_file: { name: "", data: [], path: "" },
          factory_certificate_file: { name: "", data: [], path: "" },
          factory_product: [
            {
              product_name: "",
              product_structure: "",
              product_power_output: "",
              product_trade_name: [
                {
                  trade_name: "",
                },
              ],
            },
          ],
          isIndustrial: "out",
          posIndustrial: "",
        },
      ],

      ///// addDelegate.js ######################################
      delegateFields: {
        name_prefixTH_index: 0,
        name_prefixEN_index: 0,
        name_prefixTH_text: "นาย",
        name_prefixEN_text: "Mr.",
        nationality_value: 1,
        nationality_index: 0,
        nationality_text: "คนไทย",
        delegate_nameTH: "",
        delegate_nameEN: "",
        delegate_id_number: "",
        delegate_passport_number: "",
        delegate_phone_number: "",
        dataprovince: "",
        datadistrict: "",
        dataSubDistrict: "",
        zip_code: "",
        foreigner_address: "",
        delegate_email: "",
      },
      delegate_id_number: "",
      nationality_value: 1,
      nationality_index: 0,
      file_idcard: { name: "", data: [], path: "" },
      file_passport: { name: "", data: [], path: "" },
      file_poa: { name: "", data: [], path: "" },

      // register confirm ##################################

      // activeItem: 'ข้อมูลผู้ประกอบการ',

      formProductImport: [
        {
          factory: {
            nameFactory: "",
            certificate: "",
            from: "",
            under: "",
            location: "",
            country: "",
            file_doc: "",
          },
          productImport: [
            {
              productType: "",
              productStructure: "",
              importCapacity: "",
              productName: [""],
            },
          ],
          exportPerson: [
            {
              exportPersonName: "",
              exportPersonCountry: "",
            },
          ],
        },
      ],

      errorsFormProductImport: {
        factory: {
          nameFactory: false,
          certificate: false,
          from: false,
          under: false,
          location: false,
          country: false,
          file_doc: false,
        },
        productImport: [
          {
            productType: false,
            productStructure: false,
            importCapacity: false,
            productName: [false],
          },
        ],
        exportPerson: [
          {
            exportPersonName: false,
            exportPersonCountry: false,
          },
        ],
      },
      personAuthorized: [
        {
          nationality: 1,
          idNumber: "",
          nameTH: "",
          nameEN: "",
          address: "",
          address_id: "",
          alley: "",
          road: "",
          sub_district: "",
          district: "",
          province: "",
          zip_code: "",
          phoneNumber: "",
          email: "",
          file: "",
          type: 1,
        },
      ],
      errorPersonAuthorized: [
        {
          nationality: false,
          idNumber: false,
          nameTH: false,
          nameEN: false,
          address: false,
          address_id: false,
          alley: false,
          road: false,
          sub_district: false,
          district: false,
          province: false,
          zip_code: false,
          phoneNumber: false,
          email: false,
          file: false,
        },
      ],
    };
  }

  render() {
    return (
      <div>
        <Headers />
        <Container>
          <br />
          <Segment size="large" padded="very" color="violet">
            <Message
              icon="warning sign"
              negative
              header="เกิดข้อผิดพลาด"
              content="ไม่สามารถทำรายการได้"
            />
          </Segment>
          <Link to="/company/main">
            <center>
              <Button
                size="big"
                primary
                onClick={() => {
                  this.props.dispatch(reduxSource(this.state));
                }}
              >
                กลับสู่หน้าหลัก
              </Button>
            </center>
          </Link>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    message: "This is message from mapStateToProps",
    stateForm: state.reduxxx,
  };
};

export default connect(mapStateToProps)(EditFailed);
