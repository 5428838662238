import React, { Component } from "react";
import {
  Segment,
  Header,
  Icon,
  Container,
  Table,
  Divider,
  Menu,
  Dropdown,
  Pagination,
  Input,
  Button,
  Grid,
} from "semantic-ui-react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  OIE_PENDING,
  OIE_APPROVE,
  OIE_REJECT,
  OIE_EDIT,
  TOKEN_BLOB,
  OIE_EXPORT_COMPANY_APPROVE,
  GET_STATUS_PDPA,
  UPDATE_STATUS_REQUEST_PDPA,
} from "../router";
import Headers from "../approval/ApprovalHeader";
import { connect } from "react-redux";
import { setApprovedStatus } from "../actions/actions";
import moment from "moment";
import "../../css/style_oie.scss";
import _ from "lodash";
import Modals from "../Modals";
import Swal from "sweetalert2";

const allSizeRow = [
  { key: 1, text: 5, value: 5 },
  { key: 2, text: 10, value: 10 },
  { key: 3, text: 20, value: 20 },
  { key: 4, text: 100, value: 100 },
];

const date_now = new Date();

class Home extends Component {
  constructor(props) {
    super(props);
    this.delayTimer = null;
    this.state = {
      activeItem: "รอการอนุมัติ",
      data: [],
      rowQty: 10,
      currentPage: 1,
      pageQty: 1,
      loading: true,
      searchTxt: "",
      menuStatus: "pending",
      openRequestPDPA:false,
    };
  }

  componentDidMount = () => {
    axios
      .get(OIE_PENDING, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.setState(
          {
            data: response.data.value,
            dataRaw: response.data.value,
          },
          () => {
            let lengthData = 1;
            if (this.state.data !== null) {
              lengthData = this.state.data.length;
            }
            let pageQty = lengthData / this.state.rowQty;
            this.setState({ pageQty: Math.ceil(pageQty), loading: false });
          }
        );
        // console.log('state data', this.state.data)
      })
      .catch((error) => {
        localStorage.clear();
        this.props.history.push("/oie");
      });
      this.fetchCheckRequestPDPA()
  };

  async fetchCheckRequestPDPA() {
		try {
			const { data } = await axios.get(GET_STATUS_PDPA, {
				headers: {
					Authorization: `Bearer ` + localStorage.getItem("token"),
				},
			});
			if (!data.value.consented) {
				this.setState({
					openRequestPDPA: true,
				});
			}
		} catch (error) {
			if (error.response.status === 401) {
        localStorage.clear();
        this.props.history.push("/oie");
			}
		}
	}

	handleSubmitConfirmPDPA = async () => {
		try {
			await axios.post(UPDATE_STATUS_REQUEST_PDPA, null, {
        headers: {
          Authorization: `Bearer ` + localStorage.getItem("token")
        }
      });
			this.setState({
				openRequestPDPA:false
			})
		} catch (error) {
			Swal.fire({
				type: "error",
				title: "เกิดข้อผิดพลาด...",
			});
		}
	};

  changeRowSize = (e, { name, value }) =>
    this.setState({ [name]: value, currentPage: 1 }, () => {
      let lengthData = this.state.data.length;
      let pageQty = lengthData / this.state.rowQty;
      this.setState({ pageQty: Math.ceil(pageQty) });
    });

  changePage = (name, value) => this.setState({ [name]: value });

  handleMenuClick = (e, { name, value }) => {
    this.setState({ activeItem: name, currentPage: 1 });
    if (name === "รอการอนุมัติ") {
      this.setState({ loading: true });
      axios
        .get(OIE_PENDING, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.setState(
            {
              data: response.data.value,
              dataRaw: response.data.value,
              menuStatus: "pending",
            },
            () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              this.setState({
                pageQty: Math.ceil(pageQty),
                searchTxt: "",
                loading: false,
              });
            }
          );
        })
        .catch((error) => {
          if (error.response.data.message === "invalid or expired jwt") {
            localStorage.clear();
            this.props.history.push("/oie");
          }
        });
    } else if (name === "อนุมัติ") {
      this.setState({ loading: true });
      axios
        .get(OIE_APPROVE, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.setState(
            {
              data: response.data.value,
              dataRaw: response.data.value,
              menuStatus: "approved",
            },
            () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              this.setState({
                pageQty: Math.ceil(pageQty),
                searchTxt: "",
                loading: false,
              });
            }
          );
        })
        .catch((error) => {
          if (error.response.data.message === "invalid or expired jwt") {
            localStorage.clear();
            this.props.history.push("/oie");
          }
        });
    } else if (name === "ไม่ผ่านการอนุมัติ") {
      this.setState({ loading: true });
      axios
        .get(OIE_REJECT, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.setState(
            {
              data: response.data.value,
              dataRaw: response.data.value,
              menuStatus: "notApproved",
            },
            () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              this.setState({
                pageQty: Math.ceil(pageQty),
                searchTxt: "",
                loading: false,
              });
            }
          );
        })
        .catch((error) => {
          if (error.response.data.message === "invalid or expired jwt") {
            localStorage.clear();
            this.props.history.push("/oie");
          }
        });
    } else if (name === "แก้ไข") {
      this.setState({ loading: true });
      axios
        .get(OIE_EDIT, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.setState(
            {
              data: response.data.value,
              dataRaw: response.data.value,
              menuStatus: "edit",
            },
            () => {
              let lengthData = 1;
              if (this.state.data !== null) {
                lengthData = this.state.data.length;
              }
              let pageQty = lengthData / this.state.rowQty;
              this.setState({
                pageQty: Math.ceil(pageQty),
                searchTxt: "",
                loading: false,
              });
            }
          );
        })
        .catch((error) => {
          if (error.response.data.message === "invalid or expired jwt") {
            localStorage.clear();
            this.props.history.push("/oie");
          }
        });
    }
  };

  handleDateTitleTable = () => {
    switch (this.state.activeItem) {
      case "รอการอนุมัติ":
        return "วันที่ทำรายการ";
      case "แก้ไข":
        return "วันที่ส่งแก้ไข";
      case "ไม่ผ่านการอนุมัติ":
        return "วันที่ไม่อนุมัติ";
      case "อนุมัติ":
        return "วันที่อนุมัติ";
      default:
        return "";
    }
  };

  handleDateType = (data) => {
    let date;
    switch (this.state.activeItem) {
      case "รอการอนุมัติ":
        date = data.date_activity;
        break;
      case "แก้ไข":
        date = data.edit_oie_at;
        break;
      case "ไม่ผ่านการอนุมัติ":
        date = data.unapprove_oie_at;
        break;
      case "อนุมัติ":
        date = data.approve_oie_at;
        break;
      default:
        date = "";
    }
    // console.log('date',date)
    return (
      <React.Fragment>
        <div>{moment(date).format("DD-MM-YYYY")}</div>
        <div>{moment(date).format("HH:mm")}</div>
      </React.Fragment>
    );
  };

  handleDateTypeMobile = (data) => {
    let date;
    switch (this.state.activeItem) {
      case "รอการอนุมัติ":
        date = data.date_activity;
        break;
      case "แก้ไข":
        date = data.edit_oie_at;
        break;
      case "ไม่ผ่านการอนุมัติ":
        date = data.unapprove_oie_at;
        break;
      case "อนุมัติ":
        date = data.approve_oie_at;
        break;
      default:
        date = "";
    }
    // console.log('date',date)
    return (
      <React.Fragment>
        <span style={{ fontWeight: "bolder" }}>
          {this.handleDateTitleTable()}{" "}
        </span>
        {moment(date).format("DD-MM-YYYY")}
        {moment(date).format("HH:mm")}
      </React.Fragment>
    );
  };
  

  doSearch = (txt) => {
    clearTimeout(this.delayTimer);

    this.delayTimer = setTimeout(() => {
      const list = _.filter(this.state.dataRaw, (data) => {
        return (
          data.company_name.includes(txt) ||
          (typeof data.person_name === "undefined"
            ? false
            : data.person_name.includes(txt)) ||
          (typeof data.person_phone === "undefined"
            ? false
            : data.person_phone.includes(txt)) ||
          // this.handleDateType(data).includes(txt) ||
          (typeof data.last_approve_edited_data_oie_at === "undefined"
            ? false
            : moment(data.last_approve_edited_data_oie_at)
                .format("DD-MM-YYYY HH:mm")
                .includes(txt))
        );
      });
      this.setState({ data: list }, () => {
        let lengthData = 1;
        if (this.state.data !== null) {
          lengthData = this.state.data.length;
        }
        let pageQty = lengthData / this.state.rowQty;
        this.setState({
          pageQty: Math.ceil(pageQty),
          currentPage: 1,
        });
      });
    }, 300);
  };

  checkPersonName = (fn, txt) => {
    try {
      return fn().includes(txt);
    } catch (e) {
      return false;
    }
  };

  exportExcelCompanyApprove = async (companies_id, company_name, file_type) => {
    await axios
      .get(
        OIE_EXPORT_COMPANY_APPROVE + "/" + companies_id + "/" + file_type,
        TOKEN_BLOB
      )
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", company_name + ".xlsx"); //any other extension

        document.body.appendChild(link);

        link.click();

        // link.remove();
      })
      .catch((error) => {
        console.error("error export excel");
      });
  };

  render() {
    let props = this.props;
    return (
      <div>
        <Modals.PDPARequestForm open={this.state.openRequestPDPA} callback={this.handleSubmitConfirmPDPA} onClose={()=>this.setState({
          openRequestPDPA:false
        })} />
        <Headers />
        <div style={{ margin: "30px 10px" }}>
          <Container fluid>
            <Header as="h2" textAlign="left">
              <Icon name="users" color="violet" />
              ข้อมูลผู้ประกอบการ
            </Header>

            <Divider />
            <Grid>
              <Grid.Row only=" mobile">
                <Grid.Column>
                  <Menu
                    fluid
                    vertical
                    attached="top"
                    icon="labeled"
                    // tabular
                    color="violet"
                    size="small"
                  >
                    <Menu.Item
                      name="รอการอนุมัติ"
                      icon="wait"
                      active={this.state.activeItem === "รอการอนุมัติ"}
                      onClick={this.handleMenuClick}
                    />

                    <Menu.Item
                      name="แก้ไข"
                      icon="edit"
                      active={this.state.activeItem === "แก้ไข"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Item
                      name="ไม่ผ่านการอนุมัติ"
                      icon="times"
                      active={this.state.activeItem === "ไม่ผ่านการอนุมัติ"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Item
                      name="อนุมัติ"
                      icon="check"
                      active={this.state.activeItem === "อนุมัติ"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Menu position="right">
                      <Menu.Item>
                        <Input
                          // icon='search'
                          // placeholder='Search...'
                          // size={{small}}
                          label={{ icon: "search" }}
                          labelPosition="right corner"
                          placeholder="Search..."
                          value={this.state.searchTxt}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^[^-\s]/;
                            if (!regex.test(value) && value.length > 0) {
                              this.setState({ searchTxt: "" });
                            } else {
                              this.setState({ searchTxt: value }, () => {
                                this.doSearch(this.state.searchTxt);
                              });
                            }
                            // this.doSearch(e.target.value)
                          }}
                        />
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row only="tablet computer">
                <Grid.Column>
                  <Menu
                    fluid
                    attached="top"
                    icon="labeled"
                    tabular
                    color="violet"
                    size="small"
                  >
                    <Menu.Item
                      name="รอการอนุมัติ"
                      icon="wait"
                      active={this.state.activeItem === "รอการอนุมัติ"}
                      onClick={this.handleMenuClick}
                    />

                    <Menu.Item
                      name="แก้ไข"
                      icon="edit"
                      active={this.state.activeItem === "แก้ไข"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Item
                      name="ไม่ผ่านการอนุมัติ"
                      icon="times"
                      active={this.state.activeItem === "ไม่ผ่านการอนุมัติ"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Item
                      name="อนุมัติ"
                      icon="check"
                      active={this.state.activeItem === "อนุมัติ"}
                      onClick={this.handleMenuClick}
                    />
                    <Menu.Menu position="right">
                      <Menu.Item>
                        <Input
                          // icon='search'
                          // placeholder='Search...'
                          // size={{small}}
                          label={{ icon: "search" }}
                          labelPosition="right corner"
                          placeholder="Search..."
                          value={this.state.searchTxt}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^[^-\s]/;
                            if (!regex.test(value) && value.length > 0) {
                              this.setState({ searchTxt: "" });
                            } else {
                              this.setState({ searchTxt: value }, () => {
                                this.doSearch(this.state.searchTxt);
                              });
                            }
                            // this.doSearch(e.target.value)
                          }}
                        />
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* <div style={{ textAlign: 'right' }}>
									<Input
										icon='search'
										placeholder='Search...'
										value={this.state.searchTxt}
										onChange={
											(e) => {
												const value = e.target.value
												const regex = /^[^-\s]/
												if (!regex.test(value) && value.length > 0) {
													this.setState({ searchTxt: '' })
												} else {
													this.setState({ searchTxt: value }, () => { this.doSearch(this.state.searchTxt) })

												}
												// this.doSearch(e.target.value)
											}
										}
									/>
						</div> */}
            <Segment
              attached="bottom"
              textAlign="center"
              loading={this.state.loading}
            >
              <div
                style={{
                  paddingBottom: 20,
                  overflowX: "auto",
                }}
              >
                {this.state.data.length > 0 ? (
                  <Grid>
                    <Grid.Row only="tablet computer">
                      <Grid.Column>
                        <Table padded celled textAlign="center">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell style={{ width: "5%" }}>
                                ลำดับ
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                style={{ width: "25%", padding: "10px" }}
                                textAlign="left"
                              >
                                <div style={{ marginLeft: "40%" }}>
                                  <span>ชื่อผู้ประกอบการ</span>
                                  {/* {this.state.activeItem === 'อนุมัติ' ? (
														<span style={{ float: 'right' }}>
														<span>Export to </span>
														<Button circular size='mini' color='green' icon='file excel' />
														<Button circular size='mini' color='red' icon='file pdf' />
														</span>
													) : null} */}
                                </div>
                              </Table.HeaderCell>
                              {/* <Table.HeaderCell>ประเภท</Table.HeaderCell> */}

                              {this.state.activeItem === "อนุมัติ" ? null : (
                                <React.Fragment>
                                  <Table.HeaderCell style={{ width: "10%" }}>
                                    ผู้ประสานงาน
                                  </Table.HeaderCell>
                                  <Table.HeaderCell style={{ width: "10%" }}>
                                    เบอร์โทรศัพท์
                                  </Table.HeaderCell>
                                </React.Fragment>
                              )}

                              {/* <Table.HeaderCell>อีเมลล์</Table.HeaderCell> */}
                              <Table.HeaderCell style={{ width: "15%" }}>
                                {this.handleDateTitleTable()}
                              </Table.HeaderCell>
                              {this.state.activeItem === "อนุมัติ" ? (
                                <Table.HeaderCell style={{ width: "15%" }}>
                                  วันที่แก้ไขข้อมูลล่าสุด
                                </Table.HeaderCell>
                              ) : null}
                              {this.state.activeItem === "อนุมัติ" ? (
                                <Table.HeaderCell style={{ width: "5%" }}>
                                  หมายเหตุ
                                </Table.HeaderCell>
                              ) : null}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.state.data &&
                              this.state.data.map((data, index) => {
                                if (
                                  index >
                                    this.state.rowQty *
                                      (this.state.currentPage - 1) -
                                      1 &&
                                  index <
                                    this.state.rowQty * this.state.currentPage
                                ) {
                                  // ### handle condition approve table company show the standard_qc_expier
                                  let date_standard_qc_expire = new Date(
                                    data.standard_qc_expire
                                  );
                                  let color_qc_expired = "";
                                  if (date_standard_qc_expire <= date_now) {
                                    color_qc_expired =
                                      props.approve_table_company
                                        .color_qc_expired_notes;
                                  }
                                  //  ###

                                  return (
                                    <React.Fragment key={index}>
                                      <Table.Row textAlign="center">
                                        {/* แสดงลำดับจากมากไปน้อย */}
                                        <Table.Cell>
                                          {this.state.data.length - index}
                                        </Table.Cell>
                                        {/* แสดงชื่อผู้ประกอบการ */}
                                        <Table.Cell>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            {/* <Header as='h4' floated='left'> */}
                                            <div
                                              style={{
                                                textAlign: "left",
                                                alignSelf: "center",
                                                maxWidth: "70%",
                                              }}
                                            >
                                              <Link
                                                to={`/oie/detailCompany/${
                                                  data.companies_id
                                                }/${this.state.menuStatus}/${
                                                  data.type_registration
                                                    ? data.type_registration
                                                    : ""
                                                }`}
                                                onClick={() => {
                                                  this.props.dispatch(
                                                    setApprovedStatus(
                                                      data.approved
                                                    )
                                                  );
                                                }}
                                              >
                                                {/* <Container textAlign='left'> */}
                                                {data.company_name}
                                                {/* <br /> */}
                                                {/* {`(${data.name_en})`} */}
                                                {/* </Container> */}
                                              </Link>
                                            </div>

                                            {/* </Header> */}
                                            {/* <Header as='h6' floated='right'> */}
                                            {
                                              this.state.activeItem ===
                                                "อนุมัติ" && (
                                                <div style={{ width: "75px" }}>
                                                  <Button
                                                    circular
                                                    size="mini"
                                                    color="green"
                                                    icon="file excel"
                                                    onClick={() => {
                                                      this.exportExcelCompanyApprove(
                                                        data.companies_id,
                                                        data.company_name,
                                                        "excel"
                                                      );
                                                    }}
                                                  />
                                                  {/* <Button
																					circular
																					size='mini' 
																					color='red' 
																					icon='file pdf' 
																				/> */}
                                                </div>
                                              )

                                              // <span style={{ float: 'right' }}>

                                              // </span>
                                            }
                                          </div>
                                          {/* </Header> */}

                                          {/* <Header as='h4' color='violet'> */}

                                          {/* </Header> */}
                                        </Table.Cell>
                                        {/* <Table.Cell collapsing>{data.type_company}</Table.Cell> */}
                                        {/* <Table.Cell
															>{`${data.address} ${data.soi} ${data.road} ${data.subdistrict} ${data.district} ${data.province}`}
															</Table.Cell> */}
                                        {this.state.activeItem ===
                                        "อนุมัติ" ? null : (
                                          <React.Fragment>
                                            <Table.Cell collapsing>
                                              {`${data.person_name}`}
                                              <br />
                                              {/* {`(${data.person_name_en})`} */}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {data.person_phone}
                                            </Table.Cell>
                                          </React.Fragment>
                                        )}

                                        {/* <Table.Cell collapsing>{data.person_email}</Table.Cell> */}
                                        <Table.Cell>
                                          {this.handleDateType(data)}
                                        </Table.Cell>
                                        {/* วันที่แก้ไขข้อมูลล่าสุด แสดงเฉพาะช่อง อนุมัติ */}
                                        {this.state.activeItem ===
                                          "อนุมัติ" && (
                                          <Table.Cell collapsing>
                                            {data.last_approve_edited_data_oie_at ? (
                                              <React.Fragment>
                                                <div>
                                                  {moment(
                                                    data.last_approve_edited_data_oie_at
                                                  ).format("DD-MM-YYYY ")}
                                                </div>
                                                <div>
                                                  {moment(
                                                    data.last_approve_edited_data_oie_at
                                                  ).format("HH:mm")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              "-"
                                            )}
                                          </Table.Cell>
                                        )}
                                        {/* หมายเหตุ */}
                                        {this.state.activeItem ===
                                          "อนุมัติ" && (
                                          <Table.Cell collapsing>
                                            {data.standard_qc_expire ? (
                                              <React.Fragment>
                                                <div
                                                  className={color_qc_expired}
                                                >
                                                  {moment(
                                                    data.standard_qc_expire
                                                  ).format("DD-MM-YYYY")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              "-"
                                            )}
                                          </Table.Cell>
                                        )}
                                      </Table.Row>
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                          </Table.Body>
                        </Table>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <label style={{ alignSelf: "center" }}>Row</label>
                          <Dropdown
                            placeholder="Row"
                            selection
                            compact
                            name="rowQty"
                            value={this.state.rowQty}
                            options={allSizeRow}
                            onChange={this.changeRowSize}
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              width: 60,
                            }}
                          />
                          <label style={{ alignSelf: "center" }}>Page</label>
                          <Pagination
                            boundaryRange={1}
                            activePage={this.state.currentPage}
                            siblingRange={1}
                            name="currentPage"
                            totalPages={this.state.pageQty}
                            onPageChange={(e, { name, activePage }) =>
                              this.changePage(name, Math.ceil(activePage))
                            }
                            style={{ marginLeft: 10, marginRight: 10 }}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row only="mobile">
                      <Grid.Column>
                        <Table textAlign="left" celled>
                          <Table.Body>
                            {this.state.data &&
                              this.state.data.map((data, index) => {
                                if (
                                  index >
                                    this.state.rowQty *
                                      (this.state.currentPage - 1) -
                                      1 &&
                                  index <
                                    this.state.rowQty * this.state.currentPage
                                ) {
                                  // ### handle condition approve table company show the standard_qc_expier
                                  let date_standard_qc_expire = new Date(
                                    data.standard_qc_expire
                                  );
                                  let color_qc_expired = "";
                                  if (date_standard_qc_expire <= date_now) {
                                    color_qc_expired =
                                      props.approve_table_company
                                        .color_qc_expired_notes;
                                  }
                                  //  ###

                                  return (
                                    <React.Fragment key={index}>
                                      <Table.Row>
                                        {/* แสดงลำดับจากมากไปน้อย */}
                                        <Table.Cell>
                                          <span
                                            style={{ fontWeight: "bolder" }}
                                          >
                                            ลำดับ :
                                          </span>{" "}
                                          {this.state.data.length - index}
                                        </Table.Cell>
                                        {/* แสดงชื่อผู้ประกอบการ */}
                                        <Table.Cell>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            {/* <Header as='h4' floated='left'> */}
                                            <div
                                              style={{
                                                textAlign: "left",
                                                alignSelf: "center",
                                                maxWidth: "70%",
                                              }}
                                            >
                                              <span
                                                style={{ fontWeight: "bolder" }}
                                              >
                                                ชื่อผู้ประกอบการ :
                                              </span>{" "}
                                              <Link
                                                to={`/oie/detailCompany/${
                                                  data.companies_id
                                                }/${this.state.menuStatus}/${
                                                  data.type_registration
                                                    ? data.type_registration
                                                    : ""
                                                }`}
                                                onClick={() => {
                                                  this.props.dispatch(
                                                    setApprovedStatus(
                                                      data.approved
                                                    )
                                                  );
                                                }}
                                              >
                                                {/* <Container textAlign='left'> */}
                                                {data.company_name}
                                                {/* <br /> */}
                                                {/* {`(${data.name_en})`} */}
                                                {/* </Container> */}
                                              </Link>
                                            </div>

                                            {/* </Header> */}
                                            {/* <Header as='h6' floated='right'> */}
                                            {
                                              this.state.activeItem ===
                                                "อนุมัติ" && (
                                                <div style={{ width: "75px" }}>
                                                  <Button
                                                    circular
                                                    size="mini"
                                                    color="green"
                                                    icon="file excel"
                                                    onClick={() => {
                                                      this.exportExcelCompanyApprove(
                                                        data.companies_id,
                                                        data.company_name,
                                                        "excel"
                                                      );
                                                    }}
                                                  />
                                                  {/* <Button
																					circular
																					size='mini' 
																					color='red' 
																					icon='file pdf' 
																				/> */}
                                                </div>
                                              )

                                              // <span style={{ float: 'right' }}>

                                              // </span>
                                            }
                                          </div>
                                          {/* </Header> */}

                                          {/* <Header as='h4' color='violet'> */}

                                          {/* </Header> */}
                                        </Table.Cell>
                                        {/* <Table.Cell collapsing>{data.type_company}</Table.Cell> */}
                                        {/* <Table.Cell
															>{`${data.address} ${data.soi} ${data.road} ${data.subdistrict} ${data.district} ${data.province}`}
															</Table.Cell> */}
                                        {this.state.activeItem ===
                                        "อนุมัติ" ? null : (
                                          <React.Fragment>
                                            <Table.Cell collapsing>
                                              <span
                                                style={{ fontWeight: "bolder" }}
                                              >
                                                ผู้ประสานงาน :
                                              </span>{" "}
                                              {`${data.person_name}`}
                                              <br />
                                              {/* {`(${data.person_name_en})`} */}
                                            </Table.Cell>
                                            <Table.Cell>
                                              <span
                                                style={{ fontWeight: "bolder" }}
                                              >
                                                เบอร์โทรศัพท์ :
                                              </span>{" "}
                                              {data.person_phone}
                                            </Table.Cell>
                                          </React.Fragment>
                                        )}

                                        {/* <Table.Cell collapsing>{data.person_email}</Table.Cell> */}
                                        <Table.Cell>
                                          {/* {this.handleDateType(data)} */}
                                          {this.handleDateTypeMobile(data)}
                                        </Table.Cell>
                                        {/* วันที่แก้ไขข้อมูลล่าสุด แสดงเฉพาะช่อง อนุมัติ */}
                                        {this.state.activeItem ===
                                          "อนุมัติ" && (
                                          <Table.Cell collapsing>
                                            {data.last_approve_edited_data_oie_at ? (
                                              <React.Fragment>
                                                <div>
                                                  <span
                                                    style={{
                                                      fontWeight: "bolder",
                                                    }}
                                                  >
                                                    วันที่แก้ไขข้อมูลล่าสุด :
                                                  </span>{" "}
                                                  {moment(
                                                    data.last_approve_edited_data_oie_at
                                                  ).format("DD-MM-YYYY ")}
                                                  {moment(
                                                    data.last_approve_edited_data_oie_at
                                                  ).format("HH:mm")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              "-"
                                            )}
                                          </Table.Cell>
                                        )}
                                        {/* หมายเหตุ */}
                                        {this.state.activeItem ===
                                          "อนุมัติ" && (
                                          <Table.Cell collapsing>
                                            {data.standard_qc_expire ? (
                                              <React.Fragment>
                                                <div
                                                  className={color_qc_expired}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: "bolder",
                                                    }}
                                                  >
                                                    หมายเหตุ :
                                                  </span>{" "}
                                                  {moment(
                                                    data.standard_qc_expire
                                                  ).format("DD-MM-YYYY")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              "-"
                                            )}
                                          </Table.Cell>
                                        )}
                                      </Table.Row>
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                          </Table.Body>
                        </Table>
                        <div
                          style={{
                           textAlign:'right'
                          }}
                        >
                          Row
                          <Dropdown
                            placeholder="Row"
                            selection
                            compact
                            name="rowQty"
                            value={this.state.rowQty}
                            options={allSizeRow}
                            onChange={this.changeRowSize}
                            
                          />
                         Page
                          <Pagination
                            boundaryRange={0}
                            activePage={this.state.currentPage}
                            siblingRange={0}
                            name="currentPage"
                            totalPages={this.state.pageQty}
                            onPageChange={(e, { name, activePage }) =>
                              this.changePage(name, Math.ceil(activePage))
                            }
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "200px",
                      paddingBottom: "200px",
                    }}
                  >
                    <i
                      className="big frown outline"
                      style={{ height: "50px" }}
                    />
                    <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
                  </div>
                )}
              </div>
            </Segment>
          </Container>
        </div>
      </div>
    );
  }
}

Home.defaultProps = {
  approve_table_company: {
    color_qc_expired_notes: "standard_qc_expired_color_condition",
  },
};

const mapStateToProps = function (state) {
  return {
    message: "This is message from mapStateToProps",
    stateForm: state.StoreCompanyResponse.approved,
  };
};

export default connect(mapStateToProps)(Home);
