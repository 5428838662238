import React, { Component, createRef } from "react";
import { connect } from "react-redux"; // connect redux
import axios from "axios";
import {
  Grid,
  Button,
  Table,
  Icon,
  Sticky,
  Ref,
  Rail,
  Header,
  Menu,
  Label,
} from "semantic-ui-react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import background_c1 from "../../assets/img/base_stricker-C1.png";
import background_c2 from "../../assets/img/base_stricker-C2.png";
import background_c3 from "../../assets/img/base_stricker-C3.png";
import background_c3_special from "../../assets/img/base_sticker-C3-special.png";
import vector1 from "../../assets/img/vector1.png";
import vector2 from "../../assets/img/vector2.png";
import volume1 from "../../assets/img/volume_1.png";
import volume2 from "../../assets/img/volume_2.png";
import volume3 from "../../assets/img/volume_3.png";
import water_mark from "../../assets/img/watermark.png";
import {
  GET_DRAFT_STICKER_DATA,
  HOST_PATH_FILE,
  GET_REQUEST_DATA_BY_ID,
  GET_STICKER_DATA_BY_SIZE_ID,
} from "../router";
import { customCompany } from "./customCompany";
import Headers from "../header/requestHeader";
import ApprovalHeader from "../approval/ApprovalHeader";
import Swal from "sweetalert2";
import Requestbutton from "./requestButton";

var QRCode = require("qrcode.react");

const requestLicensePath = `/company/request-license`;
const requestDetailPath = `/company/request-detail`;
const requestTestPath = `/company/request-test`;

class previewApprove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productIndex: 0,
      selected_file: "",
      openStickerPreview: false,
    };
  }
  contextRef = createRef();
  tableHeadRef = createRef();
  componentWillMount = async () => {
    if (window.location.href.includes("request-preview")) {
      await this.setState(this.props.requestRedux);

      await this.setState({
        fileServer: this.state.isOpenDraft ? true : false,
        selected_file: this.state.productRequest[0].licenseNumberFile.path,
      });
    } else if (window.location.href.includes("preview_product")) {
      this.getRequestData(this.props.location.state.tyre_id);

      await this.setState(this.props.requestRedux);
    }
  };

  getRequestData = async (request_id) => {
    this.setState({ loader_request: true });

    try {
      // // console.log("request_id -> ", request_id);
      let request_data = await axios.get(GET_REQUEST_DATA_BY_ID + request_id);
      let data = request_data.data.value.tyre_requests;

      this.setState({ draftName: data.tyre_requests_name });
      let productArr = [];
      let preState = this.state;

      // preState.approve_oie_tisi = data.approve_oie_tisi;
      // preState.approve_technical = data.approve_technical;

      preState.created_at = data.created_at;

      // preState.approve_oie_tisi = 2;
      // preState.approve_technical = 2;

      data.product_requests.map((product, prod_idx) => {
        // console.log(prod_idx);
        let d = {
          id: product.id,
          productId: product.products_id,
          productTypeId: product.products.type_product_id.toString(),
          productName: product.products.product_struct,
          license: "",
          licenseNumber: product.license_request,
          licenseNumberFile: {
            name: product.file_filename_product_request,
            data: [],
            path: product.file_path_product_request,
          },
          listProduct: product.list_request,
          brandsRequest: product.brand_requests.map((brand, brand_idx) => {
            return {
              id: brand.id,
              brandId: brand.brands_id,
              brandName: brand.brands.name,
              genRequest: brand.gen_requests.map((gen, gen_idx) => {
                return {
                  id: gen.id,
                  genName: gen.gen_name,
                  genLayoutFile: {
                    name: gen.file_filename_gen_request,
                    data: [],
                    path: gen.file_path_gen_request,
                  },
                  sizeRequest: gen.size_requests.map((size, size_idx) => {
                    return {
                      id: size.id,
                      sizeGen: size.size_text,
                      sizeCapacity: size.speed_symbol,
                      sizeSpecialFeature: size.special_feature,
                      sizeStructure: size.size_struct,
                      sizeTypeOfUse: size.type_use,
                      sizeCuttingWidth: size.cutting_width,
                      sizeSymbol: size.symbol,
                    };
                  }),
                };
              }),
            };
          }),
          testResult: {
            id: product.test_results.id,
            testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
            testValue_a: product.test_results.rs_test_value.toString(),
            testUnit_a: "N/kN",
            brandName_a: product.test_results.rs_brand_request_value,
            genName_a: product.test_results.rs_gen_request_value,
            sizeGen_a: product.test_results.rs_size_request_value,
            testPage_a: product.test_results.rs_test_page,
            testFile_a: {
              name: product.test_results.rs_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_a
                      .data
                  : [],
              path: product.test_results.rs_file_path,
            },

            testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
            testValue_b: product.test_results.rg_test_value.toString(),
            testUnit_b: "G",
            brandName_b: product.test_results.rg_brand_request_value,
            genName_b: product.test_results.rg_gen_request_value,
            sizeGen_b: product.test_results.rg_size_request_value,
            testPage_b: product.test_results.rg_test_page,
            testFile_b: {
              name: product.test_results.rg_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_b
                      .data
                  : [],
              path: product.test_results.rg_file_path,
            },

            testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
            testValue_c: product.test_results.tr_test_value.toString(),
            testUnit_c: "dB(A)",
            brandName_c: product.test_results.tr_brand_request_value,
            genName_c: product.test_results.tr_gen_request_value,
            sizeGen_c: product.test_results.tr_size_request_value,
            testPage_c: product.test_results.tr_test_page,
            testFile_c: {
              name: product.test_results.tr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_c
                      .data
                  : [],
              path: product.test_results.tr_file_path,
            },

            testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
            testValue_d: product.test_results.sr_test_value.toString(),
            testUnit_d: "SG",
            brandName_d: product.test_results.sr_brand_request_value,
            genName_d: product.test_results.sr_gen_request_value,
            sizeGen_d: product.test_results.sr_size_request_value,
            testPage_d: product.test_results.sr_test_page,
            testFile_d: {
              name: product.test_results.sr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_d
                      .data
                  : [],
              path: product.test_results.sr_file_path,
            },

            // testFile: {
            //   name: product.test_results.file_filename_test_result,
            //   data: [],
            //   path: product.test_results.file_path_test_result
            // },
            // testPage: product.test_results.test_page
          },
        };
        return productArr.push(d);
      });

      // // console.log("SJDKFJSLKDJFKLSJFKLSJFLKSDFKLSDJFKL:S",data.id)
      preState.tyre_requests_id = data.id;
      // // console.log("preState.tyre_requests_id", preState.tyre_requests_id);
      preState.user_type =
        data.factories.type_factories_id === "1"
          ? "ผู้ผลิต"
          : data.factories.type_factories_id === "2"
          ? "ผู้นำเข้า"
          : "";
      // preState.productTypeOptions =
      //   factory !== null
      //     ? factory.find(f => f.id === data.factories_id).product_type_options
      //     : [];
      preState.factory = data.factories.name_th;
      preState.factoryTypeId = data.factories.type_factories_id;
      preState.factoryId = data.factories_id;
      preState.productRequest = productArr;

      // // console.log("draft", request_data.data.value);
      // console.log("prestate", preState);
      await this.setState(preState);

      // console.log("file path", this.state.productRequest[0].licenseNumberFile.path);
      await this.setState({
        fileServer: true,
        selected_file: this.state.productRequest[0].licenseNumberFile.path,
      });
      // console.log('this state',this.state)
      this.setState({ loader_request: false });
      return true;
    } catch (error) {
      console.log(error);
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
        confirmButtonText: "ตกลง",
      });
      this.setState({ loader_request: false });
      return false;
    }
  };

  goBack() {
    if (window.location.href.includes("request-preview")) {
      switch (this.state.currentPage) {
        case "license":
          this.props.history.push(requestLicensePath);
          break;

        case "detail":
          this.props.history.push(requestDetailPath);
          break;

        case "test":
          this.props.history.push(requestTestPath);
          break;

        default:
          break;
      }
    } else if (window.location.href.includes("preview_product")) {
      // console.log(this.props.location.state.tyre_id);
      this.props.history.push("/approval/product_detail", {
        id: this.props.location.state.tyre_id,
      });
    }
  }

  openSticker = async (prod, brand, gen, size) => {
    var form = this.state;
    var data = {
      companies_id: form.companyId,
      factories_id: form.factoryId,
      type_product_id: parseInt(prod.productTypeId),
      type_struct_id: parseInt(prod.productTypeStructId),
      brands_name: brand.brandName,
      gen_name: gen.genName,
      size_request: {
        size_text: size.sizeGen, //  ชื่อขนาด
        special_feature: size.sizeSpecialFeature, // คุณลักษณะพิเศษ (Extraload)
        type_use: size.sizeTypeOfUse, // ประเภทใช้งาน (ทั่วไป)
        cutting_width: size.sizeCuttingWidth, // มีเฉพาะ C1
        symbol: size.sizeSymbol, // เครื่องหมาน (S1WR1)
        load_capacity: size.sizeCapacity,
        speed_symbol: size.sizeSpeedSymbol,
      },
      test_result: {
        rs_test_value: prod.testResult.testValue_a, // ค่าการหมุน
        rs_test_unit: prod.testResult.testUnit_a, // หน่วยหมุน
        rg_test_value: prod.testResult.testValue_b, // ค่ายึดเกาะพิ้นเปียก
        rg_test_unit: prod.testResult.testUnit_b, // หน่วยยึดเกาะพิ้นเปียก
        sr_test_value: prod.testResult.testValue_d, // ค่าระดับเสียงจากยางล้อที่สัมผัสถนน
        sr_test_unit: prod.testResult.testUnit_d, // หน่วยระดับเสียงจากยางล้อที่สัมผัสถนน
        tr_test_value: prod.testResult.testValue_c, // ค่าเกาะถนนหิมะ
        tr_test_unit: prod.testResult.testUnit_c, // หน่วยเกาะถนนหิมะ
      },
    };

    try {
      let res = await axios.post(GET_DRAFT_STICKER_DATA, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      await this.setState({
        openStickerPreview: true,
        stickerData: res.data.sticker,
      });
      // console.log("state stickerData", this.state.stickerData);
      // await this.setState(res.data.sticker);
      // return res.data.sticker;
      // console.log("after ", this.state);
    } catch (error) {
      console.log(error);
    }
  };

  getStickerData = async (id) => {
    try {
      let res = await axios.get(GET_STICKER_DATA_BY_SIZE_ID + id);
      // console.log("response", res.data);
      // await this.setState(res.data.sticker);
      let stickerData = res.data.sticker;
      stickerData.sizeId = id;

      await this.setState({
        openStickerPreview: true,
        sizeID: id,
        stickerData: stickerData,
      });

      // console.log("after ", this.state);
    } catch (error) {
      console.log(error);
    }
  };

  findFactory = () => {
    var form = this.state;

    var factoryName;
    if (form.user_type === "ผู้นำเข้า") {
      factoryName = form.factoryImportTypeOptions.find(
        (f) => f.id === form.factory
      );
    } else {
      factoryName = form.factoryManufacturerTypeOptions.find(
        (f) => f.id === form.factory
      );
    }

    return factoryName ? factoryName.text : "-";
  };

  scrollToRef = (ref) =>
    window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop });

  render() {
    if (
      this.state.companyId === "" &&
      window.location.href.includes("request-preview")
    ) {
      window.location.href = "request-license";
    }

    // let tableData_list = [];
    let tableFactory;
    let tableProduct;
    let tableProductList = [];
    let form = this.state;

    tableFactory = (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2" width={1}>
              <Icon name="user" />
              ข้อมูลการร้องขอ
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>ประเภทผู้ยื่นขอ</Table.Cell>
            <Table.Cell>
              {form.user_type !== "" ? form.user_type : "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>โรงงาน</Table.Cell>
            <Table.Cell>
              {/* form.factoryImportTypeOptions.find(f => f.id === form.factory).text */}

              {window.location.href.includes("preview_product")
                ? form.factory
                : this.findFactory()}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );

    form.productRequest.map((product, prod_idx) => {
      tableProduct = (
        <Table key={prod_idx} celled striped style={{ wordBreak: "break-all" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2" width={1}>
                <Icon name="user" />
                ข้อมูลผลิตภัณฑ์ที่ {prod_idx + 1 + ` : ` + product.productName}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {product.licenseType === 1 ? (
              <React.Fragment>
                <Table.Row>
                  <Table.Cell>
                    เลขที่ใบอนุญาต มอก.
                    {product.productTypeId === "1" ? "2718" : "2719"}-2560
                  </Table.Cell>
                  <Table.Cell>
                    {product.licenseNumber !== "" ? product.licenseNumber : "-"}{" "}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>หน้าที่</Table.Cell>
                  <Table.Cell>
                    {" "}
                    {product.listProduct !== ""
                      ? product.listProduct
                      : "-"}{" "}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan="2">
                    <Button
                      fluid
                      size="large"
                      onClick={async () => {
                        this.setState({
                          selected_file: product.licenseNumberFile.path,
                          openStickerPreview: false,
                        });
                        document
                          .getElementById("iframe_mobile")
                          .scrollIntoView({
                            behavior: "smooth",
                          });
                      }}
                      color="orange"
                    >
                      <Icon name="file outline" />
                      ดูเอกสารใบอนุญาต มอก.
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ) : (
              <Table.Row>
                <Table.Cell>เลขที่ใบอนุญาต มอก. 2721-2560</Table.Cell>
                <Table.Cell>{product.requestNO}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">การทดสอบความต้านทานการหมุน </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_a.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_a.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_a.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ค่าสัมประสิทธิ์ของความต้านทานการหมุน</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_a !== ""
                  ? product.testResult.testValue_a
                  : "-"}{" "}
                {product.testResult.testUnit_a}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2">
                <Button
                  size="large"
                  fluid
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_a.path,
                      openStickerPreview: false,
                    });
                    document.getElementById("iframe_mobile").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  ดูเอกสารการทดสอบความต้านทานการหมุน
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบความต้านทานการหมุน
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_a !== ""
                  ? product.testResult.testPage_a
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">การทดสอบการยึดเกาะถนนบนพื้นเปียก </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_b.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_b.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_b.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_b !== ""
                  ? product.testResult.testValue_b
                  : "-"}{" "}
                {product.testResult.testUnit_b}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2">
                <Button
                  size="large"
                  fluid
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_b.path,
                      openStickerPreview: false,
                    });
                    document.getElementById("iframe_mobile").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  ดูเอกสารการทดสอบการยึดเกาะถนนบนพื้นเปียก
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบการยึดเกาะถนนบนพื้นเปียก
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_b !== ""
                  ? product.testResult.testPage_b
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">
                  การทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน{" "}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_c.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_c.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_c.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_c !== ""
                  ? product.testResult.testValue_c
                  : "-"}{" "}
                {product.testResult.testUnit_c}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2">
                <Button
                  size="large"
                  fluid
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_c.path,
                      openStickerPreview: false,
                    });
                    document.getElementById("iframe_mobile").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  ดูเอกสารการทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_c !== ""
                  ? product.testResult.testPage_c
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">การทดสอบการเกาะถนนหิมะ </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_d.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_d.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_d.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ค่าดัชนีการเกาะถนนหิมะ</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_d !== ""
                  ? product.testResult.testValue_d
                  : "-"}{" "}
                {product.testResult.testUnit_d}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2">
                <Button
                  size="large"
                  fluid
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_d.path,
                      openStickerPreview: false,
                    });
                    document.getElementById("iframe_mobile").scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  ดูเอกสารการทดสอบการเกาะถนนหิมะ
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบการเกาะถนนหิมะ
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_d !== ""
                  ? product.testResult.testPage_d
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2" positive>
                <Header as="h5">
                  <Icon name="file outline" />
                  ข้อมูลชื่อทางการค้า
                </Header>
              </Table.Cell>
            </Table.Row>

            {product.brandsRequest.map((brand, brand_idx) => (
              <React.Fragment key={brand_idx}>
                <Table.Row>
                  <Table.Cell colSpan="2">
                    <Table color={"yellow"} celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan="2" width={1}>
                            <Icon name="circle" /> ชื่อทางการค้าที่{" "}
                            {brand_idx + 1} : {brand.brandName}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {brand.genRequest.map((gen, gen_idx) => (
                          <React.Fragment key={gen_idx}>
                            <Table.Row>
                              <Table.Cell colSpan="2">
                                <Table color={"olive"} size="small" celled>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell colSpan="2" width={1}>
                                        <Grid columns="equal" stackable>
                                          <Grid.Column width={8}>
                                            <Icon name="dot circle" />
                                            รุ่นที่ {gen_idx + 1} :{" "}
                                            {gen.genName !== ""
                                              ? gen.genName
                                              : "-"}{" "}
                                          </Grid.Column>
                                          <Grid.Column>
                                            <Button
                                              fluid
                                              size="tiny"
                                              // floated="right"
                                              onClick={() => {
                                                this.setState({
                                                  selected_file:
                                                    gen.genLayoutFile.path,
                                                  openStickerPreview: false,
                                                });
                                                document
                                                  .getElementById(
                                                    "iframe_mobile"
                                                  )
                                                  .scrollIntoView({
                                                    behavior: "smooth",
                                                  });
                                              }}
                                              color="orange"
                                            >
                                              <Icon name="file outline" />
                                              ดูเอกสารแบบทางวิศวกรรม
                                            </Button>
                                          </Grid.Column>
                                        </Grid>
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                    {gen.sizeRequest.map((size, size_idx) => (
                                      <React.Fragment key={size}>
                                        <Table.Row>
                                          <Table.Cell colSpan="2">
                                            <Table
                                              color={"olive"}
                                              size="small"
                                              inverted
                                            >
                                              <Table.Header>
                                                <Table.Row>
                                                  <Table.HeaderCell
                                                    colSpan="2"
                                                    width={1}
                                                  >
                                                    <Icon name="dot circle" />
                                                    ขนาด {size_idx + 1} :{" "}
                                                    {size.sizeGen !== ""
                                                      ? size.sizeGen
                                                      : "-"}
                                                    {size.sizeSymbol.startsWith(
                                                      "S2"
                                                    ) && (
                                                      <Label
                                                        color="red"
                                                        style={{
                                                          float: "right",
                                                        }}
                                                        size={"large"}
                                                      >
                                                        Stage 2
                                                      </Label>
                                                    )}
                                                  </Table.HeaderCell>
                                                </Table.Row>
                                              </Table.Header>

                                              <Table.Body>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    ดัชนีความสามารถการรับโหลด
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {size.sizeCapacity !== ""
                                                      ? size.sizeCapacity
                                                      : "-"}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    สัญลักษณ์ความเร็ว
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {size.sizeSpeedSymbol !== ""
                                                      ? size.sizeSpeedSymbol
                                                      : "-"}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    คุณลักษณะพิเศษ
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {size.sizeSpecialFeature !==
                                                    ""
                                                      ? size.sizeSpecialFeature
                                                      : "-"}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    โครงสร้าง
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {size.sizeStructure !== ""
                                                      ? size.sizeStructure
                                                      : "-"}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    ประเภทใช้งาน
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {size.sizeTypeOfUse !== ""
                                                      ? size.sizeTypeOfUse
                                                      : "-"}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    ความกว้างภาคตัดระบุ
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {size.sizeCuttingWidth !==
                                                    ""
                                                      ? size.sizeCuttingWidth
                                                      : "-"}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    เครื่องหมาย
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {size.sizeSymbol !== ""
                                                      ? size.sizeSymbol
                                                      : "-"}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell colSpan="2">
                                                    <Button
                                                      onClick={async () => {
                                                        window.location.href.includes(
                                                          "request-preview"
                                                        )
                                                          ? this.openSticker(
                                                              product,
                                                              brand,
                                                              gen,
                                                              size
                                                            )
                                                          : this.getStickerData(
                                                              size.id
                                                            );

                                                        document
                                                          .getElementById(
                                                            "iframe_mobile"
                                                          )
                                                          .scrollIntoView({
                                                            behavior: "smooth",
                                                          });
                                                      }}
                                                      color="green"
                                                      size="large"
                                                      fluid
                                                    >
                                                      <Icon name="file outline" />
                                                      ดู Sticker ของรุ่น{" "}
                                                      {gen.genName !== ""
                                                        ? gen.genName
                                                        : "-"}{" "}
                                                      / ขนาด{" "}
                                                      {size.sizeGen !== ""
                                                        ? size.sizeGen
                                                        : "-"}
                                                    </Button>
                                                  </Table.Cell>
                                                </Table.Row>
                                              </Table.Body>
                                            </Table>
                                          </Table.Cell>
                                        </Table.Row>
                                      </React.Fragment>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </Table.Cell>
                            </Table.Row>
                          </React.Fragment>
                        ))}
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      );

      return tableProductList.push(tableProduct);
    });

    // tableProductBlank = (
    //   <Table celled striped>
    //     <Table.Header>
    //       <Table.Row>
    //         <Table.HeaderCell colSpan="2" width={1}>
    //           <Icon name="user" />
    //           ข้อมูลผลิตภัณฑ์ที่ 1
    //         </Table.HeaderCell>
    //       </Table.Row>

    //     </Table.Header>

    //     <Table.Body>
    //       <Table.Row>
    //         <Table.Cell colSpan="2" warning> <Header as='h5'> ชื่อทางการค้าที่ 1</Header></Table.Cell>
    //       </Table.Row>
    //       <Table.Row>
    //         <Table.Cell>เลขที่ใบอนุญาต มอก.2718-2560</Table.Cell>
    //         <Table.Cell> - </Table.Cell>
    //       </Table.Row>
    //       <Table.Row>
    //         <Table.Cell>รายการที่</Table.Cell>
    //         <Table.Cell> - </Table.Cell>
    //       </Table.Row>
    //       <Table.Row>
    //         <Table.Cell>ใบอนุญาต</Table.Cell>
    //         <Table.Cell><Button
    //           onClick={() => {

    //           }}
    //           color="orange"
    //         >
    //           <Icon name="file outline" />
    //           Preview
    //             </Button>
    //         </Table.Cell>
    //       </Table.Row>

    //           <Table.Row>
    //             <Table.Cell>
    //               <Header as='h5'> รุ่นที่ 1 </Header>
    //             </Table.Cell>
    //             <Table.Cell>-</Table.Cell>
    //           </Table.Row>
    //           <Table.Row>
    //             <Table.Cell>แบบทางวิศวกรรม</Table.Cell>
    //             <Table.Cell><Button
    //               onClick={() => {

    //               }}
    //               color="orange"
    //             >
    //               <Icon name="file outline" />
    //               Preview
    //             </Button>
    //             </Table.Cell>
    //           </Table.Row>

    //             <Table.Row>
    //               <Table.Cell>
    //                 <Header as='h5'>รุ่นที่ 1 / ขนาดที่ 1</Header>
    //               </Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>ดัชนีความสามารถการรับโหลดและสัญลักษณ์ความเร็ว</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>คุณลักษณะพิเศษ</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>โครงสร้าง</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>ประเภทใช้งาน</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>ความกว้างภาคตัดระบุ</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>เครื่องหมาย</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell colSpan="2" warning>
    //                 <Header as='h5'>
    //                   การทดสอบความต้านทานการหมุน
    //                 </Header>
    //               </Table.Cell>
    //             </Table.Row>

    //             <Table.Row>
    //               <Table.Cell>ค่าสัมประสิทธิ์ของความต้านทานการหมุน</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell colSpan="2" warning>
    //                 <Header as='h5'>
    //                   การทดสอบการยึดเกาะถนนบนพื้นเปียก
    //                 </Header>
    //               </Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>

    //             <Table.Row>
    //               <Table.Cell colSpan="2" warning>
    //                 <Header as='h5'>
    //                   การทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน
    //                 </Header>
    //               </Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>

    //             <Table.Row>
    //               <Table.Cell colSpan="2" warning>
    //                 <Header as='h5'>
    //                   การทดสอบการเกาะถนนหิมะ
    //                 </Header>
    //               </Table.Cell>
    //             </Table.Row>
    //             <Table.Row>
    //               <Table.Cell>ค่าดัชนีการเกาะถนนหิมะ</Table.Cell>
    //               <Table.Cell>-</Table.Cell>
    //             </Table.Row>

    //             <Table.Row>
    //               <Table.Cell>ใบรับรองและผลการทดสอบตามมาตรฐาน UNECE R117</Table.Cell>
    //               <Table.Cell><Button
    //                 onClick={() => {

    //                 }}
    //                 color="orange"
    //               >
    //                 <Icon name="file outline" />
    //                 Preview
    //             </Button>
    //               </Table.Cell>
    //             </Table.Row>

    //             <Table.Row>
    //               <Table.Cell>หมายเลขหน้าที่แสดงผลการทดสอบทั้ง 3 ส่วน</Table.Cell>
    //               <Table.Cell> - </Table.Cell>
    //             </Table.Row>
    //     </Table.Body>
    //   </Table >)

    return (
      <div>
        {window.location.href.includes("request-preview") ? (
          this.state.params_request_id === undefined &&
          this.state.params_company_id === undefined ? (
            <Headers />
          ) : (
            <span />
          )
        ) : (
          <ApprovalHeader></ApprovalHeader>
        )}
        <div style={{ margin: 30 }}>
          <Ref innerRef={this.contextRef}>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={10} computer={8}>
                  {tableFactory}

                  <Sticky context={this.contextRef}>
                    <Menu
                      widths={3}
                      attached="top"
                      tabular
                      style={{ backgroundColor: "#fff", paddingTop: "1em" }}
                    >
                      <Menu.Item>
                        <Button
                          circular
                          icon="backward"
                          onClick={() => {
                            this.scrollToRef(this.tableHeadRef);
                            this.setState((prevState) => ({
                              productIndex: prevState.productIndex - 1,
                            }));
                          }}
                          disabled={this.state.productIndex === 0}
                        />
                      </Menu.Item>

                      <Menu.Item>
                        ข้อมูลผลิตภัณฑ์ที่ {this.state.productIndex + 1} /{" "}
                        {this.state.productRequest.length}
                      </Menu.Item>

                      <Menu.Item>
                        <Button
                          circular
                          icon="forward"
                          onClick={() => {
                            this.scrollToRef(this.tableHeadRef);
                            this.setState((prevState) => ({
                              productIndex: prevState.productIndex + 1,
                            }));
                          }}
                          disabled={
                            this.state.productIndex + 1 ===
                            this.state.productRequest.length
                          }
                        />
                      </Menu.Item>
                    </Menu>
                  </Sticky>

                  <div id="tableHead" ref={this.tableHeadRef}></div>

                  {tableProductList[this.state.productIndex]}
                </Grid.Column>
                <Grid.Column only="tablet computer" tablet={6} computer={8}>
                  <Rail
                    style={{
                      width: "100%",
                    }}
                  >
                    <Sticky context={this.contextRef}>
                      {/* <iframe
                        style={{
                          width: "100%",
                          height: window.innerHeight
                        }}
                        src={HOST_PATH_FILE + this.state.selected_file}
                      /> */}
                      {(() => {
                        if (
                          !this.state.selected_file.startsWith("blob") &&
                          this.state.fileServer === true &&
                          this.state.openStickerPreview === false
                        ) {
                          return (
                            <iframe
                              title="file_iframe_static"
                              style={{
                                width: "100%",
                                height: window.innerHeight,
                              }}
                              src={HOST_PATH_FILE + this.state.selected_file}
                            />
                          );
                        } else if (
                          this.state.selected_file !== "" &&
                          this.state.openStickerPreview === false
                        ) {
                          return (
                            <iframe
                              title="file_iframe"
                              style={{
                                width: "100%",
                                height: window.innerHeight,
                              }}
                              src={this.state.selected_file}
                            />
                          );
                        } else if (this.state.openStickerPreview) {
                          return <PDF data={this.state.stickerData} />;
                        } else {
                          return (
                            <iframe
                              title="empty_iframe"
                              style={{
                                width: "100%",
                                height: window.innerHeight,
                              }}
                            />
                          );
                        }
                      })()}
                    </Sticky>
                  </Rail>
                </Grid.Column>

                <Grid.Column id="iframe_mobile" only="mobile" mobile={16}>
                  {(() => {
                    if (
                      !this.state.selected_file.startsWith("blob") &&
                      this.state.fileServer === true &&
                      this.state.openStickerPreview === false
                    ) {
                      return (
                        <iframe
                          title="file_iframe_static"
                          style={{
                            width: "100%",
                            height: window.innerHeight,
                          }}
                          src={HOST_PATH_FILE + this.state.selected_file}
                        />
                      );
                    } else if (
                      this.state.selected_file !== "" &&
                      this.state.openStickerPreview === false
                    ) {
                      return (
                        <iframe
                          title="file_iframe"
                          style={{
                            width: "100%",
                            height: window.innerHeight,
                          }}
                          src={this.state.selected_file}
                        />
                      );
                    } else if (this.state.openStickerPreview) {
                      return <PDF data={this.state.stickerData} />;
                    } else {
                      return (
                        <iframe
                          title="empty_iframe"
                          style={{
                            width: "100%",
                            height: window.innerHeight,
                          }}
                        />
                      );
                    }
                  })()}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  {this.props.location.state.isCheckingStep ? (
                    <Requestbutton
                      history={this.props.history}
                      pos={this.state.productPos}
                      data={this.state}
                      url={"requestPreview"} // this.constructor.name
                      productLength={this.state.productRequest.length}
                      callBackParent={this.recieveProps}
                    />
                  ) : (
                    <Button
                      icon
                      labelPosition="left"
                      type="button"
                      size="large"
                      color={"violet"}
                      floated="left"
                      onClick={async () => {
                        this.goBack();
                      }}
                    >
                      ย้อนกลับ
                      <Icon name="arrow left" />
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Ref>

          {/* 
            <Button
              icon
              labelPosition="left"
              type="button"
              size="large"
              color={'green'}
              floated="right"
              onClick={async () => {
                this.setState({
                  modalSave: true
                });
              }}
            >
              ยืนยันความถูกต้องของข้อมูล
              <Icon name="checkmark" />
            </Button> */}
        </div>
      </div>
    );
  }

  recieveProps = (childData) => {
    // console.log("childData", childData);
    this.setState(childData);
  };
}

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'column',
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35
    // backgroundColor: '#E4E4E4'
  },
  header_1: {
    fontFamily: "rsu",
    color: `gray`,
    fontSize: 30,
  },
  watermark: {
    left: "10",
    top: "150",
    height: "130.3mm",
    // hegiht:'100%',
    position: `absolute`,
    zIndex: 30,
  },
  image: {
    // top: `100px`, /* your required value */
    // left: `300`,
    width: "311.81",
    height: "623.62",
    position: `absolute`,
    // height:'100%',
    // width:'100%',
    // margin:'0',
    // padding:'0',
    // zIndex: 15,
    // marginVertical: 15,
    // marginHorizontal: 100,
  },
  textWhite: {
    color: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  wrapper: {
    width: "311.81",
    height: "623",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    padding: "0",
    backgroundColor: "yellow",
  },
  wrapper_blank: {
    width: "110mm",
    height: "220mm",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    // backgroundColor: 'yellow'
    TextAlign: "center",
  },
  logo: {
    overflow: "visible",
    // alignSelf: 'flex-end',
    // backgroundColor: `green`,
    fontFamily: "rsu",
    marginTop: "4mm",
    // paddingTop: '2px',
    color: "blue",
    height: "18mm",
    textAlign: "center",
  },
  logo_txt: {
    lineHeight: "20%",
    overflow: "visible",
    position: "relative",
    top: "20px",
    // backgroundColor: 'blue',
    padding: "0",
    fontSize: 34,
    //34
    color: `red`,
  },
  title: {
    // position: 'relative',
    // top: '20px',
    lineHeight: "80%",
    fontFamily: "rsu",
    // marginTop: '2mm',
    height: "31mm",
    textAlign: "center",
    // backgroundColor: `red`
  },
  sec: {
    fontFamily: "rsu",
    width: "100%",
    height: "167mm",
    border: "2px; solid; palevioletred;",
    // backgroundColor: 'rgba(255,0,0,0) ',
    color: "red",
    textAlign: "center",
    verticalAlign: "middle",
    marginLeft: "auto",
    marginRight: "auto",
    // paddingTop:'50',
    // marginTop: '50',
    // marginBottom: '55mm'
  },
  imageSecTop: {
    display: "flex",
    flexWrap: "wrap",
    width: "103mm", // width of sec - marLeft - marRight = 75 - 5 - 5 = 65
    height: "73mm", // 70% of 110mm is 77m // height - marginTop = height --> 77 - 5 = 72mm
    // backgroundColor: 'orange',
    marginLeft: "4mm",
    marginRight: "4mm",
    marginTop: "2mm",
  },
  imageGasTank: {
    //  backgroundColor: 'green',
    width: "60%",
    height: "100%",
  },
  logoGas: {
    // backgroundColor: 'purple',
    width: "100%",
    height: "20mm", // estimation 29% of height imageSecTop(72) = 20.88
    color: "white",
  },
  wrapSecAndGradArrow: {
    width: "100%",
    height: "52mm", // height imageSecTop - height logoGas = 72 - 20 = 52
    display: "flex",
    flexWrap: "wrap",
  },
  secGrade: {
    paddingTop: "2",
    // backgroundColor: 'red',
    width: "50%",
    height: "100%",
  },
  secArrow: {
    marginTop: "5mm",
    width: "50%",
    height: "46mm",
    //  backgroundColor: 'purple',
  },
  spaceLogoGrade: {
    width: "100%",
    height: "7mm",
    // backgroundColor: 'silver'
  },
  wrapGrade: {
    width: "100%",
    height: "5mm",
    marginBottom: "1mm",
    // backgroundColor: 'cream'
  },
  gasGradeA: {
    width: "20%",
    height: "100%",
    backgroundColor: "#2E8B57",
    // border-left: 20px solid #58ff00;
    // border-top: 20px solid #00800000;
    // border-bottom: 20px solid #00800000;
  },
  gasGradeB: {
    width: "50%",
    height: "100%",
    backgroundColor: "#32CD32",
  },
  gasGradeC: {
    width: "60%",
    height: "100%",
    backgroundColor: "#ADFF2F",
  },
  gasGradeD: {
    width: "70%",
    height: "100%",
    backgroundColor: "#FFFF00",
  },
  gasGradeE: {
    width: "80%",
    height: "100%",
    backgroundColor: "#FFD700",
  },
  gasGradeF: {
    width: "90%",
    height: "100%",
    backgroundColor: "##FF6347",
  },
  gasGradeG: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FF0000",
  },
  logoSlippery: {
    // backgroundColor: 'brown',
    width: "100%",
    height: "20mm", // estimation 25% of height imageSecTop = 18
    color: "white",
  },
  imageSlippery: {
    //  backgroundColor: 'pink',
    width: "39%",
    height: "100%",
  },
  imageSecBottom: {
    display: "flex",
    flexWrap: "wrap",
    height: "24mm",
    width: "71mm", // 30% of 110mm is 33m // height - marginTop - height Of <div> image Flag = height --> 33 - 2 - 9 = 22mm
    // backgroundColor: 'black',
    marginLeft: "36mm",
    marginRight: "3mm",
    // marginTop: '2mm'
  },
  volumeSec: {
    width: "50%",
    height: "100%",
    // backgroundColor: 'green',
  },
  volumeValueSec: {
    width: "50%",
    height: "100%",
    // backgroundColor: 'pink',
  },
  tyreClassWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "7mm",
    // backgroundColor: 'green',
    // opacity: '0.5'
  },
  tyreClass: {
    color: "black",
    fontSize: "12",
    textAlign: "left",
    width: "78%", // Equal
    height: "7mm",
    // backgroundColor: 'red',
    marginLeft: "3mm",
    marginBottom: "2mm",
  },

  tyreModel: {
    color: "black",
    fontSize: "12",
    textAlign: "right",
    width: "16%", // Equal
    height: "7mm",
    // backgroundColor: 'blue',
  },
  standardWrapper: {
    display: "flex",
    flexWrap: "wrap",

    width: "100%",
    height: "60mm",
    // backgroundColor: 'red',
  },
  qrWrapper: {
    flexWrap: "wrap",
    marginLeft: "4",
    width: "31%",
    height: "100%",
    // backgroundColor: 'gray'
  },
  qrTopSection: {
    width: "95%",
    height: "56%",
    // backgroundColor: 'green',

    verticalAlign: "text-bottom",
  },
  qrTextSection: {
    position: "relative",
    top: "2",
    fontSize: "9",
    color: "black",

    width: "50%",
    // backgroundColor: 'black',
    marginTop: "1",
    marginLeft: "18",
  },
  qrBottomSection: {
    width: "52%",
    height: "30%",
    // backgroundColor: "yellow",
    // marginTop: '12',
    marginLeft: "18",
    // verticalAlign: 'text-bottom'
  },

  standardDetailWrapper: {
    position: "relative",
    top: "-5px",
    padding: "15",
    fontSize: "16",
    color: "black",
    textAlign: "left",
    width: "67%",
    height: "100%",
    // backgroundColor: 'yellow',
    marginBottom: "4",
  },
  importBy: {
    // display: "flex",
    // flexWrap: "wrap",
    // height: "50%",
    height: "auto",
    width: "95%",
    marginTop: "1",
    // backgroundColor: 'black'
  },
  importTitle: {
    height: "100%",
    width: "30%",
    // backgroundColor: 'red',
  },
  importValue: {
    marginLeft: "5",
    height: "100%",
    width: "65%",
    // backgroundColor: 'red',
  },
  produceBy: {
    // display: 'flex',
    // flexWrap: 'wrap',
    height: "30%", //30
    width: "95%",
    marginTop: "10",
    // backgroundColor: 'yellow'
  },
  importbyWrapper: {
    display: "flex",
    flexWrap: "wrap",
    // height: "48%",
    height: "auto",
    maxHeight: "34%",
    width: "100%",
    // backgroundColor: 'blue'
  },
  productbyWrapper: {
    display: "flex",
    flexWrap: "wrap",
    // height: "48%",
    height: "auto",
    maxHeight: "32%",
    width: "100%",
    // backgroundColor: 'blue'
  },

  produceTitle: {
    height: "100%",
    width: "30%",
    // backgroundColor: 'red',
  },
  produceValue: {
    // marginTop: "3",
    marginLeft: "5",
    height: "100%",
    width: "65%",
    // backgroundColor: 'red',
  },
  vector1: {
    position: "relative",
    top: "20px",
  },
});

class PDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand_name: "",
      gen_name: "",
      size_text: "",
      rolling_grade: "A",
      rolling_value: 0,
      wet_grip_grade: "A",
      wet_grip_value: 0,
      noise_grade: 2,
      noise_value: 0,
      type_product_name: "",
      size_symbol: "",
      standard1: "",
      standard2: "",
      company_title_name: "",
      country: "",
      qr_value: "car.go.th",
      qr_path: "#",
      qrURL: "",
    };
    this.refs = React.createRef();
  }

  componentWillMount = async () => {
    // console.log("will Mount");

    await this.setState(this.props.data);
  };

  componentDidMount = async () => {
    const canvas = document.getElementById("qr1");
    const qrURL = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    await this.setQrPath(qrURL);
    // this.setState({ qr_path: qrURL })
    // let res = await axios.post(`http://192.168.250.178:1323/api/v1/requesting/componentDidMount`, {
    //   url: qrURL
    // });

    // await this.setState({ qr_path: res.data.value })
    // await this.setState({ qr_path: qrURL })

    // console.log("canvas", qrURL);
    // console.log(" PDF state", this.state);
  };

  componentWillReceiveProps = async (nextProps) => {
    if (
      `${nextProps.data.brand_name}+${nextProps.data.gen_name}+${nextProps.data.size_text}` !==
      `${this.state.brand_name}+${this.state.gen_name}+${this.state.size_text}`
    ) {
      await this.setState(
        { ...nextProps.data, qr_path: "#" },
        console.log("nai", this.state)
      );

      const canvas = document.getElementById("qr1");
      const qrURL = canvas.toDataURL();
      await this.setQrPath(qrURL);
    }
  };
  setQrPath = (url) => {
    return this.setState({ qr_path: url });
    // new Promise((resolve, reject) => {
    // setTimeout(() => {

    // resolve("success");
    //   }, 1000);
    // });
  };

  calVolume = (vol) => {
    var res;
    switch (vol) {
      case 1:
        res = volume1;
        break;
      case 2:
        res = volume2;
        break;
      case 3:
        res = volume3;
        break;
      default:
        res = "//:0";
    }
    return res;
  };

  calPosition = (grade) => {
    var pos = "";
    switch (grade) {
      case "A":
        pos = -4;
        break;
      case "B":
        pos = 13;
        break;
      case "C":
        pos = 30;
        break;
      case "D":
        pos = 47;
        break;
      case "E":
        pos = 64;
        break;
      case "F":
        pos = 81;
        break;
      case "G":
        pos = 98;
        break;
      default:
        pos = 0;
    }
    return pos;
  };

  calPositionB = (grade) => {
    var pos = "";
    switch (grade) {
      case "A":
        pos = 3;
        break;
      case "B":
        pos = 21;
        break;
      case "C":
        pos = 38;
        break;
      case "D":
        pos = 55;
        break;
      case "E":
        pos = 73;
        break;
      case "F":
        pos = 90;
        break;
      case "G":
        pos = 106;
        break;
      default:
        pos = 0;
    }
    return pos;
  };

  calLenghtWord = () => {
    let brandFontSize;

    let importCompany =
      this.state.company_prefix_th +
      " " +
      this.state.company_name_th +
      " " +
      this.state.company_suffix_th;

    let { part_one, part_two, importFontSize, productFontSize } = customCompany(
      importCompany,
      this.state.company_name_th,
      this.state.companyId,
      this.state.factory_name_en,
      this.state.factory_name_th
    );

    if (this.state.brand_name.length >= 45) {
      // 20 คือตัวอักษรยาวเกิน 45
      brandFontSize = 30;
    } else if (this.state.brand_name.length.length > 17) {
      // 24 คือยาวเกิน 13
      brandFontSize = 24;
    } else {
      // 30 คือยาวใน 1 บรรทัด
      brandFontSize = 30;
    }

    return {
      part_one,
      part_two,
      importFontSize,
      productFontSize,
      brandFontSize,
    };
  };

  render() {
    const styles_vector = StyleSheet.create({
      vector1: {
        // marginLeft:'5px',// height:'30%',
        position: "relative",
        top: this.calPosition(this.state.rolling_grade),
        left: "1.995px",
      },
      vector2: {
        position: "relative",
        top: this.calPositionB(this.state.wet_grip_grade),
      },
      vector1_txt: {
        fontSize: 22,
        color: "white",
        fontFamily: "rsu",
        position: "relative",
        top: this.calPosition(this.state.rolling_grade) - 34,
        left: 20,
      },
      vector2_txt: {
        fontSize: 18,
        color: "white",
        fontFamily: "rsu",
        position: "relative",
        top: this.calPositionB(this.state.wet_grip_grade) - 25,
        left: 12,
      },
      volume: {
        position: "relative",
        top: 22,
        left: 17,
        width: "20%",
      },
    });

    let {
      part_one,
      part_two,

      importFontSize,
      productFontSize,
      brandFontSize,
    } = this.calLenghtWord();
    // console.log(this.state.brand_name);
    const doc = (
      <Document>
        <Page size={[311.81, 623.62]} style={styles.page}>
          <View style={styles.wrapper}>
            <Image
              style={styles.image}
              src={
                this.state.type_product_name === "C1"
                  ? background_c1
                  : this.state.type_product_name === "C2"
                  ? background_c2
                  : this.state.type_product_name === "C3" &&
                    this.state.type_struct_id !== 2
                  ? background_c3
                  : background_c3_special
              }
            />
            <View style={styles.logo}>
              <Text style={styles.logo_txt}>ยาง</Text>
            </View>
            <View style={styles.title}>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    fontFamily: "rsu",
                    color: `gray`,
                    fontSize: brandFontSize,
                  }}
                >
                  {this.state.brand_name}
                </Text>
              </View>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    position: "relative",
                    // top: "0px",
                    // top: "-15px"
                    fontSize: 18,
                  }}
                >
                  {this.state.gen_name}
                </Text>
              </View>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    position: "relative",
                    // top: "-8px"
                    // top :"-25px"
                  }}
                >
                  ขนาด {this.state.size_text} {this.state.load_capacity}
                  {this.state.speed_symbol} {this.state.special_feature}
                </Text>
              </View>
            </View>
            <View style={styles.sec}>
              <View style={styles.imageSecTop}>
                <View style={styles.imageGasTank}>
                  <View style={styles.logoGas}>
                    {/* <Text>Logo Gas</Text> */}
                  </View>
                  <View style={styles.wrapSecAndGradArrow}>
                    <View style={styles.secGrade}>
                      <View style={styles.spaceLogoGrade} />
                      {/* <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeA}>
                                                </View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeB}>
                                                </View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeC}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeD}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeE}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeF}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeG}></View>
                                            </View> */}
                      <View style={styles.spaceLogoGrade} />
                    </View>
                    <View style={styles.secArrow}>
                      {!(
                        this.state.type_product_name === "C3" &&
                        this.state.type_struct_id === 2
                      ) && (
                        <React.Fragment>
                          <Image style={styles_vector.vector1} src={vector1} />
                          <Text style={styles_vector.vector1_txt}>
                            {this.state.rolling_value}
                          </Text>
                        </React.Fragment>
                      )}
                    </View>
                  </View>
                </View>
                <View style={styles.imageSlippery}>
                  <View style={styles.logoSlippery}>
                    {/* <Text>Logo Slippery</Text> */}
                  </View>
                  {/* <View style={styles.spaceLogoGrade}></View> */}

                  <View style={styles.wrapSecAndGradArrow}>
                    <View style={styles.secGrade} />
                    <View style={styles.secArrow}>
                      <Image style={styles_vector.vector2} src={vector2} />
                      <Text style={styles_vector.vector2_txt}>
                        {this.state.wet_grip_value}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.imageSecBottom}>
                {!(
                  this.state.type_product_name === "C3" &&
                  this.state.type_struct_id === 2
                ) && (
                  <React.Fragment>
                    <View style={styles.volumeSec}>
                      <Image
                        style={styles_vector.volume}
                        src={this.calVolume(this.state.noise_grade)}
                      />
                    </View>
                    <View style={styles.volumeValueSec}>
                      <Text
                        style={{
                          color: "white",
                          position: "relative",
                          top: 24,
                          left: 16,
                        }}
                      >
                        {this.state.noise_value}
                      </Text>
                    </View>
                  </React.Fragment>
                )}
              </View>

              <View style={styles.tyreClassWrapper}>
                <View style={styles.tyreClass}>
                  <Text>Tyre Class {this.state.type_product_name}</Text>
                </View>
                <View style={styles.tyreModel}>
                  <Text>{this.state.size_symbol}</Text>
                </View>
              </View>
              <View style={styles.standardWrapper}>
                <View style={styles.qrWrapper}>
                  <View style={styles.qrTopSection} />
                  <View style={styles.qrTextSection}>
                    <Text>ID {this.state.qr_running} </Text>
                  </View>
                  <View style={styles.qrBottomSection}>
                    <Image
                      // style={styles.watermark}
                      src={this.state.qr_path}
                    />
                    {/* <QRCode renderAs='svg' value="http://facebook.github.io/react/" /> */}
                  </View>
                </View>
                <View style={styles.standardDetailWrapper}>
                  <Text>{this.state.standard1}</Text>
                  <Text>{this.state.standard2}</Text>
                  <View style={styles.importBy}>
                    {this.state.company_title_name === "ผลิตโดย" ? (
                      <View>
                        <View style={styles.importbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: importFontSize }}>
                              <Text>{this.state.company_prefix_th}</Text>
                              <Text> {part_one}</Text>
                              <Text>{part_two} </Text>
                              <Text>{this.state.company_suffix_th}</Text>
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>
                              {/* ผลิตโดย */}
                            </Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {/* {this.state.factory_name_en} */}
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            {/* <Text style={{ fontSize: 13 }}>ประเทศ</Text> */}
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {/* {(this.state.country).toUpperCase()} */}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <View>
                        <View style={styles.importbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>นำเข้าโดย</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: importFontSize }}>
                              <Text>{this.state.company_prefix_th}</Text>
                              <Text> {part_one}</Text>
                              <Text>{part_two} </Text>
                              <Text>{this.state.company_suffix_th}</Text>
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: productFontSize }}>
                              {this.state.factory_name_en === ""
                                ? this.state.factory_name_th
                                : this.state.factory_name_en}
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>ประเทศ</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {this.state.country.toUpperCase()}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                  {/* <View style={styles.produceBy}>
                                        <View style={styles.productbyWrapper}>
                                            <View style={styles.produceTitle}>
                                                <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                                            </View>
                                            <View style={styles.produceValue}>
                                                <Text style={{ fontSize: 13 }}>company</Text>
                                            </View>
                                        </View>

                                        <View style={styles.productbyWrapper}>
                                            <View style={styles.produceTitle}>
                                                <Text style={{ fontSize: 13 }}>ประเทศ</Text>
                                            </View>
                                            <View style={styles.produceValue}>
                                                <Text style={{ fontSize: 13 }}>company</Text>
                                            </View>
                                        </View>


                                    </View> */}
                </View>
              </View>
            </View>
          </View>
          {!(this.state.request_status === 2) ? (
            <Image style={styles.watermark} src={water_mark} />
          ) : null}
          {/* <Image style={styles.watermark} src={water_mark} /> */}
        </Page>
      </Document>
    );

    // // console.log('pdf blob', pdf(doc).toBlob())
    return (
      <div>
        {this.state.qr_path !== `#` && (
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            {doc}
          </PDFViewer>
        )}
        <QRCode
          id="qr1"
          value={
            "https://tyre.go.th/company/sticker-preview/" + this.state.sizeId
          }
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    requestRedux: state.requestRedux,
  };
};

export default connect(mapStateToProps)(previewApprove);
