// preview class
import React, { Component, createRef } from "react";
import axios from "axios";
import { connect } from "react-redux"; // connect redux
import {
  Grid,
  Button,
  Table,
  Icon,
  Segment,
  Sticky,
  Ref,
  Rail,
  Header,
  Step,
  Container,
  Dimmer,
  Loader,
  Modal,
  Form,
  Label
} from "semantic-ui-react";
import Swal from "sweetalert2";
import { HOST_PATH_FILE, GET_REQUEST_DATA_BY_ID } from "../router";

// pdf class
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Font
} from "@react-pdf/renderer";
import background_c1 from "../../assets/img/base_stricker-C1.png";
import background_c2 from "../../assets/img/base_stricker-C2.png";
import background_c3 from "../../assets/img/base_stricker-C3.png";
import background_c3_special from '../../assets/img/base_sticker-C3-special.png'
import vector1 from "../../assets/img/vector1.png";
import vector2 from "../../assets/img/vector2.png";
import volume1 from "../../assets/img/volume_1.png";
import volume2 from "../../assets/img/volume_2.png";
import volume3 from "../../assets/img/volume_3.png";
import water_mark from "../../assets/img/watermark.png";
import { GET_STICKER_DATA_BY_SIZE_ID } from "../router";
import { customCompany } from "./customCompany";
import font from "../../assets/font/RSU_Regular.ttf";

Font.register({ family: "rsu", src: font });

var QRCode = require("qrcode.react");

class previewApproveFormGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_file: "",
      openStickerPreview: false,
      sizeID: "",
      uploadPaymentFile: { name: "", data: [], path: "" }
    };
  }
  contextRef = createRef();
  componentWillMount = async () => {
    await this.setState(this.props.requestRedux);

    if (this.props.match.params.request_id) {
      await this.getRequestData(this.props.match.params.request_id);
    } else {
      this.setState({ loader_request: true });
    }
    // console.log("after will mount", this.state);
  };

  render() {
    let tableFactory;
    let tableProduct;
    let tableProductList = [];
    let form = this.state;

    tableFactory = (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2" width={1}>
              <Icon name="user" />
              ข้อมูลการร้องขอ
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>ประเภทผู้ยื่นขอ</Table.Cell>
            <Table.Cell>
              {form.user_type !== "" ? form.user_type : "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>โรงงาน</Table.Cell>
            <Table.Cell>{form.factory !== "" ? form.factory : "-"}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );

    form.productRequest.map((product, prod_idx) => {
      tableProduct = (
        <Table key={prod_idx} celled striped style={{ wordBreak: 'break-word' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2" width={1}>
                <Icon name="user" />
                ข้อมูลผลิตภัณฑ์ที่ {prod_idx + 1 + ` : ` + product.productName}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                เลขที่ใบอนุญาต มอก.
                {product.productTypeId === "1" ? "2718" : "2719"}-2560
              </Table.Cell>
              <Table.Cell>
                {product.licenseNumber !== "" ? product.licenseNumber : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รายการที่</Table.Cell>
              <Table.Cell>
                {" "}
                {product.listProduct !== "" ? product.listProduct : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ใบอนุญาต</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  onClick={async () => {
                    await this.setState({
                      openStickerPreview: false,
                      selected_file: product.licenseNumberFile.path
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  Preview
                </Button>
              </Table.Cell>
            </Table.Row>
            {product.brandsRequest.map((brand, brand_idx) => (
              <React.Fragment key={brand_idx}>
                <Table.Row>
                  <Table.Cell colSpan="2" warning>
                    {" "}
                    <Header as="h5">
                      {" "}
                      ชื่อทางการค้าที่ {brand_idx + 1} : {brand.brandName}
                    </Header>
                  </Table.Cell>
                </Table.Row>

                {brand.genRequest.map((gen, gen_idx) => (
                  <React.Fragment key={gen_idx}>
                    <Table.Row>
                      <Table.Cell colSpan="2">
                        <Header as="h5">
                          {" "}
                          รุ่นที่ {gen_idx + 1} :{" "}
                          {gen.genName !== "" ? gen.genName : "-"}{" "}
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell></Table.Cell> */}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>แบบทางวิศวกรรม</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Button
                          onClick={() => {
                            this.setState({
                              selected_file: gen.genLayoutFile.path,
                              openStickerPreview: false
                            });
                          }}
                          color="orange"
                        >
                          <Icon name="file outline" />
                          Preview
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                    {gen.sizeRequest.map((size, size_idx) => (
                      <React.Fragment key={size_idx}>
                        <Table.Row>
                          <Table.Cell>
                            <Header as="h5">
                              รุ่นที่ {gen_idx + 1} / ขนาดที่ {size_idx + 1}
                            </Header>
                          </Table.Cell>
                          <Table.Cell>
                            {size.sizeGen !== "" ? size.sizeGen : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>ดัชนีความสามารถการรับโหลด</Table.Cell>
                          <Table.Cell>
                            {size.sizeCapacity !== "" ? size.sizeCapacity : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>สัญลักษณ์ความเร็ว</Table.Cell>
                          <Table.Cell>
                            {size.sizeSpeedSymbol !== ""
                              ? size.sizeSpeedSymbol
                              : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>คุณลักษณะพิเศษ</Table.Cell>
                          <Table.Cell>
                            {size.sizeSpecialFeature !== ""
                              ? size.sizeSpecialFeature
                              : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>โครงสร้าง</Table.Cell>
                          <Table.Cell>
                            {size.sizeStructure !== ""
                              ? size.sizeStructure
                              : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>ประเภทใช้งาน</Table.Cell>
                          <Table.Cell>
                            {size.sizeTypeOfUse !== ""
                              ? size.sizeTypeOfUse
                              : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>ความกว้างภาคตัดระบุ</Table.Cell>
                          <Table.Cell>
                            {size.sizeCuttingWidth !== ""
                              ? size.sizeCuttingWidth
                              : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>เครื่องหมาย</Table.Cell>
                          <Table.Cell>
                            {size.sizeSymbol !== "" ? size.sizeSymbol : "-"}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>ตัวอย่างสติ๊กเกอร์</Table.Cell>
                          <Table.Cell textAlign="center">
                            <Button
                              onClick={async () => {
                                let data = await this.getStickerData(size.id);
                                // console.log("data", data);
                                await this.setState({
                                  openStickerPreview: true,
                                  sizeID: size.id,
                                  stickerData: data
                                });
                              }}
                              color="orange"
                            >
                              <Icon name="file outline" />
                              Preview
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">การทดสอบความต้านทานการหมุน </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_a.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_a.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_a.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ค่าสัมประสิทธิ์ของความต้านทานการหมุน</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_a !== ""
                  ? product.testResult.testValue_a
                  : "-"}{" "}
                {product.testResult.testUnit_a}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>เอกสารการทดสอบความต้านทานการหมุน</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_a.path,
                      openStickerPreview: false
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  Preview
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบความต้านทานการหมุน
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_a !== ""
                  ? product.testResult.testPage_a
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">การทดสอบการยึดเกาะถนนบนพื้นเปียก </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_b.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_b.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_b.split("->")[0]}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_b !== ""
                  ? product.testResult.testValue_b
                  : "-"}{" "}
                {product.testResult.testUnit_b}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>เอกสารการทดสอบการยึดเกาะถนนบนพื้นเปียก</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_b.path,
                      openStickerPreview: false
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  Preview
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบการยึดเกาะถนนบนพื้นเปียก
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_b !== ""
                  ? product.testResult.testPage_b
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">
                  การทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน{" "}
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_c.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_c.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_c.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_c !== ""
                  ? product.testResult.testValue_c
                  : "-"}{" "}
                {product.testResult.testUnit_c}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                เอกสารการทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_c.path,
                      openStickerPreview: false
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  Preview
                </Button>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_c !== ""
                  ? product.testResult.testPage_c
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2" warning>
                <Header as="h5">การทดสอบการเกาะถนนหิมะ </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ชื่อทางการค้า</Table.Cell>
              <Table.Cell>
                {product.testResult.brandName_d.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>รุ่น</Table.Cell>
              <Table.Cell>
                {product.testResult.genName_d.split("->")[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ขนาด</Table.Cell>
              <Table.Cell>
                {product.testResult.sizeGen_d.split("->")[0]}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>ค่าดัชนีการเกาะถนนหิมะ</Table.Cell>
              <Table.Cell>
                {product.testResult.testValue_d !== ""
                  ? product.testResult.testValue_d
                  : "-"}{" "}
                {product.testResult.testUnit_d}{" "}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>เอกสารการทดสอบการเกาะถนนหิมะ</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  onClick={() => {
                    this.setState({
                      selected_file: product.testResult.testFile_d.path,
                      openStickerPreview: false
                    });
                  }}
                  color="orange"
                >
                  <Icon name="file outline" />
                  Preview
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                หมายเลขหน้าที่แสดงผลการทดสอบการเกาะถนนหิมะ
              </Table.Cell>
              <Table.Cell>
                {product.testResult.testPage_d !== ""
                  ? product.testResult.testPage_d
                  : "-"}{" "}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );

      return tableProductList.push(tableProduct);
    });

    return (
      <div>
        <div style={{ margin: 30 }}>
          <Segment padded="very">
            <Dimmer active={this.state.loader_request}>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
            {/* Segment all */}
            <Segment color="violet">
              {/* Segment step */}
              <Container>
                {(() => {
                  if (this.state.approve_all) {
                    return (
                      <Segment inverted style={{ backgroundColor: "#dff0d8" }}>
                        <div
                          style={{
                            margin: "20px",
                            color: "#3c763d",
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          <div style={{ fontWeight: "bold" }}>Completed</div>
                        </div>
                      </Segment>
                    );
                  } else {
                    return (
                      <Segment inverted style={{ backgroundColor: "#f8d7da" }}>
                        <div
                          style={{
                            margin: "20px",
                            color: "#721c24",
                            fontSize: "18px",
                            textAlign: "center"
                          }}
                        >
                          <div style={{ fontWeight: "bold" }}>Incompleted</div>
                        </div>
                      </Segment>
                    );
                  }
                })()}

                <Step.Group fluid stackable="tablet">
                  <Step completed>
                    <Icon name="checkmark" />
                    <Step.Content>
                      <Step.Title>ยื่นใบอนุญาต</Step.Title>
                      <Step.Description>ได้ยื่นใบอนุญาตแล้ว</Step.Description>
                    </Step.Content>
                  </Step>

                  <Step
                    // completed={1}
                    completed={this.state.approve_oie_tisi}
                  >
                    <Icon name="wait" />
                    <Step.Content>
                      <Step.Title>รอการอนุมัติ</Step.Title>
                      <Step.Description>จาก สศอ. / สมอ.</Step.Description>
                    </Step.Content>
                  </Step>

                  <Step completed={this.state.approve_technical}>
                    <Icon name="wait" />
                    <Step.Content>
                      <Step.Title>รอการอนุมัติ</Step.Title>
                      <Step.Description>จาก Technical Service</Step.Description>
                    </Step.Content>
                  </Step>

                  <Step completed={this.state.isPayment}>
                    <Icon name="wait" />
                    <Step.Content>
                      <Step.Title>รอตรวจสอบ</Step.Title>
                      <Step.Description>การชำระเงิน</Step.Description>
                    </Step.Content>
                  </Step>

                  <Step
                    disabled={!this.state.approve_all}
                    completed={this.state.approve_all}
                  >
                    <Icon name="check circle outline" />
                    <Step.Content>
                      <Step.Title>ผ่านการอนุมัติ</Step.Title>
                    </Step.Content>
                  </Step>
                </Step.Group>

                <div>
                  <div style={{ fontWeight: "bold" }}>
                    ใบอนุญาตนี้ยื่นขอวันที่ :
                    {this.formatDate(this.state.created_at)}
                  </div>
                  <div>
                    สถานะ (Status) :{" "}
                    {this.state.approve_oie_tisi === false ? (
                      <span>
                        สศอ./สมอ. ยังไม่อนุมัติ <Icon name="delete" /> |{" "}
                      </span>
                    ) : (
                        <span></span>
                      )}
                    {this.state.approve_technical === false ? (
                      <span>
                        Technical Service ยังไม่อนุมัติ <Icon name="delete" /> |
                      </span>
                    ) : (
                        <span></span>
                      )}
                    {this.state.isPayment === false ? (
                      <span>
                        ยังไม่จ่ายเงิน <Icon name="delete" />
                      </span>
                    ) : (
                        <span></span>
                      )}
                  </div>
                  {this.state.uploadPayment === true ? (
                    <div align="center">
                      {/* <Button
                        onClick={async () => {
                          await this.setState({
                            modalUpload: true
                          });
                        }}
                        size="massive"
                        color="green"
                      >
                        <Icon name="file outline" />
                        อัปโหลดใบเสร็จชำระเงิน
                      </Button> */}
                    </div>
                  ) : (
                      <span></span>
                    )}
                  <div align="right">
                    {/* {this.state.approve_technical &&
                      this.state.approve_oie_tisi ? (
                        <Button
                          onClick={async () => {
                            await this.setState({
                              selected_file: this.state.exampleLicenseFilePath,
                              openStickerPreview: false
                            });
                          }}
                          size="huge"
                          color="orange"
                        >
                          <Icon name="picture" />
                          ตัวอย่างใบอนุญาต
                      </Button>
                      ) : null} */}
                  </div>
                </div>
              </Container>
            </Segment>
            <Ref innerRef={this.contextRef}>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    {tableFactory}
                    {tableProductList}
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    <Rail
                      style={{
                        width: "100%"
                      }}
                    >
                      <Sticky context={this.contextRef}>
                        {(() => {
                          if (
                            this.state.selected_file !== "" &&
                            this.state.openStickerPreview === false
                          ) {
                            return (
                              <iframe
                                title="file_iframe"
                                style={{
                                  width: "100%",
                                  height: window.innerHeight
                                }}
                                src={HOST_PATH_FILE + this.state.selected_file}
                              />
                            );
                          } else if (this.state.openStickerPreview) {
                            return (
                              <PDF
                                data={this.state.stickerData}
                                id={this.state.sizeID}
                              />
                            );
                          } else {
                            return (
                              <iframe
                                title="empty_iframe"
                                style={{
                                  width: "100%",
                                  height: window.innerHeight
                                }}
                              />
                            );
                          }
                        })()}
                      </Sticky>
                    </Rail>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Ref>
          </Segment>
        </div>

        <Modal size={"small"} open={this.state.modalUpload} centered={false}>
          <Segment
            padded="very"
            style={{
              border: "rgba(0, 0, 0, 0)"
            }}
          >
            <Dimmer active={this.state.loader_all}>
              <Loader size="massive">Loading</Loader>
            </Dimmer>

            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Modal.Content>
                    <Form>
                      <Form.Field>
                        <Segment>
                          <Label
                            attached="top"
                            color={"violet"}
                            as="label"
                            htmlFor={"uploadPaymentFile"}
                            size="large"
                          >
                            <Icon name="upload" />
                            เลือกไฟล์
                            <input
                              hidden
                              id={"uploadPaymentFile"}
                              type="file"
                              accept="image/png, image/jpeg"
                              onChange={async e => {
                                if (e.target.files[0]) {
                                  if (e.target.files.size > 10000000) {
                                    alert("ไฟล์ใหญ่เกินไป");
                                    return;
                                  }
                                  var src = URL.createObjectURL(
                                    e.target.files[0]
                                  );

                                  this.setState({
                                    uploadPaymentFile: {
                                      name: e.target.files[0].name,
                                      data: e.target.files[0],
                                      path: src
                                    }
                                  });

                                  // this.state.uploadPaymentFile.name =
                                  //   e.target.files[0].name;

                                  // this.state.uploadPaymentFile.data =
                                  //   e.target.files[0];

                                  // this.state.uploadPaymentFile.path = src;
                                }
                              }}
                            />
                          </Label>
                          <p>
                            {this.state.uploadPaymentFile.name}
                            &nbsp; &nbsp;
                            {this.state.uploadPaymentFile.name ? (
                              <Icon
                                color="red"
                                size="large"
                                name="times circle outline"
                                link
                                onClick={async e => {
                                  document.getElementById(
                                    "uploadPaymentFile"
                                  ).value = null;
                                }}
                              />
                            ) : null}
                          </p>
                        </Segment>
                      </Form.Field>
                    </Form>
                  </Modal.Content>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Modal.Actions>
                    <Button
                      floated="right"
                      positive
                      icon
                      labelPosition="left"
                      onClick={async () => {
                        // this.submitDraft();
                        alert("COMING SOON");
                      }}
                    >
                      <Icon name="save" />
                      อัปโหลด
                    </Button>
                    <Button
                      floated="right"
                      onClick={() => {
                        this.setState({
                          modalUpload: false
                        });
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Modal.Actions>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal>
      </div>
    );
  }

  getRequestData = async request_id => {
    this.setState({ loader_request: true });

    try {
      // // console.log("request_id -> ", request_id);
      let request_data = await axios.get(GET_REQUEST_DATA_BY_ID + request_id);
      let data = request_data.data.value.tyre_requests;
  

      this.setState({
        draftName: data.tyre_requests_name,
        selected_file: data.product_requests[0].file_path_product_request
      });
      let productArr = [];
      let preState = this.state;

      // preState.approve_oie_tisi = data.approve_oie_tisi;
      // preState.approve_technical = data.approve_technical;

      preState.created_at = data.created_at;
      if (data.approve_oie_tisi === 2) {
        preState.approve_oie_tisi = true;
      } else {
        preState.approve_oie_tisi = false;
      }

      if (data.approve_technical === 2) {
        preState.approve_technical = true;
      } else {
        preState.approve_technical = false;
      }

      if (data.payment_status === 7) {
        preState.isPayment = true;
      } else if (data.payment_status === 6) {
        preState.isPayment = false;
        preState.uploadPayment = false;
      } else if (
        data.approve_oie_tisi === 2 &&
        data.approve_technical === 2 &&
        data.payment_status !== 6 &&
        data.payment_status !== 7
      ) {
        preState.isPayment = false;
        preState.uploadPayment = true;
      } else {
        preState.isPayment = false;
      }

      if (
        data.approve_oie_tisi === 2 &&
        data.approve_technical === 2 &&
        data.payment_status === "success"
      ) {
        preState.approve_all = true;
      }
      // preState.approve_oie_tisi = 2;
      // preState.approve_technical = 2;

      data.product_requests.map((product, prod_idx) => {
        // console.log(prod_idx);
        let d = {
          id: product.id,
          productId: product.products_id,
          productTypeId: product.products.type_product_id.toString(),
          productName: product.products.product_struct,
          license: "",
          licenseNumber: product.license_request,
          licenseNumberFile: {
            name: product.file_filename_product_request,
            data: [],
            path: product.file_path_product_request
          },
          listProduct: product.list_request,
          brandsRequest: product.brand_requests.map((brand, brand_idx) => {
            return {
              id: brand.id,
              brandId: brand.brands_id,
              brandName: brand.brands.name,
              genRequest: brand.gen_requests.map((gen, gen_idx) => {
                return {
                  id: gen.id,
                  genName: gen.gen_name,
                  genLayoutFile: {
                    name: gen.file_filename_gen_request,
                    data: [],
                    path: gen.file_path_gen_request
                  },
                  sizeRequest: gen.size_requests.map((size, size_idx) => {
                    return {
                      id: size.id,
                      sizeGen: size.size_text,
                      sizeCapacity: size.load_capacity,
                      sizeSpeedSymbol: size.speed_symbol,
                      sizeSpecialFeature: size.special_feature,
                      sizeStructure: size.size_struct,
                      sizeTypeOfUse: size.type_use,
                      sizeCuttingWidth: size.cutting_width,
                      sizeSymbol: size.symbol
                    };
                  })
                };
              })
            };
          }),
          testResult: {
            id: product.test_results.id,
            testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
            testValue_a: product.test_results.rs_test_value.toString(),
            testUnit_a: "N/kN",
            brandName_a: product.test_results.rs_brand_request_value,
            genName_a: product.test_results.rs_gen_request_value,
            sizeGen_a: product.test_results.rs_size_request_value,
            testPage_a: product.test_results.rs_test_page,
            testFile_a: {
              name: product.test_results.rs_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_a
                    .data
                  : [],
              path: product.test_results.rs_file_path
            },

            testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
            testValue_b: product.test_results.rg_test_value.toString(),
            testUnit_b: "G",
            brandName_b: product.test_results.rg_brand_request_value,
            genName_b: product.test_results.rg_gen_request_value,
            sizeGen_b: product.test_results.rg_size_request_value,
            testPage_b: product.test_results.rg_test_page,
            testFile_b: {
              name: product.test_results.rg_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_b
                    .data
                  : [],
              path: product.test_results.rg_file_path
            },

            testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
            testValue_c: product.test_results.tr_test_value.toString(),
            testUnit_c: "dB(A)",
            brandName_c: product.test_results.tr_brand_request_value,
            genName_c: product.test_results.tr_gen_request_value,
            sizeGen_c: product.test_results.tr_size_request_value,
            testPage_c: product.test_results.tr_test_page,
            testFile_c: {
              name: product.test_results.tr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_c
                    .data
                  : [],
              path: product.test_results.tr_file_path
            },

            testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
            testValue_d: product.test_results.sr_test_value.toString(),
            testUnit_d: "SG",
            brandName_d: product.test_results.sr_brand_request_value,
            genName_d: product.test_results.sr_gen_request_value,
            sizeGen_d: product.test_results.sr_size_request_value,
            testPage_d: product.test_results.sr_test_page,
            testFile_d: {
              name: product.test_results.sr_file_filename,
              data:
                this.state.productRequest[prod_idx] !== undefined
                  ? this.state.productRequest[prod_idx].testResult.testFile_d
                    .data
                  : [],
              path: product.test_results.sr_file_path
            }

            // testFile: {
            //   name: product.test_results.file_filename_test_result,
            //   data: [],
            //   path: product.test_results.file_path_test_result
            // },
            // testPage: product.test_results.test_page
          }
        };
        return productArr.push(d);
      });

      // // console.log("SJDKFJSLKDJFKLSJFKLSJFLKSDFKLSDJFKL:S",data.id)
      preState.tyre_requests_id = data.id;
      // // console.log("preState.tyre_requests_id", preState.tyre_requests_id);
      preState.user_type =
        data.factories.type_factories_id === "1"
          ? "ผู้ผลิต"
          : data.factories.type_factories_id === "2"
            ? "ผู้นำเข้า"
            : "";
      // preState.productTypeOptions =
      //   factory !== null
      //     ? factory.find(f => f.id === data.factories_id).product_type_options
      //     : [];
      preState.factory = data.factories.name_th;
      preState.factoryTypeId = data.factories.type_factories_id;
      preState.factoryId = data.factories_id;
      preState.productRequest = productArr;

      // // console.log("draft", request_data.data.value);
      // console.log("prestate", preState);
      await this.setState(preState);

      // console.log("success", this.state);

      this.setState({ loader_request: false });
      return true;
    } catch (error) {
      console.log(error);
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
        confirmButtonText: "ตกลง"
      });
      this.setState({ loader_request: false });
      return false;
    }
  };

  formatDate = dateString => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (day < 10) {
      day = "0" + day;
    }

    switch (month) {
      case 1:
        month = "Jan";
        break;
      case 2:
        month = "Feb";
        break;
      case 3:
        month = "Mar";
        break;
      case 4:
        month = "Apr";
        break;
      case 5:
        month = "May";
        break;
      case 6:
        month = "Jun";
        break;
      case 7:
        month = "Jul";
        break;
      case 8:
        month = "Aug";
        break;
      case 9:
        month = "Sep";
        break;
      case 10:
        month = "Oct";
        break;
      case 11:
        month = "Nov";
        break;
      case 12:
        month = "Dec";
        break;
      default:
        break;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var formattedDate =
      " วันที่ " +
      day +
      "-" +
      month +
      "-" +
      (year + 543) +
      "\n เวลา " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;

    return formattedDate;
  };

  getStickerData = async id => {
    try {
      let res = await axios.get(GET_STICKER_DATA_BY_SIZE_ID + id);
      // console.log("response", res.data);
      // await this.setState(res.data.sticker);
      let stickerData = res.data.sticker;
      stickerData.sizeId = id;

      return stickerData;
      // console.log("after ", this.state);
    } catch (error) {
      console.log(error);
    }
  };
}

// const qr = <QRCode id="qr1" ref="qr1" value="http://facebook.github.io/react/" />

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'column',
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35
    // backgroundColor: '#E4E4E4'
  },
  header_1: {
    fontFamily: "rsu",
    color: `gray`,
    fontSize: 30
  },
  watermark: {
    left: "100",
    top: "150",
    height: "150.3mm",
    // hegiht:'100%',
    position: `absolute`,
    zIndex: 30
  },
  image: {
    // top: `100px`, /* your required value */
    // left: `300`,
    width:'311.81',
    height: "623.62",
    position: `absolute`,
    // height:'100%',
    // width:'100%',
    // margin:'0',
    // padding:'0',
    // zIndex: 15,
    // marginVertical: 15,
    // marginHorizontal: 100,
  },
  textWhite: {
    color: "white"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  wrapper: {
    width: "311.81",
    height: "623",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    padding:"0",
    backgroundColor: "yellow"
  },
  wrapper_blank: {
    width: "110mm",
    height: "220mm",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    // backgroundColor: 'yellow'
    TextAlign: "center"
  },
  logo: {
    overflow: "visible",
    // alignSelf: 'flex-end',
    // backgroundColor: `green`,
    fontFamily: "rsu",
    marginTop: "4mm",
    // paddingTop: '2px',
    color: "blue",
    height: "18mm",
    textAlign: "center"
  },
  logo_txt: {
    lineHeight: "20%",
    overflow: "visible",
    position: "relative",
    top: "20px",
    // backgroundColor: 'blue',
    padding: "0",
    fontSize: 34,
    //34
    color: `red`
  },
  title: {
    // position: 'relative',
    // top: '20px',
    lineHeight: "80%",
    fontFamily: "rsu",
    // marginTop: '2mm',
    height: "31mm",
    textAlign: "center"
    // backgroundColor: `red`
  },
  sec: {
    fontFamily: "rsu",
    width: "100%",
    height: "167mm",
    border: "2px; solid; palevioletred;",
    // backgroundColor: 'rgba(255,0,0,0) ',
    color: "red",
    textAlign: "center",
    verticalAlign: "middle",
    marginLeft: "auto",
    marginRight: "auto"
    // paddingTop:'50',
    // marginTop: '50',
    // marginBottom: '55mm'
  },
  imageSecTop: {
    display: "flex",
    flexWrap: "wrap",
    width: "103mm", // width of sec - marLeft - marRight = 75 - 5 - 5 = 65
    height: "73mm", // 70% of 110mm is 77m // height - marginTop = height --> 77 - 5 = 72mm
    // backgroundColor: 'orange',
    marginLeft: "4mm",
    marginRight: "4mm",
    marginTop: "2mm"
  },
  imageGasTank: {
    //  backgroundColor: 'green',
    width: "60%",
    height: "100%"
  },
  logoGas: {
    // backgroundColor: 'purple',
    width: "100%",
    height: "20mm", // estimation 29% of height imageSecTop(72) = 20.88
    color: "white"
  },
  wrapSecAndGradArrow: {
    width: "100%",
    height: "52mm", // height imageSecTop - height logoGas = 72 - 20 = 52
    display: "flex",
    flexWrap: "wrap"
  },
  secGrade: {
    paddingTop: "2",
    // backgroundColor: 'red',
    width: "50%",
    height: "100%"
  },
  secArrow: {
    marginTop: "5mm",
    width: "50%",
    height: "46mm"
    // backgroundColor: 'purple',
  },
  spaceLogoGrade: {
    width: "100%",
    height: "7mm"
    // backgroundColor: 'silver'
  },
  wrapGrade: {
    width: "100%",
    height: "5mm",
    marginBottom: "1mm"
    // backgroundColor: 'cream'
  },
  gasGradeA: {
    width: "20%",
    height: "100%",
    backgroundColor: "#2E8B57"
    // border-left: 20px solid #58ff00;
    // border-top: 20px solid #00800000;
    // border-bottom: 20px solid #00800000;
  },
  gasGradeB: {
    width: "50%",
    height: "100%",
    backgroundColor: "#32CD32"
  },
  gasGradeC: {
    width: "60%",
    height: "100%",
    backgroundColor: "#ADFF2F"
  },
  gasGradeD: {
    width: "70%",
    height: "100%",
    backgroundColor: "#FFFF00"
  },
  gasGradeE: {
    width: "80%",
    height: "100%",
    backgroundColor: "#FFD700"
  },
  gasGradeF: {
    width: "90%",
    height: "100%",
    backgroundColor: "##FF6347"
  },
  gasGradeG: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FF0000"
  },
  logoSlippery: {
    // backgroundColor: 'brown',
    width: "100%",
    height: "20mm", // estimation 25% of height imageSecTop = 18
    color: "white"
  },
  imageSlippery: {
    //  backgroundColor: 'pink',
    width: "39%",
    height: "100%"
  },
  imageSecBottom: {
    display: "flex",
    flexWrap: "wrap",
    height: "24mm",
    width: "71mm", // 30% of 110mm is 33m // height - marginTop - height Of <div> image Flag = height --> 33 - 2 - 9 = 22mm
    // backgroundColor: 'black',
    marginLeft: "36mm",
    marginRight: "3mm"
    // marginTop: '2mm'
  },
  volumeSec: {
    width: "50%",
    height: "100%"
    // backgroundColor: 'green',
  },
  volumeValueSec: {
    width: "50%",
    height: "100%"
    // backgroundColor: 'pink',
  },
  tyreClassWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "7mm"
    // backgroundColor: 'green',
    // opacity: '0.5'
  },
  tyreClass: {
    color: "black",
    fontSize: "12",
    textAlign: "left",
    width: "78%", // Equal
    height: "7mm",
    // backgroundColor: 'red',
    marginLeft: "3mm",
    marginBottom: "2mm"
  },

  tyreModel: {
    color: "black",
    fontSize: "12",
    textAlign: "right",
    width: "16%", // Equal
    height: "7mm"
    // backgroundColor: 'blue',
  },
  standardWrapper: {
    display: "flex",
    flexWrap: "wrap",

    width: "100%",
    height: "60mm"
    // backgroundColor: 'red',
  },
  qrWrapper: {
    flexWrap: "wrap",
    marginLeft: "4",
    width: "31%",
    height: "100%"
    // backgroundColor: 'gray'
  },
  qrTopSection: {
    width: "95%",
    height: "56%",
    // backgroundColor: 'green',

    verticalAlign: "text-bottom"
  },
  qrTextSection: {
    position: "relative",
    top: "2",
    fontSize: "9",
    color: "black",

    width: "50%",
    // backgroundColor: 'black',
    marginTop: "1",
    marginLeft: "18"
  },
  qrBottomSection: {
    width: "52%",
    height: "30%",
    // backgroundColor: "yellow",
    // marginTop: '12',
    marginLeft: "18"
    // verticalAlign: 'text-bottom'
  },

  standardDetailWrapper: {
    position: "relative",
    top: "-5px",
    padding: "15",
    fontSize: "16",
    color: "black",
    textAlign: "left",
    width: "67%",
    height: "100%",
    // backgroundColor: 'yellow',
    marginBottom: "4"
  },
  importBy: {
    display: "flex",
    flexWrap: "wrap",
    // height: "30%",
    height: "auto",
    width: "95%",
    marginTop: "1"
    // backgroundColor: 'black'
  },
  importTitle: {
    height: "100%",
    width: "30%"
    // backgroundColor: 'red',
  },
  importValue: {
    marginLeft: "5",
    height: "100%",
    width: "65%"
    // backgroundColor: 'red',
  },
  produceBy: {
    // display: 'flex',
    // flexWrap: 'wrap',
    height: "30%",
    width: "95%",
    marginTop: "10"
    // backgroundColor: 'black'
  },
  importbyWrapper: {
    display: "flex",
    flexWrap: "wrap",
    // height: "48%",
    height: "auto",
    maxHeight: "34%",
    width: "100%"
    // backgroundColor: 'blue'
  },
  productbyWrapper: {
    display: "flex",
    flexWrap: "wrap",
    height: "32%",
    // height:"auto",
    width: "100%"
    // backgroundColor: 'blue'
  },
  produceTitle: {
    height: "100%",
    width: "30%"
    // backgroundColor: 'red',
  },
  produceValue: {
    // marginTop: "3",
    marginLeft: "5",
    height: "100%",
    width: "65%"
    // backgroundColor: 'red',
  },
  vector1: {
    position: "relative",
    top: "20px"
  }
});

// const blank_page = (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.wrapper_blank}>
//         <Text>Generate..</Text>
//       </View>
//     </Page>
//   </Document>
// );

class PDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brand_name: "",
      gen_name: "",
      size_text: "",
      rolling_grade: "A",
      rolling_value: 0,
      wet_grip_grade: "A",
      wet_grip_value: 0,
      noise_grade: 2,
      noise_value: 0,
      type_product_name: "",
      size_symbol: "",
      standard1: "",
      standard2: "",
      company_title_name: "",
      qr_value: "google.co.th",
      qr_path: "#"
    };
  }

  componentWillMount = async () => {
    // console.log("will Mount");
    // console.log("props", this.props.data);
    // console.log("before", this.state);
    await this.setState(this.props.data);
    // await this.setState({qr_value : 'localhost:3000/company/sticker-preview/'+this.props.id})
    // console.log("after", this.state);
    // console.log('sizeIDProps',this.props.id)
  };
  componentDidMount = async () => {
    const canvas = document.getElementById("qr1");
    const qrURL = canvas.toDataURL();
    await this.setQrPath(qrURL);
    // this.setState({ qr_path: qrURL })
    // let res = await axios.post(`http://192.168.250.178:1323/api/v1/requesting/componentDidMount`, {
    //   url: qrURL
    // });

    // await this.setState({ qr_path: res.data.value })
    // await this.setState({ qr_path: qrURL })

    // console.log("canvas", qrURL);
    // console.log(" PDF state", this.state);
  };
  componentWillReceiveProps = async nextProps => {
    await this.setState(nextProps.data);

    const canvas = document.getElementById("qr1");
    const qrURL = canvas.toDataURL();
    await this.setQrPath(qrURL);
  };

  setQrPath = url => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // console.log('wake')
        this.setState({ qr_path: url });
        resolve("success");
      }, 1000);
    });
  };

  calVolume = vol => {
    var res;
    switch (vol) {
      case 1:
        res = volume1;
        break;
      case 2:
        res = volume2;
        break;
      case 3:
        res = volume3;
        break;
      default:
        res = "//:0";
    }
    return res;
  };

  calPosition = grade => {
    var pos = "";
    switch (grade) {
      case "A":
        pos = -4;
        break;
      case "B":
        pos = 13;
        break;
      case "C":
        pos = 30;
        break;
      case "D":
        pos = 47;
        break;
      case "E":
        pos = 64;
        break;
      case "F":
        pos = 81;
        break;
      case "G":
        pos = 98;
        break;
      default:
        pos = 0;
    }
    return pos;
  };

  calPositionB = grade => {
    var pos = "";
    switch (grade) {
      case "A":
        pos = 3;
        break;
      case "B":
        pos = 21;
        break;
      case "C":
        pos = 38;
        break;
      case "D":
        pos = 55;
        break;
      case "E":
        pos = 73;
        break;
      case "F":
        pos = 90;
        break;
      case "G":
        pos = 106;
        break;
      default:
        pos = 0;
    }
    return pos;
  };


  calLenghtWord = () => {
    let brandFontSize;

    let importCompany =
      this.state.company_prefix_th +
      " " +
      this.state.company_name_th +
      " " +
      this.state.company_suffix_th;

    let { part_one, part_two, importFontSize, productFontSize } = customCompany(
      importCompany,
      this.state.company_name_th,
      this.state.companyId,
      this.state.factory_name_en,
      this.state.factory_name_th
    );



    if (this.state.brand_name.length >= 45) {
      // 20 คือตัวอักษรยาวเกิน 45
      brandFontSize = 30;
    } else if (this.state.brand_name.length.length > 17) {
      // 24 คือยาวเกิน 13
      brandFontSize = 24;
    } else {
      // 30 คือยาวใน 1 บรรทัด
      brandFontSize = 30;
    }

    return {
      part_one,
      part_two,
      importFontSize,
      productFontSize,
      brandFontSize
    };
  };

  render() {
    const styles_vector = StyleSheet.create({
      vector1: {
        // marginLeft:'5px',// height:'30%',
        position: "relative",
        top: this.calPosition(this.state.rolling_grade),
        left: "1.995px"
      },
      vector2: {
        position: "relative",
        top: this.calPositionB(this.state.wet_grip_grade)
      },
      vector1_txt: {
        fontSize: 22,
        color: "white",
        fontFamily: "rsu",
        position: "relative",
        top: this.calPosition(this.state.rolling_grade) - 34,
        left: 20
      },
      vector2_txt: {
        fontSize: 18,
        color: "white",
        fontFamily: "rsu",
        position: "relative",
        top: this.calPositionB(this.state.wet_grip_grade) - 25,
        left: 12
      },
      volume: {
        position: "relative",
        top: 22,
        left: 17,
        width: "20%"
      }
    });

    let {
      part_one,
      part_two,

      importFontSize,
      productFontSize,
      brandFontSize
    } = this.calLenghtWord();
    // console.log(this.state.brand_name);
    const doc = (
      <Document>
        <Page size={[311.81, 623.62]} style={styles.page}>
          <View style={styles.wrapper}>
            <Image
              style={styles.image}
              src={
                this.state.type_product_name === "C1"
                  ? background_c1
                  : this.state.type_product_name === "C2"
                    ? background_c2
                    : this.state.type_product_name === 'C3' && this.state.type_struct_id !== 2 ? background_c3
                      : background_c3_special
              }
            />
            <View style={styles.logo}>
              <Text style={styles.logo_txt}>ยาง</Text>
            </View>
            <View style={styles.title}>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    fontFamily: "rsu",
                    color: `gray`,
                    fontSize: brandFontSize
                  }}
                >
                  {this.state.brand_name}
                </Text>
              </View>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    position: "relative",
                    // top: "0px",
                    // top: "-15px"
                    fontSize: 18
                  }}
                >
                  {this.state.gen_name}
                </Text>
              </View>
              <View style={{ lineHeight: 1 }}>
                <Text
                  style={{
                    position: "relative"
                    // top: "-8px"
                    // top :"-25px"
                  }}
                >
                  ขนาด {this.state.size_text} {this.state.load_capacity}
                  {this.state.speed_symbol} {this.state.special_feature}
                </Text>
              </View>
            </View>
            <View style={styles.sec}>
              <View style={styles.imageSecTop}>
                <View style={styles.imageGasTank}>
                  <View style={styles.logoGas}>
                    {/* <Text>Logo Gas</Text> */}
                  </View>
                  <View style={styles.wrapSecAndGradArrow}>
                    <View style={styles.secGrade}>
                      <View style={styles.spaceLogoGrade} />
                      {/* <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeA}>
                                                </View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeB}>
                                                </View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeC}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeD}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeE}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeF}></View>
                                            </View>
                                            <View style={styles.wrapGrade}>
                                                <View style={styles.gasGradeG}></View>
                                            </View> */}
                      <View style={styles.spaceLogoGrade} />
                    </View>
                    <View style={styles.secArrow}>
                      {!(this.state.type_product_name === 'C3' && this.state.type_struct_id === 2) &&
                        (< React.Fragment >
                          <Image style={styles_vector.vector1} src={vector1} />
                          <Text style={styles_vector.vector1_txt}>
                            {this.state.rolling_value}
                          </Text>
                        </React.Fragment>
                        )
                      }
                    </View>
                  </View>
                </View>
                <View style={styles.imageSlippery}>
                  <View style={styles.logoSlippery}>
                    {/* <Text>Logo Slippery</Text> */}
                  </View>
                  {/* <View style={styles.spaceLogoGrade}></View> */}

                  <View style={styles.wrapSecAndGradArrow}>
                    <View style={styles.secGrade} />
                    <View style={styles.secArrow}>
                      <Image style={styles_vector.vector2} src={vector2} />
                      <Text style={styles_vector.vector2_txt}>
                        {this.state.wet_grip_value}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.imageSecBottom}>
              {!(this.state.type_product_name === 'C3' && this.state.type_struct_id === 2) &&
                  (<React.Fragment>
                    <View style={styles.volumeSec}>
                      <Image
                        style={styles_vector.volume}
                        src={this.calVolume(this.state.noise_grade)}
                      />
                    </View>
                    <View style={styles.volumeValueSec}>

                      <Text
                        style={{
                          color: "white",
                          position: "relative",
                          top: 24,
                          left: 16
                        }}
                      >
                        {this.state.noise_value}
                      </Text>

                    </View>
                  </React.Fragment>
                  )
                }
              </View>

              <View style={styles.tyreClassWrapper}>
                <View style={styles.tyreClass}>
                  <Text>Tyre Class {this.state.type_product_name}</Text>
                </View>
                <View style={styles.tyreModel}>
                  <Text>{this.state.size_symbol}</Text>
                </View>
              </View>
              <View style={styles.standardWrapper}>
                <View style={styles.qrWrapper}>
                  <View style={styles.qrTopSection} />
                  <View style={styles.qrTextSection}>
                    <Text>ID {this.state.qr_running} </Text>
                  </View>
                  <View style={styles.qrBottomSection}>
                    <Image
                      // style={styles.watermark}
                      src={this.state.qr_path}
                    />
                    {/* <QRCode renderAs='svg' value="http://facebook.github.io/react/" /> */}
                  </View>
                </View>
                <View style={styles.standardDetailWrapper}>
                  <Text>{this.state.standard1}</Text>
                  <Text>{this.state.standard2}</Text>
                  <View style={styles.importBy}>
                    {this.state.company_title_name === "ผลิตโดย" ? (
                      <View>
                        <View style={styles.importbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: importFontSize }}>
                              <Text>{this.state.company_prefix_th}</Text>
                              <Text> {part_one}</Text>
                              <Text>{part_two} </Text>
                              <Text>{this.state.company_suffix_th}</Text>
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            <Text style={{ fontSize: 13 }}>
                              {/* ผลิตโดย */}
                            </Text>
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {/* {this.state.factory_name_en} */}
                            </Text>
                          </View>
                        </View>

                        <View style={styles.productbyWrapper}>
                          <View style={styles.produceTitle}>
                            {/* <Text style={{ fontSize: 13 }}>ประเทศ</Text> */}
                          </View>
                          <View style={styles.produceValue}>
                            <Text style={{ fontSize: 13 }}>
                              {/* {(this.state.country).toUpperCase()} */}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                        <View>
                          <View style={styles.importbyWrapper}>
                            <View style={styles.produceTitle}>
                              <Text style={{ fontSize: 13 }}>นำเข้าโดย</Text>
                            </View>
                            <View style={styles.produceValue}>
                              <Text style={{ fontSize: importFontSize }}>
                                <Text>{this.state.company_prefix_th}</Text>
                                <Text> {part_one}</Text>
                                <Text>{part_two} </Text>
                                <Text>{this.state.company_suffix_th}</Text>
                              </Text>
                            </View>
                          </View>

                          <View style={styles.productbyWrapper}>
                            <View style={styles.produceTitle}>
                              <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                            </View>
                            <View style={styles.produceValue}>
                              <Text style={{ fontSize: productFontSize }}>
                                {this.state.factory_name_en === ""
                                  ? this.state.factory_name_th
                                  : this.state.factory_name_en}
                              </Text>
                            </View>
                          </View>

                          <View style={styles.productbyWrapper}>
                            <View style={styles.produceTitle}>
                              <Text style={{ fontSize: 13 }}>ประเทศ</Text>
                            </View>
                            <View style={styles.produceValue}>
                              <Text style={{ fontSize: 13 }}>
                                {this.state.country.toUpperCase()}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}
                  </View>
                  {/* <View style={styles.produceBy}>
                                        <View style={styles.productbyWrapper}>
                                            <View style={styles.produceTitle}>
                                                <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
                                            </View>
                                            <View style={styles.produceValue}>
                                                <Text style={{ fontSize: 13 }}>company</Text>
                                            </View>
                                        </View>

                                        <View style={styles.productbyWrapper}>
                                            <View style={styles.produceTitle}>
                                                <Text style={{ fontSize: 13 }}>ประเทศ</Text>
                                            </View>
                                            <View style={styles.produceValue}>
                                                <Text style={{ fontSize: 13 }}>company</Text>
                                            </View>
                                        </View>


                                    </View> */}
                </View>
              </View>
            </View>
          </View>
          {!(this.state.request_status === 2) ? (
            <Image style={styles.watermark} src={water_mark} />
          ) : null}
          {/* <Image style={styles.watermark} src={water_mark} /> */}
        </Page>
      </Document>
    );

    // // console.log('pdf blob', pdf(doc).toBlob())
    return (
      <div>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>{doc}</PDFViewer>
        <QRCode
          id="qr1"
          value={
            "https://tyre.go.th/company/sticker-preview/" + this.state.sizeId
          }
          style={{ display: "none" }}
        />
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    requestRedux: state.requestRedux
  };
};

export default connect(mapStateToProps)(previewApproveFormGeneral);
