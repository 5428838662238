import React, { Component } from 'react'
import { Table, Button, Popup } from 'semantic-ui-react'
import Axios from 'axios';
import { Apiurl } from '../../../router';

export class LicenseTable extends Component {
  constructor(props) {
    super(props)
   
    
    this.state = {
      type: 'c1',
      license: 'มอก.2718-2560',
      user: [],
      data:[],
      name:''
    }
  }

  componentWillMount() {
     var name = this.props.name.slice(-2)
    
     if(name === "C1"){
       this.setState({license:"มอก.2718-2560"})
     }else if(name === "C2" || name === "C3"){
      this.setState({license:"มอก.2719-2560"})
     }
     Axios.post(Apiurl + '/approve/get_detail_product_license', { brands_id: this.props.brands_id })
      .then(res => {
       
        this.setState({data:res.data.Data})
      }).catch(err =>{
        console.log(err)
      })
  }

  render() {
    return (
      <div>
        <Table celled fixed style={{wordBreak: 'break-word'}}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='3' textAlign='center'>ใบอนุญาต{this.state.license}</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>เลขที่ใบอนุญาต</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>รายการที่</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>เอกสารแนบ</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.data.map((item, i) =>
              <Table.Row key={item.id}>
                <Table.Cell>{item.license_request}</Table.Cell>
                <Table.Cell>
                {/* {item.list_request} */}
                <Popup position='bottom right' content={item.list_request} trigger={<span>{item.list_request}</span>} />
                </Table.Cell>
                <Table.Cell textAlign='center' >
                  <Button onClick={()=>this.props.test(item.file_path_product_request)} primary>
                    แสดง
                 </Button>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default LicenseTable
