/* eslint-disable */
import React, { Component } from "react";
import {
  Segment,
  Divider,
  Icon,
  Container,
  Form,
  Button,
  Header,
  Grid,
  Input,
  TextArea,
  Label,
  Dropdown,
  Select,
} from "semantic-ui-react";
import axios from "axios";
import {
  GET_DATA_PERSONAL,
  GET_PROVINCE,
  GET_DISTRICT,
  GET_SUB_DISTRICT,
} from "../router";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  changeFormPersonAuthorized,
  changeErrorsFormPersonAuthorized,
  tempAddressFormPersonAuthorized,
  setBackwardProductImport,
  setDefaultDelegateData,
} from "../actions/actions";
import Swal from "sweetalert2";
import Headers from "../header/headerUser";

const namePrefixTH = [
	{ key: 1, text: 'นาย', value: 1 },
	{ key: 2, text: 'นางสาว', value: 2 },
	{ key: 3, text: 'นาง', value: 3 }
];

// Select Name Prefix Place #############################################
const namePrefixEN = [
	{ key: 1, text: 'Mr.', value: 1 },
	{ key: 2, text: 'Miss', value: 2 },
	{ key: 3, text: 'Mrs.', value: 3 }
];

class addAuthorized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      forward: false,
      backward: false,
      authen: false,
      loading: false,
      nullFieldAuthen: {
        nameTH: false,
        address_id: false,
        alley: false,
        road: false,
        sub_district: false,
        district: false,
        province: false,
      },
      initialIndex: 0,
      indexType: 0,
      typeSigning: 1,
      nationalityOption: [
        { key: 1, text: "ไทย", value: 1 },
        { key: 2, text: "ต่างชาติ", value: 2 },
      ],
      type: [
        { key: 1, text: "ผู้มีอำนาจลงนามเอง", value: 1 },
        { key: 2, text: "มอบอำนาจ", value: 2 },
      ],
      tax_id_pass: true,
    };
  }

  componentWillMount = () => {
    this.setState(
      {
        optionDropdown: this.props.optionDropdown,
        person: this.props.stateForm,
        error: this.props.stateErrors,
      });
    if (this.props.haveDelegate) {
      this.setState({ indexType: 1, typeSigning: 2 });
    }
    window.scrollTo(0, 0);
    this.GetProvince(0);
  };

  componentDidMount() {
    //Checker when refresh redirect to main page
    this.getNamePrefix();
    if (
      localStorage.getItem("company_id_edit") !== null &&
      this.state.person[0].nameTH === ""
    ) {
      this.props.history.push(
        "/entrepreneurRegister/" + localStorage.getItem("company_id_edit")
      );
    }
  }

  handleOnChange = (name, value, index) =>
    this.setState(
      (state) => (
        (state.person[index][name] = value), (state.error[index][name] = false)
      )
    );

  handleChangeEngName = (name, value, index) => {
    this.setState(
      (state) => (
        (state.person[index][name] = value.toUpperCase()),
        (state.error[index][name] = false)
      )
    );
  };

  handleSigning = (name, value) =>
    this.setState(
      (state) => ((state[name] = value), (state.indexType = value - 1))
    );

  addPerson = () => {
    if (!this.validation()) {
      this.setState({ initialIndex: this.state.person.length }, () => {
        this.GetProvince(this.state.initialIndex);
      });

      this.setState(
        (state) => (
          (state.person = [
            ...state.person,
            {
              person_id: "",
              nationality: 1,
              idNumber: "",
              // name_prefixTH: 'นาย',
              // name_prefixTH_index: 0,
              // name_prefixTH_text: 'นาย',
              // name_prefixEN: 'Mr.',
              // name_prefixEN_index: 0,
              // name_prefixEN_text: 'Mr.',
              nameTH: "",
              nameEN: "",
              address: "",
              address_id: "",
              alley: "",
              road: "",
              sub_district: "",
              district: "",
              province: "",
              zip_code: "",
              phoneNumber: "",
              email: "",
              file: "",
              type: 1,
            },
          ]),
          (state.error = [
            ...state.error,
            {
              nationality: false,
              idNumber: false,
              namePrefixTH: false,
              namePrefixEN: false,
              nameTH: false,
              nameEN: false,
              address: false,
              address_id: false,
              alley: false,
              road: false,
              sub_district: false,
              district: false,
              province: false,
              zip_code: false,
              phoneNumber: false,
              email: false,
              file: false,
            },
          ])
        )
      );
    }
  };

  validation = () => {
    let errorForm = false;
    let error = this.state.error;
    this.state.person.map((person, index) => {
      if (person.file === "") {
        error[index].file = true;
        errorForm = true;
      }
      let regExEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      if (!regExEmail.test(person.email)) {
        error[index].email = true;
        errorForm = true;
        this[`email[${index}]`].focus();
      }

      let regExPhone = /([0]{1})([0-9]{8,9})/;
      if (!regExPhone.test(person.phoneNumber) || person.phoneNumber === "") {
        error[index].phoneNumber = true;
        errorForm = true;
        this[`phoneNumber[${index}]`].focus();
      }
      if (person.zip_code === "" && person.nationality === 1) {
        error[index].zip_code = true;
        errorForm = true;
        this[`zip_code[${index}]`].focus();
      }

      if (person.sub_district === "" && person.nationality === 1) {
        error[index].sub_district = true;
        errorForm = true;
        let sub_district = document.getElementById("sub_district" + index);
        sub_district.focus();
      }
      if (person.district === "" && person.nationality === 1) {
        error[index].district = true;
        errorForm = true;
        let district = document.getElementById("district" + index);
        district.focus();
      }

      if (person.province === "" && person.nationality === 1) {
        error[index].province = true;
        errorForm = true;
        let province = document.getElementById("province" + index);
        province.focus();
      }
      if (person.road === "" && person.nationality === 1) {
        error[index].road = true;
        errorForm = true;
        this[`road[${index}]`].focus();
      }
      if (person.alley === "" && person.nationality === 1) {
        error[index].alley = true;
        errorForm = true;
        this[`alley[${index}]`].focus();
      }
      if (person.address_id === "" && person.nationality === 1) {
        error[index].address_id = true;
        errorForm = true;
        this[`address_id[${index}]`].focus();
      }
      if (person.address === "" && person.nationality === 2) {
        error[index].address = true;
        errorForm = true;
        this[`address[${index}]`].focus();
      }

      if (person.name_prefixEN_index === undefined) {
        error[index].namePrefixEN = true;
        errorForm = true;
        document.getElementById(`prefixEN${index}`).focus();
      }
      if (person.nameEN === "") {
        error[index].nameEN = true;
        errorForm = true;
        this[`nameEN[${index}]`].focus();
      }

      if (person.name_prefixTH_index === undefined) {
        error[index].namePrefixTH = true;
        errorForm = true;
        document.getElementById(`prefixTH${index}`).focus();
      }
      if (person.nameTH === "") {
        error[index].nameTH = true;
        errorForm = true;
        this[`nameTH[${index}]`].focus();
      }
      if (
        person.idNumber === "" ||
        (this.state.tax_id_pass !== true && person.nationality === 1)
      ) {
        error[index].idNumber = true;
        errorForm = true;
        this[`idNumber[${index}]`].focus();
      }
    });
    this.setState({ error: error });
    return errorForm;
  };

  authentication = (ssn, index) => {
    // this.setState({
    // 	loading: true,
    // });
    this.setState(
      {
        loading: true,
      },
      () => {
        this.setState({
          loading: false,
          authen: true,
          tax_id_pass: true,
        });
      }
    );
    // axios
    // 	.get(`${GET_DATA_PERSONAL}${ssn}`)
    // 	.then((response) => {
    // 		if (response.data.status === 'success') {
    // 			let data = response.data.value;

    // 			if (data.houseNo === null) {
    // 				this.state.nullFieldAuthen.address_id = true;
    // 			}
    // 			else {
    // 				this.state.person[index].address_id = data.houseNo;
    // 				this.state.error[index].address_id = false;
    // 			}

    // 			if (data.alleyDesc === null) {
    // 				this.state.nullFieldAuthen.alley = true;
    // 			}
    // 			else {
    // 				this.state.person[index].alley = data.alleyDesc;
    // 				this.state.error[index].alley = false;
    // 			}

    // 			if (data.roadDesc === null) {
    // 				this.state.nullFieldAuthen.road = true;
    // 			}
    // 			else {
    // 				this.state.person[index].road = data.roadDesc;
    // 				this.state.error[index].road = false;
    // 			}

    // 			if (data.fullnameAndRank !== null) {
    // 				this.state.person[index].nameTH = data.fullnameAndRank;
    // 			}
    // 			else {
    // 				this.state.person[index].nameTH = `${data.titleName}${data.firstName} ${data.lastName}`;
    // 			}

    // 			this.state.error[index].nameTH = false;

    // 			this.setState({
    // 				authen: true,
    // 				tax_id_pass: true,
    // 				loading: false
    // 			});
    // 		}
    // 		else {
    // 			// this.state.person[index].nameTH = '';
    // 			// this.state.person[index].address_id = '';
    // 			// this.state.person[index].alley = '';
    // 			// this.state.person[index].road = '';
    // 			// this.setState({ authen: true, tax_id_pass: false, loading: false });
    // 			// Swal.fire({
    // 			// 	type: 'error',
    // 			// 	title: `ไม่พบข้อมูล กรุณาติดต่อเข้าหน้าที่`
    // 			// });
    // 			this.setState({ authen: true, tax_id_pass: true, loading: false });
    // 		}
    // 	})
    // 	.catch((error) => {
    // 		// let msg = 'เกิดข้อผิดพลาดไม่สามารถเชื่อมต่อกับระบบได้';
    // 		// if (error.response.data.message === 'Tax ID repeatedly') {
    // 		// 	msg = `เลขที่ประจำตัวนี้
    // 		// 		ถูกลงทะเบียนแล้ว`;
    // 		// }

    // 		// this.setState({ authen: true, tax_id_pass: false, loading: false });
    // 		// Swal.fire({
    // 		// 	type: 'error',
    // 		// 	title: msg
    // 		// });

    // 		this.setState({ authen: true, tax_id_pass: true, loading: false });
    // 	});
  };

  handleSSNField = async (name, value, index) => {
    await this.setState(
      (state) => (
        (state.person[index][name] = value),
        (state.error[index].idNumber = false)
      )
    );
    let res = value.replace(/-/g, "");
    let resShow = "";
    for (let i = 0; i < this.state.person[index].idNumber.length; i++) {
      if (i === 1 || i === 5 || i === 10 || i === 12) {
        resShow += "-" + res.charAt(i);
      } else if (i === 5) {
        resShow += "-" + res.charAt(i);
      } else if (i === 10) {
        resShow += "-" + res.charAt(i);
      } else if (i === 12) {
        resShow += "-" + res.charAt(i);
      } else {
        resShow += res.charAt(i);
      }
    }
    if (resShow.length === 17) {
      if (this.checkSSNPattern(res)) {
        this.setState({ tax_id_pass: true });
      } else {
        this.setState({ tax_id_pass: false });
      }
    } else {
      this.setState({ tax_id_pass: false });
    }
    this.setState((state) => (state.person[index].idNumber = resShow));
  };

  checkSSNPattern = (ssn) => {
    let pattern = 0;
    for (let i = 0; i < 12; i++)
      pattern += parseFloat(ssn.charAt(i)) * (13 - i);
    if ((11 - (pattern % 11)) % 10 != parseFloat(ssn.charAt(12))) {
      return false;
    } else {
      return true;
    }
  };

  SSNField = (index) => {
    return (
      <Grid.Row>
        <Grid.Column computer="16" tablet="16">
          เลขประจำตัวประชาชน <span style={{ color: "red" }}>*</span>
          <Form.Field error={this.state.error[index].idNumber}>
            <Input
              name="idNumber"
              placeholder="ตัวอย่าง    X-XXXX-XXXXX-XX-X"
              maxLength="17"
              minLength="17"
              value={this.state.person[index].idNumber}
              onChange={(e, { name, value }) =>
                this.handleSSNField(name, value.replace(/\D/g, ""), index)
              }
              ref={(input) => {
                this[`idNumber[${index}]`] = input;
              }}
            />
            <p
              style={{
                color: "#db2828",
                fontWeight: "bold",
                paddingTop: "1rem",
              }}
            >
              หมายเหตุ : กรอกเลขที่ประจำตัวประชาชน
            </p>
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  passportField = (index) => {
    return (
      <Grid.Column computer="16">
        เลขที่หนังสือเดินทาง <span style={{ color: "red" }}>*</span>
        <Form.Field error={this.state.error[index].idNumber}>
          <Input
            name="idNumber"
            icon="briefcase"
            iconPosition="left"
            value={this.state.person[index].idNumber}
            onChange={(e, { name, value }) =>
              this.handleOnChange(name, value, index)
            }
            ref={(input) => {
              this[`idNumber[${index}]`] = input;
            }}
          />
        </Form.Field>
      </Grid.Column>
    );
  };

  handleUploadFile = (event, index) => {
    if (event.target.files[0] !== undefined) {
      if (event.target.files[0].type === "application/pdf") {
        let files = event.target.files[0];
        if (files.size > 10000000) {
          Swal.fire({
            type: "error",
            title: `ขนาดไฟล์ใหญ่เกินไป`,
            text: `ขนาดไฟล์ต้องไม่เกิน 10 MB`,
          });
          return;
        }
        this.setState(
          (state) => (
            (state.person[index].file = files),
            (state.error[index].file = false)
          )
        );
      } else {
        Swal.fire({
          type: "error",
          title: `ชนิดไฟล์ไม่ถูกต้อง 
				กรุณาตรวจสอบใหม่อีกครั้ง`,
        });
      }
    }
  };

  handleUploadFileReset = (e, index) =>
    this.setState((state) => (state.person[index].file = ""));

  handleSeleteType = async (name, value, index) => {
    this.state.person[index].nameTH = "";
    this.state.person[index].nameEN = "";
    this.state.person[index].address = "";
    this.state.person[index].idNumber = "";
    this.state.person[index].nameTH = "";
    this.state.person[index].address_id = "";
    this.state.person[index].alley = "";
    this.state.person[index].road = "";
    this.state.person[index].sub_district = "";
    this.state.person[index].district = "";
    this.state.person[index].province = "";

    this.state.error[index].idNumber = false;
    this.state.error[index].nameTH = false;
    this.state.error[index].nameEN = false;
    this.state.error[index].address = false;
    this.state.error[index].phoneNumber = false;
    this.state.error[index].email = false;
    this.state.error[index].file = false;
    this.setState((state) => (state.person[index][name] = value));
  };

  GetProvince = (index) => {
    axios
      .get(GET_PROVINCE)
      .then((res) => {
        const data = res.data.value;
        let dataprovince = data.map((i) => ({
          key: i.id,
          text: i.name_th,
          value: i.name_th,
        }));
        this.state.optionDropdown.dataprovince[index] = dataprovince;
        this.setState({
          dataprovince: this.state.dataprovince,
        });
      })
      .catch((error) => {});
  };

  GetDistrict = (value, index) => {
    try {
      axios.get(GET_DISTRICT + "?province_id=" + value).then((res) => {
        const data = res.data.value;
        let datadistrict = data.map((i) => ({
          key: i.id,
          text: i.name_th,
          value: i.name_th,
        }));
        this.state.optionDropdown.datadistrict[index] = datadistrict;
        this.setState({
          datadistrict: this.state.datadistrict,
        });
      });
    } catch (error) {}
  };

  GetSubDistrict = async (value, index) => {
    axios.get(GET_SUB_DISTRICT + "?amphure_id=" + value).then((res) => {
      const data = res.data.value;
      let dataSubDistrict = data.map((i) => ({
        key: i.id,
        text: i.name_th,
        value: i.name_th,
      }));
      this.state.optionDropdown.dataSubDistrict[index] = dataSubDistrict;
      this.setState({
        dataSubDistrict: this.state.dataSubDistrict,
        tempSubDistrict: data,
      });
    });
  };

  GetPostCode = (value, index) => {
    let zipcode = this.state.tempSubDistrict.find((data) => {
      return data.id === value;
    });
    if (zipcode !== undefined) {
      this.setState(
        (state) => (state.person[index].zip_code = zipcode.zip_code)
      );
      this.state.error[index].zip_code = false;
    } else {
      this.setState((state) => (state.person[index].zip_code = ""));
    }
  };

  handleChangeAddress = (event, name, value, index) => {
    if (name === `province${index}`) {
      this.setState(
        (state) => (
          (state.person[index].province = value),
          (state.person[index].district = ""),
          (state.person[index].sub_district = ""),
          (state.person[index].zip_code = ""),
          (state.optionDropdown.datadistrict[index] = ""),
          (state.optionDropdown.dataSubDistrict[index] = "")
        )
      );
      if (value !== "") {
        let valueObj = this.state.optionDropdown.dataprovince[index].filter(
          (data) => data.value === value
        );
        this.setState((state) => (state.error[index].province = false));
        this.GetDistrict(valueObj[0].key, index);
      }
    }
    if (name === `district${index}`) {
      this.setState(
        (state) => (
          (state.person[index].district = value),
          (state.person[index].sub_district = ""),
          (state.person[index].zip_code = ""),
          (state.optionDropdown.dataSubDistrict[index] = "")
        )
      );
      if (value !== "") {
        this.setState((state) => (state.error[index].district = false));
        let valueObj = this.state.optionDropdown.datadistrict[index].filter(
          (data) => data.value === value
        );
        this.GetSubDistrict(valueObj[0].key, index);
      }
    }
    if (name === `sub_district${index}`) {
      this.setState(
        (state) => (
          (state.person[index].sub_district = value),
          (state.person[index].zip_code = "")
        )
      );
      if (value !== "") {
        this.setState((state) => (state.error[index].sub_district = false));
        let valueObj = this.state.optionDropdown.dataSubDistrict[index].filter(
          (data) => data.value === value
        );
        this.GetPostCode(valueObj[0].key, index);
      }
    }
  };

  checkValidateToForward = () => {
    if (!this.validation()) {
      this.props.dispatch(changeFormPersonAuthorized(this.state.person));
      if (this.state.typeSigning === 1) {
        this.props.dispatch(setDefaultDelegateData());
      }
      this.props.dispatch(changeErrorsFormPersonAuthorized(this.state.error));
      this.props.dispatch(
        tempAddressFormPersonAuthorized(this.state.optionDropdown)
      );
      this.setState({ forward: true });
      window.scrollTo(0, 0);
    }
  };

  checkValidateToBackward = () => {
    this.props.dispatch(changeFormPersonAuthorized(this.state.person));
    this.props.dispatch(setBackwardProductImport());
    this.props.dispatch(changeErrorsFormPersonAuthorized(this.state.error));
    this.props.dispatch(
      tempAddressFormPersonAuthorized(this.state.optionDropdown)
    );
    this.setState({ backward: true });
    window.scrollTo(0, 0);
  };

  namePrefixChange = async (event, { name, value }, index) => {
    // this.setState((state) => ((state.person[index][name] = value), (state.error[index][name] = false)));
    const person = this.state.person[index];
    const error = this.state.error[index];
    const textContent = event.target.textContent;

    if (name === "name_prefixTH") {
      this.setState(
        () => (
          (person.name_prefixTH = value),
          (person.name_prefixTH_index = value - 1),
          (person.name_prefixTH_text = namePrefixTH[value-1].text),
          (error.namePrefixTH = false)
        )
      );
    } else if (name === "name_prefixEN") {
      this.setState(
        () => (
          (person.name_prefixEN = value),
          (person.name_prefixEN_index = value - 1),
          (person.name_prefixEN_text = namePrefixEN[value-1].text),
          (error.namePrefixEN = false)
        )
      );
    }
  };
  getNamePrefix = () => {
    for (let idx = 0; idx < this.state.person.length; idx++) {
      const person = this.state.person[idx];
      for (let i = 0; i < namePrefixTH.length; i++) {
        const idxOfPrefixTH = person.nameTH.indexOf(namePrefixTH[i].text);
        if (idxOfPrefixTH === 0) {
          this.setState(
            () => (
              (person.nameTH = person.nameTH.substring(
                idxOfPrefixTH +
                  namePrefixTH[i].text.length +
                  (person.nameTH[namePrefixTH[i].text.length] !== " " ? 0 : 1)
              )),
              (person.name_prefixTH = i + 1),
              (person.name_prefixTH_index = i),
              (person.name_prefixTH_text = namePrefixTH[i].text)
            )
          );
          break;
        }
      }

      // const prefixEN = ['Mr.', 'Miss', 'Mrs.']
      for (let i = 0; i < namePrefixEN.length; i++) {
        const idxOfPrefixEN = person.nameEN
          .toUpperCase()
          .indexOf(namePrefixEN[i].text.toUpperCase());
        if (idxOfPrefixEN === 0) {
          this.setState(
            () => (
              (person.nameEN = person.nameEN.substring(
                idxOfPrefixEN +
                  namePrefixEN[i].text.length +
                  (person.nameEN[namePrefixEN[i].text.length] !== " " ? 0 : 1)
              )),
              (person.name_prefixEN = i + 1),
              (person.name_prefixEN_index = i),
              (person.name_prefixEN_text = namePrefixEN[i].text)
            )
          );
          break;
        }
      }
    }
  };

  render = () => {
    const indexPerson = this.state.person.length;
    if (this.state.forward && this.state.typeSigning === 1)
      return <Redirect to="/registerConfirm" />;
    if (this.state.forward && this.state.typeSigning === 2)
      return <Redirect to="/addDelegate" />;
    if (this.state.backward) {
      if (this.props.previous) {
        return <Redirect to="/productImport" />;
      } else {
        return <Redirect to="/productRegister" />;
      }
    }

    return (
      <div>
        <Headers />
        <Container>
          <br />
          <Form loading={this.state.loading}>
            <Segment padded="very" color="violet">
              <Header>
                <h1>
                  <Icon color="violet" name="users" />
                  ข้อมูลผู้มีอำนาจลงนาม
                </h1>
              </Header>
              <Divider />
              {this.state.person.map((person, index) => (
                <div key={index}>
                  <h3>ผู้มีอำนาจลงนามลำดับที่ {index + 1}</h3>
                  <Grid>
                    <Grid.Column computer="16">
                      <Form.Dropdown
                        label="สัญชาติ"
                        fluid
                        selection
                        wrapSelection={false}
                        options={this.state.nationalityOption}
                        value={
                          this.state.nationalityOption[person.nationality - 1]
                            .value
                        }
                        placeholder="Choose an option"
                        name="nationality"
                        onChange={(e, { name, value }) =>
                          this.handleSeleteType(name, value, index)
                        }
                      />
                    </Grid.Column>
                    {person.nationality === 1
                      ? this.SSNField(index)
                      : this.passportField(index)}

                    <Grid.Column computer="16">
                      ชื่อภาษาไทย <span style={{ color: "red" }}>*</span>
                      <Form.Group>
                        <Form.Field
                          error={this.state.error[index].namePrefixTH}
                          width={3}
                        >
                          <Dropdown
                            id={`prefixTH${index}`}
                            fluid
                            selection
                            wrapSelection={false}
                            options={namePrefixTH}
                            value={
                              person.name_prefixTH_index !== undefined
                                ? namePrefixTH[person.name_prefixTH_index].value
                                : null
                            }
                            placeholder="Choose an option"
                            name="name_prefixTH"
                            onChange={(e, value) =>
                              this.namePrefixChange(e, value, index)
                            }
                          />
                        </Form.Field>
                        <Form.Field
                          error={this.state.error[index].nameTH}
                          width={13}
                        >
                          <Input
                            name="nameTH"
                            // readOnly={
                            // 	person.nationality === 1 &&
                            // 	(!this.state.nullFieldAuthen.nameTH || !this.state.authen)
                            // }
                            icon="user"
                            iconPosition="left"
                            value={person.nameTH}
                            onChange={(e, { name, value }) =>
                              this.handleOnChange(name, value, index)
                            }
                            ref={(input) => {
                              this[`nameTH[${index}]`] = input;
                            }}
                          />
                          <p
                            style={{
                              color: "#db2828",
                              fontWeight: "bold",
                              paddingTop: "1rem",
                            }}
                          >
                            หมายเหตุ : กรอกชื่อภาษาไทย เว้นวรรคชื่อและนามสกุล
                          </p>
                        </Form.Field>
                      </Form.Group>
                    </Grid.Column>
                    <Grid.Column computer="16">
                      ชื่อภาษาอังกฤษ <span style={{ color: "red" }}>*</span>
                      <Form.Group>
                        <Form.Field
                          error={this.state.error[index].namePrefixEN}
                          width={3}
                        >
                          <Dropdown
                            id={`prefixEN${index}`}
                            fluid
                            selection
                            wrapSelection={false}
                            options={namePrefixEN}
                            value={
                              person.name_prefixEN_index !== undefined
                                ? namePrefixEN[person.name_prefixEN_index].value
                                : null
                            }
                            placeholder="Choose an option"
                            name="name_prefixEN"
                            onChange={(e, value) =>
                              this.namePrefixChange(e, value, index)
                            }
                          />
                        </Form.Field>
                        <Form.Field
                          error={this.state.error[index].nameEN}
                          width={13}
                        >
                          <Input
                            name="nameEN"
                            icon="user"
                            iconPosition="left"
                            value={person.nameEN}
                            onChange={(e, { name, value }) =>
                              this.handleChangeEngName(name, value, index)
                            }
                            ref={(input) => {
                              this[`nameEN[${index}]`] = input;
                            }}
                          />
                          <p
                            style={{
                              color: "#db2828",
                              fontWeight: "bold",
                              paddingTop: "1rem",
                            }}
                          >
                            หมายเหตุ : กรอกชื่อภาษาอังกฤษ เว้นวรรคชื่อและนามสกุล
                          </p>
                        </Form.Field>
                      </Form.Group>
                    </Grid.Column>
                    {person.nationality === 1 ? (
                      <React.Fragment>
                        <Grid.Column computer="16">
                          <Form.Field
                            error={this.state.error[index].address_id}
                          >
                            เลขที่ <span style={{ color: "red" }}>*</span>
                            <Input
                              name="address_id"
                              // readOnly={
                              // 	!this.state.nullFieldAuthen.address_id ||
                              // 	!this.state.authen
                              // }
                              icon="point"
                              iconPosition="left"
                              placeholder="ตัวอย่าง: XX/XX หมู่ที่ XX"
                              value={person.address_id}
                              onChange={(e, { name, value }) =>
                                this.handleOnChange(name, value, index)
                              }
                              ref={(input) => {
                                this[`address_id[${index}]`] = input;
                              }}
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer="16">
                          <Form.Field error={this.state.error[index].alley}>
                            ซอย <span style={{ color: "red" }}>*</span>
                            <Input
                              name="alley"
                              // readOnly={
                              // 	!this.state.nullFieldAuthen.alley || !this.state.authen
                              // }
                              icon="compress"
                              iconPosition="left"
                              value={person.alley}
                              ref={(input) => {
                                this[`alley[${index}]`] = input;
                              }}
                              onChange={(e, { name, value }) =>
                                this.handleOnChange(name, value, index)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer="16">
                          <Form.Field error={this.state.error[index].road}>
                            ถนน <span style={{ color: "red" }}>*</span>
                            <Input
                              name="road"
                              // readOnly={
                              // 	!this.state.nullFieldAuthen.road || !this.state.authen
                              // }
                              icon="road"
                              iconPosition="left"
                              value={person.road}
                              ref={(input) => {
                                this[`road[${index}]`] = input;
                              }}
                              onChange={(e, { name, value }) =>
                                this.handleOnChange(name, value, index)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer="16">
                          <Form.Field error={this.state.error[index].province}>
                            จังหวัด <span style={{ color: "red" }}>*</span>
                            <Select
                              clearable
                              search
                              fluid
                              placeholder="เลือกจังหวัด"
                              selection
                              id={"province" + index}
                              name={"province" + index}
                              options={
                                this.state.optionDropdown.dataprovince[index]
                              }
                              value={person.province}
                              onChange={(e, { name, value }) =>
                                this.handleChangeAddress(e, name, value, index)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer="16">
                          <Form.Field error={this.state.error[index].district}>
                            อำเภอ/เขต <span style={{ color: "red" }}>*</span>
                            <Select
                              clearable
                              search
                              fluid
                              placeholder="เลือกอำเภอ/เขต"
                              selection
                              id={"district" + index}
                              name={"district" + index}
                              options={
                                this.state.optionDropdown.datadistrict[index]
                              }
                              value={person.district}
                              onChange={(e, { name, value }) =>
                                this.handleChangeAddress(e, name, value, index)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer="16">
                          <Form.Field
                            error={this.state.error[index].sub_district}
                          >
                            ตำบล/แขวง <span style={{ color: "red" }}>*</span>
                            <Select
                              clearable
                              search
                              fluid
                              placeholder="เลือกตำบล/แขวง"
                              selection
                              id={"sub_district" + index}
                              name={"sub_district" + index}
                              options={
                                this.state.optionDropdown.dataSubDistrict[index]
                              }
                              value={person.sub_district}
                              onChange={(e, { name, value }) =>
                                this.handleChangeAddress(e, name, value, index)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column computer="16">
                          <Form.Field error={this.state.error[index].zip_code}>
                            รหัสไปรษณีย์ <span style={{ color: "red" }}>*</span>
                            <Input
                              icon="envelope"
                              iconPosition="left"
                              name="zip_code"
                              placeholder="รหัสไปรษณีย์"
                              maxLength="5"
                              value={person.zip_code}
                              onChange={(e, { name, value }) =>
                                this.handleOnChange(name, value, index)
                              }
                              ref={(input) => {
                                this[`zip_code[${index}]`] = input;
                              }}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </React.Fragment>
                    ) : (
                      <Grid.Column computer="16">
                        <Form.Field error={this.state.error[index].address}>
                          ที่อยู่ <span style={{ color: "red" }}>*</span>
                          <TextArea
                            name="address"
                            readOnly={person.nationality === 1}
                            value={person.address}
                            onChange={(e, { name, value }) =>
                              this.handleOnChange(name, value, index)
                            }
                            ref={(input) => {
                              this[`address[${index}]`] = input;
                            }}
                          />
                        </Form.Field>
                      </Grid.Column>
                    )}

                    <Grid.Column computer="16">
                      <Form.Field error={this.state.error[index].phoneNumber}>
                        เบอร์โทรศัพท์ <span style={{ color: "red" }}>*</span>
                        <Input
                          name="phoneNumber"
                          icon="phone"
                          iconPosition="left"
                          maxLength={10}
                          value={person.phoneNumber}
                          onChange={(e, { name, value }) =>
                            this.handleOnChange(
                              name,
                              value.replace(/\D/g, ""),
                              index
                            )
                          }
                          ref={(input) => {
                            this[`phoneNumber[${index}]`] = input;
                          }}
                        />
                        <p
                          style={{
                            color: "#db2828",
                            fontWeight: "bold",
                            paddingTop: "1rem",
                          }}
                        >
                          หมายเหตุ : กรอกเบอร์โทรศัพท์ที่สามารถติดต่อได้
                          โดยไม่ต้องใส่เครื่องหมาย -
                        </p>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column computer="16">
                      <Form.Field error={this.state.error[index].email}>
                        e-mail <span style={{ color: "red" }}>*</span>
                        <Input
                          name="email"
                          type="email"
                          icon="mail"
                          iconPosition="left"
                          value={person.email}
                          onChange={(e, { name, value }) =>
                            this.handleOnChange(name, value, index)
                          }
                          ref={(input) => {
                            this[`email[${index}]`] = input;
                          }}
                        />
                        <p
                          style={{
                            color: "#db2828",
                            fontWeight: "bold",
                            paddingTop: "1rem",
                          }}
                        >
                          หมายเหตุ : กรอก e-mail ที่ใช้งานอยู่จริง
                          และตรวจสอบตัวสะกดให้ถูกต้อง
                        </p>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginRight: 50,
                      }}
                    >
                      <Grid.Column computer={4} tablet={4}>
                        <label>
                          {person.nationality === 1 ? (
                            <b>
                              เอกสารแนบ: <br />
                              สำเนาบัตรประจำตัวประชาชน
                            </b>
                          ) : (
                            <b>
                              เอกสารแนบ: <br />
                              สำเนาหนังสือเดินทาง
                            </b>
                          )}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </Grid.Column>
                      <Grid.Column computer={8} tablet={8}>
                        <Segment>
                          <Label
                            attached="top"
                            color={
                              this.state.error[index].file ? "red" : "violet"
                            }
                            as="label"
                            htmlFor={"file" + index}
                            size="large"
                          >
                            <Icon name="upload" />
                            เลือกไฟล์
                            <input
                              hidden
                              type="file"
                              name="file"
                              id={"file" + index}
                              accept=".pdf"
                              onChange={(e) => this.handleUploadFile(e, index)}
                            />
                          </Label>
                          <p>
                            {person.file.name !== undefined && person.file.name}
                            &nbsp; &nbsp;
                            {person.file.name && (
                              <Icon
                                id="file_doc_reset"
                                color="red"
                                size="large"
                                name="times circle outline"
                                link
                                onClick={(e) =>
                                  this.handleUploadFileReset(e, index)
                                }
                              />
                            )}
                          </p>
                        </Segment>
                        <p
                          style={{
                            color: "#db2828",
                            fontWeight: "bold",
                            paddingTop: "1rem",
                          }}
                        >
                          ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
                        </p>
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4}>
                        <Label
                          size="large"
                          basic
                          color="red"
                          pointing="left"
                          style={{
                            display: this.state.error[index].file
                              ? "block"
                              : "none",
                          }}
                        >
                          <Icon name="warning circle" />
                          กรุณาเพิ่มไฟล์ !
                        </Label>
                      </Grid.Column>
                      {index !== 0 && (
                        <Grid.Column computer="16" style={{ marginTop: 30 }}>
                          <Button
                            color="red"
                            floated="right"
                            onClick={() =>
                              this.setState((state) =>
                                state.person.splice(index, 1)
                              )
                            }
                          >
                            ลบข้อมูลผู้มีอำนาจลงนามลำดับที่ {indexPerson}
                          </Button>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </div>
              ))}
              <Grid>
                <Grid.Column computer="16">
                  <Button onClick={this.addPerson} primary>
                    ผู้มีอำนาจลงนามลำดับที่ {indexPerson + 1}
                  </Button>
                </Grid.Column>
                <Grid.Column computer="16">
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                    การลงนาม<span style={{ color: "red" }}>*</span>
                  </p>
                  <Dropdown
                    label="การลงนาม"
                    fluid
                    selection
                    wrapSelection={false}
                    options={this.state.type}
                    value={this.state.type[this.state.indexType].value}
                    placeholder="Choose an option"
                    name="typeSigning"
                    onChange={(e, { name, value }) =>
                      this.handleSigning(name, value)
                    }
                  />
                </Grid.Column>
              </Grid>
            </Segment>
          </Form>
          <br />
          <Button
            icon
            labelPosition="left"
            floated="left"
            onClick={this.checkValidateToBackward}
          >
            กลับไปหน้าที่แล้ว
            <Icon name="left arrow" />
          </Button>
          <Button
            icon
            labelPosition="left"
            color="violet"
            floated="right"
            onClick={this.checkValidateToForward}
            type="sumbit"
          >
            ไปหน้าถัดไป
            <Icon name="right arrow" />
          </Button>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </div>
    );
  };
}

const mapStateToProps = function (state) {
  return {
    message: "This is message from mapStateToProps",
    stateForm: state.reduxxx.personAuthorized,
    stateErrors: state.reduxxx.errorPersonAuthorized,
    previous: state.reduxxx.productImport,
    optionDropdown: state.reduxxx.optionAddressDropdown,
    haveDelegate: state.reduxxx.addDelegate,
  };
};

export default connect(mapStateToProps)(addAuthorized);
