import { combineReducers } from 'redux';
import reduxxx from './reduxxx';
import requestRedux from './requestRedux';
import StoreCompanyResponse from './StoreCompanyResponse';
import ApprovalReducer from "./ApprovalReducer";
export default combineReducers({
	reduxxx,
	requestRedux,
	StoreCompanyResponse,
	ApprovalReducer
});
