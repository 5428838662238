import React, { Component } from "react";
import {
	Segment,
	Divider,
	Header,
	Icon,
	Container,
	Form,
	Button,
	Label,
	Input,
	Grid,
	Checkbox,
} from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import {
	GET_DATA_PERSONAL,
	GET_PROVINCE,
	GET_DISTRICT,
	GET_SUB_DISTRICT,
	GET_DATA_COMPANY,
	HOST_PATH_FILE,
	CHECK_REGIS_EDITING_STATUS_TEMP,
	GET_REGIS_EDITING_DATA_COMPANY_ENTREPRENEUR,
	INDUSTRY_COMPANY_REGISTER,
} from "../router";
import { GET_DATA_JURISTIC } from "../router";
import { Redirect } from "react-router";
import { connect } from "react-redux"; // connect redux
import { reduxSource } from "../actions/actions";
import Swal from "sweetalert2";
import Headers from "../header/headerUser";
import Axios from "axios";
import styled from "styled-components";
import Modals from "../Modals";

// Select Personality Type  #############################################
// const tradeMark = [ { key: 1, text: 'มี', value: 1 }, { key: 2, text: 'ไม่มี', value: 2 } ];

// Select Name Prefix Place #############################################
const namePrefixTH = [
	{ key: 1, text: "นาย", value: 1 },
	{ key: 2, text: "นางสาว", value: 2 },
	{ key: 3, text: "นาง", value: 3 },
];

// Select Name Prefix Place #############################################
const namePrefixEN = [
	{ key: 1, text: "Mr.", value: 1 },
	{ key: 2, text: "Miss", value: 2 },
	{ key: 3, text: "Mrs.", value: 3 },
];

const renderLabelCompanyType = (label) => ({
	//   color: 'blue',
	content: `${label.text}`,
	icon: "check",
});

class entrepreneurRegister extends Component {
	constructor(props) {
		super(props);

		this.state = {
			entrepreneur_type_array: [],
			personal_nameTH: "",
			personal_nameEN: "",
			name_juristicTH: "",
			name_juristicEN: "",
			name_limited_partnershipTH: "",
			name_limited_partnershipEN: "",
			name_company_limitedTH: "",
			name_company_limitedEN: "",
			name_public_companyTH: "",
			name_public_companyEN: "",
			entrepreneur_type: [],
			tax_id: "",
			tax_id_show: "",
			tradeMark_id: "",
			address_id: "",
			alley: "",
			road: "",
			phone_number: "",
			fax_number: "",
			website: "",
			file_corporation_certificate: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ
			file_vat_certificate: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ
			file_trademark: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ
			personality_value: 1,
			personality_index: 0,
			personality_text: "บุคคลธรรมดา",
			show_tradeMark_id: true,
			show_person_form: true,
			show_incorporated_form: false,
			show_limited_partnership_form: false,
			show_company_limited_form: false,
			show_public_company_form: false,
			message_file_error: "false",
			redirect: false,
			redirectConfirm: false,

			// name_prefixTH_value: '1',
			// name_prefixTH_index: 0,
			// name_prefixTH_text: 'นาย',
			// name_prefixEN_value: '1',
			// name_prefixEN_index: 0,
			// name_prefixEN_text: 'Mr.',

			Get_Data_province: [],
			Get_Data_district: [],
			Get_Data_subDistrict: [],
			Data_province: "",
			Data1Options: [],
			Data2Options: [],
			Data3Options: [],
			Data4Options: [],
			province_value: "",
			province_text: "",
			district_value: "",
			district_text: "",
			sub_district_value: "",
			sub_district_text: "",
			dataprovince: [],
			district_Disable: true,
			sub_district_Disable: true,
			datadistrict: [],
			dataSubDistrict: [],
			tempSubDistrict: [],
			province: "",
			district: "",
			sub_district: "",
			zip_code: "",
			zipcode_disabled_state: true,
			province_error: false,
			district_error: false,
			sub_district_error: false,
			zip_code_error: false,

			tradeMark_value: 1,
			tradeMark_index: 0,

			nextpage_path: "/productRegister",

			authen: false,
			loading: false,
			checkValue: {
				address_id: false,
				alley: false,
				road: false,
			},
			personalityType: [
				{ key: 1, text: "บุคคลธรรมดา", value: 1 },
				{ key: 2, text: "ห้างหุ้นส่วนสามัญนิติบุคคล", value: 2 },
				{ key: 3, text: "ห้างหุ้นส่วนจำกัด", value: 3 },
				{ key: 4, text: "บริษัทจำกัด", value: 4 },
				{ key: 5, text: "บริษัทมหาชนจำกัด", value: 5 },
			],
			companyType: [
				{ key: 1, text: "ผู้ผลิต", value: 1 },
				{ key: 2, text: "ผู้นำเข้า", value: 2 },
			],
			tradeMark: [
				{ key: 1, text: "มี", value: 1 },
				{ key: 2, text: "ไม่มี", value: 2 },
			],
			tax_id_pass: false,
			forwardProductImport: true,
			pdpa_request: false,
			pdpa_request_error: false,
			openModalsPDPA: false,
			allData: {},
		};
		this.handleChange = this.handleChange.bind(this);
	}
	fileInputRef = React.createRef();

	// Upload File Control ##############################################
	async handleUploadFileChange(e) {
		if (e.target.files[0] !== undefined) {
			if (e.target.files[0].type === "application/pdf") {
				if (e.target.files[0]) {
					if (e.target.files[0].size > 10000000) {
						Swal.fire({
							type: "error",
							title: `ขนาดไฟล์ใหญ่เกินไป`,
							text: `ขนาดไฟล์ต้องไม่เกิน 10 MB`,
						});
						return;
					}
					var src = URL.createObjectURL(e.target.files[0]);
					var id = e.target.id;
					const form = new FormData();
					form.append("test_file", e.target.files[0]);
					await this.setState({
						[id]: {
							name: e.target.files[0].name,
							data: e.target.files[0],
							path: src,
						},
					});
					if (id === "file_corporation_certificate") {
						this.setState({ file_corporation_certificate_error: false });
					} else if (id === "file_vat_certificate") {
						this.setState({ file_vat_certificate_error: false });
					} else if (id === "file_trademark") {
						this.setState({ file_trademark_error: false });
					}
				}
			} else {
				Swal.fire({
					type: "error",
					title: `ชนิดไฟล์ไม่ถูกต้อง 
				กรุณาตรวจสอบใหม่อีกครั้ง`,
				});
			}
		}
	}

	async handleUploadFileReset(e) {
		if (e.target.id === "file_corporation_certificate_reset") {
			await this.setState({
				file_corporation_certificate: { name: "", data: "", path: "" },
				file_corporation_certificate_error: true,
			});
			document.getElementById("file_corporation_certificate").value = null;
		} else if (e.target.id === "file_vat_certificate_reset") {
			await this.setState({
				file_vat_certificate: { name: "", data: "", path: "" },
				file_vat_certificate_error: true,
			});
			document.getElementById("file_vat_certificate").value = null;
		} else if (e.target.id === "file_trademark_reset") {
			await this.setState({
				file_trademark: { name: "", data: "", path: "" },
				file_trademark_error: true,
			});
			document.getElementById("file_trademark").value = null;
		}
	}

	namePrefixChange = async (event, { name, value }) => {
		if (name === "name_prefixTH") {
			this.setState({
				name_prefixTH: value,
				name_prefixTH_index: value - 1,
				name_prefixTH_text: namePrefixTH[value - 1].text,
				prefix_personal_nameTH_error: false,
			});
		} else if (name === "name_prefixEN") {
			this.setState({
				name_prefixEN: value,
				name_prefixEN_index: value - 1,
				name_prefixEN_text: namePrefixEN[value - 1].text,
				prefix_personal_nameEN_error: false,
			});
		}
	};

	setDefaultValue() {
		this.setState({
			// Clear Value
			personal_nameTH: "",
			personal_nameEN: "",
			name_juristicTH: "",
			name_juristicEN: "",
			name_limited_partnershipTH: "",
			name_limited_partnershipEN: "",
			name_company_limitedTH: "",
			name_company_limitedEN: "",
			name_public_companyTH: "",
			name_public_companyEN: "",
			entrepreneur_type: [],
			entrepreneur_type_text: "",
			tradeMark_value: 1,
			tax_id_show: "",
			tradeMark_id: "",
			address_id: "",
			alley: "",
			road: "",
			province_value: "",
			district_value: "",
			sub_district_value: "",
			zip_code: "",
			phone_number: "",
			fax_number: "",
			website: "",
			file_corporation_certificate: { name: "", data: [], path: "" },
			file_vat_certificate: { name: "", data: [], path: "" },
			file_trademark: { name: "", data: [], path: "" },
		});
	}

	onPersonalityChange = async (event, { name, value }) => {
		if (name === "personality_type" && value === 1) {
			this.setState({
				show_person_form: true,
				show_incorporated_form: false,
				show_limited_partnership_form: false,
				show_company_limited_form: false,
				show_public_company_form: false,

				// Set Filed Error
				// personal_nameTH_error: false,
				// personal_nameEN_error: false,

				name_juristicTH_error: false,
				name_juristicEN_error: false,
				name_limited_partnershipEN_error: false,
				name_limited_partnershipTH_error: false,
				name_company_limitedTH_error: false,
				name_company_limitedEN_error: false,
				name_public_companyTH_error: false,
				name_public_companyEN_error: false,
				entrepreneur_type_error: false,
				tax_id_error: false,
				tradeMark_id_error: false,
				address_id_error: false,
				alley_error: false,
				road_error: false,
				province_error: false,
				district_error: false,
				sub_district_error: false,
				zip_code_error: false,
				phone_number_error: false,
				file_corporation_certificate_error: false,
				file_vat_certificate_error: false,
				file_trademark_error: false,
			});
			this.setDefaultValue();
		} else if (name === "personality_type" && value === 2) {
			this.setState({
				show_person_form: false,
				show_incorporated_form: true,
				show_limited_partnership_form: false,
				show_company_limited_form: false,
				show_public_company_form: false,

				// Set Filed Error
				prefix_personal_nameTH_error: false,
				prefix_personal_nameEN_error: false,
				personal_nameTH_error: false,
				personal_nameEN_error: false,

				// name_juristicTH_error: false,
				// name_juristicEN_error: false,
				name_limited_partnershipEN_error: false,
				name_limited_partnershipTH_error: false,
				name_company_limitedTH_error: false,
				name_company_limitedEN_error: false,
				name_public_companyTH_error: false,
				name_public_companyEN_error: false,
				entrepreneur_type_error: false,
				tax_id_error: false,
				tradeMark_id_error: false,
				address_id_error: false,
				alley_error: false,
				road_error: false,
				province_error: false,
				district_error: false,
				sub_district_error: false,
				zip_code_error: false,
				phone_number_error: false,
				file_corporation_certificate_error: false,
				file_vat_certificate_error: false,
				file_trademark_error: false,
			});
			this.setDefaultValue();
		} else if (name === "personality_type" && value === 3) {
			this.setState({
				show_person_form: false,
				show_incorporated_form: false,
				show_limited_partnership_form: true,
				show_company_limited_form: false,
				show_public_company_form: false,

				// Set Filed Error
				personal_nameTH_error: false,
				personal_nameEN_error: false,
				name_juristicTH_error: false,
				name_juristicEN_error: false,
				// name_limited_partnershipEN_error: false,
				// name_limited_partnershipTH_error: false,
				name_company_limitedTH_error: false,
				name_company_limitedEN_error: false,
				name_public_companyTH_error: false,
				name_public_companyEN_error: false,
				entrepreneur_type_error: false,
				tax_id_error: false,
				tradeMark_id_error: false,
				address_id_error: false,
				alley_error: false,
				road_error: false,
				province_error: false,
				district_error: false,
				sub_district_error: false,
				zip_code_error: false,
				phone_number_error: false,
				file_corporation_certificate_error: false,
				file_vat_certificate_error: false,
				file_trademark_error: false,
			});
			this.setDefaultValue();
		} else if (name === "personality_type" && value === 4) {
			this.setState({
				show_person_form: false,
				show_incorporated_form: false,
				show_limited_partnership_form: false,
				show_company_limited_form: true,
				show_public_company_form: false,

				// Set Filed Error
				personal_nameTH_error: false,
				personal_nameEN_error: false,
				name_juristicTH_error: false,
				name_juristicEN_error: false,
				name_limited_partnershipEN_error: false,
				name_limited_partnershipTH_error: false,
				// name_company_limitedTH_error: false,
				// name_company_limitedEN_error: false,
				name_public_companyTH_error: false,
				name_public_companyEN_error: false,
				entrepreneur_type_error: false,
				tax_id_error: false,
				tradeMark_id_error: false,
				address_id_error: false,
				alley_error: false,
				road_error: false,
				province_error: false,
				district_error: false,
				sub_district_error: false,
				zip_code_error: false,
				phone_number_error: false,
				file_corporation_certificate_error: false,
				file_vat_certificate_error: false,
				file_trademark_error: false,
			});
			this.setDefaultValue();
		} else if (name === "personality_type" && value === 5) {
			this.setState({
				show_person_form: false,
				show_incorporated_form: false,
				show_limited_partnership_form: false,
				show_company_limited_form: false,
				show_public_company_form: true,

				// Set Filed Error
				personal_nameTH_error: false,
				personal_nameEN_error: false,
				name_juristicTH_error: false,
				name_juristicEN_error: false,
				name_limited_partnershipEN_error: false,
				name_limited_partnershipTH_error: false,
				name_company_limitedTH_error: false,
				name_company_limitedEN_error: false,
				// name_public_companyTH_error: false,
				// name_public_companyEN_error: false,
				entrepreneur_type_error: false,
				tax_id_error: false,
				tradeMark_id_error: false,
				address_id_error: false,
				alley_error: false,
				road_error: false,
				province_error: false,
				district_error: false,
				sub_district_error: false,
				zip_code_error: false,
				phone_number_error: false,
				file_corporation_certificate_error: false,
				file_vat_certificate_error: false,
				file_trademark_error: false,
			});
			this.setDefaultValue();
		}
		await this.setState({
			personality_value: value,
			personality_index: value - 1,
			personality_text: event.target.textContent,
		});
	};

	onTradeMarkChange = async (event, { name, value }) => {
		if (name === "tradeMark_type" && value === 1) {
			await this.setState({
				show_tradeMark_id: true,
				tradeMark_value: value,
				tradeMark_index: value - 1,
				tradeMark_id: "",
				file_corporation_certificate_error: false,
				file_vat_certificate_error: false,
				file_trademark_error: false,
				file_trademark: { name: "", data: [], path: "" },
			});
		} else if (name === "tradeMark_type" && value === 2) {
			await this.setState({
				show_tradeMark_id: false,
				tradeMark_value: value,
				tradeMark_index: value - 1,
				tradeMark_id: "",
				file_corporation_certificate_error: false,
				file_vat_certificate_error: false,
				file_trademark_error: false,
				file_trademark: { name: "", data: [], path: "" },
			});
		}
	};

	setStringTaxId = (id) => {
		let res = id.replace(/-/g, "");
		let resShow = "";
		this.setState({ tax_id: res });
		for (let i = 0; i < this.state.tax_id.length; i++) {
			if (i === 1 || i === 5 || i === 10 || i === 12) {
				resShow += "-" + res.charAt(i);
			} else if (i === 5) {
				resShow += "-" + res.charAt(i);
			} else if (i === 10) {
				resShow += "-" + res.charAt(i);
			} else if (i === 12) {
				resShow += "-" + res.charAt(i);
			} else {
				resShow += res.charAt(i);
			}
		}
		this.setState({
			tax_id_show: resShow,
			tax_id_error: false,
		});
	};

	onIdChange = async (e, { name, value }) => {
		switch (name) {
			case "tax_id":
				let res = value.replace(/-/g, "").replace(/\D/g, "");
				let resShow = "";
				await this.setState({ tax_id: res });
				for (let i = 0; i < this.state.tax_id.length; i++) {
					if (i === 1 || i === 5 || i === 10 || i === 12) {
						resShow += "-" + res.charAt(i);
					} else if (i === 5) {
						resShow += "-" + res.charAt(i);
					} else if (i === 10) {
						resShow += "-" + res.charAt(i);
					} else if (i === 12) {
						resShow += "-" + res.charAt(i);
					} else {
						resShow += res.charAt(i);
					}
				}
				await this.setState({
					tax_id_show: resShow,
					tax_id_error: false,
				});
				break;
			default:
				break;
		}
	};

	// Sent Field Value #################################################
	handleChange = (event, { name, value, checked }) => {
		let entrepreneurFields = this.state.entrepreneurFields;
		entrepreneurFields[event.target.name] = event.target.value;
		this.setState({
			entrepreneurFields,
		});

		//Check Validate Form Error When Data Change
		if (name === "personal_nameTH") {
			this.setState({
				personal_nameTH: value, //.replace(/[^ก-๙]+/g, '')
				personal_nameTH_error: false,
			});
		}

		if (name === "personal_nameEN") {
			this.setState({
				personal_nameEN: value.toUpperCase(), //.replace(/[^A-Za-z]+/g, '')
				personal_nameEN_error: false,
			});
		}

		if (name === "name_juristicTH") {
			this.setState({
				name_juristicTH: value, //.replace(/[^ก-๙]+/g, '')
				name_juristicTH_error: false,
			});
		}

		if (name === "name_juristicEN") {
			this.setState({
				name_juristicEN: value.toUpperCase(), //.replace(/[^A-Za-z]+/g, '')
				name_juristicEN_error: false,
			});
		}

		if (name === "name_limited_partnershipTH") {
			this.setState({
				name_limited_partnershipTH: value, //.replace(/[^ก-๙]+/g, '')
				name_limited_partnershipTH_error: false,
			});
		}
		if (name === "name_limited_partnershipEN") {
			this.setState({
				name_limited_partnershipEN: value.toUpperCase(), //.replace(/[^A-Za-z]+/g, '')
				name_limited_partnershipEN_error: false,
			});
		}

		if (name === "name_company_limitedTH") {
			this.setState({
				name_company_limitedTH: value, //.replace(/[^ก-๙]+/g, '')
				name_company_limitedTH_error: false,
			});
		}
		if (name === "name_company_limitedEN") {
			this.setState({
				name_company_limitedEN: value.toUpperCase(), //.replace(/[^A-Za-z]+/g, '')
				name_company_limitedEN_error: false,
			});
		}

		if (name === "name_public_companyTH") {
			this.setState({
				name_public_companyTH: value, //.replace(/[^ก-๙]+/g, '')
				name_public_companyTH_error: false,
			});
		}

		if (name === "name_public_companyEN") {
			this.setState({
				name_public_companyEN: value.toUpperCase(), //.replace(/[^A-Za-z]+/g, '')
				name_public_companyEN_error: false,
			});
		}

		if (name === "tax_id") {
			this.setState({
				tax_id: value,
				tax_id_error: false,
			});
		}

		if (name === "tradeMark_id") {
			this.setState({
				tradeMark_id: value.replace(/\D/g, ""),
				tradeMark_id_error: false,
			});
		}

		if (name === "address_id") {
			this.setState({
				address_id: value,
				address_id_error: false,
			});
		}

		if (name === "alley") {
			this.setState({
				alley: value,
				alley_error: false,
			});
		}

		if (name === "road") {
			this.setState({
				road: value,
				road_error: false,
			});
		}

		if (name === "province") {
			this.setState({
				province_text: value,
				province_error: false,
			});
		}

		if (name === "district") {
			this.setState({
				district_text: value,
				district_error: false,
			});
		}

		if (name === "sub_district") {
			this.setState({
				sub_district_text: value,
				sub_district_error: false,
			});
		}

		if (name === "zip_code") {
			this.setState({
				zip_code: value.replace(/\D/g, ""),
				zip_code_error: false,
			});
		}

		if (name === "phone_number") {
			this.setState({
				phone_number: value.replace(/\D/g, ""),
				phone_number_error: false,
			});
		}

		if (name === "fax_number") {
			this.setState({
				fax_number: value,
				fax_number_error: false,
			});
		}

		if (name === "website") {
			this.setState({
				website: value,
				website_error: false,
			});
		}
		if (name === "pdpa_request") {
			this.setState({
				pdpa_request: checked,
				pdpa_request_error: false,
			});
		}
	};

	checkSSNPattern = (ssn) => {
		ssn = ssn.replace(/-/g, "");
		let pattern = 0;
		for (let i = 0; i < 12; i++) pattern += parseFloat(ssn.charAt(i)) * (13 - i);
		if ((11 - (pattern % 11)) % 10 !== parseFloat(ssn.charAt(12))) {
			return false;
		} else {
			return true;
		}
	};

	authentication = (ssn) => {
		this.setState({ loading: true });
		if (this.checkSSNPattern(ssn)) {
			if (this.state.personality_value === 1) {
				axios
					.get(`${GET_DATA_PERSONAL}${ssn}`)
					.then((response) => {
						if (response.data.status === "success") {
							this.setState({
								tax_id_pass: true,
							});

							let data = response.data.value;

							if (data.houseNo === null) {
								this.setState((state) => (state.checkValue.address_id = true));
							} else {
								this.setState({ address_id: data.houseNo });
							}

							if (data.alleyDesc === null) {
								this.setState((state) => (state.checkValue.alley = true));
							} else {
								this.setState({ alley: data.alleyDesc });
							}

							if (data.roadDesc === null) {
								this.setState((state) => (state.checkValue.road = true));
							} else {
								this.setState({ road: data.roadDesc });
							}

							//set input form error
							this.setState({
								tax_id_error: false,
								address_id_error: false,
								alley_error: false,
								road_error: false,
							});
							this.setState({ authen: true });
							this.setState({ loading: false });
							Swal.fire({
								position: "center",
								type: "success",
								title: "เลขที่ประจำตัวผู้เสียภาษีอากรนี้ใช้งานได้",
								showConfirmButton: false,
								timer: 1000,
							});
						} else {
							this.setState({ loading: false });
							this.setState({ loading: false });
							Swal.fire({
								type: "error",
								title: `ไม่พบข้อมูล 
                        กรุณาตรวจสอบใหม่อีกครั้ง`,
							});
							this.setState({ tax_id_pass: false });
						}
					})
					.catch((error) => {
						// Clear value in input form
						let msg = "เกิดข้อผิดพลาดไม่สามารถเชื่อมต่อกับระบบได้";
						if (error.response.data.message === "Tax ID repeatedly") {
							msg = `เลขที่ประจำตัวผู้เสียภาษีอากรนี้
						ถูกลงทะเบียนแล้ว`;
						}

						this.setState({
							checkValue: {
								address_id: true,
								alley: true,
								road: true,
							},
						});
						this.setState({ tax_id_pass: false, authen: true, loading: false });

						Swal.fire({
							type: "error",
							title: msg,
						});
					});
			} else {
				this.setState({ authen: true });
				this.setState({ loading: true });
				axios
					.get(`${GET_DATA_JURISTIC}${ssn}`)
					.then((response) => {
						if (response.data.status === "success") {
							// let data = response.data.value;
							// this.setState({ loading: false });

							// if (data.addressInformationType[0].juristicAddressNo === null) {
							// 	this.setState((state) => (state.checkValue.address_id = true));
							// }
							// else {
							// 	this.setState({ address_id: data.addressInformationType[0].juristicAddressNo });
							// }

							// if (data.addressInformationType[0].juristicSoi === null) {
							// 	this.setState((state) => (state.checkValue.alley = true));
							// }
							// else {
							// 	this.setState({ alley: data.addressInformationType[0].juristicSoi });
							// }

							// if (data.addressInformationType[0].juristicRoad === null) {
							// 	this.setState((state) => (state.checkValue.road = true));
							// }
							// else {
							// 	this.setState({ road: data.addressInformationType[0].juristicRoad });
							// }

							// //set input form error
							// this.setState({
							// 	address_id_error: false,
							// 	alley_error: false,
							// 	road_error: false
							// });

							this.setState({ authen: true });
							this.setState({ loading: false });
							this.setState({
								tax_id_pass: true,
							});
							Swal.fire({
								position: "center",
								type: "success",
								title: "เลขที่ประจำตัวผู้เสียภาษีอากรนี้ใช้งานได้",
								showConfirmButton: false,
								timer: 1000,
							});
						} else {
							this.setState({
								tax_id_pass: false,
							});
							this.setState({ loading: false });
							this.setState({ loading: false });
							Swal.fire({
								type: "error",
								title: `ไม่พบข้อมูล 
                        กรุณาตรวจสอบใหม่อีกครั้ง`,
							});
						}
					})
					.catch((error) => {
						// Clear value in input form
						this.setState({
							tax_id_pass: false,
						});
						let msg = "เกิดข้อผิดพลาดไม่สามารถเชื่อมต่อกับระบบได้";
						if (error.response.data.message === "Tax ID repeatedly") {
							msg = `เลขที่ประจำตัวผู้เสียภาษีอากรนี้
						ถูกลงทะเบียนแล้ว`;
						}

						this.setState({
							checkValue: {
								address_id: true,
								alley: true,
								road: true,
							},
						});
						this.setState({ tax_id_pass: false, authen: true, loading: false });

						Swal.fire({
							type: "error",
							title: msg,
						});
					});
			}
		} else {
			this.setState({ tax_id_pass: false, authen: true, loading: false });
			let msg = `เลขที่ประจำตัวผู้เสียภาษีอากรนี้
						ไม่ถูกต้อง`;
			Swal.fire({
				type: "error",
				title: msg,
			});
		}
	};

	handleChangeEtc = async (event, { name, value }) => {
		switch (name) {
			case "province":
				this.setState({
					province_value: value,
					province_text: event.target.textContent,
					district_Disable: false,
				});
				this.GetDistrict(value);
				break;
			case "district":
				this.setState({
					district_value: value,
					district_text: event.target.textContent,
					sub_district_Disable: false,
				});
				this.GetSubDistrict(value);
				break;
			case "sub_district":
				this.setState({
					sub_district_value: value,
					sub_district_text: event.target.textContent,
				});
				this.GetPostCode(value);
				break;
			case "entrepreneur_type":
				await this.setState({
					entrepreneur_type: value,
					entrepreneur_type_text: event.target.textContent,
					entrepreneur_type_error: false,
				});
				// Set Entrepreneur Type Text
				let companyType_text = "";
				this.state.entrepreneur_type.map((data, index) => {
					if (this.state.entrepreneur_type.length > 1 && this.state.entrepreneur_type.length === index + 1) {
						return (companyType_text += "และ " + this.state.companyType[data - 1].text);
					} else {
						return (companyType_text += this.state.companyType[data - 1].text + " ");
					}
				});
				this.setState({ companyType_text: companyType_text });
				break;
			default:
				break;
		}
	};

	// Start Get Province Method ##############################################
	GetProvince = async () => {
		try {
			await axios.get(GET_PROVINCE).then((res) => {
				const data = res.data.value;
				let dataprovince = data.map((i) => ({
					key: i.name_th,
					text: i.name_th,
					value: i.id,
				}));
				this.setState({
					dataprovince: dataprovince,
				});
			});
		} catch (error) {
			console.log(error);
		}
	};

	GetDistrict = async (value) => {
		try {
			await axios.get(GET_DISTRICT + "?province_id=" + value).then((res) => {
				const data = res.data.value;
				let datadistrict = data.map((i) => ({
					key: i.name_th,
					text: i.name_th,
					value: i.id,
				}));
				this.setState({
					datadistrict: datadistrict,
				});
			});
		} catch (error) {}
	};

	GetSubDistrict = async (value) => {
		try {
			await axios.get(GET_SUB_DISTRICT + "?amphure_id=" + value).then((res) => {
				const data = res.data.value;
				let dataSubDistrict = data.map((i) => ({
					key: i.name_th,
					text: i.name_th,
					value: i.id,
				}));
				this.setState({
					dataSubDistrict: dataSubDistrict,
					tempSubDistrict: data,
				});
			});
		} catch (error) {}
	};

	GetPostCode = async (value) => {
		try {
			let zipcode = this.state.tempSubDistrict.find((data) => {
				return data.id === value;
			});
			this.setState({
				zip_code: zipcode.zip_code,
				zip_code_error: false,
			});
		} catch (error) {}
	};

	handleChangeAddress = (event, { name, value }) => {
		if (name === "province") {
			if (value === "") {
				this.setState({
					datadistrict: "",
					dataSubDistrict: "",
					province_value: "",
					district_value: "",
					sub_district_value: "",
					zip_code: "",
				});
				this.setState({
					district_Disable: true,
					sub_district_Disable: true,
					zipcode_disabled_state: true,
				});
			} else {
				let textValue = this.state.dataprovince.filter((data) => data.value === value);
				this.setState({
					datadistrict: "",
					dataSubDistrict: "",
					district_value: "",
					sub_district_value: "",
					zip_code: "",

					sub_district_Disable: true,
					zipcode_disabled_state: true,

					province_value: value,
					province_text: textValue[0].text,
					district_Disable: false,
					province_error: false,
				});
				this.GetDistrict(value);
			}
		} else if (name === "district") {
			if (value === "") {
				this.setState({
					dataSubDistrict: "",
					district_value: "",
					sub_district_value: "",
					zip_code: "",
				});
				this.setState({
					//Disabled State
					sub_district_Disable: true,
					zipcode_disabled_state: true,
				});
			} else {
				let textValue = this.state.datadistrict.filter((data) => data.value === value);
				this.setState({
					dataSubDistrict: "",
					sub_district_value: "",
					zip_code: "",

					zipcode_disabled_state: true,

					district_value: value,
					district_text: textValue[0].text,
					sub_district_Disable: false,
					district_error: false,
				});
				this.GetSubDistrict(value);
			}
		} else if (name === "sub_district") {
			if (value === "") {
				this.setState({
					sub_district_value: "",
					zip_code: "",
				});
				this.setState({
					//Disabled State
					zipcode_disabled_state: true,
				});
			} else {
				let textValue = this.state.dataSubDistrict.filter((data) => data.value === value);
				this.setState({
					zip_code: "",

					sub_district_value: value,
					sub_district_text: textValue[0].text,
					sub_district_error: false,
					zip_code_error: false,
					zipcode_disabled_state: false,
				});
				this.GetPostCode(value);
			}
		}
	};

	validate() {
		//เรียงจากล่างขึ้นบน react focus จากล่างขึ้นบน

		if (this.state.tradeMark_value === 1) {
			if (this.state.file_trademark.path === "") {
				this.setState({ file_trademark_error: true });
			} else {
				this.setState({ file_trademark_error: false });
			}
		} else if (this.state.tradeMark_value === 2) {
			if (this.state.file_trademark.path === "") {
				this.setState({ file_trademark_error: false });
			} else {
				this.setState({ file_trademark_error: false });
			}
		}

		if (this.state.file_vat_certificate.path === "") {
			this.setState({ file_vat_certificate_error: true });
		} else {
			this.setState({ file_vat_certificate_error: false });
		}

		if (this.state.file_corporation_certificate.path === "") {
			this.setState({ file_corporation_certificate_error: true });
		} else {
			this.setState({ file_corporation_certificate_error: false });
		}

		if (this.state.phone_number === "") {
			this.setState({ phone_number_error: true });
			this.phone_number_input.focus();
		} else {
			this.setState({ phone_number_error: false });
		}

		if (this.state.zip_code === "") {
			this.setState({ zip_code_error: true });
			this.zip_code_input.focus();
		} else {
			this.setState({ zip_code_error: false });
		}
		if (this.state.sub_district_value === "") {
			this.setState({ sub_district_error: true });
			let sub_district = document.getElementById("sub_district");
			sub_district.focus();
		} else {
			this.setState({ sub_district_error: false });
		}

		if (this.state.district_value === "") {
			this.setState({ district_error: true });
			let district = document.getElementById("district");
			district.focus();
		} else {
			this.setState({ district_error: false });
		}

		if (this.state.province_value === "") {
			this.setState({ province_error: true });
			let province = document.getElementById("province");
			province.focus();
		} else {
			this.setState({ province_error: false });
		}

		if (this.state.road === "") {
			this.setState({ road_error: true });
			this.road_input.focus();
		} else {
			this.setState({ road_error: false });
		}

		if (this.state.alley === "") {
			this.setState({ alley_error: true });
			this.alley_input.focus();
		} else {
			this.setState({ alley_error: false });
		}

		if (this.state.address_id === "") {
			this.setState({ address_id_error: true });
			this.address_id_input.focus();
		} else {
			this.setState({ address_id_error: false });
		}

		if (this.state.tradeMark_value === 1) {
			if (this.state.tradeMark_id === "") {
				this.setState({ tradeMark_id_error: true });
				this.tradeMark_id_input.focus();
			}
		} else {
			this.setState({ tradeMark_id_error: false });
		}

		if (this.state.tax_id_show === "" || this.state.tax_id_pass !== true) {
			this.setState({ tax_id_error: true });
			this.tax_id_input.focus();
		} else {
			this.setState({ tax_id_error: false });
		}

		if (this.state.entrepreneur_type.length === 0) {
			this.setState({ entrepreneur_type_error: true });
			let entrepreneur_type = document.getElementById("entrepreneur_type");
			entrepreneur_type.focus();
		} else {
			this.setState({ entrepreneur_type_error: false });
		}

		if (this.state.personality_value === 1) {
			// if( this.state.)
			if (this.state.name_prefixEN_index === undefined) {
				this.setState({ prefix_personal_nameEN_error: true });
				let prefixEN = document.getElementById("prefixEN");
				prefixEN.focus();
			} else {
				this.setState({ prefix_personal_nameEN_error: false });
			}

			if (this.state.personal_nameEN === "") {
				this.setState({ personal_nameEN_error: true });
				this.personal_nameEN_input.focus();
			} else {
				this.setState({ personal_nameEN_error: false });
			}

			if (this.state.name_prefixTH_index === undefined) {
				this.setState({ prefix_personal_nameTH_error: true });
				let prefixTH = document.getElementById("prefixTH");
				prefixTH.focus();
			} else {
				this.setState({ prefix_personal_name_error: false });
			}

			if (this.state.personal_nameTH === "") {
				this.setState({ personal_nameTH_error: true });
				this.personal_nameTH_input.focus();
			} else {
				this.setState({ personal_nameTH_error: false });
			}
		} else if (this.state.personality_value === 2) {
			if (this.state.name_juristicEN === "") {
				this.setState({ name_juristicEN_error: true });
				this.name_juristicEN_input.focus();
			} else {
				this.setState({ name_juristicEN_error: false });
			}

			if (this.state.name_juristicTH === "") {
				this.setState({ name_juristicTH_error: true });
				this.name_juristicTH_input.focus();
			} else {
				this.setState({ name_juristicTH_error: false });
			}
		} else if (this.state.personality_value === 3) {
			if (this.state.name_limited_partnershipEN === "") {
				this.setState({ name_limited_partnershipEN_error: true });
				this.name_limited_partnershipEN_input.focus();
			} else {
				this.setState({ name_limited_partnershipEN_error: false });
			}

			if (this.state.name_limited_partnershipTH === "") {
				this.setState({ name_limited_partnershipTH_error: true });
				this.name_limited_partnershipTH_input.focus();
			} else {
				this.setState({ name_limited_partnershipTH_error: false });
			}
		} else if (this.state.personality_value === 4) {
			if (this.state.name_company_limitedEN === "") {
				this.setState({ name_company_limitedEN_error: true });
				this.name_company_limitedEN_input.focus();
			} else {
				this.setState({ name_company_limitedEN_error: false });
			}

			if (this.state.name_company_limitedTH === "") {
				this.setState({ name_company_limitedTH_error: true });
				this.name_company_limitedTH_input.focus();
			} else {
				this.setState({ name_company_limitedTH_error: false });
			}
		} else if (this.state.personality_value === 5) {
			if (this.state.name_public_companyEN === "") {
				this.setState({ name_public_companyEN_error: true });
				this.name_public_companyEN_input.focus();
			} else {
				this.setState({ name_public_companyEN_error: false });
			}

			if (this.state.name_public_companyTH === "") {
				this.setState({ name_public_companyTH_error: true });
				this.name_public_companyTH_input.focus();
			} else {
				this.setState({ name_public_companyTH_error: false });
			}
		}
		if (!this.state.pdpa_request) {
			this.setState({ pdpa_request_error: true });
		} else {
			this.setState({ pdpa_request_error: false });
		}

		return true;
	}

	validateFormError = () => {
		if (this.state.prefix_personal_nameEN_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.prefix_personal_nameTH_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.personal_nameTH_error === true) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.personal_nameEN_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_juristicTH_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_juristicEN_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_limited_partnershipTH_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_limited_partnershipEN_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_company_limitedTH_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_company_limitedEN_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_public_companyTH_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.name_public_companyEN_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.entrepreneur_type_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.tax_id_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.tradeMark_id_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.address_id_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.alley_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.road_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.province_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.district_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.sub_district_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.zip_code_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.phone_number_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.file_corporation_certificate_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.file_vat_certificate_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.file_trademark_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else if (this.state.pdpa_request_error) {
			this.setState({
				formError: true,
			});
			return true;
		} else {
			this.setState({
				formError: false,
			});
			return false;
		}
	};

	async handleSubmit() {
		await this.validate();
		let formerror = await this.validateFormError();
		if (!formerror) {
			this.setState({ redirect: true });
		}
	}

	// Set State Redux
	async goNextRedux() {
		await this.setState({ productRegister: false, productImport: false });
		if (this.state.entrepreneur_type.length === 2) {
			this.setState({
				productRegister: true,
				productImport: true,
				status_manufacture: 1,
				status_import: 1,
				nextpage_path: "/productRegister",
			});
		} else if (this.state.entrepreneur_type[0] === 1) {
			this.setState({
				productRegister: true,
				status_manufacture: 1,
				status_import: 0,
				nextpage_path: "/productRegister",
			});
		} else if (this.state.entrepreneur_type[0] === 2) {
			this.setState({
				productImport: true,
				status_manufacture: 0,
				status_import: 1,
				nextpage_path: "/productImport",
			});
		}
		await this.setState({
			a: 10000000,
			entrepreneurFields: {
				name_prefixTH_value: this.state.name_prefixTH_value,
				name_prefixTH_index: this.state.name_prefixTH_index,
				name_prefixTH_text: this.state.name_prefixTH_text,
				name_prefixEN_value: this.state.name_prefixTH_value,
				name_prefixEN_index: this.state.name_prefixTH_index,
				name_prefixEN_text: this.state.name_prefixEN_text,
				personality_value: this.state.personality_value,
				personality_index: this.state.personality_index,
				personality_text: this.state.personality_text,
				personal_nameTH: this.state.personal_nameTH,
				personal_nameEN: this.state.personal_nameEN,
				name_juristicTH: this.state.name_juristicTH,
				name_juristicEN: this.state.name_juristicEN,
				name_limited_partnershipTH: this.state.name_limited_partnershipTH,
				name_limited_partnershipEN: this.state.name_limited_partnershipEN,
				name_company_limitedTH: this.state.name_company_limitedTH,
				name_company_limitedEN: this.state.name_company_limitedEN,
				name_public_companyTH: this.state.name_public_companyTH,
				name_public_companyEN: this.state.name_public_companyEN,
				entrepreneur_type: this.state.entrepreneur_type,
				entrepreneur_type_text: this.state.entrepreneur_type_text,
				status_manufacture: this.state.status_manufacture,
				status_import: this.state.status_import,
				tax_id: this.state.tax_id,
				tax_id_show: this.state.tax_id_show,
				tradeMark_id: this.state.tradeMark_id,
				address_id: this.state.address_id,
				alley: this.state.alley,
				road: this.state.road,
				province: this.state.province_text,
				district: this.state.district_text,
				sub_district: this.state.sub_district_text,
				zip_code: this.state.zip_code,
				phone_number: this.state.phone_number,
				fax_number: this.state.fax_number,
				website: this.state.website,
				file_corporation_certificate: this.state.file_corporation_certificate,
				file_vat_certificate: this.state.file_vat_certificate,
				file_trademark: this.state.file_trademark,
				companyType_text: this.state.companyType_text,
			},
			status_entrepreneur: true,
		});

		this.props.dispatch(reduxSource(this.state));
	}

	getNamePrefix = (name, type) => {
		if (type === "th") {
			// const prefix = ['นาย', 'นางสาว', 'นาง']
			for (let i = 0; i < namePrefixTH.length; i++) {
				const idxOfPrefixTH = name.indexOf(namePrefixTH[i].text);
				if (idxOfPrefixTH === 0) {
					this.setState({
						personal_nameTH: this.state.personal_nameTH.substring(
							idxOfPrefixTH +
								namePrefixTH[i].text.length +
								(this.state.personal_nameTH[namePrefixTH[i].text.length] !== " " ? 0 : 1)
						),
						name_prefixTH_value: i + 1,
						name_prefixTH_index: i,
						name_prefixTH_text: namePrefixTH[i].text,
					});
					break;
				}
			}
		}

		if (type === "en") {
			// console.log('name', this.state.personal_nameEN)
			for (let i = 0; i < namePrefixEN.length; i++) {
				const idxOfPrefixEN = name.toUpperCase().indexOf(namePrefixEN[i].text.toUpperCase());
				if (idxOfPrefixEN === 0) {
					this.setState({
						personal_nameEN: this.state.personal_nameEN.substring(
							idxOfPrefixEN +
								namePrefixEN[i].text.length +
								(this.state.personal_nameEN[namePrefixEN[i].text.length] !== " " ? 0 : 1)
						),
						name_prefixEN_value: i + 1,
						name_prefixEN_index: i,
						name_prefixEN_text: namePrefixEN[i].text,
					});
					break;
				}
			}
		}
	};

	findPersonalType = (company) => {
		if (company === "บุคคลธรรดา") {
			this.setState(
				{
					personality_index: 0,
					personality_value: 1,
					personal_nameTH: this.state.allData?.company_name_th,
					personal_nameEN: this.state.allData?.company_name_en,
					show_person_form: true,
					show_incorporated_form: false,
					show_limited_partnership_form: false,
					show_company_limited_form: false,
					show_public_company_form: false,
				},
				() => {
					this.getNamePrefix(this.state.allData?.company_name_th, "th");
					this.getNamePrefix(this.state.allData?.company_name_en, "en");
				}
			);
		} else if (company === "ห้างหุ้นส่วนสามัญนิติบุคคล") {
			this.setState({
				personality_index: 1,
				personality_value: 2,
				name_juristicTH: this.state.allData?.company_name_th,
				name_juristicEN: this.state.allData?.company_name_en,
				show_person_form: false,
				show_incorporated_form: true,
				show_limited_partnership_form: false,
				show_company_limited_form: false,
				show_public_company_form: false,
			});
		} else if (company === "ห้างหุ้นส่วนจำกัด") {
			this.setState({
				personality_index: 2,
				personality_value: 3,
				name_limited_partnershipTH: this.state.allData?.company_name_th,
				name_limited_partnershipEN: this.state.allData?.company_name_en,
				show_person_form: false,
				show_incorporated_form: false,
				show_limited_partnership_form: true,
				show_company_limited_form: false,
				show_public_company_form: false,
			});
		} else if (company === "บริษัทจำกัด") {
			this.setState({
				personality_index: 3,
				personality_value: 4,
				name_company_limitedTH: this.state.allData?.company_name_th,
				name_company_limitedEN: this.state.allData?.company_name_en,
				show_person_form: false,
				show_incorporated_form: false,
				show_limited_partnership_form: false,
				show_company_limited_form: true,
				show_public_company_form: false,
			});
		} else if (company === "บริษัทมหาชนจำกัด") {
			this.setState({
				personality_index: 4,
				personality_value: 5,
				name_public_companyTH: this.state.allData?.company_name_th,
				name_public_companyEN: this.state.allData?.company_name_en,
				show_person_form: false,
				show_incorporated_form: false,
				show_limited_partnership_form: false,
				show_company_limited_form: false,
				show_public_company_form: true,
			});
		}
	};

	componentWillMount = () => {
		this.GetProvince();
		if (this.props.stateRedux !== "") {
			this.setState(this.props.stateRedux);
		}
	};
	componentDidMount = async () => {
		let path = this.props.location.pathname.split("/");
		if (this.props.isIndustry) {
			await this.setState({ loading: true });

			await this.getListDataRegisterCompany();
			this.setStringTaxId(this.state.allData?.tax_id);

			// ----------------- Entrepreneur Type -----------------
			// await this.setState({
			//   entrepreneur_type: value,
			//   entrepreneur_type_text: event.target.textContent,
			//   entrepreneur_type_error: false,
			// });
			// Set Entrepreneur Type Text
			let companyType_text = "";
			this.state.entrepreneur_type.map((data, index) => {
				if (this.state.entrepreneur_type.length > 1 && this.state.entrepreneur_type.length === index + 1) {
					return (companyType_text += "และ " + this.state.companyType[data - 1].text);
				} else {
					return (companyType_text += this.state.companyType[data - 1].text + " ");
				}
			});
			this.setState({ companyType_text: companyType_text });

			this.setState({
				personality_type: this.findPersonalType(this.state.allData?.company_type_id),
				personal_nameTH: this.state.allData?.manager_name,
				address_id: this.state.allData?.house_number,
				road: this.state.allData?.road,
				phone_number: this.state.allData?.contact_tel,
				fax_number: this.state.allData?.fax,
				website: this.state.allData?.website,
				sub_district_text: this.state.allData?.sub_district,
			});

			// let province = this.state.dataprovince.map((data) => data.key).indexOf(this.state.allData?.province);

			// this.setState({
			//   province_value: this.state.dataprovince[province].value,
			//   province_text: this.state.allData?.province,
			//   district_Disable: false,
			//   province_error: false,
			// }, () => {
			//   console.log(this.state)
			// });
		} else {
			let companyId = path[2];
			if (path[2] !== undefined && path[2] !== "") {
				this.setState({ loading: true });
				localStorage.setItem("company_id_edit", path[2]);
				axios.get(`${CHECK_REGIS_EDITING_STATUS_TEMP}${companyId}`).then(({ data: { has_temp } }) => {
					// console.log('doesTemp',has_temp)
					// GET_REGIS_EDITING_DATA_COMPANY_ENTREPRENEUR
					axios
						.post(
							`${has_temp ? GET_REGIS_EDITING_DATA_COMPANY_ENTREPRENEUR : GET_DATA_COMPANY}${companyId}`
						)
						.then((response) => {
							this.setState({ edit_form: true });
							let data = response.data.value;
							let company = data.Company[0];
							if (company.status === "แก้ไข") {
								this.setState({ company_id_edit_form: company.companies_id });
								if (company.type_companies_id === "บุคคลธรรดา") {
									this.setState(
										{
											personality_index: 0,
											personality_value: 1,
											personal_nameTH: data.Company[0].name_th,
											personal_nameEN: data.Company[0].name_en,
											show_person_form: true,
											show_incorporated_form: false,
											show_limited_partnership_form: false,
											show_company_limited_form: false,
											show_public_company_form: false,
										},
										() => {
											this.getNamePrefix(data.Company[0].name_th, "th");
											this.getNamePrefix(data.Company[0].name_en, "en");
										}
									);
								} else if (company.type_companies_id === "ห้างหุ้นส่วนสามัญนิติบุคคล") {
									this.setState({
										personality_index: 1,
										personality_value: 2,
										name_juristicTH: data.Company[0].name_th,
										name_juristicEN: data.Company[0].name_en,
										show_person_form: false,
										show_incorporated_form: true,
										show_limited_partnership_form: false,
										show_company_limited_form: false,
										show_public_company_form: false,
									});
								} else if (company.type_companies_id === "ห้างหุ้นส่วนจำกัด") {
									this.setState({
										personality_index: 2,
										personality_value: 3,
										name_limited_partnershipTH: data.Company[0].name_th,
										name_limited_partnershipEN: data.Company[0].name_en,
										show_person_form: false,
										show_incorporated_form: false,
										show_limited_partnership_form: true,
										show_company_limited_form: false,
										show_public_company_form: false,
									});
								} else if (company.type_companies_id === "บริษัทจำกัด") {
									this.setState({
										personality_index: 3,
										personality_value: 4,
										name_company_limitedTH: company.name_th,
										name_company_limitedEN: company.name_en,
										show_person_form: false,
										show_incorporated_form: false,
										show_limited_partnership_form: false,
										show_company_limited_form: true,
										show_public_company_form: false,
									});
								} else if (company.type_companies_id === "บริษัทมหาชนจำกัด") {
									this.setState({
										personality_index: 4,
										personality_value: 5,
										name_public_companyTH: company.name_th,
										name_public_companyEN: company.name_en,
										show_person_form: false,
										show_incorporated_form: false,
										show_limited_partnership_form: false,
										show_company_limited_form: false,
										show_public_company_form: true,
									});
								}

								let province = this.state.dataprovince
									.map((data) => data.key)
									.indexOf(company.province);
								this.setState({
									province_value: this.state.dataprovince[province].value,
									province_text: company.province,
									district_Disable: false,
									province_error: false,
								});

								this.setState({
									companyType: [
										{ key: 1, text: "ผู้ผลิต", value: 1 },
										{ key: 2, text: "ผู้นำเข้า", value: 2 },
									],
								});
								let entrepreneur_type = [];
								if (company.manufacture.toString() === "1") {
									entrepreneur_type.push(1);
								}
								if (company.import.toString() === "1") {
									entrepreneur_type.push(2);
								}
								let companyType_text = "";
								entrepreneur_type.map((data, index) => {
									if (entrepreneur_type.length > 1 && entrepreneur_type.length === index + 1) {
										return (companyType_text += "และ " + this.state.companyType[data - 1].text);
									} else {
										return (companyType_text += this.state.companyType[data - 1].text + " ");
									}
								});

								this.setState({
									companyType_text: companyType_text,
									entrepreneur_type: entrepreneur_type,
									tax_id_pass: true,
								});

								this.GetDistrict(this.state.dataprovince[province].value).then(() => {
									let district = this.state.datadistrict
										.map((data) => data.key)
										.indexOf(company.district);

									this.setState({
										district_value: this.state.datadistrict[district].value,
										district_text: company.district,
										sub_district_Disable: false,
										district_error: false,
									});
									this.GetSubDistrict(this.state.datadistrict[district].value).then(() => {
										let subdistrict = this.state.dataSubDistrict
											.map((data) => data.key)
											.indexOf(company.subdistrict);
										this.setState({
											sub_district_value: this.state.dataSubDistrict[subdistrict].value,
											sub_district_text: company.subdistrict,
											sub_district_error: false,
											zip_code_error: false,
											zipcode_disabled_state: false,
										});
									});
								});

								this.setStringTaxId(company.tax_id);
								if (company.trademark === "") {
									this.setState({
										tradeMark_index: 1,
										show_tradeMark_id: false,
										tradeMark_value: 2,
									});
								} else {
									let nameFileTrademarkdoc = company.trademarkdoc_name;
									axios
										.get(`${HOST_PATH_FILE}${company.trademarkdoc}`, {
											responseType: "arraybuffer",
										})
										.then((response) => {
											let data = response.data;
											let blobFile = new Blob([data]);
											let trademarkdoc = new File([blobFile], nameFileTrademarkdoc, {
												type: "application/pdf",
											});
											let file_trademark = {
												name: nameFileTrademarkdoc,
												data: trademarkdoc,
												path: URL.createObjectURL(trademarkdoc),
											};
											this.setState({ file_trademark: file_trademark });
										});
								}
								this.state.entrepreneurFields.address_id = company.address;
								this.state.entrepreneurFields.alley = company.soi;
								this.state.entrepreneurFields.road = company.road;
								this.setState({
									tradeMark_id: company.trademark,
									address_id: company.address,
									alley: company.soi,
									road: company.road,
									zip_code: company.zipcode,
									phone_number: company.phone,
									fax_number: company.fax,
									website: company.website,
								});

								let nameFile = company.certificatedoc_name;
								axios
									.get(`${HOST_PATH_FILE}${company.certificatedoc}`, {
										responseType: "arraybuffer",
									})
									.then((response) => {
										let data = response.data;
										let blobFile = new Blob([data]);
										let certificatedoc = new File([blobFile], nameFile, {
											type: "application/pdf",
										});
										let file_corporation_certificate = {
											name: nameFile,
											data: certificatedoc,
											path: URL.createObjectURL(certificatedoc),
										};
										this.setState({
											file_corporation_certificate: file_corporation_certificate,
										});
									});

								let nameFileTaxdoc = company.taxdoc_name;
								axios
									.get(`${HOST_PATH_FILE}${company.taxdoc}`, {
										responseType: "arraybuffer",
									})
									.then((response) => {
										let data = response.data;
										let blobFile = new Blob([data]);
										let taxdoc = new File([blobFile], nameFileTaxdoc, {
											type: "application/pdf",
										});
										let file_vat_certificate = {
											name: nameFileTaxdoc,
											data: taxdoc,
											path: URL.createObjectURL(taxdoc),
										};
										this.setState({ file_vat_certificate: file_vat_certificate });
									});

								let indexFactoryType1 = 0;
								data.Factories.map((factory, index) => {
									if (factory.type_factories_id === "โรงงานผลิต") {
										indexFactoryType1++;
										if (index > 0) {
											this.state.factory_form_obj.push({
												factory_name_th: "",
												factory_name_en: "",
												factory_license_number: "",
												factory_certificate: "",
												factory_expire_certificate: "",
												factory_from: "",
												factory_under: "",
												factory_location: "",
												factory_number: "",
												factory_alley: "",
												factory_road: "",
												factory_province: "",
												factory_district: "",
												factory_zone: "",
												factory_zip_code: "",
												factory_tel: "",
												factory_license_file: { name: "", data: [], path: "" },
												factory_certificate_file: { name: "", data: [], path: "" },
												factory_product: [
													{
														product_name: "",
														product_structure: "",
														product_power_output: "",
														product_trade_name: [
															{
																trade_name: "",
															},
														],
													},
												],
												isIndustrial: "out",
												posIndustrial: "",
											});
											this.state.index_factory_location_options.push(0);
										}
										if (factory.factories_area_id.toString() !== "0") {
											this.state.factory_form_obj[index].isIndustrial = "in";
											this.state.factory_form_obj[index].factory_province = factory.province;
											this.state.factory_form_obj[index].factory_district = factory.district;
											this.state.factory_form_obj[index].factory_zone = factory.subdistrict;
											this.state.index_factory_location_options[index] =
												factory.factories_area_id - 1;
										} else {
											this.GetDataAddressByType(
												index,
												factory.type_factories_id,
												factory.province,
												factory.district,
												factory.subdistrict
											);
										}
										this.state.factory_form_obj[index].factories_id = factory.id;
										this.state.factory_form_obj[index].factory_name_th = factory.name_th;
										this.state.factory_form_obj[index].factory_name_en = factory.name_en;
										this.state.factory_form_obj[index].factory_license_number = factory.license_no;
										this.state.factory_form_obj[index].factory_certificate = factory.standard_qc_id;
										this.state.factory_form_obj[index].factory_expire_certificate =
											factory.standard_qc_expire;
										this.state.factory_form_obj[index].factory_from = factory.standard_from;
										this.state.factory_form_obj[index].factory_under = factory.standard_department;
										this.state.factory_form_obj[index].posIndustrial = factory.factories_area_id;
										this.state.factory_form_obj[index].factory_number = factory.address;
										this.state.factory_form_obj[index].factory_alley = factory.soi;
										this.state.factory_form_obj[index].factory_road = factory.road;

										this.state.factory_form_obj[index].factory_zip_code = factory.zipcode;

										this.state.factory_form_obj[index].factory_tel = factory.phone;
										// TODO: Loop product
										factory.Products.map((product, indexProduct) => {
											if (indexProduct === 0) {
												this.state.factory_form_obj[index].factory_product[
													indexProduct
												].product_id = product.id;
												this.state.factory_form_obj[index].factory_product[
													indexProduct
												].product_name = product.type_product_id;

												this.state.factory_form_obj[index].factory_product[
													indexProduct
												].product_structure = product.type_struct_id;

												this.state.factory_form_obj[index].factory_product[
													indexProduct
												].product_power_output = product.product_amount;

												product.Brands.map((brand, indexBrand) => {
													if (indexBrand === 0) {
														this.state.factory_form_obj[index].factory_product[
															indexProduct
														].product_trade_name[indexBrand].brand_id = brand.id;
														this.state.factory_form_obj[index].factory_product[
															indexProduct
														].product_trade_name[indexBrand].trade_name = brand.name;
													} else {
														this.setState((state) =>
															state.factory_form_obj[index].factory_product[
																indexProduct
															].product_trade_name.push({
																brand_id: brand.id,
																trade_name: brand.name,
															})
														);
													}
													return null;
												});
											} else {
												this.setState((state) =>
													state.factory_form_obj[index].factory_product.push({
														product_id: product.id,
														product_name: product.type_product_id,
														product_structure: product.type_struct_id,
														product_power_output: product.product_amount,
														product_trade_name: [],
													})
												);
												product.Brands.map((brand) => {
													return this.setState((state) =>
														state.factory_form_obj[index].factory_product[
															indexProduct
														].product_trade_name.push({
															brand_id: brand.id,
															trade_name: brand.name,
														})
													);
												});
											}
											return null;
										});

										// TODO: set File from axios
										let nameFileQc_licensec = factory.qc_license_name;
										axios
											.get(`${HOST_PATH_FILE}${factory.qc_license}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let qc_license = new File([blobFile], nameFileQc_licensec, {
													type: "application/pdf",
												});
												let factory_certificate_file = {
													name: nameFileQc_licensec,
													data: qc_license,
													path: URL.createObjectURL(qc_license),
												};
												this.setState(
													(state) =>
														(state.factory_form_obj[index].factory_certificate_file =
															factory_certificate_file)
												);
											});

										let nameFileFactory_license = factory.factory_license_name;
										axios
											.get(`${HOST_PATH_FILE}${factory.factory_license}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let factory_license = new File([blobFile], nameFileFactory_license, {
													type: "application/pdf",
												});
												let factory_license_file = {
													name: nameFileFactory_license,
													data: factory_license,
													path: URL.createObjectURL(factory_license),
												};
												this.setState(
													(state) =>
														(state.factory_form_obj[index].factory_license_file =
															factory_license_file)
												);
											});
									} else {
										// TODO: SET PROVINCE
										if (index - indexFactoryType1 > 0) {
											this.state.formProductImport.push({
												factory: {
													factories_id: "",
													nameFactory: "",
													certificate: "",
													expire_certificate: "",
													from: "",
													under: "",
													location: "",
													country: "",
													file_doc: "",
												},
												productImport: [
													{
														product_id: "",
														productType: "",
														productStructure: "",
														importCapacity: "",
														productName: [
															{
																brand_id: "",
																name: "",
															},
														],
													},
												],
												exportPerson: [
													{
														export_person_id: "",
														exportPersonName: "",
														exportPersonCountry: "",
													},
												],
												warehouse: [
													{
														factory_import_id: "",
														address_id: "",
														alley: "",
														road: "",
														sub_district: "",
														district: "",
														province: "",
														zip_code: "",
													},
												],
											});

											this.state.optionAddressProductImport.push({
												dataprovince: [],
												datadistrict: [],
												dataSubDistrict: [],
											});
										}
										this.state.formProductImport[index - indexFactoryType1].factory.factories_id =
											factory.id;
										this.state.formProductImport[index - indexFactoryType1].factory.nameFactory =
											factory.name_th;
										this.state.formProductImport[index - indexFactoryType1].factory.certificate =
											factory.standard_qc_id;
										this.state.formProductImport[
											index - indexFactoryType1
										].factory.expire_certificate = factory.standard_qc_expire;
										this.state.formProductImport[index - indexFactoryType1].factory.from =
											factory.standard_from;
										this.state.formProductImport[index - indexFactoryType1].factory.under =
											factory.standard_department;
										this.state.formProductImport[index - indexFactoryType1].factory.location =
											factory.text_address;
										this.state.formProductImport[index - indexFactoryType1].factory.country =
											factory.country_id;
										// TODO: set file
										let nameFileQC_license = factory.qc_license_name;
										axios
											.get(`${HOST_PATH_FILE}${factory.qc_license}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let qc_license = new File([blobFile], nameFileQC_license, {
													type: "application/pdf",
												});
												this.state.formProductImport[
													index - indexFactoryType1
												].factory.file_doc = qc_license;
											});

										factory.Products.map((product, indexProduct) => {
											if (indexProduct === 0) {
												this.state.formProductImport[index - indexFactoryType1].productImport[
													indexProduct
												].product_id = product.id;

												this.state.formProductImport[index - indexFactoryType1].productImport[
													indexProduct
												].productType = product.type_product_id;

												this.state.formProductImport[index - indexFactoryType1].productImport[
													indexProduct
												].productStructure = product.type_struct_id;

												this.state.formProductImport[index - indexFactoryType1].productImport[
													indexProduct
												].importCapacity = product.product_amount;

												product.Brands.map((brand, indexBrand) => {
													if (indexBrand === 0) {
														this.state.formProductImport[
															index - indexFactoryType1
														].productImport[indexProduct].productName[indexBrand].brand_id =
															brand.id;
														this.state.formProductImport[
															index - indexFactoryType1
														].productImport[indexProduct].productName[indexBrand].name =
															brand.name;
													} else {
														this.setState((state) =>
															state.formProductImport[
																index - indexFactoryType1
															].productImport[indexProduct].productName.push({
																brand_id: brand.id,
																name: brand.name,
															})
														);
													}
													return null;
												});
											} else {
												this.setState((state) =>
													state.formProductImport[
														index - indexFactoryType1
													].productImport.push({
														product_id: product.id,
														productType: product.type_product_id,
														productStructure: product.type_struct_id,
														importCapacity: product.product_amount,
														productName: [],
													})
												);

												this.state.errorsFormProductImport.productImport.push({
													productType: false,
													productStructure: false,
													importCapacity: false,
													productName: [],
												});

												product.Brands.map((brand) => {
													this.setState((state) =>
														state.formProductImport[
															index - indexFactoryType1
														].productImport[indexProduct].productName.push({
															brand_id: brand.id,
															name: brand.name,
														})
													);

													this.state.errorsFormProductImport.productImport[
														indexProduct
													].productName.push(false);
												});
											}
											return null;
										});
										factory.ExportPersons.map((exportPerson, indexExportPerson) => {
											if (indexExportPerson === 0) {
												this.state.formProductImport[index - indexFactoryType1].exportPerson[
													indexExportPerson
												].export_person_id = exportPerson.id;
												this.state.formProductImport[index - indexFactoryType1].exportPerson[
													indexExportPerson
												].exportPersonName = exportPerson.name;
												this.state.formProductImport[index - indexFactoryType1].exportPerson[
													indexExportPerson
												].exportPersonCountry = exportPerson.country_id;
											} else {
												this.state.errorsFormProductImport.exportPerson.push({
													exportPersonName: false,
													exportPersonCountry: false,
												});
												this.state.formProductImport[
													index - indexFactoryType1
												].exportPerson.push({
													export_person_id: exportPerson.id,
													exportPersonName: exportPerson.name,
													exportPersonCountry: exportPerson.country_id,
												});
											}
										});
										if (factory.FactoriesImportTh !== null) {
											factory.FactoriesImportTh.map((warehouse, indexWarehouse) => {
												if (indexWarehouse === 0) {
													this.state.formProductImport[index - indexFactoryType1].warehouse[
														indexWarehouse
													].factory_import_id = warehouse.id;
													this.state.formProductImport[index - indexFactoryType1].warehouse[
														indexWarehouse
													].address_id = warehouse.address;
													this.state.formProductImport[index - indexFactoryType1].warehouse[
														indexWarehouse
													].alley = warehouse.soi;
													this.state.formProductImport[index - indexFactoryType1].warehouse[
														indexWarehouse
													].road = warehouse.road;
													this.state.formProductImport[index - indexFactoryType1].warehouse[
														indexWarehouse
													].zip_code = warehouse.zipcode;
												} else {
													this.state.optionAddressProductImport[
														index - indexFactoryType1
													].dataprovince.push([]);
													this.state.optionAddressProductImport[
														index - indexFactoryType1
													].datadistrict.push([]);
													this.state.optionAddressProductImport[
														index - indexFactoryType1
													].dataSubDistrict.push([]);
													this.state.formProductImport[
														index - indexFactoryType1
													].warehouse.push({
														factory_import_id: warehouse.id,
														address_id: warehouse.address,
														alley: warehouse.soi,
														road: warehouse.road,
														sub_district: warehouse.subdistrict,
														district: warehouse.district,
														province: warehouse.province,
														zip_code: warehouse.zipcode,
													});
													this.state.errorsFormProductImport.warehouse.push({
														address_id: false,
														alley: false,
														road: false,
														sub_district: false,
														district: false,
														province: false,
														zip_code: false,
													});
												}
												this.GetDataAddressWarehouse(
													index - indexFactoryType1,
													indexWarehouse,
													warehouse.province,
													warehouse.district,
													warehouse.subdistrict
												);
											});
										}
									}
									return;
								});

								data.Persons.map((person, indexPerson) => {
									this.GetDataAddressByType(
										indexPerson,
										person.type_person,
										person.province,
										person.district,
										person.subdistrict
									);
									if (indexPerson === 0) {
										if (person.nationality === "ไทย") {
											this.state.personAuthorized[indexPerson].nationality = 1;
											this.state.personAuthorized[indexPerson].idNumber = person.tax_id;
										} else {
											this.state.personAuthorized[indexPerson].nationality = 2;
											this.state.personAuthorized[indexPerson].idNumber = person.passport;
										}
										this.state.personAuthorized[indexPerson].person_id = person.id;
										this.state.personAuthorized[indexPerson].nameTH = person.name_th;
										this.state.personAuthorized[indexPerson].nameEN = person.name_en;
										this.state.personAuthorized[indexPerson].address = person.foreigner_address;
										this.state.personAuthorized[indexPerson].address_id = person.address;
										this.state.personAuthorized[indexPerson].alley = person.soi;
										this.state.personAuthorized[indexPerson].road = person.road;
										this.state.personAuthorized[indexPerson].zip_code = person.zipcode;
										this.state.personAuthorized[indexPerson].phoneNumber = person.phone;
										this.state.personAuthorized[indexPerson].email = person.email;
										// TODO: axios file
										let nameFilePerson_certificate = person.person_certificate_name;
										axios
											.get(`${HOST_PATH_FILE}${person.person_certificate}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let person_certificate = new File(
													[blobFile],
													nameFilePerson_certificate,
													{
														type: "application/pdf",
													}
												);
												this.state.personAuthorized[indexPerson].file = person_certificate;
											});
									} else if (person.type_person === "ผู้รับมอบอำนาจ") {
										if (person.nationality === "ไทย") {
											this.state.nationality_index = 0;
											this.state.nationality_value = 1;
										} else {
											this.state.nationality_index = 1;
											this.state.nationality_value = 2;
										}
										this.state.delegateFields_id = person.id;
										this.state.nationality_text = person.nationality;
										this.state.addDelegate = true;
										this.state.delegate_id_number = person.tax_id;
										this.state.delegate_passport_number = person.passport;
										this.state.delegate_nameTH = person.name_th;
										this.state.delegate_nameEN = person.name_en;
										this.state.foreigner_address = person.foreigner_address;
										this.state.delegate_address_id = person.address;
										this.state.delegate_alley = person.soi;
										this.state.delegate_road = person.road;
										// TODO: SET PROVINCE
										this.state.delegate_sub_district_text = person.subdistrict;
										this.state.delegate_district_text = person.district;
										this.state.delegate_province_text = person.province;

										this.state.delegate_zip_code = person.zipcode;
										this.state.delegate_phone_number = person.phone;
										this.state.delegate_email = person.email;
										// TODO: axios file
										let nameFile_idcard = person.person_certificate_name;
										axios
											.get(`${HOST_PATH_FILE}${person.person_certificate}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let file_idcard = new File([blobFile], nameFile_idcard, {
													type: "application/pdf",
												});
												this.state.file_idcard = {
													name: nameFile_idcard,
													data: file_idcard,
													path: URL.createObjectURL(file_idcard),
												};
											});
										let nameFile_passport = person.person_certificate_name;
										axios
											.get(`${HOST_PATH_FILE}${person.person_certificate}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let file_passport = new File([blobFile], nameFile_passport, {
													type: "application/pdf",
												});
												this.state.file_passport = {
													name: nameFile_passport,
													data: file_passport,
													path: URL.createObjectURL(file_passport),
												};
											});

										let nameFile_poa = person.power_of_attorney_name;
										axios
											.get(`${HOST_PATH_FILE}${person.power_of_attorney}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let file_poa = new File([blobFile], nameFile_poa, {
													type: "application/pdf",
												});
												this.state.file_poa = {
													name: nameFile_poa,
													data: file_poa,
													path: URL.createObjectURL(file_poa),
												};
											});
									} else {
										let nationality;
										let idNumber;
										if (person.nationality === "ไทย") {
											nationality = 1;
											idNumber = person.tax_id;
										} else {
											nationality = 2;
											idNumber = person.passport;
										}
										this.state.personAuthorized.push({
											person_id: person.id,
											nationality: nationality,
											idNumber: idNumber,
											nameTH: person.name_th,
											nameEN: person.name_en,
											address: person.foreigner_address,
											address_id: person.address,
											alley: person.soi,
											road: person.road,
											sub_district: person.subdistrict,
											district: person.district,
											province: person.province,
											zip_code: person.zipcode,
											phoneNumber: person.phone,
											email: person.email,
											file: person.person_certificate,
											type: 1,
										});
										this.state.errorPersonAuthorized.push({
											nationality: false,
											idNumber: false,
											nameTH: false,
											nameEN: false,
											address: false,
											address_id: false,
											alley: false,
											road: false,
											sub_district: false,
											district: false,
											province: false,
											zip_code: false,
											phoneNumber: false,
											email: false,
											file: false,
										});
										// TODO: axios file
										let nameFilePerson_certificate = person.person_certificate_name;
										axios
											.get(`${HOST_PATH_FILE}${person.person_certificate}`, {
												responseType: "arraybuffer",
											})
											.then((response) => {
												let data = response.data;
												let blobFile = new Blob([data]);
												let person_certificate = new File(
													[blobFile],
													nameFilePerson_certificate,
													{
														type: "application/pdf",
													}
												);
												this.state.personAuthorized[indexPerson].file = person_certificate;
											});
									}
								}).then(() => {
									this.setState({ loading: false });
								});
							} else {
								// this.setState({ loading: true });
								this.props.history.push("/registerEditPending");
							}
						})
						.catch((error) => {
							this.setState({ loading: false });
						});
				});
			}
		}

		window.scrollTo(0, 0);
	};

	handleOpenReqeustPDPA = () => {
		this.setState({
			openModalsPDPA: true,
		});
	};

	handleSubmitReqeustPDPA = () => {
		this.setState({
			pdpa_request: true,
			openModalsPDPA: false,
		});
	};

	getListDataRegisterCompany = async () => {
		const search = await this.props.location.search;
		const queryParams = new URLSearchParams(search);
		let juristic_id = queryParams.get("juristic_id");
		let access_token = queryParams.get("access_token");
		//register-industry?access_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzUzNDY0MzAsImlhdCI6MTY3NDcxNzY0MywidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImVjb190eXBlIjoiY2FyIiwiaUluZHVzdHJ5X3VzZXJfaWQiOiIyMjYzNzYzNTc2ODMwIiwianVyaXN0aWNfaWQiOlsiMDEwNTUzMzA3MjI5MSJdfQ.P3d-xJ_RZMspxDo0nZtCE7Ib_gO33AHuNXsWFCcPvsb47Tjl-zynd9Lr-L3hpj65briGNjeU_bB70z4LdIIRGbjTbHVGgXOcafDKifesHVhGVRc8-MtzYMV9DLG7hJFQ5an_6TheK65u-JEnPLdpEU6egJ_9I4Joap7SjthnRYA&juristic_id=0105533072291

		let response = await Axios({
			url: `${INDUSTRY_COMPANY_REGISTER}${juristic_id}`,
			method: "GET",
			headers: {
				// Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzQxMzY4MzAsImlhdCI6MTY3NDEwMjM3MSwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImVjb190eXBlIjoiY2FyIiwiaUluZHVzdHJ5X3VzZXJfaWQiOiJpbmR1c3RyeV91c2VyX2lkX3Rlc3QiLCJqdXJpc3RpY19pZCI6WyIwMTA1NTQwMDg2ODgwIiwiMDEwNTUwOTAwMTIyMyIsIjAxMDU1MTQwMDM0OTIiLCIwMTA1NTMzMDcyMjkxIl19.PANVFz3LIavVUYsqbroFTCzrz5fxYRkpl4g3C0tFA2ozDu7fls6tKzB5nzsdYBM6lA0iNJ1-9KGnwRs1dt-AnH128HvbnGe2luT4Tm3n9MFvdwM5eouIxw0xqBvRrFrKbznYUMSiaX8ll0_a3VNAofD6dB6yC81R3KvZ35m4sO8`,
				Authorization: `Bearer ${access_token}`,
			},
		});
		const data = response?.data?.data;
		this.setState({ allData: data, loading: false });
		console.log(data);
	};

	GetDataAddressWarehouse = (index, indexWarehouse, valueProvince, valueDistrict, valueSubDistrict) => {
		axios
			.get(GET_PROVINCE)
			.then((res) => {
				const data = res.data.value;
				let dataprovince = data.map((i) => ({
					key: i.id,
					text: i.name_th,
					value: i.name_th,
				}));
				this.setState(
					(state) => (state.optionAddressProductImport[index].dataprovince[indexWarehouse] = dataprovince)
				);
			})
			.then(() => {
				this.setState(
					(state) => (state.formProductImport[index].warehouse[indexWarehouse].province = valueProvince)
				);
				let position = this.state.optionAddressProductImport[index].dataprovince[indexWarehouse]
					.map((data) => data.value)
					.indexOf(valueProvince);
				this.GetDistrictWarehouse(
					index,
					indexWarehouse,
					this.state.optionAddressProductImport[index].dataprovince[indexWarehouse][position].key,
					valueDistrict,
					valueSubDistrict
				);
			})
			.catch((error) => {});
	};

	GetDataAddressByType = (index, type, valueProvince, valueDistrict, valueSubDistrict) => {
		if (type === "โรงงานผลิต") {
			axios
				.get(GET_PROVINCE)
				.then((res) => {
					const data = res.data.value;
					let dataprovince = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.setState((state) => (state.optionAddressProductRegister.dataprovince[index] = dataprovince));
				})
				.then(() => {
					this.setState((state) => (state.factory_form_obj[index].factory_province = valueProvince));
					let position = this.state.optionAddressProductRegister.dataprovince[index]
						.map((data) => data.value)
						.indexOf(valueProvince);
					this.GetDistrictByType(
						index,
						type,
						this.state.optionAddressProductRegister.dataprovince[index][position].key,
						valueDistrict,
						valueSubDistrict
					);
				})
				.catch((error) => {});
		} else if (type === "โรงงานนำเข้า") {
			axios
				.get(GET_PROVINCE)
				.then((res) => {
					const data = res.data.value;
					let dataprovince = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.setState((state) => (state.optionAddressProductImport.dataprovince[index] = dataprovince));
				})
				.then(() => {
					this.setState((state) => (state.formProductImport[index].warehouse.province = valueProvince));
					let position = this.state.optionAddressProductImport.dataprovince[index]
						.map((data) => data.value)
						.indexOf(valueProvince);
					this.GetDistrictByType(
						index,
						type,
						this.state.optionAddressProductImport.dataprovince[index][position].key,
						valueDistrict,
						valueSubDistrict
					);
				})
				.catch((error) => {});
		} else if (type === "ผู้ลงนาม") {
			axios
				.get(GET_PROVINCE)
				.then((res) => {
					const data = res.data.value;
					let dataprovince = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.state.optionAddressDropdown.dataprovince[index] = dataprovince;
					this.setState({});
				})
				.then(() => {
					this.state.personAuthorized[index].province = valueProvince;
					let position = this.state.optionAddressDropdown.dataprovince[index]
						.map((data) => data.value)
						.indexOf(valueProvince);
					this.GetDistrictByType(
						index,
						type,
						this.state.optionAddressDropdown.dataprovince[index][position].key,
						valueDistrict,
						valueSubDistrict
					);
				})
				.catch((error) => {});
		} else if (type === "ผู้รับมอบอำนาจ") {
			axios
				.get(GET_PROVINCE)
				.then((res) => {
					let data = res.data.value;
					let dataprovince = data.map((i) => ({
						key: i.name_th,
						text: i.name_th,
						value: i.id,
					}));
					this.state.delegate_dataprovice = dataprovince;
					this.setState({});
				})
				.then(() => {
					let position = this.state.delegate_dataprovice.map((data) => data.key).indexOf(valueProvince);
					this.setState({
						delegate_province: this.state.delegate_dataprovice[position].value,
					});
					this.GetDistrictByType(
						index,
						type,
						this.state.delegate_dataprovice[position].value,
						valueDistrict,
						valueSubDistrict
					);
				})
				.catch((error) => {});
		}
	};

	GetDistrictWarehouse = (index, indexWarehouse, key, valueDistrict, valueSubDistrict) => {
		axios
			.get(GET_DISTRICT + "?province_id=" + key)
			.then((res) => {
				const data = res.data.value;
				let datadistrict = data.map((i) => ({
					key: i.id,
					text: i.name_th,
					value: i.name_th,
				}));
				this.state.optionAddressProductImport[index].datadistrict[indexWarehouse] = datadistrict;
				this.setState({});
			})
			.then(() => {
				this.state.formProductImport[index].warehouse[indexWarehouse].district = valueDistrict;
				let position = this.state.optionAddressProductImport[index].datadistrict[indexWarehouse]
					.map((data) => data.value)
					.indexOf(valueDistrict);
				this.GetSubDistrictWarehouse(
					index,
					indexWarehouse,
					this.state.optionAddressProductImport[index].datadistrict[indexWarehouse][position].key,
					valueSubDistrict
				);
			});
	};
	GetDistrictByType = (index, type, key, valueDistrict, valueSubDistrict) => {
		if (type === "โรงงานผลิต") {
			axios
				.get(GET_DISTRICT + "?province_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let datadistrict = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.state.optionAddressProductRegister.datadistrict[index] = datadistrict;
					this.setState({});
				})
				.then(() => {
					this.state.factory_form_obj[index].factory_district = valueDistrict;
					let position = this.state.optionAddressProductRegister.datadistrict[index]
						.map((data) => data.value)
						.indexOf(valueDistrict);
					this.GetSubDistrictByType(
						index,
						type,
						this.state.optionAddressProductRegister.datadistrict[index][position].key,
						valueSubDistrict
					);
				});
		} else if (type === "โรงงานนำเข้า") {
			axios
				.get(GET_DISTRICT + "?province_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let datadistrict = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.state.optionAddressProductImport.datadistrict[index] = datadistrict;
					this.setState({});
				})
				.then(() => {
					this.state.formProductImport[index].warehouse.district = valueDistrict;
					let position = this.state.optionAddressProductImport.datadistrict[index]
						.map((data) => data.value)
						.indexOf(valueDistrict);
					this.GetSubDistrictByType(
						index,
						type,
						this.state.optionAddressProductImport.datadistrict[index][position].key,
						valueSubDistrict
					);
				});
		} else if (type === "ผู้ลงนาม") {
			axios
				.get(GET_DISTRICT + "?province_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let datadistrict = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.state.optionAddressDropdown.datadistrict[index] = datadistrict;
					this.setState({});
				})
				.then(() => {
					this.state.personAuthorized[index].district = valueDistrict;
					let position = this.state.optionAddressDropdown.datadistrict[index]
						.map((data) => data.value)
						.indexOf(valueDistrict);
					this.GetSubDistrictByType(
						index,
						type,
						this.state.optionAddressDropdown.datadistrict[index][position].key,
						valueSubDistrict
					);
				});
		} else if (type === "ผู้รับมอบอำนาจ") {
			axios
				.get(GET_DISTRICT + "?province_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let datadistrict = data.map((i) => ({
						key: i.name_th,
						text: i.name_th,
						value: i.id,
					}));
					this.state.delegate_datadistrict = datadistrict;
					this.setState({});
				})
				.then(() => {
					let position = this.state.delegate_datadistrict.map((data) => data.key).indexOf(valueDistrict);
					this.setState({
						delegate_district: this.state.delegate_datadistrict[position].value,
					});
					this.GetSubDistrictByType(
						index,
						type,
						this.state.delegate_datadistrict[position].value,
						valueSubDistrict
					);
				});
		}
	};

	GetSubDistrictWarehouse = (index, indexWarehouse, key, valueSubDistrict) => {
		axios
			.get(GET_SUB_DISTRICT + "?amphure_id=" + key)
			.then((res) => {
				const data = res.data.value;
				let dataSubDistrict = data.map((i) => ({
					key: i.id,
					text: i.name_th,
					value: i.name_th,
				}));
				this.state.optionAddressProductImport[index].dataSubDistrict[indexWarehouse] = dataSubDistrict;
				this.setState({});
			})
			.then(() => {
				this.state.formProductImport[index].warehouse[indexWarehouse].sub_district = valueSubDistrict;
				this.setState({});
			});
	};
	GetSubDistrictByType = (index, type, key, valueSubDistrict) => {
		if (type === "โรงงานผลิต") {
			axios
				.get(GET_SUB_DISTRICT + "?amphure_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let dataSubDistrict = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.state.optionAddressProductRegister.dataSubDistrict[index] = dataSubDistrict;
					this.setState({});
				})
				.then(() => {
					this.state.factory_form_obj[index].factory_zone = valueSubDistrict;
					this.setState({});
				});
		} else if (type === "โรงงานนำเข้า") {
			axios
				.get(GET_SUB_DISTRICT + "?amphure_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let dataSubDistrict = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.state.optionAddressProductImport.dataSubDistrict[index] = dataSubDistrict;
					this.setState({});
				})
				.then(() => {
					this.state.formProductImport[index].warehouse.sub_district = valueSubDistrict;
					this.setState({});
				});
		} else if (type === "ผู้ลงนาม") {
			axios
				.get(GET_SUB_DISTRICT + "?amphure_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let dataSubDistrict = data.map((i) => ({
						key: i.id,
						text: i.name_th,
						value: i.name_th,
					}));
					this.state.optionAddressDropdown.dataSubDistrict[index] = dataSubDistrict;
					this.setState({});
				})
				.then(() => {
					this.state.personAuthorized[index].sub_district = valueSubDistrict;
					this.setState({});
				});
		} else if (type === "ผู้รับมอบอำนาจ") {
			axios
				.get(GET_SUB_DISTRICT + "?amphure_id=" + key)
				.then((res) => {
					const data = res.data.value;
					let dataSubDistrict = data.map((i) => ({
						key: i.name_th,
						text: i.name_th,
						value: i.id,
					}));
					this.state.delegate_dataSubDistrict = dataSubDistrict;
					this.setState({});
				})
				.then(() => {
					let position = this.state.delegate_dataSubDistrict
						.map((data) => data.key)
						.indexOf(valueSubDistrict);

					// this.state.delegate_sub_district = this.state.delegate_dataSubDistrict[index][position].value;

					this.setState({
						delegate_sub_district: this.state.delegate_dataSubDistrict[position].value,
					});
				});
		}
	};

	render() {
		if (this.state.redirect === true) {
			// return <Redirect push to='/registerConfirm' />;
			return <Redirect push to={this.state.nextpage_path} />;
		} else if (this.state.redirectConfirm === true) {
			return <Redirect push to="/alert" />;
		}

		return (
			<div>
				<Headers />
				<Container>
					<br />
					<Form
						onSubmit={(event) => {
							this.handleSubmit(event);
						}}
						error={this.state.formError}
						success={this.state.formSuccess}
						loading={this.state.loading}
					>
						<Segment padded="very" color="violet">
							<Header>
								<h1>
									<Icon color="violet" name="users" />
									ข้อมูลผู้ประกอบการ
								</h1>
							</Header>
							<Divider />

							<br />
							<Form.Field>
								<h4>
									สภาพบุคคล<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid.Column mobile={16} tablet={4} computer={2}>
									<Dropdown
										fluid
										selection
										wrapSelection={false}
										options={this.state.personalityType}
										value={this.state.personalityType[this.state.personality_index].value}
										placeholder="Choose an option"
										name="personality_type"
										onChange={this.onPersonalityChange}
									/>
								</Grid.Column>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_person_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาไทย<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={4} computer={3}>
										<Form.Field error={this.state.prefix_personal_nameTH_error}>
											<Dropdown
												id="prefixTH"
												fluid
												selection
												wrapSelection={false}
												options={namePrefixTH}
												value={
													this.state.name_prefixTH_index !== undefined
														? namePrefixTH[this.state.name_prefixTH_index].value
														: null
												}
												placeholder="Choose an option"
												name="name_prefixTH"
												onChange={this.namePrefixChange}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={8} computer={13}>
										<Form.Field error={this.state.personal_nameTH_error}>
											<Input
												fluid
												size="large"
												icon="user"
												iconPosition="left"
												name="personal_nameTH"
												value={this.state.personal_nameTH}
												onChange={this.handleChange}
												ref={(input) => {
													this.personal_nameTH_input = input;
												}}
											/>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกชื่อภาษาไทย เว้นวรรคชื่อและนามสกุล
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_person_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาอังกฤษ
									<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={4} computer={3}>
										<Form.Field error={this.state.prefix_personal_nameEN_error}>
											<Dropdown
												id="prefixEN"
												fluid
												selection
												wrapSelection={false}
												options={namePrefixEN}
												value={
													this.state.name_prefixEN_index !== undefined
														? namePrefixEN[this.state.name_prefixEN_index].value
														: null
												}
												placeholder="Choose an option"
												name="name_prefixEN"
												onChange={this.namePrefixChange}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={8} computer={13}>
										<Form.Field error={this.state.personal_nameEN_error}>
											<Input
												fluid
												size="large"
												icon="user"
												iconPosition="left"
												name="personal_nameEN"
												value={this.state.personal_nameEN}
												onChange={this.handleChange}
												ref={(input) => {
													this.personal_nameEN_input = input;
												}}
											/>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกชื่อภาษาอังกฤษ เว้นวรรคชื่อและนามสกุล
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>

							<Form.Field
								style={{
									display: this.state.show_incorporated_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาไทย<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_juristicTH_error}>
											<Input
												fluid
												size="large"
												icon="users"
												iconPosition="left"
												name="name_juristicTH"
												value={this.state.name_juristicTH}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_juristicTH_input = input;
												}}
											/>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_incorporated_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาอังกฤษ
									<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_juristicEN_error}>
											<Input
												fluid
												labelPosition="right"
												size="large"
												name="name_juristicEN"
												value={this.state.name_juristicEN}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_juristicEN_input = input;
												}}
											>
												<input />
												<Label basic>Juristic Ordinary Partnership</Label>
											</Input>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>

							<Form.Field
								style={{
									display: this.state.show_limited_partnership_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาไทย<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_limited_partnershipTH_error}>
											<Input
												fluid
												size="large"
												icon="users"
												iconPosition="left"
												name="name_limited_partnershipTH"
												value={this.state.name_limited_partnershipTH}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_limited_partnershipTH_input = input;
												}}
											/>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_limited_partnership_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาอังกฤษ
									<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_limited_partnershipEN_error}>
											<Input
												fluid
												labelPosition="right"
												size="large"
												name="name_limited_partnershipEN"
												value={this.state.name_limited_partnershipEN}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_limited_partnershipEN_input = input;
												}}
											>
												<input />
												<Label basic>Limited Partnership</Label>
											</Input>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>

							<Form.Field
								style={{
									display: this.state.show_company_limited_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาไทย<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_company_limitedTH_error}>
											<Input
												fluid
												size="large"
												labelPosition="right"
												name="name_company_limitedTH"
												value={this.state.name_company_limitedTH}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_company_limitedTH_input = input;
												}}
											>
												<Label basic>บริษัท</Label>
												<input />
												<Label basic>จำกัด</Label>
											</Input>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_company_limited_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาอังกฤษ
									<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_company_limitedEN_error}>
											<Input
												fluid
												labelPosition="right"
												size="large"
												name="name_company_limitedEN"
												value={this.state.name_company_limitedEN}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_company_limitedEN_input = input;
												}}
											>
												<input />
												<Label basic>Company Limited</Label>
											</Input>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>

							<Form.Field
								style={{
									display: this.state.show_public_company_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาไทย<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_public_companyTH_error}>
											<Input
												fluid
												size="large"
												// icon='users'
												// iconPosition='left'
												labelPosition="right"
												name="name_public_companyTH"
												value={this.state.name_public_companyTH}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_public_companyTH_input = input;
												}}
											>
												<Label basic>บริษัท</Label>
												<input />
												<Label basic>จำกัด (มหาชน)</Label>
											</Input>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_public_company_form ? "block" : "none",
								}}
							>
								<h4>
									ชื่อผู้ประกอบการภาษาอังกฤษ
									<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={14} computer={16}>
										<Form.Field error={this.state.name_public_companyEN_error}>
											<Input
												fluid
												labelPosition="right"
												size="large"
												name="name_public_companyEN"
												value={this.state.name_public_companyEN}
												onChange={this.handleChange}
												ref={(input) => {
													this.name_public_companyEN_input = input;
												}}
											>
												<input />
												<Label basic>Public Company Limited</Label>
											</Input>
											<p
												style={{
													color: "#db2828",
													fontWeight: "bold",
													paddingTop: "1rem",
												}}
											>
												หมายเหตุ : กรอกเฉพาะชื่อ
											</p>
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form.Field>

							<Form.Field error={this.state.entrepreneur_type_error}>
								<h4>
									ประเภทผู้ประกอบการ<span style={{ color: "red" }}>*</span>
								</h4>
								<Dropdown
									multiple
									selection
									fluid
									options={this.state.companyType}
									value={this.state.entrepreneur_type}
									renderLabel={renderLabelCompanyType}
									id="entrepreneur_type"
									name="entrepreneur_type"
									onChange={this.handleChangeEtc}
								/>
							</Form.Field>
							<Form.Field error={this.state.tax_id_error}>
								<h4>
									เลขประจำตัวผู้เสียภาษีอากร
									<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={8} computer={6}>
										<Input
											size="large"
											name="tax_id"
											id="tax_id"
											maxLength="17"
											minLength="17"
											placeholder="ตัวอย่าง    X-XXXX-XXXXX-XX-X"
											onChange={this.onIdChange}
											value={this.state.tax_id_show}
											ref={(input) => {
												this.tax_id_input = input;
											}}
										/>
										<p
											style={{
												color: "#db2828",
												fontWeight: "bold",
												paddingTop: "1rem",
												fontSize: "13px",
											}}
										>
											หมายเหตุ : กรอกเลขประจำตัวผู้เสียภาษีอากรและกดตรวจสอบข้อมูล
										</p>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={8} computer={10}>
										<Button
											style={{ width: "200px" }}
											primary
											type="button"
											onClick={() => {
												this.authentication(this.state.tax_id_show);
											}}
										>
											ตรวจสอบข้อมูล
										</Button>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field>
								<h4>
									เครื่องหมายทางการค้า<span style={{ color: "red" }}>*</span>
								</h4>
								<Grid.Column mobile={16} tablet={4} computer={2}>
									<Dropdown
										fluid
										selection
										wrapSelection={false}
										options={this.state.tradeMark}
										value={this.state.tradeMark[this.state.tradeMark_index].value}
										placeholder="Choose an option"
										name="tradeMark_type"
										onChange={this.onTradeMarkChange}
									/>
								</Grid.Column>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_tradeMark_id ? "block" : "none",
								}}
								error={this.state.tradeMark_id_error}
							>
								<h4>
									ทะเบียนเลขที่<span style={{ color: "red" }}>*</span>
								</h4>
								<Input
									size="large"
									icon="trademark"
									iconPosition="left"
									name="tradeMark_id"
									maxLength="30"
									value={this.state.tradeMark_id}
									onChange={this.handleChange}
									ref={(input) => {
										this.tradeMark_id_input = input;
									}}
								/>
							</Form.Field>
							<br />
							<Form.Field>
								<h3>ที่ตั้งสำนักงานใหญ่</h3>
							</Form.Field>
							<br />
							<Form.Field error={this.state.address_id_error}>
								<h4>
									เลขที่<span style={{ color: "red" }}>*</span>
								</h4>
								<Input
									size="large"
									icon="point"
									iconPosition="left"
									name="address_id"
									// readOnly={
									//     !this.state.checkValue.address_id ||
									//     !this.state.authen
									// }
									placeholder="ตัวอย่าง: XX/XX หมู่ที่ XX"
									value={this.state.address_id}
									onChange={this.handleChange}
									ref={(input) => {
										this.address_id_input = input;
									}}
								/>
								<p
									style={{
										color: "#db2828",
										fontWeight: "bold",
										paddingTop: "1rem",
									}}
								>
									หมายเหตุ : กรอกเลขที่และหมู่ที่ (หากมี)
								</p>
							</Form.Field>
							<Form.Field error={this.state.alley_error}>
								<h4>
									ซอย<span style={{ color: "red" }}>*</span>
								</h4>
								<Input
									size="large"
									icon="compress"
									iconPosition="left"
									name="alley"
									// readOnly={
									//     !this.state.checkValue.alley ||
									//     !this.state.authen
									// }
									value={this.state.alley}
									onChange={this.handleChange}
									ref={(input) => {
										this.alley_input = input;
									}}
								/>
							</Form.Field>
							<Form.Field error={this.state.road_error}>
								<h4>
									ถนน<span style={{ color: "red" }}>*</span>
								</h4>
								<Input
									size="large"
									icon="road"
									iconPosition="left"
									name="road"
									// readOnly={
									//     !this.state.checkValue.road ||
									//     !this.state.authen
									// }
									value={this.state.road}
									onChange={this.handleChange}
									ref={(input) => {
										this.road_input = input;
									}}
								/>
							</Form.Field>
							<Form.Field error={this.state.province_error}>
								<h4>
									จังหวัด<span style={{ color: "red" }}>*</span>
								</h4>
								<Dropdown
									fluid
									placeholder="เลือกจังหวัด"
									clearable
									selection
									search
									id="province"
									name="province"
									options={this.state.dataprovince}
									value={this.state.province_value}
									onChange={this.handleChangeAddress}
								/>
							</Form.Field>
							<Form.Field error={this.state.district_error}>
								<h4>
									อำเภอ/เขต<span style={{ color: "red" }}>*</span>
								</h4>
								<Dropdown
									fluid
									placeholder="เลือกอำเภอ/เขต"
									clearable
									selection
									search
									id="district"
									name="district"
									options={this.state.datadistrict}
									value={this.state.district_value}
									onChange={this.handleChangeAddress}
									disabled={this.state.district_Disable}
								/>
							</Form.Field>
							<Form.Field error={this.state.sub_district_error}>
								<h4>
									ตำบล/แขวง<span style={{ color: "red" }}>*</span>
								</h4>
								<Dropdown
									fluid
									placeholder="เลือกตำบล/แขวง"
									clearable
									selection
									search
									id="sub_district"
									name="sub_district"
									options={this.state.dataSubDistrict}
									value={this.state.sub_district_value}
									onChange={this.handleChangeAddress}
									disabled={this.state.sub_district_Disable}
								/>
							</Form.Field>
							<Form.Field error={this.state.zip_code_error}>
								<h4>
									รหัสไปรษณีย์<span style={{ color: "red" }}>*</span>
								</h4>
								<Input
									id="zip_code"
									icon="envelope"
									iconPosition="left"
									maxLength="5"
									minLength="5"
									placeholder="รหัสไปรษณีย์"
									name="zip_code"
									value={this.state.zip_code}
									onChange={this.handleChange}
									ref={(input) => {
										this.zip_code_input = input;
									}}
									disabled={this.state.zipcode_disabled_state}
								/>
							</Form.Field>

							{/* ##################################################################################### */}
							<Form.Field error={this.state.phone_number_error}>
								<h4>
									เบอร์โทรศัพท์<span style={{ color: "red" }}>*</span>
								</h4>
								<Input
									size="large"
									icon="phone square"
									iconPosition="left"
									name="phone_number"
									maxLength="10"
									minLength="9"
									value={this.state.phone_number}
									onChange={this.handleChange}
									placeholder="ตัวอย่าง: 0899999999"
									ref={(input) => {
										this.phone_number_input = input;
									}}
								/>
								<p
									style={{
										color: "#db2828",
										fontWeight: "bold",
										paddingTop: "1rem",
									}}
								>
									หมายเหตุ : กรอกเบอร์โทรศัพท์ที่สามารถติดต่อได้ โดยไม่ต้องใส่เครื่องหมาย -
								</p>
							</Form.Field>
							{/* <Form.Field error={this.state.fax_number_error}>
								<h4>เบอร์แฟกซ์</h4>
								<Input
									size="large"
									icon="fax"
									iconPosition="left"
									name="fax_number"
									minLength="9"
									maxLength="20"
									placeholder="ตัวอย่าง: 02900000"
									value={this.state.fax_number}
									onChange={this.handleChange}
									ref={(input) => {
										this.fax_number_input = input;
									}}
								/>
								<p
									style={{
										color: "#db2828",
										fontWeight: "bold",
										paddingTop: "1rem",
									}}
								>
									หมายเหตุ : กรอกเบอร์แฟกซ์ โดยไม่ต้องใส่เครื่องหมาย -
								</p>
							</Form.Field> */}
							<Form.Field error={this.state.website_error}>
								<h4>เว็บไซต์</h4>
								<Input
									size="large"
									// icon='globe'
									// iconPosition='left'
									label="http://"
									placeholder="www.google.com"
									name="website"
									value={this.state.website}
									onChange={this.handleChange}
									ref={(input) => {
										this.website_input = input;
									}}
								/>
							</Form.Field>
							<Form.Field>
								<h2>
									เอกสารแนบ<span style={{ color: "red" }}>*</span>
								</h2>
							</Form.Field>
							<br />
							<Form.Field error={this.state.file_corporation_certificate_error}>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={4} computer={4}>
										<label>
											<b>หนังสือรับรองนิติบุคคล</b>
											<span style={{ color: "red" }}>*</span>
										</label>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={8} computer={8}>
										<Segment>
											<Label
												attached="top"
												color={this.state.file_corporation_certificate_error ? "red" : "violet"}
												as="label"
												htmlFor="file_corporation_certificate"
												size="large"
											>
												<Icon name="upload" />
												เลือกไฟล์
												<input
													hidden
													type="file"
													id="file_corporation_certificate"
													name="file_corporation_certificate"
													accept=".pdf"
													onChange={this.handleUploadFileChange.bind(this)}
												/>
											</Label>
											<p>
												{this.state.file_corporation_certificate.name}
												&nbsp; &nbsp;
												{this.state.file_corporation_certificate.name ? (
													<Icon
														id="file_corporation_certificate_reset"
														color="red"
														size="large"
														name="times circle outline"
														link
														onClick={this.handleUploadFileReset.bind(this)}
													/>
												) : null}
											</p>
										</Segment>
										<p
											style={{
												color: "#db2828",
												fontWeight: "bold",
												paddingTop: "1rem",
											}}
										>
											ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
										</p>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={4} computer={3}>
										<Label
											size="large"
											basic
											color="red"
											pointing="left"
											style={{
												display: this.state.file_corporation_certificate_error
													? "block"
													: "none",
											}}
										>
											<Icon name="warning circle" />
											กรุณาเพิ่มไฟล์ !
										</Label>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field error={this.state.file_vat_certificate_error}>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={4} computer={4}>
										<label>
											<b>ใบทะเบียนภาษีมูลค่าเพิ่ม (ภ.พ.20)</b>
											<span style={{ color: "red" }}>*</span>
										</label>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={8} computer={8}>
										<Segment>
											<Label
												attached="top"
												color={this.state.file_vat_certificate_error ? "red" : "violet"}
												as="label"
												htmlFor="file_vat_certificate"
												size="large"
											>
												<Icon name="upload" />
												เลือกไฟล์
												<input
													hidden
													type="file"
													id="file_vat_certificate"
													name="file_vat_certificate"
													accept=".pdf"
													onChange={this.handleUploadFileChange.bind(this)}
												/>
											</Label>
											<p>
												{this.state.file_vat_certificate.name}
												&nbsp; &nbsp;
												{this.state.file_vat_certificate.name ? (
													<Icon
														id="file_vat_certificate_reset"
														color="red"
														size="large"
														name="times circle outline"
														link
														onClick={this.handleUploadFileReset.bind(this)}
													/>
												) : null}
											</p>
										</Segment>
										<p
											style={{
												color: "#db2828",
												fontWeight: "bold",
												paddingTop: "1rem",
											}}
										>
											ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
										</p>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={4} computer={3}>
										<Label
											size="large"
											basic
											color="red"
											pointing="left"
											style={{
												display: this.state.file_vat_certificate_error ? "block" : "none",
											}}
										>
											<Icon name="warning circle" />
											กรุณาเพิ่มไฟล์ !
										</Label>
									</Grid.Column>
								</Grid>
							</Form.Field>
							<Form.Field
								style={{
									display: this.state.show_tradeMark_id ? "block" : "none",
								}}
								error={this.state.file_trademark_error}
							>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={4} computer={4}>
										<label>
											<b>หนังสือสำคัญแสดงการจดทะเบียนเครื่องหมายการค้า เครื่องหมายบริการ</b>
											<span style={{ color: "red" }}>*</span>
										</label>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={8} computer={8}>
										<Segment>
											<Label
												attached="top"
												color={this.state.file_trademark_error ? "red" : "violet"}
												as="label"
												htmlFor="file_trademark"
												size="large"
											>
												<Icon name="upload" />
												เลือกไฟล์
												<input
													hidden
													type="file"
													id="file_trademark"
													name="file_trademark"
													accept=".pdf"
													onChange={this.handleUploadFileChange.bind(this)}
												/>
											</Label>
											<p>
												{this.state.file_trademark.name}
												&nbsp; &nbsp;
												{this.state.file_trademark.name ? (
													<Icon
														id="file_trademark_reset"
														color="red"
														size="large"
														name="times circle outline"
														link
														onClick={this.handleUploadFileReset.bind(this)}
													/>
												) : null}
											</p>
										</Segment>
										<p
											style={{
												color: "#db2828",
												fontWeight: "bold",
												paddingTop: "1rem",
											}}
										>
											ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
										</p>
									</Grid.Column>
									<Grid.Column mobile={16} tablet={4} computer={3}>
										<Label
											size="large"
											basic
											color="red"
											pointing="left"
											style={{
												display: this.state.file_trademark_error ? "block" : "none",
											}}
										>
											<Icon name="warning circle" />
											กรุณาเพิ่มไฟล์ !
										</Label>
									</Grid.Column>
								</Grid>
							</Form.Field>

							{/* pdpa */}
							<Form.Field error={this.state.pdpa_request_error}>
								<Grid verticalAlign="top">
									<Grid.Column mobile={16} tablet={4} computer={4} />
									<Grid.Column mobile={16} tablet={8} computer={8}>
										<ContainerCheckbox>
											<BoxContentCheckbox value={this.state.pdpa_request}>
												<InputCheckBox
													name="pdpa_request"
													checked={this.state.pdpa_request}
													onChange={this.handleChange}
												/>
												ยอมรับ &nbsp;
												<ButtonShowDetail
													error={this.state.pdpa_request_error}
													onClick={this.handleOpenReqeustPDPA}
													style={{ textAlign: "left" }}
												>
													ข้อกำหนดและเงื่อนไข
												</ButtonShowDetail>
												{/* </ButtonShowDetail> */}
											</BoxContentCheckbox>
										</ContainerCheckbox>
									</Grid.Column>
								</Grid>
							</Form.Field>
						</Segment>

						<Button
							icon
							labelPosition="left"
							color="violet"
							floated="right"
							type="submit"
							onClick={this.goNextRedux.bind(this)}
						>
							ไปหน้าถัดไป
							<Icon name="right arrow" />
						</Button>
						<br />
						<br />
						<br />
						<br />
					</Form>
				</Container>
				<Modals.PDPARequestForm open={this.state.openModalsPDPA} callback={this.handleSubmitReqeustPDPA} />
			</div>
		);
	}
}

entrepreneurRegister.defaultProps = {
	isIndustry: false,
};

const mapStateToProps = function (state) {
	return {
		message: "This is message from mapStateToProps",
		stateRedux: state.reduxxx || "",
	};
};

export default connect(mapStateToProps)(entrepreneurRegister);

const ContainerCheckbox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const InputCheckBox = styled(Checkbox)({
	marginRight: 10,
	"&:checked": {
		backgroundColor: "green",
	},
});
const BoxContentCheckbox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	box-shadow: ${({ value }) => (value ? "#6435c9 1.95px 1.95px 1.5px" : "")};
	border: ${({ value }) => (value ? "1px solid rgba(34, 36, 38, 0.15)" : "1px solid rgba(34, 36, 38, 0.15)")};
`;

const ButtonShowDetail = styled.p`
	border: none;
	outline: none;
	background: none;
	color: #6435c9;
	font-size: 15px;
	font-weight: 600;
	/* padding: 8px; */
	cursor: pointer;
	&:hover,
	&:focus {
		${({ error }) => (error ? `color: red;` : `color: #6435c9;`)}
		color:gray
	}
	${({ error }) =>
		error &&
		`
    color: red;
  `}
`;
