import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/th";
import styled from "styled-components";
import axios from "axios";
import { Header, Icon, Modal, Pagination } from "semantic-ui-react";
import {
	GET_STATUS_PDPA,
	MODULE_PREFIX,
	PORTAL_ECO_URL_FRONT_END,
	UPDATE_STATUS_REQUEST_PDPA,
	getAssessmentList,
} from "./router";
import ModalsConsent from "../components/Modals";
import Swal from "sweetalert2";

const Modals = styled(Modal)`
	&.ui.small.modal {
		background-color: transparent;
	}
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: flex-end;
	margin-top: -60px;
	margin-bottom: 20px;
	padding-left: 15px;
	width: 100%;
`;

const LogoBox = styled.div`
	width: 100px;
	background-color: #fff;
	background-image: url("/logo-eco.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	height: 100px;
	outline: 10px solid #ddd;
	border-radius: 50%;
`;

const ModalBackdrop = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 320px;
	/* max-width: 700px; */
	max-height: 90vh;
	background-color: #fff !important;
	border-radius: 15px !important;
	padding: 1rem;
	& img {
		max-width: 800;
	}
`;

const Stack = styled.div`
	display: flex;
	${(props) => (props.direction == "column" ? "flex-direction: column;" : "flex-direction: row;")}
	${(props) => (props.alignItems == "center" ? "align-items: center;" : "")}
	${(props) => (props.justifyContent == "center" ? "justify-content: center;" : "")}
	${(props) => (props.mb ? "margin-bottom: 15px;" : "")}
`;

const ListAssessmentBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 1rem;
	border: 1px solid #eee;
	border-radius: 10px;
	cursor: pointer;
	margin-bottom: 1rem;
`;

const Typography = styled.span`
	font-weight: 400;
`;

const Button = styled.button`
	min-width: 64px;
	padding: 3px 9px;
	border-radius: 8px;
	border: 1px solid rgba(0, 171, 85, 0.5);
	color: #00ab55;
	background-color: #fff;
	cursor: pointer;
	min-height: 36px;

	&:hover {
		text-decoration: none;
		background-color: rgba(0, 171, 85, 0.08);
		border: 1px solid #00ab55;
	}
`;

const PaginationCustom = styled(Pagination)`
	&.pagination.pointing.secondary .active.item {
		border-color: #00ab55;
		font-weight: 500;
		color: #00ab55;

		&:hover {
			border-color: #00ab55;
			color: #00ab55;
		}
	}
`;
const ListAssessment = ({ data, onClick }) => {
	const handleClick = async () => {
		const url = PORTAL_ECO_URL_FRONT_END;
		const prefix = MODULE_PREFIX;
		let token = localStorage.getItem("token");
		if (token) {
			window.open(`${url}?sys_module=${prefix}&id=${data.id}&token=${token}`, "_blank");
		}
		onClick();
	};
	return (
		<ListAssessmentBox onClick={() => handleClick()}>
			<Stack direction="column">
				<Header size="small">{data.subject}</Header>
				<Stack alignItems="center">
					<Icon name="calendar alternate outline" />{" "}
					<Typography>
						{moment(data.updated_at).locale("th").add(543, "years").format("วันที่ LL")}
					</Typography>
				</Stack>
			</Stack>

			<Button
				onClick={() => {}}
				style={{ width: "fit-content", fontSize: 14 }}
				variant="outlined"
				color="primary"
				size="small"
			>
				ทำแบบสอบถาม
			</Button>
		</ListAssessmentBox>
	);
};

const AssesmentModal = ({ open, onClose }) => {
	const [page, setPage] = useState(1);
	const [formData, setFormData] = useState({});
	const [closeable, setCloseable] = useState(false);

	const handleChangePage = (e, activePage) => {
		setPage(activePage);
	};

	const getAssessment = async () => {
		const responseList = await getAssessmentList();
		if (responseList?.data?.data?.assessment_form_list.length > 0) {
			setFormData(responseList?.data?.data);
		}
	};

	const handleTrigerClosable = () => {
		if (!closeable) {
			setCloseable(true);
		}
	};

	useEffect(() => {
		getAssessment();
	}, [page]);

	return (
		<Modals open={open} onClose={() => (closeable ? onClose() : void 0)} size="small">
			<ModalBackdrop>
				<LogoContainer>
					<LogoBox />
					<Header style={{ marginLeft: 15, height: "fit-content" }}>รายการแบบสอบถาม</Header>
				</LogoContainer>

				<Stack direction="column" mb>
					<Header size="small">ขอความกรุณาทำแบบสอบถาม/แบบประเมินความพึงพอใจ</Header>
					<Typography>Please complete assessment form.</Typography>
				</Stack>
				<Stack spacing={1} mb direction="column">
					{formData?.assessment_form_list?.map((item, key) => {
						return <ListAssessment key={key} data={item} onClick={handleTrigerClosable} />;
					})}
				</Stack>
				{formData?.info?.total_pages > 1 && (
					<Stack direction="row" justifyContent={"center"} mb>
						<PaginationCustom
							activePage={page}
							defaultActivePage={1}
							firstItem={null}
							lastItem={null}
							pointing
							secondary
							totalPages={formData?.info?.total_pages}
							onChange={handleChangePage}
						/>
					</Stack>
				)}
				{closeable && (
					<Stack justifyContent="center">
						<Button onClick={() => onClose()}>ปิด</Button>
					</Stack>
				)}
			</ModalBackdrop>
		</Modals>
	);
};

const ModalSection = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isOpenModalConsent, setIsOpenModalConsent] = useState(false);

	const initailLayout = async () => {
		try {
			const responseList = await getAssessmentList();
			if (responseList?.data?.data?.assessment_form_list.length > 0) {
				setIsOpenModal(true);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e);
		}
	};

	const fetchCheckRequestPDPA = async () => {
		try {
			const { data } = await axios.get(GET_STATUS_PDPA, {
				headers: {
					Authorization: `Bearer ` + localStorage.getItem("token"),
				},
			});
			if (!data.value.consented) {
				setIsOpenModalConsent(true);
			}
		} catch (error) {
			setIsOpenModalConsent(false);

			console.log(error);
		}
	};

	const handleSubmitConfirmPDPA = async () => {
		try {
			await axios.post(UPDATE_STATUS_REQUEST_PDPA, null, {
				headers: {
					Authorization: `Bearer ` + localStorage.getItem("token"),
				},
			});
			setIsOpenModalConsent(false);
		} catch (error) {
			Swal.fire({
				type: "error",
				title: "เกิดข้อผิดพลาด...",
			});
		}
	};

	useEffect(() => {
		fetchCheckRequestPDPA();
		if (!isOpenModalConsent) {
			initailLayout();
		}
	}, [isOpenModalConsent]);

	return (
		<>
			<ModalsConsent.PDPARequestForm
				open={isOpenModalConsent}
				onClose={() => setIsOpenModalConsent(false)}
				callback={handleSubmitConfirmPDPA}
			/>
			{!isLoading && !isOpenModalConsent && (
				<AssesmentModal open={isOpenModal} onClose={() => setIsOpenModal(false)} />
			)}
		</>
	);
};

export default ModalSection;
