import React, { Component } from "react";
import {
    Segment,
    Header,
    Icon,
    Container,
    Table,
    Divider,
    Button,
    Dropdown,
    Pagination,
    Dimmer,
    Loader,
    Label,
    Modal,
    Grid,
    Form,
    Accordion,
} from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux"; // connect redux

import {
    TOKEN_COMPANY,
    GET_DRAFT_ALL_DATA_COMPANY,
    GET_FACTORY_NOT_PAID,
    GET_REQUEST_DATA_COMPANY,
    GET_REQUEST_WAITING_FOR_APPROVAL_DATA_COMPANY,
    GET_REQUEST_APPROVE_DATA_COMPANY,
    GET_REQUEST_NOT_ALLOWED_DATA_COMPANY,
    GET_REQUEST_EDIT_COMPANY,
    SAVE_RECEIPT_PER_FACTORY,
} from "../router";
import Headers from "../header/requestHeader";
import Swal from "sweetalert2";

class requestPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: null,
            login: true,
            activeItem: "รอการอนุมัติ",
            data: [],
            rowQty: 5,
            currentPage: 1,
            pageQty: 1,
            allSizeRow: [
                { key: 1, text: 5, value: 5 },
                { key: 2, text: 10, value: 10 },
                { key: 3, text: 20, value: 20 }
            ],
            loader_table: false,
            loader_all: false,
            newRequest: {
                refCode: "",
                companyId: "",
                factoryId: "",
                factoryTypeId: "",
                tyre_requests_name: "",

                productRemove: [],
                brandRemove: [],
                genRemove: [],
                sizeRemove: [],
                productPos: 0,
                productRequest: [
                    {
                        id: "",
                        productId: "",
                        productTypeId: "",
                        productName: "",
                        licenseNumber: "",
                        licenseNumberFile: { name: "", data: [], path: "" },
                        listProduct: "",
                        brandsRequest: [
                            {
                                id: "",
                                brandId: "",
                                brandName: "",
                                genRequest: [
                                    {
                                        id: "",
                                        genName: "",
                                        genLayoutFile: { name: "", data: [], path: "" },
                                        sizeRequest: [
                                            {
                                                id: "",
                                                sizeGen: "",
                                                sizeCapacity: "",
                                                sizeSpeedSymbol: "",
                                                sizeSpecialFeature: "",
                                                sizeStructure: "",
                                                sizeTypeOfUse: "",
                                                sizeCuttingWidth: "",
                                                sizeSymbol: ""
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        testResult: {
                            id: "",
                            testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
                            testValue_a: "0",
                            testUnit_a: "N/kN",
                            brandName_a: "",
                            genName_a: "",
                            sizeGen_a: "",
                            sizeSymbol_a: "",
                            testPage_a: "",
                            testFile_a: { name: "", data: [], path: "" },

                            testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
                            testValue_b: "0",
                            testUnit_b: "G",
                            brandName_b: "",
                            genName_b: "",
                            sizeGen_b: "",
                            testPage_b: "",
                            testFile_b: { name: "", data: [], path: "" },

                            testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
                            testValue_c: "0",
                            testUnit_c: "dB(A)",
                            brandName_c: "",
                            genName_c: "",
                            sizeGen_c: "",
                            testPage_c: "",
                            testFile_c: { name: "", data: [], path: "" },

                            testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
                            testValue_d: "0",
                            testUnit_d: "SG",
                            brandName_d: "",
                            genName_d: "",
                            sizeGen_d: "",
                            testPage_d: "",
                            testFile_d: { name: "", data: [], path: "" }
                        }
                    }
                ]
            },
            file: {
                name: '',
                data: '',
                path: '',
            },
            factory_modal_detail: {
                id: '',
                name: '',
                path: '',
            },
            openModal: false,
        };
    }

    componentWillMount = async () => {
        // console.log(this.state.login)
        if (localStorage.getItem("role") !== null) {
            this.setState({
                loader_table: true
            });
            await this.getFactoryNotPaid();
            // await this.getAllDraft();
            // await this.getDataCompany();

            this.setState({ loader_table: false });
        }
    };
    getFactoryNotPaid = async () => {
        try {
            let { data } = await axios.get(GET_FACTORY_NOT_PAID, TOKEN_COMPANY());



            if (data.value !== null) {
                this.setState({ data: data.value });
                let lengthData = 1;
                if (this.state.data !== null) {
                    lengthData = this.state.data.length;
                }
                let pageQty = lengthData / this.state.rowQty;
                this.setState({ pageQty: Math.ceil(pageQty), currentPage: 1 });
            } else {
                this.setState({ loader_table: false });
            }

        } catch (error) {
            // console.log(error);
            Swal.fire({
                type: "error",
                title: "ผิดพลาด",
                text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                confirmButtonText: "ตกลง"
            });
        }
    }
    getAllDraft = async () => {
        try {
            let { data } = await axios.get(
                GET_REQUEST_WAITING_FOR_APPROVAL_DATA_COMPANY,
                TOKEN_COMPANY()
            );
            // console.log("data", status);
            if (data.value !== null) {
                await this.setState({ data: data.value });
                let lengthData = 1;
                if (this.state.data !== null) {
                    lengthData = this.state.data.length;
                }
                let pageQty = lengthData / this.state.rowQty;
                await this.setState({ pageQty: Math.ceil(pageQty), currentPage: 1 });
            } else {
                await this.setState({ loader_table: false });
            }
        } catch (error) {
            // console.log(error);
            Swal.fire({
                type: "error",
                title: "ผิดพลาด",
                text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                confirmButtonText: "ตกลง"
            });
        }
    };

    getDataCompany = async () => {
        // console.log(localStorage.getItem("company_data"));
        if (localStorage.getItem("company_data") === null) {
            // console.log("object");
            try {
                let { data } = await axios.get(GET_REQUEST_DATA_COMPANY, TOKEN_COMPANY());
                //console.log("get data ", data);
                localStorage.setItem("company_data", JSON.stringify(data));
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    type: "error",
                    title: "ผิดพลาด",
                    text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                    confirmButtonText: "ตกลง"
                });
            }
        }
    };


    render() {
        var dataFetch = this.state.data;

        return (
            <div>
                <Headers />
                <div style={{ margin: 30 }}>
                    <Container fluid>
                        <Dimmer active={this.state.loader_all}>
                            <Loader size="massive">Loading</Loader>
                        </Dimmer>
                        <Header as="h2" textAlign="left">
                            <Icon name="users" color="violet" />
                            ผู้ประกอบการ
            </Header>
                        <Divider />

                        {/* ประเภทผู้ยื่นขอ ชื่อโรงงาน วันที่สร้าง วันที่อัปเดตล่าสุด */}
                        <Segment
                            attached="bottom"
                            style={{ padding: 20 }}
                            textAlign="center"
                        >
                            <Dimmer active={this.state.loader_table}>
                                <Loader size="massive">Loading</Loader>
                            </Dimmer>

                            {(() => {

                                // console.log("dataFetch", dataFetch);

                                let tableHeader_wait = (
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell collapsing>
                                                ลำดับ
                            </Table.HeaderCell>


                                            <Table.HeaderCell style={{ width: "40%" }}>
                                                ชื่อโรงงาน
                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{ width: "30%" }}>
                                                ที่อยู่
                            </Table.HeaderCell>
                                            <Table.HeaderCell style={{ width: "20%" }}>
                                                อัปโหลดเอกสาร
                            </Table.HeaderCell>



                                        </Table.Row>
                                    </Table.Header>
                                );

                                let tableBody_wait = [];

                                dataFetch.forEach((data_fetch, data_index) => {
                                    if (
                                        data_index >=
                                        this.state.rowQty *
                                        (this.state.currentPage - 1) &&
                                        data_index <
                                        this.state.rowQty * this.state.currentPage &&
                                        data_fetch.FactoryPaymentStatus !== null
                                        // data_fetch.tyre_requests.length !== 0
                                    ) {
                                        let rowBody_wait = (
                                            <React.Fragment key={data_index}>
                                                <Table.Row>
                                                    <Table.Cell>{data_index + 1} </Table.Cell>

                                                    <Table.Cell textAlign="left">
                                                        <Accordion fluid>
                                                            <Accordion.Title
                                                                style={{ padding: 0 }}
                                                                active={
                                                                    this.state.activeIndex === data_index
                                                                }
                                                                index={data_index}
                                                                onClick={(e, titleProps) => {
                                                                    // const { index } = titleProps;

                                                                    if (
                                                                        this.state.activeIndex !==
                                                                        data_index
                                                                    ) {
                                                                        this.setState({
                                                                            activeIndex: data_index
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            activeIndex: -1
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <Icon name="dropdown" />
                                                                {data_fetch.name_th}
                                                            </Accordion.Title>
                                                            <Accordion.Content
                                                                active={
                                                                    this.state.activeIndex === data_index
                                                                }
                                                            >
                                                                <ul style={{ margin: 0 }}>
                                                                    {data_fetch.tyre_requests.map((tyre_req, data_index) => {

                                                                        // console.log(tyre_req)
                                                                        return (<li style={{ margin: 0 }} >{tyre_req.ref_code}</li>)
                                                                    })
                                                                    }
                                                                    {/* <li>fff</li> */}
                                                                </ul>
                                                            </Accordion.Content>
                                                        </Accordion>

                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {data_fetch.text_address === "" ? data_fetch.address + " " + data_fetch.soi + " " + data_fetch.road + " " + data_fetch.subdistrict + " " + data_fetch.district + " " + data_fetch.province : data_fetch.text_address}           </Table.Cell>

                                                    <Table.Cell>

                                                        {(data_fetch.FactoryPaymentStatus === 5 || data_fetch.FactoryPaymentStatus === 8) ?
                                                            (
                                                                <div>
                                                                    <Button primary
                                                                        onClick={() => {
                                                                            this.openModal(data_fetch)
                                                                        }
                                                                        }
                                                                    >
                                                                        <Icon name="cloud upload" /> อัปโหลดเอกสารชำระเงิน
                                                                    </Button>
                                                                    <div style={{ display: data_fetch.FactoryPaymentStatus === 8 ? 'block' : 'none' }}>
                                                                        <p style={{ color: 'red', fontSize: '12px' }} >กรุณาอัปโหลดใหม่</p>
                                                                    </div>
                                                                </div>

                                                            )
                                                            : data_fetch.FactoryPaymentStatus === 6 ?
                                                                (<Label style={{ width: "100%" }} color={"yellow"}>
                                                                    รอการอนุมัติใบเสร็จ
                                                                </Label>)
                                                                : data_fetch.FactoryPaymentStatus === 7 && data_fetch.tyre_requests.length === 0 ?
                                                                    (<Label style={{ width: "100%" }} color={"green"}>
                                                                        ชำระเงินสำเร็จ
                                                                    </Label>)
                                                                    : (<Label style={{ width: "100%" }} >
                                                                        รอใบแจ้งชำระเงิน
                                                                    </Label>)

                                                        }

                                                    </Table.Cell>
                                                </Table.Row>
                                            </React.Fragment>
                                        );

                                        tableBody_wait.push(rowBody_wait);
                                    }
                                });


                                let tableFooter_wait = (
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="9" textAlign="right">
                                                Row
                              <Dropdown
                                                    placeholder="Row"
                                                    selection
                                                    compact
                                                    name="rowQty"
                                                    value={this.state.rowQty}
                                                    options={this.state.allSizeRow}
                                                    onChange={this.changeRowSize}
                                                    style={{
                                                        marginLeft: 10,
                                                        marginRight: 10,
                                                        width: 60
                                                    }}
                                                />
                                                Page
                              <Pagination
                                                    boundaryRange={1}
                                                    activePage={this.state.currentPage}
                                                    siblingRange={1}
                                                    name="currentPage"
                                                    totalPages={this.state.pageQty}
                                                    onPageChange={(e, { name, activePage }) =>
                                                        this.changePage(name, Math.ceil(activePage))
                                                    }
                                                    style={{ marginLeft: 10, marginRight: 10 }}
                                                />
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                );

                                return (
                                    <div>
                                        <Table padded celled textAlign="center">
                                            {tableHeader_wait}
                                            <Table.Body>{tableBody_wait}</Table.Body>
                                            {tableFooter_wait}
                                        </Table>

                                        <Modal
                                            open={this.state.openModal}
                                            onClose={this.closeModal}
                                        >
                                            <Modal.Header>{this.state.factory_modal_detail.name}</Modal.Header>
                                            <Modal.Content >

                                                {/* <Modal.Description> */}
                                                <Header>อัปโหลดเอกสารการชำระเงิน</Header>
                                                <Grid.Row>
                                                    <Grid.Column computer={16}>
                                                        <Form.Field>
                                                            {/* <label>ใบอนุญาต</label> */}
                                                            <Segment id={"licenseNumberFile_0"}>
                                                                <Label
                                                                    attached="top"
                                                                    color={"viloet"}
                                                                    as="label"
                                                                    htmlFor={"licenseNumberFile_upload_0"}
                                                                    size="large"
                                                                >
                                                                    <Icon name="upload" />
                                                                    เลือกไฟล์
                          <input
                                                                        hidden
                                                                        id={"licenseNumberFile_upload_0"}
                                                                        type="file"
                                                                        // accept=".pdf"
                                                                        accept="image/*"
                                                                        onChange={async e => {
                                                                            if (e.target.files[0]) {
                                                                                if (e.target.files.size > 10000000) {
                                                                                    alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                                                                    return;
                                                                                }
                                                                                var tmpFile = this.state.file
                                                                                var src = URL.createObjectURL(
                                                                                    e.target.files[0]
                                                                                );

                                                                                tmpFile.name = e.target.files[0].name;
                                                                                tmpFile.data = e.target.files[0]
                                                                                tmpFile.path = src

                                                                                // tmp[productPos].licenseNumberFileError = false;

                                                                                await this.setState({
                                                                                    file: tmpFile
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </Label>
                                                                <p>
                                                                    {this.state.file.name}
                                                                    &nbsp; &nbsp;
                          {this.state.file.name ? (
                                                                        <Icon
                                                                            color="red"
                                                                            size="large"
                                                                            name="times circle outline"
                                                                            link
                                                                            onClick={async e => {
                                                                                var tmpFile = {
                                                                                    name: "",
                                                                                    data: "",
                                                                                    path: ""
                                                                                };

                                                                                // tmp[productPos].licenseNumberFileError = true;

                                                                                await this.setState({
                                                                                    file: tmpFile
                                                                                });

                                                                                // document.getElementById(
                                                                                //     "licenseNumberFile_" + productPos
                                                                                // ).value = null;
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </p>
                                                            </Segment>

                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>

                                                {/* </Modal.Description> */}
                                            </Modal.Content>
                                            <Modal.Actions>

                                                <Button
                                                    color='green'
                                                    onClick={() => {
                                                        this.uploadFile()
                                                    }}
                                                    disabled={this.state.file.name === ''}
                                                >
                                                    <Icon name='checkmark' /> Upload
                                </Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </div>
                                );

                            })()}

                        </Segment>
                    </Container>
                </div>
            </div>
        );
    }

    // getModalButton = () => {

    //     return ()
    // }
    openModal = async (data) => {

        await this.setState({
            factory_modal_detail: {
                id: data.id,
                name: data.name_th,
                path: ''
            },
            openModal: true
        })
    }

    closeModal = () => {
        var tmpFile = {
            name: "",
            data: "",
            path: ""
        };
        this.setState({
            file: tmpFile,
            openModal: false
        })
    }

    uploadFile = async () => {
        // console.log("upload Swal")

        Swal.fire({
            title: "ยืนยันการอัปโหลดเอกสาร",
            // text: "You won't be able to revert this!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#43A047",
            cancelButtonColor: "#E53935",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก"
        }).then(async result => {
            if (result.value) {
                let formData = new FormData();
                // // console.log("SAVE FORM DATA ", this.state);
                formData.append("fatories_id", this.state.factory_modal_detail.id);
                formData.append("file_tyre_requests_receipt", this.state.file.data);

                try {
                    var { data } = await axios.post(SAVE_RECEIPT_PER_FACTORY, formData);

                    // console.log("file upload", data)
                    if (data.status === "success") {
                        Swal.fire({
                            //  position: 'top-end',
                            type: 'success',
                            title: 'อัปโหลดสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            this.getFactoryNotPaid();
                            this.closeModal()
                        })
                    }
                } catch (error) {
                    console.log(error);
                    Swal.fire({
                        position: "top",
                        title: "เกิดข้อผิดพลาด!",
                        type: "error",
                        confirmButtonColor: "red",
                        confirmButtonText: "ตกลง"
                    });

                }
            }
        });



    }
    changePage = (name, value) => this.setState({ [name]: value });

    changeRowSize = (e, { name, value }) => {
        this.setState({ [name]: value, currentPage: 1 }, () => {
            let lengthData = this.state.data.length; // data length
            let pageQty = lengthData / this.state.rowQty; // data length หาร dropdown value
            this.setState({ pageQty: Math.ceil(pageQty) });
        });
    };

    handleMenuClick = async (e, { name, value }) => {
        this.setState({ activeItem: name, data: [] });
        if (name === "รอการอนุมัติ") {
            // console.log(name);
            await this.setState({
                loader_table: true
            });

            try {
                let { data } = await axios.get(
                    GET_REQUEST_WAITING_FOR_APPROVAL_DATA_COMPANY,
                    TOKEN_COMPANY()
                );

                // console.log("รอการอนุมัติ", data);

                if (data.value !== null) {
                    await this.setState(
                        { data: data.value, loader_table: false },
                        async () => {
                            let lengthData = 1;
                            if (this.state.data !== null) {
                                lengthData = this.state.data.length;
                            }
                            let pageQty = lengthData / this.state.rowQty;
                            await this.setState({
                                pageQty: Math.ceil(pageQty),
                                currentPage: 1
                            });
                        }
                    );
                } else {
                    await this.setState({ loader_table: false });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    type: "error",
                    title: "ผิดพลาด",
                    text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                    confirmButtonText: "ตกลง"
                });
            }
            await this.setState({
                loader_table: false
            });
        } else if (name === "อนุมัติ") {
            // console.log(name);
            await this.setState({
                loader_table: true
            });

            try {
                let { data } = await axios.get(GET_REQUEST_APPROVE_DATA_COMPANY, TOKEN_COMPANY());

                // console.log(data);

                if (data.value !== null) {
                    await this.setState(
                        { data: data.value, loader_table: false },
                        async () => {
                            let lengthData = 1;
                            if (this.state.data !== null) {
                                lengthData = this.state.data.length;
                            }
                            let pageQty = lengthData / this.state.rowQty;
                            await this.setState({
                                pageQty: Math.ceil(pageQty),
                                currentPage: 1
                            });
                        }
                    );
                } else {
                    await this.setState({ loader_table: false });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    type: "error",
                    title: "ผิดพลาด",
                    text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                    confirmButtonText: "ตกลง"
                });
            }

            await this.setState({
                loader_table: false
            });
        } else if (name === "ไม่ผ่านการอนุมัติ") {
            // console.log(name);
            await this.setState({
                loader_table: true
            });

            try {
                let { data } = await axios.get(GET_REQUEST_NOT_ALLOWED_DATA_COMPANY, TOKEN_COMPANY());

                // console.log(data);

                if (data.value !== null) {
                    await this.setState(
                        { data: data.value, loader_table: false },
                        async () => {
                            let lengthData = 1;
                            if (this.state.data !== null) {
                                lengthData = this.state.data.length;
                            }
                            let pageQty = lengthData / this.state.rowQty;
                            await this.setState({
                                pageQty: Math.ceil(pageQty),
                                currentPage: 1
                            });
                        }
                    );
                } else {
                    await this.setState({ loader_table: false });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    type: "error",
                    title: "ผิดพลาด",
                    text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                    confirmButtonText: "ตกลง"
                });
            }

            await this.setState({
                loader_table: false
            });
        } else if (name === "แก้ไข") {
            // console.log(name);
            await this.setState({
                loader_table: true
            });

            try {
                let { data } = await axios.get(GET_REQUEST_EDIT_COMPANY, TOKEN_COMPANY());

                // console.log(data);

                if (data.value !== null) {
                    await this.setState(
                        { data: data.value, loader_table: false },
                        async () => {
                            let lengthData = 1;
                            if (this.state.data !== null) {
                                lengthData = this.state.data.length;
                            }
                            let pageQty = lengthData / this.state.rowQty;
                            await this.setState({
                                pageQty: Math.ceil(pageQty),
                                currentPage: 1
                            });
                        }
                    );
                } else {
                    await this.setState({ loader_table: false });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    type: "error",
                    title: "ผิดพลาด",
                    text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                    confirmButtonText: "ตกลง"
                });
            }

            await this.setState({
                loader_table: false
            });
        } else if (name === "ฉบับร่าง") {
            await this.setState({
                loader_table: true
            });

            try {
                let { data } = await axios.get(GET_DRAFT_ALL_DATA_COMPANY, TOKEN_COMPANY());

                // console.log(data);

                if (data.value !== null) {
                    await this.setState(
                        { data: data.value, loader_table: false },
                        async () => {
                            let lengthData = 1;
                            if (this.state.data !== null) {
                                lengthData = this.state.data.length;
                            }
                            let pageQty = lengthData / this.state.rowQty;
                            await this.setState({
                                pageQty: Math.ceil(pageQty),
                                currentPage: 1
                            });
                        }
                    );
                } else {
                    await this.setState({ loader_table: false });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    type: "error",
                    title: "ผิดพลาด",
                    text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                    confirmButtonText: "ตกลง"
                });
            }

            await this.setState({
                loader_table: false
            });
        }
    };

    formatDate = dateString => {
        var date = new Date(dateString);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        if (day < 10) {
            day = "0" + day;
        }

        switch (month) {
            case 1:
                month = "Jan";
                break;
            case 2:
                month = "Feb";
                break;
            case 3:
                month = "Mar";
                break;
            case 4:
                month = "Apr";
                break;
            case 5:
                month = "May";
                break;
            case 6:
                month = "Jun";
                break;
            case 7:
                month = "Jul";
                break;
            case 8:
                month = "Aug";
                break;
            case 9:
                month = "Sep";
                break;
            case 10:
                month = "Oct";
                break;
            case 11:
                month = "Nov";
                break;
            case 12:
                month = "Dec";
                break;
            default:
                break;
        }

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        var formattedDate =
            " วันที่ " +
            day +
            "-" +
            month +
            "-" +
            (year + 543) +
            "\n เวลา " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;

        return formattedDate;
    };
}

const mapStateToProps = function (state) {
    return {
        requestRedux: state.requestRedux
    };
};

export default connect(mapStateToProps)(requestPayment);
