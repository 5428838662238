import React, { Component } from "react";
import {
  Menu,
  Image,
  Icon,
  Responsive,
  Button,
  Sidebar,
  Segment,
} from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MobileHeader from "./MobileHeader";
import styles from "../../css/style_sidebar.module.scss";

class ApprovalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogout: "",
      home: "",
      message: "",
      username: "",
      account: "Person",
      activeAdminMenuItem: "entrepreneur",
      login: true,
      isSidebarOpen: false,
    };
  }

  componentWillMount = () => {
    if (
      localStorage.getItem("role") !== "1" ||
      localStorage.getItem("role") === null
    ) {
      this.setState({ login: false });
    }
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  logout = () => {
    Swal.fire({
      title: "ยืนยันที่จะออกจากระบบ",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      buttonsStyling: true,
    }).then((res) => {
      if (res.value) {
        localStorage.clear();
        this.props.history.push("/");
      } else {
        return;
      }
    });
  };
  handleAdminMenuItemClick = (_, { name }) => {
    if (localStorage.getItem("role") === null) {
      this.setState({ login: false });
    }
    this.setState({ activeAdminMenuItem: name });
    this.props.history.push("/oie/entrepreneur");
  };

  handleClickLogo = () => {
    localStorage.clear();
    this.props.history.push("/");
  };
  navcheck = () => {
    if (
      localStorage.getItem("role") !== "0" &&
      localStorage.getItem("role") !== null
    ) {
      return (
        // <Sticky>
        <React.Fragment>
          {/* <Sidebar.Pushable
            className={`${styles.sidebar_pusher} ${
              this.state.isSidebarOpen ? styles.sidebar_visible : ""
            }`}
            as={Segment}
          >
            <Sidebar
              as={Menu}
              animation="overlay"
              icon="labeled"
              onHide={() => {
                this.setState({ isSidebarOpen: false });
              }}
              vertical
              visible={this.state.isSidebarOpen}
              width="thin"
              style={{ width: "100%" }}
            >
              <Menu.Item
                name="request"
                href="/company/main"
                target="_self"
                onClick={this.handleAdminMenuItemClick}
              >
                <Icon name="home" />
                หน้าแรก
              </Menu.Item>
              <Menu.Item
                name="request"
                href="/company/request-license"
                onClick={(_, val) => {
                  this.handleAdminMenuItemClick.bind(_, val);
                  localStorage.removeItem("draftId");
                }}
              >
                <Image
                  centered
                  src={"/eco.png"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "32px",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                  }}
                />
                กรอกข้อมูล
              </Menu.Item>

              <Menu.Item>
                {localStorage.getItem("name") ? (
                  <React.Fragment>
                    <Icon size="small" name="user" />
                    {localStorage.getItem("name")}

                    <Menu.Menu>
                      <Link to={this.state.URLEdit}>
                        <Menu.Item onClick={this.handleEditClick}>
                          <label> แก้ไขข้อมูล</label>
                        </Menu.Item>
                      </Link>
                      <Menu.Item
                        onClick={() => this.openModalCompanylogoWebsite()}
                      >
                        <label>อัพโหลดภาพเครื่องหมายการค้า</label>
                      </Menu.Item>
                      <Menu.Item>
                        <label>เปลี่ยนรหัสผ่าน</label>
                      </Menu.Item>
                      <Link to="/">
                        <Menu.Item
                          onClick={() => {
                            localStorage.clear();
                          }}
                        >
                          <label
                            style={{ justifyContent: "center", color: "red" }}
                          >
                            ออกจากระบบ
                          </label>
                        </Menu.Item>
                      </Link>
                    </Menu.Menu>
                  </React.Fragment>
                ) : (
                  <Link to="/company/login">
                    <Button basic color="violet">
                      เข้าสู่ระบบ
                    </Button>
                  </Link>
                )}
              </Menu.Item>
            </Sidebar>

            <Sidebar.Pusher
              style={{ maxHeight: `100vh`, minHeight: `50vh` }}
            ></Sidebar.Pusher>
          </Sidebar.Pushable> */}
          <Menu
            icon="labeled"
            secondary
            style={{
              background: "linear-gradient(45deg, yellow , orange)",
            }}
          >
            <Responsive minWidth={Responsive.onlyMobile.minWidth}>
              <Menu.Item onClick={this.handleClickLogo}>
                <Image
                  src={"/imagelogin/logobg.png"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90px",
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderTopRightRadius: 15,
                    borderTopLeftRadius: 15,
                  }}
                />
              </Menu.Item>
            </Responsive>

            {localStorage.getItem("role") !== "2" && (
              <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                {/* <Link to='/oie/entrepreneur'> */}
                <Menu.Item
                  name="entrepreneur"
                  active={this.props.match.path === "/oie/entrepreneur"}
                  // onClick={this.handleAdminMenuItemClick}
                  onClick={(_, val) => {
                    this.handleAdminMenuItemClick(_, val);
                    this.props.history.push("/oie/entrepreneur");
                  }}
                >
                  <Icon name="users" />
                  ผู้ประกอบการ
                </Menu.Item>
                {/* </Link> */}
              </Responsive>
            )}

            {/* <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              <Menu.Item
                name="motorcycle"
                // active={this.props.match.path === '/approval/tisi_oie'}
                onClick={this.handleAdminMenuItemClick}
                style={{ marginTop: "20px" }}
              >
                <h4>ยาง</h4>
              </Menu.Item>
            </Responsive>
            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              <Menu.Item
                name="DetailAll"
                active={this.state.activeAdminMenuItem === "DetailAll"}
                onClick={this.handleAdminMenuItemClick}
                style={{ marginTop: "20px" }}
              >
                <h4>ข้อมูลทั้งหมด</h4>
              </Menu.Item>
            </Responsive>
            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              <Menu.Item
                name="service"
                active={this.state.activeAdminMenuItem === "service"}
                onClick={this.handleAdminMenuItemClick}
                style={{ marginTop: "20px" }}
              >
                <h4>Technical Service</h4>
              </Menu.Item>
            </Responsive> */}

            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              {/* <Link to='/approval/tisi_oie'> */}
              <Menu.Item
                name="ตรวจสอบคำขอ"
                active={this.props.match.path === "/approval/tisi_oie"}
                // onClick={this.handleAdminMenuItemClick}
                // style={{ marginTop: "10px" }}
                onClick={(_, val) => {
                  this.handleAdminMenuItemClick(_, val);
                  this.props.history.push("/approval/tisi_oie");
                }}
              >
                <Image
                  src={"/eco.png"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "32px",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    // marginBottom: "10px" ,
                  }}
                />
                ข้อมูล ECO Sticker
              </Menu.Item>
              {/* </Link> */}
            </Responsive>

            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                {/* <Link to='/oie/entrepreneur'> */}
                <Menu.Item
                  name="entrepreneur"
                  active={this.props.match.path === "/oie/dashboard"}
                  // onClick={this.handleAdminMenuItemClick}
                  onClick={(_, val) => {
                    this.handleAdminMenuItemClick(_, val);
                    this.props.history.push("/oie/dashboard");
                  }}
                >
                  <Icon name="chart bar" />
                  Dashboard
                </Menu.Item>
                {/* </Link> */}
              </Responsive>
            {/* <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                <Menu.Item
                  name='รายการชำระเงิน'
                  active={this.props.match.path === '/approval/payment'}
                  onClick={this.handleAdminMenuItemClick}
                  style={{ marginTop: '20px' }}
                  onClick={()=>{
                    this.props.history.push('/approval/payment')
                  }}
                >
                  <h4>ตรวจสอบการชำระเงิน</h4>
                </Menu.Item>
            
            </Responsive> */}
            {/* <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              <Menu.Item
                name="ยื่นใบแจ้งหนี้"
                active={this.props.match.path === "/approval/submit_invoice"}
                onClick={this.handleAdminMenuItemClick}
                style={{ marginTop: "20px" }}
                onClick={() => {
                  this.props.history.push("/approval/submit_invoice");
                }}
              >
                <h4>ยื่นใบแจ้งหนี้</h4>
              </Menu.Item>
            </Responsive>  */}

            <Menu.Item position="right" />
            <Responsive
              maxWidth={Responsive.onlyTablet.maxWidth}
              style={{ marginTop: "20px" }}
            >
              <MobileHeader />
            </Responsive>

            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              <Menu.Item style={{ marginTop: "20px" }}>
                {/* <Icon name="user" /> */}
                <span>
                  {" "}
                  <Icon name="user" /> {localStorage.getItem("name")}
                </span>
              </Menu.Item>
            </Responsive>

            <Responsive
              minWidth={Responsive.onlyComputer.minWidth}
              floated="right"
            >
              <Menu.Item
                onClick={() => this.logout()}
                style={{ marginTop: "15px" }}
              >
                <Button basic>
                  <Icon name="sign-out" />
                  Logout
                </Button>
              </Menu.Item>
            </Responsive>
          </Menu>
        </React.Fragment>
        // </Sticky>
      );
    } else {
      if (
        window.location.href.includes("entrepreneur") ||
        window.location.href.includes("approval") ||
        window.location.href.includes("detailCompany")
      ) {
        window.location.href = "/oie";
      }
      // return <Redirect to='/oie' />;
      return (
        // <Sticky>
        <Menu
          secondary
          style={{
            background: "linear-gradient(45deg, yellow , orange)",
            height: "80px",
            marginBottom: "20px",
          }}
        >
          <Responsive minWidth={Responsive.onlyMobile.minWidth}>
            <Menu.Item>
              <Image
                src={"/imagelogin/logobg.png"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "110px",
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
                  borderTopRightRadius: 15,
                  borderTopLeftRadius: 15,
                }}
              />
            </Menu.Item>
          </Responsive>

          <Menu.Item position="right" />
          <Responsive
            maxWidth={Responsive.onlyTablet.maxWidth}
            style={{ marginTop: "20px" }}
          >
            <MobileHeader />
          </Responsive>

          <Responsive
            minWidth={Responsive.onlyComputer.minWidth}
            floated="right"
          >
            <Menu.Item
              onClick={() => this.props.history.push("/")}
              style={{ marginTop: "15px" }}
            >
              <Button basic>
                <Icon name="home" />
                กลับหน้าหลัก
              </Button>
            </Menu.Item>
          </Responsive>
        </Menu>
        // </Sticky>
      );
    }
  };
  render() {
    // if (!this.state.login) {
    //   window.location.href = '/oie';
    //   return <Redirect to='/oie' />;
    // }
    // const { active } = this.state;
    return <div width="100%">{this.navcheck()}</div>;
  }
}

export default withRouter(ApprovalHeader);
