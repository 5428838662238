const initialState = {
  refCode: "",
  companyId: "",
  factoryId: "",
  factoryTypeId: "",
  tyre_requests_name: "",

  productRemove: [],
  brandRemove: [],
  genRemove: [],
  sizeRemove: [],
  productPos: 0,
  productRequest: [
    {
      id: "",
      productId: "",
      productTypeId: "",
      productTypeStructId: "",
      productName: "",
      licenseType: null,
      licenseNumber: "",
      licenseNumberFile: { name: "", data: [], path: "" },
      listProduct: "",
      requestNO: '',
      brandsRequest: [
        {
          id: "",
          brandId: "",
          brandName: "",
          genRequest: [
            {
              id: "",
              genName: "",
              genLayoutFile: { name: "", data: [], path: "" },
              sizeRequest: [
                {
                  id: "",
                  sizeGen: "",
                  sizeCapacity: "",
                  sizeSpeedSymbol: "",
                  sizeSpecialFeature: "",
                  sizeStructure: "",
                  sizeTypeOfUse: "",
                  sizeCuttingWidth: "",
                  sizeSymbol: ""
                }
              ]
            }
          ]
        }
      ],
      testResult: {
        id: "",
        testName_a: "ค่าสัมประสิทธิ์ของความต้านทานการหมุน",
        testValue_a: "0",
        testUnit_a: "N/kN",
        brandName_a: "",
        genName_a: "",
        sizeGen_a: "",
        sizeSymbol_a: "",
        testPage_a: "",
        testFile_a: { name: "", data: [], path: "" },

        testName_b: "ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก",
        testValue_b: "0",
        testUnit_b: "G",
        brandName_b: "",
        genName_b: "",
        sizeGen_b: "",
        testPage_b: "",
        testFile_b: { name: "", data: [], path: "" },

        testName_c: "ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน",
        testValue_c: "0",
        testUnit_c: "dB(A)",
        brandName_c: "",
        genName_c: "",
        sizeGen_c: "",
        testPage_c: "",
        testFile_c: { name: "", data: [], path: "" },

        testName_d: "ค่าดัชนีการเกาะถนนหิมะ",
        testValue_d: "0",
        testUnit_d: "SG",
        brandName_d: "",
        genName_d: "",
        sizeGen_d: "",
        testPage_d: "",
        testFile_d: { name: "", data: [], path: "" }
      }
    }
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "fetchRequest":
      state = action.value;
      return state;

    default:
      return state;
  }
};
