import React, { Component, createRef } from 'react';
import { Table, Icon, Form, Button, Grid, Ref, Sticky, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
class personAuthorizePreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			indexForm: 0
		};
	}

	contextRef = createRef();
	showFile = (index) => this.setState({ showFile: true, indexForm: index });
	render() {
		return (
			<Grid>
				<Grid.Column computer={8} tablet={8}>
					<Ref innerRef={this.contextRef}>
						<Form.Field>
							<Table celled striped>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell colSpan='2' width='1'>
										<Header as='h4'>
											<Header.Content>
												<Icon name="user" />
												ข้อมูลผู้มีอำนาจลงนาม
											</Header.Content>
										</Header>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{this.props.form.map((form, indexForm) => (
										<React.Fragment>
											<Table.Row>
												<Table.Cell collapsing active colSpan='2'>
												<Header as='h5'>
													<Header.Content>
													ผู้มีอำนาจลงนามลำดับที่ {indexForm + 1}
													</Header.Content>
												</Header>													
												</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													สัญชาติ
													</Header.Content>
												</Header>	
												</Table.Cell>
												<Table.Cell>{form.nationality === 1 ? 'ไทย' : 'ต่างชาติ'}</Table.Cell>
											</Table.Row>
											<Table.Row>
												{form.nationality === 1 ? (
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													เลขประจำตัวประชาชน
													</Header.Content>
												</Header>
												</Table.Cell>
												) : (
													<Table.Cell>
														<Header as='h5'>
															<Header.Content>
															เลขที่หนังสือเดินทาง
															</Header.Content>
														</Header>
													</Table.Cell>
												)}
												<Table.Cell>{form.idNumber}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ชื่อภาษาไทย
													</Header.Content>
												</Header>	
												</Table.Cell>
												<Table.Cell>{form.nameTH}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ชื่อภาษาอังกฤษ
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.nameEN}</Table.Cell>
											</Table.Row>
											<Table.Row>
											<Table.Cell colSpan='3'>
												{/* <Icon name="point" /> */}
												<Header as='h5'>
												<Header.Content>
												ที่อยู่
												</Header.Content>
											</Header>
												
											</Table.Cell>
											</Table.Row>
											{form.nationality === 1 ? (
												<React.Fragment>
													<Table.Row>
														<Table.Cell>เลขที่</Table.Cell>
														<Table.Cell>{form.address_id}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>ซอย</Table.Cell>
														<Table.Cell>{form.alley}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>ถนน</Table.Cell>
														<Table.Cell>{form.road}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>ตำบล/แขวง</Table.Cell>
														<Table.Cell>{form.sub_district}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>อำเภอ/เขต</Table.Cell>
														<Table.Cell>{form.district}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>จังหวัด</Table.Cell>
														<Table.Cell>{form.province}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>รหัสไปรษณีย์</Table.Cell>
														<Table.Cell>{form.zip_code}</Table.Cell>
													</Table.Row>
												</React.Fragment>
											) : (
												<Table.Row>
													<Table.Cell>ที่อยู่</Table.Cell>
													<Table.Cell>{form.address}</Table.Cell>
												</Table.Row>
											)}

											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													เบอร์โทร
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.phoneNumber}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													e-mail
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.email}</Table.Cell>
											</Table.Row>
											<Table.Row>
											<Table.Cell colSpan='3'>
												{/* <Icon name="point" /> */}
												<Header as='h5'>
												<Header.Content>
												เอกสารแนบ
												</Header.Content>
											</Header>
												
											</Table.Cell>
											</Table.Row>
											<Table.Row>
												{form.nationality === 1 ? (
													<Table.Cell>
														<Icon name='file outline' /> สำเนาบัตรประจำตัวประชาชน
													</Table.Cell>
												) : (
													<Table.Cell>
														<Icon name='file outline' /> สำเนาหนังสือเดินทาง
													</Table.Cell>
												)}
												<Table.Cell>
													<Button
														size='small'
														basic
														floated='left'
														color='orange'
														onClick={() => this.showFile(indexForm)}
													>
														<Icon name='search' />
														ดูเอกสาร
													</Button>
												</Table.Cell>
											</Table.Row>
										</React.Fragment>
									))}
								</Table.Body>
							</Table>
						</Form.Field>
					</Ref>
				</Grid.Column>
				<Grid.Column computer={8} tablet={8}>
					{this.props.form[this.state.indexForm].file && (
						<Sticky context={this.contextRef} pushing>
							<Iframe
								url={URL.createObjectURL(this.props.form[this.state.indexForm].file)}
								width='100%'
								height='800px'
								display='initial'
								position='relative'
							/>
						</Sticky>
					)}
				</Grid.Column>
			</Grid>
		);
	}
}
const mapStateToProps = function(state){
	return {
		message: 'This is message from mapStateToProps',
		form: state.reduxxx.personAuthorized
	};
};

export default connect(mapStateToProps)(personAuthorizePreview);
