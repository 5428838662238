import React, { Component, createRef } from 'react';
import { Table, Icon, Form, Button, Grid, Ref, Sticky, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';

class productImportPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			indexForm: 0
		};
	}

	contextRef = createRef();
	showProductImport = (index) => this.setState({ showProductImport: true, showFile: false, indexForm: index });
	showFile = (index) => this.setState({ showFile: true, showProductImport: false, indexForm: index });
	render() {
		return (
			<Grid>
				<Grid.Column computer={8}>
					<Ref innerRef={this.contextRef}>
						<Form.Field>
							{this.props.form.map((form, indexForm) => (
								<Table celled striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell colSpan='2'>
											<Header as='h4'>
												<Header.Content>
												<Icon name="user" />
												ข้อมูลการนำเข้าโรงงานที่ {indexForm + 1}
												</Header.Content>
											</Header>												
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										<React.Fragment>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ชื่อโรงงาน
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.factory.nameFactory}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ใบรับรองระบบบริหารงานคุณภาพ
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.factory.certificate}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													วันหมดอายุใบรับรองระบบบริหารงานคุณภาพ
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.factory.expire_certificate}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													จาก
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.factory.from}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ภายใต้
													</Header.Content>
												</Header></Table.Cell>
												<Table.Cell>{form.factory.under}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ที่ตั้งโรงงาน
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.factory.location}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ประเทศ
													</Header.Content>
												</Header>
												</Table.Cell>
												<Table.Cell>{form.factory.country}</Table.Cell>
											</Table.Row>

											<Table.Row>
												<Table.Cell colSpan='2'>
													<Header as='h5'>
														<Header.Content>
														เอกสารแนบ
														</Header.Content>
													</Header>
												</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell>
													ใบรับรองระบบบริหารงานคุณภาพ
												</Table.Cell>
												<Table.Cell>
													<Button
														size='small'
														basic
														color='orange'
														style={{
															marginTop: 10,
															display: 'flex',
															justifyContens: 'flex-end'
														}}
														onClick={() => this.showFile(indexForm)}
													>
														<Icon name='search' />
														ดูเอกสาร
													</Button>
												</Table.Cell>
											</Table.Row>
											{form.productImport.map((productImport, indexProduct) => (
												<React.Fragment>
													<Table.Row>
														<Table.Cell collapsing active colSpan='2'>
														<Header as='h5'>
															<Header.Content>
															ข้อมูลผลิตภัณฑ์ที่ {indexProduct + 1}
															</Header.Content>
														</Header>															
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell collapsing>
															<Header as='h5'>
																<Header.Content>
																ผลิตภัณฑ์
																</Header.Content>
															</Header>
														</Table.Cell>
														<Table.Cell>{productImport.productType}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell collapsing>
														<Header as='h5'>
																<Header.Content>
																โครงสร้าง
																</Header.Content>
															</Header>
															</Table.Cell>
														<Table.Cell>{productImport.productStructure}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell collapsing>
														<Header as='h5'>
																<Header.Content>
																	ปริมาณการนำเข้า (เส้น/ปี)
																</Header.Content>
															</Header>
															</Table.Cell>
														<Table.Cell>{productImport.importCapacity}</Table.Cell>
													</Table.Row>

													{this.props.form[indexForm].productImport[
														indexProduct
													].productName.map((productName, index) => (
														<Table.Row>
															<Table.Cell collapsing>
															<Header as='h5'>
																<Header.Content>
																ชื่อทางการค้าที่ {index + 1}
																</Header.Content>
															</Header>																
															</Table.Cell>
															<Table.Cell>{productName.name}</Table.Cell>
														</Table.Row>
													))}
												</React.Fragment>
											))}
											{form.exportPerson.map((exportPerson, indexPerson) => {
												return (
													<React.Fragment>
														<Table.Row>
															<Table.Cell collapsing active colSpan='2'>
															<Header as='h5'>
																<Header.Content>
																ข้อมูลผู้ส่งออก
																</Header.Content>
															</Header>	
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell collapsing>
															<Header as='h5'>
																<Header.Content>
																ผู้ส่งออกคนที่ {indexPerson + 1}
																</Header.Content>
															</Header>																	
															</Table.Cell>
															<Table.Cell>{exportPerson.exportPersonName}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell collapsing>
															<Header as='h5'>
																<Header.Content>
																ประเทศ
																</Header.Content>
															</Header>
															</Table.Cell>
															<Table.Cell>{exportPerson.exportPersonCountry}</Table.Cell>
														</Table.Row>
													</React.Fragment>
												);
											})}
											<Table.Row>
												<Table.Cell collapsing active colSpan='2'>
												<Header as='h5'>
													<Header.Content>
													สถานที่เก็บสินค้าในประเทศไทย
													</Header.Content>
												</Header>
												</Table.Cell>
											</Table.Row>
											{form.warehouse.map((warehouse, index) => (
												<React.Fragment>
													<Table.Row>
														<Table.Cell collapsing colSpan='2'>
														<Header as='h5'>
															<Header.Content>
															สถานที่เก็บสินค้าลำดับที่ {index + 1}
															</Header.Content>
														</Header>															
														</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>เลขที่</Table.Cell>
														<Table.Cell>{warehouse.address_id}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>ซอย</Table.Cell>
														<Table.Cell>{warehouse.alley}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>ถนน</Table.Cell>
														<Table.Cell>{warehouse.road}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>ตำบล/แขวง</Table.Cell>
														<Table.Cell>{warehouse.sub_district}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>อำเภอ/เขต</Table.Cell>
														<Table.Cell>{warehouse.district}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>จังหวัด</Table.Cell>
														<Table.Cell>{warehouse.province}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell>รหัสไปรษณีย์</Table.Cell>
														<Table.Cell>{warehouse.zip_code}</Table.Cell>
													</Table.Row>
												</React.Fragment>
											))}
										</React.Fragment>
									</Table.Body>
								</Table>
							))}
						</Form.Field>
					</Ref>
				</Grid.Column>
				<Grid.Column computer={8}>
					{this.props.form[this.state.indexForm].factory.file_doc && (
						<Sticky context={this.contextRef} pushing>
							<Iframe
								url={URL.createObjectURL(this.props.form[this.state.indexForm].factory.file_doc)}
								width='100%'
								height='800px'
								display='initial'
								position='relative'
							/>
						</Sticky>
					)}
				</Grid.Column>
			</Grid>
		);
	}
}
const mapStateToProps = function(state){
	return {
		message: 'This is message from mapStateToProps',
		form: state.reduxxx.formProductImport
	};
};

export default connect(mapStateToProps)(productImportPreview);
