import React, { Component } from "react";

import {
  Container,
  Segment,
  Header,
  Icon,
  Form,
  Message,
  Grid,
  Input,
  Checkbox,
  Label,

} from "semantic-ui-react";
import Headers from "../header/requestHeader";
import Requestbutton from "./requestButton";
import { connect } from "react-redux"; // connect redux

const theme_color_1 = "violet";

class requestTest extends Component {
  componentWillMount = async () => {

    await this.setState(this.props.requestRedux);

    this.componentWillReceiveProps = (nextProps) => {
      this.setState(nextProps.requestRedux);

      document.getElementById("headPage").scrollIntoView({
        behavior: "smooth"
      });

    }
    this.setState({
      currentPage: "test"
    });


    // this.createOption();
  };

  render() {
    var productPos = this.state.productPos;
    var product = this.state.productRequest[productPos];

    let tmp = this.state.productRequest;

    // console.log("render test", product.testResult);
    return (
      <div>
        {this.state.params_request_id === undefined &&
          this.state.params_company_id === undefined ? (
            <Headers />
          ) : (
            <span />
          )}

        <Container id="headPage" style={{ margin: 30 }}>
          {/* productPos = {productPos} */}
          <br />

          <Form error={product.testResult.formError}>
            {(() => {
              if (this.state.draftName) {
                return (
                  <Segment style={{ margin: 0 }} compact>
                    <h2>ฉบับร่าง: {this.state.draftName}</h2>
                  </Segment>
                );
              } else {
                return <span />;
              }
            })()}
            <Segment padded="very" color={theme_color_1}>
              <Header>
                <h1>รายละเอียดของยางที่ใช้ในการทดสอบและผลการทดสอบ</h1>
              </Header>

              <Message
                error
                header="กรอกข้อมูลไม่ครบถ้วน"
                content="กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง"
              />

              {/* ======================================================================================================================================== */}

              {/* 1.การทดสอบความต้านทานการหมุน */}

              <Segment>
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <h3>1.การทดสอบความต้านทานการหมุน</h3>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 1. ชื่อทางการค้า */}
                    <Grid.Column computer={6} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.brandName_a_error}>
                        {/* <label id={"brandName_a_" + productPos}>
                          ชื่อทางการค้า
                        </label> */}
                        <label>ชื่อทางการค้า</label>

                        <Input
                          id={"brandName_a_" + productPos}
                          placeholder="กรอกชื่อทางการค้า"
                          value={product.testResult.brandName_a.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.brandName_a = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกชื่อทางการค้า"
                          options={product.testResult.brandNameOption_a || []}
                          value={product.testResult.brandName_a}
                          onChange={async (e, { value }) => {
                            product.testResult.brandNameOption_a.map(
                              (brand_option, index_brand_option) => {
                                if (brand_option.value === value) {
                                  product.testResult.genNameOption_a =
                                    brand_option.gen_name_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.brandName_a = value;
                            product.testResult.genName_a = "";

                            product.testResult.sizeGenOption_a = [];
                            product.testResult.sizeGen_a = "";

                            product.testResult.disabled_a_input_1 = false;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 1. รุ่น  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.genName_a_error}>
                        {/* <label id={"genName_a_" + productPos}> รุ่น</label> */}
                        <label> ชื่อรุ่น</label>
                        <Input
                          id={"genName_a_" + productPos}
                          placeholder="กรอกชื่อรุ่น"
                          value={product.testResult.genName_a.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.genName_a = value.toUpperCase();
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          disabled={product.testResult.disabled_a_input_1}
                          placeholder="เลือกรุ่น"
                          options={product.testResult.genNameOption_a || []}
                          value={product.testResult.genName_a}
                          onChange={async (e, { value }) => {
                            product.testResult.genNameOption_a.map(
                              (gen_option, index_gen_option) => {
                                if (gen_option.value === value) {
                                  product.testResult.sizeGenOption_a =
                                    gen_option.size_gen_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.genName_a = value;
                            product.testResult.sizeGen_a = "";

                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 1. รายการ  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.sizeGen_a_error}>
                        {/* <label id={"sizeGen_a_" + productPos}> รายการ</label> */}
                        <label> ขนาด</label>

                        <Input
                          id={"sizeGen_a_" + productPos}
                          placeholder="กรอกขนาด"
                          value={product.testResult.sizeGen_a.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.sizeGen_a = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกรายการ"
                          options={product.testResult.sizeGenOption_a || []}
                          value={product.testResult.sizeGen_a}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.sizeGen_a = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 1. ค่าสัมประสิทธิ์ของความต้านทานการหมุน */}
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.testValue_a_error}>
                        <h4>
                          ค่าสัมประสิทธิ์ของความต้านทานการหมุน
                          <span style={{ color: "red" }}>*</span>
                           {/* {(product.productName.includes("ผ้าใบเฉียง") && product.productTypeId === "3") &&
                            (
                              <span style={{ color: "red" }}>*</span>
                            )
                          } */}
                        </h4>

                        <Input
                          id={"testValue_a_" + productPos}
                          type="number"
                          min={0}
                          max={12}
                          label={{
                            basic: true,
                            content: product.testResult.testUnit_a
                          }}
                          labelPosition="right"
                          placeholder="ค่าสัมประสิทธิ์ของความต้านทานการหมุน"
                          value={product.testResult.testValue_a}
                          onChange={async (e, { value }) => {
                            // // console.log(typeof value);
                            let parseNumber = parseFloat(value);
                            // console.log(parseNumber);
                            if (Number.isNaN(parseNumber)) {
                              // // console.log("in NanN");
                              tmp[productPos].testResult.testValue_a = "";
                              await this.setState({
                                productRequest: tmp
                              });
                            }

                            if (product.productTypeId === "1") {
                              if (parseNumber >= 0 && parseNumber <= 12) {
                                tmp[productPos].testResult.testValue_a = value;
                                await this.setState({
                                  productRequest: tmp
                                });
                              }
                            } else if (product.productTypeId === "2") {
                              if (parseNumber >= 0 && parseNumber <= 11.5) {
                                tmp[productPos].testResult.testValue_a = value;
                                await this.setState({
                                  productRequest: tmp
                                });
                              }
                            }
                            else if (product.productTypeId === "3") {
                              // if (product.productId === "9f27091f-133d-4007-b87d-4d2c9ed58540") {
                              if (parseNumber >= 0 && parseNumber <= 8.0) {
                                tmp[productPos].testResult.testValue_a = value;
                                await this.setState({
                                  productRequest: tmp
                                });
                                // tmp[productPos].testResult.testValue_a = value;
                                // await this.setState({
                                //   productRequest: tmp
                                // });
                              }
                            }
                            // // console.log(
                            //   "in state ",
                            //   tmp[productPos].testResult.testValue_a
                            // );
                          }}
                        />

                        {(product.productName.includes("ผ้าใบเฉียง") && product.productTypeId === "3") &&
                          (<p style={{ color: "#db2828" }}>
                            *หมายเหตุ: หากไม่มีผลการทดสอบให้กรอกเป็นเลขศูนย์
                        </p>)
                        }
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 1. เอกสารการทดสอบความต้านทานการหมุน */}
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field>
                        <h4 id={"testFile_a_" + productPos}>
                          เอกสารการทดสอบความต้านทานการหมุน
                        </h4>
                        <Segment>
                          <Label
                            attached="top"
                            color={
                              product.testResult.testFile_a_error
                                ? "red"
                                : "violet"
                            }
                            as="label"
                            htmlFor={"testFile_a_upload_" + productPos}
                            size="large"
                          >
                            <Icon name="upload" />
                            เลือกไฟล์
                            <input
                              hidden
                              id={"testFile_a_upload_" + productPos}
                              type="file"
                              accept=".pdf"
                              onChange={async e => {
                                if (e.target.files[0]) {
                                  if (e.target.files.size > 10000000) {
                                    alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                    return;
                                  }

                                  var src = URL.createObjectURL(
                                    e.target.files[0]
                                  );

                                  product.testResult.testFile_a.name =
                                    e.target.files[0].name;

                                  product.testResult.testFile_a.data =
                                    e.target.files[0];

                                  product.testResult.testFile_a.path = src;

                                  product.testResult.testFile_a_error = false;

                                  await this.setState({
                                    productRequest: tmp
                                  });
                                }
                              }}
                            />
                          </Label>
                          <p>
                            {product.testResult.testFile_a.name}
                            &nbsp; &nbsp;
                            {product.testResult.testFile_a.name ? (
                              <Icon
                                color="red"
                                size="large"
                                name="times circle outline"
                                link
                                onClick={async e => {
                                  product.testResult.testFile_a = {
                                    name: "",
                                    data: "",
                                    path: ""
                                  };

                                  tmp[productPos].testFile_a_error = true;

                                  await this.setState({
                                    productRequest: tmp
                                  });

                                  document.getElementById(
                                    "testFile_a_upload_" + productPos
                                  ).value = null;
                                }}
                              />
                            ) : null}
                          </p>
                        </Segment>
                        <p
                          style={{
                            color: "#db2828"
                          }}
                        >
                          ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                        </p>
                      </Form.Field>
                    </Grid.Column>

                    {/* 1. หมายเลขหน้าที่แสดงผลการทดสอบทั้ง */}
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field error={product.testResult.testPage_a_error}>
                        <h4>
                          หมายเลขหน้าที่แสดงผลการทดสอบความต้านทานการหมุน
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h4>
                        <Input
                          id={"testPage_a_" + productPos}
                          placeholder="หมายเลขหน้าที่"
                          value={product.testResult.testPage_a}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.testPage_a = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              {/* ======================================================================================================================================== */}

              {/* 2.การทดสอบการยึดเกาะถนนบนพื้นเปียก */}
              <Segment>
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <h3>2.การทดสอบการยึดเกาะถนนบนพื้นเปียก </h3>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* Checkbox Coppy 1 */}
                    <Grid.Column computer={4} mobile={16} tablet={8}>
                      <Checkbox
                        disabled={product.testResult.disabled_b_1}
                        checked={product.testResult.checked_b_1}
                        ref="check_b_1"
                        label="ใช้ยางรายการเดียวกับข้อ 1."
                        onChange={async (e, { value }) => {
                          if (!this.refs.check_b_1.state.checked) {
                            // Coppy Value
                            product.testResult.brandName_b =
                              product.testResult.brandName_a;
                            product.testResult.genName_b =
                              product.testResult.genName_a;
                            product.testResult.sizeGen_b =
                              product.testResult.sizeGen_a;

                            // Coppy Options
                            // product.testResult.brandNameOption_b =
                            //   product.testResult.brandNameOption_a;
                            // product.testResult.genNameOption_b =
                            //   product.testResult.genNameOption_a;
                            // product.testResult.sizeGenOption_b =
                            //   product.testResult.sizeGenOption_a;

                            // disabled other
                            product.testResult.disabled_b_2 = true;
                            product.testResult.disabled_b_3 = true;
                            product.testResult.disabled_b_4 = true;

                            // checked
                            product.testResult.checked_b_1 = true;

                            await this.setState({
                              productRequest: tmp
                            });
                          } else {
                            // enable other
                            product.testResult.disabled_b_2 = false;
                            product.testResult.disabled_b_3 = false;
                            product.testResult.disabled_b_4 = false;

                            // uncheck
                            product.testResult.checked_b_1 = false;
                            await this.setState({
                              productRequest: tmp
                            });
                          }
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 2. ชื่อทางการค้า */}
                    <Grid.Column computer={6} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.brandName_b_error}>
                        {/* <label id={"brandName_b_" + productPos}>
                          ชื่อทางการค้า
                        </label> */}
                        <label>ชื่อทางการค้า</label>

                        <Input
                          id={"brandName_b_" + productPos}
                          placeholder="กรอกชื่อทางการค้า"
                          value={product.testResult.brandName_b.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.brandName_b = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />
                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกชื่อทางการค้า"
                          options={product.testResult.brandNameOption_b || []}
                          value={product.testResult.brandName_b}
                          onChange={async (e, { value }) => {
                            product.testResult.brandNameOption_b.map(
                              (brand_option, index_brand_option) => {
                                if (brand_option.value === value) {
                                  product.testResult.genNameOption_b =
                                    brand_option.gen_name_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.brandName_b = value;
                            product.testResult.genName_b = "";

                            product.testResult.sizeGenOption_b = [];
                            product.testResult.sizeGen_b = "";

                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 2. รุ่น  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.genName_b_error}>
                        {/* <label id={"genName_b_" + productPos}> รุ่น</label> */}
                        <label> ชื่อรุ่น</label>
                        <Input
                          id={"genName_b_" + productPos}
                          placeholder="กรอกชื่อรุ่น"
                          value={product.testResult.genName_b.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.genName_b = value.toUpperCase();
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกรุ่น"
                          options={product.testResult.genNameOption_b || []}
                          value={product.testResult.genName_b}
                          onChange={async (e, { value }) => {
                            product.testResult.genNameOption_b.map(
                              (gen_option, index_gen_option) => {
                                if (gen_option.value === value) {
                                  product.testResult.sizeGenOption_b =
                                    gen_option.size_gen_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.genName_b = value;
                            product.testResult.sizeGen_b = "";

                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 2. รายการ  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.sizeGen_b_error}>
                        {/* <label id={"sizeGen_b_" + productPos}> รายการ</label> */}
                        <label> ขนาด</label>

                        <Input
                          id={"sizeGen_b_" + productPos}
                          placeholder="กรอกขนาด"
                          value={product.testResult.sizeGen_b.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.sizeGen_b = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกรายการ"
                          options={product.testResult.sizeGenOption_b || []}
                          value={product.testResult.sizeGen_b}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.sizeGen_b = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 2. ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก */}
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.testValue_b_error}>
                        <h4>
                          ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h4>
                        <Input
                          id={"testValue_b_" + productPos}
                          type="number"
                          step="0.01"
                          label={{
                            basic: true,
                            content: product.testResult.testUnit_b
                          }}
                          labelPosition="right"
                          placeholder="ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก"
                          value={product.testResult.testValue_b}
                          onBlur={(e) => {
                            tmp[productPos].testResult.testValue_b = parseFloat(e.target.value).toFixed(2);
                            this.setState({
                              productRequest: tmp
                            });
                            
                          }}

                          onChange={(e, { value }) => {
                            var regex = /^\d+(\.\d{1,2})?$/;
                            
                            // this.value = parseFloat(this.value);
                            let testValue = value
                            if (!regex.test(value)) {
                              testValue = value.substring(0, value.length - 1);
                             
                              testValue = parseFloat(testValue).toFixed(2);
                            }
                            tmp[productPos].testResult.testValue_b = testValue;
                            this.setState({
                              productRequest: tmp
                            });

                           
                          }}
                        />
                        {/* <p style={{ color: "#db2828" }}>
                          *หมายเหต: หากไม่มีผลการทดสอบให้กรอกเป็นเลขศูนย์
                        </p> */}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 2. เอกสารการทดสอบการยึดเกาะถนนบนพื้นเปียก*/}
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field>
                        <h4 id={"testFile_b_" + productPos}>
                          เอกสารการทดสอบการยึดเกาะถนนบนพื้นเปียก
                        </h4>
                        <Segment>
                          <Label
                            attached="top"
                            color={
                              product.testResult.testFile_b_error
                                ? "red"
                                : "violet"
                            }
                            as="label"
                            htmlFor={"testFile_b_upload_" + productPos}
                            size="large"
                          >
                            <Icon name="upload" />
                            เลือกไฟล์
                            <input
                              hidden
                              id={"testFile_b_upload_" + productPos}
                              type="file"
                              accept=".pdf"
                              onChange={async e => {
                                if (e.target.files[0]) {
                                  if (e.target.files.size > 10000000) {
                                    alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                    return;
                                  }

                                  var src = URL.createObjectURL(
                                    e.target.files[0]
                                  );

                                  product.testResult.testFile_b.name =
                                    e.target.files[0].name;

                                  product.testResult.testFile_b.data =
                                    e.target.files[0];

                                  product.testResult.testFile_b.path = src;

                                  product.testResult.testFile_b_error = false;

                                  await this.setState({
                                    productRequest: tmp
                                  });
                                }
                              }}
                            />
                          </Label>
                          <p>
                            {product.testResult.testFile_b.name}
                            &nbsp; &nbsp;
                            {product.testResult.testFile_b.name ? (
                              <Icon
                                color="red"
                                size="large"
                                name="times circle outline"
                                link
                                onClick={async e => {
                                  product.testResult.testFile_b = {
                                    name: "",
                                    data: "",
                                    path: ""
                                  };

                                  tmp[productPos].testFile_b_error = true;

                                  await this.setState({
                                    productRequest: tmp
                                  });

                                  document.getElementById(
                                    "testFile_b_upload_" + productPos
                                  ).value = null;
                                }}
                              />
                            ) : null}
                          </p>
                        </Segment>
                        <p
                          style={{
                            color: "#db2828"
                          }}
                        >
                          ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                        </p>
                      </Form.Field>
                    </Grid.Column>

                    {/* 2. หมายเลขหน้าที่แสดงผลการทดสอบทั้ง */}

                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field error={product.testResult.testPage_b_error}>
                        <h4>
                          หมายเลขหน้าที่แสดงผลการทดสอบการยึดเกาะถนนบนพื้นเปียก
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h4>
                        <Input
                          id={"testPage_b_" + productPos}
                          placeholder="หมายเลขหน้าที่"
                          value={product.testResult.testPage_b}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.testPage_b = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              {/* ======================================================================================================================================== */}

              {/* 3.การทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน */}
              <Segment>
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <h3>3.การทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน </h3>
                    </Grid.Column>
                  </Grid.Row>

                  {/* Checkbox */}
                  <Grid.Row>
                    {/* Checkbox Coppy 1 */}
                    <Grid.Column computer={4} mobile={16} tablet={8}>
                      <Checkbox
                        disabled={product.testResult.disabled_c_1}
                        checked={product.testResult.checked_c_1}
                        ref="check_c_1"
                        label="ใช้ยางรายการเดียวกับข้อ 1."
                        onChange={async (e, { value }) => {
                          if (!this.refs.check_c_1.state.checked) {
                            // Coppy Value
                            product.testResult.brandName_c =
                              product.testResult.brandName_a;
                            product.testResult.genName_c =
                              product.testResult.genName_a;
                            product.testResult.sizeGen_c =
                              product.testResult.sizeGen_a;

                            // Coppy Options
                            // product.testResult.brandNameOption_c =
                            //   product.testResult.brandNameOption_a;
                            // product.testResult.genNameOption_c =
                            //   product.testResult.genNameOption_a;
                            // product.testResult.sizeGenOption_c =
                            //   product.testResult.sizeGenOption_a;

                            // disabled other
                            product.testResult.disabled_c_2 = true;
                            product.testResult.disabled_c_3 = true;
                            product.testResult.disabled_c_4 = true;

                            // checked
                            product.testResult.checked_c_1 = true;

                            await this.setState({
                              productRequest: tmp
                            });
                          } else {
                            // enable other
                            product.testResult.disabled_c_2 = false;
                            product.testResult.disabled_c_3 = false;
                            product.testResult.disabled_c_4 = false;

                            // uncheck
                            product.testResult.checked_c_1 = false;

                            await this.setState({
                              productRequest: tmp
                            });
                          }
                        }}
                      />
                    </Grid.Column>

                    {/* Checkbox Coppy 2 */}
                    <Grid.Column computer={4} mobile={16} tablet={8}>
                      <Checkbox
                        disabled={product.testResult.disabled_c_2}
                        checked={product.testResult.checked_c_2}
                        ref="check_c_2"
                        label="ใช้ยางรายการเดียวกับข้อ 2."
                        onChange={async (e, { value }) => {
                          if (!this.refs.check_c_2.state.checked) {
                            // Coppy Value
                            product.testResult.brandName_c =
                              product.testResult.brandName_b;
                            product.testResult.genName_c =
                              product.testResult.genName_b;
                            product.testResult.sizeGen_c =
                              product.testResult.sizeGen_b;

                            // Coppy Options
                            // product.testResult.brandNameOption_c =
                            //   product.testResult.brandNameOption_b;
                            // product.testResult.genNameOption_c =
                            //   product.testResult.genNameOption_b;
                            // product.testResult.sizeGenOption_c =
                            //   product.testResult.sizeGenOption_b;

                            // disabled other
                            product.testResult.disabled_c_1 = true;
                            product.testResult.disabled_c_3 = true;
                            product.testResult.disabled_c_4 = true;

                            // checked
                            product.testResult.checked_c_2 = true;

                            await this.setState({
                              productRequest: tmp
                            });
                          } else {
                            // enable other
                            product.testResult.disabled_c_1 = false;
                            product.testResult.disabled_c_3 = false;
                            product.testResult.disabled_c_4 = false;

                            // uncheck
                            product.testResult.checked_c_2 = false;

                            await this.setState({
                              productRequest: tmp
                            });
                          }
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 3. ชื่อทางการค้า */}
                    <Grid.Column computer={6} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.brandName_c_error}>
                        {/* <label id={"brandName_c_" + productPos}>
                          ชื่อทางการค้า
                        </label> */}

                        <label>ชื่อทางการค้า</label>

                        <Input
                          id={"brandName_c_" + productPos}
                          placeholder="กรอกชื่อทางการค้า"
                          value={product.testResult.brandName_c.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.brandName_c = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกชื่อทางการค้า"
                          options={product.testResult.brandNameOption_c || []}
                          value={product.testResult.brandName_c}
                          onChange={async (e, { value }) => {
                            product.testResult.brandNameOption_c.map(
                              (brand_option, index_brand_option) => {
                                if (brand_option.value === value) {
                                  product.testResult.genNameOption_c =
                                    brand_option.gen_name_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.brandName_c = value;
                            product.testResult.genName_c = "";

                            product.testResult.sizeGenOption_c = [];
                            product.testResult.sizeGen_c = "";

                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 3. รุ่น  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.genName_c_error}>
                        {/* <label id={"genName_c_" + productPos}> รุ่น</label> */}
                        <label> ชื่อรุ่น</label>
                        <Input
                          id={"genName_c_" + productPos}
                          placeholder="กรอกชื่อรุ่น"
                          value={product.testResult.genName_c.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.genName_c = value.toUpperCase();
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกรุ่น"
                          options={product.testResult.genNameOption_c || []}
                          value={product.testResult.genName_c}
                          onChange={async (e, { value }) => {
                            product.testResult.genNameOption_c.map(
                              (gen_option, index_gen_option) => {
                                if (gen_option.value === value) {
                                  product.testResult.sizeGenOption_c =
                                    gen_option.size_gen_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.genName_c = value;
                            product.testResult.sizeGen_c = "";

                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 3. รายการ  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.sizeGen_c_error}>
                        {/* <label id={"sizeGen_c_" + productPos}> รายการ</label> */}
                        <label> ขนาด</label>

                        <Input
                          id={"sizeGen_c_" + productPos}
                          placeholder="กรอกขนาด"
                          value={product.testResult.sizeGen_c.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.sizeGen_c = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกรายการ"
                          options={product.testResult.sizeGenOption_c || []}
                          value={product.testResult.sizeGen_c}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.sizeGen_c = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 3. ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน */}
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.testValue_c_error}>
                        <h4>
                          ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน
                          {/* {(product.productName.includes("ผ้าใบเฉียง") && product.productTypeId === "3") &&
                            ( */}
                              <span style={{ color: "red" }}>*</span>
                            {/* )
                          } */}
                        </h4>
                        <Input
                          id={"testValue_c_" + productPos}
                          type="number"
                          label={{
                            basic: true,
                            content: product.testResult.testUnit_c
                          }}
                          labelPosition="right"
                          placeholder="ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน"
                          value={product.testResult.testValue_c}
                          onChange={async (e, { value }) => {
                            // if (value.includes(".")) {
                            //   tmp[productPos].testResult.testValue_c = "";
                            // } else {
                            //   tmp[productPos].testResult.testValue_c = value;
                            // }
                            tmp[productPos].testResult.testValue_c = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />
                        {(product.productName.includes("ผ้าใบเฉียง") && product.productTypeId === "3") &&
                          (
                            <p style={{ color: "#db2828" }}>
                              *หมายเหตุ: หากไม่มีผลการทดสอบให้กรอกเป็นเลขศูนย์
                        </p>
                          )
                        }
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 3. เอกสารการทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน */}
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field>
                        <h4 id={"testFile_c_" + productPos}>
                          เอกสารการทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน
                        </h4>
                        <Segment>
                          <Label
                            attached="top"
                            color={
                              product.testResult.testFile_c_error
                                ? "red"
                                : "violet"
                            }
                            as="label"
                            htmlFor={"testFile_c_upload_" + productPos}
                            size="large"
                          >
                            <Icon name="upload" />
                            เลือกไฟล์
                            <input
                              hidden
                              id={"testFile_c_upload_" + productPos}
                              type="file"
                              accept=".pdf"
                              onChange={async e => {
                                if (e.target.files[0]) {
                                  if (e.target.files.size > 10000000) {
                                    alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                    return;
                                  }

                                  var src = URL.createObjectURL(
                                    e.target.files[0]
                                  );

                                  product.testResult.testFile_c.name =
                                    e.target.files[0].name;

                                  product.testResult.testFile_c.data =
                                    e.target.files[0];

                                  product.testResult.testFile_c.path = src;

                                  product.testResult.testFile_c_error = false;

                                  await this.setState({
                                    productRequest: tmp
                                  });
                                }
                              }}
                            />
                          </Label>
                          <p>
                            {product.testResult.testFile_c.name}
                            &nbsp; &nbsp;
                            {product.testResult.testFile_c.name ? (
                              <Icon
                                color="red"
                                size="large"
                                name="times circle outline"
                                link
                                onClick={async e => {
                                  product.testResult.testFile_c = {
                                    name: "",
                                    data: "",
                                    path: ""
                                  };

                                  tmp[productPos].testFile_c_error = true;

                                  await this.setState({
                                    productRequest: tmp
                                  });

                                  document.getElementById(
                                    "testFile_c_upload_" + productPos
                                  ).value = null;
                                }}
                              />
                            ) : null}
                          </p>
                        </Segment>
                        <p
                          style={{
                            color: "#db2828"
                          }}
                        >
                          ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                        </p>
                      </Form.Field>
                    </Grid.Column>

                    {/* 3. หมายเลขหน้าที่แสดงผลการทดสอบทั้ง */}

                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field error={product.testResult.testPage_c_error}>
                        <h4>
                          หมายเลขหน้าที่แสดงผลการทดสอบระดับเสียงจากยางล้อที่สัมผัสผิวถนน
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h4>
                        <Input
                          id={"testPage_c_" + productPos}
                          placeholder="หมายเลขหน้าที่"
                          value={product.testResult.testPage_c}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.testPage_c = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              {/* ======================================================================================================================================== */}

              {/* 4.การทดสอบการเกาะถนนหิมะ */}
              <Segment>
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <h3>4.การทดสอบการเกาะถนนหิมะ </h3>
                    </Grid.Column>
                  </Grid.Row>

                  {/* Checkbox */}
                  <Grid.Row>
                    {/* Checkbox Coppy 1 */}
                    <Grid.Column computer={4} mobile={16} tablet={8}>
                      <Checkbox
                        disabled={product.testResult.disabled_d_1}
                        checked={product.testResult.checked_d_1}
                        ref="check_d_1"
                        label="ใช้ยางรายการเดียวกับข้อ 1."
                        onChange={async (e, { value }) => {
                          if (!this.refs.check_d_1.state.checked) {
                            // Coppy Value
                            product.testResult.brandName_d =
                              product.testResult.brandName_a;
                            product.testResult.genName_d =
                              product.testResult.genName_a;
                            product.testResult.sizeGen_d =
                              product.testResult.sizeGen_a;

                            // Coppy Options
                            // product.testResult.brandNameOption_d =
                            //   product.testResult.brandNameOption_a;
                            // product.testResult.genNameOption_d =
                            //   product.testResult.genNameOption_a;
                            // product.testResult.sizeGenOption_d =
                            //   product.testResult.sizeGenOption_a;

                            // disabled other
                            product.testResult.disabled_d_2 = true;
                            product.testResult.disabled_d_3 = true;
                            product.testResult.disabled_d_4 = true;

                            // checked
                            product.testResult.checked_d_1 = true;

                            await this.setState({
                              productRequest: tmp
                            });
                          } else {
                            // enable other
                            product.testResult.disabled_d_2 = false;
                            product.testResult.disabled_d_3 = false;
                            product.testResult.disabled_d_4 = false;

                            // uncheck
                            product.testResult.checked_d_1 = false;

                            await this.setState({
                              productRequest: tmp
                            });
                          }
                        }}
                      />
                    </Grid.Column>

                    {/* Checkbox Coppy 2 */}
                    <Grid.Column computer={4} mobile={16} tablet={8}>
                      <Checkbox
                        disabled={product.testResult.disabled_d_2}
                        checked={product.testResult.checked_d_2}
                        ref="check_d_2"
                        label="ใช้ยางรายการเดียวกับข้อ 2."
                        onChange={async (e, { value }) => {
                          if (!this.refs.check_d_2.state.checked) {
                            // Coppy Value
                            product.testResult.brandName_d =
                              product.testResult.brandName_b;
                            product.testResult.genName_d =
                              product.testResult.genName_b;
                            product.testResult.sizeGen_d =
                              product.testResult.sizeGen_b;

                            // Coppy Options
                            // product.testResult.brandNameOption_d =
                            //   product.testResult.brandNameOption_b;
                            // product.testResult.genNameOption_d =
                            //   product.testResult.genNameOption_b;
                            // product.testResult.sizeGenOption_d =
                            //   product.testResult.sizeGenOption_b;

                            // disabled other
                            product.testResult.disabled_d_1 = true;
                            product.testResult.disabled_d_3 = true;
                            product.testResult.disabled_d_4 = true;

                            // checked
                            product.testResult.checked_d_2 = true;

                            await this.setState({
                              productRequest: tmp
                            });
                          } else {
                            // enable other
                            product.testResult.disabled_d_1 = false;
                            product.testResult.disabled_d_3 = false;
                            product.testResult.disabled_d_4 = false;

                            // uncheck
                            product.testResult.checked_d_2 = false;

                            await this.setState({
                              productRequest: tmp
                            });
                          }
                        }}
                      />
                    </Grid.Column>

                    {/* Checkbox Coppy 3 */}
                    <Grid.Column computer={4} mobile={16} tablet={8}>
                      <Checkbox
                        disabled={product.testResult.disabled_d_3}
                        checked={product.testResult.checked_d_3}
                        ref="check_d_3"
                        label="ใช้ยางรายการเดียวกับข้อ 3."
                        onChange={async (e, { value }) => {
                          if (!this.refs.check_d_3.state.checked) {
                            // Coppy Value
                            product.testResult.brandName_d =
                              product.testResult.brandName_c;
                            product.testResult.genName_d =
                              product.testResult.genName_c;
                            product.testResult.sizeGen_d =
                              product.testResult.sizeGen_c;

                            // Coppy Options
                            // product.testResult.brandNameOption_d =
                            //   product.testResult.brandNameOption_c;
                            // product.testResult.genNameOption_d =
                            //   product.testResult.genNameOption_c;
                            // product.testResult.sizeGenOption_d =
                            //   product.testResult.sizeGenOption_c;

                            // disabled other
                            product.testResult.disabled_d_1 = true;
                            product.testResult.disabled_d_2 = true;
                            product.testResult.disabled_d_4 = true;

                            // checked
                            product.testResult.checked_d_3 = true;

                            await this.setState({
                              productRequest: tmp
                            });
                          } else {
                            // enable other
                            product.testResult.disabled_d_1 = false;
                            product.testResult.disabled_d_2 = false;
                            product.testResult.disabled_d_4 = false;

                            // uncheck
                            product.testResult.checked_d_3 = false;

                            await this.setState({
                              productRequest: tmp
                            });
                          }
                        }}
                      />
                    </Grid.Column>

                    {/* Checkbox Coppy 4 */}
                    {/* <Grid.Column computer={4} mobile={16} tablet={8}>
                      <Checkbox
                        disabled={product.testResult.disabled_d_4}
                        checked={product.testResult.checked_d_4}
                        ref="check_d_4"
                        label="ใช้ยางรายการเดียวกับข้อ 4."
                        onChange={async (e, { value }) => {
                          if (!this.refs.check_d_4.state.checked) {
                            // Coppy Value
                            product.testResult.brandName_d =
                              product.testResult.brandName_d;
                            product.testResult.genName_d =
                              product.testResult.genName_d;
                            product.testResult.sizeGen_d =
                              product.testResult.sizeGen_d;

                            // Coppy Options
                            product.testResult.brandNameOption_d =
                              product.testResult.brandNameOption_d;
                            product.testResult.genNameOption_d =
                              product.testResult.genNameOption_d;
                            product.testResult.sizeGenOption_d =
                              product.testResult.sizeGenOption_d;

                            // disabled other
                            product.testResult.disabled_d_1 = true;
                            product.testResult.disabled_d_2 = true;
                            product.testResult.disabled_d_3 = true;

                            // checked
                            product.testResult.checked_d_4 = true;

                            await this.setState({
                              productRequest: tmp
                            });
                          } else {
                            // enable other
                            product.testResult.disabled_d_1 = false;
                            product.testResult.disabled_d_2 = false;
                            product.testResult.disabled_d_3 = false;

                            // uncheck
                            product.testResult.checked_d_4 = false;

                            await this.setState({
                              productRequest: tmp
                            });
                          }
                        }}
                      />
                    </Grid.Column> */}
                  </Grid.Row>

                  <Grid.Row>
                    {/* 4. ชื่อทางการค้า */}
                    <Grid.Column computer={6} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.brandName_d_error}>
                        {/* <label id={"brandName_d_" + productPos}>
                          ชื่อทางการค้า
                        </label> */}

                        <label>ชื่อทางการค้า</label>

                        <Input
                          id={"brandName_d_" + productPos}
                          placeholder="กรอกชื่อทางการค้า"
                          value={product.testResult.brandName_d.split("->")[0]}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.brandName_d = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกชื่อทางการค้า"
                          options={product.testResult.brandNameOption_d || []}
                          value={product.testResult.brandName_d}
                          onChange={async (e, { value }) => {
                            product.testResult.brandNameOption_d.map(
                              (brand_option, index_brand_option) => {
                                if (brand_option.value === value) {
                                  product.testResult.genNameOption_d =
                                    brand_option.gen_name_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.brandName_d = value;
                            product.testResult.genName_d = "";

                            product.testResult.sizeGenOption_d = [];
                            product.testResult.sizeGen_d = "";

                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 4. รุ่น  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.genName_d_error}>
                        {/* <label id={"genName_d_" + productPos}> รุ่น</label> */}
                        <label> ชื่อรุ่น</label>
                        <Input
                          id={"genName_d_" + productPos}
                          placeholder="กรอกชื่อรุ่น"
                          value={product.testResult.genName_d.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.genName_d = value.toUpperCase();
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกรุ่น"
                          options={product.testResult.genNameOption_d || []}
                          value={product.testResult.genName_d}
                          onChange={async (e, { value }) => {
                            product.testResult.genNameOption_d.map(
                              (gen_option, index_gen_option) => {
                                if (gen_option.value === value) {
                                  product.testResult.sizeGenOption_d =
                                    gen_option.size_gen_option;
                                }
                              }
                            );
                            tmp[productPos].testResult.genName_d = value;
                            product.testResult.sizeGen_d = "";

                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>

                    {/* 4. รายการ  */}
                    <Grid.Column computer={5} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.sizeGen_d_error}>
                        {/* <label id={"sizeGen_d_" + productPos}> รายการ</label> */}
                        <label> ขนาด</label>

                        <Input
                          id={"sizeGen_d_" + productPos}
                          placeholder="กรอกขนาด"
                          value={product.testResult.sizeGen_d.split("->")[0]}
                          onChange={async (e, { value }) => {
                            // tmp[productPos].testResult.testPage = value;
                            tmp[productPos].testResult.sizeGen_d = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />

                        {/* <Dropdown
                          clearable
                          selection
                          wrapSelection={false}
                          placeholder="เลือกรายการ"
                          options={product.testResult.sizeGenOption_d || []}
                          value={product.testResult.sizeGen_d}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.sizeGen_d = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        /> */}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  {/* 4. ค่าดัชนีการเกาะถนนหิมะ */}
                  <Grid.Row>
                    <Grid.Column computer={16} mobile={16} tablet={16}>
                      <Form.Field error={product.testResult.testValue_d_error}>
                        <h4>
                          ค่าดัชนีการเกาะถนนหิมะ
                          <span style={{ color: "red" }}>*</span>
                        </h4>
                        <Input
                          id={"testValue_d_" + productPos}
                          type="number"
                          label={{
                            basic: true,
                            content: product.testResult.testUnit_d
                          }}
                          labelPosition="right"
                          placeholder="ค่าดัชนีการเกาะถนนหิมะ"
                          value={product.testResult.testValue_d}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.testValue_d = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />
                        {/* <p style={{ color: "#db2828" }}>
                          *หมายเหต: หากไม่มีผลการทดสอบให้กรอกเป็นเลขศูนย์
                        </p> */}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {/* 4. เอกสารการทดสอบการเกาะถนนหิมะ*/}
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field>
                        <h4 id={"testFile_d_" + productPos}>
                          เอกสารการทดสอบการเกาะถนนหิมะ
                        </h4>
                        <Segment>
                          <Label
                            attached="top"
                            color={
                              product.testResult.testFile_d_error
                                ? "red"
                                : "violet"
                            }
                            as="label"
                            htmlFor={"testFile_d_upload_" + productPos}
                            size="large"
                          >
                            <Icon name="upload" />
                            เลือกไฟล์
                            <input
                              hidden
                              id={"testFile_d_upload_" + productPos}
                              type="file"
                              accept=".pdf"
                              onChange={async e => {
                                if (e.target.files[0]) {
                                  if (e.target.files.size > 10000000) {
                                    alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                    return;
                                  }

                                  var src = URL.createObjectURL(
                                    e.target.files[0]
                                  );

                                  product.testResult.testFile_d.name =
                                    e.target.files[0].name;

                                  product.testResult.testFile_d.data =
                                    e.target.files[0];

                                  product.testResult.testFile_d.path = src;

                                  product.testResult.testFile_d_error = false;

                                  await this.setState({
                                    productRequest: tmp
                                  });
                                }
                              }}
                            />
                          </Label>
                          <p>
                            {product.testResult.testFile_d.name}
                            &nbsp; &nbsp;
                            {product.testResult.testFile_d.name ? (
                              <Icon
                                color="red"
                                size="large"
                                name="times circle outline"
                                link
                                onClick={async e => {
                                  product.testResult.testFile_d = {
                                    name: "",
                                    data: "",
                                    path: ""
                                  };

                                  tmp[productPos].testFile_d_error = true;

                                  await this.setState({
                                    productRequest: tmp
                                  });

                                  document.getElementById(
                                    "testFile_d_upload_" + productPos
                                  ).value = null;
                                }}
                              />
                            ) : null}
                          </p>
                        </Segment>
                        <p
                          style={{
                            color: "#db2828"
                          }}
                        >
                          ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                        </p>
                      </Form.Field>
                    </Grid.Column>

                    {/* 4. หมายเลขหน้าที่แสดงผลการทดสอบทั้ง */}

                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <Form.Field error={product.testResult.testPage_d_error}>
                        <h4>
                          หมายเลขหน้าที่แสดงผลการทดสอบการเกาะถนนหิมะ
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h4>
                        <Input
                          id={"testPage_d_" + productPos}
                          placeholder="หมายเลขหน้าที่"
                          value={product.testResult.testPage_d}
                          onChange={async (e, { value }) => {
                            tmp[productPos].testResult.testPage_d = value;
                            await this.setState({
                              productRequest: tmp
                            });
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              {/* ======================================================================================================================================== */}

              <Grid>
                {/* ใบรับรองและผลการทดสอบตามมาตรฐาน */}
                {/* <Grid.Row>
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <Form.Field>
                      <h4 id={"testFile_" + productPos}>
                        ใบรับรองและผลการทดสอบตามมาตรฐาน UNECE R117
                      </h4>
                      <Segment>
                        <Label
                          attached="top"
                          color={
                            product.testResult.testFile_error ? "red" : "violet"
                          }
                          as="label"
                          htmlFor={"testFile_upload_" + productPos}
                          size="large"
                        >
                          <Icon name="upload" />
                          เลือกไฟล์
                          <input
                            hidden
                            id={"testFile_upload_" + productPos}
                            type="file"
                            accept=".pdf"
                            onChange={async e => {
                              if (e.target.files[0]) {
                                if (e.target.files.size > 10000000) {
                                  alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                  return;
                                }

                                var src = URL.createObjectURL(
                                  e.target.files[0]
                                );

                                product.testResult.testFile.name =
                                  e.target.files[0].name;

                                product.testResult.testFile.data =
                                  e.target.files[0];

                                product.testResult.testFile.path = src;

                                product.testResult.testFile_error = false;

                                await this.setState({
                                  productRequest: tmp
                                });
                              }
                            }}
                          />
                        </Label>
                        <p>
                          {product.testResult.testFile.name}
                          &nbsp; &nbsp;
                          {product.testResult.testFile.name ? (
                            <Icon
                              color="red"
                              size="large"
                              name="times circle outline"
                              link
                              onClick={async e => {
                                product.testResult.testFile = {
                                  name: "",
                                  data: "",
                                  path: ""
                                };

                                tmp[productPos].testFile_error = true;

                                await this.setState({
                                  productRequest: tmp
                                });

                                document.getElementById(
                                  "testFile_upload_" + productPos
                                ).value = null;
                              }}
                            />
                          ) : null}
                        </p>
                      </Segment>
                      <p
                        style={{
                          color: "#db2828"
                        }}
                      >
                        ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                      </p>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row> */}
              </Grid>
            </Segment>
          </Form>
          <br />

          <Requestbutton
            history={this.props.history}
            pos={productPos}
            url={"requestTest"}
            data={this.state}
            productLength={tmp.length}
            validate={this.validateForm}
            callBackParent={this.recieveProps}
          />
        </Container>
      </div>
    );
  }

  recieveProps = childData => {
    // // console.log("childData", childData);
    this.setState(childData);
    // this.createOption();

    // await this.set();
    // // console.log("after childData", this.state);
  };

  createOption = async () => {
    var productPos = this.state.productPos;
    var product = this.state.productRequest[productPos];

    var tmp = this.state.productRequest;

    product.testResult.brandNameOption_a = [];
    product.testResult.brandNameOption_b = [];
    product.testResult.brandNameOption_c = [];
    product.testResult.brandNameOption_d = [];

    product.brandsRequest.map((brand, brand_index) => {
      let opt_tmp = {
        key: brand_index,
        text: brand.brandName,
        value: brand.brandName + "->" + brand_index,
        gen_name_option: []
      };

      brand.genRequest.map((gen, gen_index) => {
        opt_tmp.gen_name_option.push({
          key: brand_index + "" + gen_index,
          text: gen.genName,
          value: gen.genName + "->" + brand_index + gen_index,
          size_gen_option: []
        });

        gen.sizeRequest.map((size, size_index) => {
          return opt_tmp.gen_name_option[gen_index].size_gen_option.push({
            key: brand_index + "" + gen_index + "" + size_index,
            text: size.sizeGen,
            value: size.sizeGen + "->" + brand_index + gen_index + size_index
          });
          // product.testResult.sizeGenOption_a = opt_tmp;
        });
      });

      product.testResult.brandNameOption_a.push(opt_tmp);
      product.testResult.brandNameOption_b.push(opt_tmp);
      product.testResult.brandNameOption_c.push(opt_tmp);
      product.testResult.brandNameOption_d.push(opt_tmp);
    });

    // set value to dropdown field and set option to dropdown

    // segment a
    if (product.testResult.genNameOption_a === undefined) {
      product.testResult.genNameOption_a = [];
      product.testResult.sizeGenOption_a = [];
    }
    if (product.testResult.brandNameOption_a) {
      let count_brand_a = 0;

      product.testResult.brandNameOption_a.map(
        (brand_option, index_brand_option) => {
          if (brand_option.value === product.testResult.brandName_a) {
            product.testResult.genNameOption_a = brand_option.gen_name_option;
            count_brand_a++;
            let count_gen_a = 0;

            product.testResult.genNameOption_a.map(
              (gen_option, index_gen_option) => {
                if (gen_option.value === product.testResult.genName_a) {
                  product.testResult.sizeGenOption_a =
                    gen_option.size_gen_option;
                  count_gen_a++;
                  let count_size_a = 0;
                  product.testResult.sizeGenOption_a.map(
                    (size_option, index_size_option) => {
                      if (size_option.value === product.testResult.sizeGen_a) {
                        count_size_a++;
                      }
                    }
                  );

                  if (count_size_a === 0) {
                    // product.testResult.sizeGen_a = "";
                  }
                }
              }
            );

            if (count_gen_a === 0) {
              product.testResult.genName_a = "";
              // product.testResult.sizeGen_a = "";
              // product.testResult.sizeGenOption_a = [];
            }
          }
        }
      );

      if (count_brand_a === 0) {
        product.testResult.brandName_a = "";
        product.testResult.genName_a = "";
        product.testResult.genNameOption_a = [];
        // product.testResult.sizeGen_a = "";
        // product.testResult.sizeGenOption_a = [];
      }
    }

    // segment b
    if (product.testResult.genNameOption_b === undefined) {
      product.testResult.genNameOption_b = [];
      product.testResult.sizeGenOption_b = [];
    }
    if (product.testResult.brandNameOption_b) {
      let count_brand_b = 0;

      product.testResult.brandNameOption_b.map(
        (brand_option, index_brand_option) => {
          if (brand_option.value === product.testResult.brandName_b) {
            product.testResult.genNameOption_b = brand_option.gen_name_option;
            count_brand_b++;
            let count_gen_b = 0;

            product.testResult.genNameOption_b.map(
              (gen_option, index_gen_option) => {
                if (gen_option.value === product.testResult.genName_b) {
                  product.testResult.sizeGenOption_b =
                    gen_option.size_gen_option;
                  count_gen_b++;
                  let count_size_b = 0;
                  product.testResult.sizeGenOption_b.map(
                    (size_option, index_size_option) => {
                      if (size_option.value === product.testResult.sizeGen_b) {
                        count_size_b++;
                      }
                    }
                  );

                  if (count_size_b === 0) {
                    // product.testResult.sizeGen_b = "";
                  }
                }
              }
            );

            if (count_gen_b === 0) {
              product.testResult.genName_b = "";
              // product.testResult.sizeGen_b = "";
              // product.testResult.sizeGenOption_b = [];
            }
          }
        }
      );

      if (count_brand_b === 0) {
        product.testResult.brandName_b = "";
        product.testResult.genName_b = "";
        product.testResult.genNameOption_b = [];
        // product.testResult.sizeGen_b = "";
        // product.testResult.sizeGenOption_b = [];
      }
    }

    // segment c
    if (product.testResult.genNameOption_c === undefined) {
      product.testResult.genNameOption_c = [];
      product.testResult.sizeGenOption_c = [];
    }
    if (product.testResult.brandNameOption_c) {
      let count_brand_c = 0;

      product.testResult.brandNameOption_c.map(
        (brand_option, index_brand_option) => {
          if (brand_option.value === product.testResult.brandName_c) {
            product.testResult.genNameOption_c = brand_option.gen_name_option;
            count_brand_c++;
            let count_gen_c = 0;

            product.testResult.genNameOption_c.map(
              (gen_option, index_gen_option) => {
                if (gen_option.value === product.testResult.genName_c) {
                  product.testResult.sizeGenOption_c =
                    gen_option.size_gen_option;
                  count_gen_c++;
                  let count_size_c = 0;
                  product.testResult.sizeGenOption_c.map(
                    (size_option, index_size_option) => {
                      if (size_option.value === product.testResult.sizeGen_c) {
                        count_size_c++;
                      }
                    }
                  );

                  if (count_size_c === 0) {
                    // product.testResult.sizeGen_c = "";
                  }
                }
              }
            );

            if (count_gen_c === 0) {
              product.testResult.genName_c = "";
              // product.testResult.sizeGen_c = "";
              // product.testResult.sizeGenOption_c = [];
            }
          }
        }
      );

      if (count_brand_c === 0) {
        product.testResult.brandName_c = "";
        product.testResult.genName_c = "";
        product.testResult.genNameOption_c = [];
        // product.testResult.sizeGen_c = "";
        // product.testResult.sizeGenOption_c = [];
      }
    }

    // segment d
    if (product.testResult.genNameOption_d === undefined) {
      product.testResult.genNameOption_d = [];
      product.testResult.sizeGenOption_d = [];
    }
    if (product.testResult.brandNameOption_d) {
      let count_brand_d = 0;

      product.testResult.brandNameOption_d.map(
        (brand_option, index_brand_option) => {
          if (brand_option.value === product.testResult.brandName_d) {
            product.testResult.genNameOption_d = brand_option.gen_name_option;
            count_brand_d++;
            let count_gen_d = 0;

            product.testResult.genNameOption_d.map(
              (gen_option, index_gen_option) => {
                if (gen_option.value === product.testResult.genName_d) {
                  product.testResult.sizeGenOption_d =
                    gen_option.size_gen_option;
                  count_gen_d++;
                  let count_size_d = 0;
                  product.testResult.sizeGenOption_d.map(
                    (size_option, index_size_option) => {
                      if (size_option.value === product.testResult.sizeGen_d) {
                        count_size_d++;
                      }
                    }
                  );

                  if (count_size_d === 0) {
                    // product.testResult.sizeGen_d = "";
                  }
                }
              }
            );

            if (count_gen_d === 0) {
              product.testResult.genName_d = "";
              // product.testResult.sizeGen_d = "";
              // product.testResult.sizeGenOption_d = [];
            }
          }
        }
      );

      if (count_brand_d === 0) {
        product.testResult.brandName_d = "";
        product.testResult.genName_d = "";
        product.testResult.genNameOption_d = [];
        // product.testResult.sizeGen_d = "";
        // product.testResult.sizeGenOption_d = [];
      }
    }

    await this.setState({
      productRequest: tmp
    });
  };
}

const mapStateToProps = function (state) {
  return {
    requestRedux: state.requestRedux
  };
};

export default connect(mapStateToProps)(requestTest);
