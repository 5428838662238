import React, { Component } from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';

export class TechnicalDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      technicalShow: [],
      load: false
    }
  }

  componentDidMount() {
    Axios.get('http://192.168.250.49:1323/api/v1/approve/get_list_approve_tisi_oie')
      .then(res => {
        this.setState({ technicalShow: res.data, load: true })
       

      })
  }

  render() {
    return (
      <div>
        Technical Detail
        <ul>
          {this.state.load && this.state.technicalShow.Data.map((item, i) => (
            <Link to='' key={item.id}><li >{item.id}</li></Link>
          ))}
        </ul>
      </div>
    )
  }
}

export default TechnicalDetail
