import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Button, Menu, Image, Icon, Responsive, } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import headerLogo from './../../assets/img/logo-eco.png';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeAdminMenuItem: 'entrepreneur'
		};
	}

	handleAdminMenuItemClick = (e, { name }) => this.setState({ activeAdminMenuItem: name });

	render() {
		return (
			<div>
				<Menu stackable>
					<Menu.Item
						onClick={() => this.props.history.push("/")}
					>
						<Image src={headerLogo} size='mini' />
					</Menu.Item>
					<Menu.Item position="right" />


					<Responsive
						minWidth={Responsive.onlyComputer.minWidth}
						floated="right"
					>
						<Menu.Item onClick={() => this.props.history.push("/")}>
							<Button basic>
								<Icon name="home" />
								กลับหน้าหลัก
                </Button>
						</Menu.Item>
					</Responsive>
				</Menu>
			</div>
		);
	}
}
export default withRouter(App);
