import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';
import { createStore } from 'redux';
import rootReducer from './components/reducer';
import { Provider } from 'react-redux';
import './css/App.css';

import CookieConsent from './components/cookieConsent/CookieConsent';

// ReactDOM.render(<App />, document.getElementById('root'));

const AppLayout = () => {
	return (
		<Provider store={store}>
			<App />
			<CookieConsent />
		</Provider>
	);
};

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(<AppLayout />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
