import React, { useEffect, useState } from "react";
import axios from "axios";

import { GET_STICKER_DATA_BY_SIZE_ID } from "../router";

// pdf class
import { Font } from "@react-pdf/renderer";
import background_c1 from "../../assets/img/base_stricker-C1.png";
import background_c2 from "../../assets/img/base_stricker-C2.png";
import background_c3 from "../../assets/img/base_stricker-C3.png";
import background_c3_special from "../../assets/img/base_sticker-C3-special.png";
import vector1 from "../../assets/img/vector1.png";
import vector2 from "../../assets/img/vector2.png";
import volume1 from "../../assets/img/volume_1.png";
import volume2 from "../../assets/img/volume_2.png";
import volume3 from "../../assets/img/volume_3.png";
import font from "../../assets/font/RSU_Regular.ttf";
import styles from "./StickerDrawer.module.css";
import { Dimmer, Loader } from "semantic-ui-react";

Font.register({ family: "rsu", src: font });
var QRCode = require("qrcode.react");

const StickerDrawer = ({ sizeId }) => {
    const [data, setData] = useState({
        brand_name: "",
        gen_name: "",
        size_text: "",
        rolling_grade: "A",
        rolling_value: 0,
        wet_grip_grade: "A",
        wet_grip_value: 0,
        noise_grade: 2,
        noise_value: 0,
        type_product_name: "",
        size_symbol: "",
        standard1: "",
        standard2: "",
        company_title_name: "",
        qr_value: "google.co.th",
        qr_path: "#",
        isLoading: true,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        handleFetchData(sizeId);
    }, [sizeId]);

    const handleFetchData = async (sizeId) => {
        setIsLoading(true);
        try {
            const res = await getStickerData(sizeId);
            let temp = res;
            temp.sticker.sizeId = sizeId;
            temp.sticker.backgroundImg = getBackgroundSticker(res.sticker.type_product_name, res.sticker.type_struct_id);
            temp.sticker.volumeImg = getVolume(res.sticker.noise_grade);
            setData(temp.sticker);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getStickerData = async (sizeId) => {
        try {
            let { data, status } = await axios.get(GET_STICKER_DATA_BY_SIZE_ID + sizeId);

            if (status === 200) {
                return data;
            } else {
                return;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getBackgroundSticker = (productName, typeStructID) => {
        switch (productName) {
            case "C1":
                return background_c1;
            case "C2":
                return background_c2;
            case "C3":
                return typeStructID === 2 ? background_c3_special : background_c3;
            default:
                return null;
        }
    };

    const getVolume = (level) => {
        switch (level) {
            case 1:
                return volume1;
            case 2:
                return volume2;
            case 3:
                return volume3;
            default:
                return null;
        }
    };

    // const qrUrl = useQRCode('https://www.1stg.me')
    return (
        <div className={styles.Div_Sticker}>
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <Dimmer
                        inverted
                        active
                    >
                        <Loader size="large"></Loader>
                    </Dimmer>
                </div>
            ) : (
                <>
                    {isError ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                position: "relative",
                            }}
                        >
                            ไม่สามารถแสดงสติกเกอร์ได้
                        </div>
                    ) : (
                        <div>
                            <div className={styles.sticker}>
                                <img
                                    src={data.backgroundImg}
                                    className={styles.ShowSticker}
                                    alt="Sticker"
                                />
                                <div className={styles.header_wrapper}>
                                    <div className={styles.header_company}>ยาง</div>
                                    <div className={styles.header_brand}>{data.brand_name}</div>
                                    <div className={styles.header_gen}>{data.gen_name}</div>
                                    <div className={styles.header_size}>
                                        ขนาด {data.size_text} {data.load_capacity}
                                        {data.speed_symbol} {data.special_feature}
                                    </div>
                                </div>

                                {!(data.type_product_name === "C3" && data.type_struct_id === 2) && (
                                    <div className={styles?.[`vector_1_wrapper_${data.rolling_grade}`]}>
                                        <img
                                            //  src={() => this.getBackgroundSticker(this.state.type_product_name, this.state.type_struct_id)}
                                            src={vector1}
                                            className={styles.vector_1}
                                            alt="Sticker"
                                        ></img>

                                        <div className={styles.vector_1_txt}>{data.rolling_value}</div>
                                    </div>
                                )}

                                <div className={styles?.[`vector_2_wrapper_${data.wet_grip_grade}`]}>
                                    <img
                                        //  src={() => this.getBackgroundSticker(this.state.type_product_name, this.state.type_struct_id)}
                                        src={vector2}
                                        className={styles.vector_2}
                                        alt="Sticker"
                                    />

                                    <div className={styles.vector_2_txt}>{data.wet_grip_value}</div>
                                </div>

                                {!(data.type_product_name === "C3" && data.type_struct_id === 2) && (
                                    <React.Fragment>
                                        {data.noise_grade && (
                                            <img
                                                src={data.volumeImg}
                                                className={styles.volume}
                                                alt="Sticker"
                                            ></img>
                                        )}

                                        <div className={styles.volume_txt}>{data.noise_value}</div>
                                    </React.Fragment>
                                )}

                                <div className={styles.tyre_class_wrapper}>
                                    <div className={styles.tyre_class_left}>Tyre Class {data.type_product_name}</div>
                                    <div className={styles.tyre_class_right}>{data.size_symbol}</div>
                                </div>

                                <div className={styles.standard_top}>{data.standard1}</div>

                                <div className={styles.standard_bottom}>{data.standard2}</div>

                                {data.company_title_name === "ผลิตโดย" && (
                                    <React.Fragment>
                                        <div className={styles.produce_by_wrapper}>
                                            <div className={styles.produce_by_left}>ผลิตโดย</div>

                                            <div className={styles.produce_by_right}>
                                                {data.company_prefix_th} {data.company_name_th} {data.company_suffix_th}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                {data.company_title_name === "นำเข้าโดย" && (
                                    <React.Fragment>
                                        <div className={styles.produce_by_wrapper}>
                                            <div className={styles.produce_by_left}>นำเข้าโดย</div>

                                            <div className={styles.produce_by_right}>
                                                {data.company_prefix_th} {data.company_name_th} {data.company_suffix_th}
                                            </div>
                                        </div>

                                        <div className={styles.import_by_wrapper}>
                                            <div className={styles.import_by_left}>ผลิตโดย</div>

                                            <div className={styles.import_by_right}>
                                                {data.factory_name_en === "" ? data.factory_name_th : data.factory_name_en}
                                            </div>
                                        </div>

                                        <div className={styles.country_wrapper}>
                                            <div className={styles.country_left}>ประเทศ</div>

                                            <div className={styles.country_right}>{data.country}</div>
                                        </div>
                                    </React.Fragment>
                                )}

                                <div className={styles.QR_wrapper}>
                                    <div className={styles.QR_txt}>
                                        ID {data.qr_running}
                                        {/* ยาง */}
                                    </div>

                                    <QRCode
                                        renderAs="svg"
                                        className={styles.QR}
                                        size={80}
                                        value={`https://tyre.go.th/company/sticker-preview/${data.sizeId}`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default StickerDrawer;
