import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import "react-modal-video/css/modal-video.min.css";
// import ModalVideo from "react-modal-video";
import Fade from "react-reveal/Fade";
import { Button, Icon, Popup } from "semantic-ui-react";
import classNames from "classnames";
import styles from "./BannerOneFn.module.scss";

const HomeSlides = ({ setActiveMenu }) => {
    const [is_open_search, setIsOpenSearch] = useState(true);
    const [is_open_search_responsive, setIsOpenSearchResponsive] = useState(false);
    const [is_expand, setIsExpand] = useState(false);
    const [resder_iframe, setRenderIframe] = useState(0);

    const fnHandleGoback = () => {
        setRenderIframe(resder_iframe + 1);
    };

    return (
        <React.Fragment>
            <div id="home">
                {/* <ModalVideo
                    channel="youtube"
                    isOpen={is_open}
                    videoId="vr0qNXmkUJ8"
                    onClose={() => setIsOpen(false)}
                /> */}
                <Fade>
                    <section className="main-banner item-bg-one">
                        <div className={classNames("d-table", styles.mainContainer)}>
                            <div className={styles.searchSection}>
                                <div
                                    className={classNames(styles.iframeContainer, {
                                        [styles.open]: is_open_search,
                                        [styles.expand]: is_expand,
                                    })}
                                >
                                    <div className={styles.headIframe}>
                                        <span>ค้นหายางรถยนต์</span>
                                        <div className={styles.actionButton}>
                                            <Popup
                                                content={"กลับหน้าค้นหา"}
                                                basic
                                                trigger={
                                                    <Button
                                                        icon
                                                        size="small"
                                                        circular
                                                        onClick={() => fnHandleGoback()}
                                                    >
                                                        <Icon name="arrow left" />
                                                    </Button>
                                                }
                                            />
                                            <Popup
                                                content={is_expand ? "ย่อหน้าต่าง" : "ขยายหน้าต่าง"}
                                                basic
                                                trigger={
                                                    <Button
                                                        icon
                                                        circular
                                                        color="orange"
                                                        onClick={() => setIsExpand(!is_expand)}
                                                        className={styles.expandButton}
                                                    >
                                                        {is_expand ? <Icon name="compress" /> : <Icon name="expand" />}
                                                    </Button>
                                                }
                                            />
                                            <Popup
                                                content="ปิดหน้าต่างค้นหา"
                                                basic
                                                trigger={
                                                    <Button
                                                        icon
                                                        circular
                                                        color="red"
                                                        onClick={() => {
                                                            setIsExpand(false);
                                                            setIsOpenSearch(false);
                                                        }}
                                                    >
                                                        <Icon name="times" />
                                                    </Button>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <iframe
                                        key={resder_iframe}
                                        title="Eco Tyre"
                                        src="https://service.tisi.go.th/ecotyre/"
                                        className={styles.iframeBox}
                                    />
                                </div>
                                <div
                                    className={classNames(styles.activeButton, {
                                        [styles.open]: is_open_search,
                                    })}
                                    onClick={() => {
                                        fnHandleGoback();
                                        setIsOpenSearch(true);
                                    }}
                                >
                                    <Icon
                                        size="large"
                                        name="search"
                                    />
                                    <span>ค้นหายางรถยนต์</span>
                                </div>
                            </div>
                            <div
                                className={classNames("d-table-cell", styles.content, {
                                    [styles.open]: is_open_search,
                                    [styles.expand]: is_expand,
                                })}
                            >
                                <VisibilitySensor onChange={(isVisible) => isVisible && setActiveMenu("home")}>
                                    <div className="container">
                                        <div className={classNames("hero-content", styles.contentTextBox, { [styles.expand]: is_expand })}>
                                            <span
                                                className={classNames(styles.contentText, {
                                                    [styles.open]: is_open_search,
                                                    [styles.expand]: is_expand,
                                                })}
                                            >
                                                ECO Sticker ยางรถยนต์
                                            </span>
                                            <h5>ประหยัด ปลอดภัย ไร้เสียงรบกวน</h5>
                                        </div>
                                    </div>
                                </VisibilitySensor>
                            </div>
                        </div>
                        <div
                            className={classNames(styles.searchSectionMobileButton, {
                                [styles.hide]: is_open_search_responsive,
                            })}
                        >
                            <div
                                className={styles.activeButton}
                                onClick={() => {
                                    fnHandleGoback();
                                    setIsOpenSearchResponsive(true);
                                }}
                            >
                                <Icon
                                    size="large"
                                    name="search"
                                />
                                <span>ค้นหายางรถยนต์</span>
                            </div>
                        </div>
                        <div
                            className={classNames(styles.searchSectionMobile, {
                                [styles.open]: is_open_search_responsive,
                            })}
                        >
                            <div className={styles.iframeContainer}>
                                <div className={styles.headIframe}>
                                    <span>ค้นหายางรถยนต์</span>
                                    <div className={styles.actionButton}>
                                        <Popup
                                            content={"กลับหน้าค้นหา"}
                                            basic
                                            trigger={
                                                <Button
                                                    icon
                                                    size="small"
                                                    circular
                                                    onClick={() => fnHandleGoback()}
                                                >
                                                    <Icon name="arrow left" />
                                                </Button>
                                            }
                                        />
                                        <Popup
                                            content="ปิดหน้าต่างค้นหา"
                                            basic
                                            trigger={
                                                <Button
                                                    icon
                                                    circular
                                                    color="red"
                                                    onClick={() => {
                                                        setIsOpenSearchResponsive(false);
                                                    }}
                                                >
                                                    <Icon name="times" />
                                                </Button>
                                            }
                                        />
                                    </div>
                                </div>
                                <iframe
                                    key={resder_iframe}
                                    title="Eco Tyre"
                                    src="https://service.tisi.go.th/ecotyre/"
                                    className={styles.iframeBox}
                                />
                            </div>
                        </div>
                    </section>
                </Fade>
            </div>
        </React.Fragment>
    );
};

export default HomeSlides;
