import React, { Component, createRef, Fragment } from "react";
import {
  Table,
  Icon,
  Form,
  Button,
  Grid,
  Ref,
  Sticky,
  Label,
} from "semantic-ui-react";
import { HOST_PATH_FILE } from "../../router";
import Iframe from "react-iframe";

export default class productImportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExportPersonsChange: [],
      isBrandsChange: [],
      file: "",
      new_arr_factory_import: null,
    };
  }
  contextRef = createRef();

  componentDidMount = () => {
    // console.log('data',data)

    const data = this.props.dataFactory.filter(
      (data) => data.type_factories_idTemp === "โรงงานนำเข้า"
    );
    const factory = data[0];

    let isExportPersonsChange = [];
    let isBrandsChange = [];
    console.log("data", data);
    data.map((d) => {
      if (d.ExportPersons) {
        let _tempExportPerson = d.ExportPersons.some((e) => {
          return e.action === "deleted" || e.action === "created";
        });

        if (
          d.ExportPersons.filter((person) => person.origin === "created")
            .length === 0
        ) {
          _tempExportPerson = false;
        }
        isExportPersonsChange.push(_tempExportPerson);
      }

      const _tempBrand = d.Products.map((prod) => {
        let isChange = prod.Brands
          ? prod.Brands.some((e) => {
              return e.action === "deleted" || e.action === "created";
            })
          : false;
        if (prod.action === "created" || prod.action ==="deleted") {
          console.log('in',prod)
          isChange = false;
        }
        return isChange;
      });

      isBrandsChange.push(_tempBrand);
    });
    if (factory) {
      this.showFile(factory);
    }
    this.setState(
      {
        isExportPersonsChange: isExportPersonsChange,
        isBrandsChange: isBrandsChange,
      },
      () => {
        // console.log(
        //   "export",
        //   this.state.isExportPersonsChange,
        //   this.state.isBrandsChange
        // );
      }
    );
  };

  showFile = (factory) => {
    let path_file = "";

    if (factory.factory_licenseTemp !== "" || factory.factory_licenseOriginal) {
      path_file = factory.factory_licenseIsEdited
        ? factory.factory_licenseTemp
        : factory.factory_licenseOriginal;
    }
    if (factory.qc_licenseTemp !== "" || factory.qc_licenseOriginal !== "") {
      path_file = factory.qc_licenseIsEdited
        ? factory.qc_licenseTemp
        : factory.qc_licenseOriginal;
    }
    this.setState({
      file: `${HOST_PATH_FILE}${path_file}`,
    });
  };

  getCompared = (origin, alter) => {
    return (
      <Fragment>
        <span style={{ textDecoration: "line-through", color: "gray" }}>
          {origin}
        </span>{" "}
        <span style={{ color: "green" }}>{alter}</span>
      </Fragment>
    );
  };

  getLabelStatus = (tableType, status) => {
    let labelText = "";
    switch (tableType) {
      case "factory":
        labelText = "โรงงาน";
        break;
      case "product":
        labelText = "ผลิตภัณฑ์";
        break;
      case "export":
        labelText = "ผู้ส่งออก";
        break;
      case "warehouse":
        labelText = "สถานที่เก็บสินค้า";
        break;
      case "brand":
        labelText = "ชื่อทางการค้า";
        break;
    }
    if (status === "created" || status === "deleted") {
      return (
        <Label
          floated
          style={{ float: "right" }}
          color={status === "created" ? "green" : "red"}
        >
          {`ข้อมูล${labelText}ที่${
            status === "created" ? "เพิ่มเข้ามาใหม่" : "ถูกลบ"
          }`}
        </Label>
      );
    }
    return null;
  };
  render() {
    return (
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Ref innerRef={this.contextRef}>
            <Form.Field>
              {this.props.dataFactory
                .filter((data) => data.type_factories_idTemp === "โรงงานนำเข้า")
                .map((factory, index) => {
                  return (
                    <Table
                      className={"oie-table"}
                      inverted={
                        factory.action === "created" ||
                        factory.action === "deleted"
                          ? true
                          : false
                      }
                      color={
                        factory.action === "created"
                          ? "green"
                          : factory.action === "deleted"
                          ? "red"
                          : null
                      }
                      celled
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan="2" width="1">
                            <Icon name="user" />
                            ข้อมูลการนำเข้าโรงงานที่ {index + 1}
                            {this.getLabelStatus("factory", factory.action)}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <React.Fragment>
                          <Table.Row>
                            <Table.Cell>ชื่อโรงงาน</Table.Cell>
                            <Table.Cell>
                              {factory.name_thIsEdited
                                ? this.getCompared(
                                    factory.name_thOriginal,
                                    factory.name_thTemp
                                  )
                                : factory.name_thOriginal}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>ใบรับรองระบบบริหารงานคุณภาพ</Table.Cell>
                            <Table.Cell>
                              {factory.standard_qc_idIsEdited
                                ? this.getCompared(
                                    factory.standard_qc_idOriginal,
                                    factory.standard_qc_idTemp
                                  )
                                : factory.standard_qc_idOriginal}
                            </Table.Cell>
                          </Table.Row>
                          {/* {factory.standard_qc_expire && ( */}
                          <Table.Row>
                            <Table.Cell>
                              วันหมดอายุใบรับรองระบบบริหารงานคุณภาพ
                            </Table.Cell>
                            <Table.Cell>
                              {factory.standard_qc_expireIsEdited
                                ? this.getCompared(
                                    factory.standard_qc_expireOriginal,
                                    factory.standard_qc_expireTemp
                                  )
                                : factory.standard_qc_expireOriginal}
                            </Table.Cell>
                          </Table.Row>
                          {/* )} */}
                          <Table.Row>
                            <Table.Cell>จาก</Table.Cell>
                            <Table.Cell>
                              {factory.standard_fromIsEdited
                                ? this.getCompared(
                                    factory.standard_fromOriginal,
                                    factory.standard_fromTemp
                                  )
                                : factory.standard_fromOriginal}
                            </Table.Cell>
                            {/* <Table.Cell>{factory.standard_from}</Table.Cell> */}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>ภายใต้</Table.Cell>
                            <Table.Cell>
                              {factory.standard_departmentIsEdited
                                ? this.getCompared(
                                    factory.standard_departmentOriginal,
                                    factory.standard_departmentTemp
                                  )
                                : factory.standard_departmentOriginal}
                            </Table.Cell>
                            {/* <Table.Cell>{factory.standard_department}</Table.Cell> */}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>ที่ตั้งโรงงาน</Table.Cell>
                            <Table.Cell>
                              {factory.text_addressIsEdited
                                ? this.getCompared(
                                    factory.text_addressOriginal,
                                    factory.text_addressTemp
                                  )
                                : factory.text_addressOriginal}
                            </Table.Cell>
                            {/* <Table.Cell>{factory.text_address}</Table.Cell> */}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>ประเทศ</Table.Cell>
                            <Table.Cell>
                              {factory.country_idIsEdited
                                ? this.getCompared(
                                    factory.country_idOriginal,
                                    factory.country_idTemp
                                  )
                                : factory.country_idOriginal}
                            </Table.Cell>
                            {/* <Table.Cell>{factory.country_id}</Table.Cell> */}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              เอกสารแนบ: ใบรับรองระบบบริหารงานคุณภาพ
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                size="small"
                                basic
                                color="orange"
                                style={{
                                  marginTop: 10,
                                  display: "flex",
                                  justifyContens: "flex-end",
                                }}
                                onClick={() => this.showFile(factory)}
                              >
                                <Icon name="search" />
                                ดูเอกสาร
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell active colSpan="2">
                              ข้อมูลผลิตภัณฑ์
                            </Table.Cell>
                          </Table.Row>
                          {factory.Products &&
                            factory.Products.map(
                              (productImport, indexProduct) => {
                                return (
                                  <Table.Row>
                                    <Table.Cell colSpan="2" textAlign="center">
                                      <Table
                                        style={{ width: "100%" }}
                                        size="small"
                                        className={"oie-table"}
                                        inverted={
                                          productImport.action === "created" ||
                                          productImport.action === "deleted"
                                            ? true
                                            : false
                                        }
                                        color={
                                          productImport.action === "created"
                                            ? "green"
                                            : productImport.action === "deleted"
                                            ? "red"
                                            : null
                                        }
                                        collapsing
                                      >
                                        <Table.Header>
                                          <Table.Row>
                                            <Table.HeaderCell colSpan="2">
                                              ข้อมูลผลิตภัณฑ์ที่{" "}
                                              {indexProduct + 1}
                                              {/* {this.getLabelStatus(
                                              "product",
                                              productImport.action
                                            )} */}
                                            </Table.HeaderCell>
                                          </Table.Row>
                                        </Table.Header>
                                        <Table.Row>
                                          <Table.Cell collapsing>
                                            ผลิตภัณฑ์
                                          </Table.Cell>
                                          <Table.Cell>
                                            {productImport.type_product_idIsEdited
                                              ? this.getCompared(
                                                  productImport.type_product_idOriginal,
                                                  productImport.type_product_idTemp
                                                )
                                              : productImport.type_product_idTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell collapsing>
                                            โครงสร้าง
                                          </Table.Cell>
                                          <Table.Cell>
                                            {productImport.type_struct_idIsEdited
                                              ? this.getCompared(
                                                  productImport.type_struct_idOriginal,
                                                  productImport.type_struct_idTemp
                                                )
                                              : productImport.type_struct_idTemp}
                                            {/* {productImport.type_struct_id} */}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell collapsing>
                                            ปริมาณการนำเข้า (เส้น/ปี)
                                          </Table.Cell>
                                          <Table.Cell>
                                            {productImport.product_amountIsEdited
                                              ? this.getCompared(
                                                  productImport.product_amountOriginal,
                                                  productImport.product_amountTemp
                                                )
                                              : productImport.product_amountTemp}
                                            {/* {productImport.product_amount} */}
                                          </Table.Cell>
                                        </Table.Row>
                                        {this.state.isBrandsChange.length > 0 &&
                                          (productImport.Brands &&
                                          (!this.state.isBrandsChange[index][
                                            indexProduct
                                          ] ||
                                            productImport.action ===
                                              "created") ? (
                                            productImport.Brands.map(
                                              (productName, index) => {
                                                return (
                                                  <Table.Row>
                                                    <Table.Cell collapsing>
                                                      ชื่อทางการค้าที่{" "}
                                                      {index + 1}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                      {productName.nameIsEdited
                                                        ? this.getCompared(
                                                            productName.nameOriginal,
                                                            productName.nameTemp
                                                          )
                                                        : productName.nameTemp}
                                                      {/* {this.getLabelStatus(
                                                      "brand",
                                                      productName.action
                                                    )} */}
                                                    </Table.Cell>
                                                  </Table.Row>
                                                );
                                              }
                                            )
                                          ) : (
                                            <React.Fragment>
                                              <Table.Row>
                                                <Table.Cell>
                                                  ข้อมูลชื่อทางการค้า
                                                </Table.Cell>

                                                <Table.Cell>
                                                  <Grid>
                                                    <Grid.Row>
                                                      <Grid.Column width={8}>
                                                        <Label
                                                          style={{
                                                            width: `100%`,
                                                          }}
                                                          color="olive"
                                                          size="large"
                                                        >
                                                          ข้อมูลชุดดั้งเดิม
                                                        </Label>
                                                        {productImport.Brands &&
                                                          productImport.Brands.filter(
                                                            (e) =>
                                                              e.origin ===
                                                                "original" ||
                                                              e.origin ===
                                                                "edit"
                                                          ).map(
                                                            (
                                                              productName,
                                                              idx
                                                            ) => {
                                                              return (
                                                                <React.Fragment>
                                                                  <Table
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    size="small"
                                                                    className={
                                                                      "oie-table"
                                                                    }
                                                                    collapsing
                                                                    colSpan="2"
                                                                  >
                                                                    <Table.Row>
                                                                      <Table.Cell
                                                                        collapsing
                                                                      >
                                                                        ชื่อทางการค้าที่{" "}
                                                                        {idx +
                                                                          1}
                                                                      </Table.Cell>
                                                                      <Table.Cell>
                                                                        {productName.nameIsEdited
                                                                          ? this.getCompared(
                                                                              productName.nameOriginal,
                                                                              productName.nameTemp
                                                                            )
                                                                          : productName.nameTemp}

                                                                        {/* {this.getLabelStatus(
                                              "export",
                                              exportPerson.action
                                            )} */}
                                                                      </Table.Cell>
                                                                    </Table.Row>
                                                                  </Table>
                                                                </React.Fragment>
                                                              );
                                                            }
                                                          )}
                                                      </Grid.Column>
                                                      <Grid.Column width={8}>
                                                        <Label
                                                          style={{
                                                            width: `100%`,
                                                          }}
                                                          color="yellow"
                                                          size="large"
                                                        >
                                                          ข้อมูลชุดที่มีการแก้ไข
                                                        </Label>
                                                        {productImport.Brands &&
                                                          productImport.Brands.filter(
                                                            (e) =>
                                                              e.origin ===
                                                                "temp" ||
                                                              e.origin ===
                                                                "edit"
                                                          ).map(
                                                            (
                                                              productName,
                                                              idx
                                                            ) => {
                                                              return (
                                                                <React.Fragment>
                                                                  <Table
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    size="small"
                                                                    className={
                                                                      "oie-table"
                                                                    }
                                                                    collapsing
                                                                    colSpan="2"
                                                                  >
                                                                    <Table.Row>
                                                                      <Table.Cell
                                                                        collapsing
                                                                      >
                                                                        ชื่อทางการค้าที่{" "}
                                                                        {idx +
                                                                          1}
                                                                      </Table.Cell>
                                                                      <Table.Cell>
                                                                        {productName.nameIsEdited
                                                                          ? this.getCompared(
                                                                              productName.nameOriginal,
                                                                              productName.nameTemp
                                                                            )
                                                                          : productName.nameTemp}

                                                                        {/* {this.getLabelStatus(
                                              "export",
                                              exportPerson.action
                                            )} */}
                                                                      </Table.Cell>
                                                                    </Table.Row>
                                                                  </Table>
                                                                </React.Fragment>
                                                              );
                                                            }
                                                          )}
                                                      </Grid.Column>
                                                    </Grid.Row>
                                                  </Grid>
                                                </Table.Cell>
                                              </Table.Row>
                                            </React.Fragment>
                                          ))}
                                      </Table>
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          {factory.ExportPersons && (
                            <Table.Cell active colSpan="2">
                              ข้อมูลผู้ส่งออก
                            </Table.Cell>
                          )}
                          <Table.Row verticalAlign={"top"}>
                            {!this.state.isExportPersonsChange[index] ? (
                              <Table.Cell colSpan={2} textAlign="center">
                                {factory.ExportPersons &&
                                  factory.ExportPersons.map(
                                    (exportPerson, indexPerson) => {
                                      return (
                                        <React.Fragment>
                                          <Table
                                            style={{ width: "100%" }}
                                            size="small"
                                            className={"oie-table"}
                                            collapsing
                                            colSpan="2"
                                            inverted={
                                              exportPerson.action ===
                                                "created" ||
                                              exportPerson.action === "deleted"
                                                ? true
                                                : false
                                            }
                                            color={
                                              exportPerson.action === "created"
                                                ? "green"
                                                : exportPerson.action ===
                                                  "deleted"
                                                ? "red"
                                                : null
                                            }
                                          >
                                            <Table.Row>
                                              <Table.Cell collapsing>
                                                ผู้ส่งออกคนที่ {indexPerson + 1}
                                              </Table.Cell>
                                              <Table.Cell>
                                                {exportPerson.nameIsEdited
                                                  ? this.getCompared(
                                                      exportPerson.nameOriginal,
                                                      exportPerson.nameTemp
                                                    )
                                                  : exportPerson.nameTemp}
                                              </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                              <Table.Cell collapsing>
                                                ประเทศ
                                              </Table.Cell>
                                              <Table.Cell>
                                                {exportPerson.country_idIsEdited
                                                  ? this.getCompared(
                                                      exportPerson.country_idOriginal,
                                                      exportPerson.country_idTemp
                                                    )
                                                  : exportPerson.country_idTemp}
                                              </Table.Cell>
                                            </Table.Row>
                                          </Table>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                              </Table.Cell>
                            ) : (
                              <React.Fragment>
                                <Table.Cell textAlign="center">
                                  <Label
                                    style={{ width: `100%` }}
                                    color="olive"
                                    size="large"
                                  >
                                    ข้อมูลชุดดั้งเดิม
                                  </Label>
                                  {factory.ExportPersons &&
                                    factory.ExportPersons.filter(
                                      (e) =>
                                        e.origin === "original" ||
                                        e.origin === "edit"
                                    ).map((exportPerson, indexPerson) => {
                                      return (
                                        <React.Fragment>
                                          <Table
                                            style={{ width: "100%" }}
                                            size="small"
                                            className={"oie-table"}
                                            collapsing
                                            colSpan="2"
                                          >
                                            <Table.Row>
                                              <Table.Cell collapsing>
                                                ผู้ส่งออกคนที่ {indexPerson + 1}
                                              </Table.Cell>
                                              <Table.Cell>
                                                {exportPerson.nameIsEdited
                                                  ? this.getCompared(
                                                      exportPerson.nameOriginal,
                                                      exportPerson.nameTemp
                                                    )
                                                  : exportPerson.nameTemp}

                                                {/* {this.getLabelStatus(
                                              "export",
                                              exportPerson.action
                                            )} */}
                                              </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                              <Table.Cell collapsing>
                                                ประเทศ
                                              </Table.Cell>
                                              <Table.Cell>
                                                {exportPerson.country_idIsEdited
                                                  ? this.getCompared(
                                                      exportPerson.country_idOriginal,
                                                      exportPerson.country_idTemp
                                                    )
                                                  : exportPerson.country_idTemp}
                                              </Table.Cell>
                                            </Table.Row>
                                          </Table>
                                        </React.Fragment>
                                      );
                                    })}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Label
                                    style={{ width: `100%` }}
                                    color="yellow"
                                    size="large"
                                  >
                                    ข้อมูลชุดที่มีการแก้ไข
                                  </Label>
                                  {factory.ExportPersons &&
                                    factory.ExportPersons.filter(
                                      (e) =>
                                        e.origin === "temp" ||
                                        e.origin === "edit"
                                    ).map((exportPerson, indexPerson) => {
                                      return (
                                        <React.Fragment>
                                          <Table
                                            style={{ width: "100%" }}
                                            size="small"
                                            className={"oie-table"}
                                            collapsing
                                            colSpan="2"
                                          >
                                            <Table.Row>
                                              <Table.Cell collapsing>
                                                ผู้ส่งออกคนที่ {indexPerson + 1}
                                              </Table.Cell>
                                              <Table.Cell>
                                                {exportPerson.nameIsEdited
                                                  ? this.getCompared(
                                                      exportPerson.nameOriginal,
                                                      exportPerson.nameTemp
                                                    )
                                                  : exportPerson.nameTemp}

                                                {/* {this.getLabelStatus(
                                              "export",
                                              exportPerson.action
                                            )} */}
                                              </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                              <Table.Cell collapsing>
                                                ประเทศ
                                              </Table.Cell>
                                              <Table.Cell>
                                                {exportPerson.country_idIsEdited
                                                  ? this.getCompared(
                                                      exportPerson.country_idOriginal,
                                                      exportPerson.country_idTemp
                                                    )
                                                  : exportPerson.country_idTemp}
                                              </Table.Cell>
                                            </Table.Row>
                                          </Table>
                                        </React.Fragment>
                                      );
                                    })}
                                </Table.Cell>
                              </React.Fragment>
                            )}
                          </Table.Row>

                          {factory.FactoriesImportTh && (
                            <Table.Cell active colSpan="2">
                              ข้อมูลสถานที่เก็บสินค้า
                            </Table.Cell>
                          )}
                          {factory.FactoriesImportTh &&
                            factory.FactoriesImportTh.map(
                              (warehouse, index) => (
                                <React.Fragment>
                                  <Table.Row>
                                    <Table.Cell colSpan="2" textAlign="center">
                                      <Table
                                        style={{ width: "100%" }}
                                        size="small"
                                        className={"oie-table"}
                                        inverted={
                                          warehouse.action === "created" ||
                                          warehouse.action === "deleted"
                                            ? true
                                            : false
                                        }
                                        color={
                                          warehouse.action === "created"
                                            ? "green"
                                            : warehouse.action === "deleted"
                                            ? "red"
                                            : null
                                        }
                                        collapsing
                                        colSpan="2"
                                      >
                                        <Table.Header>
                                          <Table.Row>
                                            <Table.HeaderCell
                                              collapsing
                                              colSpan="2"
                                            >
                                              สถานที่เก็บสินค้าลำดับที่{" "}
                                              {index + 1}
                                              {/* {this.getLabelStatus(
                                            "warehouse",
                                            warehouse.action
                                          )} */}
                                            </Table.HeaderCell>
                                          </Table.Row>
                                        </Table.Header>
                                        <Table.Row>
                                          <Table.Cell>เลขที่</Table.Cell>
                                          <Table.Cell>
                                            {/* {warehouse.address} */}
                                            {warehouse.addressIsEdited
                                              ? this.getCompared(
                                                  warehouse.addressOriginal,
                                                  warehouse.addressTemp
                                                )
                                              : warehouse.addressTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell>ซอย</Table.Cell>
                                          <Table.Cell>
                                            {/* {warehouse.soi} */}
                                            {warehouse.soiIsEdited
                                              ? this.getCompared(
                                                  warehouse.soiOriginal,
                                                  warehouse.soiTemp
                                                )
                                              : warehouse.soiTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell>ถนน</Table.Cell>
                                          <Table.Cell>
                                            {/* {warehouse.road} */}
                                            {warehouse.roadIsEdited
                                              ? this.getCompared(
                                                  warehouse.roadOriginal,
                                                  warehouse.roadTemp
                                                )
                                              : warehouse.roadTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell>ตำบล/แขวง</Table.Cell>
                                          <Table.Cell>
                                            {/* {warehouse.subdistrict} */}
                                            {warehouse.subdistrictIsEdited
                                              ? this.getCompared(
                                                  warehouse.subdistrictOriginal,
                                                  warehouse.subdistrictTemp
                                                )
                                              : warehouse.subdistrictTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell>อำเภอ/เขต</Table.Cell>
                                          <Table.Cell>
                                            {/* {warehouse.district} */}

                                            {warehouse.districtIsEdited
                                              ? this.getCompared(
                                                  warehouse.districtOriginal,
                                                  warehouse.districtTemp
                                                )
                                              : warehouse.districtTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell>จังหวัด</Table.Cell>
                                          <Table.Cell>
                                            {/* {warehouse.province} */}
                                            {warehouse.provinceIsEdited
                                              ? this.getCompared(
                                                  warehouse.provinceOriginal,
                                                  warehouse.provinceTemp
                                                )
                                              : warehouse.provinceTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell>รหัสไปรษณีย์</Table.Cell>
                                          <Table.Cell>
                                            {warehouse.zipcode}
                                            {warehouse.zipcodeIsEdited
                                              ? this.getCompared(
                                                  warehouse.zipcodeOriginal,
                                                  warehouse.zipcodeTemp
                                                )
                                              : warehouse.zipcodeTemp}
                                          </Table.Cell>
                                        </Table.Row>
                                      </Table>
                                    </Table.Cell>
                                  </Table.Row>
                                </React.Fragment>
                              )
                            )}
                        </React.Fragment>
                      </Table.Body>
                    </Table>
                  );
                })}
            </Form.Field>
          </Ref>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Sticky context={this.contextRef} pushing>
            <Iframe
              url={this.state.file}
              width="100%"
              height="800px"
              display="initial"
              position="relative"
            />
          </Sticky>
        </Grid.Column>
      </Grid>
    );
  }
}
