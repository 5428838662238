import React, { Component } from 'react';
import { Grid, Header, Icon, Container, Card, Image } from 'semantic-ui-react';
import OIELogo from '../../assets/img/oie-logo.png';
import LayBgOieTisi from '../../assets/img/lay_bg_and_logo.png';
// import TISILogo from '../../assets/img/TISI-logo.png'
import VisibilitySensor from 'react-visibility-sensor';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Fade from 'react-reveal/Fade';
import axios from 'axios';

export class Statistics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
			sticker_amount: {
				total: 0,
				C1: { percent: '', total: 0 },
				C2: { percent: '', total: 0 },
				C3: { percent: '', total: 0 },
			},
			company_amount: {
				total: 0,
				importing: { percent: '', total: 0 },
				manufacturing: { percent: '', total: 0 },
				manufacturing_and_importing: { percent: '', total: 0 },
			},
		};
	}

	static getDerivedStateFromProps(props) {
		if (props.company_amount) {
			// console.log('props',parseFloat(props.company_amount.importing.percent))
			return {
				...props,
				is_loading: false,
			};
		}
    return null
	}

	render() {
		const { company_amount, sticker_amount, is_loading } = this.state;

		return (
			<section
				id="about"
				className="statistics-area  "
			>
				{/* <VisibilitySensor
          onChange={(isVisible) =>
            isVisible && this.props.setActiveMenu("about")
          }
        > */}
				<React.Fragment>
					<div className="statistics-header">
						<Image
							src={LayBgOieTisi}
							className="image-logo"
						/>
					</div>

					<Fade bottom>
						<div className="container">
							<div className="row justify-content-center">
								<div
									className="col-lg-4 col-10"
									style={{ paddingBottom: '10px' }}
								>
									{/* <Card.Group stackable centered doubling itemsPerRow={2}> */}
									<Card
										className="statistics-card"
										fluid
										raised
										centered
									>
										<Card.Content>
											<Header
												as="h2"
												className="statistics-header-text"
											>
												STICKER
											</Header>
											<Header
												as="h3"
												style={{ display: 'inline-block' }}
											>
												Total stickers
											</Header>
											<Header
												as="h3"
												style={{ float: 'right' }}
											>
												{!is_loading ? (
													sticker_amount.total.toLocaleString()
												) : (
													<Icon
														loading
														name="spinner"
													/>
												)}
											</Header>
											<Card.Description>
												<div className="percentage-box">
													<div
														className="percentage-item"
														style={{
															width: `${sticker_amount.C1.percent}`,
														}}
													>
														C1{' '}
													</div>

													<div
														className="percentage-item"
														style={{
															width: `${sticker_amount.C2.percent}`,
														}}
													>
														C2{' '}
													</div>

													<div
														className="percentage-item"
														style={{
															position: `relative`,
															left: `10px`,
															maxWidth: `${
																parseFloat(
																	sticker_amount.C3.percent
																) - 1
															}%`,
														}}
													>
														{' '}
														C3
													</div>

													<div
														className="percentage-item"
														style={{ width: '100%' }}
													>
														<div
															className="bar white"
															style={{
																width: `${sticker_amount.C1.percent}`,
															}}
														>
															{sticker_amount.C1.percent}
														</div>
														<div
															className="bar grey"
															style={{
																minWidth: `${sticker_amount.C2.percent}`,
															}}
														>
															{sticker_amount.C2.percent}
														</div>
														<div
															className="bar black"
															style={{
																minWidth: `${sticker_amount.C3.percent}`,
															}}
														>
															{sticker_amount.C3.percent}
														</div>
													</div>
												</div>
											</Card.Description>
										</Card.Content>
									</Card>

									{/* </Card.Group> */}
								</div>

								<div
									className="col-lg-4 col-10"
									style={{ paddingBottom: '10px' }}
								>
									<Card
										fluid
										className="statistics-card"
										raised
										centered
									>
										<Card.Content>
											<Header as="h2">ENTREPRENEUR</Header>
											<Header
												as="h3"
												style={{ display: 'inline-block' }}
											>
												Total entrepreneurs
											</Header>
											<Header
												as="h3"
												style={{
													display: 'inline-block',
													float: 'right',
												}}
											>
												{!is_loading ? (
													company_amount.total.toLocaleString()
												) : (
													<Icon
														loading
														name="spinner"
													/>
												)}
											</Header>
											<Card.Description>
												<div className="percentage-box">
													<div
														className="percentage-item"
														style={{
															width: `${company_amount.manufacturing.percent}`,
														}}
													>
														ผู้ผลิต
													</div>

													<div
														className="percentage-item"
														style={{
															width: `${company_amount.importing.percent}`,
														}}
													>
														ผู้นำเข้า
													</div>

													<div
														className="percentage-item"
														style={{
															position: `relative`,
															left: `-32px`,
															maxWidth: `${
																parseFloat(
																	company_amount
																		.manufacturing_and_importing
																		.percent
																) - 1
															}%`,
														}}
													>
														ผู้ผลิตและนำเข้า
													</div>
													<div
														className="percentage-item"
														style={{ width: '100%' }}
													>
														<div
															className="bar white"
															style={{
																width: `${company_amount.manufacturing.percent}`,
															}}
														>
															{company_amount.manufacturing.percent}
														</div>
														<div
															className="bar grey"
															style={{
																minWidth: `${company_amount.importing.percent}`,
															}}
														>
															{company_amount.importing.percent}
														</div>

														<div
															className="bar black"
															style={{
																minWidth: `${company_amount.manufacturing_and_importing.percent}`,
																// width: `10%`,
															}}
														>
															{
																company_amount
																	.manufacturing_and_importing
																	.percent
															}
														</div>
													</div>
												</div>
											</Card.Description>
										</Card.Content>
									</Card>
								</div>
							</div>
						</div>
					</Fade>
				</React.Fragment>
				{/* </VisibilitySensor> */}
			</section>
		);
	}
}
export default Statistics;
