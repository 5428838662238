import React, { Component, Fragment } from "react";
import NavBar from "./exolot/NavBar";
import BannerOne from "./exolot/banner/BannerOneFn";
import About from "./exolot/About";
import Statistics from "./exolot/Statistics";
import ContactUs from "./exolot/ContactUs";
import Regulation from "./exolot/Regulation";
import Reports from "./exolot/Reports";
import News from "./exolot/News";
import CompanyLogin from "./exolot/CompanyLogin";
import { GET_STATISTICS, GET_VISITORS } from "./router";
import axios from "axios";
// import { animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
// import NoticeModal from './exolot/NoticeModal'

// import BannerTwo from "./exolot/banner/BannerTwo";
// import BannerThree from "./exolot/banner/BannerThree";
// import Welcome from "./exolot/Welcome";
// import Feadback from "./exolot/Feadback"
// import Features from "./exolot/Features"
// import Partners from "./exolot/Partner"
// import Pricing from "./exolot/Pricing"
// import Services from "./exolot/Services"
// import { setResponseValue } from './actions/actions';

import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";
import "../css/bootstrap-scoped.css";
import "../css/style.scss";
import "../css/responsive.scss";
// import SearchPage from "../pages/SearchPage";
import classNames from "classnames";

export default class Main_beta extends Component {
    constructor(props) {
        super(props);
        this.state = { activeMenu: "home", visibilityPopup: false };
    }

    componentDidMount() {
        this.getStatistics();
        this.updateVisitAmount();

        // const search = this.props?.location?.search;
        // if (search) {
        //     const params = new URLSearchParams(search);
        //     if (params.size > 0) {
        //         const obj_params = Object.fromEntries(params);
        //         this.scroll(obj_params.active);
        //     }
        // }

        this.interval = setInterval(() => this.updateVisitAmount(), 1.2e6);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getStatistics = async () => {
        try {
            const {
                data: { value },
            } = await axios.get(GET_STATISTICS);
            this.setState({ ...value, is_loading: false });
        } catch (err) {
            console.log(err);
        }
    };

    updateVisitAmount = async () => {
        await axios.put(GET_VISITORS);
    };

    popupCloseHandler = () => {
        this.setState({ visibilityPopup: false });
    };

    setActiveMenu = (value) => {
        this.setState({
            activeMenu: value,
        });
    };

    scroll = (name) => {
        if (name === "reports") {
            this.setActiveMenu(name);
            document.getElementById("snap").scrollTo(0, 0);
        } else {
            this.setActiveMenu(name);
            document.getElementById("snap").scroll({
                top: document.getElementById(name)?.getBoundingClientRect().top || 0 + document.getElementById("snap").scrollTop,
                behavior: "smooth",
            });
        }
    };

    render() {
        return (
            <Fragment>
                <article className="bootstrap-scope">
                    <div
                        id="snap"
                        className={classNames("snap-container", {
                            "hidden-y": this.state.activeMenu === "search",
                        })}
                    >
                        <NavBar
                            activeMenu={this.state.activeMenu}
                            setActiveMenu={this.setActiveMenu}
                            scroll={this.scroll}
                        />
                        {/* {(this.state.activeMenu !== 'news') ?  */}
                        <div
                            style={{
                                display: this.state.activeMenu === "reports" || this.state.activeMenu === "search" ? "none" : "block",
                            }}
                        >
                            <BannerOne setActiveMenu={this.setActiveMenu} />
                            <About setActiveMenu={this.setActiveMenu} />
                            <Statistics
                                company_amount={this.state.company_amount}
                                sticker_amount={this.state.sticker_amount}
                                setActiveMenu={this.setActiveMenu}
                            />
                            <CompanyLogin setActiveMenu={this.setActiveMenu} />
                            <News />
                            <Regulation setActiveMenu={this.setActiveMenu} />
                            {/* <News setActiveMenu={this.setActiveMenu} /> */}
                            <ContactUs
                                visitorAmount={this.state.visitor}
                                setActiveMenu={this.setActiveMenu}
                            />
                        </div>

                        <div
                            style={{
                                display: this.state.activeMenu !== "reports" ? "none" : "block",
                            }}
                        >
                            {this.state.activeMenu === "reports" && <Reports setActiveMenu={this.setActiveMenu} />}
                        </div>
                        {/* {this.state.activeMenu === "search" && (
                            <div id="search">
                                <SearchPage />
                            </div>
                        )} */}
                    </div>
                    {/* <NoticeModal />
					<Welcome /> */}
                </article>
            </Fragment>
        );
    }
}
