const responseBackEnd = {
  user: [
    {
      id: '',
      name: "",
      username: "",
      email: "",
      address: {
        street: "",
        suite: "",
        city: "",
        zipcode: "",
        geo: {
          lat: "",
          lng: ""
        }
      },
      phone: "",
      website: "",
      company: {
        name: "",
        catchPhrase: "",
        bs: ""
      }
    }
  ]
}

export default (state = responseBackEnd, action) => {
  switch (action.type) {
    case 'fetchApproval':
      state = action.value
      return state
    default:
      return state
  }
}