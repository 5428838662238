import React, { Component } from 'react'
import { Modal, Header, Button, List } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

export class MobileHeader extends Component {
  state = { open: false }

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
  }

  close = () => this.setState({ open: false })

  logout = () => {
    Swal.fire({
      title: 'ยืนยันที่จะออกจากระบบ',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      buttonsStyling: true
    }).then((res) => {
      if (res.value) {
        localStorage.clear();
        this.props.history.push("/");
      } else {
        return;
      }
    });
  };

  render() {
    const { open, closeOnEscape, closeOnDimmerClick } = this.state
    return (
      <div>
        <Button
          floated='right'
          icon='bars'
          color='orange'
          onClick={this.closeConfigShow(true, true)}
          size='large'
          style={{width:'60px'}}
        />
        <Modal
          open={open}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          onClose={this.close}
          closeIcon>
          <Header content='รายการ' />
          <Modal.Content>
            <List divided relaxed size='big'>
              <List.Item>
                <List.Content>
                  <List.Description href='/oie/entrepreneur'>ผู้ประกอบการ</List.Description>
                </List.Content>
              </List.Item>
              {/* <List.Item>
                <List.Content>
                  <List.Description href='/oie/entrepreneur'>ยาง</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Description href='/oie/entrepreneur'>ข้อมูลทั้งหมด</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Description href='/oie/entrepreneur'>CoC</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Description href='/oie/entrepreneur'>Technical Service</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Description href='/approval/tisi_oie'>Timeline</List.Description>
                </List.Content>
              </List.Item> */}
              <List.Item>
                <List.Content>
                  <List.Description href='/approval/tisi_oie'>ตรวจสอบคำขอ</List.Description>
                </List.Content>
              </List.Item>
              {/* <List.Item>
                <List.Content>
                  <List.Description href='/approval/payment'>ตรวจสอบการชำระเงิน</List.Description>
                </List.Content>
              </List.Item> */}
            </List>
          </Modal.Content>
          <Modal.Actions>
            <h3>{localStorage.getItem('name')}</h3>
            <Button content='ออกจากระบบ' onClick={this.logout} negative />
          </Modal.Actions>
        </Modal>
      </div >
    )
  }
}

export default withRouter(MobileHeader) 
