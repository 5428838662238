function customCompany(importCompany, companyName, companyId, factoryNameEN, factoryNameTH) {

  let part_one, part_two, importFontSize, productFontSize;

  if (importCompany.length >= 50) {
    importFontSize = 9;
  } else if (importCompany.length > 30) {
    importFontSize = 10;
  } else {
    importFontSize = 11;
  }

  if (factoryNameEN === "") {
    if (factoryNameTH.length >= 50) {
      productFontSize = 8;
    } else if (factoryNameTH.length > 38) {
      productFontSize = 9;
    } else {
      productFontSize = 12;
    }
  } else {
    if (factoryNameEN.length >= 50) {
      productFontSize = 8;
    } else if (factoryNameEN.length > 38) {
      productFontSize = 9;
    } else {
      productFontSize = 12;
    }
  }

  // console.log("companyName", companyName);
  // console.log("companyId", companyId);

  switch (companyId) {
    // ซิลเวอร์ไทร์อินเตอร์เนชั่นแนล
    case "5ca81118-a1ca-4d7a-ba34-346c1a1ca919":
      part_one = companyName.slice(0, 12);
      part_two = companyName.slice(12);
      importFontSize = 11;
      break
    // สมูท ทรานสปอร์ต อินเตอร์เนชั่นแนล
    // case "0e6861ef-014f-4c0e-b419-0e265dcd131c":
    //   break
    // //คอนติเนนทอล ไทร์ส (ประเทศไทย)
    // case "10e8dcc4-014d-43c5-899f-d7b6cc165a51":
    //   break
    // //ซูมิโตโม รับเบอร์ (ไทยแลนด์)
    // case "1787864f-c03f-440a-9f5d-093d689cb0b6":
    //   break
    // //เจพีดับเบิลยู 168 กรุ๊ป
    // case "36172c41-99b0-4ab4-8bcf-1941aff4603c":
    //   break
    // //โยโกฮามา ไทร์ เซลส์ (ประเทศไทย)
    // case "4fb02e05-314d-4292-8f5d-06d9f6e1025a":
    //   break
    // //ดรีมเมคเกอร์-ไทย คอร์ปอเรชั่น
    // case "5960c353-b69f-4d94-8953-42a356debb3d":
    //   break
    // //กู๊ดเยียร์ (ประเทศไทย)
    // // case "5b431a22-afe8-4aa6-82e5-26e2b873bad2":
    // //   break
    // //ซิลเวอร์ไทร์อินเตอร์เนชั่นแนล
    // case "5ca81118-a1ca-4d7a-ba34-346c1a1ca919":
    //   break
    // //ชวกิจรวมยาง
    // case "735feaf0-a977-4e08-ba87-b018571d01a3":
    //   break
    // //เอสติมา ไทร์
    // case "8cd4f469-3c4a-438c-9089-ef117bf50706":
    //   break
    // //ตันจง ซูบารุ ออโตโมทีฟ (ประเทศไทย)
    // case "8fb180a7-dda3-4910-a1c2-42c72518733b":
    //   break
    // //สยามมิชลิน
    // case "9aaf7867-44a5-4321-83db-6b2dc48e5b6a":
    //   break
    // //แม็กซิส  อินเตอร์เนชั่นแนล (ประเทศไทย)
    // case "9b16c015-5b4f-403b-a3c1-3313208ff002":
    //   break
    // //ดันลอป ไทร์ (ไทยแลนด์)
    // case "a055e5e5-3a02-4e21-b902-1d83187ed8a2":
    //   break
    // //กู๊ดไทร์ รีเทรดดิ้ง
    // case "a996d609-e38b-46f9-ab59-5e9d9c3bb143":
    //   break
    // //ที เอช ดี ออโต้ พาร์ท
    // case "adada5f4-425b-4f08-98f7-3471b74611c4":
    //   break
    // //อินนิเชียล ไทร์ แอนด์ แอ็คเซสซอรี่
    // case "c57df523-68db-4fa1-995f-6c992e533eec":
    //   break
    // //บริดจสโตนเซลส์ (ประเทศไทย)
    // case "cf9054a5-c508-4e73-9d90-d5d5e0c2f47c":
    //   break
    default:
      part_one = companyName;
      part_two = "";
      break
  }

  //  console.log("part_one", part_one, "part_two", part_two,importFontSize);
  return { part_one, part_two, importFontSize, productFontSize };
}

export { customCompany };
