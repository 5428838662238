import React from "react";
import {
  Grid
} from "semantic-ui-react";
import StickerDrawer from './StickerDrawer'

const StickerDetail = (props) => {
  const size_id = props.match.params.sizeId;


  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={5}
        >

          <StickerDrawer sizeId={size_id} />

        </Grid.Column>
      </Grid.Row>
    </Grid >



  );

}

export default StickerDetail

// const styles = StyleSheet.create({
//   page: {

//   },
//   header_1: {
//     fontFamily: "rsu",
//     color: `gray`,
//     fontSize: 30
//   },
//   watermark: {
//     left: "100",
//     top: "150",
//     height: "150.3mm",
//     // hegiht:'100%',
//     position: `absolute`,
//     zIndex: 30
//   },
//   image: {
//     // top: `100px`, /* your required value */
//     // left: `300`,
//     width: '311.81',
//     height: "623.62",
//     position: `absolute`,
//     // height:'100%',
//     // width:'100%',
//     // margin:'0',
//     // padding:'0',
//     // zIndex: 15,
//     // marginVertical: 15,
//     // marginHorizontal: 100,
//   },
//   textWhite: {
//     color: "white"
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   },
//   wrapper: {
//     width: "311.81",
//     height: "623",
//     marginLeft: "auto",
//     marginRight: "auto",
//     marginTop: "auto",
//     marginBottom: "auto",
//     padding: "0",
//     backgroundColor: "yellow"
//   },
//   wrapper_blank: {
//     width: "110mm",
//     height: "220mm",
//     marginLeft: "auto",
//     marginRight: "auto",
//     marginTop: "auto",
//     marginBottom: "auto",
//     // backgroundColor: 'yellow'
//     TextAlign: "center"
//   },
//   logo: {
//     overflow: "visible",
//     // alignSelf: 'flex-end',
//     // backgroundColor: `green`,
//     fontFamily: "rsu",
//     marginTop: "4mm",
//     // paddingTop: '2px',
//     color: "blue",
//     height: "18mm",
//     textAlign: "center"
//   },
//   logo_txt: {
//     lineHeight: "20%",
//     overflow: "visible",
//     position: "relative",
//     top: "20px",
//     // backgroundColor: 'blue',
//     padding: "0",
//     fontSize: 34,
//     //34
//     color: `red`
//   },
//   title: {
//     // position: 'relative',
//     // top: '20px',
//     // lineHeight: "80%",
//     paddingLeft: 10,
//     paddingRight: 10,
//     fontFamily: "rsu",
//     // marginTop: '2mm',
//     height: "31mm",
//     textAlign: "center"
//     // backgroundColor: `blue`
//   },
//   sec: {
//     fontFamily: "rsu",
//     width: "100%",
//     height: "167mm",
//     border: "2px; solid; palevioletred;",
//     // backgroundColor: 'rgba(255,0,0,0) ',
//     color: "red",
//     textAlign: "center",
//     verticalAlign: "middle",
//     marginLeft: "auto",
//     marginRight: "auto"
//     // paddingTop:'50',
//     // marginTop: '50',
//     // marginBottom: '55mm'
//   },
//   imageSecTop: {
//     display: "flex",
//     flexWrap: "wrap",
//     width: "103mm", // width of sec - marLeft - marRight = 75 - 5 - 5 = 65
//     height: "73mm", // 70% of 110mm is 77m // height - marginTop = height --> 77 - 5 = 72mm
//     // backgroundColor: 'orange',
//     marginLeft: "4mm",
//     marginRight: "4mm",
//     marginTop: "2mm"
//   },
//   imageGasTank: {
//     //  backgroundColor: 'green',
//     width: "60%",
//     height: "100%"
//   },
//   logoGas: {
//     // backgroundColor: 'purple',
//     width: "100%",
//     height: "20mm", // estimation 29% of height imageSecTop(72) = 20.88
//     color: "white"
//   },
//   wrapSecAndGradArrow: {
//     width: "100%",
//     height: "52mm", // height imageSecTop - height logoGas = 72 - 20 = 52
//     display: "flex",
//     flexWrap: "wrap"
//   },
//   secGrade: {
//     paddingTop: "2",
//     // backgroundColor: 'red',
//     width: "50%",
//     height: "100%"
//   },
//   secArrow: {
//     marginTop: "5mm",
//     width: "50%",
//     height: "46mm"
//     // backgroundColor: 'purple',
//   },
//   spaceLogoGrade: {
//     width: "100%",
//     height: "7mm"
//     // backgroundColor: 'silver'
//   },
//   wrapGrade: {
//     width: "100%",
//     height: "5mm",
//     marginBottom: "1mm"
//     // backgroundColor: 'cream'
//   },
//   gasGradeA: {
//     width: "20%",
//     height: "100%",
//     backgroundColor: "#2E8B57"
//     // border-left: 20px solid #58ff00;
//     // border-top: 20px solid #00800000;
//     // border-bottom: 20px solid #00800000;
//   },
//   gasGradeB: {
//     width: "50%",
//     height: "100%",
//     backgroundColor: "#32CD32"
//   },
//   gasGradeC: {
//     width: "60%",
//     height: "100%",
//     backgroundColor: "#ADFF2F"
//   },
//   gasGradeD: {
//     width: "70%",
//     height: "100%",
//     backgroundColor: "#FFFF00"
//   },
//   gasGradeE: {
//     width: "80%",
//     height: "100%",
//     backgroundColor: "#FFD700"
//   },
//   gasGradeF: {
//     width: "90%",
//     height: "100%",
//     backgroundColor: "##FF6347"
//   },
//   gasGradeG: {
//     width: "100%",
//     height: "100%",
//     backgroundColor: "#FF0000"
//   },
//   logoSlippery: {
//     // backgroundColor: 'brown',
//     width: "100%",
//     height: "20mm", // estimation 25% of height imageSecTop = 18
//     color: "white"
//   },
//   imageSlippery: {
//     //  backgroundColor: 'pink',
//     width: "39%",
//     height: "100%"
//   },
//   imageSecBottom: {
//     display: "flex",
//     flexWrap: "wrap",
//     height: "24mm",
//     width: "71mm", // 30% of 110mm is 33m // height - marginTop - height Of <div> image Flag = height --> 33 - 2 - 9 = 22mm
//     // backgroundColor: 'black',
//     marginLeft: "36mm",
//     marginRight: "3mm"
//     // marginTop: '2mm'
//   },
//   volumeSec: {
//     width: "50%",
//     height: "100%"
//     // backgroundColor: 'green',
//   },
//   volumeValueSec: {
//     width: "50%",
//     height: "100%"
//     // backgroundColor: 'pink',
//   },
//   tyreClassWrapper: {
//     display: "flex",
//     flexWrap: "wrap",
//     width: "100%",
//     height: "7mm"
//     // backgroundColor: 'green',
//     // opacity: '0.5'
//   },
//   tyreClass: {
//     color: "black",
//     fontSize: "12",
//     textAlign: "left",
//     width: "78%", // Equal
//     height: "7mm",
//     // backgroundColor: 'red',
//     marginLeft: "3mm",
//     marginBottom: "2mm"
//   },

//   tyreModel: {
//     color: "black",
//     fontSize: "12",
//     textAlign: "right",
//     width: "16%", // Equal
//     height: "7mm"
//     // backgroundColor: 'blue',
//   },
//   standardWrapper: {
//     display: "flex",
//     flexWrap: "wrap",

//     width: "100%",
//     height: "60mm"
//     // backgroundColor: 'red',
//   },
//   qrWrapper: {
//     flexWrap: "wrap",
//     marginLeft: "4",
//     width: "31%",
//     height: "100%"
//     // backgroundColor: 'gray'
//   },
//   qrTopSection: {
//     width: "95%",
//     height: "56%",
//     // backgroundColor: 'green',

//     verticalAlign: "text-bottom"
//   },
//   qrTextSection: {
//     position: "relative",
//     top: "2",
//     fontSize: "9",
//     color: "black",

//     width: "50%",
//     // backgroundColor: 'black',
//     marginTop: "1",
//     marginLeft: "18"
//   },
//   qrBottomSection: {
//     width: "52%",
//     height: "30%",
//     // backgroundColor: "yellow",
//     // marginTop: '12',
//     marginLeft: "18"
//     // verticalAlign: 'text-bottom'
//   },

//   standardDetailWrapper: {
//     position: "relative",
//     top: "-5px",
//     padding: "15",
//     fontSize: "16",
//     color: "black",
//     textAlign: "left",
//     width: "67%",
//     height: "100%",
//     // backgroundColor: 'yellow',
//     marginBottom: "4"
//   },
//   importBy: {
//     display: "flex",
//     flexWrap: "wrap",
//     // height: "30%",
//     height: "auto",
//     width: "95%",
//     marginTop: "1"
//     // backgroundColor: 'black'
//   },
//   importTitle: {
//     height: "100%",
//     width: "30%"
//     // backgroundColor: 'red',
//   },
//   importValue: {
//     marginLeft: "5",
//     height: "100%",
//     width: "65%"
//     // backgroundColor: 'red',
//   },
//   produceBy: {
//     // display: 'flex',
//     // flexWrap: 'wrap',
//     height: "30%",
//     width: "95%",
//     marginTop: "10"
//     // backgroundColor: 'black'
//   },
//   importbyWrapper: {
//     display: "flex",
//     flexWrap: "wrap",
//     // height: "48%",
//     height: "auto",
//     maxHeight: "34%",
//     width: "100%"
//     // backgroundColor: 'blue'
//   },
//   productbyWrapper: {
//     display: "flex",
//     flexWrap: "wrap",
//     height: "32%",
//     // height:"auto",
//     width: "100%"
//     // backgroundColor: 'blue'
//   },
//   produceTitle: {
//     height: "100%",
//     width: "30%"
//     // backgroundColor: 'red',
//   },
//   produceValue: {
//     // marginTop: "3",
//     marginLeft: "5",
//     height: "100%",
//     width: "65%"
//     // backgroundColor: 'red',
//   },
//   vector1: {
//     position: "relative",
//     top: "20px"
//   }
// });


// class PDF extends Component {
//   constructor(props) {
//     super(props);
//     this.pdfLinkRef = React.createRef()
//     this.state = {
//       brand_name: "",
//       gen_name: "",
//       size_text: "",
//       rolling_grade: "A",
//       rolling_value: 0,
//       wet_grip_grade: "A",
//       wet_grip_value: 0,
//       noise_grade: 2,
//       noise_value: 0,
//       type_product_name: "",
//       size_symbol: "",
//       standard1: "",
//       standard2: "",
//       company_title_name: "",
//       qr_value: "google.co.th",
//       qr_path: "#",
//       isLoading: true
//     };
//   }

//   componentWillMount = async () => {
//     await this.setState(this.props.data);


//   };
//   componentDidMount = async () => {
//     const canvas = document.getElementById("qr1");
//     const qrURL = canvas.toDataURL();
//     await this.setQrPath(qrURL);
//     this.setRefLink()
//   };
//   componentWillReceiveProps = async nextProps => {
//     await this.setState(nextProps.data);
//     const canvas = document.getElementById("qr1");
//     const qrURL = canvas.toDataURL();
//     await this.setQrPath(qrURL);
//   };

//   setQrPath = url => {
//     return new Promise((resolve, reject) => {
//       setTimeout(() => {
//         this.setState({ qr_path: url });
//         resolve("success");
//       }, 1000);
//     });
//   };

//   setRefLink = () => {
//     return new Promise((resolve, reject) => {
//       setTimeout(() => {
//         if (/Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//           this.clickRefLink()

//         }

//         resolve("success");
//       }, 50);
//     });
//   };

//   calVolume = vol => {
//     var res;
//     switch (vol) {
//       case 1:
//         res = volume1;
//         break;
//       case 2:
//         res = volume2;
//         break;
//       case 3:
//         res = volume3;
//         break;
//       default:
//         res = "//:0";
//     }
//     return res;
//   };

//   calPosition = grade => {
//     var pos = "";
//     switch (grade) {
//       case "A":
//         pos = -4;
//         break;
//       case "B":
//         pos = 13;
//         break;
//       case "C":
//         pos = 30;
//         break;
//       case "D":
//         pos = 47;
//         break;
//       case "E":
//         pos = 64;
//         break;
//       case "F":
//         pos = 81;
//         break;
//       case "G":
//         pos = 98;
//         break;
//       default:
//         pos = 0;
//     }
//     return pos;
//   };

//   calPositionB = grade => {
//     var pos = "";
//     switch (grade) {
//       case "A":
//         pos = 3;
//         break;
//       case "B":
//         pos = 21;
//         break;
//       case "C":
//         pos = 38;
//         break;
//       case "D":
//         pos = 55;
//         break;
//       case "E":
//         pos = 73;
//         break;
//       case "F":
//         pos = 90;
//         break;
//       case "G":
//         pos = 106;
//         break;
//       default:
//         pos = 0;
//     }
//     return pos;
//   };


//   isEngChar = word => {
//     var english = /^[A-Za-z0-9&,.() ]*$/;
//     for (let index = 0; index < word.length; index++) {
//       if (!english.test(word[index])) {
//         return false;
//       }
//     }
//     return true;
//   };

//   calLenghtWord = () => {
//     let brandFontSize;

//     let importCompany =
//       this.state.company_prefix_th +
//       " " +
//       this.state.company_name_th +
//       " " +
//       this.state.company_suffix_th;

//     let { part_one, part_two, importFontSize, productFontSize } = customCompany(
//       importCompany,
//       this.state.company_name_th,
//       this.state.companyId,
//       this.state.factory_name_en,
//       this.state.factory_name_th
//     );



//     if (this.state.brand_name.length >= 45) {
//       // 20 คือตัวอักษรยาวเกิน 45
//       brandFontSize = 30;
//     } else if (this.state.brand_name.length.length > 17) {
//       // 24 คือยาวเกิน 13
//       brandFontSize = 24;
//     } else {
//       // 30 คือยาวใน 1 บรรทัด
//       brandFontSize = 30;
//     }

//     return {
//       part_one,
//       part_two,
//       importFontSize,
//       productFontSize,
//       brandFontSize
//     };
//   };


//   clickRefLink = () => {
//     const ref = document.getElementById('linkRef')
//     ref.click()
//     this.setState({ isLoading: false })
//   }

//   render() {

//     const styles_vector = StyleSheet.create({
//       vector1: {

//         position: "relative",
//         top: this.calPosition(this.state.rolling_grade),
//         left: "1.995px"
//       },
//       vector2: {
//         position: "relative",
//         top: this.calPositionB(this.state.wet_grip_grade)
//       },
//       vector1_txt: {
//         fontSize: 22,
//         color: "white",
//         fontFamily: "rsu",
//         position: "relative",
//         top: this.calPosition(this.state.rolling_grade) - 34,
//         left: 20
//       },
//       vector2_txt: {
//         fontSize: 18,
//         color: "white",
//         fontFamily: "rsu",
//         position: "relative",
//         top: this.calPositionB(this.state.wet_grip_grade) - 25,
//         left: 12
//       },
//       volume: {
//         position: "relative",
//         top: 22,
//         left: 17,
//         width: "20%"
//       }
//     });

//     let {
//       part_one,
//       part_two,
//       importFontSize,
//       productFontSize,
//       brandFontSize
//     } = this.calLenghtWord();
//     const doc = (
//       <Document>
//         <Page size={[311.81, 623.62]} style={styles.page}>
//           <View style={styles.wrapper}>
//             <Image
//               style={styles.image}
//               src={
//                 this.state.type_product_name === 'C1' ? background_c1
//                   : this.state.type_product_name === 'C2' ? background_c2
//                     : this.state.type_product_name === 'C3' && this.state.type_struct_id !== 2 ? background_c3
//                       : background_c3_special
//               }
//             />
//             <View style={styles.logo}>
//               <Text style={styles.logo_txt}>ยาง</Text>
//             </View>
//             <View style={styles.title}>
//               <View style={{ lineHeight: 1 }}>
//                 <Text
//                   style={{
//                     fontFamily: "rsu",
//                     color: `gray`,
//                     fontSize: brandFontSize
//                   }}
//                 >
//                   {this.state.brand_name}
//                 </Text>
//               </View>
//               <View style={{ lineHeight: 1 }}>
//                 <Text
//                   style={{
//                     position: "relative",
//                     // top: "0px",
//                     // top: "-15px"
//                     fontSize: 18
//                   }}
//                 >
//                   {this.state.gen_name}
//                 </Text>
//               </View>
//               <View style={{ lineHeight: 1 }}>
//                 <Text
//                   style={{
//                     position: "relative"
//                     // top: "-8px"
//                     // top :"-25px"
//                   }}
//                 >
//                   ขนาด {this.state.size_text} {this.state.load_capacity}
//                   {this.state.speed_symbol} {this.state.special_feature}
//                 </Text>
//               </View>
//             </View>
//             <View style={styles.sec}>
//               <View style={styles.imageSecTop}>
//                 <View style={styles.imageGasTank}>
//                   <View style={styles.logoGas}>
//                     {/* <Text>Logo Gas</Text> */}
//                   </View>
//                   <View style={styles.wrapSecAndGradArrow}>
//                     <View style={styles.secGrade}>
//                       <View style={styles.spaceLogoGrade} />

//                       <View style={styles.spaceLogoGrade} />
//                     </View>
//                     <View style={styles.secArrow}>
//                       {!(this.state.type_product_name === 'C3' && this.state.type_struct_id === 2) &&
//                         (< React.Fragment >
//                           <Image style={styles_vector.vector1} src={vector1} />
//                           <Text style={styles_vector.vector1_txt}>
//                             {this.state.rolling_value}
//                           </Text>
//                         </React.Fragment>
//                         )
//                       }
//                     </View>
//                   </View>
//                 </View>
//                 <View style={styles.imageSlippery}>
//                   <View style={styles.logoSlippery}>

//                   </View>


//                   <View style={styles.wrapSecAndGradArrow}>
//                     <View style={styles.secGrade} />
//                     <View style={styles.secArrow}>
//                       <Image style={styles_vector.vector2} src={vector2} />
//                       <Text style={styles_vector.vector2_txt}>
//                         {this.state.wet_grip_value}
//                       </Text>
//                     </View>
//                   </View>
//                 </View>
//               </View>
//               <View style={styles.imageSecBottom}>
//                 {!(this.state.type_product_name === 'C3' && this.state.type_struct_id === 2) &&
//                   (<React.Fragment>
//                     <View style={styles.volumeSec}>
//                       <Image
//                         style={styles_vector.volume}
//                         src={this.calVolume(this.state.noise_grade)}
//                       />
//                     </View>
//                     <View style={styles.volumeValueSec}>

//                       <Text
//                         style={{
//                           color: "white",
//                           position: "relative",
//                           top: 24,
//                           left: 16
//                         }}
//                       >
//                         {this.state.noise_value}
//                       </Text>

//                     </View>
//                   </React.Fragment>
//                   )
//                 }
//               </View>

//               <View style={styles.tyreClassWrapper}>
//                 <View style={styles.tyreClass}>
//                   <Text>Tyre Class {this.state.type_product_name}</Text>
//                 </View>
//                 <View style={styles.tyreModel}>
//                   <Text>{this.state.size_symbol}</Text>
//                 </View>
//               </View>
//               <View style={styles.standardWrapper}>
//                 <View style={styles.qrWrapper}>
//                   <View style={styles.qrTopSection} />
//                   <View style={styles.qrTextSection}>
//                     <Text>ID {this.state.qr_running} </Text>
//                   </View>
//                   <View style={styles.qrBottomSection}>
//                     <Image
//                       // style={styles.watermark}
//                       src={this.state.qr_path}
//                     />
//                     {/* <QRCode renderAs='svg' value="http://facebook.github.io/react/" /> */}
//                   </View>
//                 </View>
//                 <View style={styles.standardDetailWrapper}>
//                   <Text>{this.state.standard1}</Text>
//                   <Text>{this.state.standard2}</Text>
//                   <View style={styles.importBy}>
//                     {this.state.company_title_name === "ผลิตโดย" ? (
//                       <View>
//                         <View style={styles.importbyWrapper}>
//                           <View style={styles.produceTitle}>
//                             <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
//                           </View>
//                           <View style={styles.produceValue}>
//                             <Text style={{ fontSize: importFontSize }}>
//                               <Text>{this.state.company_prefix_th}</Text>
//                               <Text> {part_one}</Text>
//                               <Text>{part_two} </Text>
//                               <Text>{this.state.company_suffix_th}</Text>
//                             </Text>
//                           </View>
//                         </View>

//                         <View style={styles.productbyWrapper}>
//                           <View style={styles.produceTitle}>
//                             <Text style={{ fontSize: 13 }}>
//                               {/* ผลิตโดย */}
//                             </Text>
//                           </View>
//                           <View style={styles.produceValue}>
//                             <Text style={{ fontSize: 13 }}>
//                               {/* {this.state.factory_name_en} */}
//                             </Text>
//                           </View>
//                         </View>

//                         <View style={styles.productbyWrapper}>
//                           <View style={styles.produceTitle}>
//                             {/* <Text style={{ fontSize: 13 }}>ประเทศ</Text> */}
//                           </View>
//                           <View style={styles.produceValue}>
//                             <Text style={{ fontSize: 13 }}>
//                               {/* {(this.state.country).toUpperCase()} */}
//                             </Text>
//                           </View>
//                         </View>
//                       </View>
//                     ) : (
//                         <View>
//                           <View style={styles.importbyWrapper}>
//                             <View style={styles.produceTitle}>
//                               <Text style={{ fontSize: 13 }}>นำเข้าโดย</Text>
//                             </View>
//                             <View style={styles.produceValue}>
//                               <Text style={{ fontSize: importFontSize }}>
//                                 <Text>{this.state.company_prefix_th}</Text>
//                                 <Text> {part_one}</Text>
//                                 <Text>{part_two} </Text>
//                                 <Text>{this.state.company_suffix_th}</Text>
//                               </Text>
//                             </View>
//                           </View>

//                           <View style={styles.productbyWrapper}>
//                             <View style={styles.produceTitle}>
//                               <Text style={{ fontSize: 13 }}>ผลิตโดย</Text>
//                             </View>
//                             <View style={styles.produceValue}>
//                               <Text style={{ fontSize: productFontSize }}>
//                                 {this.state.factory_name_en === ""
//                                   ? this.state.factory_name_th
//                                   : this.state.factory_name_en}
//                               </Text>
//                             </View>
//                           </View>

//                           <View style={styles.productbyWrapper}>
//                             <View style={styles.produceTitle}>
//                               <Text style={{ fontSize: 13 }}>ประเทศ</Text>
//                             </View>
//                             <View style={styles.produceValue}>
//                               <Text style={{ fontSize: 13 }}>
//                                 {this.state.country.toUpperCase()}
//                               </Text>
//                             </View>
//                           </View>
//                         </View>
//                       )}
//                   </View>

//                 </View>
//               </View>
//             </View>
//           </View>
//           {!(this.state.request_status === 2) ? (
//             <Image style={styles.watermark} src={water_mark} />
//           ) : null}

//         </Page>
//       </Document>
//     );

//     return (
//       <div style={{ height: '100vh' }}>

//         {/Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//           navigator.userAgent
//         ) ? (
//             <div >

//               <PDFDownloadLink id='testLink' document={doc} fileName="somename.pdf">
//                 {({ blob, url, loading, error }) => (
//                   <React.Fragment>
//                     <Dimmer active={this.state.isLoading} inverted>
//                       <Loader size='massive' > Loading... </Loader>
//                     </Dimmer>
//                     <button
//                       style={{ display: 'none' }}
//                       ref={this.pdfLinkRef}
//                       id='linkRef'
//                       class="huge ui orange fluid button">
//                       Sticker
//                   </button>
//                   </React.Fragment>
//                 )}
//               </PDFDownloadLink>
//             </div>
//           )

//           : (
//             <PDFViewer style={{ width: "100%", height: "100vh" }}>
//               {doc}
//             </PDFViewer>
//           )
//         }

//         <QRCode
//           id="qr1"
//           value={
//             "https://tyre.go.th/company/sticker-preview/" + this.state.sizeId
//           }
//           style={{ display: "none" }}
//         />
//       </div >
//     );
//   }
// }
