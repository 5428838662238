import React, { useEffect, useState } from "react";
import styles from "./FactoryDetailModal.module.scss";
import Axios from "axios";
import { HEADER_TOKEN, OIE_COMPANY_FACTORY_DETAIL } from "../../router";
import { Button, Dimmer, Header, Label, Loader, Modal } from "semantic-ui-react";
import classNames from "classnames";
import moment from "moment";

const DetailItem = ({ data }) => {
    return (
        <div className={styles.detailContainer}>
            <div className={styles.leftSelction}>
                <span className={styles.factoryNameTh}>{data?.factories_name_th || "-"}</span>
                {/* {data?.factories_name_en && <span className={styles.factoryNameEn}>{data.factories_name_th}</span>} */}
                <span className={styles.factoryAddress}>ที่อยู่ {data?.factories_address || "-"}</span>
            </div>
            <div className={styles.rightSelction}>
                <span>ใบรับรองระบบบริหารงานคุณภาพ:</span>
                <div className={styles.standardText}>{data?.standard_qc || "-"}</div>
                <div className={styles.standardExp}>
                    วันหมดอายุ:
                    <span
                        className={classNames(styles.standardExpDate, {
                            [styles.expired]: moment().diff(moment(data.standard_qc_expire, "DD/MM/YYYY")) > 0,
                        })}
                    >
                        {data?.standard_qc_expire || "-"}
                    </span>
                </div>
            </div>
        </div>
    );
};

const FactoryDetailModal = ({ company, open, onClose }) => {
    const [is_loading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    const fnFetchData = async () => {
        setIsLoading(true);
        try {
            const { data } = await Axios.get(`${OIE_COMPANY_FACTORY_DETAIL}${company.id}`, HEADER_TOKEN());

            setData(data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fnFetchData();
        }
    }, [company]);

    return (
        <Modal
            onClose={() => onClose()}
            open={open}
            className={styles.modalContainer}
            size="small"
        >
            <div className={styles.container}>
                <Header
                    as="h3"
                    style={{ margin: 0 }}
                >
                    ข้อมูลโรงงานผลิตและนำเข้า
                </Header>
                {is_loading ? (
                    <Dimmer
                        active
                        inverted
                    >
                        <Loader inverted>กำลังโหลดข้อมูล...</Loader>
                    </Dimmer>
                ) : (
                    <div className={styles.contentContainer}>
                        <div className={styles.companyContainer}>
                            <Label color="gray">
                                <Header as="h5">ชื่อบริษัท</Header>
                            </Label>
                            <div className={styles.companyName}>{company.name || "-"}</div>
                        </div>

                        {company?.type?.includes("ผลิต") && (
                            <>
                                <Label color="gray">
                                    <Header as="h4">ข้อมูลโรงงานผลิต</Header>
                                </Label>
                                {data?.import_factories?.length > 0 ? (
                                    <div className={styles.factoryContainer}>
                                        {data?.import_factories?.map((item, key) => (
                                            <DetailItem
                                                key={key}
                                                data={item}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className={styles.empty}>ไม่พบข้อมูล</div>
                                )}
                            </>
                        )}
                        {company?.type?.includes("นำเข้า") && (
                            <>
                                <Label color="gray">
                                    <Header as="h5">ข้อมูลโรงงานนำเข้า</Header>
                                </Label>
                                {data?.manufactur_factories?.length > 0 ? (
                                    <div className={styles.factoryContainer}>
                                        {data?.manufactur_factories?.map((item, key) => (
                                            <DetailItem
                                                key={key}
                                                data={item}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className={styles.empty}>ไม่พบข้อมูล</div>
                                )}
                            </>
                        )}
                    </div>
                )}
                <Button onClick={() => onClose()}>ปิด</Button>
            </div>
        </Modal>
    );
};

export default FactoryDetailModal;
