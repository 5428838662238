import React, { Component } from "react";
import "../../css/style.scss";
import {
  Container,
  Segment,
  Form,
  Table,
  Grid,
  Button,
  TextArea,
  Header,
  Menu,
  Divider,
  Icon,
} from "semantic-ui-react";
import axios from "axios";
import {
  GET_DATA_COMPANY,
  GET_REGIS_EDITING_DATA_COMPANY,
  GET_REGIS_EDIT_DATA_COMPANY,
  APPROVE_FORM,
  EDIT_FORM,
  REJECT_FORM,
  GET_TEMP_DATA_COMPANY,
  APPROVE_EDIT_DATA,
  REJECT_EDIT_DATA,
  APPROVE_REGIS_EDITING_FORM,
  REGIS_EDIT_FORM,
  REJECT_REGIS_EDITING_FORM,
} from "../router";
import AuthorizeTable from "./componentDetail/authorizedTable";
import EntrepreneurTable from "./componentDetail/entrepreneurTable";
import DelegateTable from "./componentDetail/delegateTable";
import ProductImportTable from "./componentDetail/productImportTable";
import ProductRegisterTable from "./componentDetail/productRegisterTable";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { setResponseValue } from "../actions/actions";
import Swal from "sweetalert2";
import Headers from "../approval/ApprovalHeader";

class detailCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fectSuccess: false,
      activeItem: "ข้อมูลผู้ประกอบการ",
      table_entrepreneur: true,
      loading: false,
      refCode: "",
      PSText: "",
      error: {
        refCode: false,
        PSText: false,
      },
      success: false,
      lengthFactory: 0,
      tempdata: [],
      checkGetTempcompany: false,
    };
  }

  compareCompany = (original, altered) => {
    let obj = {};
    for (var key in original) {
      if (altered[key] === null || typeof altered[key] === "undefined") {
        continue;
      }
      if (original[key] && original[key] !== altered[key]) {
        obj = {
          ...obj,
          [key]: {
            isEdit: true,
            original: original[key],
            edited: altered[key],
          },
        };
      } else {
        obj = {
          ...obj,
          [key]: {
            isEdit: false,
            original: original[key],
            edited: "",
          },
        };
      }
    }
    this.setState({ editedData: obj }, () => {
      //   console.log("editedData", this.state.editedData);
    });
  };

  compareProduceFactory = (original, altered) => {};
  componentDidMount = () => {
    let props = this.props;
    let path = props.location.pathname.split("/");

    // loading ดึงข้อมูล
    this.setState({ loading: true });

    axios.post(`${GET_DATA_COMPANY}${path[3]}`).then((response) => {
      this.props.dispatch(setResponseValue(response.data.value));
      let checkGetTempcompany =
        response.data.value.Company[0].approved ||
        path[5] === "edit-registration";
      if (path[4] === "approved") {
        checkGetTempcompany = false;
      }

      this.setState(
        {
          data: this.props.stateForm,
          tempdata: this.props.stateForm,
          checkGetTempcompany: checkGetTempcompany,
          registrationType: path[5],
        },
        () => {
          // console.log(this.state);

          let factoryType1 = this.state.data.Factories.find(
            (factory) => factory.type_factories_id === "โรงงานผลิต"
          );

          if (factoryType1 !== undefined) {
            this.setState({ productRegister: true });
          }

          let lengthFactories = 0;
          this.state.data.Factories.map((factory) => {
            if (factory.type_factories_id === "โรงงานผลิต") {
              lengthFactories++;
            }
            return null;
          });

          this.setState({ lengthFactory: lengthFactories });

          let factoryType2 = this.state.data.Factories.find(
            (factory) => factory.type_factories_id === "โรงงานนำเข้า"
          );
          if (factoryType2 !== undefined) {
            this.setState({ productImport: true });
          }

          let personType2 = this.state.data.Persons.find(
            (person) => person.type_person === "ผู้รับมอบอำนาจ"
          );
          if (personType2 !== undefined) {
            this.setState({ addDelegate: true });
          }
          // this.setState({filteredFactories : this.SeparateArray(this.state.data.Factories,this.state.data.Factories)})
          const filtered = this.SeparateArray(
            this.state.data.Factories,
            this.state.data.Factories
          );

          const filteredPerson = this.SeparateArray(
            this.state.data.Persons,
            this.state.data.Persons
          );

          this.setState({
            filteredFactories: filtered,
            filteredPerson: filteredPerson,
          });

          this.setState({ dataReal: true });

          // approved = true = edit => else
          // approved = false => if
          // true or fale and approve => if
          // let checkApproveMenu = false
          // if paramApprove { checkApproveMenu = true }
          // let checkGetTempcompany = this.state.data.Company[0].approved;

          // if (path[4] === 'approved') {
          //   console.log(checkGetTempcompany)
          //   checkGetTempcompany = false;
          // }
          // console.log(checkGetTempcompany)
          if (!checkGetTempcompany) {
            this.setState({ loading: false, fectSuccess: true });
          } else {
            let fetcher =
              this.state.registrationType === "edit-registration"
                ? axios.get
                : axios.post;
            fetcher(
              `${
                this.state.registrationType === "edit-registration"
                  ? GET_REGIS_EDITING_DATA_COMPANY
                  : path[4] === "edit"
                  ? GET_REGIS_EDIT_DATA_COMPANY
                  : GET_TEMP_DATA_COMPANY
              }${path[3]}`,
              {
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
              .then((response) => {
                this.setState({ tempdata: response.data.value }, () => {
                  this.compareCompany(
                    this.state.data.Company[0],
                    this.state.tempdata.Company[0]
                  );

                  this.setState({
                    filteredFactories: this.SeparateArray(
                      this.state.data.Factories,
                      this.state.tempdata.Factories
                    ),
                    filteredPerson: this.SeparateArray(
                      this.state.data.Persons,
                      this.state.tempdata.Persons
                    ),
                  });
                });
              })
              .then(() => {
                let factoryType1 = this.state.tempdata.Factories.find(
                  (factory) => factory.type_factories_id === "โรงงานผลิต"
                );

                if (factoryType1 !== undefined) {
                  this.setState({ productRegisterTemp: true });
                }

                let lengthFactories = 0;
                this.state.tempdata.Factories.map((factory) => {
                  if (factory.type_factories_id === "โรงงานผลิต") {
                    lengthFactories++;
                  }
                  return null;
                });

                this.setState({ lengthTempFactory: lengthFactories });

                let factoryType2 = this.state.tempdata.Factories.find(
                  (factory) => factory.type_factories_id === "โรงงานนำเข้า"
                );
                if (factoryType2 !== undefined) {
                  this.setState({ productImportTemp: true });
                }

                let personType2 = this.state.tempdata.Persons.find(
                  (person) => person.type_person === "ผู้รับมอบอำนาจ"
                );
                if (personType2 !== undefined) {
                  this.setState({ addDelegateTemp: true });
                }
                this.setState({ loading: false, fectSuccess: true });
              })
              .catch((error) => {
                console.log("err",error.message);
                //   if (error.response.data.status === "Fail") {
                this.setState({
                  loading: false,
                  fectSuccess: true,
                  dataReal: false,
                });
                //   }
              });
          }
        }
      );
    });
  };

  handleItemClick = (_, { name }) => {
    this.setState({ activeItem: name });
    if (name === "ข้อมูลผู้ประกอบการ") {
      this.setState({
        table_entrepreneur: true,
        table_productRegister: false,
        table_productImport: false,
        table_authorized: false,
        table_delegate: false,
      });
    }

    if (name === "ข้อมูลการผลิต") {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: true,
        table_productImport: false,
        table_authorized: false,
        table_delegate: false,
      });
    }

    if (name === "ข้อมูลการนำเข้า") {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: false,
        table_productImport: true,
        table_authorized: false,
        table_delegate: false,
      });
    }

    if (name === "ข้อมูลผู้มีอำนาจลงนาม") {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: false,
        table_productImport: false,
        table_authorized: true,
        table_delegate: false,
      });
    }

    if (name === "ผู้รับมอบอำนาจ") {
      this.setState({
        table_entrepreneur: false,
        table_productRegister: false,
        table_productImport: false,
        table_authorized: false,
        table_delegate: true,
      });
    }
  };

  handleApproval = (method) => {
    if (method === "approveForm") {
      Swal.fire({
        title: "ยืนยันการอนุมัติหรือไม่",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          let data = {
            company_id: this.state.data.Company[0].companies_id,
            name: localStorage.getItem("name"),
          };
          let routeAPI;
          if (this.state.checkGetTempcompany) {
            routeAPI =
              this.state.registrationType === "edit-registration"
                ? APPROVE_REGIS_EDITING_FORM
                : APPROVE_EDIT_DATA;
          } else {
            routeAPI = APPROVE_FORM;
          }
          axios
            .post(routeAPI, data, {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((_) => {
              this.setState({ loading: false, success: true });
              Swal.fire({
                position: "center",
                type: "success",
                title: "บันทึกเรียบร้อย",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((error) => {
              this.setState({ loading: false });
              Swal.fire({
                position: "center",
                type: "error",
                title: `เกิดข้อผิดพลาด ${error.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    } else if (method === "rejectForm") {
      if (!this.validationRejectAndEdit()) {
        Swal.fire({
          title: "ยืนยันการไม่อนุมัติหรือไม่",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.value) {
            this.setState({ loading: true });
            let data = {
              company_id: this.state.data.Company[0].companies_id,
              name: localStorage.getItem("name"),
              note: this.state.PSText,
            };
            let routeAPI;
            if (this.state.checkGetTempcompany) {
              routeAPI =
                this.state.registrationType === "edit-registration"
                  ? REJECT_REGIS_EDITING_FORM
                  : REJECT_EDIT_DATA;
            } else {
              routeAPI = REJECT_FORM;
            }
            axios
              .post(routeAPI, data, {
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((_) => {
                this.setState({ loading: false, success: true });
                Swal.fire({
                  position: "center",
                  type: "success",
                  title: "บันทึกเรียบร้อย",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((error) => {
                this.setState({ loading: false });
                Swal.fire({
                  position: "center",
                  type: "error",
                  title: `เกิดข้อผิดพลาด ${error.message}`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          }
        });
      }
    } else if (method === "editForm") {
      if (!this.validationRejectAndEdit()) {
        Swal.fire({
          title: "ยืนยันการส่งแก้ไขหรือไม่",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.value) {
            this.setState({ loading: true });
            let data = {
              company_id: this.state.data.Company[0].companies_id,
              name: localStorage.getItem("name"),
              note: this.state.PSText,
            };

            axios
              .post(
                this.state.registrationType === "edit-registration"
                  ? REGIS_EDIT_FORM
                  : EDIT_FORM,
                data,
                {
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then((_) => {
                this.setState({ loading: false, success: true });
                Swal.fire({
                  position: "center",
                  type: "success",
                  title: "บันทึกเรียบร้อย",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((error) => {
                this.setState({ loading: false });
                Swal.fire({
                  position: "center",
                  type: "error",
                  title: `เกิดข้อผิดพลาด ${error.message}`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          }
        });
      }
    }
  };

  validationRejectAndEdit = () => {
    let error = false;

    if (this.state.PSText === "") {
      this.setState((state) => (state.error.PSText = true));
      error = true;
      this.PSText.focus();
    }
    return error;
  };

  handleOnChange = (name, value) =>
    this.setState(
      (state) => ((state[name] = value), (state.error[name] = false))
    );

  SeparateArray = (original, temp) => {
    let dataArray = [];
    if (Array.isArray(original) && original.length) {
      let { comparedObject, deleteIndex } =
        this.CompareBetweenOriginalAndTempObj(original[0], temp);
      dataArray = [...dataArray, ...comparedObject];
      temp = [...deleteIndex];

      if (original.slice(1).length > 0) {
        // if (temp.length !== 0) {
        dataArray = [
          ...dataArray,
          ...this.SeparateArray(original.slice(1), temp),
        ];
        // }
      } else {
        dataArray = [...dataArray, ...this.SetRestOfArrayNested("temp", temp)];
      }
    }
    return dataArray;
  };

  isInteger = (value) => {
    return /^\d+$/.test(value);
  };

  CompareBetweenOriginalAndTempObj = (objOriginal, objTemp) => {
    let dataArray = [];
    if (objOriginal.hasOwnProperty("id")) {
      // id เป็นตัวเลข increment จะไม่สามารถเทียบค่าได้จึงต้องใช้ name แทน

      var { data, index } = this.CompareByKey(
        this.isInteger(objOriginal["id"]) ? "name" : "id",
        objOriginal,
        objTemp
      );
      dataArray = [...dataArray, ...data];
    }

    let result = { comparedObject: [...dataArray], deleteIndex: index };
    if (objOriginal["id"] === "671") {
    }
    return result;
  };

  extractArray = (arr = []) => {
    let newArr = arr.map((data) => {
      let _tempData = data;
      for (let key in _tempData) {
        _tempData[key + "IsEdited"] = false;
        _tempData[key + "Original"] = _tempData[key];
        _tempData[key + "Temp"] = _tempData[key];
      }

      return _tempData;
    });
    return newArr;
  };

  CompareByKey = (keyOption, objOriginal, objTemp) => {
    let option = keyOption;

    const extractArray = (arr = []) => {
      let newArr = arr.map((data) => {
        let _tempData = data;
        for (let key in _tempData) {
          _tempData["action"] = "deleted";
          _tempData["origin"] = "original";
          _tempData[key + "IsEdited"] = false;
          _tempData[key + "Original"] = _tempData[key];
          _tempData[key + "Temp"] = _tempData[key];
        }
        return _tempData;
      });
      return newArr;
    };
    let dataArray = [];
    if (!objOriginal[option] && option === "name") {
      // let data = {};
      // let obj = { ...objTemp[0] };
      // data["action"] = "default";
      // for (let key in obj) {
      //   if (Array.isArray(obj[key])) {
      //     data[key] = this.SeparateArray(objOriginal[key], obj[key]);
      //   } else {
      //     data["origin"] = "edit";
      //     data[key + "Original"] = obj[key];
      //     data[key + "Temp"] = obj[key];
      //     data[key + "IsEdited"] = false;
      //   }
      // }

      // objTemp = objTemp.slice(1);
      // dataArray.push(data);

      option = "sequence";
    }
    let filteredTempIndex = -1;
    filteredTempIndex = objTemp.findIndex((data) => {
      //  if(objOriginal.district === 'พระสมุทรเจดีย์'){
      //   //  console.log('found',objOriginal)
      //  }
      return data[option].toString() === objOriginal[option].toString();
    });

    if (filteredTempIndex === -1) {
      let original = {};
      let temp = {};
      original["action"] = "default";
      temp["action"] = "default";
      for (let key in objOriginal) {
        if (Array.isArray(objOriginal[key])) {
          original[key] = extractArray(objOriginal[key]);
        } else {
          original["action"] = "deleted";
          original["origin"] = "original";
          original[key + "IsEdited"] = false;
          original[key + "Original"] = objOriginal[key];
          original[key + "Temp"] = objOriginal[key];
        }
      }
      dataArray.push(original);
    } else {
      let filterdData = { ...objTemp[filteredTempIndex] };
      let data = {};
      data["action"] = "default";
      for (let key in objOriginal) {
        if (Array.isArray(objOriginal[key])) {
          data[key] = this.SeparateArray(objOriginal[key], filterdData[key]);
        } else {
          data[key + "Original"] = objOriginal[key];
          data["origin"] = "edit";
          data[key + "Temp"] = filterdData[key];
          data[key + "IsEdited"] = false;
          if (objOriginal[key] != filterdData[key]) {
            data[key + "IsEdited"] = true;
            data["action"] = "edited";
          }
        }
      }
      // const tempClone = [...objTemp];

      // console.log("cut", data[option], filterdData[option]);
      objTemp = objTemp.filter((data) => data[option] !== filterdData[option]);
      dataArray.push(data);
    }

    // if (objOriginal["id"] === "671") {
    //   console.log("found rest", objTemp);
    // }

    if (filteredTempIndex !== -1) {
    }

    return { data: dataArray, index: objTemp };
  };

  SetRestOfArrayNested = (dataType, nested) => {
    let dataArray = [];
    for (let i = 0; i < nested.length; i++) {
      let data = {};
      data["action"] = "defalut";
      for (let key in nested[i]) {
        if (this.getType(nested[i][key]).includes("Array")) {
          data[key] = this.SetRestOfArrayNested(dataType, nested[i][key]);
        } else {
          switch (dataType) {
            case "original":
              data["action"] = "deleted";
              data[key + "Original"] = nested[i][key];
              data[key + "Temp"] = nested[i][key];
              break;
            case "temp":
              data["action"] = "created";
              data["origin"] = "temp";
              data[key + "Original"] = nested[i][key];
              data[key + "Temp"] = nested[i][key];
              break;
            default:
              break;
          }
          data[key + "IsEdited"] = false;
        }
      }
      dataArray.push(data);
    }
    return dataArray;
  };

  defaultVal = (type) => {
    if (typeof type !== "string") throw new TypeError("Type must be a string.");

    // Handle simple types (primitives and plain function/object)
    switch (type) {
      case "boolean":
        return false;
      case "function":
        return function () {};
      case "null":
        return null;
      case "number":
        return 0;
      case "object":
        return {};
      case "string":
        return "";
      case "symbol":
        return Symbol();
      case "undefined":
        return void 0;
    }

    try {
      // Look for constructor in this or current scope
      var ctor = typeof this[type] === "function" ? this[type] : eval(type);

      return new ctor();

      // Constructor not found, return new object
    } catch (e) {
      return {};
    }
  };

  getType = (obj) => {
    var type = typeof obj;

    if (type !== "object") return type; // primitive or function
    if (obj === null) return "null"; // null

    // Everything else, check for a constructor
    var ctor = obj.constructor;
    var name = typeof ctor === "function" && ctor.name;

    return typeof name === "string" && name.length > 0 ? name : "object";
  };
  render() {
    if (this.state.success) {
      return <Redirect to="/oie/entrepreneur" />;
    }
    if (this.state.fectSuccess) {
      return (
        <div>
          <Headers />
          <Container fluid>
            <Form loading={this.state.loading}>
              <Segment style={{ marginBottom: 30 }}>
                <Header>
                  <h1>
                    <Icon color="violet" name="user circle" />
                    {this.state.checkGetTempcompany
                      ? "ตรวจสอบการแก้ไขข้อมูลของผู้ประกอบการ"
                      : "ตรวจสอบข้อมูลการลงทะเบียน"}
                  </h1>
                </Header>
                <Divider />
                <br />
                <div>
                  <Menu size="large" pointing secondary>
                    <Menu.Item
                      name="ข้อมูลผู้ประกอบการ"
                      active={this.state.activeItem === "ข้อมูลผู้ประกอบการ"}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      style={{
                        display: (
                          this.state.checkGetTempcompany
                            ? this.state.productRegisterTemp
                            : this.state.productRegister
                        )
                          ? "block"
                          : "none",
                      }}
                      name="ข้อมูลการผลิต"
                      active={this.state.activeItem === "ข้อมูลการผลิต"}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      style={{
                        display: (
                          this.state.checkGetTempcompany
                            ? this.state.productImportTemp
                            : this.state.productImport
                        )
                          ? "block"
                          : "none",
                      }}
                      name="ข้อมูลการนำเข้า"
                      active={this.state.activeItem === "ข้อมูลการนำเข้า"}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name="ข้อมูลผู้มีอำนาจลงนาม"
                      active={this.state.activeItem === "ข้อมูลผู้มีอำนาจลงนาม"}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      style={{
                        display: (
                          this.state.checkGetTempcompany
                            ? this.state.addDelegateTemp
                            : this.state.addDelegate
                        )
                          ? "block"
                          : "none",
                      }}
                      name="ผู้รับมอบอำนาจ"
                      active={this.state.activeItem === "ผู้รับมอบอำนาจ"}
                      onClick={this.handleItemClick}
                    />
                  </Menu>
                </div>
                <br />
                <br />
                <Form.Field
                  style={{
                    display: this.state.table_entrepreneur ? "block" : "none",
                  }}
                >
                  {this.state.checkGetTempcompany ? (
                    <EntrepreneurTable
                      dataCompany={this.state.data.Company}
                      editedData={this.state.tempdata.Company}
                    />
                  ) : (
                    <EntrepreneurTable dataCompany={this.state.data.Company} />
                  )}
                </Form.Field>
                <Form.Field
                  style={{
                    display: this.state.table_productRegister
                      ? "block"
                      : "none",
                  }}
                >
                  {this.state.checkGetTempcompany ? (
                    <ProductRegisterTable
                      dataFactory={this.state.filteredFactories}
                    />
                  ) : (
                    <ProductRegisterTable
                      dataFactory={this.state.filteredFactories}
                    />
                  )}
                </Form.Field>
                <Form.Field
                  style={{
                    display: this.state.table_productImport ? "block" : "none",
                  }}
                >
                  {this.state.checkGetTempcompany ? (
                    <ProductImportTable
                      dataFactory={this.state.filteredFactories}
                    />
                  ) : (
                    <ProductImportTable
                      dataFactory={this.state.filteredFactories}
                    />
                  )}
                </Form.Field>
                <Form.Field
                  style={{
                    display: this.state.table_authorized ? "block" : "none",
                  }}
                >
                  {this.state.checkGetTempcompany ? (
                    <AuthorizeTable dataPersons={this.state.filteredPerson} />
                  ) : (
                    <AuthorizeTable dataPersons={this.state.filteredPerson} />
                  )}
                </Form.Field>

                <Form.Field
                  style={{
                    display: this.state.table_delegate ? "block" : "none",
                  }}
                >
                  {this.state.checkGetTempcompany ? (
                    <DelegateTable dataPersons={this.state.filteredPerson} />
                  ) : (
                    <DelegateTable dataPersons={this.state.filteredPerson} />
                  )}
                </Form.Field>
              </Segment>

              {((this.state.data.Company[0].status === "อนุมัติ" &&
                this.state.checkGetTempcompany) ||
                this.state.data.Company[0].status === "รอการอนุมัติ") && (
                <Table celled style={{ marginBottom: 30 }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>พิจารณาแล้วเห็นควรว่า</Table.Cell>
                      <Table.Cell>
                        <Grid stackable centered>
                          <Grid.Row>
                            <Grid.Column mobile={8} computer={4}>
                              <Button
                                color="green"
                                fluid
                                type="button"
                                name="approveForm"
                                onClick={(e, { name }) => {
                                  this.handleApproval(name);
                                }}
                              >
                                อนุมัติ
                              </Button>
                            </Grid.Column>
                            <Grid.Column mobile={8} computer={4}>
                              <Button
                                color="red"
                                fluid
                                type="button"
                                name="rejectForm"
                                onClick={(e, { name }) => {
                                  this.handleApproval(name);
                                }}
                              >
                                ไม่อนุมัติ
                              </Button>
                            </Grid.Column>
                            {this.state.data.Company[0].status ===
                              "รอการอนุมัติ" && (
                              <Grid.Column width={4}>
                                <Button
                                  color="orange"
                                  fluid
                                  type="button"
                                  name="editForm"
                                  onClick={(e, { name }) => {
                                    this.handleApproval(name);
                                  }}
                                >
                                  แก้ไข
                                </Button>
                              </Grid.Column>
                            )}
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        หมายเหตุ (โปรดระบุ กรณีไม่อนุมัติ/แก้ไข){" "}
                      </Table.Cell>
                      <Table.Cell>
                        <Form.Field error={this.state.error.PSText}>
                          <TextArea
                            id="PSText"
                            name="PSText"
                            value={this.state.PSText}
                            placeholder="หมายเหตุ (โปรดระบุ กรณีไม่อนุมัติ/แก้ไข)"
                            rows={2}
                            onChange={(e, { name, value }) => {
                              this.handleOnChange(name, value);
                            }}
                            ref={(input) => (this.PSText = input)}
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>ผู้อนุมัติ</Table.Cell>
                      <Table.Cell>{localStorage.getItem("name")}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>วันที่ตรวจอนุมัติ</Table.Cell>
                      <Table.Cell>{`${new Date().toLocaleDateString()}`}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Form>
            <Link to="/oie/entrepreneur">
              <Button size="large" fluid primary>
                กลับไปหน้าหลัก
              </Button>
            </Link>
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <center>
            <Container>
              <Form loading style={{ margin: "50%" }} />
            </Container>
          </center>
        </div>
      );
    }
  }
}

const mapStateToProps = function (state) {
  return {
    message: "This is message from mapStateToProps",
    stateForm: state.StoreCompanyResponse,
  };
};

export default connect(mapStateToProps)(detailCompany);
