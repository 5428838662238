import React, { Component } from 'react';
import { connect } from 'react-redux'; // connect redux
import { Table, Button, Grid, Icon, Header } from 'semantic-ui-react';

class addDelegatePreview extends Component {
  constructor() {
    super();
    this.state = {
      delegatePathFile: "",
    }
  }

  componentDidMount() {
    if (this.props.stateRedux.delegateFields.nationality_value === 1) {
      if (this.props.stateRedux.delegateFields.file_idcard !== undefined) {
        this.setState({ delegatePathFile: this.props.stateRedux.delegateFields.file_idcard.path })
      }
    } else {
      if (this.props.stateRedux.delegateFields.file_passport !== undefined) {
        this.setState({ delegatePathFile: this.props.stateRedux.delegateFields.file_passport.path })
      }
    }
  }

  async delegateShowPDF(e) {
    const name = e.target.name;
    this.setState({ delegatePathFile: this.props.stateRedux[name].path })
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8}>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='3'>
                        <Header as='h4'>
                        <Header.Content>
                        <Icon name="user outline" />
                        ผู้รับมอบอำนาจ
                        </Header.Content>
                        </Header>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>

                  {this.props.stateRedux.delegateFields.nationality_value === 1 && (
                    <Table.Row >
                      <Table.Cell collapsing>
                        <Header as='h5'>
													<Header.Content>
													ชื่อภาษาไทย
													</Header.Content>
												</Header>	
                          </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.name_prefixTH_text}
                        &nbsp;
                            {this.props.stateRedux.delegateFields.delegate_nameTH}
                      </Table.Cell>
                    </Table.Row>
                  )}


                  <Table.Row>
                    <Table.Cell collapsing>
                    <Header as='h5'>
													<Header.Content>
													ชื่อภาษาอังกฤษ
													</Header.Content>
												</Header>	
                          </Table.Cell>
                    <Table.Cell>
                      {this.props.stateRedux.delegateFields.name_prefixEN_text}
                      &nbsp;
                            {this.props.stateRedux.delegateFields.delegate_nameEN}
                    </Table.Cell>
                  </Table.Row>


                  {this.props.stateRedux.delegateFields.nationality_value === 1 && (
                    <Table.Row >
                      <Table.Cell collapsing>
                      <Header as='h5'>
													<Header.Content>
													เลขบัตรประชาชน
													</Header.Content>
												</Header>
                        
                            </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.delegate_id_number}</Table.Cell>
                    </Table.Row>
                  )}

                  {this.props.stateRedux.delegateFields.nationality_value === 2 && (
                    <Table.Row >
                      <Table.Cell collapsing>
                      <Header as='h5'>
													<Header.Content>
													เลขที่หนังสือดินทาง
													</Header.Content>
												</Header>
                            </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.delegate_passport_number}
                      </Table.Cell>
                    </Table.Row>
                  )}

                  {this.props.stateRedux.delegateFields.nationality_value === 1 && (
                    <>
                    <Table.Row>
											<Table.Cell colSpan='3'>
												{/* <Icon name="point" /> */}
												<Header as='h5'>
												<Header.Content>
												ที่อยู่
												</Header.Content>
											</Header>
												
											</Table.Cell>
											</Table.Row>
                      <Table.Row>
                        <Table.Cell collapsing>
                          เลขที่
                              </Table.Cell>
                        <Table.Cell>
                          {this.props.stateRedux.delegateFields.address_id}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                      <Table.Cell collapsing>
                        ซอย
                            </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.alley}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        ถนน
                           </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.road}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        ตำบล/แขวง
                            </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.sub_district}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        อำเภอ/เขต
                            </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.district}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        จังหวัด
                            </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.province}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>
                        รหัสไปรษณีย์
                            </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.zip_code}
                      </Table.Cell>
                    </Table.Row>
                    </>
                  )}

                  {this.props.stateRedux.delegateFields.nationality_value === 2 && (
                    <Table.Row>
                      <Table.Cell>
                        ที่อยู่
                          </Table.Cell>
                      <Table.Cell>
                        {this.props.stateRedux.delegateFields.foreigner_address}
                      </Table.Cell>
                    </Table.Row>
                  )}

                  <Table.Row>
                    <Table.Cell collapsing>
                    <Header as='h5'>
													<Header.Content>
													เบอร์โทรศัพท์
													</Header.Content>
												</Header>
                      
                          </Table.Cell>
                    <Table.Cell>
                      {this.props.stateRedux.delegateFields.delegate_phone_number}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell collapsing>
                    <Header as='h5'>
													<Header.Content>
													อีเมล์
													</Header.Content>
												</Header>
                      
                          </Table.Cell>
                    <Table.Cell>
                      {this.props.stateRedux.delegateFields.delegate_email}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
											<Table.Cell colSpan='3'>
												{/* <Icon name="point" /> */}
												<Header as='h5'>
												<Header.Content>
												เอกสารแนบ
												</Header.Content>
											</Header>
												
											</Table.Cell>
											</Table.Row>

                  {this.props.stateRedux.delegateFields.nationality_value === 1 && (
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Icon name='file outline' />สำเนาบัตรประชาชน
                          </Table.Cell>
                      <Table.Cell>
                        <Button
                          size='small'
                          floated='left'
                          basic
                          color='orange'
                          name="file_idcard"
                          id="file_idcard"
                          onClick={this.delegateShowPDF.bind(this)}
                        // onClick={this.handleshowPDF.bind(this)}
                        >
                          <Icon name='search' />
                          ดูเอกสาร
                              </Button>
                      </Table.Cell>
                    </Table.Row>
                  )}

                  {this.props.stateRedux.delegateFields.nationality_value === 2 && (
                    <Table.Row>
                      <Table.Cell collapsing>
                        <Icon name='file outline' />สำเนาหนังสือเดินทาง
                          </Table.Cell>
                      <Table.Cell>
                        <Button
                          size='small'
                          floated='left'
                          basic
                          color='orange'
                          name="file_passport"
                          id="file_passport"
                          onClick={this.delegateShowPDF.bind(this)}
                        // onClick={this.handleshowPDF.bind(this)}
                        >
                          <Icon
                            name='search'
                          />
                          ดูเอกสาร
                              </Button>
                      </Table.Cell>
                    </Table.Row>
                  )}

                  <Table.Row>
                    <Table.Cell collapsing>
                      <Icon name='file outline' />สำเนาหนังสือมอบอำนาจ
                          </Table.Cell>
                    <Table.Cell>
                      <Button
                        size='small'
                        floated='left'
                        basic
                        color='orange'
                        name="file_poa"
                        id="file_poa"
                        onClick={this.delegateShowPDF.bind(this)}
                      >
                        <Icon
                          name='search'
                        />
                        ดูเอกสาร
                              </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>

            <Grid.Column computer={8}>
              <iframe
                title="file_iframe"
                src={this.state.delegatePathFile}
                style={{
                  height: window.innerHeight,
                  width: '100%',
                  borderWidth: 0
                }}
              />
              {/* <iframe style={{ width:"100%", height:"100%" }} name="plugin" id="plugin" src={this.state.delegatePathFile} type="application/pdf"></iframe> */}
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

// export default entrepreneurPreview;
const mapStateToProps = function (state) {
  return {
    message: 'This is message from mapStateToProps',
    stateRedux: state.reduxxx
  }
}

export default connect(mapStateToProps)(addDelegatePreview);