import React, { Component } from 'react';
import {
    Button,
    Form,
    Input,
    Message,
    Modal,
    Grid

} from 'semantic-ui-react';
import { LOGIN_ADMIN } from '../router';
import { withRouter, Redirect } from "react-router-dom";


export class OIELoginModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: {
                username: '',
                password: ''
            },
            isLoggingIn: false,
        };

    }




    handleSummit = async () => {
        this.setState({ isLoggingIn: true });
        fetch(LOGIN_ADMIN, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password
            })
        }).then((res) =>
            res.json().then((response) => {
                // console.log(response)
                if (response.status === 'Success') {
                    localStorage.setItem('name', response.value.name);
                    localStorage.setItem('token', response.value.access_token);
                    localStorage.setItem('refreshToken', response.value.refresh_token);
                    localStorage.setItem('role', response.value.role);
                    this.setState({ loginFail: false, login: true, isLoggingIn: false });
                }
                else if (response.message === 'wrong Username') {
                    this.setState({ loginFail: true, textError: 'ไม่พบรหัสผู้ใช้งานนี้ในระบบ', isLoggingIn: false });
                }
                else if (response.message === 'wrong Password') {
                    this.setState({ loginFail: true, textError: 'รหัสผ่านไม่ถูกต้อง', isLoggingIn: false });
                }
            })
        );
    };


    handleOnchange = (_, { name, value }) => {
        this.setState({ [name]: value });
    };


    render() {
        if (this.state.login) {
            if (localStorage.getItem("role") !== "2") {
                return <Redirect to='/oie/entrepreneur' />;
            } else {
                return <Redirect to='/approval/tisi_oie' />;
            }

        }
        return (
            <React.Fragment>
                <Modal
                    className="bootstrap-scope"
                    open={this.props.isOpen}
                    onClose={this.props.closeModal}
                    size='tiny'
                    centered={false}
                    closeIcon
                >
                    <Modal.Header>Sign in</Modal.Header>
                    <Modal.Content >
                        <Grid columns='equal'>
                            <Grid.Column>

                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Form size={'large'} onSubmit={this.handleSummit}>

                                    <Form.Field>
                                        <Input
                                            icon='user'
                                            iconPosition='left'
                                            placeholder='Username'
                                            name='username'
                                            onChange={this.handleOnchange}
                                            ref={(input) => {
                                                this.username = input;
                                            }}
                                            maxLength='13'
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Input
                                            icon='lock'
                                            iconPosition='left'
                                            type='Password'
                                            placeholder='Password'
                                            name='password'
                                            onChange={this.handleOnchange}
                                            ref={(input) => {
                                                this.password = input;
                                            }}
                                        />
                                    </Form.Field>

                                    {this.state.loginFail && <Message size='mini' negative header={this.state.textError} content='กรุณาตรวจสอบใหม่อีกครั้ง' />}


                                    {this.state.networkFail && <Message size='mini' negative header={this.state.textError} content='กรุณาเข้าใช้งานใหม่ในภายหลัง' />}



                                    <Button loading={this.state.isLoggingIn || this.state.login} disabled={this.state.isLoggingIn || this.state.login} fluid positive size='large' type='submit'>
                                        Log in
</Button>

                                </Form>
                            </Grid.Column>
                            <Grid.Column>

                            </Grid.Column>
                        </Grid>








                    </Modal.Content>
                </Modal>




            </React.Fragment >
        );
    }
}


export default withRouter(OIELoginModal)

