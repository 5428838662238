import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Segment,
  Icon,
  Button,
  Label,
  Modal,
  Grid,
  Divider,
  Header,
  Form,
  Input,
  Transition,
} from "semantic-ui-react";
import {
  HOST_PATH_FILE,
  upload_file_tisi2718_2719,
  upload_file_tisi2721,
  GET_REQUEST_DATA_BY_ID,
} from "../router";
import axios from "axios";

const ModalUploadFile = ({
  id,
  data,
  isModalOpen,
  closeModal,
  type,
  getRequestCallback,
  currentPage,
  factoryType,
}) => {
  const [products, setProducts] = useState([]);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  useEffect(() => {
    if (isModalOpen) {
      // setIsUploadOpen(false)
      setIsUploadOpen(false);
      // console.log("modal data", data);
      // let productsArr = [...data];
      // const newProducts = productsArr.map((p) => {
      //   return { ...p, name: type === 1 ? p.file_filename_product_request : p.file_name_tisi_2721 };
      // });
      setProducts(data);
    }
  }, [isModalOpen]);

  const submitFile = async (idx) => {
    let productsArr = [...products];
    productsArr[idx].isSubmitting = true;
    setProducts(productsArr);

    let formData = new FormData();
    formData.append(
      type === 1
        ? "file_tisi2718_2719_for_product_request"
        : "file_tisi2721_for_product_request",
      productsArr[idx].data
    );

    if (type === 1) {
      formData.append("list_request", productsArr[idx].list_request_tmp);
      formData.append("license_request", productsArr[idx].license_request_tmp);
    }

    if (type === 2) {
      formData.append(
        "license_request_tisi_2721",
        productsArr[idx].license_request_tisi_2721_tmp
      );
      formData.append(
        "list_request_tisi_2721",
        productsArr[idx].list_request_tisi_2721_tmp
      );
    }

    await axios.put(
      (type === 1 ? upload_file_tisi2718_2719 : upload_file_tisi2721) +
        productsArr[idx].id,
      formData,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    productsArr[idx].isSubmitting = false;

    Swal.fire({
      type: "success",
      title: "Upload สำเร็จ",
      showConfirmButton: false,
      timer: 950,
    }).then(async () => {
      setIsUploadOpen(false)
      const {
        data: {
          value: {
            tyre_requests: { product_requests },
          },
        },
      } = await axios.get(GET_REQUEST_DATA_BY_ID + id);
      product_requests[idx].license_request_tmp = ''
      product_requests[idx].list_request_tmp = ''
      product_requests[idx].license_request_tisi_2721_tmp = ''
      product_requests[idx].list_request_tisi_2721_tmp = ''
      setProducts((prev) => {
        prev[idx] = product_requests[idx];
        return prev;
      });
      getRequestCallback(currentPage);
      // setProducts(productsArr)
    });
  };

  const handleInputChange = (e, idx) => {
    if (e.target.files[0]) {
      if (e.target.files.size > 10000000) {
        // alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
        return;
      }
      let newArr = [...products];
      newArr[idx] = {
        ...newArr[idx],
        name: e.target.files[0].name,
        data: e.target.files[0],
        path: URL.createObjectURL(e.target.files[0]),
      };
      setProducts(newArr);
    }
  };

  const handleInputText = (name, value, idx) => {
    let newArr = [...products];
    newArr[idx] = {
      ...newArr[idx],
      [name]: value,
    };
    setProducts(newArr);
  };

  return (
    <Modal
      closeIcon
      open={isModalOpen}
      onClose={() => {
        setProducts([]);
        setIsUploadOpen(false);
        closeModal();
      }}
    >
      <Modal.Header>
        ใบอนุญาต มอก. {type === 1 ? "2718-2560/2719-2560" : " 2721-2560"}
      </Modal.Header>
      <Modal.Content scrolling>
        {/* <Modal.Description> */}

        <Grid.Row>
          <Grid.Column computer={16}>
            {products.map((product, idx) => (
              <React.Fragment>
                <Segment disabled={product.isSubmitting} raised>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={12}>
                        <Header size="medium">
                          ผลิตภัณฑ์ที่ {idx + 1} :{" "}
                          {product.products.product_struct}
                          {/* {(type === 1 && product.file_path_product_request) && */}
                          {/* <Label color='green' size='large' circular>
                                                {`ใบอนุญาตที่ : ${product.license_request}  หน้าที่ : ${product.list_request}`}
                                                {}
                                            </Label> */}
                          {/* } */}
                        </Header>
{ (type ===1 ? product.license_request: product.license_request_tisi_2721)&&
                        <Header size='small'>   {`ใบอนุญาตที่ : ${type ===1 ? product.license_request: product.license_request_tisi_2721}  หน้าที่ : ${type === 1? product.list_request : product.list_request_tisi_2721}`}</Header>
}
                      </Grid.Column>
                      <Grid.Column width={4}>
                        {(type === 1
                          ? product.file_path_product_request
                          : product.file_path_tisi_2721) && (
                          <Button
                          fluid
                            compact
                            onClick={() => setIsUploadOpen((prev) => !prev)}
                            toggle
                            icon
                            floated={"right"}
                            color="yellow"
                          >
                            <Icon name="edit" /> อัปเดทข้อมูลใบอนุญาต
                          </Button>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  {/* Upload box for not file Uploaded yet or edit */}
                  {/* {(!(type === 1 ? product.file_path_product_request : product.file_path_tisi_2721) || (type === 1 && product.status_uploaded_file_product_request === 2)) && */}
                  <Transition
                    visible={isUploadOpen}
                    animation="scale"
                    duration={200}
                  >
                    <div>
                      {/* {type === 1 && */}
                      <Form>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column computer={8} mobile={16}>
                              <Form.Field disabled={false}>
                                <label>ใบอนุญาตที่</label>
                                <Input
                                  name={`license_request${
                                    type === 2 ? "_tisi_2721" : ""
                                  }_tmp`}
                                  placeholder={`ตัวอย่าง ${
                                    factoryType === "โรงงานผลิต"
                                      ? "ท xxxx-xx"
                                      : "น xxxxx-xx"
                                  }/${
                                    type === 1 ?  (product.products.type_product_id.toString() ==="1" ? "2718" : "2719" ) : "2721"
                                  }`}
                                  value={
                                    type === 1
                                      ? product.license_request_tmp
                                      : product.license_request_tisi_2721_tmp
                                  }
                                  onChange={async (e, { name, value }) => {
                                    handleInputText(name, value, idx);
                                  }}
                                  // defaultValue={product.license_request}
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                              <Form.Field disabled={false}>
                                <label>หน้าที่</label>

                                <Input
                                  name={`list_request${
                                    type === 2 ? "_tisi_2721" : ""
                                  }_tmp`}
                                  placeholder="กรอกหมายเลขหน้า"
                                  value={
                                    type === 1
                                      ? product.list_request_tmp
                                      : product.list_request_tisi_2721_tmp
                                  }
                                  onChange={async (e, { name, value }) => {
                                    handleInputText(name, value, idx);
                                  }}
                                  // value={product.list_request}
                                />
                                {/* ${product.license_request}  หน้าที่ : ${product.list_request} */}
                                <p style={{ color: "#db2828" }}>
                                  หมายเหตุ:
                                  กรอกหมายเลขหน้าที่ปรากฏรายการยางรถยนต์ข้างต้น
                                  หากมีมากกว่า 1 หน้า ให้ใช้เครื่องหมาย ,
                                  คั่นระหว่างตัวเลข เช่น 1, 3, 4
                                </p>
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form>
                      {/* } */}
                      <Segment id={`TISI_File_${idx}`}>
                        <Label
                          attached="top"
                          color={"viloet"}
                          as="label"
                          htmlFor={`TISI_file_${idx}`}
                          size="large"
                        >
                          <Icon name="upload" />
                          เลือกไฟล์
                          <input
                            hidden
                            id={`TISI_file_${idx}`}
                            type="file"
                            accept=".pdf"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => handleInputChange(e, idx)}
                          />
                        </Label>
                        <p>
                          {product.name}
                          &nbsp; &nbsp;
                          {product.name && (
                            <Icon
                              color="red"
                              size="large"
                              name="times circle outline"
                              link
                              onClick={(e) => {
                                let newArr = [...products];
                                delete newArr[idx].name;
                                delete newArr[idx].data;
                                delete newArr[idx].path;
                                setProducts(newArr);
                              }}
                            />
                          )}
                        </p>
                      </Segment>

                      <Button
                        loading={product.isSubmitting}
                        positive
                        onClick={() => submitFile(idx)}
                        size="large"
                        content="Upload"
                        icon="upload"
                        labelPosition="left"
                        disabled={
                          type === 1
                            ? !product.data ||
                              !product.list_request_tmp ||
                              !product.license_request_tmp
                            : !product.data ||
                            !product.list_request_tisi_2721_tmp ||
                            !product.license_request_tisi_2721_tmp
                        }
                      />
                      {type === 1 &&
                        product.status_uploaded_file_product_request === 2 && (
                          <React.Fragment>
                            <p style={{ marginTop: "12px", color: "#db2828" }}>
                              <span style={{ fontWeight: "bold" }}>
                                หมายเหตุ:{" "}
                              </span>
                              การอัปโหลดนี้เป็นการอัปโหลดเพื่อแก้ไขใบอนุญาต มอก.
                              2718/2560 หรือ 2719/2560
                              ผู้ใช้งานสามารถอัปโหลดได้เพียงครั้งเดียวเท่านั้น
                              กรุณาตรวจสอบความถูกต้องของไฟล์ก่อนอัปโหลด
                            </p>
                          </React.Fragment>
                        )}
                    </div>
                 
                  </Transition>
                  {/* } */}
                  {!(type === 1
                    ? product.file_path_product_request
                    : product.file_path_tisi_2721) && (
                      <div>
                      {/* {type === 1 && */}
                      <Form>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column computer={8} mobile={16}>
                              <Form.Field disabled={false}>
                                <label>ใบอนุญาตที่</label>
                                <Input
                                  name={`license_request${
                                    type === 2 ? "_tisi_2721" : ""
                                  }_tmp`}
                                  placeholder={`ตัวอย่าง ${
                                    factoryType === "โรงงานผลิต"
                                      ? "ท xxxx-xx"
                                      : "น xxxxx-xx"
                                  }/${
                                   type === 1 ?  (product.products.type_product_id.toString() ==="1" ? "2718" : "2719" ) : "2721"
                                    
                                     
                                  
                                  }`}
                                  value={
                                    type === 1
                                      ? product.license_request_tmp
                                      : product.license_request_tisi_2721_tmp
                                  }
                                  onChange={async (e, { name, value }) => {
                                    handleInputText(name, value, idx);
                                  }}
                                  // defaultValue={product.license_request}
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                              <Form.Field disabled={false}>
                                <label>หน้าที่</label>

                                <Input
                                  name={`list_request${
                                    type === 2 ? "_tisi_2721" : ""
                                  }_tmp`}
                                  placeholder="กรอกหมายเลขหน้า"
                                  value={
                                    type === 1
                                      ? product.list_request_tmp
                                      : product.list_request_tisi_2721_tmp
                                  }
                                  onChange={async (e, { name, value }) => {
                                    handleInputText(name, value, idx);
                                  }}
                                  // value={product.list_request}
                                />
                                {/* ${product.license_request}  หน้าที่ : ${product.list_request} */}
                                <p style={{ color: "#db2828" }}>
                                  หมายเหตุ:
                                  กรอกหมายเลขหน้าที่ปรากฏรายการยางรถยนต์ข้างต้น
                                  หากมีมากกว่า 1 หน้า ให้ใช้เครื่องหมาย ,
                                  คั่นระหว่างตัวเลข เช่น 1, 3, 4
                                </p>
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form>
                      {/* } */}
                      <Segment id={`TISI_File_${idx}`}>
                        <Label
                          attached="top"
                          color={"viloet"}
                          as="label"
                          htmlFor={`TISI_file_${idx}`}
                          size="large"
                        >
                          <Icon name="upload" />
                          เลือกไฟล์
                          <input
                            hidden
                            id={`TISI_file_${idx}`}
                            type="file"
                            accept=".pdf"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={(e) => handleInputChange(e, idx)}
                          />
                        </Label>
                        <p>
                          {product.name}
                          &nbsp; &nbsp;
                          {product.name && (
                            <Icon
                              color="red"
                              size="large"
                              name="times circle outline"
                              link
                              onClick={(e) => {
                                let newArr = [...products];
                                delete newArr[idx].name;
                                delete newArr[idx].data;
                                delete newArr[idx].path;
                                setProducts(newArr);
                              }}
                            />
                          )}
                        </p>
                      </Segment>

                      <Button
                        loading={product.isSubmitting}
                        positive
                        onClick={() => submitFile(idx)}
                        size="large"
                        content="Upload"
                        icon="upload"
                        labelPosition="left"
                        disabled={
                          type === 1
                            ? !product.data ||
                              !product.list_request_tmp ||
                              !product.license_request_tmp
                            : !product.data ||
                            !product.list_request_tisi_2721_tmp ||
                            !product.license_request_tisi_2721_tmp
                        }
                      />
                      {type === 1 &&
                        product.status_uploaded_file_product_request === 2 && (
                          <React.Fragment>
                            <p style={{ marginTop: "12px", color: "#db2828" }}>
                              <span style={{ fontWeight: "bold" }}>
                                หมายเหตุ:{" "}
                              </span>
                              การอัปโหลดนี้เป็นการอัปโหลดเพื่อแก้ไขใบอนุญาต มอก.
                              2718/2560 หรือ 2719/2560
                              ผู้ใช้งานสามารถอัปโหลดได้เพียงครั้งเดียวเท่านั้น
                              กรุณาตรวจสอบความถูกต้องของไฟล์ก่อนอัปโหลด
                            </p>
                          </React.Fragment>
                        )}
                    </div>
                  
                  )}
                  {/* Iframe for file showing  */}
                  {(type === 1
                    ? product.file_path_product_request
                    : product.file_path_tisi_2721) && (
                    <React.Fragment>
                      <iframe
                        title="file_iframe"
                        style={{
                          margin: "8px",
                          width: "100%",
                          height: window.innerHeight,
                        }}
                        src={
                          HOST_PATH_FILE +
                          (type === 1
                            ? product.file_path_product_request
                            : product.file_path_tisi_2721)
                        }
                      />
                    </React.Fragment>
                  )}
                </Segment>
                {idx !== products.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Grid.Column>
        </Grid.Row>

        {/* </Modal.Description> */}
      </Modal.Content>
    </Modal>
  );
};

export default ModalUploadFile;
