import React, { Component } from "react";
import {
    Button,
    Icon
} from 'semantic-ui-react';
import "./style.scss";

export class UnderConstruction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            landing: false
        }
    }

    render() {
        if (this.state.landing) {
            window.location.href = '/'
        }
        return (
            <React.Fragment>
                <div className={"underConstruction"}>
                    <Button
                        icon className={'btn-back'}
                        color='blue' onClick={() => this.setState({ landing: true })} >
                        <Icon name='angle double left' /> กลับหน้าหลัก </Button>
                </div>
            </React.Fragment >
        );
    }
}


export default UnderConstruction