import React, { useEffect, useState, useRef, useCallback } from "react";
// import * as Scroll from 'react-scroll';
import { Link } from "react-scroll";
import { Image, Icon, Dropdown, Menu } from "semantic-ui-react";
import { Navbar, Nav, Container } from "react-bootstrap";
import TISI_logo from "../../assets/img/tisi-logo2.png";
import OIE_logo from "../../assets/img/oie-logo.png";
import ECO_logo from "../../assets/img/logo-eco.png";
// import SideNav from "./SideNav";
import OIELoginModal from "./OIELogin";
import { animations, AnimateOnChange } from "react-animation";

const NavBar = ({ activeMenu, setActiveMenu, scroll }) => {
    const images = [TISI_logo, OIE_logo];
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [activeIndex, setactiveIndex] = useState(0);
    const [dropdownMenu, setDropdownMenu] = useState("");
    // const activeMenuRef = useRef(activeMenu);
    const [_, setActive] = useState("home");

    const handler = useCallback(
        ({ target: { scrollTop } }) => {
            let elem = document.getElementById("navbar");
            if (scrollTop > 200) {
                if (!elem.classList.contains("is-sticky")) {
                    elem.classList.add("is-sticky");
                }
            } else {
                if (elem.classList.contains("is-sticky")) {
                    elem.classList.remove("is-sticky");
                }
            }
        },
        [activeMenu]
    );

    // const options = [
    // 	{ key: 1, text: 'Entrepreneurs', value: 1 },
    // 	{ key: 2, text: 'Comsumers', value: 2 },
    // ];
    // useEffect(() => {
    // 	activeMenuRef.current = activeMenu;
    // }, [activeMenu]);

    useEffect(() => {
        setActive(activeMenu);
        document.getElementById("snap").addEventListener("scroll", handler);

        return () => document.getElementById("snap").removeEventListener("scroll", handler);
    }, [activeMenu]);

    useEffect(() => {
        const interval = setInterval(() => {
            activeIndex === 0 ? setactiveIndex(1) : setactiveIndex(0);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const closeModal = () => {
        setIsOpenModal(false);
    };

    const closeNavbar = () => {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    };

    const onClickMenu = (name) => {
        // let elem = document.getElementById("navbar");

        if (name !== "reports" && name !== "search") {
            document.getElementById("snap").addEventListener("scroll", handler);
            // elem.classList.contains('is-sticky') && elem.classList.remove("is-sticky");
            // activeMenu === "home" && elem.classList.remove("is-sticky");
        }
        scroll(name);
    };

    return (
        <React.Fragment>
            <OIELoginModal
                isOpen={isOpenModal}
                closeModal={closeModal}
            />

            <Navbar
                sticky="top"
                id="navbar"
                bg="light"
                expand="lg"
                className="navbar navbar-expand-lg navbar-light bg-light"
                collapseOnSelect={true}
            >
                <Container className="nav_container">
                    <Navbar.Brand>
                        {/* <span>E</span>CO tyre */}
                        <AnimateOnChange>
                            <Image
                                onClick={() => onClickMenu("home")}
                                onDoubleClick={() => setIsOpenModal(true)}
                                className="navbar-logo"
                                style={{ animation: animations.popIn }}
                                src={images[activeIndex]}
                            />
                        </AnimateOnChange>
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        id="collaspe-btn"
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            {/* <Nav.Item>
								{activeMenu === 'search' ? (
									<a
										className={`nav-link active`}
										onClick={() => {
											closeNavbar();
											onClickMenu('search');
										}}
									>
										<div>
											<Icon
												size="big"
												name="search"
											/>
											<div className="nav_icon"> ค้นหายาง </div>
										</div>
									</a>
								) : (
									<div
										className="input-search"
										onClick={() => {
											closeNavbar();
											onClickMenu('search');
										}}
									>
										<div className="input-search-text">ค้นหายาง</div>
										<div className="input-search-icon">
											<Icon
												size="small"
												name="search"
											/>
										</div>
									</div>
								)}
							</Nav.Item> */}
                            <Nav.Item>
                                <a
                                    className={`nav-link ${activeMenu === "home" && "active"}`}
                                    onClick={() => {
                                        closeNavbar();
                                        onClickMenu("home");
                                    }}
                                >
                                    <div>
                                        <Icon
                                            size="big"
                                            name="home"
                                        />
                                        <div className="nav_icon"> Home </div>
                                    </div>

                                    {/* <div>Home</div> */}
                                </a>
                            </Nav.Item>

                            <Nav.Item>
                                <a
                                    className={`nav-link ${activeMenu === "about" && "active"}`}
                                    onClick={() => {
                                        closeNavbar();
                                        onClickMenu("about");
                                    }}
                                >
                                    <Icon
                                        size="big"
                                        name="info"
                                        style={{ fontSize: "1.9em" }}
                                    />
                                    <div
                                        className="nav_icon"
                                        style={{ paddingLeft: "0px" }}
                                    >
                                        {" "}
                                        About Us{" "}
                                    </div>
                                    {/* About */}
                                </a>
                            </Nav.Item>

                            <Nav.Item>
                                <a
                                    className={`nav-link ${activeMenu === "eco_stickers" && "active"}`}
                                    // onClick={() => {
                                    //   closeNavbar();
                                    //   onClickMenu("eco_stickers");
                                    // }}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        closeNavbar();
                                        onClickMenu("eco_stickers");
                                    }}
                                >
                                    <Image
                                        size="tiny"
                                        style={{ marginRight: "3px", display: "inline-block" }}
                                        src={ECO_logo}
                                    />
                                    {/* <div className="nav_icon"> ECO Sticker </div> */}
                                    <div className="nav_icon">
                                        ECO Sticker
                                        {/* <Dropdown
                      className="dropdown"
                      text="ECO Sticker"
                      // open
                      // options={options}
                      simple
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          active={dropdownMenu === "entrepreneur"}
                          onClick={() => {
                            closeNavbar();
                            onClickMenu("eco_stickers");
                            setDropdownMenu("entrepreneur");
                          }}
                          text="Entrepreneur"
                        />
                        <Dropdown.Item
                          text="Consumer"
                          active={dropdownMenu === "consumer"}
                          onClick={() => setDropdownMenu("consumer")}
                        />
                      </Dropdown.Menu>
                    </Dropdown> */}
                                    </div>
                                </a>
                            </Nav.Item>

                            <Nav.Item>
                                <a
                                    className={`nav-link ${activeMenu === "regulation" && "active"}`}
                                    onClick={() => {
                                        closeNavbar();
                                        onClickMenu("regulation");
                                    }}
                                >
                                    <Icon
                                        size="big"
                                        name="law"
                                        style={{ fontSize: "1.85em" }}
                                    />
                                    <div
                                        className="nav_icon"
                                        // style={{ paddingLeft: '10px' }}
                                    >
                                        Regulation
                                    </div>
                                    {/* Regulation */}
                                </a>
                            </Nav.Item>

                            {/* <Nav.Item>
                <a
                  className={`nav-link ${activeMenu === "news" && "active"}`}
                  onClick={() => {
                    closeNavbar();
                    onClickMenu("news");
                  }}
                >
                  <Icon size="big" name="newspaper" />
                  <div className="nav_icon"> News </div>
                </a>
              </Nav.Item> */}
                            <Nav.Item>
                                <a
                                    className={`nav-link ${activeMenu === "contact" && "active"}`}
                                    onClick={() => {
                                        closeNavbar();
                                        onClickMenu("contact");
                                    }}
                                >
                                    <Icon
                                        size="big"
                                        name="mail outline"
                                    />
                                    <div className="nav_icon"> Contact us</div>
                                    {/* News */}
                                </a>
                            </Nav.Item>
                            {/* 
              <Nav.Item>
                <a
                  className={`nav-link ${activeMenu === "reports" && "active"}`}
                  onClick={() => {
                    closeNavbar()
                    onClickMenu('reports')
                  }}
                >
                  <Icon size="big" name="area graph" />
                  <div className="nav_icon"> Reports </div>
                </a>
              </Nav.Item> */}
                            {/* </Link> */}

                            {/* 
              <Nav.Item>
                <a
                  className={`nav-link ${activeMenu === "news" && "active"}`}
                  onClick={() => {
                    closeNavbar()
                    onClickMenu('news')
                  }}
                >
                  <Icon size="big" name="newspaper" />
                  <div className="nav_icon"> News </div>
                </a>
              </Nav.Item> */}

                            {/* *** News menu link to underconstruction */}
                            {/* <Nav.Item>
                <Link
                    activeClass="active"
                    to="news"
                    containerId='snap'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick={closeNavbar}
                >
                    <Icon size='big' name='newspaper' />
                    <div className='nav_icon'> News </div>
                </Link>

                <a
                  className={`nav-link`}
                  href="/underconstruct"
                >
                  <Icon size="big" name="newspaper" />
                  <div className="nav_icon"> News</div>
                </a>
              </Nav.Item> */}

                            {/* <Nav.Item>
                                SideNav: src/components/SideNav
                                <SideNav />
                            </Nav.Item> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
};

export default NavBar;
