import React from "react";
import { Loader, Table } from "semantic-ui-react";

const MainTable = ({ columns, activeMenu, data = [], loading = false }) => {
    return (
        <div style={{ overflowX: "auto", display: "flex", flexDirection: "column", minHeight: "50vh", backgroundColor: "#f0f0f0" }}>
            <Table
                celled
                unstackable
                structured
            >
                <Table.Header>
                    <Table.Row textAlign="center">
                        {columns?.map((item, i) => {
                            if (item?.showOnTab) {
                                if (item?.showOnTab?.includes(activeMenu)) {
                                    return (
                                        <Table.HeaderCell
                                            key={i}
                                            style={{ width: "10px", ...(item.styleHead || {}) }}
                                        >
                                            {item.label}
                                        </Table.HeaderCell>
                                    );
                                } else {
                                    return null;
                                }
                            }
                            return (
                                <Table.HeaderCell
                                    key={i}
                                    style={{ width: "10px", ...(item.styleHead || {}) }}
                                >
                                    {item.label}
                                </Table.HeaderCell>
                            );
                        })}
                    </Table.Row>
                </Table.Header>
                {data?.length !== 0 && !loading && (
                    <Table.Body>
                        {data.map((row, index) => {
                            return (
                                <Table.Row key={index}>
                                    {columns?.map((column, i) => {
                                        if (column.showOnTab) {
                                            if (column.showOnTab.includes(activeMenu)) {
                                                return (
                                                    <Table.Cell
                                                        key={`${index}-${i}`}
                                                        style={column.style}
                                                    >
                                                        {column.format && typeof column.format === "function"
                                                            ? column.format({
                                                                  index,
                                                                  data: row,
                                                              })
                                                            : row[column.id] || "-"}
                                                    </Table.Cell>
                                                );
                                            } else {
                                                return null;
                                            }
                                        }
                                        return (
                                            <Table.Cell
                                                key={`${index}-${i}`}
                                                style={column.style}
                                            >
                                                {column.format && typeof column.format === "function"
                                                    ? column.format({
                                                          index,
                                                          data: row,
                                                      })
                                                    : row[column.id] || "-"}
                                            </Table.Cell>
                                        );
                                    })}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                )}
            </Table>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "50vh",
                    }}
                >
                    <Loader size="large"></Loader>
                </div>
            ) : (
                <>
                    {(data?.length == 0 || !data) && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "50vh",
                            }}
                        >
                            ไม่มีข้อมูล
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default MainTable;
