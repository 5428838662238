import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Grid, Table, Icon, Header, Container, Button } from "semantic-ui-react";
import { GET_SEARCH_STICKER_DATA_BY_SIZE_ID } from "../router";

import { useRouteMatch } from "react-router-dom";
import NavBar from "./NavBar";
import { useHistory } from "react-router-dom";
import StickerDrawer from "./StickerDrawer";
import styles from "./StickerDetail.module.scss";
import classNames from "classnames";

const StickerDetail = () => {
    const [state, setState] = useState({});
    const match = useRouteMatch();
    const [loader_request, setLoaderRequest] = useState(true);
    // const [width_div, setWidthDiv] = useState(0);
    const history = useHistory();

    const size_id = match?.params?.sizeId;

    const tableRef = useRef();

    useEffect(() => {
        fnGetData(size_id);
    }, []);

    // useEffect(() => {
    //     if (!loader_request) {
    //         fnSetWidthColoumn();
    //     }
    // }, [loader_request]);

    const fnGetData = async (sizeId) => {
        setLoaderRequest(true);

        let data = await fnGetStickerData(sizeId);
        data.sticker.sizeId = sizeId;

        setState({
            ...data,
        });

        setLoaderRequest(false);
    };

    const fnGetStickerData = async (sizeId) => {
        try {
            let { data } = await axios.get(GET_SEARCH_STICKER_DATA_BY_SIZE_ID + sizeId);
            setLoaderRequest(false);
            return data;
        } catch (error) {
            setLoaderRequest(false);
            console.log(error);
        }
    };

    // const fnDownloadFile = (path) => {
    //     const link = document.createElement("a");

    //     link.href = SEARCH_HOST_PATH_FILE + path;

    //     link.setAttribute("download", "request_file.pdf"); //any other extension
    //     document.body.appendChild(link);

    //     link.click();
    // };

    // const fnSetWidthColoumn = async () => {
    //     let isMobile = fnDetectMobile();
    //     let mul;
    //     let minus = 0;
    //     if (isMobile) {
    //         mul = 1;
    //     } else {
    //         mul = 2;
    //         minus = 20;
    //     }
    //     setWidthDiv(tableRef?.current?.offsetWidth / mul - minus);
    // };

    // const fnDetectMobile = () => {
    //     if (window.innerWidth <= 800) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // };

    const fnHandleGoBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            history.goBack();
        } else {
            history.push("/?active=search");
        }
    };

    return (
        <>
            <article className="bootstrap-scope">
                <div
                    id="snap"
                    className="snap-container"
                >
                    <NavBar
                        activeMenu={"search"}
                        // scroll={this.scroll}
                    />
                    <Container
                        style={{ marginTop: 70, paddingBottom: 20, fontFamily: "Sarabun" }}
                        className={classNames(styles.container, styles.override)}
                        data-type="override"
                    >
                        <div style={{ padding: "10px 0 " }}>
                            <Button
                                style={{ fontFamily: "Sarabun" }}
                                onClick={() => fnHandleGoBack()}
                            >
                                ย้อนกลับ
                            </Button>
                        </div>
                        {loader_request ? (
                            <div
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%" }}
                            >
                                <Icon
                                    loading
                                    name="spinner"
                                />
                                <span>กำลังโหลดข้อมูล...</span>
                            </div>
                        ) : (
                            <div ref={tableRef}>
                                <Grid centered>
                                    <Grid.Row>
                                        <Grid.Column
                                            mobile={16}
                                            tablet={8}
                                            computer={10}
                                        >
                                            <Table
                                                celled
                                                striped
                                            >
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell
                                                            colSpan="2"
                                                            width={1}
                                                        >
                                                            <Icon name="user" />
                                                            ข้อมูลการร้องขอ
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell>ประเภทผู้ยื่นขอ</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.tyreRequest?.type_factories?.name
                                                                ? state?.tyreRequest.type_factories.name
                                                                : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>โรงงาน</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.tyreRequest?.factories?.name_th
                                                                ? state?.tyreRequest.factories.name_th
                                                                : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>

                                            <Table
                                                celled
                                                striped
                                                width="100%"
                                                // style={{ margin: "20" }}
                                            >
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell
                                                            colSpan="2"
                                                            width={1}
                                                        >
                                                            <Icon name="user" />
                                                            ข้อมูลผลิตภัณฑ์ <br />
                                                            {state?.productRequests?.products?.product_struct
                                                                ? state?.productRequests.products.product_struct
                                                                : "-"}
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {state?.productRequests?.license_request && (
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                เลขที่ใบอนุญาต มอก.
                                                                {state.productRequests.products.type_product_id === 1 ? "2718" : "2719"}
                                                                -2560
                                                            </Table.Cell>
                                                            <Table.Cell>{state.productRequests.license_request}</Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                    {state?.productRequests?.list_request && (
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                หน้าที่ (มอก.
                                                                {state?.productRequests?.products?.type_product_id === 1 ? "2718" : "2719"}
                                                                -2560)
                                                            </Table.Cell>
                                                            <Table.Cell>{state?.productRequests.list_request}</Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                    {/* {state?.productRequests?.file_path_product_request && (
                                                        <Table.Row>
                                                            <Table.Cell colSpan="2">
                                                                <button
                                                                    onClick={() =>
                                                                        fnDownloadFile(state?.productRequests?.file_path_product_request)
                                                                    }
                                                                    className="large ui orange fluid button"
                                                                    style={{ fontFamily: "Sarabun" }}
                                                                >
                                                                    ดาวน์โหลดใบอนุญาต มอก.
                                                                    {state?.productRequests?.products?.type_product_id === 1
                                                                        ? "2718"
                                                                        : "2719"}
                                                                    -2560
                                                                </button>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )} */}
                                                    {state?.productRequests?.license_request_tisi_2721 && (
                                                        <Table.Row>
                                                            <Table.Cell>เลขที่ใบอนุญาต มอก. 2721-2560</Table.Cell>
                                                            <Table.Cell>{state.productRequests.license_request_tisi_2721}</Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                    {state?.productRequests?.list_request_tisi_2721 && (
                                                        <Table.Row>
                                                            <Table.Cell>หน้าที่ (มอก. 2721-2560)</Table.Cell>
                                                            <Table.Cell>{state?.productRequests.list_request_tisi_2721}</Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                    {/* {state?.productRequests?.file_path_tisi_2721 && (
                                                        <Table.Row>
                                                            <Table.Cell colSpan="2">
                                                                <button
                                                                    onClick={() =>
                                                                        fnDownloadFile(state?.productRequests.file_path_tisi_2721)
                                                                    }
                                                                    className="large ui orange fluid button"
                                                                    style={{ fontFamily: "Sarabun" }}
                                                                >
                                                                    ดาวน์โหลดใบอนุญาต มอก. 2721-2560
                                                                </button>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )} */}
                                                    <Table.Row>
                                                        <Table.Cell
                                                            colSpan="2"
                                                            warning
                                                        >
                                                            <Header as="h5">
                                                                ชื่อทางการค้า
                                                                {state?.brandRequest?.brands.name ? state?.brandRequest.brands.name : "-"}
                                                            </Header>
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell colSpan="2">
                                                            <Header as="h5">
                                                                รุ่น {state?.genRequest?.gen_name ? state?.genRequest.gen_name : "-"}
                                                            </Header>
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell colSpan="2">
                                                            <Header as="h5">
                                                                ขนาด {state?.sizeRequest?.size_text ? state?.sizeRequest.size_text : "-"}
                                                            </Header>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>ดัชนีความสามารถการรับโหลด</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.sizeRequest?.load_capacity ? state?.sizeRequest.load_capacity : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell>สัญลักษณ์ความเร็ว</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.sizeRequest?.speed_symbol ? state?.sizeRequest.speed_symbol : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>คุณลักษณะพิเศษ</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.sizeRequest?.special_feature ? state?.sizeRequest.special_feature : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>โครงสร้าง</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.sizeRequest?.size_struct ? state?.sizeRequest.size_struct : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>ประเภทใช้งาน</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.sizeRequest?.type_use ? state?.sizeRequest.type_use : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>ความกว้างภาคตัดระบุ</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.sizeRequest?.cutting_width ? state?.sizeRequest.cutting_width : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>เครื่องหมาย</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.sizeRequest?.symbol ? state?.sizeRequest.symbol : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell>ค่าสัมประสิทธิ์ของความต้านทานการหมุน</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.testResult
                                                                ? `${state?.testResult?.rs_test_value || ""} ${
                                                                      state?.testResult?.rs_test_unit || ""
                                                                  }`
                                                                : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell>ค่าดัชนีของการยึดเกาะถนนบนพื้นเปียก</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.testResult
                                                                ? `${state?.testResult?.rg_test_value || ""} ${
                                                                      state?.testResult?.rg_test_unit || ""
                                                                  }`
                                                                : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell>ค่าระดับเสียงจากยางล้อที่สัมผัสผิวถนน</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.testResult
                                                                ? `${state?.testResult.tr_test_value || ""} ${
                                                                      state?.testResult.tr_test_unit || ""
                                                                  }`
                                                                : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>ค่าดัชนีการเกาะถนนหิมะ</Table.Cell>
                                                        <Table.Cell>
                                                            {state?.testResult
                                                                ? `${state?.testResult.sr_test_value || ""} ${
                                                                      state?.testResult.sr_test_unit || ""
                                                                  }`
                                                                : "-"}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>

                                        <Grid.Column
                                            mobile={16}
                                            tablet={8}
                                            computer={6}
                                            style={{ padding: "0px", margin: "0px" }}
                                        >
                                            <StickerDrawer sizeId={size_id} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                {/* </Ref> */}
                            </div>
                        )}
                    </Container>
                </div>
            </article>
        </>
    );
};

export default StickerDetail;
