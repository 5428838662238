
import React, { Component } from 'react';
import { Segment, Divider, Header, Icon, Container, TextArea, Form, Input, Label, Button, Grid } from 'semantic-ui-react';
import axios from 'axios';
import { GET_PROVINCE, GET_DISTRICT, GET_SUB_DISTRICT } from '../router';
import { Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux'; // connect redux
import { reduxSource } from '../actions/actions';
import Swal from 'sweetalert2';
import Headers from '../header/requestHeader';
import { withRouter } from "react-router-dom";

// Select Name Prefix Place #############################################
const namePrefixTH = [
    { key: 1, text: 'นาย', value: 1 },
    { key: 2, text: 'นางสาว', value: 2 },
    { key: 3, text: 'นาง', value: 3 },

]

const namePrefixEN = [
    { key: 1, text: 'Mr.', value: 1 },
    { key: 2, text: 'Miss', value: 2 },
    { key: 3, text: 'Mrs.', value: 3 },

]


class addDelegate extends Component {
    constructor() {
        super();
        this.refPrefixNameTH = React.createRef()
        this.refPrefixNameEN = React.createRef()
        this.state = {
            redirect: false,
            redirectConfirm: false,
            show_name_th: true,
            show_name_en: true,
            show_file_idcard: true,
            show_file_poa: true, // POA = Power of Attorney
            show_file_passport: false,
            show_address: true,
            show_alley: true,
            show_road: true,
            show_province_form: true,
            show_district_form: true,
            show_sub_district_form: true,
            show_zipcode_form: true,
            show_foreigner_address: false,
            show_phone_number: true,
            show_email: true,
            nationality_value: 1,
            nationality_text: "ไทย",
            nationality_index: 0,

            name_prefixTH_value_error: false,
            // name_prefixTH_value: 1,
            // name_prefixTH_index: 0,
            // name_prefixTH_text: "นาย",

            name_prefixEN_value_error: false,
            // name_prefixEN_value: 1,
            // name_prefixEN_index: 0,
            // name_prefixEN_text: "Mr.",

            delegate_dataprovince: [],
            district_Disable: true,
            sub_district_Disable: true,
            delegate_datadistrict: [],
            delegate_dataSubDistrict: [],
            tempSubDistrict: [],
            zipcode_disabled_state: true,
            province_error: false,
            district_error: false,
            sub_district_error: false,
            zip_code_error: false,

            delegate_nameTH: "",
            delegate_nameEN: "",
            delegate_id_number: "",
            delegate_passport_number: "",
            delegate_address_id: "",
            delegate_alley: "",
            delegate_road: "",
            delegate_province: "",
            delegate_province_text: "",
            delegate_district: "",
            delegate_district_text: "",
            delegate_sub_district: "",
            delegate_sub_district_text: "",
            province: "",
            district: "",
            sub_district: "",
            delegate_zip_code: "",
            foreigner_address: "",
            delegate_phone_number: "",
            delegate_email: "",

            file_idcard: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ
            file_poa: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ
            file_passport: { name: "", data: [], path: "" }, //ไม่ตรวจสอบ

            authen: false,
            loading: false,
            checkValue: {
                delegate_address_id: false,
                delegate_alley: false,
                delegate_road: false,
            },
            TypePersons: [
                { key: 1, text: 'ไทย', value: 1 },
                { key: 2, text: 'ต่างชาติ', value: 2 }
            ],
            tax_id_pass: false
        };
    }

    nationalityState = async (e, { name, value }) => {

        this.setDefaultError()
        this.setDefaultValue()
        await this.setState({
            nationality_value: value,
            nationality_index: value - 1,
            nationality_text: e.target.textContent,
        })

    }

    // Start Get Province Method ##############################################
    GetProvince = async () => {
        try {
            await axios.get(GET_PROVINCE)
                .then(res => {
                    const data = res.data.value;
                    let dataprovince = data.map(i => ({
                        key: i.name_th,
                        text: i.name_th,
                        value: i.id,
                    }))
                    this.setState({
                        delegate_dataprovince: dataprovince,
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    GetDistrict = async (value) => {
        try {
            await axios.get(GET_DISTRICT + "?province_id=" + value)
                .then(res => {
                    const data = res.data.value;
                    let datadistrict = data.map(i => ({
                        key: i.name_th,
                        text: i.name_th,
                        value: i.id,
                    }))
                    this.setState({
                        delegate_datadistrict: datadistrict,


                    })
                })
        } catch (error) {

        }
    }

    GetSubDistrict = async (value) => {
        try {
            await axios.get(GET_SUB_DISTRICT + "?amphure_id=" + value)
                .then(res => {
                    const data = res.data.value;
                    let dataSubDistrict = data.map(i => ({
                        key: i.name_th,
                        text: i.name_th,
                        value: i.id,
                    }))
                    this.setState({
                        delegate_dataSubDistrict: dataSubDistrict,
                        tempSubDistrict: data,
                    })
                })
        } catch (error) {

        }
    }

    GetPostCode = async (value) => {
        try {
            let zipcode = this.state.tempSubDistrict.find((data) => {
                return data.id === value;
            })
            this.setState({
                // Fields: {
                //     zip_code: zipcode.zip_code
                // },
                delegate_zip_code: zipcode.zip_code,
                zip_code_error: false
            })
        } catch (error) {

        }
    }

    handleChangeAddress = (event, { name, value }) => {
        if (name === "province") {
            if (value === "") {
                this.setState({
                    delegate_datadistrict: "",
                    delegate_dataSubDistrict: "",
                    delegate_province: "",
                    delegate_district: "",
                    delegate_sub_district: "",
                    delegate_zip_code: "",
                })
                this.setState({
                    //Disabled State
                    district_Disable: true,
                    sub_district_Disable: true,
                    zipcode_disabled_state: true,
                })
            } else {
                let textValue = this.state.delegate_dataprovince.filter((data) => data.value === value);
                this.setState({
                    delegate_datadistrict: "",
                    delegate_dataSubDistrict: "",
                    delegate_district: "",
                    delegate_sub_district: "",
                    delegate_zip_code: "",

                    sub_district_Disable: true,
                    zipcode_disabled_state: true,

                    delegate_province: value,
                    delegate_province_text: textValue[0].text,
                    district_Disable: false,
                    province_error: false
                })
                this.GetDistrict(value)
            }
        }
        else if (name === "district") {
            if (value === "") {
                this.setState({
                    delegate_dataSubDistrict: "",
                    delegate_district: "",
                    delegate_sub_district: "",
                    delegate_zip_code: "",
                })
                this.setState({
                    //Disabled State
                    sub_district_Disable: true,
                    zipcode_disabled_state: true,
                })
            } else {
                let textValue = this.state.delegate_datadistrict.filter((data) => data.value === value);
                this.setState({
                    delegate_dataSubDistrict: "",
                    delegate_sub_district: "",
                    delegate_zip_code: "",

                    zipcode_disabled_state: true,

                    delegate_district: value,
                    delegate_district_text: textValue[0].text,
                    sub_district_Disable: false,
                    district_error: false
                })
                this.GetSubDistrict(value)
            }
        }
        else if (name === "sub_district") {
            if (value === "") {
                this.setState({
                    delegate_sub_district: "",
                    delegate_zip_code: "",
                })
                this.setState({
                    //Disabled State
                    zipcode_disabled_state: true,
                })
            } else {
                let textValue = this.state.delegate_dataSubDistrict.filter((data) => data.value === value);
                this.setState({
                    delegate_zip_code: "",

                    delegate_sub_district: value,
                    delegate_sub_district_text: textValue[0].text,
                    sub_district_error: false,
                    zip_code_error: false,
                    zipcode_disabled_state: false,
                })
                this.GetPostCode(value)
            }
        }
    }

    // Upload File Control ##############################################
    async handleUploadFileChange(e) {
        if (e.target.files[0].type === "application/pdf") {
            if (e.target.files[0]) {
                if (e.target.files.size > 10000000) {
                    alert("ไฟล์ใหญ่เกินที่กำหนดไว้")
                    return;
                }
                // console.log(e.target.files[0]);
                var src = URL.createObjectURL(e.target.files[0]);
                var id = e.target.id;
                const form = new FormData();
                form.append("test_file", e.target.files[0]);

                await this.setState({
                    [id]: { name: e.target.files[0].name, data: e.target.files[0], path: src }
                })
                // console.log(this.state.$[id]);
                if (id === "file_idcard") {
                    this.setState({ file_idcard_error: false })
                } else if (id === "file_passport") {
                    this.setState({ file_passport_error: false })
                } else if (id === "file_poa") {
                    this.setState({ file_poa_error: false })
                }
            }
        } else {
            Swal.fire({
                type: 'error',
                title: `ชนิดของไฟล์ไม่ถูกต้อง 
			กรุณาตรวจสอบใหม่อีกครั้ง`
            });
        }
    }


    async handleUploadFileReset(e) {
        if (e.target.id === "file_idcard_reset") {
            await this.setState({ file_idcard: { name: "", data: "", path: "" }, file_idcard_error: true });
            document.getElementById("file_idcard").value = null;
        }

        else if (e.target.id === "file_poa_reset") {
            await this.setState({ file_poa: { name: "", data: "", path: "" }, file_poa_error: true });
            document.getElementById("file_poa").value = null;
        }

        else if (e.target.id === "file_passport_reset") {
            await this.setState({ file_passport: { name: "", data: "", path: "" }, file_passport_error: true });
            document.getElementById("file_passport").value = null;
        }

    }

    //Check ID Pattern
    onIdChange = async (e, { name, value }) => {

        switch (name) {
            case "delegate_id_number":
                let res = value.replace(/-/g, "");
                let resShow = "";
                await this.setState({ delegate_id_number: res });
                for (let i = 0; i < this.state.delegate_id_number.length; i++) {
                    if (i === 1 || i === 5 || i === 10 || i === 12) {
                        resShow += "-" + res.charAt(i);
                    } else if (i === 5) {
                        resShow += "-" + res.charAt(i);
                    } else if (i === 10) {
                        resShow += "-" + res.charAt(i);
                    } else if (i === 12) {
                        resShow += "-" + res.charAt(i);
                    } else {
                        resShow += res.charAt(i);
                    }
                }
                if (resShow.length === 17) {
                    this.setState({ tax_id_pass: true });
                }
                else {
                    this.setState({ tax_id_pass: false });
                }
                await this.setState({
                    delegate_id_number: resShow,
                    delegate_id_number_error: false,
                });
                break;
            default:
                break;
        }
    }

    authentication = (ssn) => {
        // this.setState({loading: true})
        // axios.get(`${GET_DATA_PERSONAL}${ssn}`).then((response) => {
        //     if (response.data.status === 'success') {
        //         // Clear value in input form
        //         this.setState({
        //             delegate_address_id: "",
        //             delegate_alley: "",
        //             delegate_road: "",
        //         })
        //         let data = response.data.value;

        //         if(data.houseNo === null){
        //             this.setState((state) => (state.checkValue.delegate_address_id = true))
        //         } else {
        //             this.setState((state) => (state.delegate_address_id = data.houseNo ))
        //         }

        //         if(data.alleyDesc === null){
        //             this.setState((state) => (state.checkValue.delegate_alley = true))
        //         } else {
        //             this.setState((state) => (state.delegate_alley = data.alleyDesc ))

        //         }

        //         if(data.roadDesc === null){
        //             this.setState((state) => (state.checkValue.delegate_road = true))
        //         } else {
        //             this.setState((state) => (state.delegate_road = data.roadDesc ))
        //         }

        //         //set input form error
        //         this.setState({
        //             address_id_error: false,
        //             alley_error: false,
        //             road_error: false,
        //         })
        //         this.setState({ authen: true });
        //         this.setState({ loading: false });
        //     } else {
        //         this.setState({ loading: false });
        //         this.setState({ loading: false });
        //         Swal.fire({
        //             type: 'error',
        //             title: `ไม่พบข้อมูล 
        //             กรุณาตรวจสอบใหม่อีกครั้ง`
        //         });
        //     }
        // }).catch(() => {
        //     // Clear value in input form
        //     this.setState({
        //         delegate_address_id: "",
        //         delegate_alley: "",
        //         delegate_road: "",
        //     })

        //     this.setState({
        //         checkValue:{
        //             delegate_address_id: true,
        //             delegate_alley: true,
        //             delegate_road: true,
        //         }
        //     })
        //     this.setState({ authen: true });
        //     this.setState({ loading: false });
        //     Swal.fire({
        //         type: 'error',
        //         title: `เกิดข้อผิดพลาด 
        //         ไม่สามารถเชื่อมต่อกับระบบได้`
        //     });
        // });
    };

    getNamePrefix = (nameTH, nameEN) => {
        let cloneState = { ...this.state }
        const prefixTH = ['นาย', 'นางสาว', 'นาง']
        const prefixEN = ['Mr.', 'Miss', 'Mrs.']

        for (let i = 0; i < prefixTH.length; i++) {
            const idxOfPrefixTH = nameTH.indexOf(prefixTH[i])
            if (idxOfPrefixTH === 0) {
                cloneState.delegate_nameTH = cloneState.delegate_nameTH.substring((idxOfPrefixTH + prefixTH[i].length) + (cloneState.delegate_nameTH[prefixTH[i].length] !== ' ' ? 0 : 1))
                cloneState.delegateFields.name_prefixTH_value = i + 1
                cloneState.delegateFields.name_prefixTH_index = i
                cloneState.delegateFields.name_prefixTH_text = prefixTH[i]
                break
            }
        }

        for (let i = 0; i < prefixEN.length; i++) {
            const idxOfPrefixEN = nameEN.toUpperCase().indexOf(prefixEN[i].toUpperCase())
            if (idxOfPrefixEN === 0) {
                cloneState.delegate_nameEN = cloneState.delegate_nameEN.substring((idxOfPrefixEN + prefixEN[i].length) + (cloneState.delegate_nameEN[prefixEN[i].length] !== ' ' ? 0 : 1))
                cloneState.delegateFields.name_prefixEN_value = i + 1
                cloneState.delegateFields.name_prefixEN_index = i
                cloneState.delegateFields.name_prefixEN_text = prefixEN[i]
                break
            }
        }

        this.setState(cloneState)

    }


    setDefaultValue() {
        this.setState({
            delegate_nameTH: "",
            delegate_nameEN: "",
            delegate_id_number: "",
            delegate_passport_number: "",
            delegate_address_id: "",
            delegate_alley: "",
            delegate_road: "",
            delegate_province: "",
            delegate_district: "",
            delegate_sub_district: "",
            delegate_zip_code: "",
            foreigner_address: "",
            delegate_phone_number: "",
            delegate_email: "",
            file_idcard: { name: "", data: [], path: "" },
            file_passport: { name: "", data: [], path: "" },
            file_poa: { name: "", data: [], path: "" },
        })
    }

    setDefaultError() {
        this.setState({
            delegate_nameTH_error: false,
            delegate_nameEN_error: false,
            delegate_id_number_error: false,
            delegate_passport_number_error: false,
            address_id_error: false,
            alley_error: false,
            road_error: false,
            province_error: false,
            district_error: false,
            sub_district: false,
            zip_code_error: false,
            foreigner_address_error: false,
            delegate_phone_number_error: false,
            delegate_email_error: false,
            file_idcard_error: false,
            file_passport_error: false,
            file_poa_error: false,
        })
    }

    // Sent Field Value #################################################
    handleChange = (event, { name, value }) => {
        let delegateFields = this.state.delegateFields;
        delegateFields[event.target.name] = event.target.value;
        this.setState({
            delegateFields
        });

        // console.log(delegateFields)

        if (name === "delegate_nameTH") {
            this.setState({
                delegate_nameTH: value,
                delegate_nameTH_error: false,
            })
        }

        if (name === "delegate_nameEN") {
            this.setState({
                delegate_nameEN: value.toUpperCase(),
                delegate_nameEN_error: false,
            })
        }

        if (name === "delegate_id_number") {
            this.setState({
                delegate_id_number: value,
                delegate_id_number_error: false,
            })
        }

        if (name === "delegate_passport_number") {
            this.setState({
                delegate_passport_number: value,
                delegate_passport_number_error: false,
            })
        }

        if (name === "address_id") {
            this.setState({
                delegate_address_id: value,
                address_id_error: false,
            })
        }

        if (name === "alley") {
            this.setState({
                delegate_alley: value,
                alley_error: false,
            })
        }

        if (name === "road") {
            this.setState({
                delegate_road: value,
                road_error: false,
            })
        }

        if (name === "foreigner_address") {
            this.setState({
                foreigner_address: value,
                foreigner_address_error: false,
            })
        }


        if (name === "delegate_phone_number") {
            this.setState({
                delegate_phone_number: value.replace(/\D/g, ''),
                delegate_phone_number_error: false,
            })
        }

        if (name === "delegate_email") {
            this.setState({
                delegate_email: value,
                delegate_email_error: false,
            })
        }
    }

    validate() {
        if (this.state.nationality_value === 1) {
            if (this.state.file_poa.path === "") {
                this.setState({ file_poa_error: true });
            } else {
                this.setState({ file_poa_error: false });
            }

            if (this.state.file_idcard.path === "") {
                this.setState({ file_idcard_error: true });
            } else {
                this.setState({ file_idcard_error: false });
            }

            if (this.state.delegate_email === "") {
                this.setState({ delegate_email_error: true })
                this.delegate_email_input.focus();
            } else {
                this.setState({ delegate_email_error: false })
            }

            if (this.state.delegate_phone_number === "") {
                this.setState({ delegate_phone_number_error: true })
                this.delegate_phone_number_input.focus();
            } else {
                this.setState({ delegate_phone_number_error: false })
            }

            if (this.state.delegate_zip_code === "") {
                this.setState({ zip_code_error: true })
                this.delegate_zip_code_input.focus();
            } else {
                this.setState({ zip_code_error: false })
            }

            if (this.state.delegate_sub_district === "") {
                this.setState({ sub_district_error: true })
                let sub_district = document.getElementById('sub_district');
                sub_district.focus();
            } else {
                this.setState({ sub_district_error: false })
            }

            if (this.state.delegate_district === "") {
                this.setState({ district_error: true })
                let district = document.getElementById('district');
                district.focus();
            } else {
                this.setState({ district_error: false })
            }

            if (this.state.delegate_province === "") {
                this.setState({ province_error: true })
                let province = document.getElementById('province');
                province.focus();
            } else {
                this.setState({ province_error: false })
            }

            if (this.state.delegate_road === "") {
                this.setState({ road_error: true })
                this.delegate_road_input.focus();
            } else {
                this.setState({ road_error: false })
            }

            if (this.state.delegate_alley === "") {
                this.setState({ alley_error: true })
                this.delegate_alley_input.focus();
            } else {
                this.setState({ alley_error: false })
            }

            if (this.state.delegate_address_id === "") {
                this.setState({ address_id_error: true })
                this.delegate_address_id_input.focus();
            } else {
                this.setState({ address_id_error: false })
            }

            if (this.state.delegate_id_number === "" || this.state.tax_id_pass !== true) {
                this.setState({ delegate_id_number_error: true })
                this.delegate_id_number_input.focus();
            } else {
                this.setState({ delegate_id_number_error: false })
            }
            if (this.state.delegateFields.name_prefixEN_index === undefined) {
                this.setState({ name_prefixEN_value_error: true })
                this.refPrefixNameEN.current.scrollIntoView()
                window.scrollBy(0, -50);
            } else {
                this.setState({ name_prefixEN_value_error: false })
            }

            if (this.state.delegate_nameEN === "") {
                this.setState({ delegate_nameEN_error: true })
                this.delegate_nameEN_input.focus();
            } else {
                this.setState({ delegate_nameEN_error: false })
            }

            if (this.state.delegateFields.name_prefixTH_index === undefined) {
                this.setState({ name_prefixTH_value_error: true })
                this.refPrefixNameTH.current.scrollIntoView()
                window.scrollBy(0, -50);
            } else {
                this.setState({ name_prefixTH_value_error: false })
            }

            if (this.state.delegate_nameTH === "") {
                this.setState({ delegate_nameTH_error: true })
                this.delegate_nameTH_input.focus();
            } else {
                this.setState({ delegate_nameTH_error: false })
            }
            return true;

        } else if (this.state.nationality_value === 2) {
            if (this.state.file_poa.path === "") {
                this.setState({ file_poa_error: true });
            } else {
                this.setState({ file_poa_error: false });
            }

            if (this.state.file_passport.path === "") {
                this.setState({ file_passport_error: true });
            } else {
                this.setState({ file_passport_error: false });
            }

            if (this.state.delegate_email === "") {
                this.setState({ delegate_email_error: true })
                this.delegate_email_input.focus();
            } else {
                this.setState({ delegate_email_error: false })
            }

            if (this.state.delegate_phone_number === "") {
                this.setState({ delegate_phone_number_error: true })
                this.delegate_phone_number_input.focus();
            } else {
                this.setState({ delegate_phone_number_error: false })
            }

            if (this.state.foreigner_address === "") {
                this.setState({ foreigner_address_error: true })
                this.foreigner_address_input.focus();
            } else {
                this.setState({ foreigner_address_error: false })
            }

            if (this.state.delegate_passport_number === "") {
                this.setState({ delegate_passport_number_error: true })
                this.delegate_passport_number_input.focus();
            } else {
                this.setState({ delegate_passport_number_error: false })
            }

            if (this.state.delegateFields.name_prefixEN_index === undefined) {
                this.setState({ name_prefixEN_value_error: true })
                this.refPrefixNameEN.current.scrollIntoView()
                window.scrollBy(0, -50);
            } else {
                this.setState({ name_prefixEN_value_error: false })
            }

            if (this.state.delegate_nameEN === "") {
                this.setState({ delegate_nameEN_error: true })
                this.delegate_nameEN_input.focus();
            } else {
                this.setState({ delegate_nameEN_error: false })
            }

            return true;

        }
    }

    validateFormError = () => {
        if (this.state.nationality_value === 1) {
            if (this.state.name_prefixTH_value_error) {
                this.setState({
                    formError: true
                })
                return true
            }
            else if (this.state.delegate_nameTH_error === true) {
                this.setState({
                    formError: true
                })
                return true

            } else if (this.state.name_prefixEN_value_error) {
                this.setState({
                    formError: true
                })
                return true
            }
            else if (this.state.delegate_nameEN_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.delegate_id_number_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.address_id_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.alley_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.road_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.province_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.district_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.sub_district_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.zip_code_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.delegate_phone_number_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.delegate_email_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.file_idcard_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.file_poa_error) {
                this.setState({
                    formError: true
                })
                return true
            } else {
                this.setState({
                    formError: false
                })
                return false
            }

        } else if (this.state.nationality_value === 2) {

            if (this.state.name_prefixEN_value_error) {
                this.setState({
                    formError: true
                })
                return true
            }
            else if (this.state.delegate_nameEN_error === true) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.delegate_passport_number_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.foreigner_address_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.delegate_phone_number_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.delegate_email_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.file_passport_error) {
                this.setState({
                    formError: true
                })
                return true
            } else if (this.state.file_poa_error) {
                this.setState({
                    formError: true
                })
                return true
            } else {
                this.setState({
                    formError: false
                })
                return false
            }


        }

    }

    async handleSubmit() {
        await this.validate();
        let formerror = await this.validateFormError();
        // console.log(this.state.file_idcard_error + ' handleSubmit')
        // console.log(this.state.file_passport_error + ' handleSubmit')
        if (!formerror) {
            this.setState({
                redirect: true,
                // addDelegate: true,
            });
            // this.goNextRedux()
            // this.props.dispatch(addTest(this.state));
            // console.log("success : "+ this.state);
            // console.log("redirect"+ this.state.redirect)
        }

    }

    namePrefixChange = async (event, { name, value }) => {

        const content = event.target.textContent
        if (name === 'name_prefixTH') {
            await this.setState((prev) => (
                {
                    name_prefixTH_value_error: false,
                    delegateFields: {
                        ...prev.delegateFields,
                        name_prefixTH_value: value,
                        name_prefixTH_index: value - 1,
                        name_prefixTH_text: namePrefixTH[value-1].text
                    }
                }))
            // console.log(this.state.name_prefixTH_value)
            // console.log(this.state.name_prefixTH_index)
            // console.log(this.state.name_prefixTH_text)
        } else if (name === 'name_prefixEN') {

            await this.setState((prev) => (
                {
                    name_prefixEN_value_error: false,
                    delegateFields: {
                        ...prev.delegateFields,
                        name_prefixEN_value: value,
                        name_prefixEN_index: value - 1,
                        name_prefixEN_text: namePrefixEN[value-1].text
                    }
                }))
            // console.log(this.state.name_prefixEN_value)
            // console.log(this.state.name_prefixEN_index)
            // console.log(this.state.name_prefixEN_text)
        }
    }

    // Set State Redux
    goNextRedux = async (isNext = true) => {
        await this.setState({
            addDelegate: true,
            delegateFields: {
                name_prefixTH_index: this.state.delegateFields.name_prefixTH_index,
                name_prefixEN_index: this.state.delegateFields.name_prefixEN_index,
                name_prefixTH_text: this.state.delegateFields.name_prefixTH_text,
                name_prefixEN_text: this.state.delegateFields.name_prefixEN_text,
                nationality_value: this.state.nationality_value,
                nationality_index: this.state.nationality_index,
                nationality_text: this.state.nationality_text,
                delegate_nameTH: this.state.delegate_nameTH,
                delegate_nameEN: this.state.delegate_nameEN,
                delegate_id_number: this.state.delegate_id_number,
                delegate_passport_number: this.state.delegate_passport_number,
                address_id: this.state.delegate_address_id,
                alley: this.state.delegate_alley,
                road: this.state.delegate_road,
                province: this.state.delegate_province_text,
                district: this.state.delegate_district_text,
                sub_district: this.state.delegate_sub_district_text,
                zip_code: this.state.delegate_zip_code,
                foreigner_address: this.state.foreigner_address,
                delegate_phone_number: this.state.delegate_phone_number,
                delegate_email: this.state.delegate_email,
                file_idcard: this.state.file_idcard,
                file_poa: this.state.file_poa,
                file_passport: this.state.file_passport,
            },
            status_delegate: true,
        }, () => this.props.dispatch(reduxSource(this.state))
        )
        if (!isNext) {
            this.props.history.push("/company/editEntrepreneur/authorizedPerson");
        }


    }

    componentWillMount() {

        this.setState(this.props.stateRedux, () => {
            this.getNamePrefix(this.state.delegate_nameTH, this.state.delegate_nameEN)
            this.GetProvince();
        })

        window.scrollTo(0, 0);

    }


    render() {
        if (this.state.redirect === true) {
            return <Redirect push to='/company/editEntrepreneur/editionConfirm' />;
        } else if (this.state.redirectConfirm === true) {
            return <Redirect push to='/alert' />;
        } else
            return (
                <div className="App">

                    <Headers />
                    <Container >
                        <br />
                        <Form
                            onSubmit={(event) => { this.handleSubmit(event); }}
                            error={this.state.formError}
                            success={this.state.formSuccess}
                            loading={this.state.loading}
                        >
                            <Segment padded='very' color='violet'>
                                <Header>
                                    <h1><Icon color='violet' name='user circle' />ข้อมูลผู้รับมอบอำนาจ</h1>
                                </Header>
                                <Divider></Divider>
                                <br />
                                <br />

                                <Form.Field>
                                    <label>สัญชาติ<span style={{ color: "red" }}>*</span></label>
                                    <Dropdown
                                        selection
                                        fluid
                                        options={this.state.TypePersons}
                                        placeholder='เลือกประเภท'
                                        value={this.state.TypePersons[this.state.nationality_index].value}
                                        name='select_nationality'
                                        onChange={this.nationalityState}
                                    />
                                </Form.Field>
                                {this.state.nationality_value === 1 && (
                                    <Form.Field >
                                        <h4>ชื่อภาษาไทย<span style={{ color: "red" }}>*</span></h4>
                                        <Grid verticalAlign='top'>
                                            <Grid.Column mobile={16} tablet={4} computer={3}>
                                                <span id='nameTH_section' ref={this.refPrefixNameTH} />
                                                <Form.Field error={this.state.name_prefixTH_value_error}>
                                                    <Dropdown
                                                        fluid
                                                        selection
                                                        wrapSelection={false}
                                                        options={namePrefixTH}
                                                        value={this.state.delegateFields.name_prefixTH_index !== undefined ? namePrefixTH[this.state.delegateFields.name_prefixTH_index].value : null}
                                                        placeholder='Choose an option'
                                                        name="name_prefixTH"
                                                        onChange={this.namePrefixChange}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={13}>
                                                <Form.Field error={this.state.delegate_nameTH_error}>
                                                    <Input
                                                        fluid
                                                        size='large'
                                                        icon='user'
                                                        iconPosition='left'
                                                        name="delegate_nameTH"
                                                        value={this.state.delegate_nameTH}
                                                        onChange={this.handleChange}
                                                        ref={(input) => { this.delegate_nameTH_input = input; }}
                                                    />
                                                    <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                                                        หมายเหตุ : กรอกชื่อภาษาไทย เว้นวรรคชื่อและนามสกุล
								        			</p>
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </Form.Field>
                                )}
                                <Form.Field style={{ display: this.state.show_name_en ? 'block' : 'none' }}>
                                    <h4>ชื่อภาษาอังกฤษ<span style={{ color: "red" }}>*</span></h4>
                                    <Grid verticalAlign='top'>
                                        <Grid.Column mobile={16} tablet={4} computer={3}>
                                            <span id='nameEN_section' ref={this.refPrefixNameEN} />
                                            <Form.Field error={this.state.name_prefixEN_value_error}>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    wrapSelection={false}
                                                    options={namePrefixEN}
                                                    value={this.state.delegateFields.name_prefixEN_index !== undefined ? namePrefixEN[this.state.delegateFields.name_prefixEN_index].value : null}
                                                    placeholder='Choose an option'
                                                    name="name_prefixEN"
                                                    onChange={this.namePrefixChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={13}>
                                            <Form.Field error={this.state.delegate_nameEN_error}>
                                                <Input
                                                    fluid
                                                    size='large'
                                                    icon='user'
                                                    iconPosition='left'
                                                    name="delegate_nameEN"
                                                    value={this.state.delegate_nameEN}
                                                    onChange={this.handleChange}
                                                    ref={(input) => { this.delegate_nameEN_input = input; }}
                                                />
                                                <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                                                    หมายเหตุ : กรอกชื่อภาษาอังกฤษ เว้นวรรคชื่อและนามสกุล
												</p>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Form.Field>
                                {this.state.nationality_value === 1 ? (<Form.Field error={this.state.delegate_id_number_error} >
                                    <h4>เลขที่ประจำตัวประชาชน<span style={{ color: "red" }}>*</span></h4>
                                    <Grid verticalAlign='top'>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Input
                                                size="large"
                                                name="delegate_id_number"
                                                id="delegate_id_number"
                                                maxLength="17"
                                                minLength="17"
                                                placeholder="ตัวอย่าง    X-XXXX-XXXXX-XX-X"
                                                onChange={this.onIdChange}
                                                value={this.state.delegate_id_number}
                                                ref={(input) => { this.delegate_id_number_input = input; }}
                                            />
                                            <p style={{ color: "#db2828", fontWeight: 'bold', paddingTop: "1rem" }}>
                                                หมายเหตุ : กรอกเลขที่ประจำตัวประชาชน
                                            </p>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={10}>
                                            {/* <Button 
                                            style={{ width: '200px'}} 
                                            primary
                                            type='button'
                                            onClick={() => {this.authentication(this.state.delegate_id_number)}}
                                            >
                                                ตรวจสอบข้อมูล
                                        </Button> */}
                                        </Grid.Column>
                                    </Grid>
                                </Form.Field>
                                ) : (
                                        <Form.Field error={this.state.delegate_passport_number_error} inline>
                                            <label>เลขที่หนังสือเดินทาง<span style={{ color: "red" }}>*</span></label>
                                            <Grid verticalAlign='top'>
                                                <Grid.Column mobile={16} tablet={4} computer={6}>
                                                    <Form.Field >
                                                        <Input
                                                            fluid
                                                            size='large'
                                                            icon='travel'
                                                            iconPosition='left'
                                                            maxLength="15"
                                                            name="delegate_passport_number"
                                                            value={this.state.delegate_passport_number}
                                                            onChange={this.handleChange}
                                                            ref={(input) => { this.delegate_passport_number_input = input; }}
                                                        />
                                                        <p style={{ color: "#db2828", fontWeight: 'bold', paddingTop: "1rem" }}>
                                                            หมายเหตุ : กรอกเลขที่หนังสือเดินทาง
                                                </p>
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                        </Form.Field>
                                    )}
                                {this.state.nationality_value === 1 ? (
                                    <React.Fragment>
                                        <Form.Field error={this.state.address_id_error} >
                                            <h3>ที่อยู่</h3>
                                            <h4>เลขที่<span style={{ color: "red" }}>*</span></h4>
                                            <Input
                                                size='large'
                                                icon='point'
                                                iconPosition='left'
                                                name="address_id"
                                                // readOnly={
                                                //     !this.state.checkValue.address_id ||
                                                //     !this.state.authen
                                                // }
                                                value={this.state.delegate_address_id}
                                                onChange={this.handleChange}
                                                ref={(input) => { this.delegate_address_id_input = input; }}
                                            />
                                            <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                                                หมายเหตุ : กรอกเลขที่และหมู่ที่หากมี
										    </p>
                                        </Form.Field>
                                        <Form.Field error={this.state.alley_error} >
                                            <h4>ซอย<span style={{ color: "red" }}>*</span></h4>
                                            <Input
                                                size='large'
                                                icon='compress'
                                                iconPosition='left'
                                                name="alley"
                                                // readOnly={
                                                //     !this.state.checkValue.alley ||
                                                //     !this.state.authen
                                                // }
                                                value={this.state.delegate_alley}
                                                onChange={this.handleChange}
                                                ref={(input) => { this.delegate_alley_input = input; }}
                                            />
                                        </Form.Field>
                                        <Form.Field error={this.state.road_error}>
                                            <h4>ถนน<span style={{ color: "red" }}>*</span></h4>
                                            <Input
                                                size='large'
                                                icon='road'
                                                iconPosition='left'
                                                name="road"
                                                // readOnly={
                                                //     !this.state.checkValue.road ||
                                                //     !this.state.authen
                                                // }
                                                value={this.state.delegate_road}
                                                onChange={this.handleChange}
                                                ref={(input) => { this.delegate_road_input = input; }}
                                            />
                                        </Form.Field>
                                        <Form.Field error={this.state.province_error}>
                                            <h4>จังหวัด<span style={{ color: "red" }}>*</span></h4>
                                            <Dropdown
                                                fluid
                                                placeholder="เลือกจังหวัด"
                                                clearable
                                                selection
                                                search
                                                id="province"
                                                name="province"
                                                options={this.state.delegate_dataprovince}
                                                value={this.state.delegate_province}
                                                onChange={this.handleChangeAddress}
                                            />
                                        </Form.Field>
                                        <Form.Field error={this.state.district_error}>
                                            <h4>อำเภอ/เขต<span style={{ color: "red" }}>*</span></h4>
                                            <Dropdown
                                                fluid
                                                placeholder="เลือกอำเภอ/เขต"
                                                clearable
                                                selection
                                                search
                                                id="district"
                                                name="district"
                                                options={this.state.delegate_datadistrict}
                                                value={this.state.delegate_district}
                                                onChange={this.handleChangeAddress}
                                                disabled={this.state.district_Disable}
                                            />
                                        </Form.Field>
                                        <Form.Field error={this.state.sub_district_error} >
                                            <h4>ตำบล/แขวง<span style={{ color: "red" }}>*</span></h4>
                                            <Dropdown
                                                fluid
                                                placeholder="เลือกตำบล/แขวง"
                                                clearable
                                                selection
                                                search
                                                id="sub_district"
                                                name="sub_district"
                                                options={this.state.delegate_dataSubDistrict}
                                                value={this.state.delegate_sub_district}
                                                onChange={this.handleChangeAddress}
                                                disabled={this.state.sub_district_Disable}
                                            />
                                        </Form.Field>
                                        <Form.Field error={this.state.zip_code_error} >
                                            <h4>รหัสไปรษณีย์<span style={{ color: "red" }}>*</span></h4>
                                            <Input
                                                id="zip_code"
                                                icon='envelope'
                                                disabled={this.state.zipcode_disabled_state}
                                                iconPosition='left'
                                                maxLength="5"
                                                placeholder='รหัสไปรษณีย์'
                                                name="zip_code"
                                                value={this.state.delegate_zip_code}
                                                onChange={this.handleChange}
                                                ref={(input) => { this.delegate_zip_code_input = input; }}
                                            />
                                        </Form.Field>
                                    </React.Fragment>
                                ) : (
                                        <Form.Field error={this.state.foreigner_address_error} >
                                            <h4>ที่อยู่</h4>
                                            <TextArea
                                                rows={3}
                                                placeholder='กรอกที่อยู่'
                                                name="foreigner_address"
                                                value={this.state.foreigner_address}
                                                onChange={this.handleChange}
                                                ref={(input) => { this.foreigner_address_input = input; }}
                                            />
                                        </Form.Field>
                                    )}
                                <Form.Field error={this.state.delegate_phone_number_error} >
                                    <label>เบอร์โทรศัพท์<span style={{ color: "red" }}>*</span></label>
                                    <Input
                                        size='large'
                                        icon='phone'
                                        iconPosition='left'
                                        minLength="10"
                                        // maxLength="10"
                                        name="delegate_phone_number"
                                        value={this.state.delegate_phone_number}
                                        onChange={this.handleChange}
                                        ref={(input) => { this.delegate_phone_number_input = input; }}
                                    />
                                    <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                                        หมายเหตุ : กรอกเบอร์โทรศัพท์ที่สามารถติดต่อได้ โดยไม่ต้องใส่เครื่องหมาย -
									</p>
                                </Form.Field>
                                <Form.Field error={this.state.delegate_email_error} >
                                    <label>E-mail<span style={{ color: "red" }}>*</span></label>
                                    <Input
                                        size='large'
                                        icon='mail'
                                        iconPosition='left'
                                        name="delegate_email"
                                        value={this.state.delegate_email}
                                        onChange={this.handleChange}
                                        ref={(input) => { this.delegate_email_input = input; }}
                                        type='email'
                                    />
                                    <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                                        หมายเหตุ : กรอก Email ที่ใช้งานอยู่จริง และตรวจสอบตัวสะกดให้ถูกต้อง
									</p>
                                </Form.Field>
                                <Form.Field>
                                    <h2>เอกสารแนบ<span style={{ color: "red" }}>*</span></h2>
                                </Form.Field>
                                <br />
                                {this.state.nationality_value === 1 ? (
                                    <Form.Field >
                                        <Grid verticalAlign='top'>
                                            <Grid.Column mobile={16} tablet={4} computer={4}>
                                                <label><b>สำเนาบัตรประจำตัวประชาชน</b><span style={{ color: "red" }}>*</span></label>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                                <Form.Field error={this.state.file_idcard_error}>
                                                    <Segment >
                                                        <Label
                                                            attached='top'
                                                            color={this.state.file_idcard_error ? "red" : "violet"}
                                                            as="label"
                                                            htmlFor="file_idcard"
                                                            size="large"
                                                        >
                                                            <Icon name="upload" />
                                  เลือกไฟล์
                                  <input
                                                                hidden
                                                                type="file"
                                                                id="file_idcard"
                                                                name='file_idcard'
                                                                accept=".pdf"
                                                                onChange={this.handleUploadFileChange.bind(this)}
                                                            />
                                                        </Label>
                                                        <p>
                                                            {this.state.file_idcard.name}
                                    &nbsp; &nbsp;
                                    {this.state.file_idcard.name ?
                                                                <Icon
                                                                    id="file_idcard_reset"
                                                                    color="red" size="large"
                                                                    name="times circle outline"
                                                                    link
                                                                    onClick={this.handleUploadFileReset.bind(this)} />
                                                                : null}
                                                        </p>
                                                    </Segment>
                                                    <p style={{ color: "#db2828", fontWeight: 'bold', paddingTop: "1rem" }}>
                                                        ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
                            </p>
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={4} computer={3}>
                                                <Label
                                                    size="large"
                                                    basic
                                                    color='red'
                                                    pointing='left'
                                                    style={{ display: this.state.file_idcard_error ? 'block' : 'none' }}
                                                >
                                                    <Icon name="warning circle" />
                        กรุณาเพิ่มไฟล์ !
                      </Label>
                                            </Grid.Column>
                                        </Grid>
                                    </Form.Field>
                                ) : (
                                        <Form.Field >
                                            <Grid verticalAlign='top'>
                                                <Grid.Column mobile={16} tablet={4} computer={4}>
                                                    <label><b>สำเนาหนังสือเดินทาง</b><span style={{ color: "red" }}>*</span></label>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                    <Form.Field error={this.state.file_passport_error}>
                                                        <Segment >
                                                            <Label
                                                                attached='top'
                                                                color={this.state.file_passport_error ? "red" : "violet"}
                                                                as="label"
                                                                htmlFor="file_passport"
                                                                size="large"
                                                            >
                                                                <Icon name="upload" />
                                    เลือกไฟล์
                                  <input
                                                                    hidden
                                                                    type="file"
                                                                    id="file_passport"
                                                                    name='file_passport'
                                                                    accept=".pdf"
                                                                    onChange={this.handleUploadFileChange.bind(this)}
                                                                />
                                                            </Label>
                                                            <p>
                                                                {this.state.file_passport.name}
                                    &nbsp; &nbsp;
                                    {this.state.file_passport.name ?
                                                                    <Icon
                                                                        id="file_passport_reset"
                                                                        color="red" size="large"
                                                                        name="times circle outline"
                                                                        link
                                                                        onClick={this.handleUploadFileReset.bind(this)} />
                                                                    : null}
                                                            </p>
                                                        </Segment>
                                                        <p style={{ color: "#db2828", fontWeight: 'bold', paddingTop: "1rem" }}>
                                                            ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
                            </p>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={4} computer={3}>
                                                    <Label
                                                        size="large"
                                                        basic
                                                        color='red'
                                                        pointing='left'
                                                        style={{ display: this.state.file_passport_error ? 'block' : 'none' }}
                                                    >
                                                        <Icon name="warning circle" />
                        กรุณาเพิ่มไฟล์ !
                      </Label>
                                                </Grid.Column>
                                            </Grid>
                                        </Form.Field>
                                    )}
                                <Form.Field>
                                    <Grid verticalAlign='top'>
                                        <Grid.Column mobile={16} tablet={4} computer={4}>
                                            <label><b>สำเนาหนังสือมอบอำนาจ</b><span style={{ color: "red" }}>*</span></label>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Form.Field error={this.state.file_poa_error}>
                                                <Segment >
                                                    <Label
                                                        attached='top'
                                                        color={this.state.file_poa_error ? "red" : "violet"}
                                                        as="label"
                                                        htmlFor="file_poa"
                                                        size="large"
                                                    >
                                                        <Icon name="upload" />
                                  เลือกไฟล์
                                  <input
                                                            hidden
                                                            type="file"
                                                            id="file_poa"
                                                            name='file_poa'
                                                            accept=".pdf"
                                                            onChange={this.handleUploadFileChange.bind(this)}
                                                        />
                                                    </Label>
                                                    <p>
                                                        {this.state.file_poa.name}
                                    &nbsp; &nbsp;
                                    {this.state.file_poa.name ?
                                                            <Icon
                                                                id="file_poa_reset"
                                                                color="red" size="large"
                                                                name="times circle outline"
                                                                link
                                                                onClick={this.handleUploadFileReset.bind(this)} />
                                                            : null}
                                                    </p>
                                                </Segment>
                                                <p style={{ color: "#db2828", fontWeight: 'bold', paddingTop: "1rem" }}>
                                                    ชนิดไฟล์ที่ใช้อัปโหลด : .pdf ขนาดไฟล์ไม่เกิน 10 M
                            </p>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={4} computer={3}>
                                            <Label
                                                size="large"
                                                basic
                                                color='red'
                                                pointing='left'
                                                style={{ display: this.state.file_poa_error ? 'block' : 'none' }}
                                            >
                                                <Icon name="warning circle" />
                        กรุณาเพิ่มไฟล์ !
                      </Label>
                                        </Grid.Column>
                                    </Grid>
                                </Form.Field>
                                <br />
                                <br />
                            </Segment>

                            {/* <Link to='/company/editEntrepreneur/authorizedPerson'> */}
                            <Button onClick={() => {

                                this.goNextRedux(false)
                                // this.props.history.push("/company/editEntrepreneur/authorizedPerson");
                            }}
                                type='button'
                                icon
                                labelPosition='left'
                                floated='left'>
                                กลับไปหน้าที่แล้ว
            <Icon name='left arrow' />
                            </Button>
                            {/* </Link> */}
                            <Button
                                icon
                                labelPosition='left'
                                type='submit'
                                color='violet'
                                floated='right'
                                onClick={this.goNextRedux}
                            >
                                ไปหน้าถัดไป
          <Icon name='right arrow' />
                            </Button>
                            <br />
                            <br />
                            <br />
                            <br />
                        </Form>
                    </Container>
                </div>
            );
    }
}
// export default addDelegate;
const mapStateToProps = function (state) {
    return {
        message: 'This is message from mapStateToProps',
        stateRedux: state.reduxxx || ''
    }
}

export default withRouter(connect(mapStateToProps)(addDelegate));
