import React, { Component } from "react";
import {
  Segment,
  Table,
  Button,
  Icon,
  Dimmer,
  Loader,
  Container,
  Header,
  Divider,
  Dropdown,
  Pagination,
  Accordion,
  Modal,
  Image
} from "semantic-ui-react";
import ApprovalHeader from "../ApprovalHeader";
import {
  HOST_PATH_FILE,
  finance_get_all,
  approve_receipt,
  not_approve_receipt,
  send_email_by_factory_id
} from "../../router";
import Axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const allSizeRow = [
  { key: 1, text: 5, value: 5 },
  { key: 2, text: 10, value: 10 },
  { key: 3, text: 20, value: 20 }
];

export default class SubmitInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      rowQty: 5,
      currentPage: 1,
      pageQty: 1,
      companySeleted: "",
      activeIndex: -1
    };
  }
  componentDidMount = async () => {
    await this.getData();
  };

  getData = async () => {
    this.setState({
      loader_all: true
    });
    await this.getFinanceAll();

    // console.log("state", this.state);

    this.setState({
      loader_all: false
    });
  };

  getFinanceAll = async () => {
    try {
      let { data, status } = await Axios.get(finance_get_all);

     
      if (status === 200) {
        await this.setState(
          {
            data: data.value
          },
          async () => {
            let lengthData = 1;
            if (this.state.data !== null) {
              lengthData = this.state.data.length;
            }
            let pageQty = lengthData / this.state.rowQty;
            await this.setState({
              pageQty: Math.ceil(pageQty),
              currentPage: 1
            });
          }
        );
        return;
      } else {
        return;
      }
    } catch (error) {
     
      Swal.fire({
        type: "error",
        title: "ผิดพลาด",
        text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
        confirmButtonText: "ตกลง"
      });
      return;
    }
  };

  factoryName = data_state => {
    // console.log("data", data_state);
    if (data_state.factories_text_address === "") {
      // console.log(data_index, 'text address is null')
      if (data_state.factories_name_th === "") {
        return data_state.factories_name_en;
      }
      return data_state.factories_name_th;
    } else {
      // console.log(data_index, 'text address is not null')
      if (data_state.factories_name_en === "") {
        return data_state.factories_name_th;
      }

      return data_state.factories_name_en;
    }
  };

  render() {
    return (
      <div>
        <ApprovalHeader />
        <div style={{ margin: 30 }}>
          <Container fluid>
            <Header as="h2" textAlign="left">
              <Icon name="users" color="violet" />
              ส่งใบแจ้งหนี้
            </Header>
            <Divider />

            <Segment attached="bottom" padded="very" textAlign="center">
              <Dimmer active={this.state.loader_all}>
                <Loader size="medium">Loading</Loader>
              </Dimmer>
              <Table padded celled textAlign="center">
                <Table.Header className="fade-in-bottom ui ">
                  <Table.Row>
                    <Table.HeaderCell collapsing>ลำดับ</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "20%" }}>
                      บริษัท
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "35%" }}>
                      โรงงาน
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "20%" }}>
                      ที่อยู่โรงงาน
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "12%" }}>
                      สถานะ
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "13%" }}>
                      อนุมัติ / ไม่อนุมัติ
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(() => {
                    if (this.state.data.length !== 0) {
                      let tableData = [];
                      this.state.data.forEach((data_state, data_index) => {
                        if (
                          data_index >=
                            this.state.rowQty * (this.state.currentPage - 1) &&
                          data_index <
                            this.state.rowQty * this.state.currentPage
                        ) {
                          let rowData = (
                            <React.Fragment key={data_index}>
                              <Table.Row className="fade-in-bottom">
                                <Table.Cell>{data_index + 1}</Table.Cell>
                                <Table.Cell style={{ verticalAlign: "top" }}>
                                  {data_state.companies_name_th}
                                </Table.Cell>
                                <Table.Cell
                                  textAlign="left"
                                  // style={{ verticalAlign: "top" }}
                                >
                                  {(() => {
                                    if (
                                      data_state.tyre_requests !== undefined
                                    ) {
                                      let liFactory = [];
                                      data_state.tyre_requests.map(
                                        (tyre_data, tyre_index) => {
                                          let eachLi = (
                                            <Segment
                                              key={tyre_index}
                                              style={{
                                                padding: "0px 0px 0px 20px"
                                              }}
                                            >
                                              <li style={{ margin: 8 }}>
                                                <Link
                                                  to={{
                                                    pathname:
                                                      "/approval/product_detail",
                                                    state: {
                                                      id: tyre_data.id,
                                                      company:
                                                        tyre_data.companies_name,
                                                      factory:
                                                        tyre_data.factories_name,
                                                      entrepreneur:
                                                        tyre_data.entrepreneur,
                                                      ref_code:
                                                        tyre_data.ref_code,
                                                      approve_oie_tisi:
                                                        tyre_data.approve_oie_tisi,
                                                      approve_oie_tisi_at:
                                                        tyre_data.approve_oie_tisi_at,
                                                      approve_oie_tisi_by:
                                                        tyre_data.approve_oie_tisi_by,
                                                      cancel_oie_tisi_note:
                                                        tyre_data.cancel_oie_tisi_note,
                                                      approve_technical:
                                                        tyre_data.approve_technical,
                                                      approve_technical_at:
                                                        tyre_data.approve_technical_at,
                                                      approve_technical_by:
                                                        tyre_data.approve_technical_by,
                                                      created_at:
                                                        tyre_data.created_at
                                                    }
                                                  }}
                                                >
                                                  <Header
                                                    as="h5"
                                                    color="violet"
                                                  >
                                                    {tyre_data.ref_code}
                                                  </Header>
                                                </Link>
                                              </li>
                                            </Segment>
                                          );
                                          return liFactory.push(eachLi);
                                        }
                                      );

                                      let x = this.factoryName(data_state);
                                      return (
                                        <Accordion fluid>
                                          <Accordion.Title
                                            style={{ padding: 0 }}
                                            active={
                                              this.state.activeIndex ===
                                              data_index
                                            }
                                            index={data_index}
                                            onClick={(e, titleProps) => {
                                              // const { index } = titleProps;
                                              // console.log("test", index);
                                              if (
                                                this.state.activeIndex !==
                                                data_index
                                              ) {
                                                this.setState({
                                                  activeIndex: data_index
                                                });
                                              } else {
                                                this.setState({
                                                  activeIndex: -1
                                                });
                                              }
                                            }}
                                          >
                                            <Icon name="dropdown" />
                                            {x}
                                          </Accordion.Title>
                                          <Accordion.Content
                                            active={
                                              this.state.activeIndex ===
                                              data_index
                                            }
                                          >
                                            <ul style={{ margin: 0 }}>
                                              {liFactory}
                                            </ul>
                                          </Accordion.Content>
                                        </Accordion>
                                      );
                                    } else {
                                      return;
                                    }
                                  })()}
                                </Table.Cell>
                                <Table.Cell style={{ verticalAlign: "top" }}>
                                  {(() => {
                                    if (
                                      data_state.factories_text_address === ""
                                    ) {
                                      return (
                                        data_state.factories_address +
                                        " " +
                                        data_state.factories_soi +
                                        " " +
                                        data_state.factories_road +
                                        " " +
                                        data_state.factories_subdistrict +
                                        " " +
                                        data_state.factories_district +
                                        " " +
                                        data_state.factories_province
                                      );
                                    } else {
                                      return data_state.factories_text_address;
                                    }
                                  })()}
                                </Table.Cell>
                                <Table.Cell style={{ verticalAlign: "top" }}>
                                  {(() => {
                                    if (
                                      data_state.factories_payment_status ===
                                        null ||
                                      data_state.factories_payment_status === 7
                                    ) {
                                      return (
                                        <Button
                                          color="blue"
                                          onClick={async () => {
                                            Swal.fire({
                                              title: "ต้องการส่งใบแจ้งหนี้?",
                                              type: "warning",
                                              showCancelButton: true,
                                              confirmButtonColor: "#43A047",
                                              cancelButtonColor: "#E53935",
                                              confirmButtonText: "ยืนยัน",
                                              cancelButtonText: "ยกเลิก"
                                            }).then(async result => {
                                              if (result.value) {
                                                this.setState({
                                                  loader_all: true
                                                });

                                                try {
                                                  let {
                                                    status
                                                  } = await Axios.put(
                                                    send_email_by_factory_id +
                                                      data_state.factories_id
                                                  );

                                                  if (status === 200) {
                                                    Swal.fire({
                                                      title: "เสร็จสิ้น!",
                                                      type: "success",
                                                      confirmButtonColor:
                                                        "#03A9F4",
                                                      confirmButtonText: "ตกลง"
                                                    });
                                                    await this.getData();
                                                  } else {
                                                    Swal.fire({
                                                      type: "error",
                                                      title: "ผิดพลาด",
                                                      // text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                                                      confirmButtonText: "ตกลง"
                                                    });
                                                  }
                                                } catch (error) {
                                                  console.log(
                                                    "send email: ",
                                                    error
                                                  );
                                                }

                                                this.setState({
                                                  loader_all: false
                                                });
                                              }
                                            });
                                          }}
                                        >
                                          ส่งใบแจ้งหนี้
                                        </Button>
                                      );
                                    } else if (
                                      data_state.factories_payment_status ===
                                        5 ||
                                      data_state.factories_payment_status === 8
                                    ) {
                                      return (
                                        <Button disabled>
                                          รออัปโหลดใบเสร็จ
                                        </Button>
                                      );
                                    } else if (
                                      data_state.factories_payment_status === 6
                                    ) {
                                      return (
                                        <Modal
                                          size={"small"}
                                          trigger={
                                            <Button
                                              color="orange"
                                              onClick={() => {
                                                this.setState({
                                                  imgSrc:
                                                    data_state.file_path_receipt +
                                                    data_state.file_extension_receipt
                                                });
                                              }}
                                            >
                                              ดูใบเสร็จรับเงิน
                                            </Button>
                                          }
                                          centered={false}
                                          closeIcon={true}
                                        >
                                          <Modal.Header
                                            style={{ textAlign: "center" }}
                                          >
                                            ใบเสร็จรับเงิน
                                          </Modal.Header>
                                          <Modal.Content
                                            image
                                            style={{
                                              justifyItem: "center",
                                              alignItem: "center"
                                            }}
                                          >
                                            <Image
                                              style={{ margin: "auto" }}
                                              size="big"
                                              src={
                                                HOST_PATH_FILE + this.state.imgSrc
                                              }
                                            />
                                          </Modal.Content>
                                        </Modal>
                                      );
                                    }
                                  })()}
                                </Table.Cell>
                                <Table.Cell style={{ verticalAlign: "top" }}>
                                  {(() => {
                                    if (
                                      data_state.factories_payment_status === 6
                                    ) {
                                      return (
                                        <span>
                                          <Button
                                            color="green"
                                            onClick={async () => {
                                              Swal.fire({
                                                title: "อนุมัติใบเสร็จ?",
                                                type: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#43A047",
                                                cancelButtonColor: "#E53935",
                                                confirmButtonText: "ยืนยัน",
                                                cancelButtonText: "ยกเลิก"
                                              }).then(async result => {
                                                if (result.value) {
                                                  this.setState({
                                                    loader_all: true
                                                  });

                                                  try {
                                                    let {
                                                      status
                                                    } = await Axios.get(
                                                      approve_receipt +
                                                        data_state.factories_id
                                                    );

                                                    if (status === 200) {
                                                      Swal.fire({
                                                        title: "เสร็จสิ้น!",
                                                        type: "success",
                                                        confirmButtonColor:
                                                          "#03A9F4",
                                                        confirmButtonText:
                                                          "ตกลง"
                                                      });
                                                      await this.getData();
                                                    } else {
                                                      Swal.fire({
                                                        type: "error",
                                                        title: "ผิดพลาด",
                                                        // text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                                                        confirmButtonText:
                                                          "ตกลง"
                                                      });
                                                    }
                                                  } catch (error) {
                                                    console.log(
                                                      "approve receipt: ",
                                                      error
                                                    );
                                                  }

                                                  this.setState({
                                                    loader_all: false
                                                  });
                                                }
                                              });
                                            }}
                                          >
                                            อนุมัติ
                                          </Button>
                                          <Button
                                            color="red"
                                            onClick={async () => {
                                              Swal.fire({
                                                title: "ไม่อนุมัติใบเสร็จ?",
                                                type: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#43A047",
                                                cancelButtonColor: "#E53935",
                                                confirmButtonText: "ยืนยัน",
                                                cancelButtonText: "ยกเลิก"
                                              }).then(async result => {
                                                if (result.value) {
                                                  this.setState({
                                                    loader_all: true
                                                  });

                                                  try {
                                                    let {
                                                      status
                                                    } = await Axios.get(
                                                      not_approve_receipt +
                                                        data_state.factories_id
                                                    );

                                                    if (status === 200) {
                                                      Swal.fire({
                                                        title: "เสร็จสิ้น!",
                                                        type: "success",
                                                        confirmButtonColor:
                                                          "#03A9F4",
                                                        confirmButtonText:
                                                          "ตกลง"
                                                      });
                                                      await this.getData();
                                                    } else {
                                                      Swal.fire({
                                                        type: "error",
                                                        title: "ผิดพลาด",
                                                        // text: "เกิดข้อผิดพลาดในการดึงข้อมูล",
                                                        confirmButtonText:
                                                          "ตกลง"
                                                      });
                                                    }
                                                  } catch (error) {
                                                    console.log(
                                                      "approve receipt: ",
                                                      error
                                                    );
                                                  }

                                                  this.setState({
                                                    loader_all: false
                                                  });
                                                }
                                              });
                                            }}
                                          >
                                            ไม่อนุมัติ
                                          </Button>
                                        </span>
                                      );
                                    } else
                                      return (
                                        <span>
                                          <Button disabled>อนุมัติ</Button>
                                          <Button disabled>ไม่อนุมัติ</Button>
                                        </span>
                                      );
                                  })()}
                                </Table.Cell>
                              </Table.Row>
                            </React.Fragment>
                          );
                          tableData.push(rowData);
                        }
                      });

                      return tableData;
                    } else {
                      return (
                        <Table.Row className="fade-in-bottom">
                          <Table.Cell colSpan="6">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: "200px",
                                paddingBottom: "200px"
                              }}
                            >
                              <i
                                className="big frown outline"
                                style={{ height: "50px" }}
                              />
                              <h2>ขออภัย ขณะนี้ยังไม่มีข้อมูลในระบบ</h2>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  })()}
                </Table.Body>

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="9" textAlign="right">
                      Row
                      <Dropdown
                        placeholder="Row"
                        selection
                        compact
                        name="rowQty"
                        value={this.state.rowQty}
                        options={allSizeRow}
                        onChange={this.changeRowSize}
                        style={{ marginLeft: 10, marginRight: 10, width: 60 }}
                      />
                      Page
                      <Pagination
                        boundaryRange={1}
                        activePage={this.state.currentPage}
                        siblingRange={1}
                        name="currentPage"
                        totalPages={this.state.pageQty}
                        onPageChange={(e, { name, activePage }) =>
                          this.changePage(name, Math.ceil(activePage))
                        }
                        style={{ marginLeft: 10, marginRight: 10 }}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
          </Container>
        </div>
      </div>
    );
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changePage = (name, value) => this.setState({ [name]: value });

  changeRowSize = (e, { name, value }) => {
    this.setState({ [name]: value, currentPage: 1 }, () => {
      let lengthData = this.state.data.length; // data length
      let pageQty = lengthData / this.state.rowQty; // data length หาร dropdown value
      this.setState({ pageQty: Math.ceil(pageQty) });
    });
  };
}
