import React from "react";
import "./css/NewsContent.scss";
import Fade from "react-reveal/Fade";

const NewsContent = ({ onClose, data }) => {
  return (
    <div className="container-news-detail">
      <div className="box-detail">
        <Fade top cascade>
          <div className="box-header-detail">
            <h4>{data?.title}</h4>
            <small>{data?.date}</small>
          </div>
        </Fade>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="box-image-news">
            <img src={data?.image} />
          </div>
          <div className="box-news-detail">
            <div className="news-content">
              <p>
                {data?.details}
                {data?.details}
                {data?.details}
              </p>
            </div>
          </div>
        </div>
        <div className="box-btn-close">
          <button onClick={onClose} className="btn-close-popup">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsContent;
