import React, { Component } from 'react';
import { Segment, Button, Grid, Header, Icon, Container, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import headerLogo from '../assets/img/logo-eco.png';

export default class Main extends Component {
	render() {
		const btnStyle = {
			maxWidth: '100%',
			width: '100%',
			height: '12rem'
		};
		return (
			<div>
				<Container style={{ marginTop: 50 }}>
					<Segment padded='very' color='green'>
						<Header as='h1' textAlign='left'>
							{/* <Icon name='users' color='violet' /> */}
							<Image src={headerLogo} size='massive' /> 	ระบบ ECO Sticker Tyre 
						</Header>
						<br />
						<Segment placeholder>
							<Grid columns={2} relaxed='very' textAlign='center'>
								<Grid.Column>
									<Link to='/oie'>
										<Button color='orange' style={btnStyle}>
											<h2>
												<Icon name='key' inverted color='grey' /> เข้าสู่ระบบเจ้าหน้าที่
											</h2>
											<h3>หน้าเว็บเฉพาะเจ้าหน้าที่ สศอ. สมอ. Technical service</h3>
										</Button>
									</Link>
								</Grid.Column>
								<Grid.Column>
									<Link to='/company' onClick={() => localStorage.clear()}>
										<Button color='violet' style={btnStyle}>
											<h2>
												<Icon name='users' inverted color='grey' /> เข้าสู่ระบบผู้ประกอบการ
											
											</h2>
											
											<h3>หน้าเว็บสำหรับผู้ประกอบการสำหรับยื่นคำขอสติ๊กเกอร์</h3>
										</Button>
									</Link>
								</Grid.Column>
							</Grid>
						</Segment>

						<div>
							<p style={{ textAlign: 'right' }}>
								{' '}
								เนื่องจากนโยบาย Work From Home ของรัฐบาล ตั้งแต่วันที่ 6-30 เมษายน 2563
							</p>
						</div>
						<div>
							<p style={{ textAlign: 'right' }}>
								{' '}
								หรือจนกว่าจะมีประกาศเปลี่ยนแปลง ขอให้ท่านติดต่อเจ้าหน้าที่ทาง E-mail เท่านั้น
							</p>
						</div>

						<div>
							<p style={{ textAlign: 'right' }}>
								{' '}
								ติดต่อเจ้าหน้าที่ E-mail: pichitpon@oie.go.th 
							</p>
						</div>

					</Segment>
				</Container>
			</div>
		);
	}
}
