import React, { Component } from "react";
import { Segment, Header, Icon, Container, Table, Divider, Menu, Dropdown, Pagination, Input, Button, Grid } from "semantic-ui-react";
import axios from "axios";
import {
	OIE_PENDING,
	GET_STATISTICS,
	GET_STATISTICS_RESQUEST_STICKER,
	GET_COMPANY_AMOUNT_BY_TYPE,
	GET_STICKER_AMOUNT_BY_TYPE,
} from "../router";
import Headers from "../approval/ApprovalHeader";
import { connect } from "react-redux";
import "../../css/style_oie.scss";
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, CategoryScale, BarElement, Title } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import styled from "styled-components";
import IconFilter from "../../assets/icon/icon_btn_filter.png";
import Modals from "../Modals";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement);

const ContainerIconButtonFilter = styled.div`
	display: flex;
	justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "flex-start")};
	align-items: ${(props) => (props.alignItems ? props.alignItems : "flex-start")};
`;
const BoxButtonFilter = styled.div`
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	transition: background-color 0.3s ease;
	&:hover {
		background-color: #eee;
	}
	:active {
		background-color: #ddd;
	}
`;
const ButtonIconFilter = styled.div`
	width: 30px;
	height: 30px;
	object-fit: contain;
	img {
		width: 100%;
		height: 100%;
	}
`;

const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: true,
			text: "Chart.js Bar Chart",
		},
	},
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const data = {
	labels: ["January", "February", "March", "April", "May", "June", "July"],
	datasets: [
		{
			label: "Dataset 1",
			data: ["January", "February", "March", "April", "May", "June", "July"].map(() => 30),
			backgroundColor: "rgba(255, 99, 132, 0.5)",
		},
		{
			label: "Dataset 2",
			data: ["January", "February", "March", "April", "May", "June", "July"].map(() => 50),
			backgroundColor: "rgba(53, 162, 235, 0.5)",
		},
	],
};
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.delayTimer = null;
		this.state = {
			activeItem: "รอการอนุมัติ",
			rowQty: 10,
			currentPage: 1,
			pageQty: 1,
			loading: false,
			searchTxt: "",
			menuStatus: "pending",
			stickerData: {
				datasets: [],
			},
			companyData: {
				datasets: [],
			},
			// dashboard
			dataStatisticsSticker: {
				start_date: null,
				end_date: null,
			},
			openModalStatisticsSticker: false,
			dataStickerAmount: {
				start_date: null,
				end_date: null,
			},
			openModalStickerAmount: false,
			dataCompanyAmount: {
				start_date: null,
				end_date: null,
			},
			openModalCompanyAmount: false,
		};
		this.stickerChartOptions = {
			responsive: true,
			scales: {
				y: {
					title: {
						text: "จำนวน (สติ๊กเกอร์)",
						display: true,
						font: {
							size: 16,
						},
					},
					// max: 300,
				},
				x: {
					display: false,
				},
			},
			plugins: {
				title: {
					display: true,
					text: "ข้อมูลจำนวนสติ๊กเกอร์แบ่งตามประเภทยางรถยนต์",
					font: {
						size: 20,
					},
				},
				tooltip: {
					callbacks: {
						label: function (context) {
							let label = context.label || "";

							if (label) {
								label += ": ";
							}
							if (context.formattedValue !== null) {
								label += `${context.formattedValue} สติ๊กเกอร์`;
							}
							return label;
						},
						title: function (context) {
							let label = context[0].dataset.label || "";
							return label;
						},
					},
				},
			},
		};

		this.companyChartOptions = {
			responsive: true,
			scales: {
				y: {
					title: {
						text: "จำนวน (ผู้ประกอบการ)",
						display: true,
						font: {
							size: 16,
						},
					},
					// max: 300,
				},
				x: {
					display: false,
				},
			},
			plugins: {
				title: {
					display: true,
					text: "ข้อมูลจำนวนผู้ประกอบการแบ่งตามประเภทผู้ผลิต/นำเข้า",
					font: {
						size: 20,
					},
				},
				tooltip: {
					callbacks: {
						label: function (context) {
							let label = context.label || "";

							if (label) {
								label += ": ";
							}
							if (context.formattedValue !== null) {
								label += `${context.formattedValue} สติ๊กเกอร์`;
							}
							return label;
						},
						title: function (context) {
							let label = context[0].dataset.label || "";
							return label;
						},
					},
				},
			},
		};
	}

	componentDidMount = () => {
		const getStat = async () => {
			try {
				const response = await axios.get(GET_STATISTICS);
				const { data: amount_data } = await axios.get(GET_STATISTICS_RESQUEST_STICKER);
				let stickerDataArray = [];
				let companyDataArray = [];
				const { company_amount, sticker_amount } = response.data.value;
				const stickerColorObject = {
					C1: "rgb(84,153,199)",
					C2: "rgb(236,112,99)",
					C3: "rgb(82,190,128)",
				};

				const companyColorObject = {
					manufacturing: "rgb(241,196,15)",
					importing: "rgb(155, 89, 182)",
					manufacturing_and_importing: "rgb(113,125,126)",
				};

				const companyLabels = {
					manufacturing: "ผู้ผลิต",
					importing: "ผู้นำเข้า",
					manufacturing_and_importing: "ผู้ผลิตและนำเข้า",
				};

				for (const key in sticker_amount) {
					if (!sticker_amount[key].total) {
						continue;
					}
					let result = {
						label: key,
						data: {
							จำนวน: sticker_amount[key].total,
						},
						backgroundColor: stickerColorObject[key],
						maxBarThickness: 40,
					};

					stickerDataArray.push(result);
				}

				for (const key in company_amount) {
					if (!company_amount[key].total) {
						continue;
					}
					let result = {
						label: companyLabels[key],
						data: {
							จำนวน: company_amount[key].total,
						},
						backgroundColor: companyColorObject[key],
						maxBarThickness: 40,
					};

					companyDataArray.push(result);
				}

				this.setState({
					stickerData: { datasets: stickerDataArray },
					companyData: { datasets: companyDataArray },
					amountData: amount_data?.data,
				});
			} catch (e) {
				console.log(e);
			}
		};
		getStat();
	};

	getDashboardStickerAmount = async (clear = false, val) => {
		if (clear) {
			this.setState({
				dataStickerAmount: {
					start_date: "",
					end_date: "",
				},
			});
		}
		try {
			const { data: response } = await axios.get(GET_STICKER_AMOUNT_BY_TYPE, {
				params: {
					start_date: val?.start_date || "",
					end_date: val?.end_date || "",
				},
			});
			let stickerDataArray = [];
			const sticker_amount = response.value;
			const stickerColorObject = {
				C1: "rgb(84,153,199)",
				C2: "rgb(236,112,99)",
				C3: "rgb(82,190,128)",
			};

			for (const key in sticker_amount) {
				if (!sticker_amount[key].total) {
					continue;
				}
				let result = {
					label: key,
					data: {
						จำนวน: sticker_amount[key].total,
					},
					backgroundColor: stickerColorObject[key],
					maxBarThickness: 40,
				};

				stickerDataArray.push(result);
			}
			this.setState({ stickerData: { datasets: stickerDataArray } });
		} catch (error) {
			console.log(error);
		}
	};
	getDashboardCompanyAmount = async (clear = false, val) => {
		if (clear) {
			this.setState({
				dataCompanyAmount: {
					start_date: "",
					end_date: "",
				},
			});
		}
		try {
			const { data: response } = await axios.get(GET_COMPANY_AMOUNT_BY_TYPE, {
				params: {
					start_date: val?.start_date || "",
					end_date: val?.end_date || "",
				},
			});
			let companyDataArray = [];
			const company_amount = response.value;
			const stickerColorObject = {
				C1: "rgb(84,153,199)",
				C2: "rgb(236,112,99)",
				C3: "rgb(82,190,128)",
			};

			const companyColorObject = {
				manufacturing: "rgb(241,196,15)",
				importing: "rgb(155, 89, 182)",
				manufacturing_and_importing: "rgb(113,125,126)",
			};

			const companyLabels = {
				manufacturing: "ผู้ผลิต",
				importing: "ผู้นำเข้า",
				manufacturing_and_importing: "ผู้ผลิตและนำเข้า",
			};

			for (const key in company_amount) {
				if (!company_amount[key].total) {
					continue;
				}
				let result = {
					label: companyLabels[key],
					data: {
						จำนวน: company_amount[key].total,
					},
					backgroundColor: companyColorObject[key],
					maxBarThickness: 40,
				};

				companyDataArray.push(result);
			}
			this.setState({ companyData: { datasets: companyDataArray } });
		} catch (error) {
			console.log(error);
		}
	};
	getDashboardStickerStatistics = async (clear = false, val) => {
		if (clear) {
			this.setState({
				dataStatisticsSticker: {
					start_date: "",
					end_date: "",
				},
			});
		}
		try {
			const { data: amount_data } = await axios.get(GET_STATISTICS_RESQUEST_STICKER, {
				params: {
					start_date: val?.start_date || "",
					end_date: val?.end_date || "",
				},
			});
			this.setState({ amountData: amount_data?.data });
		} catch (error) {
			console.log(error);
		}
	};

	setFieldValueStickerAmount = (field, date) => {
		this.setState((prevState) => ({
			dataStickerAmount: {
				...prevState.dataStickerAmount,
				[field]: date,
			},
		}));
	};
	setFieldValueStatisticsSticker = (field, date) => {
		this.setState((prevState) => ({
			dataStatisticsSticker: {
				...prevState.dataStatisticsSticker,
				[field]: date,
			},
		}));
	};
	setFieldValueCompanyAmount = (field, date) => {
		this.setState((prevState) => ({
			dataCompanyAmount: {
				...prevState.dataCompanyAmount,
				[field]: date,
			},
		}));
	};

	render() {
		return (
			<div>
				<Headers />
				<div style={{ margin: "30px 26px" }}>
					<Modals.FilterDashboard
						open={this.state.openModalStickerAmount}
						data={this.state.dataStickerAmount}
						onClose={() =>
							this.setState({
								openModalStickerAmount: false,
							})
						}
						setFieldValue={this.setFieldValueStickerAmount}
						callback={this.getDashboardStickerAmount}
					/>
					<Modals.FilterDashboard
						open={this.state.openModalStatisticsSticker}
						data={this.state.dataStatisticsSticker}
						onClose={() =>
							this.setState({
								openModalStatisticsSticker: false,
							})
						}
						setFieldValue={this.setFieldValueStatisticsSticker}
						callback={this.getDashboardStickerStatistics}
					/>
					<Modals.FilterDashboard
						open={this.state.openModalCompanyAmount}
						data={this.state.dataCompanyAmount}
						onClose={() =>
							this.setState({
								openModalCompanyAmount: false,
							})
						}
						setFieldValue={this.setFieldValueCompanyAmount}
						callback={this.getDashboardCompanyAmount}
					/>
					<Container fluid>
						<Header
							as="h2"
							textAlign="left"
						>
							<Icon
								name="chart bar"
								color="violet"
							/>
							Dashboard
						</Header>

						{/* <Divider /> */}

						<Segment
							style={{ borderRadius: "16px" }}
							attached="bottom"
							textAlign="center"
							loading={this.state.loading}
						>
							<div
								style={{
									width: "95%",
									margin: "auto",
									// padding: "40px 20px",
									padding: "40px 0",
								}}
							>
								<Grid centered>
									<Grid.Row>
										<Grid.Column width={8}>
											<ContainerIconButtonFilter
												alignItems="center"
												justifyContent="flex-end"
											>
												{(this.state.dataStickerAmount?.start_date || this.state.dataStickerAmount?.end_date) && (
													<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
														<div
															style={{
																padding: "0px 15px",
																borderRadius: "63px",
																boxShadow: "0 0 #0000",
																fontWeight: "400",
																fontSize: "14px",
																color: "#fff",
																display: "flex",
																alignItems: "center",
																backgroundColor: "#FFA031",
															}}
														>
															{this.state.dataStickerAmount?.start_date
																? moment(this.state.dataStickerAmount?.start_date).format("DD/MM/YYYY")
																: "ยังไม่ระบุ"}{" "}
															ถึงวันที่{" "}
															{this.state.dataStickerAmount?.end_date
																? moment(this.state.dataStickerAmount?.end_date).format("DD/MM/YYYY")
																: "ยังไม่ระบุ"}
														</div>
														<div onClick={() => this.getDashboardStickerAmount(true)}>
															<Icon
																style={{ margin: "0px 10px" }}
																link
																name="trash alternate"
															/>
														</div>
													</div>
												)}
												<BoxButtonFilter
													onClick={() =>
														this.setState({
															openModalStickerAmount: true,
														})
													}
												>
													<ButtonIconFilter>
														<img src={IconFilter} />
													</ButtonIconFilter>
												</BoxButtonFilter>
											</ContainerIconButtonFilter>
											<Segment
												style={{ borderRadius: "12px" }}
												raised
											>
												<Bar
													style={{ margin: "30px" }}
													options={this.stickerChartOptions}
													data={this.state.stickerData}
												/>
											</Segment>
										</Grid.Column>

										<Grid.Column width={8}>
											<ContainerIconButtonFilter
												alignItems="center"
												justifyContent="flex-end"
											>
												{(this.state.dataCompanyAmount?.start_date || this.state.dataCompanyAmount?.end_date) && (
													<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
														<div
															style={{
																padding: "0px 15px",
																borderRadius: "63px",
																boxShadow: "0 0 #0000",
																fontWeight: "400",
																fontSize: "14px",
																color: "#fff",
																display: "flex",
																alignItems: "center",
																backgroundColor: "#FFA031",
															}}
														>
															{this.state.dataCompanyAmount?.start_date
																? moment(this.state.dataCompanyAmount?.start_date).format("DD/MM/YYYY")
																: "ยังไม่ระบุ"}{" "}
															ถึงวันที่{" "}
															{this.state.dataCompanyAmount?.end_date
																? moment(this.state.dataCompanyAmount?.end_date).format("DD/MM/YYYY")
																: "ยังไม่ระบุ"}
														</div>
														<div onClick={() => this.getDashboardCompanyAmount(true)}>
															<Icon
																style={{ margin: "0px 10px" }}
																link
																name="trash alternate"
															/>
														</div>
													</div>
												)}
												<BoxButtonFilter
													onClick={() =>
														this.setState({
															openModalCompanyAmount: true,
														})
													}
												>
													<ButtonIconFilter>
														<img src={IconFilter} />
													</ButtonIconFilter>
												</BoxButtonFilter>
											</ContainerIconButtonFilter>
											<Segment
												style={{ borderRadius: "12px" }}
												raised
											>
												<Bar
													style={{ margin: "30px" }}
													options={this.companyChartOptions}
													data={this.state.companyData}
												/>
											</Segment>
										</Grid.Column>
									</Grid.Row>
									<Divider />
									<Grid.Row>
										<Grid.Column width={16}>
											<ContainerIconButtonFilter
												alignItems="center"
												justifyContent="flex-end"
											>
												{(this.state.dataStatisticsSticker?.start_date ||
													this.state.dataStatisticsSticker?.end_date) && (
													<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
														<div
															style={{
																padding: "0px 15px",
																borderRadius: "63px",
																boxShadow: "0 0 #0000",
																fontWeight: "400",
																fontSize: "14px",
																color: "#fff",
																display: "flex",
																alignItems: "center",
																backgroundColor: "#FFA031",
															}}
														>
															{this.state.dataStatisticsSticker?.start_date
																? moment(this.state.dataStatisticsSticker?.start_date).format("DD/MM/YYYY")
																: "ยังไม่ระบุ"}{" "}
															ถึงวันที่{" "}
															{this.state.dataStatisticsSticker?.end_date
																? moment(this.state.dataStatisticsSticker?.end_date).format("DD/MM/YYYY")
																: "ยังไม่ระบุ"}
														</div>
														<div onClick={() => this.getDashboardStickerStatistics(true)}>
															<Icon
																style={{ margin: "0px 10px" }}
																link
																name="trash alternate"
															/>
														</div>
													</div>
												)}
												<BoxButtonFilter
													onClick={() =>
														this.setState({
															openModalStatisticsSticker: true,
														})
													}
												>
													<ButtonIconFilter>
														<img src={IconFilter} />
													</ButtonIconFilter>
												</BoxButtonFilter>
											</ContainerIconButtonFilter>
											<Segment
												style={{ borderRadius: "12px" }}
												raised
											>
												<Line
													height={100}
													options={{
														responsive: true,
														scales: {
															y: {
																title: {
																	text: "จำนวน(สติ๊กเกอร์)",
																	display: true,
																	font: {
																		size: 16,
																	},
																},
															},
															x: {
																title: {
																	text: "ปี",
																	display: true,
																},
															},
														},
														plugins: {
															title: {
																display: true,
																text: "จำนวนการขอ Eco Sticker ในแต่ละปี",
																font: {
																	size: 20,
																},
															},
															tooltip: {
																callbacks: {
																	label: function (context) {
																		let label = context.label ? `ปี ${context.label}: ` : "";
																		if (context.formattedValue !== null) {
																			label += `${context.formattedValue} รายการ`;
																		}
																		return label;
																	},
																	title: function (context) {
																		let label = context[0].dataset.label || "";
																		return label;
																	},
																},
															},
														},
													}}
													data={{
														labels: this.state.amountData?.chart?.label,
														datasets: [
															{
																label: "จำนวนการขอ Eco Sticker",
																data: this.state.amountData?.chart?.data,
																borderColor: "rgb(255, 99, 132)",
																backgroundColor: "rgba(255, 99, 132, 0.5)",
																tension: 0.4,
															},
														],
													}}
												/>
											</Segment>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column textAlign="center">
											<span style={{ width: "100%", textAlign: "center" }}>
												{this.state.amountData?.date_full_text}
											</span>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</div>
						</Segment>
					</Container>
				</div>
			</div>
		);
	}
}

export default Dashboard;
