import React, { useEffect, useState } from "react";
import { Dropdown, Pagination } from "semantic-ui-react";

const OiePagination = ({
    page = 1,
    totalPages = 0,
    rowsPerPage = 10,
    rowsPerPageOptions = [10, 25, 100],
    totalRows = 0,
    handleChangePage,
    handleChangeRowsPerPage,
}) => {
    const [rows_rer_page_option, setRowsPerPageOption] = useState([
        {
            text: 10,
            value: 10,
        },
    ]);

    useEffect(() => {
        const row_per_page_format = rowsPerPageOptions.map((item) => {
            return {
                text: item,
                value: item,
            };
        });
        setRowsPerPageOption(row_per_page_format);
    }, []);

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <div>จำนวนต่อหน้า :</div>
            <Dropdown
                selection
                compact
                name="rowQty"
                defaultValue={rowsPerPage}
                value={rowsPerPage}
                options={rows_rer_page_option}
                onChange={(e, { value }) => handleChangeRowsPerPage(value)}
                style={{
                    marginLeft: 10,
                    marginRight: 10,
                    width: 80,
                }}
            />
            {totalRows === 0 ? (
                <div>ไม่พบข้อมูล</div>
            ) : (
                <div>
                    {page * rowsPerPage - rowsPerPage + 1}-{page * rowsPerPage > totalRows ? totalRows : page * rowsPerPage} จาก {totalRows}
                </div>
            )}

            <Pagination
                boundaryRange={1}
                activePage={page}
                siblingRange={1}
                name="currentPage"
                totalPages={totalPages}
                onPageChange={(e, { activePage }) => {
                    handleChangePage(activePage);
                }}
                style={{ marginLeft: 10 }}
                nextItem={{
                    "aria-label": "Next item",
                    content: (
                        <i
                            aria-hidden="true"
                            className="angle right icon"
                        ></i>
                    ),
                    disabled: page === totalPages,
                }}
                prevItem={{
                    "aria-label": "Previous item",
                    content: (
                        <i
                            aria-hidden="true"
                            className="angle left icon"
                        ></i>
                    ),
                    disabled: page === 1,
                }}
                firstItem={
                    page === 1
                        ? null
                        : {
                              "aria-label": "First item",
                              content: (
                                  <i
                                      aria-hidden="true"
                                      className="angle double left icon"
                                  ></i>
                              ),
                          }
                }
                lastItem={
                    page === totalPages
                        ? null
                        : {
                              "aria-label": "Last item",
                              content: (
                                  <i
                                      aria-hidden="true"
                                      className="angle double right icon"
                                  ></i>
                              ),
                          }
                }
            />
        </div>
    );
};

export default OiePagination;
