import React, { Component } from 'react';
import { Segment, Button, Form, Input, Divider, Grid } from 'semantic-ui-react';
import { Redirect } from 'react-router';
// import axios from 'axios';
import { LOGIN_ADMIN } from '../router';

import ApprovalHeader from '../approval/ApprovalHeader';

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			error: {
				username: '',
				password: ''
			},
			login: false,
			textError: '',
			wrongaccount: '',
			loading: false
		};
	}
	componentWillMount = () => {
		if (localStorage.getItem('role') !== '0' && localStorage.getItem('role') !== null) {
			this.setState({ login: true });
		}
	};

	handleOnchange = (e, { name, value }) => {
		this.setState({ [name]: value });
	};

	handleSummit = () => {
		this.setState({ loading: true });
		fetch(LOGIN_ADMIN, {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
			body: JSON.stringify({
				username: this.state.username,
				password: this.state.password
			})
		}).then((res) =>
			res.json().then((response) => {
				// console.log(response)
				if (response.status === 'Success') {
					localStorage.setItem('name', response.value.name);
					localStorage.setItem('token', response.value.access_token);
					localStorage.setItem('refreshToken', response.value.refresh_token);
					localStorage.setItem('role', response.value.role);
					this.setState({ loginFail: false, login: true, loading: false });
				}
				else if (response.message === 'wrong Username') {
					this.setState({ loginFail: true, textError: 'ไม่พบรหัสผู้ใช้งานนี้ในระบบ', loading: false });
				}
				else if (response.message === 'wrong Password') {
					this.setState({ loginFail: true, textError: 'รหัสผ่านไม่ถูกต้อง', loading: false });
				}
			})
		);
	};

	render() {
		if (this.state.login) {
			if (localStorage.getItem("role") !== "2") {
				return <Redirect to='/oie/entrepreneur' />;
			} else {
				return <Redirect to='/approval/tisi_oie' />;
			}

		}

		return (
			<div>
				<ApprovalHeader />
				<Grid columns='equal'>
					<Grid.Column />
					<Segment
						style={{ backgroundColor: '#E9E9E9', borderRadius: '5px', marginTop: '100px' }}
						loading={this.state.loading}
					>
						<div style={{ textAlign: 'center' }}>
							<img src='/imagelogin/logo2.png' alt='logo' style={{ height: '200px' }} />
						</div>

						<Form onSubmit={this.handleSummit}>
							<Divider />
							<Form.Field>
								<div>
									<h4 htmlFor='username'>
										Username <br />
									</h4>
									<Input
										style={{ marginBottom: 10, width: '100%' }}
										placeholder='Username'
										name='username'
										onChange={this.handleOnchange}
										ref={(input) => {
											this.username = input;
										}}
										maxLength='13'
									/>
								</div>
							</Form.Field>
							<Form.Field>
								<div>
									<h4 htmlFor='password'>
										Password <br />
									</h4>
									<Input
										style={{ width: '100%' }}
										type='Password'
										placeholder='Password'
										name='password'
										onChange={this.handleOnchange}
										ref={(input) => {
											this.password = input;
										}}
									/>
								</div>
							</Form.Field>
							{this.state.loginFail && (
								<div style={{ marginTop: '10px', color: 'red' }}>
									<h4>{this.state.textError}</h4>
								</div>
							)}

							<Button
								fluid
								style={{ marginTop: '30px', backgroundColor: '#030C37' }}
								type='submit'
								positive
							>
								<h4 style={{ color: 'white' }}>Login</h4>
							</Button>
						</Form>
					</Segment>
					<Grid.Column />
				</Grid>
			</div>
		);
	}
}
