import React, { Component } from "react";
import PropTypes from "prop-types";
import RegulationModal from "./RegulationModal";
import TIS2718 from "../../assets/TIS2718_2558.pdf";
import TIS2719 from "../../assets/TIS2719_2558.pdf";
import TIS2721 from "../../assets/TIS2721_2560.pdf";
import Regulation_EC from "../../assets/Regulation_EC.pdf";
import Regulation from "../../assets/Regulation.pdf";
// import R2721 from "../../assets/R2721.pdf";
// import R2721_1 from "../../assets/R2721_1.pdf";
// import R2721_2 from "../../assets/R2721_2.pdf";
// import R2721_3 from "../../assets/R2721_3.pdf";
import R2721_4 from "../../assets/R2721_edit.pdf";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "react-reveal/Fade";
import { Grid, Icon } from "semantic-ui-react";

export class News extends Component {
  constructor(props) {
    super(props);
    this.pdfLinkRef = React.createRef();
    this.state = {
      isOpenModal: false,
      src: null,
    };
  }
  closeModal = () => {
    this.setState({ isOpenModal: false });
  };

  handleClick = (e, src) => {
    e.preventDefault();
    if (src) {
      this.setState({
        src: src,
        isOpenModal: true,
      });
    }
  };

  render() {
    //Start News Loop
    const newsdata = this.props.newssData.map((news, index) => (
      <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
        <div className="single-blog-post">
          <div className="post-thumbnail">
            <a href="" onClick={(e) => this.handleClick(e, news.src)}>
              <img src={news.image} alt="blog-img" />
            </a>
          </div>

          <div className="post-content">
            <h3>
              <Grid columns={2}>
                {/* <Grid.Row columns={2}> */}
                <Grid.Column width={12}>
                  <a href="" onClick={(e) => this.handleClick(e, news.src)}>
                    {news.title}
                  </a>
                </Grid.Column>
                <Grid.Column width={4} style={{ textAlign: "right" }}>
                  <Icon name="clipboard list" />
                </Grid.Column>
                {/* </Grid.Row> */}
              </Grid>
            </h3>

            <p className="sub-post-content">
              {news.btnname}
              {news.btnname_second}
            </p>
            {/* <p className="sub-post-content">{news.btnname_second}</p> */}
          </div>
        </div>
      </div>
    ));
    //End News Loop
    return (
      <React.Fragment>
        <RegulationModal
          isOpen={this.state.isOpenModal}
          closeModal={this.closeModal}
          src={this.state.src}
        />
        <section id="regulation" className="blog-area ptb-100p">
          <div className="container">
            <div>
              <VisibilitySensor
                onChange={(isVisible) =>
                  isVisible && this.props.setActiveMenu("regulation")
                }
              >
                <div
                  className="section-title"
                  style={{ textAlign: "left", marginTop: "10%" }}
                >
                  <Fade top>
                    <div>
                      <h3 className="section-header">
                        {this.props.sectionTitle}
                      </h3>
                      <div className="section-header-second">
                        {this.props.sectionTitleSpan}
                      </div>
                    </div>
                  </Fade>
                  {/* <p>{this.props.sectionContent}</p> */}
                </div>
              </VisibilitySensor>

              <div className="row">
                <Fade bottom>{newsdata}</Fade>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

News.PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  newssData: PropTypes.array,
};
News.defaultProps = {
  sectionTitle: "Regulation",
  sectionTitleSpan: "กฎ ระเบียบ มาตรฐาน และข้อกำหนดที่เกี่ยวข้อง",
  sectionContent:
    "Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
  newssData: [
    {
      imglink: "#",
      image: require("../../assets/img/กฏกระทรวง.png"),
      titlelink: "#",
      title: "กฎกระทรวง",
      btnlink: "#",
      btnname: "กำหนดลักษณะ การทำ วิธีแสดง",
      btnname_second:
        "และการใช้เครื่องหมายมาตรฐานกับผลิตภัณฑ์อุตสาหกรรม พ.ศ. 2563",
      src: Regulation,
    },
    {
      imglink: "#",
      image: require("../../assets/img/R2721.png"),
      titlelink: "#",
      title: "มอก. 2721-2560",
      btnlink: "#",
      btnname:
        "เสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียกและความต้านทานการหมุน (แก้ไขครั้งที่ ๑)",
      src: TIS2721,
    },
    {
      imglink: "#",
      image: require("../../assets/img/R2721-2560(Edit).png"),
      titlelink: "#",
      title: "R2721-2560(06)",
      btnlink: "#",
      btnname:
        "หลักเกณฑ์เฉพาะในการตรวจสอบเพื่อการอนุญาต สำหรับผลิตภัณฑ์อุตสาหกรรมยางล้อสูบลม : ข้อกำหนดด้านเสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียก และความต้านทานการหมุน มาตรฐานเลขที่ มอก. 2721-2560",
      src: R2721_4,
    },
    // {
    //   imglink: "#",
    //   image: require("../../assets/img/R2721-2560(03).png"),
    //   titlelink: "#",
    //   title: "R2721-2560(03)",
    //   btnlink: "#",
    //   btnname:
    //     "หลักเกณฑ์เฉพาะในการตรวจสอบเพื่อการอนุญาต สำหรับผลิตภัณฑ์อุตสาหกรรมยางล้อสูบลม : ข้อกำหนดด้านเสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียก และความต้านทานการหมุน มาตรฐานเลขที่ มอก. 2721-2560 ฉบับ R2721(03)",
    //   src: R2721_3,
    // },
    // {
    //   imglink: "#",
    //   image: require("../../assets/img/R2721-2560(02).png"),
    //   titlelink: "#",
    //   title: "R2721-2560(02)",
    //   btnlink: "#",
    //   btnname:
    //     "หลักเกณฑ์เฉพาะในการตรวจสอบเพื่อการอนุญาต สำหรับผลิตภัณฑ์อุตสาหกรรมยางล้อสูบลม : ข้อกำหนดด้านเสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียก และความต้านทานการหมุน มาตรฐานเลขที่ มอก. 2721-2560 ฉบับ R2721(02)",
    //   src: R2721_2,
    // },
    // {
    //   imglink: "#",
    //   image: require("../../assets/img/R2721-2560(01).png"),
    //   titlelink: "#",
    //   title: "R2721-2560(01)",
    //   btnlink: "#",
    //   btnname:
    //     "หลักเกณฑ์เฉพาะในการตรวจสอบเพื่อการอนุญาต สำหรับผลิตภัณฑ์อุตสาหกรรมยางล้อสูบลม : ข้อกำหนดด้านเสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียก และความต้านทานการหมุน มาตรฐานเลขที่ มอก. 2721-2560 ฉบับ R2721(01)",
    //   src: R2721_1,
    // },
    // {
    //   imglink: "#",
    //   image: require("../../assets/img/R2721-2560(00).png"),
    //   titlelink: "#",
    //   title: "R2721-2560(00)",
    //   btnlink: "#",
    //   btnname:
    //     "หลักเกณฑ์เฉพาะในการตรวจสอบเพื่อการอนุญาต สำหรับผลิตภัณฑ์อุตสาหกรรมยางล้อสูบลม : ข้อกำหนดด้านเสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียก และความต้านทานการหมุน มาตรฐานเลขที่ มอก. 2721-2560 ฉบับ R2721(00)",
    //   src: R2721,
    // },
    {
      imglink: "#",
      image: require("../../assets/img/2718.png"),
      titlelink: "#",
      title: "มอก. 2718-2560",
      btnlink: "#",
      btnname: "ยางล้อแบบสูบลมสำหรับรถยนต์และส่วนพ่วง (แก้ไขครั้งที่ ๑)",
      src: TIS2718,
    },
    {
      imglink: "#",
      image: require("../../assets/img/2719.png"),
      titlelink: "#",
      title: "มอก. 2719-2560",
      btnlink: "#",
      btnname:
        "ยางล้อแบบสูบลมสำหรับรถยนต์เชิงพาณิชย์และส่วนพ่วง (แก้ไขครั้งที่ ๑)",
      src: TIS2719,
    },

    {
      imglink: "#",
      image: require("../../assets/img/Regulation_EC.png"),
      titlelink: "#",
      title: "Regulation (EC) No 1222/2009",
      btnlink: "#",
      btnname: "of the European Parliament and of the Council",
      src: Regulation_EC,
    },
    // {
    //     imglink: "#",
    //     image: require("../../assets/img/blog-img3.jpg"),
    //     titlelink: "#",
    //     title: "empty",
    //     btnlink: "#",
    //     btnname: "",
    //     src: ''
    // }
  ],
};
export default News;
