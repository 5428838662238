import React, { Component, createRef,Fragment } from "react";
import {
  Table,
  Icon,
  Form,
  Button,
  Grid,
  Ref,
  Sticky,
  Label,
} from "semantic-ui-react";
import { HOST_PATH_FILE } from "../../router";
import Iframe from "react-iframe";

export default class AuthorizeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  componentDidMount() {
    // console.log('props',this.props)
    // if(this.props.dataPersons.length > 0){
    // 	this.setState({this.props.dataPersons.filter(data=> data.type_person === 'ผู้ลงนาม')
    // }
  }

  contextRef = createRef();
  showFile = (index) => this.setState({ index: index });

  getCompared = (origin, alter) => {
    return (
      <Fragment>
        <span style={{ textDecoration: "line-through", color: "gray" }}>
          {origin}
        </span>{" "}
        <span style={{color:'green'}}>{alter}</span>
      </Fragment>
    );
  };

  getLabelStatus = (status) => {
    if (status === "created" || status === "deleted") {
      return (
        <Label
          floated
          style={{ float: "right" }}
          color={status === "created" ? "green" : "red"}
        >
          {`ข้อมูลผู้มีอำนาจลงนามที่${
            status === "created" ? "เพิ่มเข้ามาใหม่" : "ถูกลบ"
          }`}
        </Label>
      );
    }
    return null;
  };
  render() {
    const data = this.props.dataPersons.filter(
      (data) => data.type_personTemp === "ผู้ลงนาม"
    );
    return (
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Ref innerRef={this.contextRef}>
            <Form.Field>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="2" width="1">
                      <Icon name="user" />
                      ข้อมูลผู้มีอำนาจลงนาม
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.map((person, index) => {
                    if (person.type_personTemp === "ผู้ลงนาม") {
                      return (
                        <Table.Row>
                          <Table.Cell colSpan="2" textAlign="center">
                            <Table
                              style={{ width: "100%" }}
                              className={"oie-table"}
                              size="small"
                              inverted={
                                person.action === "created" ||
                                person.action === "deleted"
                                  ? true
                                  : false
                              }
                              color={
                                person.action === "created"
                                  ? "green"
                                  : person.action === "deleted"
                                  ? "red"
                                  : null
                              }
                            >
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell colSpan="2" width={1}>
                                    ผู้มีอำนาจลงนามลำดับที่ {index + 1}
                                    {this.getLabelStatus(person.action)}
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Row>
                                <Table.Cell>สัญชาติ</Table.Cell>
                                <Table.Cell>
                                  {person.nationalityIsEdited
                                    ? this.getCompared(
                                        person.nationalityOriginal,
                                        person.nationalityTemp
                                      )
                                    : person.nationalityOriginal}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                {person.nationalityTemp === "ไทย" ? (
                                  <React.Fragment>
                                    <Table.Cell>เลขประจำตัวประชาชน</Table.Cell>

                                    <Table.Cell>
                                      {person.tax_idIsEdited
                                        ? this.getCompared(
                                            person.tax_idOriginal,
                                            person.tax_idTemp
                                          )
                                        : person.tax_idOriginal}
                                    </Table.Cell>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <Table.Cell>
                                      เลขที่หนังสือเดินทาง
                                    </Table.Cell>
                                    <Table.Cell>
                                      {/* {person.passport} */}
                                      {person.passportIsEdited
                                        ? this.getCompared(
                                            person.passportOriginal,
                                            person.passportTemp
                                          )
                                        : person.passportOriginal}
                                    </Table.Cell>
                                  </React.Fragment>
                                )}
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>ชื่อ</Table.Cell>
                                <Table.Cell>
                                  {/* {person.name_th} */}
                                  {person.name_thIsEdited
                                    ? this.getCompared(
                                        person.name_thOriginal,
                                        person.name_thTemp
                                      )
                                    : person.name_thOriginal}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>Name</Table.Cell>
                                <Table.Cell>
                                  {/* {person.name_en} */}
                                  {person.name_enIsEdited
                                    ? this.getCompared(
                                        person.name_enOriginal,
                                        person.name_enTemp
                                      )
                                    : person.name_enOriginal}
                                </Table.Cell>
                              </Table.Row>
                              {person.nationalityTemp === "ไทย" ? (
                                <React.Fragment>
                                  <Table.Row>
                                    <Table.Cell>เลขที่</Table.Cell>
                                    <Table.Cell>
                                      {/* {person.address} */}
                                      {person.addressIsEdited
                                        ? this.getCompared(
                                            person.addressOriginal,
                                            person.addressTemp
                                          )
                                        : person.addressOriginal}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>ซอย</Table.Cell>
                                    <Table.Cell>
                                      {/* {person.soi} */}
                                      {person.soiIsEdited
                                        ? this.getCompared(
                                            person.soiOriginal,
                                            person.soiTemp
                                          )
                                        : person.soiOriginal}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>ถนน</Table.Cell>
                                    <Table.Cell>
                                      {/* {person.road} */}
                                      {person.roadIsEdited
                                        ? this.getCompared(
                                            person.roadOriginal,
                                            person.roadTemp
                                          )
                                        : person.roadOriginal}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>ตำบล/แขวง</Table.Cell>
                                    <Table.Cell>
                                      {/* {person.subdistrict} */}
                                      {person.subdistrictIsEdited
                                        ? this.getCompared(
                                            person.subdistrictOriginal,
                                            person.subdistrictTemp
                                          )
                                        : person.subdistrictOriginal}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>อำเภอ/เขต</Table.Cell>
                                    <Table.Cell>
                                      {/* {person.district} */}
                                      {person.districtIsEdited
                                        ? this.getCompared(
                                            person.districtOriginal,
                                            person.districtTemp
                                          )
                                        : person.districtOriginal}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>จังหวัด</Table.Cell>
                                    <Table.Cell>
                                      {/* {person.province} */}
                                      {person.provinceIsEdited
                                        ? this.getCompared(
                                            person.provinceOriginal,
                                            person.provinceTemp
                                          )
                                        : person.provinceOriginal}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>รหัสไปรษณีย์</Table.Cell>
                                    <Table.Cell>
                                      {/* {person.zipcode} */}
                                      {person.zipcodeIsEdited
                                        ? this.getCompared(
                                            person.zipcodeOriginal,
                                            person.zipcodeTemp
                                          )
                                        : person.zipcodeOriginal}
                                    </Table.Cell>
                                  </Table.Row>
                                </React.Fragment>
                              ) : (
                                <Table.Row>
                                  <Table.Cell>ที่อยู่</Table.Cell>
                                  <Table.Cell>
                                    {/* {person.foreigner_address} */}
                                    {person.foreigner_addressIsEdited
                                      ? this.getCompared(
                                          person.foreigner_addressOriginal,
                                          person.foreigner_addressTemp
                                        )
                                      : person.foreigner_addressOriginal}
                                  </Table.Cell>
                                </Table.Row>
                              )}

                              <Table.Row>
                                <Table.Cell>เบอร์โทร</Table.Cell>
                                <Table.Cell>
                                  {/* {person.phone} */}
                                  {person.phoneIsEdited
                                    ? this.getCompared(
                                        person.phoneOriginal,
                                        person.phoneTemp
                                      )
                                    : person.phoneOriginal}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>Email</Table.Cell>
                                <Table.Cell>
                                  {/* {person.email} */}
                                  {person.emailIsEdited
                                    ? this.getCompared(
                                        person.emailOriginal,
                                        person.emailTemp
                                      )
                                    : person.emailOriginal}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                {person.nationality === "ไทย" ? (
                                  <Table.Cell>
                                    เอกสารแนบ: สำเนาบัตรประจำตัวประชาชน
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell>
                                    เอกสารแนบ: สำเนาหนังสือเดินทาง
                                  </Table.Cell>
                                )}
                                <Table.Cell>
                                  {/* {person.file.name} */}
                                  <Button
                                    size="small"
                                    basic
                                    color="orange"
                                    style={{
                                      marginTop: 10,
                                      display: "flex",
                                      justifyContens: "flex-end",
                                    }}
                                    onClick={() => this.showFile(index)}
                                  >
                                    <Icon name="search" />
                                    ดูเอกสาร
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Table.Body>
              </Table>
            </Form.Field>
          </Ref>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Sticky context={this.contextRef} pushing>
            <Iframe
              url={
                this.props.dataPersons[this.state.index]
                  .person_certificateIsEdited
                  ? `${HOST_PATH_FILE}${
                      this.props.dataPersons[this.state.index]
                        .person_certificateTemp
                    }`
                  : `${HOST_PATH_FILE}${
                      this.props.dataPersons[this.state.index]
                        .person_certificateOriginal
                    }`
              }
              width="100%"
              height="800px"
              display="initial"
              position="relative"
            />
          </Sticky>
        </Grid.Column>
      </Grid>
    );
  }
}
