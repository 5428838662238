import React, { Component, createRef, Fragment } from "react";
import {
  Form,
  Grid,
  Table,
  Icon,
  Button,
  Sticky,
  Ref,
} from "semantic-ui-react";
import Iframe from "react-iframe";
import { HOST_PATH_FILE } from "../../router";
import styles from "../../../css/table.module.scss";
class entrepreneurTable extends Component {
  constructor() {
    super();
    this.state = {
      entrepreneur_text: "",
      entrepreneurPathFile: "",
    };
  }
  contextRef = createRef();

  async entrepreneurShowPDF(_, { name }) {
    const company = this.props.editedData
      ? this.props.editedData[0]
      : this.props.dataCompany[0];
    if (name === "file_corporation_certificate") {
      this.setState({
        entrepreneurPathFile: `${HOST_PATH_FILE}${company.certificatedoc}`,
      });
    } else if (name === "file_vat_certificate") {
      this.setState({
        entrepreneurPathFile: `${HOST_PATH_FILE}${company.taxdoc}`,
      });
    } else if (name === "file_trademark") {
      this.setState({
        entrepreneurPathFile: `${HOST_PATH_FILE}${company.trademarkdoc}`,
      });
    }
  }

  componentDidMount() {
    // console.log("this.props", this.props);
    // Convert Status manufacture and Import To Text
    this.props.dataCompany.map((company) => {
      if (
        parseInt(company.manufacture) === 1 &&
        parseInt(company.import) === 0
      ) {
        this.setState({ entrepreneur_text: "ผู้ผลิต" });
      } else if (
        parseInt(company.manufacture) === 0 &&
        parseInt(company.import) === 1
      ) {
        this.setState({ entrepreneur_text: "ผู้นำเข้า" });
      } else {
        this.setState({ entrepreneur_text: "ผู้ผลิต และ ผู้นำเข้า" });
      }

      return null;
    });

    const alter = this.props.editedData
      ? this.props.editedData
      : this.props.dataCompany;
    alter.map((company) => {
      if (
        parseInt(company.manufacture) === 1 &&
        parseInt(company.import) === 0
      ) {
        this.setState({ entrepreneur_text_alt: "ผู้ผลิต" });
      } else if (
        parseInt(company.manufacture) === 0 &&
        parseInt(company.import) === 1
      ) {
        this.setState({ entrepreneur_text_alt: "ผู้นำเข้า" });
      } else {
        this.setState({ entrepreneur_text_alt: "ผู้ผลิต และ ผู้นำเข้า" });
      }

      return null;
    });

    // Set Default Path
    const company = this.props.editedData
      ? this.props.editedData[0]
      : this.props.dataCompany[0];

    this.setState({
      entrepreneurPathFile: `${HOST_PATH_FILE}${company.certificatedoc}`,
    });
  }

  getCompared = (origin, alter) => {
    return (
      <Fragment>
        <span style={{ textDecoration: "line-through", color: "gray" }}>
          {origin}
        </span>{" "}
        <span style={{ color: "green" }}>{alter}</span>
      </Fragment>
    );
  };

  render() {
    const original = this.props.dataCompany[0];
    const alter = this.props.editedData
      ? this.props.editedData[0]
      : this.props.dataCompany[0];
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Ref innerRef={this.contextRef}>
                <Table className={styles.table_head} celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="3">
                        <Icon name="user" />
                        ข้อมูลผู้ประกอบการ
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {alter.type_companies_id === "บุคคลธรรดา" && (
                      <Table.Row>
                        <Table.Cell>ชื่อผู้ประกอบการ</Table.Cell>
                        <Table.Cell>
                          {original.name_th !== alter.name_th
                            ? this.getCompared(original.name_th, alter.name_th)
                            : original.name_th}
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id === "บุคคลธรรดา" && (
                      <Table.Row>
                        <Table.Cell collapsing>Name</Table.Cell>
                        <Table.Cell>
                          {original.name_en !== alter.name_en
                            ? this.getCompared(original.name_en, alter.name_en)
                            : original.name_en}
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id ===
                      "ห้างหุ้นส่วนสามัญนิติบุคคล" && (
                      <Table.Row>
                        <Table.Cell collapsing>
                          ห้างหุ้นส่วนสามัญนิติบุคคล
                        </Table.Cell>
                        <Table.Cell>
                          {original.name_th !== alter.name_th
                            ? this.getCompared(original.name_th, alter.name_th)
                            : original.name_th}
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id ===
                      "ห้างหุ้นส่วนสามัญนิติบุคคล" && (
                      <Table.Row>
                        <Table.Cell collapsing>Company Name</Table.Cell>
                        <Table.Cell>
                          {original.name_en !== alter.name_en
                            ? this.getCompared(original.name_en, alter.name_en)
                            : original.name_en}
                          &nbsp; &nbsp; Juristic Ordinary Partnership
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id === "ห้างหุ้นส่วนจำกัด" && (
                      <Table.Row>
                        <Table.Cell collapsing>ห้างหุ้นส่วนจำกัด</Table.Cell>
                        <Table.Cell>
                          {original.name_th !== alter.name_th
                            ? this.getCompared(original.name_th, alter.name_th)
                            : original.name_th}
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id === "ห้างหุ้นส่วนจำกัด" && (
                      <Table.Row>
                        <Table.Cell collapsing>Company Name</Table.Cell>
                        <Table.Cell>
                          {original.name_en !== alter.name_en
                            ? this.getCompared(original.name_en, alter.name_en)
                            : original.name_en}
                          &nbsp; &nbsp; Limited Partnership
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id === "บริษัทจำกัด" && (
                      <Table.Row>
                        <Table.Cell collapsing>บริษัทจำกัด</Table.Cell>
                        <Table.Cell>
                          บริษัท &nbsp; &nbsp;
                          {original.name_th !== alter.name_th
                            ? this.getCompared(original.name_th, alter.name_th)
                            : original.name_th}
                          &nbsp; &nbsp; จำกัด
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id === "บริษัทจำกัด" && (
                      <Table.Row>
                        <Table.Cell collapsing>Company Name</Table.Cell>
                        <Table.Cell>
                          {original.name_en !== alter.name_en
                            ? this.getCompared(original.name_en, alter.name_en)
                            : original.name_en}
                          &nbsp; Company Limited
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id === "บริษัทมหาชนจำกัด" && (
                      <Table.Row>
                        <Table.Cell collapsing>บริษัทมหาชนจำกัด</Table.Cell>
                        <Table.Cell>
                          บริษัท &nbsp; &nbsp;
                          {original.name_th !== alter.name_th
                            ? this.getCompared(original.name_th, alter.name_th)
                            : original.name_th}
                          &nbsp; &nbsp; จำกัด (มหาชน)
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {alter.type_companies_id === "บริษัทมหาชนจำกัด" && (
                      <Table.Row>
                        <Table.Cell collapsing>Company Name</Table.Cell>
                        <Table.Cell>
                          {original.name_en !== alter.name_en
                            ? this.getCompared(original.name_en, alter.name_en)
                            : original.name_en}
                          Public Company Limited
                        </Table.Cell>
                      </Table.Row>
                    )}

                    <Table.Row>
                      <Table.Cell>ประเภทผู้ประกอบการ</Table.Cell>
                      <Table.Cell>
                        {this.state.entrepreneur_text ===
                        this.state.entrepreneur_text_alt
                          ? this.state.entrepreneur_text
                          : this.getCompared(
                              this.state.entrepreneur_text,
                              this.state.entrepreneur_text_alt
                            )}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>เลขที่ประจำตัวผู้เสียภาษีอากร</Table.Cell>
                      <Table.Cell>
                        {original.tax_id !== alter.tax_id
                          ? this.getCompared(original.tax_id, alter.tax_id)
                          : original.tax_id}
                      </Table.Cell>
                    </Table.Row>

                    {this.props.dataCompany[0].trademark ===
                      "บริษัทมหาชนจำกัด" && (
                      <Table.Row>
                        <Table.Cell collapsing>ทะเบียนเลขที่</Table.Cell>
                        <Table.Cell>
                          {original.trademark !== alter.trademark
                            ? this.getCompared(
                                original.trademark,
                                alter.trademark
                              )
                            : original.trademark}
                        </Table.Cell>
                      </Table.Row>
                    )}

                    <Table.Row>
                      <Table.Cell>เลขที่</Table.Cell>
                      <Table.Cell>
                        {original.address !== alter.address
                          ? this.getCompared(original.address, alter.address)
                          : original.address}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>ซอย</Table.Cell>
                      <Table.Cell>
                        {original.soi !== alter.soi
                          ? this.getCompared(original.soi, alter.soi)
                          : original.soi}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>ถนน</Table.Cell>
                      <Table.Cell>
                        {original.road !== alter.road
                          ? this.getCompared(original.road, alter.road)
                          : original.road}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>จังหวัด</Table.Cell>
                      <Table.Cell>
                        {original.province !== alter.province
                          ? this.getCompared(original.province, alter.province)
                          : original.province}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>อำเภอ/เขต</Table.Cell>
                      <Table.Cell>
                        {original.district !== alter.district
                          ? this.getCompared(original.district, alter.district)
                          : original.district}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>ตำบล/แขวง</Table.Cell>
                      <Table.Cell>
                        {original.subdistrict !== alter.subdistrict
                          ? this.getCompared(
                              original.subdistrict,
                              alter.subdistrict
                            )
                          : original.subdistrict}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>รหัสไปรษณีย์</Table.Cell>
                      <Table.Cell>
                        {original.zipcode !== alter.zipcode
                          ? this.getCompared(original.zipcode, alter.zipcode)
                          : original.zipcode}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>เบอร์โทรศัพท์</Table.Cell>
                      <Table.Cell>
                        {original.phone !== alter.phone
                          ? this.getCompared(original.phone, alter.phone)
                          : original.phone}
                      </Table.Cell>
                    </Table.Row>

                    {/* {this.props.dataCompany[0].fax !== "" && (
                      <Table.Row>
                        <Table.Cell collapsing>เบอร์แฟกซ์</Table.Cell>
                        <Table.Cell>
                          {original.fax !== alter.fax
                            ? this.getCompared(original.fax, alter.fax)
                            : original.fax}
                        </Table.Cell>
                      </Table.Row>
                    )} */}

                    {this.props.dataCompany[0].website !== "" && (
                      <Table.Row>
                        <Table.Cell collapsing>เว็บไซต์</Table.Cell>
                        <Table.Cell>
                          {original.website !== alter.website
                            ? this.getCompared(original.website, alter.website)
                            : original.website}
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {this.props.dataCompany[0].certificatedoc !== "" && (
                      <Table.Row>
                        <Table.Cell collapsing>
                          <Icon name="file outline" />
                          หนังสือรับรองนิติบุคคล
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            size="small"
                            basic
                            color="orange"
                            name="file_corporation_certificate"
                            id="file_corporation_certificate"
                            onClick={this.entrepreneurShowPDF.bind(this)}
                          >
                            <Icon name="search" />
                            ดูเอกสาร
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {this.props.dataCompany[0].taxdoc !== "" && (
                      <Table.Row>
                        <Table.Cell collapsing>
                          <Icon name="file outline" />
                          ใบทะเบียนภาษีมูลค่าเพิ่ม
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            size="small"
                            basic
                            color="orange"
                            name="file_vat_certificate"
                            id="file_vat_certificate"
                            onClick={this.entrepreneurShowPDF.bind(this)}
                          >
                            <Icon name="search" />
                            ดูเอกสาร
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    )}

                    {this.props.dataCompany[0].trademarkdoc !== "" && (
                      <Table.Row>
                        <Table.Cell width="3">
                          <Icon name="file outline" />
                          หนังสือสำคัญแสดงการจดทะเบียนเครื่องหมายการค้า
                          เครื่องหมายบริการ
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            size="small"
                            basic
                            color="orange"
                            name="file_trademark"
                            id="file_trademark"
                            onClick={this.entrepreneurShowPDF.bind(this)}
                          >
                            <Icon name="search" />
                            ดูเอกสาร
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Ref>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Sticky context={this.contextRef} pushing>
                <Iframe
                  url={this.state.entrepreneurPathFile}
                  width="100%"
                  height="800px"
                  display="initial"
                  position="relative"
                />
                {/* <iframe
                    title="file_iframe"
                    // src={`${HOST_PATH_FILE}${this.props.dataCompany[0].certificatedoc}`}
                    src={`${this.state.entrepreneurPathFile}`}
                    allowFullScreen={true}
                    style={{
                      height: window.innerHeight,
                      width: "100%",
                      borderWidth: 0
                    }}
                    type="application/pdf"
                  /> */}
              </Sticky>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default entrepreneurTable;
