import React, { Component, useMemo } from "react";
import CheckAuthentication from "../components/CheckAuthentication";
import { GET_LIST_COMPANY_INDUSTRY, GET_TOKEN_COMPANY_INDUSTRY } from "../components/router";
import Axios from "axios";
import { Container } from "semantic-ui-react";
import styles from "../css/CheckAuthentication.module.scss";
import LogoIndustry from "../assets/icon/LogoIndustry.png";

export default class AuthenticationSevice extends Component {
	constructor() {
		super();

		this.state = {
			dataParamsIndustry: {},
			isLoading: true,
			status: "",
			listCompany: [],
			loadingCompany: false,
			token: "",
			isNotJuristicPerson: false,
		};
	}
	componentDidMount() {
		this.getValueParams();
	}
	getValueParams = async () => {
		this.setState({
			loadingCompany: true,
		});
		try {
			const search = await this.props.location.search;
			const queryParams = new URLSearchParams(search);
			var params = new URLSearchParams();
			params.set("user_id", queryParams.get("uid"));
			params.set("business_id", queryParams.get("bid"));
			params.set("start_time", queryParams.get("st"));
			params.set("eco_type", "tyre");
			params.set("mock_user_start_time", queryParams.get("mock_user_start_time") || "");
			params.set("mock_user_id_decrypted", queryParams.get("mock_user_id_decrypted") || "");
			params.set("mock_business_id", queryParams.get("mock_business_id") || "");
			params.toString();

			//http://localhost:3003/industry-auth?uid=9Nb7u59tz8rFV%2BcYkIBO9A%3D%3D&bid=PD8qdKwDLd6floNZjvntA%3D%3D&st=2CjQKsJS%2Fwr7FbuCaYnxBTkDQItF5fJs1d9qMDHc8rU%3D&mock_user_start_time=2023-01-29%2013:00:30&mock_user_id_decrypted=2263763576830
			let response = await Axios({
				url: `${GET_TOKEN_COMPANY_INDUSTRY}?${params}`,
				method: "GET",
			});
			if (response?.data.code == 200) {
				if (response?.data?.data?.isJuristicPerson) {
					const { data } = await Axios({
						url: GET_LIST_COMPANY_INDUSTRY,
						method: "GET",
						headers: {
							Authorization: `Bearer ${response?.data?.data?.token}`,
						},
					});
					this.setState({
						status: "pass",
						listCompany: data.data,
						token: response?.data?.data?.token,
						isNotJuristicPerson: false,
					});
				} else {
					this.setState({
						isNotJuristicPerson: true,
					});
				}
				this.setState({
					loadingCompany: false,
				});
			}
		} catch (error) {
			this.setState({
				getParamsLoading: false,
				status: "error",
				loadingCompany: false,
			});
		}
	};
	render() {
		const { isLoading, listCompany, loadingCompany, status, token } = this.state;
		if (this.state.isNotJuristicPerson) {
			return <BoxConentNotJuristicPerson />;
		} else {
			return (
				<CheckAuthentication
					loading={isLoading}
					listCompany={listCompany}
					loadingCompany={loadingCompany}
					status={status}
					token={token}
					{...this.props}
				/>
			);
		}
	}
}

const BoxConentNotJuristicPerson = () => {
	return (
		<div className={styles.cardContainer}>
			<Container>
				<div className={styles.positionBox}>
					<div className={styles.cardNotJuristicPerson}>
						<div className={styles.containerColumn}>
							<div className={styles.boxLogoImage}>
								<img src={LogoIndustry} />
							</div>
							<div className={styles.justifyContentCenten}>
								<h1>เลขนิติบุคคลไม่ถูกต้อง</h1>
							</div>
							<div>
								<h3>กิจการที่เลือกเป็นประเภทบุคคลธรรมดา กรุณาตรวจสอบชื่อกิจการและเลขนิติบุคคลใหม่อีกครั้ง</h3>
								<p>หมายเหตุ: ยื่นขอ ECO Sticker ต้องเป็นประเภทนิติบุคคลเท่านั้น</p>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};
