import React, { Component } from "react";
// import { fetchRequest } from "../actions/actions";
// import axios from "axios";
import {
  Segment,
  Container,
  Form,
  Header,
  Message,
  Grid,
  GridColumn,
  Input,
  Icon,
  Label,
  Button,
  Dropdown,
  Radio,
} from "semantic-ui-react";
import { connect } from "react-redux"; // connect redux
import Headers from "../header/requestHeader";
import Requestbutton from "./requestButton";
import RequestTestModal from "./requestTestModal";
const theme_color_1 = "violet";
const theme_color_error = "red";

const type_of_use_options = [
  { key: 0, value: "ทั่วไป", text: "ทั่วไป" },
  { key: 1, value: "บนหิมะ", text: "บนหิมะ" },
  { key: 2, value: "บนหิมะสภาพรุนแรง", text: "บนหิมะสภาพรุนแรง" },
  { key: 3, value: "พิเศษ", text: "พิเศษ" },
];

const symbol_options = [
  { key: 0, value: "S1WR1", text: "S1WR1" },
  { key: 1, value: "S1WR2", text: "S1WR2" },
  { key: 2, value: "S2WR1", text: "S2WR1" },
  { key: 3, value: "S2WR2", text: "S2WR2" },
];

class requestDetail extends Component {
  componentWillMount = async () => {
    // console.log("will mount", this.props.requestRedux);
    // // console.log("this.props.match.params", this.props.match.params.productPos);
    await this.setState(this.props.requestRedux);

    this.setState({
      currentPage: "detail",
    });
    // await this.setState({
    //   productPos: parseInt(this.props.match.params.productPos) - 1
    // });
    // console.log("detail redux", this.state);
    document.getElementById("headPage").scrollIntoView({
      behavior: "smooth",
    });

    this.createOption();
  };

  render() {
    var productPos = this.state.productPos;
    var product = this.state.productRequest[productPos];
    var typeOfUseOptions = type_of_use_options;
    var symbolOptions = symbol_options;
    var tmp = this.state.productRequest;
    var factoryType = parseInt(this.state.factoryTypeId);
    // console.log("detail", this.state);
    return (
      <div>
        <RequestTestModal
          brand_index={this.state.brandIdx + 1}
          brandName={this.state.brandName}
        />
        {this.state.params_request_id === undefined &&
        this.state.params_company_id === undefined ? (
          <Headers />
        ) : (
          <span />
        )}

        <span id="headPage"></span>
        <br></br>
        <Container style={{ margin: 30 }}>
          <Form error={product.formError}>
            {(() => {
              if (this.state.draftName) {
                return (
                  <Segment style={{ margin: 0 }} compact>
                    <h2>ฉบับร่าง: {this.state.draftName}</h2>
                  </Segment>
                );
              } else {
                return <span />;
              }
            })()}
            <Segment padded color={theme_color_1}>
              <Header>
                <h1>{this.state.productRequest[productPos].productName}</h1>
              </Header>

              <Message
                error
                header="กรอกข้อมูลไม่ครบถ้วน"
                content="กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง"
              />
              <div>
                {product.brandsRequest.map((brand, brand_index) => (
                  <div key={brand_index}>
                    <Segment>
                      <h3>
                        ชื่อทางการค้า {brand_index + 1}. {brand.brandName}
                      </h3>
                      {brand.genRequest.map((model, gen_index) => (
                        <div key={gen_index}>
                          <Segment>
                            <Grid verticalAlign="middle">
                              <Grid.Row>
                                {/* รุ่น */}
                                <GridColumn computer={8} mobile={16}>
                                  <Form.Field
                                    error={
                                      tmp[productPos].brandsRequest[brand_index]
                                        .genRequest[gen_index].genNameError
                                    }
                                  >
                                    <label>
                                      {/* {gen_index + 1} */}
                                      ชื่อรุ่น
                                    </label>
                                    <Input
                                      placeholder="กรอกรุ่น"
                                      id={
                                        "genName_" +
                                        brand_index +
                                        "_" +
                                        gen_index
                                      }
                                      value={
                                        tmp[productPos].brandsRequest[
                                          brand_index
                                        ].genRequest[gen_index].genName
                                      }
                                      onChange={async (e, { value }) => {
                                        const input = e.target;
                                        const start = input.selectionStart;
                                        const end = input.selectionEnd;

                                        // DELETED TO UPPERCASE
                                        tmp[productPos].brandsRequest[
                                          brand_index
                                        ].genRequest[gen_index].genName = value;

                                        await this.setState(
                                          {
                                            productRequest: tmp,
                                          },
                                          () => {
                                            input.setSelectionRange(start, end);
                                          }
                                        );
                                      }}
                                    />
                                  </Form.Field>
                                </GridColumn>

                                {/* แบบทางวิศวกรรม */}
                                <GridColumn computer={8} mobile={16}>
                                  <Form.Field>
                                    <label
                                      id={
                                        "genLayoutFile_" +
                                        brand_index +
                                        "_" +
                                        gen_index
                                      }
                                    >
                                      แบบทางวิศวกรรม
                                    </label>
                                    <Segment>
                                      <Label
                                        attached="top"
                                        color={
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index]
                                            .genLayoutFileError
                                            ? "red"
                                            : "violet"
                                        }
                                        as="label"
                                        htmlFor={
                                          "genLayoutFile_upload_" +
                                          brand_index +
                                          "_" +
                                          gen_index
                                        }
                                        size="large"
                                      >
                                        <Icon name="upload" />
                                        เลือกไฟล์
                                        <input
                                          hidden
                                          id={
                                            "genLayoutFile_upload_" +
                                            brand_index +
                                            "_" +
                                            gen_index
                                          }
                                          type="file"
                                          accept=".pdf"
                                          onChange={async (e) => {
                                            if (e.target.files[0]) {
                                              if (
                                                e.target.files.size > 10000000
                                              ) {
                                                alert(
                                                  "ไฟล์ใหญ่เกินที่กำหนดไว้"
                                                );
                                                return;
                                              }

                                              var src = URL.createObjectURL(
                                                e.target.files[0]
                                              );

                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].genLayoutFile.name =
                                                e.target.files[0].name;

                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].genLayoutFile.data =
                                                e.target.files[0];

                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].genLayoutFile.path = src;

                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].genLayoutFileError = false;

                                              await this.setState({
                                                productRequest: tmp,
                                              });
                                            }
                                          }}
                                        />
                                      </Label>
                                      <p>
                                        {
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index].genLayoutFile
                                            .name
                                        }
                                        &nbsp; &nbsp;
                                        {tmp[productPos].brandsRequest[
                                          brand_index
                                        ].genRequest[gen_index].genLayoutFile
                                          .name ? (
                                          <Icon
                                            color="red"
                                            size="large"
                                            name="times circle outline"
                                            link
                                            onClick={async (e) => {
                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].genLayoutFile = {
                                                name: "",
                                                data: "",
                                                path: "",
                                              };

                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].genLayoutFileError = true;

                                              await this.setState({
                                                productRequest: tmp,
                                              });

                                              document.getElementById(
                                                "genLayoutFile_upload_" +
                                                  brand_index +
                                                  "_" +
                                                  gen_index
                                              ).value = null;
                                            }}
                                          />
                                        ) : null}
                                      </p>
                                    </Segment>
                                    <p
                                      style={{
                                        color: "#db2828",
                                      }}
                                    >
                                      ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf
                                      ขนาดไฟล์ไม่เกิน 10 M
                                    </p>
                                  </Form.Field>
                                </GridColumn>

                                <Grid.Column computer={8} mobile={16}>
                                  {/* ลบรายการ */}

                                  {/* <Button
                                    icon
                                    labelPosition="left"
                                    type="button"
                                    color="green"
                                    floated="left"
                                    onClick={async () => {

                                  
                                      await this.setState({ 
                                        brandIdx : brand_index,
                                        brandName : brand.brandName,
                                        isTestModalOpen: true })
                                      await this.props.dispatch(fetchRequest(this.state));
                                      console.log('redux prop', this.props)
                                      
                                    }}

                                  >
                                    เพิ่มผลทดสอบ
                                          <Icon name="plus" />
                                  </Button> */}

                                  {/* {brand_index + 1} {brand.brandName}  */}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            {model.sizeRequest.map((size, size_index) => (
                              <Segment key={size_index}>
                                <Grid verticalAlign="middle">
                                  {/* ขนาด */}
                                  <Grid.Row>
                                    <GridColumn computer={16}>
                                      <Form.Field
                                        error={
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index].sizeRequest[
                                            size_index
                                          ].sizeGenError
                                        }
                                      >
                                        <label>{size_index + 1}. ขนาด</label>
                                        <Input
                                          placeholder="กรอกขนาด"
                                          id={
                                            "sizeGen_" +
                                            brand_index +
                                            "_" +
                                            gen_index +
                                            "_" +
                                            size_index
                                          }
                                          value={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeGen || ""
                                          }
                                          onChange={async (e, { value }) => {
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeGen = value;
                                            await this.setState({
                                              productRequest: tmp,
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </GridColumn>
                                  </Grid.Row>

                                  {/* ดัชนีความสามารถการรับโหลด */}
                                  <Grid.Row>
                                    <GridColumn computer={16}>
                                      <Form.Field
                                        error={
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index].sizeRequest[
                                            size_index
                                          ].sizeCapacityError
                                        }
                                      >
                                        <label>ดัชนีความสามารถการรับโหลด</label>
                                        <Input
                                          placeholder="กรอกดัชนีความสามารถการรับโหลด"
                                          id={
                                            "sizeCapacity_" +
                                            brand_index +
                                            "_" +
                                            gen_index +
                                            "_" +
                                            size_index
                                          }
                                          value={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeCapacity || ""
                                          }
                                          onChange={async (e, { value }) => {
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeCapacity = value;
                                            await this.setState({
                                              productRequest: tmp,
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </GridColumn>
                                  </Grid.Row>

                                  {/* สัญลักษณ์ความเร็ว */}
                                  <Grid.Row>
                                    <GridColumn computer={16}>
                                      <Form.Field
                                        error={
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index].sizeRequest[
                                            size_index
                                          ].sizeSpeedSymbolError
                                        }
                                      >
                                        <label>สัญลักษณ์ความเร็ว</label>
                                        <Input
                                          placeholder="กรอกสัญลักษณ์ความเร็ว"
                                          id={
                                            "sizeSpeedSymbol_" +
                                            brand_index +
                                            "_" +
                                            gen_index +
                                            "_" +
                                            size_index
                                          }
                                          value={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeSpeedSymbol || ""
                                          }
                                          onChange={async (e, { value }) => {
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeSpeedSymbol = value;
                                            await this.setState({
                                              productRequest: tmp,
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </GridColumn>
                                  </Grid.Row>

                                  {/* คุณลักษณะพิเศษ */}
                                  <Grid.Row>
                                    <GridColumn computer={16}>
                                      <Form.Field
                                        error={
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index].sizeRequest[
                                            size_index
                                          ].sizeSpecialFeatureError
                                        }
                                      >
                                        <label
                                          id={
                                            "sizeSpecialFeature_" +
                                            brand_index +
                                            "_" +
                                            gen_index +
                                            "_" +
                                            size_index
                                          }
                                        >
                                          คุณลักษณะพิเศษ
                                        </label>

                                        <Dropdown
                                          clearable
                                          selection
                                          wrapSelection={false}
                                          options={
                                            product.specialFeatureOptions || []
                                          }
                                          placeholder="เลือกคุณลักษณะพิเศษ"
                                          value={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeSpecialFeature || ""
                                          }
                                          onChange={async (e, { value }) => {
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeSpecialFeature = value;
                                            await this.setState({
                                              productRequest: tmp,
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </GridColumn>
                                  </Grid.Row>

                                  {/* โครงสร้าง */}
                                  <Grid.Row>
                                    <GridColumn computer={16}>
                                      <Form.Field
                                        error={
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index].sizeRequest[
                                            size_index
                                          ].sizeStructureError
                                        }
                                      >
                                        <label
                                          id={
                                            "sizeStructure_" +
                                            brand_index +
                                            "_" +
                                            gen_index +
                                            "_" +
                                            size_index
                                          }
                                        >
                                          โครงสร้าง
                                        </label>

                                        <Dropdown
                                          clearable
                                          selection
                                          wrapSelection={false}
                                          options={
                                            product.structureOptions || []
                                          }
                                          placeholder="เลือกโครงสร้าง"
                                          value={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeStructure || ""
                                          }
                                          onChange={async (e, { value }) => {
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeStructure = value;
                                            await this.setState({
                                              productRequest: tmp,
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </GridColumn>
                                  </Grid.Row>

                                  {/* ประเภทใช้งาน */}
                                  <Grid.Row>
                                    <GridColumn computer={16}>
                                      <Form.Field
                                        error={
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].genRequest[gen_index].sizeRequest[
                                            size_index
                                          ].sizeTypeOfUseError
                                        }
                                      >
                                        <label
                                          id={
                                            "sizeTypeOfUse_" +
                                            brand_index +
                                            "_" +
                                            gen_index +
                                            "_" +
                                            size_index
                                          }
                                        >
                                          ประเภทใช้งาน
                                        </label>

                                        <Dropdown
                                          clearable
                                          selection
                                          wrapSelection={false}
                                          options={typeOfUseOptions}
                                          placeholder="เลือกโครงสร้าง"
                                          value={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeTypeOfUse || ""
                                          }
                                          onChange={async (e, { value }) => {
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeTypeOfUse = value;
                                            await this.setState({
                                              productRequest: tmp,
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </GridColumn>
                                  </Grid.Row>

                                  {/* ความกว้างภาคตัดระบุ */}

                                  {product.productTypeId === "1" ? (
                                    <Grid.Row>
                                      <GridColumn computer={16}>
                                        <Form.Field
                                          error={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeCuttingWidthError
                                          }
                                        >
                                          <label>ความกว้างภาคตัดระบุ</label>

                                          <Input
                                            type="number"
                                            placeholder="กรอกความกว้างภาคตัดระบุ"
                                            id={
                                              "sizeCuttingWidth_" +
                                              brand_index +
                                              "_" +
                                              gen_index +
                                              "_" +
                                              size_index
                                            }
                                            value={
                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[gen_index]
                                                .sizeRequest[size_index]
                                                .sizeCuttingWidth || ""
                                            }
                                            onChange={async (e, { value }) => {
                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].sizeRequest[
                                                size_index
                                              ].sizeCuttingWidth = value;
                                              await this.setState({
                                                productRequest: tmp,
                                              });
                                            }}
                                          />
                                          <p style={{ color: "#db2828" }}>
                                            *หมายเหต: หน่วยเป็นมิลลิเมตร
                                          </p>
                                        </Form.Field>
                                      </GridColumn>
                                    </Grid.Row>
                                  ) : (
                                    <span />
                                  )}

                                  {/* เครื่องหมาย */}
                                  {product.productName.includes(
                                    "ผ้าใบเฉียง"
                                  ) === true &&
                                  product.productTypeId === "3" ? (
                                    <span></span>
                                  ) : (
                                    <Grid.Row>
                                      <GridColumn computer={16}>
                                        <Form.Field
                                          error={
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].sizeRequest[
                                              size_index
                                            ].sizeSymbolError
                                          }
                                        >
                                          <label
                                            id={
                                              "sizeSymbol_" +
                                              brand_index +
                                              "_" +
                                              gen_index +
                                              "_" +
                                              size_index
                                            }
                                          >
                                            เครื่องหมาย
                                          </label>

                                          <Dropdown
                                            clearable
                                            selection
                                            wrapSelection={false}
                                            options={symbolOptions}
                                            placeholder="เลือกประเภทใช้งาน"
                                            value={
                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[gen_index]
                                                .sizeRequest[size_index]
                                                .sizeSymbol || ""
                                            }
                                            onChange={async (e, { value }) => {
                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[
                                                gen_index
                                              ].sizeRequest[
                                                size_index
                                              ].sizeSymbol = value;
                                              await this.setState({
                                                productRequest: tmp,
                                              });
                                            }}
                                          />
                                        </Form.Field>
                                      </GridColumn>
                                    </Grid.Row>
                                  )}

                                  <Grid.Row>
                                    <Grid.Column>
                                      {/* ลบรายการ */}
                                      {tmp[productPos].brandsRequest[
                                        brand_index
                                      ].genRequest[gen_index].sizeRequest
                                        .length > 1 ? (
                                        <Button
                                          icon
                                          labelPosition="left"
                                          type="button"
                                          color={theme_color_error}
                                          floated="left"
                                          onClick={async () => {
                                            let sizeRemove =
                                              this.state.sizeRemove;

                                            // console.log(
                                            //   tmp[productPos].brandsRequest[
                                            //     brand_index
                                            //   ].genRequest[gen_index]
                                            //     .sizeRequest[size_index]
                                            // );
                                            if (
                                              tmp[productPos].brandsRequest[
                                                brand_index
                                              ].genRequest[gen_index]
                                                .sizeRequest[size_index].id !==
                                              ""
                                            ) {
                                              sizeRemove.push(
                                                tmp[productPos].brandsRequest[
                                                  brand_index
                                                ].genRequest[gen_index]
                                                  .sizeRequest[size_index].id
                                              );
                                            }

                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[
                                              gen_index
                                            ].sizeRequest.splice(size_index, 1);

                                            await this.setState({
                                              productRequest: tmp,
                                              sizeRemove: sizeRemove,
                                            });
                                          }}
                                        >
                                          ลบรายการ
                                          <Icon name="trash" />
                                        </Button>
                                      ) : (
                                        <Button
                                          icon
                                          labelPosition="left"
                                          type="button"
                                          color={theme_color_error}
                                          floated="left"
                                          disabled
                                        >
                                          ลบรายการ
                                          <Icon name="trash" />
                                        </Button>
                                      )}
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Segment>
                            ))}

                            <br />
                            <Grid columns="equal">
                              {/* ลบรุ่น */}
                              <Grid.Row only="computer tablet">
                                <Grid.Column>
                                  {tmp[productPos].brandsRequest[brand_index]
                                    .genRequest.length > 1 ? (
                                    <Button
                                      icon
                                      labelPosition="left"
                                      type="button"
                                      color={theme_color_error}
                                      floated="left"
                                      onClick={async () => {
                                        let genRemove = this.state.genRemove;

                                        if (
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].id !== ""
                                        ) {
                                          genRemove.push(
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].id
                                          );
                                        }

                                        tmp[productPos].brandsRequest[
                                          brand_index
                                        ].genRequest.splice(gen_index, 1);

                                        await this.setState({
                                          productRequest: tmp,
                                          genRemove: genRemove,
                                        });
                                      }}
                                    >
                                      ลบรุ่น
                                      <Icon name="trash" />
                                    </Button>
                                  ) : (
                                    <Button
                                      icon
                                      labelPosition="left"
                                      type="button"
                                      color={theme_color_error}
                                      floated="left"
                                      disabled
                                    >
                                      ลบรุ่น
                                      <Icon name="trash" />
                                    </Button>
                                  )}
                                  {/* เพิ่มรายการ */}
                                </Grid.Column>
                                <Grid.Column only="computer tablet" />
                                <Grid.Column>
                                  <Button
                                    icon
                                    labelPosition="left"
                                    type="button"
                                    color={theme_color_1}
                                    floated="right"
                                    onClick={async () => {
                                      tmp[productPos].brandsRequest[
                                        brand_index
                                      ].genRequest[gen_index].sizeRequest.push({
                                        id: "",
                                        sizeGen: "",
                                        sizeCapacity: "",
                                        sizeSpecialFeature: "",
                                        sizeStructure: "",
                                        sizeTypeOfUse: "",
                                        sizeCuttingWidth: "",
                                        sizeSymbol: "",
                                      });
                                      //   // console.log(x);
                                      await this.setState({
                                        productRequest: tmp,
                                      });
                                    }}
                                  >
                                    เพิ่มขนาด
                                    <Icon name="plus" />
                                  </Button>
                                </Grid.Column>
                              </Grid.Row>

                              <Grid.Row only="mobile">
                                <Grid.Column>
                                  {tmp[productPos].brandsRequest[brand_index]
                                    .genRequest.length > 1 ? (
                                    <Button
                                      size={"mini"}
                                      icon
                                      labelPosition="left"
                                      type="button"
                                      color={theme_color_error}
                                      // floated="left"
                                      onClick={async () => {
                                        let genRemove = this.state.genRemove;

                                        if (
                                          tmp[productPos].brandsRequest[
                                            brand_index
                                          ].id !== ""
                                        ) {
                                          genRemove.push(
                                            tmp[productPos].brandsRequest[
                                              brand_index
                                            ].genRequest[gen_index].id
                                          );
                                        }

                                        tmp[productPos].brandsRequest[
                                          brand_index
                                        ].genRequest.splice(gen_index, 1);

                                        await this.setState({
                                          productRequest: tmp,
                                          genRemove: genRemove,
                                        });
                                      }}
                                    >
                                      ลบรุ่น
                                      <Icon name="trash" />
                                    </Button>
                                  ) : (
                                    <Button
                                      size={"mini"}
                                      icon
                                      labelPosition="left"
                                      type="button"
                                      color={theme_color_error}
                                      disabled
                                    >
                                      ลบรุ่น
                                      <Icon name="trash" />
                                    </Button>
                                  )}
                                  {/* เพิ่มรายการ */}
                                </Grid.Column>
                                <Grid.Column only="computer tablet" />
                                <Grid.Column>
                                  <Button
                                    size={"mini"}
                                    icon
                                    labelPosition="left"
                                    type="button"
                                    color={theme_color_1}
                                    onClick={async () => {
                                      tmp[productPos].brandsRequest[
                                        brand_index
                                      ].genRequest[gen_index].sizeRequest.push({
                                        id: "",
                                        sizeGen: "",
                                        sizeCapacity: "",
                                        sizeSpecialFeature: "",
                                        sizeStructure: "",
                                        sizeTypeOfUse: "",
                                        sizeCuttingWidth: "",
                                        sizeSymbol: "",
                                      });
                                      //   // console.log(x);
                                      await this.setState({
                                        productRequest: tmp,
                                      });
                                    }}
                                  >
                                    เพิ่มขนาด
                                    <Icon name="plus" />
                                  </Button>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            <br />
                          </Segment>
                          <br />
                        </div>
                      ))}
                      {/* เพิ่มรุ่น */}
                      <Button
                        icon
                        labelPosition="left"
                        type="button"
                        color={theme_color_1}
                        floated="right"
                        onClick={async () => {
                          tmp[productPos].brandsRequest[
                            brand_index
                          ].genRequest.push({
                            id: "",
                            genName: "",
                            genLayoutFile: {
                              name: "",
                              data: [],
                              path: "",
                            },
                            sizeRequest: [
                              {
                                id: "",
                                sizeGen: "",
                                sizeCapacity: "",
                                sizeSpecialFeature: "",
                                sizeStructure: "",
                                sizeTypeOfUse: "",
                                sizeCuttingWidth: "",
                                sizeSymbol: "",
                              },
                            ],
                          });
                          //   // console.log(x);
                          await this.setState({
                            productRequest: tmp,
                          });
                        }}
                      >
                        เพิ่มรุ่น
                        <Icon name="plus" />
                      </Button>
                      <br />
                      <br />
                    </Segment>
                    <br />
                  </div>
                ))}
              </div>
              <br />
              <Grid>
                {/* เลขที่ใบอนุญาต */}

                <Grid.Row>
                  <Grid.Column computer={16} mobile={16}>
                    <Form.Field>
                      <Radio
                        label={`ได้รับใบอนุญาตตามมาตรฐานเลขที่ มอก.${
                          product.productTypeId === "1" ? "2718" : "2719"
                        }-2560`}
                        checked={tmp[productPos].licenseType === 1}
                        onChange={() => {
                          console.log("this state", this.state);
                          tmp[productPos].licenseType = 1;
                          tmp[productPos].requestNO = "";
                          this.setState({ productRequest: tmp });
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={8} mobile={16}>
                    <Form.Field
                      error={tmp[productPos].licenseNumberError}
                      disabled={tmp[productPos].licenseType !== 1}
                    >
                      <label>ใบอนุญาตที่</label>
                      <Input
                        placeholder={`ตัวอย่าง ${
                          factoryType === 1 ? "ท xxxx-xx" : "น xxxxx-xx"
                        }/${product.productTypeId === "1" ? "2718" : "2719"}`}
                        id={"licenseNumber_" + productPos}
                        value={tmp[productPos].licenseNumber}
                        onChange={async (e, { value }) => {
                          tmp[productPos].licenseNumber = value;
                          await this.setState({
                            productRequest: tmp,
                          });
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column computer={8} mobile={16}>
                    <Form.Field
                      error={tmp[productPos].listProductError}
                      disabled={tmp[productPos].licenseType !== 1}
                    >
                      <label>หน้าที่</label>

                      <Input
                        placeholder="กรอกหมายเลขหน้า"
                        id={"listProduct_" + productPos}
                        value={tmp[productPos].listProduct}
                        onChange={async (e, { value }) => {
                          tmp[productPos].listProduct = value;
                          await this.setState({
                            productRequest: tmp,
                          });
                        }}
                      />
                      <p style={{ color: "#db2828" }}>
                        หมายเหตุ: กรอกหมายเลขหน้าที่ปรากฏรายการยางรถยนต์ข้างต้น
                        หากมีมากกว่า 1 หน้า ให้ใช้เครื่องหมาย ,
                        คั่นระหว่างตัวเลข เช่น 1, 3, 4
                      </p>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                {/* ใบอนุญาต */}
                <Grid.Row>
                  <Grid.Column computer={16}>
                    <Form.Field
                      error={tmp[productPos].licenseNumberFileError}
                      disabled={tmp[productPos].licenseType !== 1}
                    >
                      <label>ใบอนุญาต</label>
                      <Segment id={"licenseNumberFile_" + productPos}>
                        <Label
                          attached="top"
                          color={
                            tmp[productPos].licenseNumberFileError
                              ? "red"
                              : "violet"
                          }
                          as="label"
                          htmlFor={"licenseNumberFile_upload_" + productPos}
                          size="large"
                        >
                          <Icon name="upload" />
                          เลือกไฟล์
                          <input
                            hidden
                            id={"licenseNumberFile_upload_" + productPos}
                            type="file"
                            accept=".pdf"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                            onChange={async (e) => {
                              if (e.target.files[0]) {
                                if (e.target.files.size > 10000000) {
                                  alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                  return;
                                }

                                var src = URL.createObjectURL(
                                  e.target.files[0]
                                );

                                tmp[productPos].licenseNumberFile.name =
                                  e.target.files[0].name;

                                tmp[productPos].licenseNumberFile.data =
                                  e.target.files[0];

                                tmp[productPos].licenseNumberFile.path = src;

                                tmp[productPos].licenseNumberFileError = false;

                                await this.setState({
                                  productRequest: tmp,
                                });
                              }
                            }}
                          />
                        </Label>
                        <p>
                          {tmp[productPos].licenseNumberFile.name}
                          &nbsp; &nbsp;
                          {tmp[productPos].licenseNumberFile.name ? (
                            <Icon
                              color="red"
                              size="large"
                              name="times circle outline"
                              link
                              onClick={async (e) => {
                                tmp[productPos].licenseNumberFile = {
                                  name: "",
                                  data: "",
                                  path: "",
                                };

                                tmp[productPos].licenseNumberFileError = true;

                                await this.setState({
                                  productRequest: tmp,
                                });

                                document.getElementById(
                                  "licenseNumberFile_" + productPos
                                ).value = null;
                              }}
                            />
                          ) : null}
                        </p>
                      </Segment>
                      <p
                        style={{
                          color: "#db2828",
                        }}
                      >
                        ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                      </p>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column computer={16} mobile={16}>
                    <Form.Field>
                      <Radio
                        label={`ได้ยื่นขอใบอนุญาตตามมาตรฐานเลขที่ มอก.${
                          product.productTypeId === "1" ? "2718" : "2719"
                        }-2560`}
                        checked={tmp[productPos].licenseType === 2}
                        onChange={() => {
                          // console.log('this state', tmp[productPos])
                          tmp[productPos].licenseType = 2;
                          tmp[productPos].licenseNumber = "";
                          tmp[productPos].listProduct = "";
                          tmp[productPos].licenseNumberFile = {
                            name: "",
                            data: "",
                            path: "",
                          };
                          this.setState({ productRequest: tmp });
                          document.getElementById(
                            "licenseNumberFile_" + productPos
                          ).value = null;
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column computer={8} mobile={16}>
                    <Form.Field
                      error={tmp[productPos].requestNOError}
                      disabled={tmp[productPos].licenseType !== 2}
                    >
                      <label>คำขอที่</label>

                      <Input
                        placeholder="กรอกเลขที่คำขอ"
                        id={"requestNO_" + productPos}
                        // disabled={}
                        value={tmp[productPos].requestNO}
                        onChange={async (e, { value }) => {
                          tmp[productPos].requestNO = value;
                          await this.setState({
                            productRequest: tmp,
                          });
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
            </Segment>
          </Form>
          <br />

          <Requestbutton
            history={this.props.history}
            pos={productPos}
            url={"requestDetail"}
            data={this.state}
            productLength={tmp.length}
            callBackParent={this.recieveProps}
          />
        </Container>
      </div>
    );
  }

  recieveProps = (childData) => {
    // console.log("childData", childData);
    this.setState(childData);
    this.createOption();
  };

  createOption = async () => {
    var productPos = this.state.productPos;
    var product = this.state.productRequest[productPos];

    var tmp = this.state.productRequest;

    if (product.productTypeId === "1") {
      product.specialFeatureOptions = [
        { key: 0, value: "ไม่มี", text: "ไม่มี" },
        { key: 1, value: "Reinforced (RFD)", text: "Reinforced (RFD)" },
        { key: 2, value: "Extra Load (XL)", text: "Extra Load (XL)" },
        {
          key: 3,
          value: "Reinforced/Extra Load (RFD/XL)",
          text: "Reinforced/Extra Load (RFD/XL)",
        },
      ];
      product.structureOptions = [
        { key: 0, value: "เรเดียล", text: "เรเดียล" },
        { key: 1, value: "รันแฟลต", text: "รันแฟลต" },
      ];
    } else if (product.productTypeId === "2") {
      product.specialFeatureOptions = [
        { key: 0, value: "ไม่มี", text: "ไม่มี" },
        { key: 1, value: "Tranction", text: "Tranction" },
      ];
      product.structureOptions = [
        { key: 0, value: "เรเดียล", text: "เรเดียล" },
        { key: 1, value: "ผ้าใบเฉียง", text: "ผ้าใบเฉียง" },
        { key: 2, value: "ผ้าใบเฉียงแบบเบลท์", text: "ผ้าใบเฉียงแบบเบลท์" },
      ];
    } else if (
      product.productTypeId === "3" &&
      product.productName.includes("ผ้าใบเฉียง") === true
    ) {
      product.specialFeatureOptions = [
        { key: 0, value: "ไม่มี", text: "ไม่มี" },
        { key: 1, value: "Tranction", text: "Tranction" },
      ];
      product.structureOptions = [
        { key: 0, value: "ผ้าใบเฉียง", text: "ผ้าใบเฉียง" },
        { key: 1, value: "ผ้าใบเฉียงแบบเบลท์", text: "ผ้าใบเฉียงแบบเบลท์" },
      ];
    } else if (product.productTypeId === "3") {
      product.specialFeatureOptions = [
        { key: 0, value: "ไม่มี", text: "ไม่มี" },
        { key: 1, value: "Tranction", text: "Tranction" },
      ];
      product.structureOptions = [
        { key: 0, value: "เรเดียล", text: "เรเดียล" },
      ];
    }

    await this.setState({
      productRequest: tmp,
    });
  };
}

const mapStateToProps = function (state) {
  return {
    requestRedux: state.requestRedux,
  };
};

export default connect(mapStateToProps)(requestDetail);
