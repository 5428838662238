import React, { Component, createRef, Fragment } from "react";
import {
  Grid,
  Button,
  Table,
  Icon,
  Form,
  Divider,
  Ref,
  Sticky,
  Label,
} from "semantic-ui-react";
import { HOST_PATH_FILE } from "../../router";
import Iframe from "react-iframe";

export default class ProductRegisterTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      isBrandsChange: [],
    };
  }
  contextRef = createRef();

  showCertificate = (path) => {
    this.setState({
      file: `${HOST_PATH_FILE}${path}`,
    });
  };

  componentDidMount = () => {
   
    // const factory = this.props.dataFactory[0];
    const data = this.props.dataFactory.filter(
      (data) => data.type_factories_idTemp === "โรงงานผลิต"
    );

    const factory = data[0]

    let isBrandsChange = [];
    data.map((d) => {
      const _tempBrand = d.Products.map((prod) =>{
        const isChange = prod.Brands
          ? prod.Brands.some((e) => {
              return e.action === "deleted" || e.action === "created";
            })
          : false;
        return isChange;
      });

      isBrandsChange.push(_tempBrand);
    });
    this.setState({ isBrandsChange: isBrandsChange });
    if(factory)
    this.showCertificate(
      factory.factory_licenseIsEdited
        ? factory.factory_licenseTemp
        : factory.factory_licenseOriginal
    );

  };

  getCompared = (origin, alter) => {
    return (
      <Fragment>
        <span style={{ textDecoration: "line-through", color: "gray" }}>
          {origin}
        </span>{" "}
        <span style={{ color: "green" }}>{alter}</span>
      </Fragment>
    );
  };

  getLabelStatus = (tableType, status) => {
    let labelText = "";
    switch (tableType) {
      case "factory":
        labelText = "โรงงาน";
        break;
      case "product":
        labelText = "ผลิตภัณฑ์";
        break;
      case "export":
        labelText = "ผู้ส่งออก";
        break;
      case "warehouse":
        labelText = "สถานที่เก็บสินค้า";
        break;
      case "brand":
        labelText = "ชื่อทางการค้า";
        break;
    }
    if (status === "created" || status === "deleted") {
      return (
        <Label
          floated
          style={{ float: "right" }}
          color={status === "created" ? "green" : "red"}
        >
          {`ข้อมูล${labelText}ที่${
            status === "created" ? "เพิ่มเข้ามาใหม่" : "ถูกลบ"
          }`}
        </Label>
      );
    }
    return null;
  };
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Ref innerRef={this.contextRef}>
              <Form.Field>
                {this.props.dataFactory
                  .filter((data) => data.type_factories_idTemp === "โรงงานผลิต")
                  .map((factory, index) => {
                    {
                      /* if (factory.type_factories_id === 'โรงงานผลิต') { */
                    }
                    return (
                      <div>
                        <Table
                          className={"oie-table"}
                          inverted={
                            factory.action === "created" ||
                            factory.action === "deleted"
                              ? true
                              : false
                          }
                          color={
                            factory.action === "created"
                              ? "green"
                              : factory.action === "deleted"
                              ? "red"
                              : null
                          }
                          celled
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan="2" width={1}>
                                <Icon name="user" />
                                ข้อมูลการผลิตโรงงานที่ {index + 1}
                                {this.getLabelStatus("factory", factory.action)}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>ชื่อโรงงาน</Table.Cell>
                              <Table.Cell>
                                {factory.name_thIsEdited
                                  ? this.getCompared(
                                      factory.name_thOriginal,
                                      factory.name_thTemp
                                    )
                                  : factory.name_thOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>Factory Name</Table.Cell>
                              <Table.Cell>
                                {factory.name_enIsEdited
                                  ? this.getCompared(
                                      factory.name_enOriginal,
                                      factory.name_enTemp
                                    )
                                  : factory.name_enOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                เลขที่ใบอนุญาตประกอบกิจการโรงงาน
                              </Table.Cell>
                              <Table.Cell>
                                {factory.license_noIsEdited
                                  ? this.getCompared(
                                      factory.license_noOriginal,
                                      factory.license_noTemp
                                    )
                                  : factory.license_noOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                ใบรับรองระบบบริหารงานคุณภาพ
                              </Table.Cell>
                              <Table.Cell>
                                {" "}
                                {factory.standard_qc_idIsEdited
                                  ? this.getCompared(
                                      factory.standard_qc_idOriginal,
                                      factory.standard_qc_idTemp
                                    )
                                  : factory.standard_qc_idOriginal}
                              </Table.Cell>
                            </Table.Row>
                            {factory.standard_qc_expireOriginal && (
                              <Table.Row>
                                <Table.Cell>
                                  วันหมดอายุใบรับรองระบบบริหารงานคุณภาพ
                                </Table.Cell>
                                <Table.Cell>
                                  {factory.standard_qc_expireIsEdited
                                    ? this.getCompared(
                                        factory.standard_qc_expireOriginal,
                                        factory.standard_qc_expireTemp
                                      )
                                    : factory.standard_qc_expireOriginal}
                                </Table.Cell>
                              </Table.Row>
                            )}
                            <Table.Row>
                              <Table.Cell>จาก</Table.Cell>
                              <Table.Cell>
                                {factory.standard_fromIsEdited
                                  ? this.getCompared(
                                      factory.standard_fromOriginal,
                                      factory.standard_fromTemp
                                    )
                                  : factory.standard_fromOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ภายใต้</Table.Cell>
                              <Table.Cell>
                                {" "}
                                {factory.standard_departmentIsEdited
                                  ? this.getCompared(
                                      factory.standard_departmentOriginal,
                                      factory.standard_departmentTemp
                                    )
                                  : factory.standard_departmentOriginal}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell active colSpan="2">
                                ที่ตั้งโรงงาน
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>เลขที่</Table.Cell>
                              <Table.Cell>
                                {factory.addressIsEdited
                                  ? this.getCompared(
                                      factory.addressOriginal,
                                      factory.addressTemp
                                    )
                                  : factory.addressOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ซอย</Table.Cell>
                              <Table.Cell>
                                {factory.soiIsEdited
                                  ? this.getCompared(
                                      factory.soiOriginal,
                                      factory.soiTemp
                                    )
                                  : factory.soiOriginal}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>ถนน</Table.Cell>
                              <Table.Cell>
                                {factory.roadIsEdited
                                  ? this.getCompared(
                                      factory.roadOriginal,
                                      factory.roadTemp
                                    )
                                  : factory.roadOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>ตำบล/แขวง</Table.Cell>
                              <Table.Cell>
                                {factory.subdistrictIsEdited
                                  ? this.getCompared(
                                      factory.subdistrictOriginal,
                                      factory.subdistrictTemp
                                    )
                                  : factory.subdistrictOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>อำเภอ/เขต</Table.Cell>
                              <Table.Cell>
                                {" "}
                                {factory.districtIsEdited
                                  ? this.getCompared(
                                      factory.districtOriginal,
                                      factory.districtTemp
                                    )
                                  : factory.districtOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>จังหวัด</Table.Cell>
                              <Table.Cell>
                                {factory.provinceIsEdited
                                  ? this.getCompared(
                                      factory.provinceOriginal,
                                      factory.provinceTemp
                                    )
                                  : factory.provinceOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>รหัสไปรษณีย์</Table.Cell>
                              <Table.Cell>
                                {factory.zipcodeIsEdited
                                  ? this.getCompared(
                                      factory.zipcodeOriginal,
                                      factory.zipcodeTemp
                                    )
                                  : factory.zipcodeOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>เบอร์โทรศัพท์</Table.Cell>
                              <Table.Cell>
                                {factory.phoneIsEdited
                                  ? this.getCompared(
                                      factory.phoneOriginal,
                                      factory.phoneTemp
                                    )
                                  : factory.phoneOriginal}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell active colSpan="2">
                                เอกสารแนบ
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                ใบอนุญาตประกอบกิจการโรงงาน
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  size="small"
                                  basic
                                  color="orange"
                                  style={{
                                    marginTop: 10,
                                    display: "flex",
                                    justifyContens: "flex-end",
                                  }}
                                  onClick={() =>
                                    this.showCertificate(
                                      factory.factory_licenseIsEdited
                                        ? factory.factory_licenseTemp
                                        : factory.factory_licenseOriginal
                                    )
                                  }
                                >
                                  <Icon name="search" />
                                  ดูเอกสาร
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                ใบรับรองระบบบริหารงานคุณภาพ
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  size="small"
                                  basic
                                  color="orange"
                                  style={{
                                    marginTop: 10,
                                    display: "flex",
                                    justifyContens: "flex-end",
                                  }}
                                  onClick={() =>
                                    this.showCertificate(
                                      factory.qc_licenseIsEdited
                                        ? factory.qc_licenseTemp
                                        : factory.qc_licenseOriginal
                                    )
                                  }
                                >
                                  <Icon name="search" />
                                  ดูเอกสาร
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell active colSpan="2">
                                ข้อมูลผลิตภัณฑ์
                              </Table.Cell>
                            </Table.Row>
                            {factory.Products &&
                              factory.Products.map((product, index_product) => {
                                {
                                  /* if (product.factories_id === factory.id) { */
                                }
                                return (
                                  <React.Fragment>
                                    <Table.Row>
                                      <Table.Cell
                                        colSpan="2"
                                        textAlign="center"
                                      >
                                        <Table
                                          style={{ width: "100%" }}
                                          size="small"
                                          className={"oie-table"}
                                          inverted={
                                            product.action === "created" ||
                                            product.action === "deleted"
                                              ? true
                                              : false
                                          }
                                          color={
                                            product.action === "created"
                                              ? "green"
                                              : product.action === "deleted"
                                              ? "red"
                                              : null
                                          }
                                          collapsing
                                        >
                                          <Table.Row>
                                            <Table.Cell colSpan="2">
                                              ข้อมูลผลิตภัณฑ์ที่{" "}
                                              {index_product + 1}
                                              {/* {this.getLabelStatus(
                                              "product",
                                              product.action
                                            )} */}
                                            </Table.Cell>
                                          </Table.Row>
                                          <Table.Row>
                                            <Table.Cell>ผลิตภัณฑ์</Table.Cell>
                                            <Table.Cell>
                                              {product.type_product_idIsEdited
                                                ? this.getCompared(
                                                    product.type_product_idOriginal,
                                                    product.type_product_idTemp
                                                  )
                                                : product.type_product_idTemp}
                                            </Table.Cell>
                                          </Table.Row>
                                          <Table.Row>
                                            <Table.Cell>โครงสร้าง</Table.Cell>
                                            <Table.Cell>
                                              {product.type_struct_idIsEdited
                                                ? this.getCompared(
                                                    product.type_struct_idOriginal,
                                                    product.type_struct_idTemp
                                                  )
                                                : product.type_struct_idTemp}
                                            </Table.Cell>
                                          </Table.Row>
                                          <Table.Row>
                                            <Table.Cell>
                                              กำลังการผลิตทั้งหมด (เส้น/ปี)
                                            </Table.Cell>
                                            <Table.Cell>
                                              {product.product_amountIsEdited
                                                ? this.getCompared(
                                                    product.product_amountOriginal,
                                                    product.product_amountTemp
                                                  )
                                                : product.product_amountTemp}
                                            </Table.Cell>
                                          </Table.Row>

                                          {this.state.isBrandsChange.length > 0 &&
                                            (product.Brands &&
                                            (!this.state.isBrandsChange[index][
                                              index_product
                                            ] ||
                                              product.action === "created") ? (
                                              product.Brands.map(
                                                (productName, index) => {
                                                  return (
                                                    <Table.Row>
                                                      <Table.Cell collapsing>
                                                        ชื่อทางการค้าที่{" "}
                                                        {index + 1}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        {productName.nameIsEdited
                                                          ? this.getCompared(
                                                              productName.nameOriginal,
                                                              productName.nameTemp
                                                            )
                                                          : productName.nameTemp}
                                                        {/* {this.getLabelStatus(
                                                      "brand",
                                                      productName.action
                                                    )} */}
                                                      </Table.Cell>
                                                    </Table.Row>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <React.Fragment>
                                                <Table.Row>
                                                  <Table.Cell>
                                                    ข้อมูลชื่อทางการค้า
                                                  </Table.Cell>

                                                  <Table.Cell>
                                                    <Grid>
                                                      <Grid.Row>
                                                        <Grid.Column width={8}>
                                                          <Label
                                                            style={{
                                                              width: `100%`,
                                                            }}
                                                            color="olive"
                                                            size="large"
                                                          >
                                                            ข้อมูลชุดดั้งเดิม
                                                          </Label>
                                                          {product.Brands &&
                                                            product.Brands.filter(
                                                              (e) =>
                                                                e.origin ===
                                                                  "original" ||
                                                                e.origin ===
                                                                  "edit"
                                                            ).map(
                                                              (
                                                                productName,
                                                                idx
                                                              ) => {
                                                                return (
                                                                  <React.Fragment>
                                                                    <Table
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                      size="small"
                                                                      className={
                                                                        "oie-table"
                                                                      }
                                                                      collapsing
                                                                      colSpan="2"
                                                                    >
                                                                      <Table.Row>
                                                                        <Table.Cell
                                                                          collapsing
                                                                        >
                                                                          ชื่อทางการค้าที่{" "}
                                                                          {idx +
                                                                            1}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                          {productName.nameIsEdited
                                                                            ? this.getCompared(
                                                                                productName.nameOriginal,
                                                                                productName.nameTemp
                                                                              )
                                                                            : productName.nameTemp}

                                                                          {/* {this.getLabelStatus(
                                              "export",
                                              exportPerson.action
                                            )} */}
                                                                        </Table.Cell>
                                                                      </Table.Row>
                                                                    </Table>
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )}
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                          <Label
                                                            style={{
                                                              width: `100%`,
                                                            }}
                                                            color="yellow"
                                                            size="large"
                                                          >
                                                            ข้อมูลชุดที่มีการแก้ไข
                                                          </Label>
                                                          {product.Brands &&
                                                            product.Brands.filter(
                                                              (e) =>
                                                                e.origin ===
                                                                  "temp" ||
                                                                e.origin ===
                                                                  "edit"
                                                            ).map(
                                                              (
                                                                productName,
                                                                idx
                                                              ) => {
                                                                return (
                                                                  <React.Fragment>
                                                                    <Table
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                      size="small"
                                                                      className={
                                                                        "oie-table"
                                                                      }
                                                                      collapsing
                                                                      colSpan="2"
                                                                    >
                                                                      <Table.Row>
                                                                        <Table.Cell
                                                                          collapsing
                                                                        >
                                                                          ชื่อทางการค้าที่{" "}
                                                                          {idx +
                                                                            1}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                          {productName.nameIsEdited
                                                                            ? this.getCompared(
                                                                                productName.nameOriginal,
                                                                                productName.nameTemp
                                                                              )
                                                                            : productName.nameTemp}

                                                                          {/* {this.getLabelStatus(
                                              "export",
                                              exportPerson.action
                                            )} */}
                                                                        </Table.Cell>
                                                                      </Table.Row>
                                                                    </Table>
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )}
                                                        </Grid.Column>
                                                      </Grid.Row>
                                                    </Grid>
                                                  </Table.Cell>
                                                </Table.Row>
                                              </React.Fragment>
                                            ))}
                                        </Table>
                                      </Table.Cell>
                                    </Table.Row>
                                  </React.Fragment>
                                );
                              })}
                          </Table.Body>
                        </Table>

                        <br />
                      </div>
                    );
                    {
                      /* } */
                    }
                  })}
              </Form.Field>
            </Ref>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Sticky context={this.contextRef} pushing>
              <Iframe
                url={this.state.file}
                width="100%"
                height="800px"
                display="initial"
                position="relative"
              />
            </Sticky>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
