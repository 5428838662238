import React, { Component } from "react";
import {
  Segment,
  Grid,
  Divider,
  Header,
  Icon,
  Container,
  Image,
  Card,
} from "semantic-ui-react";
import OIELogo from "../../assets/img/oie-logo.png";
// import TISILogo from '../../assets/img/TISI-logo.png'
import VisibilitySensor from "react-visibility-sensor";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Fade from "react-reveal/Fade";

export class About extends Component {
  constructor(props) {
    super(props);
    this.title = "About us";
    this.data = [
      {
        date: "24-09-2019",
        content: `ยางรถยนต์ไทย`,
        sub_content_1: [
          { name: "ประหยัด", img_name: "timeline_img1.png" },
          { name: "ปลอดภัย", img_name: "timeline_img2.png" },
          { name: "ไร้เสียงรบกวน", img_name: "timeline_img3.png" },
        ],
        sub_content_2: "ตาม มอก. 2721-2560",
      },
      {
        date: "23-08-2019",
        content:
          `สศอ. เปิดให้ผู้ประกอบการยื่นขอ ECO Sticker ยางรถยนต์ ผ่านเว็บไซต์ www.tyre.go.th`,
      },
      {
        date: "01-08-2019",
        content:
          `สศอ. เปิดให้ผู้ประกอบการลงทะเบียนเพื่อรับ Username และ Password สำหรับเข้าใช้งานในระบบ ECO Sticker ยางรถยนต์ ซึ่งมีกำหนดเปิดให้บริการตั้งแต่วันที่ 23 สิงหาคม 2562 เป็นต้นไป`,
      },
      {
        date: "31-07-2019",
        content:
          `สศอ. ร่วมกับ สมอ. จัดประชุมเพื่อรับฟังความเห็นเกี่ยวกับระบบและชี้แจง  ขั้นตอนการลงทะเบียน การขออนุมัติ ECO Sticker ยางรถยนต์ รวมถึงการขอใบอนุญาตตาม มอก. 2721-2560 ให้กับผู้ประกอบการ โดยมีผู้ประกอบการจำนวน 52 ราย และเจ้าหน้าที่ที่เกี่ยวข้องจาก สศอ. และ สมอ. จำนวน 16 ราย เข้าร่วมในการประชุมดังกล่าว`,
      },
      {
        date: "30-07-2019",
        content:
          `สศอ. ร่วมกับ สมอ. พัฒนาระบบ ECO Sticker ยางรถยนต์ แล้วเสร็จ โดยมี สศอ. เป็นผู้ออกแบบ ตรวจอนุมัติการลงทะเบียนของผู้ประกอบการ รวมถึงบำรุงรักษาระบบ และ สมอ. เป็นผู้ตรวจอนุมัติ ECO Sticker ยางรถยนต์ `,
      },
    ];

    this.styleContent = {
      fontFamily: `"Prompt", sans-serif`,
      textAlign: `justify`,
      textJustify: `inter-word`,
      // textJustify: `inter-word`,
      // wordBreak: `break-all`,
      wordSpacing:`-2px`,
      // letterSpacing:`0.025em`,
      fontWeight: "300",
      boxShadow: "1px 1px 15px 1px rgba(203, 170, 35, 0.4)",
      borderRadius: "10px",
      border: `solid 1px transparent`,
      backgroundImage: `linear-gradient(white, white),
        linear-gradient(357.13deg, #ffd53f 2.15%, #a4dd00 97.46%)`,
      backgroundOrigin: `border-box`,
      backgroundClip: `padding-box, border-box`,
      padding: "2.8em 1.5em",
      // paddingBottom:"3em",
      color: "black",
    };

    this.styleContent_2 = {
      fontFamily: `"Prompt", sans-serif`,
      textJustify: `inter-word`,
      // textJustify: `inter-word`,
      // wordBreak: `break-all`,
      wordSpacing:`-2px`,
      // letterSpacing:`0.025em`,
      fontWeight: "300",
      boxShadow: "1px 1px 15px 1px rgba(203, 170, 35, 0.4)",
      borderRadius: "10px",
      border: `solid 1px transparent`,
      backgroundImage: `linear-gradient(white, white),
        linear-gradient(357.13deg, #ffd53f 2.15%, #a4dd00 97.46%)`,
      backgroundOrigin: `border-box`,
      backgroundClip: `padding-box, border-box`,
      padding: "2.8em 1.5em",
      // paddingBottom:"3em",
      color: "black",
    };


    this.styleArrow = {
      borderRight: "5px solid #A4DD00",
      top: "12px",
    };

    this.styleIcon = {
      background: "linear-gradient(90deg, #A4DD00 0%, #FFD53F 100%)",
      color: "#fff",
      // width: '36px',
      // height: '36px',
      // marginLeft: '-18px'
    };

    this.styleHeader = {
      fontFamily: `"Prompt", sans-serif`,
      fontWeight: "300",
    };
  }

  getTimeline = () => {
    return this.data.map((i, idx) => (
      <VerticalTimelineElement
        key={idx}
        className="vertical-timeline-element--work"
        dateClassName="vertical-timeline-date"
        contentStyle={idx===1 ? this.styleContent_2 : this.styleContent}
        contentArrowStyle={this.styleArrow}
        iconStyle={this.styleIcon}
        date={i.date}
      >
        <div className="vertical-timeline-element-title">
          <div>{i.content}</div>
          {i.sub_content_1 && (
            <Card.Group stackable itemsPerRow={3} style={{ margin: "2px 0" }}>
              {i.sub_content_1.map((item, idx) => (
                <Card key={idx} raised centered>
                  <Card.Content>
                    <Header as="h6" textAlign="center" style={this.styleHeader}>
                      {item.name}
                    </Header>
                    <Image
                      src={require(`../../assets/img/${item.img_name}`)}
                      wrapped
                      ui={false}
                    />
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          )}
          {i.sub_content_2 && <div>{i.sub_content_2}</div>}
        </div>
      </VerticalTimelineElement>
    ));
  };

  render() {
    return (
      <section id="about" className="about-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
              <div className="row ">
                <div className="col-lg-12 about-title">
                  <Fade top>
                    <VisibilitySensor
                      onChange={(isVisible) =>
                        isVisible && this.props.setActiveMenu("about")
                      }
                    >
                      <h3 className="section-header">{this.title}</h3>
                    </VisibilitySensor>
                  </Fade>

                  <VerticalTimeline className={"news-timeline"}>
                    {this.getTimeline()}
                  </VerticalTimeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
