import React, { useEffect, useState } from "react"

const InputFile = ({ filename = "", style, id = "", keyId, onFileChange , disable=false  }) => {
    const [file_name, setFileName] = useState()

    useEffect(() => {
        setFileName(filename)
    }, [filename])
    
    const fnHandleInputChange = (e, id) => {
        if (e.target.files[0]) {
            if (e.target.files.size > 10000000) {
                alert("ไฟล์ใหญ่เกินที่กำหนดไว้")
                return
            }
            let newArr  = []
            newArr[id] = {
                ...newArr[id],
                name: e.target.files[0].name,
                data: e.target.files[0],
                path: URL.createObjectURL(e.target.files[0])
            }
            setFileName(e.target.files[0].name)
            if (onFileChange) {
                onFileChange(newArr)
            }
        }
    }

    return (
        <label
            htmlFor={id}
            style={style}
        >
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "100%" }}>{file_name}</div>
                <div style={{ minWidth: "fit-content" }}>เลือกไฟล์</div>
            </div>  
            <input
                hidden
                disabled={disable}
                id={id}
                type="file"
                accept=".pdf"
                onClick={(e) => {
                    e.target.value = null
                }}
                onChange={(e) => fnHandleInputChange(e, keyId)}
            />
        </label>
    )
}

export default InputFile
