import React, { Component } from "react";
import {
	Segment,
	Button,
	Header,
	Icon,
	Container,
	Form,
	Input,
	Divider,
	Message,
	Dimmer,
	Loader,
} from "semantic-ui-react";
import Headers from "../header/headerUser";
import axios from "axios";
import { LOGIN_USER, HEADER, INDUSTRY_LOGIN } from "../router";
import { to } from "../handle";
import Axios from "axios";
import { Redirect } from "react-router-dom";
// import NoticeModal from '../exolot/NoticeModal'

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			error: {
				username: "",
				password: "",
			},
			reset_password: false,
		};
	}

	handleOnchange = (e, { name, value }) => {
		this.setState({ [name]: value });
	};

	handleSummit = async () => {
		let loginData = {
			username: this.state.username,
			password: this.state.password,
		};

		let result,
			error = [];
		await localStorage.clear();
		await this.setState({ loader_login: true });
		if (this.props.isIndustry) {
			const { token, company_id } = this.props.location.state;
			const user = {
				username: this.state.username,
				password: this.state.password,
				company_id: company_id,
			};

			({ result, error } = await to(
				axios.post(INDUSTRY_LOGIN, user, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
			));
			if (error) {
				this.setState({ loader_login: false });
				if (!error.response) {
					// network error
					this.setState({ networkFail: true, loginFail: false, textError: "เกิดข้อผิดพลาดจากระบบเครือข่าย" });
				} else {
					// response data
					const response = error.response.data;
					if (response.message === "Internal Server Error: No Username in System") {
						this.setState({
							networkFail: false,
							loginFail: true,
							textError: "ไม่พบรหัสผู้ใช้งานนี้ในระบบ",
						});
					} else if (response.message === "Internal Server Error: wrong Username") {
						this.setState({ networkFail: false, loginFail: true, textError: "ชื่อผู้ใช้งานไม่ถูกต้อง" });
					} else if (response.message === "Internal Server Error: wrong Password") {
						this.setState({ networkFail: false, loginFail: true, textError: "รหัสผ่านไม่ถูกต้อง" });
					}
				}
			}
			if (result) {
				// console.log(result.data.data.name);
				if (result.status === 200) {
					localStorage.setItem("name", result.data.data.name);
					localStorage.setItem("company_id", result.data.data.companies_id);
					localStorage.setItem("token", result.data.data.access_token);
					localStorage.setItem("refreshToken", result.data.data.refresh_token);
					localStorage.setItem("role", "0");
					localStorage.setItem("should_show_popup", true);

					await this.setState({ loader_login: false, networkFail: false, loginFail: false, login: true });
				}
			}
		} else {
			({ result, error } = await to(axios.post(LOGIN_USER, loginData, HEADER)));
			if (error) {
				this.setState({ loader_login: false });
				if (!error.response) {
					// network error
					this.setState({ networkFail: true, loginFail: false, textError: "เกิดข้อผิดพลาดจากระบบเครือข่าย" });
				} else {
					// response data
					const response = error.response.data;
					if (response.message === "No Username in System") {
						this.setState({
							networkFail: false,
							loginFail: true,
							textError: "ไม่พบรหัสผู้ใช้งานนี้ในระบบ",
						});
					} else if (response.message === "wrong Username") {
						this.setState({ networkFail: false, loginFail: true, textError: "ชื่อผู้ใช้งานไม่ถูกต้อง" });
					} else if (response.message === "wrong Password") {
						this.setState({ networkFail: false, loginFail: true, textError: "รหัสผ่านไม่ถูกต้อง" });
					}
				}
			}
			if (result) {
				if (result.data.status === "Success") {
					localStorage.setItem("name", result.data.value.name);
					localStorage.setItem("company_id", result.data.value.companies_id);
					localStorage.setItem("token", result.data.value.access_token);
					localStorage.setItem("refreshToken", result.data.value.refresh_token);
					localStorage.setItem("role", "0");
					localStorage.setItem("should_show_popup", true);

					await this.setState({ loader_login: false, networkFail: false, loginFail: false, login: true });
				}
			}
		}
	};

	render() {
		if (this.props.isIndustry) {
			if (!this.props.location.state) {
				return <Redirect to="/" />;
			}
		}
		if (this.state.login) {
			window.location.href = "/company/main";
		}
		if (this.state.reset_password) {
			window.location.href = "/company/forgetPassword";
		}
		return (
			<div style={{ alignItems: 'center' }}>
				{/* <NoticeModal /> */}
				<Headers />
				<Container style={{width: 500, marginTop: 200}}>
					<Dimmer active={this.state.loader_login}>
						<Loader size="massive">Log in</Loader>
					</Dimmer>

					<Segment padded="very" textAlign="center" color="violet" loading={false}>
						<Header>
							<Icon name="user circle" color="violet" />
							ผู้ประกอบการเข้าสู่ระบบ
						</Header>

						<Form onSubmit={this.handleSummit}>
							<Divider />
							<Form.Field>
								<Input
									label={{
										color: "violet",
										icon: "user",
									}}
									placeholder="Username"
									name="username"
									onChange={this.handleOnchange}
									ref={(input) => {
										this.username = input;
									}}
									maxLength="100"
								/>
							</Form.Field>
							<Form.Field>
								<Input
									label={{ icon: "lock", color: "violet" }}
									type="Password"
									placeholder="Password"
									name="password"
									onChange={this.handleOnchange}
									ref={(input) => {
										this.password = input;
									}}
								/>
							</Form.Field>
							{this.state.loginFail && (
								<Message negative header={this.state.textError} content="กรุณาตรวจสอบใหม่อีกครั้ง" />
							)}
							{this.state.networkFail && (
								<Message
									negative
									header={this.state.textError}
									content="กรุณาเข้าใช้งานใหม่ในภายหลัง"
								/>
							)}
							<Container style={{display: 'flex', justifyContent: 'space-between'}}>
								<Button
									basic
									size="large"
									type="button"
									onClick={() => this.setState({ reset_password: true })}
								>
									ลืมรหัสผ่าน
								</Button>

								<Button primary size="large" type="submit">
									เข้าสู่ระบบ
								</Button>
							</Container>
							<Container style={{ marginTop: '20px' }}>
								<p style={{ textAlign: "right" }}>
									{" "}
									ติดต่อเจ้าหน้าที่ โทร. 02-4306805 ต่อ 680513 หรือ tyre@oie.go.th
								</p>
							</Container>
							
						</Form>
					</Segment>
				</Container>
			</div>
		);
	}
}
