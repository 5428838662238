const responseBackEnd = {
	Company: [
		{
			companies_id: '',
			type_companies_id: '',
			name_th: '',
			name_en: '',
			trademark: '',
			manufacture: '',
			import: '',
			tax_id: '',
			address: '',
			soi: '',
			road: '',
			province: '',
			district: '',
			subdistrict: '',
			zipcode: '',
			phone: '',
			fax: '',
			website: '',
			certificatedoc: '',
			taxdoc: '',
			trademarkdoc: '',
			status: '',
			approved: '',
			created_at: '',
			created_by: '',
			updated_by: '',
			updated_at: '',
			approve_oie: '',
			approve_oie_by: '',
			approve_oie_at: ''
		}
	],
	Factories: [
		{
			id: '',
			type_factories_id: '',
			name_th: '',
			name_en: '',
			license_no: '',
			standard_qc_id: '',
			standard_from: '',
			standard_department: '',
			factories_area_id: '',
			address: '',
			soi: '',
			road: '',
			province: '',
			district: '',
			subdistrict: '',
			zipcode: '',
			text_address: '',
			country_id: '',
			phone: '',
			factory_license: '',
			qc_license: '',
			companies_id: ''
		}
	],
	Products: [
		{
			id: '',
			factories_id: '',
			type_product_id: '',
			type_struct_id: '',
			product_amount: '',
			status: '',
			created_by: '',
			created_at: '',
			updated_by: '',
			updated_at: ''
		}
	],
	Brands: [
		{
			id: '',
			name: '',
			products_id: ''
		}
	],
	ExportPersons: [
		{
			id: '',
			name: '',
			country_id: '',
			factories_id: ''
		}
	],
	Persons: [
		{
			id: '',
			companies_id: '',
			type_person: '',
			nationality: '',
			name_th: '',
			name_en: '',
			tax_id: '',
			passport: '',
			phone: '',
			email: '',
			person_certificate: '',
			power_of_attorney: '',
			created_by: '',
			created_at: '',
			updated_by: '',
			updated_at: '',
			number_person: '',
			address: '',
			soi: '',
			road: '',
			subdistrict: '',
			district: '',
			province: '',
			zipcode: '',
			foreigner_address: ''
		}
	],
	FactoriesImportTh: [
		{
			id: '',
			factories_id: '',
			address: '',
			soi: '',
			road: '',
			subdistrict: '',
			district: '',
			province: '',
			zipcode: '',
			created_at: ''
		}
	],
	approved: false
};

export default (state = responseBackEnd, action) => {
	switch (action.type) {
		case 'setResponseValue':
			state = {...action.value};
	
			return state;
		case 'setApprovedStatus':
			state.approved = action.value;
			return state;
		default:
			return state;
	}
};
