import React, { Component } from "react";
import { Segment, Button, Grid, Header, Icon, Container, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Headers from "./header/headerUser";
import Manual_Industry from "../assets/Manual_Industry.pdf";
import Iframe from "react-iframe";

const DATACONTENT_REMARK = [
	"1) บริษัทฯ ที่จะขออนุมัติ ECO Sticker จะต้องลงทะเบียนผู้ประกอบการก่อน",
	"2) เมื่อ สศอ. พิจารณาข้อมูลและเอกสารการลงทะเบียนแล้ว ระบบจะส่ง Username/Password และสศอ. จะส่งรายละเอียดการติดตั้งระบบความปลอดภัย VPN (SOPHOS) ไปยังอีเมล์ผู้ประสานงานที่แจ้งไว้ในขั้นตอนการลงทะเบียน",
	"3) ในกรณีบริษัทฯ ได้รับ Username/Password จากระบบแล้ว แต่ยังไม่ได้รับอีเมล์แจ้งรายละเอียดการติดตั้งระบบ VPN ขอให้ติดต่อคุณนัดดาวรรณ์ พิมพ์หนู สศอ. (02-644-9420)",
	"4) บริษัทฯ จะต้อง Login ระบบ VPN ก่อน แล้วจึงเข้าสู่ระบบ ECO Sticker เพื่อยื่นขออนุมัติ ECO Sticker ต่อไป",
];
export default class Main extends Component {
	constructor() {
		super();
		this.state = {
			openFileView: false,
		};
	}
	handleOpenPreviewPdf() {
		this.setState({
			openFileView: true,
		});
	}
	render() {
		const btnStyle = {
			maxWidth: "100%",
			width: "30vw",
			height: "14rem",
			paddingLeft: "auto",
			paddingRight: "auto",
		};
		return (
			<div>
				<Headers />

				<Container style={{ marginTop: 50 }}>
					<PDFDetailPreviewModals
						fileUrl={Manual_Industry}
						title="คู่มือสมัคร i-Industry"
						open={this.state.openFileView}
						onClose={() => this.setState({ openFileView: false })}
					/>
					<Segment padded="very" color="violet">
						<Header as="h1" textAlign="left">
							<Icon name="users" color="violet" />
							ผู้ประกอบการเข้าสู่ระบบ
						</Header>
						<br />
						<Segment placeholder>
							<Grid columns={2} relaxed="very" textAlign="center">
								<Grid.Column>
									{/* <Link to='/company/login'> */}
									<Button disabled fluid color="violet" style={btnStyle}>
										<h2>
											<Icon name="key" inverted color="grey" /> เข้าสู่ระบบ
										</h2>
										<h3>สำหรับบริษัทที่เคยลงทะเบียนแล้ว</h3>
									</Button>
									{/* </Link> */}
								</Grid.Column>
								<Grid.Column>
									{/* <Link to='/entrepreneurRegister' onClick={() => localStorage.clear()}> */}
									<Button disabled color="violet" style={btnStyle}>
										<h2>
											<Icon name="plus" inverted color="grey" /> ลงทะเบียน
										</h2>
										<h3>สำหรับบริษัทที่ยังไม่เคยลงทะเบียน</h3>
									</Button>
									{/* </Link> */}
								</Grid.Column>
							</Grid>
						</Segment>
						<Segment padded>
							<Grid>
								<Grid.Row>
									<Grid.Column width={8}>
										<h2>
											<sup>
												<Icon size="small" name="asterisk" />
											</sup>
											หมายเหตุ
										</h2>
										<p style={{ color: "red", fontSize: "17px" }}>
											สศอ. ขอเรียนให้ทราบว่า ตั้งแต่วันพุธที่ 1 กุมภาพันธ์ 2566 เป็นต้นไป
											การขออนุมัติECO Sticker จะดำเนินการ ผ่านระบบ i-Industry
											Platformของกระทรวงอุตสาหกรรมเท่านั้น ทั้งนี้ ท่านสามารถสมัครเพื่อขอ Username
											ของระบบ i-Industry ได้ที่{" "}
											<Link
												style={{ fontSize: "18px" }}
												to={{ pathname: "https://i.industry.go.th" }}
												target="_blank"
											>
												https://i.industry.go.th
											</Link>{" "}
											รายละเอียดตามคู่มือข้างล่างนี้
										</p>
										<Button
											size="medium"
											onClick={() => this.handleOpenPreviewPdf()}
											color="violet"
										>
											คู่มือสมัคร i-Industry
										</Button>
									</Grid.Column>
									<Grid.Column width={8}>
										{DATACONTENT_REMARK.map((item, i) => (
											<div style={{ display: "flex", padding: "0 10px",fontSize:15,color:"#868686" }}>
												<p>{item}</p>
											</div>
										))}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>
					</Segment>
				</Container>
			</div>
		);
	}
}

const BoxContainerPreviewPDF = {
	display: "flex",
	flexDirection: "column",
	minWidth: 700,
	height: "90vh",
	backgroundColor: "#fff",
	borderRadius: 15,
	overflow: "hidden",
	padding: "1.5rem 2rem",
};

const PDFDetailPreviewModals = ({ open, onClose, title, fileUrl }) => {
	return (
		<Modal closeIcon onClose={() => onClose()} open={open} dimmer="blurring" size="large">
			<div style={BoxContainerPreviewPDF}>
				<Header icon>
					<p style={{ fontSize: 20 }}>{title}</p>
				</Header>
				<iframe
					title="showPDF"
					id="showPDF"
					name="showPDF"
					src={fileUrl}
					style={{ height: "70rem", width: "100%", borderWidth: 0 }}
				/>
			</div>
		</Modal>
	);
};
