import React from "react";
import { Button, Modal } from "semantic-ui-react";

const ModalViewFile = ({ onClose, open, data }) => {
    return (
        <Modal
            onClose={onClose}
            open={open}
            style={{
                padding: 20,
            }}
        >
            <h4>{data?.title}</h4>
            <div style={{ display: "flex", flexDirection: "column", height: "75vh", width: "100%" }}>
                <iframe
                    src={data?.url}
                    title={data.title}
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                />
            </div>
            <div style={{ display: "flex", paddingTop: 20, justifyContent: "center" }}>
                <Button
                    onClick={() => onClose()}
                    style={{ width: 100 }}
                >
                    ปิด
                </Button>
            </div>
        </Modal>
    );
};

export default ModalViewFile;
