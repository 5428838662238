import React, { Component } from "react";
import PropTypes from "prop-types";
import TIS2718 from "../../assets/TIS2718_2558.pdf";
import TIS2719 from "../../assets/TIS2719_2558.pdf";
import TIS2721 from "../../assets/TIS2721_2560.pdf";
import news_scaletime from "../../assets/img/news_img_landingpage/news_22-04-2021_scaletime.png";
import Regulation_EC from "../../assets/Regulation_EC.pdf";
import Regulation from "../../assets/Regulation.pdf";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "react-reveal/Fade";
import Thailand from "@svg-maps/thailand";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import { Header, Icon, Grid, Image } from "semantic-ui-react";
import TableManufacturers from "./TableManufacturers";
import MapManufacturers from "./MapManufacturers";
import ProduceChart from "./ProduceChart";
import RevealCard from "./RevealCard";
export class Reports extends Component {
  constructor(props) {
    super(props);
    this.pdfLinkRef = React.createRef();
    this.state = {
      isOpenModal: false,
      src: null,
      styleObj: { left: 0, top: 0 },
      width: 0,
      height: 0,
    };
  }
  componentDidMount() {}
  handleClick = (e, src) => {
    e.preventDefault();
    if (src) {
      this.setState({
        src: src,
        isOpenModal: true,
      });
    }
  };

  getLocationClassName = (e) => {
    console.log('get element by name', document.getElementById("bkk"))
    // console.log("event", e.target.getBoundingClientRect().x);
    console.log("event", e.target.getBBox());
    let coord = e.target.getBBox();
    // const coord = e .target.getPointAtLength(e.target.getTotalLength());
    // const xCal = 400 + coord.x + "px";
    // const yCal = 300 + coord.y + "px";
    // console.log("x", coord.x);
    this.setState({
      styleObj: {
        ...this.state.styleObj,
        left: `${coord.x}px`,
        top: `${coord.y}px`,
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <Fade>
          <section id="reports" className="reports item-bg-one">
            <div className="container">
              <div>
                <VisibilitySensor
                  onChange={(isVisible) =>
                    isVisible && this.props.setActiveMenu("reports")
                  }
                >
                  <div>
                    {/* <div>
                      <Image src={news_scaletime} />
                    </div> */}

                    {/* <TableManufacturers /> */}
                    
                    {/* <MapManufacturers/> */}

                    {/* *** map position of factories [note: เอาออกกก่อนยังไม่เสร็จ] */}
                   

                    {/* // *** end */}

                    {/* // *** Chart แสดงปริมาณการผลิต [note: ยังไม่เสร็จปิดไว้ก่อน] */}
                    {/* <ProduceChart /> */}
                  </div>
                </VisibilitySensor>
              </div>
            </div>
          </section>
        </Fade>
      </React.Fragment>
    );
  }
}

Reports.PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  newssData: PropTypes.array,
};
Reports.defaultProps = {
  sectionTitle: "สถานที่ตั้งโรงงานผลิตยางรถยนต์",
  sectionTitleSpan: "Location of Tyre Plants",
  sectionContent:
    "Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
  newssData: [
    {
      imglink: "#",
      image: require("../../assets/img/กฏกระทรวง.png"),
      titlelink: "#",
      title: "กฎกระทรวง",
      btnlink: "#",
      btnname: "กำหนดลักษณะ การทำ วิธีแสดง",
      btnname_second:
        "และการใช้เครื่องหมายมาตรฐานกับผลิตภัณฑ์อุตสาหกรรม พ.ศ. 2563",
      src: Regulation,
    },
    {
      imglink: "#",
      image: require("../../assets/img/2721.png"),
      titlelink: "#",
      title: "มอก. 2721-2560",
      btnlink: "#",
      btnname:
        "เสียงจากยางล้อที่สัมผัสผิวถนน การยึดเกาะถนนบนพื้นเปียกและความต้านทานการหมุน (แก้ไขครั้งที่ ๑)",
      src: TIS2721,
    },
    {
      imglink: "#",
      image: require("../../assets/img/2718.png"),
      titlelink: "#",
      title: "มอก. 2718-2560",
      btnlink: "#",
      btnname: "ยางล้อแบบสูบลมสำหรับรถยนต์และส่วนพ่วง (แก้ไขครั้งที่ ๑)",
      src: TIS2718,
    },
    {
      imglink: "#",
      image: require("../../assets/img/2719.png"),
      titlelink: "#",
      title: "มอก. 2719-2560",
      btnlink: "#",
      btnname:
        "ยางล้อแบบสูบลมสำหรับรถยนต์เชิงพาณิชย์และส่วนพ่วง (แก้ไขครั้งที่ ๑)",
      src: TIS2719,
    },
  ],
};
export default Reports;
