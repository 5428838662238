import React, { Component, createRef } from 'react';
import { connect } from 'react-redux'; // connect redux
import { Grid, Button, Table, Icon, Form, Sticky, Visibility, Ref, Rail, Divider, Header } from 'semantic-ui-react';
import Iframe from 'react-iframe';

class previewProductRegister extends Component {
	constructor(props) {
		super(props);
		this.state = {
			factory_form_obj: [],
			file: ''
		};
	}
	contextRef = createRef();

	componentDidMount = async () => {
		await this.setState({
			factory_form_obj: this.props.stateRedux.factory_form_obj,
			file: this.props.stateRedux.factory_form_obj[0].factory_license_file.path
		});
	};

	showCertificate = async (path) => {
		await this.setState({
			file: path
		});
	};

	render() {
		var factory_obj = this.state.factory_form_obj;

		let tableData_list = [];
		let tableData;

		factory_obj.map((factory, index) => {
			tableData = (
				<div>
					<Table celled striped>
						<Table.Header>
							<Table.Row>
								{/* <Table.HeaderCell colSpan='2' width={1}>
									<Icon name='user' />
									ข้อมูลการผลิตโรงงานที่ {index + 1}
								</Table.HeaderCell> */}
								
								<Table.HeaderCell colSpan='2' width={1}>
								<Header as='h4'>
									<Header.Content>
									<Icon name="user" />
									ข้อมูลการผลิตโรงงานที่ {index + 1}
									</Header.Content>
								</Header>
								</Table.HeaderCell>

							</Table.Row>
						</Table.Header>

						<Table.Body>
							<Table.Row>
								<Table.Cell>
									<Header as='h5'>
										<Header.Content>
										ชื่อโรงงานภาษาไทย
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell>{factory.factory_name_th}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Header as='h5'>
										<Header.Content>
											ชื่อโรงงานภาษาอังกฤษ
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell>{factory.factory_name_en}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell><Header as='h5'>
										<Header.Content>
											เลขที่ใบอนุญาตประกอบกิจการโรงงาน
										</Header.Content>
									</Header>
									</Table.Cell>
								<Table.Cell>{factory.factory_license_number}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Header as='h5'>
										<Header.Content>
										ใบรับรองระบบบริหารงานคุณภาพ
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell>{factory.factory_certificate}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Header as='h5'>
										<Header.Content>
										วันหมดอายุใบรับรองระบบบริหารงานคุณภาพ
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell>{factory.factory_expire_certificate}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Header as='h5'>
										<Header.Content>
										จาก
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell>{factory.factory_from}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
									<Header as='h5'>
										<Header.Content>
										ภายใต้
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell>{factory.factory_under}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell colSpan='2'>
									<Header as='h5'>
										<Header.Content>
										ที่ตั้งโรงงาน
										</Header.Content>
									</Header>
									
								</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell>เลขที่</Table.Cell>
								<Table.Cell>{factory.factory_number}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>ซอย</Table.Cell>
								<Table.Cell>{factory.factory_alley}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell>ถนน</Table.Cell>
								<Table.Cell>{factory.factory_road}</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell>ตำบล/แขวง</Table.Cell>
								<Table.Cell>{factory.factory_zone}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>อำเภอ/เขต</Table.Cell>
								<Table.Cell>{factory.factory_district}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>จังหวัด</Table.Cell>
								<Table.Cell>{factory.factory_province}</Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>รหัสไปรษณีย์</Table.Cell>
								<Table.Cell>{factory.factory_zip_code}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>
								<Header as='h5'>
										<Header.Content>
										เบอร์โทรศัพท์
										</Header.Content>
									</Header>
									</Table.Cell>
								<Table.Cell>{factory.factory_tel}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan='2'>
									<Header as='h5'>
										<Header.Content>
										เอกสารแนบ
										</Header.Content>
									</Header>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>ใบอนุญาตประกอบกิจการโรงงาน</Table.Cell>
								<Table.Cell textAlign='center'>
									<Button
										size='small'
										basic
										color='orange'
										style={{
											marginTop: 10,
											display: 'flex',
											justifyContens: 'flex-end'
										}}
										onClick={() => this.showCertificate(factory.factory_license_file.path)}
									>
										<Icon name='search' />
										ดูเอกสาร
									</Button>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>ใบรับรองระบบบริหารงานคุณภาพ</Table.Cell>
								<Table.Cell textAlign='center'>
									<Button
										size='small'
										basic
										color='orange'
										style={{
											marginTop: 10,
											display: 'flex',
											justifyContens: 'flex-end'
										}}
										onClick={() => this.showCertificate(factory.factory_certificate_file.path)}
									>
										<Icon name='search' />
										ดูเอกสาร
									</Button>
								</Table.Cell>
							</Table.Row>

							{factory.factory_product.map((product, index_product) => (
								<React.Fragment>
									<Table.Row>
										<Table.Cell colSpan='2'>
											<Header as='h5'>
												<Header.Content>
												ข้อมูลผลิตภัณฑ์ที่ {index_product + 1}
												</Header.Content>
											</Header>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											<Header as='h5'>
												<Header.Content>
												ผลิตภัณฑ์
												</Header.Content>
											</Header>
											</Table.Cell>
										<Table.Cell>{product.product_name}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
										<Header as='h5'>
												<Header.Content>
												โครงสร้าง
												</Header.Content>
											</Header>
											</Table.Cell>
										<Table.Cell>{product.product_structure}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											<Header as='h5'>
												<Header.Content>
												กำลังการผลิตทั้งหมด (เส้น/ปี)
												</Header.Content>
											</Header>
											</Table.Cell>
										<Table.Cell>{product.product_power_output}</Table.Cell>
									</Table.Row>
									{product.product_trade_name.map((trade, index_trade) => (
										<React.Fragment>
											<Table.Row>
												<Table.Cell>
												<Header as='h5'>
													<Header.Content>
													ชื่อทางการค้า {index_trade + 1}
													</Header.Content>
												</Header>
											</Table.Cell>
												<Table.Cell>{trade.trade_name}</Table.Cell>
											</Table.Row>
										</React.Fragment>
									))}
								</React.Fragment>
							))}
						</Table.Body>
					</Table>

					<Divider />

					<br />
				</div>
			);
			return tableData_list.push(tableData);
		});

		return (
			<div>
				<Ref innerRef={this.contextRef}>
					<Grid>
						<Grid.Row>
							<Grid.Column mobile={8} tablet={8} computer={8}>
								<Visibility>
									<Form.Field>{tableData_list}</Form.Field>
								</Visibility>
							</Grid.Column>

							<Grid.Column mobile={8} tablet={8} computer={8}>
								<Rail
									style={{
										width: '100%'
									}}
								>
									<Sticky context={this.contextRef} pushing>
										<Iframe
											url={this.state.file}
											width='100%'
											height='800px'
											display='initial'
											position='relative'
										/>
									</Sticky>
								</Rail>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Ref>
			</div>
		);
	}
}

const mapStateToProps = function(state){
	return {
		stateRedux: state.reduxxx
	};
};

export default connect(mapStateToProps)(previewProductRegister);
