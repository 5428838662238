import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Segment,
  Table,
  Button,
  Dimmer,
  Loader
} from "semantic-ui-react";
import Axios from "axios";
import { Apiurl } from "../../router";
import Swal from "sweetalert2";
import ApprovalHeader from "../ApprovalHeader";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      id: "",

      // center ตัวแปลสำหรับแสดงไฟล์ตาม path ใน iframe XD 
      //[note: เป็นต้นเหตุของการ error "v1" ที่ไม่สามารถดึง api ได้เนื่องจากไม่มีไฟล์]
      center: "",   

      comment: "",
      data: [],
      product: []
    };
  }

  goPreview = async () => {
    this.props.history.push("/approval/product_detail/preview_product", {
      tyre_id: this.state.id
    });
  };

  componentWillMount = async () => {
    // console.log("will", this.props.location);
    // var token =
    // console.log("token",token)
    if (typeof this.props.location.state !== "undefined") {
      this.loaddata();
      await this.setState({
        ref_code: this.props.location.state.ref_code
      });
    } else {
      this.props.history.push("/oie");
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loaddata = async () => {
 
    Axios.post(Apiurl + "/approve/get_detail_tisi", {
      id: this.props.location.state.id,
      ref_code: this.props.location.state.ref_code
    })
      .then(async res => {
        // console.log(res)
        await this.setState({
          data: res.data.Entrepreneur,
          id: res.data.Entrepreneur.id,
          taxdoc: res.data.Entrepreneur.tax_doc,
          center: Apiurl + res.data.Entrepreneur.certificatedoc,
          logo: res.data.Entrepreneur.logo,
          entrepreneur: res.data.Entrepreneur.entrepreneur,
          name_th: res.data.Entrepreneur.name_th,
          fac_name_th: res.data.Entrepreneur.fac_name_th,
          tax_id: res.data.Entrepreneur.tax_id,
          status: res.data.Entrepreneur.request_status,
          type_factories: res.data.Entrepreneur.type_factories,
          certificatedoc: res.data.Entrepreneur.certificatedoc,
          tax_doc: res.data.Entrepreneur.tax_doc,
          cancel_oie_tisi_note: res.data.Entrepreneur.cancel_oie_tisi_note,
          is_auto_approve: res.data.Entrepreneur.approved_oie_tisi_id === '12' ? true : false,
          approved_oie_tisi_by: res.data.Entrepreneur.approved_oie_tisi_by,
          approved_oie_tisi_at: res.data.Entrepreneur.approved_oie_tisi_at,
          approved_technical_by: res.data.Entrepreneur.approved_technical_by,
          approved_technical_at: res.data.Entrepreneur.approved_technical_at,
          product: res.data.Product
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  notapprove = () => {
    const { id } = this.state.data;
    if (this.state.comment === "") {
      Swal.fire({
        position: "center",
        type: "warning",
        title: "กรุณากรอกเหตุผล",
        timer: 750,
        showConfirmButton: false
      }).then(() => {
        return;
      });
    } else {
      Swal.fire({
        title: "ยืนยันที่จะทำขั้นตอนนี้",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#21ba45",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      })
        .then(res => {
          this.setState({
            loader_request: true
          });
          if (res.value) {
            Axios.put(Apiurl + "/approve/unapprove_tisi", {
              request_id: id,
              reason: this.state.comment
            },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`
                }
              })
              .then(res => {
                this.setState({
                  loader_request: false
                });
                Swal.fire({
                  position: "center",
                  type: "success",
                  title: "ไม่อนุมัติสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500
                });
              })
              .catch(err => {
                console.log(err);
              })
              .then(() => new Promise(resolve => setTimeout(resolve, 1600)))
              .then(res => {
                this.props.history.push("/approval/tisi_oie");
              })
              .catch(err => {
                console.log(err);
              });
          } else if (res.dismiss === Swal.DismissReason.cancel) {
            this.setState({
              loader_request: false
            });
            return;
          }
        })
        .catch(err => {
          console.log("failed" + err);
        });
    }
  };

  approve = () => {
    const { id } = this.state.data;
    Swal.fire({
      title: "ยืนยันที่จะทำขั้นตอนนี้",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#21ba45",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก"
    })
      .then(res => {
        this.setState({
          loader_request: true
        });
        if (res.value) {
          Axios.put(
            Apiurl + "/approve/approve_tisi_oie",
            { request_id: id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
              }
            }
          )
            .then(res => {
              this.setState({
                loader_request: false
              });
              Swal.fire({
                position: "center",
                type: "success",
                title: "อนุมัติสำเร็จ",
                showConfirmButton: false,
                timer: 1500
              });
            })
            .catch(err => {
              console.log(err);
            })
            .then(() => new Promise(resolve => setTimeout(resolve, 1600)))
            .then(res => {
              this.props.history.push("/approval/tisi_oie");
            })
            .catch(err => {
              console.log("failed" + err);
            });
        } else if (res.dismiss === Swal.DismissReason.cancel) {
          this.setState({
            loader_request: false
          });
          return;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  edit = () => {
    const { id } = this.state.data;
    if (this.state.comment === "") {
      Swal.fire({
        position: "center",
        type: "warning",
        title: "กรุณากรอกเหตุผล",
        timer: 750,
        showConfirmButton: false
      }).then(() => {
        return;
      });
    } else {
      Swal.fire({
        title: "ยืนยันที่จะทำขั้นตอนนี้",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#21ba45",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      })
        .then(res => {
          this.setState({
            loader_request: true
          });
          if (res.value) {
            Axios.put(
              Apiurl + "/approve/edit_tisi_oie",
              { request_id: id, reason: this.state.comment },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`
                }
              }
            )
              .then(res => {
                this.setState({
                  loader_request: false
                });
                Swal.fire({
                  position: "center",
                  type: "success",
                  title: "แจ้งแก้ไขสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500
                });
              })
              .catch(err => {
                console.log(err);
              })
              .then(() => new Promise(resolve => setTimeout(resolve, 1600)))
              .then(res => {
                this.props.history.push("/approval/tisi_oie");
              })
              .catch(err => {
                console.log("failed" + err);
              });
          } else if (res.dismiss === Swal.DismissReason.cancel) {
            this.setState({
              loader_request: false
            });
            return;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  render() {
    console.log(this.state)
    const {
      name_th,
      fac_name_th,
      tax_id,
      certificatedoc,
      tax_doc
    } = this.state.data;
    return (
      <div>
        <ApprovalHeader />

        <Segment>
          <Dimmer active={this.state.loader_request}>
            <Loader size="massive">Loading</Loader>
          </Dimmer>
          <Grid columns="equal">
            {/* Left Space */}
            <Grid.Column></Grid.Column>
            {/* Center */}
            <Grid.Column computer={10} tablet={12} mobile={16}>
              <Segment>
                <h1 style={{ textAlign: "center", marginBottom: "50px" }}>
                  ข้อมูลประกอบการพิจารณา
                </h1>
                <Grid columns="equal">
                  {/* Left Column */}
                  <Grid.Column computer={8} tablet={16} mobile={16}>
                    {/* Top Row */}
                    <Grid.Row>
                      <Table celled fixed>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan="2" textAlign="center">
                              ข้อมูลผู้ประกอบการ
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <h4>ชื่อบริษัท</h4>
                            </Table.Cell>
                            <Table.Cell>{name_th}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>ชื่อโรงงาน</h4>
                            </Table.Cell>
                            <Table.Cell>{fac_name_th}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>เลขภาษี</h4>
                            </Table.Cell>
                            <Table.Cell>{tax_id}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>หนังสือรับรองนิติบุคคล</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                primary
                                content="เรียกดู"
                                onClick={() =>
                                  this.setState({ center: Apiurl + certificatedoc })
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>ใบทะเบียนภาษีมูลค่าเพิ่ม</h4>
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                primary
                                content="เรียกดู"
                                onClick={() =>
                                  this.setState({ center: Apiurl + tax_doc })
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <h4>ประเภทคำขอ</h4>
                            </Table.Cell>
                            <Table.Cell>
                              {this.state.type_factories === 1 ? (
                                <div>ผู้ผลิต</div>
                              ) : this.state.type_factories === 2 ? (
                                <div>ผู้นำเข้า</div>
                              ) : (
                                    ""
                                  )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row textAlign="center">
                            <Table.Cell colSpan="2">
                              <Button
                                color="facebook"
                                content="แสดงข้อมูลทั้งหมด"
                                onClick={async () => await this.goPreview()}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Row>
                    {/* Bottom Row */}
                    <Grid.Row style={{ marginTop: "30px" }}>
                      <div>
                        <Table celled>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan="4" textAlign="center">
                                ผลิตภัณฑ์
                              </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                              <Table.HeaderCell textAlign="center">
                                ประเภท
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center">
                                โครงสร้าง
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center">
                                ชื่อทางการค้า
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center" collapsing>
                                ตัวอย่างสติ๊กเกอร์
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.state.product.map((item, index) => {
                              return (
                                <Table.Row textAlign="center" key={index}>
                                  <Table.Cell>{item.type_product}</Table.Cell>
                                  <Table.Cell>{item.name_struct}</Table.Cell>
                                  <Table.Cell>{item.name_brands}</Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      onClick={() =>
                                        this.props.history.push(
                                          "/approval/product_detail/sticker_product",
                                          {
                                            item,
                                            id: this.props.location.state.id
                                          }
                                        )
                                      }
                                    >
                                      แสดง
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      </div>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column>
                    <iframe
                      title="file_iframe"
                      src={this.state.center}
                      style={{
                        height: window.innerHeight,
                        width: "100%"
                      }}
                      id="iframe"
                    />
                  </Grid.Column>
                </Grid>
                <Segment clearing>
                  <Button
                    floated="left"
                    icon="arrow left"
                    href="/approval/tisi_oie"
                    content="กลับหน้าหลัก"
                    primary
                  />
                </Segment>
              </Segment>
            </Grid.Column>
            {/* Right Space */}
            <Grid.Column></Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default withRouter(ProductDetail);
